import {useRouter} from "~/vue-router";

const routeMethods = {
    routes: (name, params) => {
        const inst = Neloh.vueInstance();
        let route = inst._instance.ctx.findRouteByName(name);
        if (!route) {
            console.warn('Route: "' + name +'" not found');
            return null;
        }
        if (route.match(/^\/?backend/g)) {
            route = route.replace(/^\/?backend\/?/g, '');
        }
        return Neloh.vueInstance()._context.provides.backendUrl + '/' + inst._instance.ctx.replaceRouteParams(route, params);
    },

    replaceRouteParams: (rawRouteString, params) => {
        const inst = Neloh.vueInstance();
        let str_replace = Neloh.vueInstance()._context.provides.str_replace
        let match, path = rawRouteString.replace(/^\//), s, isOptional = false, pattern = /\{([a-z0-9_\?]+?)\}/gi;

        if (path) {
            if (!RegExp.prototype.hasOwnProperty('execAll')) {
                // Add to RegExp prototype
                RegExp.prototype.execAll = function (string) {
                    let matches = [], match = null;
                    while ((match = this.exec(string)) !== null) {
                        let i, matchArray = [];
                        for (i in match) {
                            if (!isNaN(parseFloat(i)) && isFinite(i)) {
                                matchArray.push(match[i]);
                            }
                        }
                        if (matchArray.length) matches.push(matchArray);
                    }
                    return matches;
                };
            }


            let match, matches = pattern.execAll(path);
            for (let i = 0; i < matches.length; i++) {
                // while (match = pattern.exec(path)) {

                match = matches[i];

                if (match && match[1])
                {

                    // is parameter optional?
                    if (match[1].match(/.+?\?$/gi)) {
                        isOptional = true;
                    }

                    // remove optional marker
                    s = match[1].replace(/\?$/g, '').replace(/^\?/g, '');

                    if (s)
                    {
                        if (typeof params === 'object')
                        {
                            if (params.hasOwnProperty(s) && params[s] !== null)
                            {
                                // replace param
                                path = path.replace(match[0], params[s]);
                            }
                            else {
                                if (isOptional)
                                {
                                    path = str_replace('/{' + s + '?}', '', path);
                                }
                            }
                        }
                        else {
                            if (isOptional) {
                                path = str_replace('/{' + s + '?}', '', path);
                            }
                        }
                    }

                    // console.log("path with param: "+ path +"\n");
                    isOptional = false;
                }
            }


            return path;
        }
        else {
            while (match = pattern.exec(rawRouteString)) {
                if (match && match[1]) {
                    s = trim(match[1]);

                    if (s) {
                        if (typeof params === 'object') {
                            if (params.hasOwnProperty(s) && params[s] !== null) {
                                // replace param
                                rawRouteString = rawRouteString.replace(match[0], params[s]);
                            }
                        }
                    }
                }
            }

            return rawRouteString;
        }
    },
    findRouteByName: (name) => {
        const inst = Neloh.vueInstance();
        if (inst && typeof inst._context.provides.appRoutes === 'object') {
            if (inst._context.provides.appRoutes.hasOwnProperty(name)) {
                return inst._context.provides.appRoutes[name];
            }
        }

        return false;
    },

    hasRoute: (name) => {
        const inst = Neloh.vueInstance();
        if (inst && typeof inst._context.provides.appRoutes === 'object') {
            if (inst._context.provides.appRoutes.hasOwnProperty(name)) {
                return true;
            }
        }

        return false;
    },

    getRouteByName: (name) => {
        const router = useRouter();
        return router.resolve({name: name})
    },

    currentRouteUrl: (path) => {
        const inst = Neloh.vueInstance();
        return inst._context.provides.backendUrl + '/' + path.replace(/^\//, '');
    },

    transformRouteToUrl: (url) => {
        const inst = Neloh.vueInstance();
        let fullpath = inst._context.provides.backendUrl;
        fullpath = fullpath.replace(/\//g, '\/').replace(/\./g, '\\.');

        let regex = new RegExp('^'+ fullpath +'(.*)', 'g');
        let match = regex.exec(url);

        if (match && match.length === 2) {
            return match[1];
        }


        url = url.replace(inst._context.provides.backendUrl, '');

        return url.replace(/^\/backend/g, '');
    },

    transformUrlToRoute: (url) => {
        const inst = Neloh.vueInstance();
        let fullpath = inst._context.provides.backendUrl;
        fullpath = fullpath.toLowerCase();


        let str = url;
        let first = str.substr(0, fullpath.length );
        first = first.toLowerCase();

        if (first == fullpath) {
            str = str.substr( fullpath.length );

            return (str ? str : '/')
        }


        fullpath = fullpath.replace(/\//g, '\/').replace(/\./g, '\\.');

        let regex = new RegExp('^'+ fullpath +'(.*)', 'g');
        let match = regex.exec(url);

        if (match && match.length === 2) {
            return match[1] ==='' ? '/' : match[1];
        }

        return url.replace(inst._context.provides.baseUrl, '');
    }
};

_.each(routeMethods, (caller, k) => {
    Neloh.setPrototype(k, caller);
    Neloh.provide(k, caller)
});

Vue.mixin({
    methods: routeMethods
});
