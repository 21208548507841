<template>
    <transition-group
        :css="false"
        @enter="enter"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot/>
    </transition-group>
</template>
<script>
export default {
    name: 'VelocityGroup',
    emits: ['enter', 'leave', 'afterLeave'],
    methods: {
        enter(el, complete) {
            this.$events.$emit('enter', el, complete)
        },
        leave(el, complete) {
            this.$events.$emit('leave', el, complete)
        },
        afterLeave() {
            this.$events.$emit('afterLeave')
        }
    }
}
</script>
