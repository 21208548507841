<template>

    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">

<!--        <button @click="back" type="button" class="btn btn-primary">Zurück</button>-->

        <button class="btn btn-primary"
                @click="handleCreateUser"
                type="button"
                v-if="canCreateUser">
            <svg-icon name="plus"/>
            Benutzer anlegen
        </button>

    </toolbar>


    <data-table v-if="grid" ref="gridata"
                :field-components="fieldComponents"
                :base-data="grid"
                :toolbar="$refs.toolbar"
                detail-row-component="UserDetailRow"
                @open-detail-row="openDetails"
                @close-detail-row="closeDetails"
                @selected-count="(v) => { selectedTrashCount = v }"
                @delete="handleDelete"
                @loaded="onLoaded">



        <template v-slot:custom_actions="{ row, index, field }">
            <template v-if="row.hasOwnProperty('_rowmemo')">
                <memo-button :ref="'memo-'+ row.id"
                             :memo="row._rowmemo.value ?? ''"
                             :update-url="row._rowmemo.url"
                             :is-deleted="row.deleted_at"
                             :key="'memo-'+ row.id"
                             position="left"
                             classes="ml-5"
                             @input="m => { row._rowmemo.value = m }"></memo-button>
            </template>
        </template>


        <template v-slot:username="{row}">
            <div class="user">
                <div v-if="!row.deleted">{{ row.first_name }} {{ row.surname }}</div>
                <div class="text-small" v-if="row.username">{{ row.username }}</div>
            </div>
            <svg-icon name="eye-off" v-if="!row.profile_is_public" v-tooltip="'Ist für andere nicht sichtbar'"/>
        </template>


        <template v-slot:holder="{row}">
            <template v-if="row.holder">
                <div>{{ row.holder.name }}</div>
                <div class="text-small">{{ row.holder.street }} {{ row.holder.street_number }} <br>{{ row.holder.zip }} {{ row.holder.city }}</div>
            </template>
        </template>

        <template v-slot:institution="{row}">
            <template v-if="row.institution">
                <div>{{ row.institution.name }}</div>
                <div class="text-small">{{ row.institution.street }} {{ row.institution.street_number }} <br>{{ row.institution.zip }} {{ row.institution.city }}</div>
            </template>
        </template>



        <template v-slot:created_at="{row}">
            <created-updated-by-at v-if="row.created_at" :date="row.created_at" :user="row.createdby" type="created"/>
        </template>

        <template v-slot:updated_at="{row}">
            <created-updated-by-at v-if="row.updated_at" :date="row.updated_at" :user="row.updatedby" type="updated"/>
        </template>




    </data-table>



</template>

<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, getCurrentInstance, inject, nextTick, ref} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";

export default {
    name: "usersIndex",
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx
        const $http = inject('$http');
        const $events = inject('$events');
        const route = useRoute();
        const router = useRouter()
        const store = useStore();
	    const requestAction = inject('requestAction')
	    const setPropertyPath = inject('setPropertyPath');
        const confirmDelete = ref(null);
        const changeBreadcrumb = inject('changeBreadcrumb')

        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            refreshGrid,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
	        return store.getters['user/user'].is_admin;
            return true
            // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
        })

        loadData();


        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            if (route.query.newregistrations) {
                postData.newregistrations = true;
            }

            $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                if (!r.data.grid.url) {
                    r.data.grid.url = $root.currentRouteUrl(route.path);
                }

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                changeBreadcrumb(r.data)

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {
            changeBreadcrumb(data)
        }

        function handleCreateUser() {
            router.push({name: 'admin.users.create'})
        }


        function handleDelete(row, btn, callback) {

            if (btn.url) {
                callback();

                $events.$emit('pageloading', true);
                $http.post(btn.url).then((r) => {
                    $events.$emit('pageloading', false);

                    $root.notification(r.data);

                    if (r.data.success) {
                        refreshGrid();
                    }
                }).catch((e) => {
                    callback();

                    $events.$emit('pageloading', false);
                });
            }
        }

	    function openDetails(row, event) {
		    setPropertyPath(row, 'loadingdetails', true);

		    if (row.hasOwnProperty('rowdetails')) {
			    setPropertyPath(row, 'loadingdetails', false);
			    return;
		    }

		    requestAction('onLoadUserDetails').post($root.currentRouteUrl(route.path), {user_id: row.id}).then((r) => {
			    setPropertyPath(row, 'rowdetails', r.data);
			    setPropertyPath(row, 'loadingdetails', false);
		    });
	    }

	    function closeDetails(row, event) {

	    }

        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,
            confirmDelete,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreateUser,
            handleDelete,
	        openDetails,
	        closeDetails,
        }
    }
}
</script>

<style scoped>

</style>
