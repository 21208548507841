<script>
import {defineComponent, getCurrentInstance, inject, computed} from 'vue'

export default defineComponent({
	name: "LogsDetails",
	props: {
		rowData: {},
		rowIndex: {}
	},
	
	setup(props, context) {
		const inst = getCurrentInstance();
		const $root = inst.root.ctx;
		const $events = inject('$events');
		const $moment = inject('$moment')
		
		const hasDetails = computed(() => {
			return !props.rowData.loadingdetails && !_.isEmpty(props.rowData.rowdetails) && (Array.isArray(props.rowData.rowdetails.changes) && props.rowData.rowdetails.changes.length > 0)
		})
		
		function formatDate(v, format = 'DD.MM.YYYY') {
			return v ? $moment(v).format(format) : null;
		}
		
		return {
			hasDetails,
			formatDate
		}
	}
})
</script>

<template>
	<div class="changes-detail-row" :class="{load: rowData.loadingdetails}">
		<template v-if="hasDetails">
			<div class="date">Änderung am {{ formatDate(rowData.rowdetails.created_at) }}</div>
			<div class="changes">
				<div class="changed-header">
					<div class="fieldname">Feld</div>
					<div class="old_value">Original Wert</div>
					<div class="new_value">Neuer Wert</div>
				</div>
				<template v-for="c in rowData.rowdetails.changes">
					<div class="changed">
						<div class="fieldname">
							<div class="field-label" v-if="c.fieldlabel">{{ c.fieldlabel }}</div>
							<div class="field-name">{{ c.fieldname }}</div>
						</div>
						<div class="old_value"><pre>{{ c.old_value }}</pre></div>
						<div class="new_value"><pre>{{ c.new_value }}</pre></div>
					</div>
				</template>
			</div>
		</template>
		<template v-else>
			<div class="empty">
				<em>keine Änderungsdaten vorhanden</em>
			</div>
		</template>
	</div>
</template>

<style scoped>

</style>
