<template>
	<template v-if="container">
		<div class="badge-placeholder text-right pr-5">
		    <span class="badge badge-dark" :class="classes">
				<template v-if="prefix">
					{{ prefix }}: {{ value }}
				</template>
		        <template v-else>{{ value }}</template>
		    </span>
		</div>
	</template>
	<template v-else>
		<span class="badge badge-dark" :class="classes">
			<template v-if="prefix">
				{{ prefix }}: {{ value }}
			</template>
	        <template v-else>{{ value }}</template>
	    </span>
	</template>
</template>


<script>

export default {
	name: 'organisation-badge',
	props: {
		container: {
			type: Boolean,
			default: false
		},
		prefix: {
			type: String,
			default: 'ID:'
		},
		value: {},
		classes: {}
	}
}

</script>

<style scoped>

</style>
