<script>
import {defineComponent, ref, onBeforeMount, getCurrentInstance, inject, computed, watch} from 'vue'
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";
import {useStore} from "~/vuex";

export default defineComponent({
	name: "logs-history",
	props: {
		...defaultFieldProps,

		logs: {
			type: [Array, Object],
			required: false
		}
	},
	setup(props, context) {
		const inst = getCurrentInstance();
		const $root = inst.root.ctx;
		const $events = inject('$events');
		const $moment = inject('$moment')
		const $http = inject('$http')


        const loadAll = ref(false);

		const store = useStore();
		const {modelvalue} = useFormField(props, context);
		const loading = ref(false);
		const modelLabel = ref(null);
		const allLogs = ref(null);


		const currentActiveTab = computed(() => {
			let atab, tabs;
			if (props.storeModul) {
				atab = store.getters[props.storeModul +'/formTabActive']
				tabs = store.getters[props.storeModul +'/formTabs']
			}
			else {
				atab = store.getters['form/formTabActive']
				tabs = store.getters['form/formTabs']
			}


			if (atab && tabs) {
				return tabs.hasOwnProperty(atab) ? tabs[atab] : null
			}

			return null;
		});


		const primaryKeyName = computed(() => {
			if (props.storeModul) {
				return store.getters[props.storeModul +'/formPrimaryKeyName']
			}
			else {
				return store.getters['form/formPrimaryKeyName']
			}
		});

		const loadParams = computed(() => {
			let params = {
				modelable_type: null,
				modelable_id: null
			};

			if (props.field.hasOwnProperty('model') && props.field.model) {
				params.modelable_type = props.field.model;
			}

			if (props.formModel.hasOwnProperty(primaryKeyName.value) && props.formModel[primaryKeyName.value] > 0) {
				params.modelable_id = props.formModel[primaryKeyName.value];
			}

            if (loadAll.value) {
                params.all = true;
            }
            else {
                params.all = false;
            }

			return params;
		});

		const canShowHistory = computed(() => {
			return (loadParams.value.modelable_id > 0 && loadParams.value.modelable_type) || Array.isArray(allLogs.value);
		});


        watch(() => loadAll.value, () => {
            loadLogs()
        })

		onBeforeMount(() => {
			$events.$on('tab-shown', handleTabShown);

			if (Array.isArray(props.logs)) {
				allLogs.value = props.logs;
			}
			else {
				allLogs.value = Array.isArray(modelvalue.value) ? modelvalue.value : null;
			}
		});

		function handleTabShown() {

			if (!Array.isArray(allLogs.value) /* && currentActiveTab.value && currentActiveTab.value.id === 'log-history' */ ) {
				if (loadParams.value.modelable_id > 0 && loadParams.value.modelable_type) {
					loadLogs()
				}
			}
		}

		function loadLogs() {
			if (loading.value) return;
			loading.value = true;

			$http.post($root.routes('admin.system.logs.load'), loadParams.value).then((r) => {
				allLogs.value = r.data.logs;
				modelLabel.value = r.data.label;
				loading.value = false;
			}).catch((e) => {
				loading.value = false;
			});
		}

		function formatDate(v, format = 'DD.MM.YYYY, HH:mm:ss') {
			return v ? $moment(v).format(format) : null;
		}


		return {
            loadAll,
			canShowHistory,
			loading,
			allLogs,
			modelLabel,
			formatDate
		}
	}
})
</script>

<template>
	<form-section :field="{label: 'Verlauf der Änderungen', class: 'nopadding'}">

		<loading :active="loading === true"
		         color="blue"
		         :width="20"
		         :height="20"
		         :opacity="0.6"
		         :is-full-page="false">
			<template v-slot:after>
				<div class="m">Hole Daten...</div>
			</template>
		</loading>

        <div class="history-buttons">
            <div class="btn-group">
                <button type="button" class="btn btn-default"
                        :class="{'is-active': !loadAll}"
                        :disabled="loading"
                        @click="loadAll = false">
                    zeige die letzten 10 Änderungen
                </button>
                <button type="button" class="btn btn-default"
                        :disabled="loading"
                        :class="{'is-active': loadAll}" @click="loadAll = true">
                    zeige alle Änderungen
                </button>
            </div>
        </div>

        <div v-if="loadAll" class="p-2">
            <div class="alert alert-info"><div><b>Achtung:</b> Es werden Ihnen immer nur die letzen 100 Änderungen angezeigt!</div></div>
        </div>

		<div class="log-history" v-if="canShowHistory && Array.isArray(allLogs) && allLogs.length > 0">
			<template v-for="l in allLogs">
				<div class="log-history-entry" :class="{open: l.open === true }">
					<div class="log-history-head" @click.prevent="l.open = (l.open === true ? false : true)">
						<svg-icon name="chevron-right"/>
						<div>Änderung erfolgte <span v-if="l.user">von {{ l.user.username }}</span> am {{ formatDate(l.change_date) }}</div>
					</div>
					<div class="log-history-details" v-if="l.open === true">
						<table>
							<thead>
								<tr>
									<th class="fieldname">Feld</th>
									<th class="old_value">Original Wert</th>
									<th class="new_value">Neuer Wert</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="r in l.changes">

                                    <template v-if="r.new_value === null && r.old_value === ''"></template>
                                    <tr v-else-if="r.new_value === null && r.old_value !== ''">
                                        <td>
                                            <div class="field-label" v-if="r.fieldlabel">{{ r.fieldlabel }}</div>
                                            <div class="field-name">{{ r.fieldname }}</div>
                                        </td>
                                        <td><pre>{{ r.old_value ? r.old_value : '-' }}</pre></td>
                                        <td><pre>{{ r.new_value ? r.new_value : '-' }}</pre></td>
                                    </tr>
                                    <tr v-else-if="r.new_value !== null && r.old_value !== r.new_value">
                                        <td>
                                            <div class="field-label" v-if="r.fieldlabel">{{ r.fieldlabel }}</div>
                                            <div class="field-name">{{ r.fieldname }}</div>
                                        </td>
                                        <td><pre>{{ r.old_value ? r.old_value : '-' }}</pre></td>
                                        <td><pre>{{ r.new_value ? r.new_value : '-' }}</pre></td>
                                    </tr>
                                    <tr v-else>
                                        <td>
                                            <div class="field-label" v-if="r.fieldlabel">{{ r.fieldlabel }}</div>
                                            <div class="field-name">{{ r.fieldname }}</div>
                                        </td>
                                        <td><pre>{{ r.old_value ? r.old_value : '-' }}</pre></td>
                                        <td><pre>{{ r.new_value ? r.new_value : '-' }}</pre></td>
                                    </tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</div>
		<div class="log-history-empty" v-else>
            <div class="flex flex-row items-center text-info">
                <svg-icon name="info-circle" size="22" class="mr-2"/>
                <em>Es ist noch kein Verlauf vorhanden</em>
            </div>
		</div>
	</form-section>
</template>

<style scoped>

</style>
