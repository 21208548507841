<template>
    <form-field :name="name" :field="field" :key="name" :form-model="formModel" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>
            <div class="spinner-container" :class="{disabled: fieldIsDisabled }">
                <div v-if="!modeUpDown" class="spin-btn-left">
                    <div class="down"
                         @mouseup.prevent="stopSpin"
                         @mousedown.prevent="spin('-')"><i class="fa fa-minus" aria-hidden="true"></i></div>
                </div>
                <div class="input-field">
                    <input :name="name"
                           type="text"
                           v-model="currentValue"
                           class="form-control"
                           :class="field.class"
                           v-bind="fieldAttributes"
                           @keyup.prevent="checkValid"
                           ref="inputspinner">
                </div>
                <div class="spin-btn-right" :class="{'up-down': modeUpDown}">
                    <div v-if="modeUpDown"
                         class="up"
                         @mouseup.prevent="stopSpin"
                         @mousedown.prevent="spin('+')"><i class="fa fa-caret-up" aria-hidden="true"></i></div>
                    <div v-else class="up"
                         @mouseup.prevent="stopSpin"
                         @mousedown.prevent="spin('+')"><i class="fa fa-plus" aria-hidden="true"></i></div>

                    <div v-if="modeUpDown" class="down"
                         @mouseup.prevent="stopSpin"
                         @mousedown.prevent="spin('-')"><i class="fa fa-caret-down" aria-hidden="true"></i></div>
                </div>
            </div>
        </template>


    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "spinner",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const { fieldIsDisabled, modelvalue, fieldAttributes} = useFormField(props, context);

        const mounted = ref(false);
        const currentValue = ref(0);
        let mouseTimer = null;

        const modeUpDown = computed(() => {
            return props.field.mode === 'up-down';
        });

        const defaultValue = computed(() => {
            return props.field.default >= 0 ? props.field.default : null;
        });


        const theFieldAttributes = computed(() => {

            let attr = {
                ...fieldAttributes,
            };

            if (typeof props.field.min === "number") {
                attr.min = props.field.min;
            }
            if (typeof props.field.max === "number") {
                attr.max = props.field.max;
            }
            return attr;
        });


        watch(() => modelvalue.value, (n) => {
            if (n !== currentValue.value) {
                currentValue.value = (_.isNumber(n) ? n : 0);
            }
        });

        onMounted(() => {
            if (props.field.hasOwnProperty('value') && _.isNumber(props.field.value)) {
                currentValue.value = props.field.value;
            } else {
                currentValue.value = (_.isNumber(modelvalue.value) ? modelvalue.value : (_.isNumber(defaultValue.value) ? defaultValue.value : 0));
            }

            mounted.value = true;
        })


        function checkValid(e) {
            if (fieldIsDisabled.value) {
                return;
            }

            clearInterval(mouseTimer);

            // Test negative char only
            if (e.target.value === '-') {
                return;
            }

            let value = parseInt(e.target.value);

            if (typeof props.field.max === "number") {
                if (value > props.field.max) {
                    e.target.value = props.field.max;
                }
            }

            if (typeof props.field.min === "number") {
                if (value < props.field.min) {
                    e.target.value = props.field.min;
                }
            }

            currentValue.value = parseInt(e.target.value);

            if (props.field.returnOnly) {
                context.emit('input', currentValue.value);
                return;
            }


            modelvalue.value = currentValue.value;
        }

        function spin(direction) {
            if (fieldIsDisabled.value) {
                return;
            }
            mouseTimer = setInterval(() => {
                runSpin(direction);
            }, 100);

            runSpin(direction);
        }

        function stopSpin() {
            if (fieldIsDisabled.value) {
                return;
            }
            clearInterval(mouseTimer);
        }

        function runSpin(direction) {
            if (fieldIsDisabled.value) {
                return;
            }

            switch (direction) {
                case '+':
                    if (typeof props.field.max === "number") {
                        if (props.field.max > currentValue.value) {
                            currentValue.value += 1;
                        }
                    } else {
                        currentValue.value += 1;
                    }
                    break;

                case '-':
                    if (typeof props.field.min === "number") {
                        if (currentValue.value > props.field.min) {
                            currentValue.value -= 1;
                        }
                    } else {
                        currentValue.value -= 1;
                    }
                    break;
            }


            if (props.field.returnOnly) {
                context.emit('input', currentValue.value);
                return;
            }

            modelvalue.value = currentValue.value;
        }


        return {
            mounted,
            defaultValue,
            currentValue,
            modelvalue,
            modeUpDown,
            fieldIsDisabled,
            spin,
            stopSpin,
            checkValid,
            fieldAttributes: theFieldAttributes,
        }

    }
}
</script>

<style scoped>

</style>
