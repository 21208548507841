<template>
    <div :class="classAttr"
         @click="(e) => $emit('click', e)"
         @mousedown="(e) => $emit('mousedown', e)"
         @mouseup="(e) => $emit('mouseup', e)"
         @mouseleave="(e) => $emit('mouseleave', e)"
         @mouseenter="(e) => $emit('mouseenter', e)">
        <template v-if="hasDefinedIcon && !useSprite">
            <svg viewBox="0 0 24 24"
                :width="size"
                :height="size"
                :stroke-width="strokeWidth"
                 fill="none"
                class="tabler-icon"
                role="img"
                aria-hidden="true"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke="currentColor"
                 preserveAspectRatio="xMidYMid meet"
                v-html="getDefinedIcon">
            </svg>
        </template>
        <template v-else-if="tablerIcon && useSprite">
            <svg :width="size"
                 :height="size"
                 :stroke-width="strokeWidth"
                 class="tabler-icon"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 xmlns="http://www.w3.org/2000/svg">
                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                     :href="spriteUrl+'#'+tablerIcon"
                     :xlink-href="spriteUrl+'#'+tablerIcon"
                     :stroke-width="strokeWidth"/>
            </svg>
        </template>
        <template v-else><span :title="'Icon '+ tablerIcon + ' not found!'"></span></template>
    </div>
</template>

<script>
import {computed, getCurrentInstance} from "vue";
const IconsJson = require('../data/tabler.json');

export default {
    name: "svg-icon",
    emits: ['click', 'mousedown', 'mouseup', 'mouseleave', 'mouseenter'],
    props: {
        name: String,
        type: {
            type: String,
            default() {
                return 'svg';
            }
        },
        class: {
            type: String,
            default() {
                return '';
            }
        },
        tooltip: {
            type: String,
            default() {
                return '';
            }
        },
        mode: {
            type: String,
            default() {
                return 'tabler'; // tabler or fa
            }
        },
        attributes: {
            type: Object,
            default() {
                return {};
            }
        },
        size: {
            type: [String, Number],
            default: '16',
        },
        strokeWidth: {
            type: [String, Number],
            default: '1.5',
        },
        stroke: {
            type: String,
            default: '',
        },
        wrap: {
            type: Boolean,
            default: false,
        },
        spriteUrl: {
            type: String,
            default: 'img/svg/tabler-sprite.svg',
        },
        useSprite: {
            type: Boolean,
            default: false,
        }
    },

    setup(props, context) {
        const inst = getCurrentInstance();

        const classAttr = computed(() => {
            return props.class ? 'icon-wrap ' + props.class : 'icon-wrap';
        });

        const _tablerClean = computed(() => {
            if (!props.name) return null;

	        let clean = props.name;

            return clean.replace(/^fa-?\s*/g, '').toLowerCase();
        });

        const tablerIcon = computed(() => {
            return 'tabler-' + _tablerClean.value;
        });

        const hasDefinedIcon = computed(() => {
            return IconsJson.hasOwnProperty(_tablerClean.value);
        });

        const getDefinedIcon = computed(() => {
            if (!props.useSprite) {
                return hasDefinedIcon.value ? IconsJson[_tablerClean.value] : '';
            }
            return null;
        });

        return {
            classAttr,
            tablerIcon,
            tablerClean: _tablerClean,
            hasDefinedIcon,
            getDefinedIcon,
        };
    }
}

</script>
<style scoped>

</style>
