<template>
	<div class="tab-content">
		<div v-for="tab in tabs"
			 class="tab-pane"
			 :class="{ 'active show': activeTab === tab.id }">
			<slot :name="'tab_'+tab.id"/>
		</div>
	</div>
</template>

<script>
export default {
	name: "TabsPane",
	props: {
		tabs: {
			type: Array,
			required: true
		},
		activeTab: {}
	}

}
</script>

<style scoped>

</style>
