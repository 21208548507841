<template>
    <div class="toolbar" v-if="hasPreview">
        <div class="from">
            von&nbsp;
            <template v-if="preview.fromuser && preview.fromuser.people">
                <a href="#"
                   @click.prevent="showQuickView = true">
                    {{ preview.fromuser.people.first_name }} {{ preview.fromuser.people.surname }}
                </a>
            </template>
            <template v-else>
                <router-link :to="{name: 'admin.users.edit', params: {user: preview.fromuser.id }}">
                    {{ preview.fromuser.username }}
                </router-link>
            </template>
            &nbsp; am {{ preview.date }}
        </div>
        <div class="btn-container">

            <div v-if="hasPreview && preview.editable">
                <div class="btn-group">
                    <button class="btn btn-primary" type="button"
                            v-if="preview.status != 3"
                            @click.prevent="replyMessage('view')">
                        Antworten
                    </button>

                    <button class="btn btn-primary" type="button"
                            v-if="preview.status != 3"
                            @click.prevent="replyMessage('viewall')">
                        Allen Antworten
                    </button>

                    <button class="btn btn-primary" type="button"
                            v-if="preview.status < 1"
                            @click.prevent="markState(1)">Als gelesen markierne</button>
                    <button class="btn btn-primary" type="button"
                            v-if="preview.status == 1"
                            @click.prevent="markState(0)">Als ungelesen markierne</button>

                </div>
                <button class="btn btn-primary close-btn" type="button"
                        v-if="preview.status != 3"
                        @click.prevent="markState(3)">Abschließen</button>
                <button class="btn btn-primary close-btn" type="button"
                        v-if="preview.status == 3"
                        @click.prevent="markState(1)">Zurückholen</button>
            </div>

        </div>
    </div>
    <template v-if="hasPreview && preview.attachments && preview.attachments.length">
        <div class="attachments">
            <ul>
                <template v-for="a in preview.attachments">
                    <li>
                        <div @click.prevent="download(a)">
                            <div>{{ a.filename }}</div>
                            <div>
                                <span class="badge badge-dark">{{ formatFilesize(a.size) }}</span>
                            </div>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </template>
    <div class="message-content">
        <template v-if="hasPreview">
            <div class="subject" v-html="makeHighlightSearch(preview.subject, true)"></div>
            <div class="message">
                <div v-html="mutatedContent" ref="message"></div>
            </div>
        </template>
    </div>


    <template v-if="hasPreview">
        <message-view-modal
            :show="showReplay"
            :current-message="preview"
            :reply-type="replyType"
            @close="closeReplyMessage"
            @success="successReplyMessage"
        ></message-view-modal>
    </template>
</template>

<script>

import {computed, getCurrentInstance, ref, onBeforeMount, onMounted, nextTick, inject} from "vue";
const prismLangs = [
    'markup',
    'markup-templating',
    'css',
    'clike',
    'javascript',
    'bash',
    'php',
    'sql',
    'xml',
    'markdown',
];
import marked from 'marked';
// const md = require('markdown-it')({
//     html: true,
//     linkify: true,
//     breaks: true,
//     typographer: true,
//     quotes: '“”‘’'
// });

import Prism from "prism-es6";
import '@/directives/PrismLineNumbers';

prismLangs.forEach((language) => {
    if (!Prism.languages[language]) {
        require(`prism-es6/components/prism-${language}`)
    }
})


export default {
    name: "messenger-sidebar",
    components: {
        'message-view-modal': require('./ViewMessageModal').default
    },
    emits: ['submitted', 'reload'],
    props: {
        preview: {},
    },

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const mutatedContent = ref('');
        const showQuickView = ref(false);
        const $moment = inject('$moment')
        const $http = inject('$http');
        const isMounted = ref(false)
        const showReplay = ref(false)
        const replyType = ref('view')

        const hasPreview = computed(() => {
            if (!isMounted.value) return false;
            return _.isObject(props.preview) && props.preview.hasOwnProperty('subject')
        });

        const messenger = computed(() => {
            return inst.parent;
        });

        const isFiltered = computed(() => {
            if (!messenger.value) return false;

            let filter = messenger.value.refs.toolbar.getGridFilter();
            if (!_.isString(filter.q) || !filter.q) {
                return false;
            } else if (filter.q) {
                return true;
            }

            return false;
        });


        onBeforeMount(() => {
            _.each(prismLangs, (language) => {
                if (!Prism.languages[language]) {
                    require(`prism-es6/components/prism-${language}`)
                }
            })
        });


        onMounted(() => {
            prepareContent(props.preview.content);


            nextTick(() => {
                isMounted.value = true;
            })
        });

        function markState(newstate) {
            if (props.preview) {
                $http.post($root.routes('admin.messager.change_state'), {id: props.preview.id, state: newstate}).then((r) => {
                    props.preview.status = newstate;
                    context.emit('reload')
                });
            }
        }

        function renderDate(value) {
            return value ? $moment(value).format('DD.MM.YYYY, HH:mm') : '';
        }

        function prepareContent(content) {
            if (content) {

                content = content.replace(/(\n{1,}\s*---{3,}\s*\n{1,})/g, "\n\n$1\n\n");
                content = "\n "+content+" \n";

                mutatedContent.value = isFiltered.value ? makeHighlightSearch(content) : formatMessage(content);

                nextTick(() => {
                    nextTick(() => {
                        nextTick(() => {
                            if (inst.refs.message) {

                                inst.refs.message.querySelectorAll('pre').forEach(el => {
                                    if (!el.classList.contains('line-numbers')) {
                                        el.classList.add('line-numbers')
                                    }
                                });

                                inst.refs.message.querySelectorAll('a').forEach(el => {
                                    if (!el.getAttribute('target')) {
                                        el.setAttribute('target', '_blank')
                                    }
                                });

                                nextTick(() => {
                                    Prism.highlightAllUnder(inst.refs.message);
                                });
                            }
                        })
                    })
                })

            }
        }

        function formatMessage(text) {
            text = _.isString(text) ? text : '';
            // return md.render(text);
            return marked.parse(text, {headerIds: false, mangle: false, xhtml: true});
        }

        function makeHighlightSearch(text, withoutMarkdown) {
            if (!isFiltered.value) {
                return text;
            }

            let filter = messenger.value.refs.toolbar.getGridFilter();

            if (!withoutMarkdown) {
                // text = md.render(text);
                text = marked.parse(text, {headerIds: false, mangle: true, xhtml: true});
            }
            if (!_.isString(filter.q) || !filter.q) {
                return text;
            }

            return text.replace(new RegExp(filter.q.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi"), (match) => `<mark>${match}</mark>`);
        }

        function download(attachment) {
            if (attachment.download_url) {
                window.open(attachment.download_url);
            }
        }

        function replyMessage(type) {

            replyType.value = type;
            showReplay.value = true;
            // $router.push({name: 'admin.messenger.view', params: {id: preview.id} })
            // $router.push({name: 'admin.messenger.viewall', params: {id: preview.id} })
        }

        function closeReplyMessage() {
            showReplay.value = false;

        }

        function successReplyMessage() {
            showReplay.value = false;

            nextTick(() => {

                isMounted.value = false;

                nextTick(() => {
                    context.emit('submitted');
                })
            })
        }


        return {
            isMounted,
            mutatedContent,
            hasPreview,
            isFiltered,
            showQuickView,
            showReplay,
            replyType,
            markState,
            renderDate,
            makeHighlightSearch,
            download,
            replyMessage,
            closeReplyMessage,
            successReplyMessage,
            formatFilesize: inject('formatFilesize')
        }
    }
}
</script>

<style scoped>

</style>
