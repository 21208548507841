import aminoapiIndex from './components/Index.vue';


export default [
    {
        path: '/aminoapi',
        component: aminoapiIndex,
        name: 'admin.aminoapi',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
];