<script>
import {computed, defineComponent, getCurrentInstance, inject, onMounted, ref} from 'vue'
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getHtmlCsrfToken} from "@/utils/auth";
import store from "@/store";

export default defineComponent({
    name: "VerifyEmail",
    setup() {
        const inst = getCurrentInstance();
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const $http = inject('$http');
        const appname = inject('appname');
        const version = inject('version');
        const notification = inject('notification');



        const model = ref({
            hash: null
        });

        if (window.options.verifyEmail) {
            model.value.hash = window.options.verifyEmail;
        }

        const canSend = computed(() => {
            return model.value.hash !== null
        });

        const isLoggedIn = computed(() => {
            return store.getters['user/isLoggedIn']
        });

        const successSend = ref(null);
        const errors = ref(null);
        const loading = ref(false);



        onMounted(() => {
            if (inst.refs.token_field) {
                inst.refs.token_field.value = getHtmlCsrfToken();
            }
        })

        function sendVerify() {

            if (loading.value) {
                return;
            }

            loading.value = true;
            errors.value = null;

            $http.post(inst.root.ctx.routes('admin.account.email.verify-email', {hash: model.value.hash}), model.value).then((r) => {

                loading.value = false;

                if (r.data.success)
                {
                    if (r.data.email_success) {

                        if (r.data.email_success && _.isObject(r.data.email_success) && r.data.email_success.hasOwnProperty('username')) {
                            store.dispatch('user/setUser', r.data.email_success)
                        }
                        else {
                            store.dispatch('user/setUser', {id: 0})
                        }

                        successSend.value = r.data.email_success;
                    }
                }
                else {

                    if (r.data.errors) {
                        errors.value = r.data.errors
                    }

                    notification(r.data);
                }
            }).catch(() => {
                loading.value = false;
            });
        }

        function toLogin() {
            if (typeof successSend.value === 'object' &&
                successSend.value &&
                successSend.value.hasOwnProperty('id') &&
                successSend.value.id > 0 && successSend.value.id === store.getters.user.id

            ) {
                router.push({name: 'admin.dashboard'})
            }
            else if (successSend.value === true) {
                router.push({name: 'auth'})
            }
            else {
                router.push({name: 'auth'})
            }
        }

        return {
            loading,
            errors,
            model,
            successSend,
            canSend,
            sendVerify,
            toLogin,
            isLoggedIn,
            appname,
            version,

        }
    }
})
</script>

<template>
    <div class="auth-container">
        <div class="auth-form-container">
            <div class="auth-form" v-if="model.hash === '0'">
                <b>Fehler</b>
                <div class="alert alert-danger mt-8">
                    <p>Huch entweder ist die E-Mail Verifizierung abgelaufen oder diese wurde schon genutzt um Ihre E-Mail Adresse zu verifizieren!</p>
                </div>
                <div class="btns mt-8">
                    <button type="button"
                            class="btn btn-primary"
                            @click.prevent="toLogin">
                        Zurück zum Login
                    </button>
                </div>
                <div class="end mt-5 text-small">{{ appname }} {{ version }}</div>
            </div>
            <div class="auth-form" v-else-if="model.hash">
                <b>E-Mail Verifizierung</b>

                <template v-if="successSend === true">
                    <div class="alert alert-info mt-8">
                        Ihre E-Mail Verifizierung wurde erfolgreich durchgeführt.
                    </div>

                    <div class="btns mt-8">
                        <button type="button"
                                class="btn btn-primary"
                                @click.prevent="toLogin">
                            Zum Login
                        </button>
                    </div>

                </template>
                <template v-else-if="typeof successSend === 'object' && successSend && successSend.hasOwnProperty('username') && successSend.id > 0">
                    <div class="alert alert-info mt-8">
                        Ihre E-Mail Verifizierung wurde erfolgreich durchgeführt.
                    </div>
                    <div class="btns mt-8">
                        <button type="button"
                                class="btn btn-primary"
                                @click.prevent="toLogin">
                            <template v-if="isLoggedIn">
                                Zum Dashboard
                            </template>
                            <template v-else>
                                Zurück zum Login
                            </template>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="alert alert-info mt-8">
                        Ihre E-Mail Verifizierung wurde erfolgreich durchgeführt.
                    </div>
                    <div class="btns mt-8">
                        <button type="button"
                                class="btn btn-primary"
                                @click.prevent="toLogin">
                            Zum Login
                        </button>
                    </div>
                </template>

                <div class="end mt-5 text-small">{{ appname }} {{ version }}</div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
