import { useTippy, plugin as Tippy} from '~/vue-tippy';

const defaultTippyTooltipOptions = {
    trigger: 'mouseenter focus',
    arrow: true,
    inertia: true,
    interactive: false,
    animation: 'shift-toward',
    animateFill: false,
    theme: 'dark',
    appendTo: () => document.body,
    allowHTML: true,
    delay: [100, 0],
    duration: [300, 0],
    popperOptions: {
        strategy: 'fixed',
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['bottom', 'right'],
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    tether: false,
                    boundary: 'document',
                },
            },
        ]
    }
};

Vue.use(Tippy);




export const VTooltip = {

    created(el, binding, vnode, prevVnode) {

        if (el.created) {
            return;
        }

        el.created = true;
        let opts = {
            content: binding.value ? binding.value : '',
            theme: 'dark',
            arrow: true,
            placement: 'top'
        };

        if (typeof opts === 'object') {
            opts = Object.assign(defaultTippyTooltipOptions, opts);
        }

        if (binding.modifiers) {
            if (binding.modifiers.bottom) {
                opts.placement = 'bottom';
            }
            else if ( binding.modifiers.bottomEnd ) {
                opts.placement = 'bottom-end';
            }
            else if ( binding.modifiers.bottomStart ) {
                opts.placement = 'bottom-start';
            }

            else if (binding.modifiers.top) {
                opts.placement = 'top';
            }
            else if ( binding.modifiers.topEnd ) {
                opts.placement = 'top-end';
            }
            else if ( binding.modifiers.topStart ) {
                opts.placement = 'top-start';
            }


            else if (binding.modifiers.left) {
                opts.placement = 'left';
            }
            else if ( binding.modifiers.leftEnd ) {
                opts.placement = 'left-end';
            }
            else if ( binding.modifiers.leftStart ) {
                opts.placement = 'left-start';
            }
            else if (binding.modifiers.right) {
                opts.placement = 'right';
            }
            else if ( binding.modifiers.rightEnd ) {
                opts.placement = 'right-end';
            }
            else if ( binding.modifiers.rightStart ) {
                opts.placement = 'right-start';
            }
        }
        let r = useTippy(el, opts);
        // r.show();
    },


    mounted(el, binding, vnode) {
        if (el.mounted) {
            return;
        }
        el.mounted = true;
    },

    beforeUnmount(el) {
        if (el.$tippy) {
            // el.$tippy.destroy();
        } else if (el._tippy) {
            // el._tippy.destroy();
        }
    },
    updated(el, binding) {
        const opts = typeof binding.value === "string" ? { content: binding.value } : binding.value || {};
        // const opts = {content: binding.value};

        if (el.getAttribute('title') && !opts.content) {
            opts.content = el.getAttribute('title');
            el.removeAttribute('title');
        }

        if (el.getAttribute('content') && !opts.content) {
            opts.content = el.getAttribute('content');
        }

        if (el.$tippy) {
            el.$tippy.setProps(opts || {});
        } else if (el._tippy) {
            el._tippy.setProps(opts || {});
        }
    },
};

Vue.directive('tooltip', VTooltip);






import Loading from './loading/overlay';
import './loading/overlay/css/index.css';
Vue.component('loading', Loading);

import "~/velocity-animate/velocity.js";
import Notify from './notification'
Vue.use(Notify, {
    velocity: (el, animation, o) => {
        if ( Velocity) {
            Velocity(el, animation,o);
        }
    }
});




Vue.component('apexchart', require("~/vue3-apexcharts").default );



Vue.component('svg-icon', require("./svg-icon.vue").default);
Vue.component('navigation', require('./Nav/Navigation.vue').default);
Vue.component('sidebar-navigation', require('./Nav/SidebarNav.vue').default);
Vue.component('toolbar', require("./Toolbar/toolbar").default);


Vue.component('save-button', require("./Buttons/save-button.vue").default);
Vue.component('action-button', require("./Buttons/actionbutton.vue").default);
Vue.component('simple-pagination', require("./simple-pagination.vue").default);


Vue.component('created-updated-by-at', require('./created-updated-by-at.vue').default);

Vue.component('loading', require('./loading/overlay').default);
Vue.component('form-errors', require('./ValidationErrors.vue').default);
Vue.component('validation-errors', require("./ValidationErrors").default);




import VueNestable from './nestable/Nestable.vue';
import VueNestableHandle from './nestable/NestableHandle.vue';
import VueNestableExpandable from './nestable/NestableExpandable.vue';


Vue.component('vue-nestable-expandable', VueNestableExpandable);
Vue.component('vue-nestable-handle', VueNestableHandle);
Vue.component('vue-nestable', VueNestable);

Vue.component('v-select', require('./v-select/index.js').default );



import vuedraggable from '~/vuedraggable'
Vue.component('draggable', vuedraggable);


Vue.component('dropdown-button', require('./dropdown-button.vue').default );



Vue.component('memo-button', require('./memo/memo-button.vue').default );
Vue.component('memo-popup', require('./memo/memo-popup.vue').default );

Vue.component('popover', require('./popover/popover.vue').default );


Vue.component('badge', require('./badges/badge.vue').default);
Vue.component('organisation-badge', require('./badges/organisation-badge.vue').default);
Vue.component('people-badge', require('./badges/people-badge.vue').default);
Vue.component('webuser-badge', require('./badges/webuser-badge.vue').default);



Vue.component("vuetable", require('./DataTable/DataTable.vue').default);
Vue.component("vuetable-pagination", require('./DataTable/DataTablePagination.vue').default);
Vue.component("vuetable-pagination-info", require('./DataTable/DataTablePaginationInfo.vue').default);

Vue.component("data-table", require('./DataTable/GridTable.vue').default);
Vue.component('data-table-columns-selector', require('./DataTable/DataTableColumnsSelector.vue').default)
Vue.component('grid-table-actions', require('./DataTable/GridTableActions.vue').default);

Vue.component('tree-table', require("./TreeTable/TreeTable").default);
Vue.component('tree-table-rows', require("./TreeTable/TreeTableRow").default);
Vue.component('tree-view', require("./Tree/TreeView").default);
Vue.component('tree-item', require("./Tree/TreeItem").default);

Vue.component('tabs', require("./Tabs/Tabs").default);
Vue.component('tabs-pane', require("./Tabs/TabsPane").default);




Vue.component('modal-form-content', require("./modal-form-content").default);
Vue.component('modal-form-tabs', require("./modal-form-tabs").default);
Vue.component('modal', require("./modal-stack").default);
Vue.component('confirm', require("./confirm").default);


// Form Components

Vue.component('form-layout-tabs', require('./form-tabs').default );
Vue.component('form-layout-content', require('./form-content').default );


Vue.component('form-container', require("./forms/formrenderer").default);
Vue.component('form-fields', require("./forms/form-fields").default);
Vue.component('form-columns', require("./forms/columns").default);
Vue.component('form-column', require("./forms/column").default);
Vue.component('form-section', require("./forms/section").default);
Vue.component('form-fileselect', require("./forms/fileselect").default);
Vue.component('form-memofield', require("./forms/memofield-section").default);
Vue.component('form-div', require("./forms/div").default);
Vue.component('form-markdown', require("./forms/markdown").default);
Vue.component('form-upload', require("./forms/upload").default);
Vue.component('form-spinner', require("./forms/spinner").default);

Vue.component('form-field', require("./forms/field").default);
Vue.component('form-input', require("./forms/input").default);
Vue.component('form-select', require("./forms/select").default);
Vue.component('form-text', require("./forms/text").default);
Vue.component('form-textarea', require("./forms/textarea").default);
Vue.component('form-date', require("./forms/date").default);
Vue.component('form-password', require("./forms/password").default);
Vue.component('form-number', require("./forms/number").default);
Vue.component('form-email', require("./forms/email").default);

Vue.component('form-checkbox', require("./forms/checkbox").default);
Vue.component('form-checkboxfield', require("./forms/checkbox-field").default);
Vue.component('form-checkboxlist', require("./forms/checkbox-list").default);

Vue.component('form-radio', require("./forms/radio").default);
Vue.component('form-radiofield', require("./forms/radio-field.vue").default);
Vue.component('form-radiolist', require("./forms/radio-list").default);
Vue.component('form-button', require('./forms/button').default);
Vue.component('form-switch', require('./forms/switch').default);

Vue.component('form-organisationselect', require("./forms/OrganisationSelect").default);
Vue.component('form-peopleselect', require("./forms/PeopleSelect").default);
Vue.component('form-userselect', require("./forms/UserSelect").default);

Vue.component('form-select-institutions', require("./forms/select-institutions").default);
Vue.component('form-select-newspaper', require("./forms/select-newspaper.vue").default);

Vue.component('form-logs-history', require("./forms/logs-history.vue").default);
Vue.component('form-subjects-select', require("./forms/subjects-select.vue").default);

//
Vue.component('field-label', require("./field-label").default);
Vue.component('field', require("./field").default);


// require('neloh-ui/components');



