<template>
    <div class="lock-screen">
        <div class="locked-container">
            <div class="head">
                Bildschirm entsperren
            </div>
            <div class="content">

                <loading :active="loading === true"
                         color="blue"
                         :width="40"
                         :height="40"
                         :opacity="0.6"
                         :is-full-page="false">
                </loading>

                <form :name="pwFieldName +'_f'" @submit.prevent="unlock" autocomplete="off">
                    <div v-if="warning" class="error">Uuups... Das hat leider nicht geklappt.</div>

                    <div class="input-group mb-10">
                        <input class="form-control"
                               :value="$store.getters['user/username']"
                               disabled>
                        <div class="input-group-append">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                    </div>

                    <div class="input-group">
                        <input class="form-control"
                               :name="pwFieldName"
                               v-model="password"
                               type="password"
                               @focus="handleAutocomplete"
                               autocomplete="new-password"
                               placeholder="Zum entsperren bitte Ihr Passwort eingeben">
                        <div class="input-group-append">
                            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-block mt-15" @click="unlock">Entsperren</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {getCurrentInstance, ref, computed, inject, onBeforeMount} from "vue";
import {useStore} from "~/vuex";
import {useRouter} from "~/vue-router";

let pwCount = 1;
export default {
    name: "locked-screen",
    setup(props, context) {
        const inst = getCurrentInstance();
        const $http = inject('$http')
        const $ls = inject('$ls');
        const store = useStore();
        const router = useRouter();

        const password = ref('');
        const warning = ref(false);
        const loading = ref(false);

        const pwFieldName = computed(() => {
            return 'pw_' + (new Date).getTime();
        });

        onBeforeMount(() => {
            if (store.getters['user/isLoggedIn']) {
                router.push({name: 'admin.dashboard'})
            }
        })


        function handleAutocomplete(evt) {
            evt.target.setAttribute("autocomplete", "disabled");
        }

        function unlock() {

            if (loading.value) return;

            warning.value = false;
            loading.value = true;

            $http.post(inst.root.ctx.routes('admin.unlock'), {password: password.value}).then(r => {
                if (r.data.success === true) {

                    if (r.data.hasOwnProperty('token')) {
                        inst.root.ctx.token = r.data.token;
                    }

                    store.dispatch('user/setUser', r.data.user);
                    store.dispatch('ui/setLockscreen', false);
                    $ls.remove('ls');
                    // inst.root.ctx.locked = false;

                    router.push({name: 'admin.dashboard'})



                } else {

                    if (r.data.hasOwnProperty('relogin')) {
                        window.location.href = inst.root.ctx.backendUrl;
                    }

                    warning.value = true;
                }

                loading.value = false;

            }).catch(e => {
                loading.value = false;

                warning.value = true;

                console.warn(e);
            });
        }

        return {
            password,
            loading,
            warning,
            pwFieldName,
            handleAutocomplete,
            unlock
        }

    },

}
</script>

<style scoped>

</style>
