<template>
    <form-field :name="name" :field="field" :key="name"
                :validate="validate"
                :form-model="formModel"
                :responseerrors="responseerrors">

        <template v-slot:field>
            <div v-if="isAutocomplete || field.vselect"
                 class="select-autocomplete"
                 :class="{multiple: isMultiple}"
                 :data-name="name"
            >
                <v-select
                    :get-option-label="getOptionLabel"
                    :get-option-key="getOptionKey"
                    :model-value="modelvalue"
                    :options="selectOptions"
                    :filterable="false"
                    :disabled="fieldIsDisabled === true"
                    @search="onSearch"
                    @input="handleAutocompleteChanged"
                    :multiple="isMultiple"
                    :class="{multiple: isMultiple}"
                    append-to-body>

                    <template v-slot:no-options>
                        <slot name="no-options">bitte Suchbegriff eingeben...</slot>
                    </template>

                    <template v-slot:selected-option="option">
                        <slot name="selected-option" :option="option" v-bind="option">
                            <div v-tooltip="option.surname + (option.first_name ? ' '+ option.first_name : '')">
                                <div>{{ option.surname }} {{ option.first_name }}</div>
                                <small v-if="option.street || option.zip || option.city">
                                    <span v-if="option.street">{{ option.street }} {{ option.street_nr }}</span>
                                    <span v-if="option.zip || option.city"> | {{ option.zip }} {{ option.city }}</span>
                                </small>
                            </div>
                        </slot>
                    </template>

                    <template v-slot:option="option">
                        <slot name="option"
                              :option="option"
                              v-bind="option">
                            <div>
                                <div>{{ option.surname }} {{ option.first_name }}</div>
                                <small v-if="option.street || option.zip || option.city">
                                    <span v-if="option.street">{{ option.street }} {{ option.street_nr }}</span>
                                    <span v-if="option.zip || option.city"> | {{ option.zip }} {{ option.city }}</span>
                                </small>
                            </div>
                        </slot>
                    </template>
                </v-select>
            </div>
            <template v-else>
                <div :class="[selectWrapClasses, {disabled: fieldIsDisabled === true }]" v-if="!isMultiple">

                    <select ref="formfield"
                            :name="name"
                            class="form-control"
                            v-model="modelvalue"
                            v-bind="fieldAttributes"
                            @input="handleChanged">

                        <template v-for="r in selectOptions">
                            <optgroup v-if="r.children && r.children.length > 0" :label="r.label">
                                <template v-for="c in r.children">
                                    <option :value="c.value" :disabled="r.disabled === true" v-html="c.label"></option>
                                </template>

                            </optgroup>
                            <option :value="r.value" :disabled="r.disabled === true" v-html="r.label"></option>
                        </template>
                    </select>
	                <svg-icon name="chevron-down"/>

                </div>
                <template v-else>

                    <div class="multi-select checkboxed" :class="{disabled: fieldIsDisabled === true }" :style="sizeStyle">
                        <template v-for="r in selectOptions">
                            <div class="checkbox-list">
                                <div class="checkbox-field checkbox">
                                    <div class="checkable-wrapper">
                                        <input ref="formfield"
                                               type="checkbox"
                                               :name="name +'[]'"
                                               :value="r.value"
                                               :checked="isChecked(r.value)"
                                               @change="handleChanged"
                                               :id="fieldId(r.value)"/>
	                                    <svg width="16"
	                                         height="16"
	                                         viewBox="0 0 24 24"
	                                         stroke-linecap="round"
	                                         stroke-linejoin="round"
	                                         xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
		                                    <path d="M5 12l5 5l10 -10"></path>
	                                    </svg>
                                    </div>
                                </div>
                                <div class="checkbox-field-label">
                                    <label :for="fieldId(r.value)" v-html="r.label"></label>
                                </div>
                            </div>
                        </template>

                    </div>
                </template>
            </template>
        </template>


    </form-field>
</template>

<script>
import {computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "~/vue"
import {useFormField, defaultFieldProps} from "./../../mixins/use-form-field";

export default {
    name: "form-select",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const {allowMultiple, fieldIsDisabled, modelName, defaultAttributes, modelvalue, fieldAttributes} = useFormField(props, context);


        const $http = inject('$http');
        const $events = inject('$events')
        const getPropertyPath = inject('getPropertyPath');
        const setPropertyPath = inject('setPropertyPath');
	    const transformKey = inject('transformKey')
        const isMounted = ref(false)
        const units = ref([])
        const fieldOptions = ref([])
        const searchInput = ref('')
        const currentSelected = ref(null);
        const cancel = ref(null)

        watch(() => allowMultiple.value, (n, o) => {
            if (n) {
                if (!Array.isArray(modelvalue.value)) {
                    modelvalue.value = [toRaw(modelvalue.value)];
                    currentSelected.value = modelvalue.value
                }
            }
            else {
                if (Array.isArray(modelvalue.value)) {
                    modelvalue.value = modelvalue.value[0];
                    currentSelected.value = modelvalue.value
                }
            }
        });

        watch(() => props.field.options, (n, o) => {
            if (n && n !== o) {
                nextTick(() => {
                    fieldOptions.value = transformFieldOptions();
                })
            }
        }, {deep: true});


        const selectWrapClasses = computed(() => {
            let classes = ['select-wrap'];
            if (fieldAttributes.value && fieldAttributes.value.hasOwnProperty('class')) {
                classes.push(fieldAttributes.value.class);
            }

            return classes;
        });

        const sizeStyle = computed(() => {
            if (!isMounted.value) return {};

            if (!fieldAttributes.value.hasOwnProperty('size')) return {};


            return {height: fieldAttributes.value.size * 37 + 'px', overflow: 'auto'};
        });

        const isAutocomplete = computed(() => {
            return props.field.hasOwnProperty('autocomplete') && props.field.autocomplete
        });

        const autocompleteUrl = computed(() => {

            if (props.field.hasOwnProperty('route')) {
                return app.routes(props.field.route);
            } else if (props.field.hasOwnProperty('url')) {
                return props.field.url;
            }
        });

        const isMultiple = computed(() => {
            return (props.field.hasOwnProperty('multiple') && props.field.multiple) || allowMultiple.value
        });
        const hasTriggerOptions = computed(() => {
            return props.field.hasOwnProperty('trigger_options');
        });

        const selectOptions = computed(() => {
            return fieldOptions.value
        });


        onBeforeUnmount(() => {
            fieldOptions.value = [];
            currentSelected.value = null
        });

        onBeforeMount(() => {
            fieldOptions.value = transformFieldOptions();

            if (isMultiple.value) {
                if (!_.isArray(modelvalue.value)) {
                    modelvalue.value = [];
                }

                currentSelected.value = _.cloneDeep(modelvalue.value);
                defaultAttributes.value = {
                    size: 6
                };
            } else {
                // Wenn wert ein array ist aber keine Mehrfachauswahl erlaubt ist dann den ersten wert
                // im array übernehmen
                if (_.isArray(modelvalue.value)) {
                    modelvalue.value = _.cloneDeep(modelvalue.value).pop();
                }
                currentSelected.value = _.cloneDeep(modelvalue.value);
            }
        });


        onMounted(() => {
            isMounted.value = true;
        });


        function getOptionLabel(option) {

            if (_.isFunction(props.field.labelOption)) {
                return props.field.labelOption(option);
            }


            let label = '';
            if (typeof option === 'object') {

                if (option.hasOwnProperty('name1') || option.hasOwnProperty('name2')) {
                    if (option.hasOwnProperty('name1')) {
                        label += option.name1
                    }
                    if (option.hasOwnProperty('name2')) {
                        label += option.name2
                    }
                } else if (option.hasOwnProperty('display_name')) {
                    label += option.display_name
                } else if (option.hasOwnProperty('label')) {
                    label += option.label
                }

            }
            return '';
        }

        function getOptionKey(option) {
            if (typeof option !== 'object') {
                return console.warn('getOptionKey', option)
            }

            try {
                if (option.hasOwnProperty('id')) {
                    return option.id
                } else if (option.hasOwnProperty('value')) {
                    return option.value
                }
            } catch (e) {
                const warning =
                    `[vue-select warn]: Could not stringify this option ` +
                    `to generate unique key. Please provide'getOptionKey' prop ` +
                    `to return a unique key for each option.\n` +
                    'https://vue-select.org/api/props.html#getoptionkey'
                return console.warn(warning, option, e)
            }
        }

        function fieldId(value) {
            return transformKey(props.name).replace(/\./g, '-') + '_' + value;
        }


        function transformFieldOptions() {

            let options = [];
            _.each(props.field.options, (label, value) => {

                if (label.hasOwnProperty('label') && label.hasOwnProperty('value')) {
                    options.push(label);
                } else {
                    if ((_.isString(value) && value.match(/^\d{1,}/g)) || _.isNumber(value)) {

                        options.push({label: label, value: parseInt(value)});
                    } else {
                        options.push({label: label, value: value});
                    }
                }
            });

            return options;
        }


        let t;

        function onSearch(search, loading) {

            clearTimeout(t)

            if (cancel.value) {
                cancel.value.cancel();
                cancel.value = null;
                loading(false);
            }

            if (!search) {
                return;
            }

            t = setTimeout(() => {
                callSearch(loading, search)
            }, 300);

        }

        function callSearch(loading, search) {


            loading(true);

            const axiosSource = axios.CancelToken.source();
            cancel.value = {cancel: axiosSource.cancel, msg: "Loading..."};


            $http.post(autocompleteUrl.value, {q: search}, {cancelToken: axiosSource.token})
                .then(r => {
                    if (r.data) {
                        fieldOptions.value = r.data;
                    }

                    loading(false);
                }).catch(e => {
                loading(false);
            });

        }


        function transformAutocomplete(result) {

        }

        function isChecked(value) {
            let model = (props.field.usemodel ? props.field.usemodel : props.formModel);
            let modelValue = getPropertyPath(model, modelName.value);
            if (modelValue === null || typeof modelValue == "undefined") {
                return false;
            }


            if (!_.isArray(modelValue)) {
                return modelValue == value;
            }


            let index = _.findIndex(modelValue, function (v, k) {
                return v == value;
            });

            return index !== -1;
        }


        function handleAutocompleteChanged(val) {
            if (!isMounted.value) return;

            let model = (props.field.usemodel ? props.field.usemodel : props.formModel);

            if (isMultiple.value) {

                setPropertyPath(model, modelName.value, val);
                context.emit('input', val)
            } else {
                if (!isAutocomplete.value) {
                    setPropertyPath(model, modelName.value, val);
                } else {
                    setPropertyPath(model, modelName.value, val);
                    currentSelected.value = val
                }

                context.emit('input', val)
            }
        }


        function handleChanged(e) {

            if (!isMounted.value) return;

            let model = (props.field.usemodel ? props.field.usemodel : props.formModel);

	        let value = e.currentTarget.value;

            if (isMultiple.value) {


                let modelValue = getPropertyPath(model, modelName.value);
                if (modelValue === null || typeof modelValue == "undefined") {
                    modelValue = [];
                    setPropertyPath(model, modelName.value, modelValue);
                }

                if (_.isObject(modelValue)) {
                    modelValue = Object.keys(modelValue).map((key) => {
                        return modelValue[key]
                    })
                }

                if (_.isArray(modelValue) || _.isObject(modelValue)) {

                    let newValue = modelValue;

                    if (e.currentTarget.checked) {
                        newValue.push(e.currentTarget.value);
                    } else {

                        let index = _.findIndex(modelValue, function (v, k) {
                            return v == value;
                        });
                        while (index !== -1) {
                            newValue.splice(index, 1);
                            index = _.findIndex(modelValue, function (v, k) {
                                return v == value;
                            });
                        }
                    }


                    setPropertyPath(model, modelName.value, newValue);
                    setPropertyPath(props.field, 'value', newValue);


                    if (props.field.hasOwnProperty('onSelect') && _.isString(props.field.onSelect)) {
                        if (props.field.onSelect.match(/^\$emit/g)) {
                            eval('$events.' + props.field.onSelect);
                        }
                    }
                }
            } else {


                if (!isAutocomplete.value) {
                    setPropertyPath(model, modelName.value, e.currentTarget.value);
                    setPropertyPath(props.field, 'value', e.currentTarget.value);
                } else {
                    setPropertyPath(model, modelName.value, e.currentTarget.value);
                    setPropertyPath(props.field, 'value', e.currentTarget.value);

                    currentSelected.value = e.currentTarget.value;
                }

                if (props.field.hasOwnProperty('onSelect') && _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval('$events.' + props.field.onSelect);
                    }
                }
            }

            if (!isAutocomplete.value) {
                context.emit('input', value)
            } else {
                context.emit('input', modelvalue.value)
            }
        }


        return {
            allowMultiple,
            currentSelected,
            fieldAttributes,
            fieldIsDisabled,
            fieldOptions,
            isAutocomplete,
            selectWrapClasses,
            isMultiple,
            modelvalue,
            selectOptions,
            sizeStyle,
            getOptionLabel,
            getOptionKey,
            onSearch,
            handleAutocompleteChanged,

            fieldId,
            isChecked,
            handleChanged,
        }


    },

}
</script>

<style scoped>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    background: #dfe5fb;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: #394066;
}

</style>
