<script>
import {computed, defineComponent, getCurrentInstance, inject, onMounted, onBeforeMount, ref} from 'vue'
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getHtmlCsrfToken} from "@/utils/auth";

export default defineComponent({
    name: "VerifyReset",
    setup() {
        const inst = getCurrentInstance();
        const store = useStore();
        const router = useRouter();
        const route = useRoute();


        const $http = inject('$http');
        const appname = inject('appname');
        const version = inject('version');
        const notification = inject('notification')

        const loading = ref(false)

        const model = ref({
            password: null,
            password_confirmation: null,
            hash: null
        });

        if (window.options.verify) {
            model.value.hash = window.options.verify;
        }

        const canSend = computed(() => {
            return model.value.password && model.value.password_confirmation && model.value.hash !== null
        });


        const successSend = ref(null);
        const errors = ref(null);




        onBeforeMount(() => {
            if (route.params.hash) {
                model.value.hash = route.params.hash
            }
        });

        onMounted(() => {
            if (inst.refs.token_field) {
                inst.refs.token_field.value = getHtmlCsrfToken();
            }
        })

        function sendVerify(evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            errors.value = null;


            loading.value = true;

            $http.post(inst.root.ctx.routes('admin.reset-password.verify'), model.value).then((r) => {
                if (r.data.success)
                {
                    if (r.data.password_success) {
                        successSend.value = r.data.password_success;
                    }
                }
                else {

                    if (r.data.errors) {
                        errors.value = r.data.errors
                    }

                    notification(r.data);
                }


                loading.value = false;
            }).catch(e => {
                loading.value = false;
            });

            // inst.refs.form.submit();
        }

        function toLogin() {
            router.push({name: 'auth'})
        }

        return {
            loading,
            errors,
            model,
            successSend,
            canSend,
            sendVerify,
            toLogin,
            appname,
            version
        }
    }
})
</script>

<template>
    <div class="auth-container">
        <div class="auth-form-container">


            <div class="auth-form reset-form-confirm" v-if="model.hash === '0' || !model.hash">

                <form>
                    <div class="login-container">
                        <div class="login-header">Amino Fehler</div>
                        <div class="login-content">
                            <div class="alert alert-danger mt-8">
                                <p>Huch entweder ist die Verifizierung abgelaufen oder diese wurde schon genutzt um Ihr Passwort zurückzusetzen!</p>
                            </div>
                        </div>
                        <div class="copyright">
                            <small>&copy; 2024 by Neloh&trade; Software - Version {{ version }}</small>
                        </div>
                    </div>
                </form>


            </div>




            <div class="auth-form reset-form-confirm" v-if="model.hash !== '0'">
                <form ref="form" action="backend/reset-password" method="post" autocomplete="off">
                    <div class="login-container">
                        <div class="login-header">Amino Login</div>
                        <div class="login-content">


                            <loading :active="loading === true"
                                     color="blue"
                                     :width="40"
                                     :height="40"
                                     :opacity="0.6"
                                     :is-full-page="false">
                            </loading>



                            <input type="hidden" name="_token" value="" ref="token_field">
                            <b>Neues Passwort vergeben</b>

                            <template v-if="errors">
                                <form-errors :errors="errors"/>
                            </template>

                            <template v-if="!successSend">
                                <!--                        <form-text name="username"-->
                                <!--                                   :form-model="model"-->
                                <!--                                   :field="{label: 'Benutzername'}" />-->

                                <form-password name="password"
                                               :form-model="model"
                                               :field="{
                                                label: 'Ihr neues Passwort',
                                                validation: 'required',
                                                description: 'Ihr neues Passwort muss mind. 6 Zeichen lang sein. Folgendes muss enthalten sein: 2 Großbuchstaben, 2 Kleinbuchstaben sowie 2 Zahlen oder Sonderzeichen'
                                            }" />

                                <form-password name="password_confirmation"
                                               :form-model="model"
                                               :field="{
                                                label: 'Kontrolleingabe des neuen Passwortes',
                                                description: 'muss Ihrem eingegebenen Passwort entsprechen',
                                                validation: 'required',
                                           }" />

                                <div class="btns mt-8">
                                    <button type="button"
                                            class="btn btn-primary"
                                            :disabled="!canSend"
                                            @click.prevent="sendVerify">
                                        Passwort übernehmen
                                    </button>



                                    <button type="button"
                                            class="btn btn-primary"
                                            @click.prevent="toLogin">
                                        Zum Login
                                    </button>

                                </div>
                            </template>
                            <template v-else>

                                <div class="alert alert-info">
                                    Wir haben Ihr Passwort geändert und Sie können sich ab sofort mit dem neuen Passwort anmelden.
                                </div>

                                <div class="btns mt-8">
                                    <button type="button"
                                            class="btn btn-primary"
                                            @click.prevent="toLogin">
                                        Weiter zum Login
                                    </button>
                                </div>

                            </template>

                        </div>

                        <div class="copyright">
                            <small>&copy; 2024 by Neloh&trade; Software - Version {{ version }}</small>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
