<template>
    <div v-if="hasActions" class="opt">
        <template v-for="(a, i) in actions" :key="'opt-action-'+i">
            <a class="btn"
                v-bind="getActionAttributes(a)"
                v-if="getIcon(a) && getActionLabel(a)"
                v-tooltip="getActionTooltip(a)"
                :href="getActionUrl(a)"
                @click="(e) => { handleAction(e, a) }">

                <template v-if="a.hasOwnProperty('icon') && a.icon">

                    <template v-if="a.icon === 'pencil'">
                        <svg viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              stroke-width="1.5"
                              fill="none"
                              class="tabler-icon"
                              role="img"
                              aria-hidden="true"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke="currentColor"
                              preserveAspectRatio="xMidYMid meet">
                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path><path d="M13.5 6.5l4 4"></path>
                        </svg>

                    </template>
                    <template v-else-if="a.icon === 'trash'">
                        <svg viewBox="0 0 24 24"
                             width="16"
                             height="16"
                             stroke-width="1.5"
                             fill="none"
                             class="tabler-icon"
                             role="img"
                             aria-hidden="true"
                             stroke-linecap="round"
                             stroke-linejoin="round"
                             stroke="currentColor"
                             preserveAspectRatio="xMidYMid meet">
                        <path d="M4 7l16 0"></path><path d="M10 11l0 6"></path><path d="M14 11l0 6"></path><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                        </svg>
                    </template>
                    <template v-else-if="a.icon === 'eye'">
                        <svg viewBox="0 0 24 24"
                             width="16"
                             height="16"
                             stroke-width="1.5"
                             fill="none"
                             class="tabler-icon"
                             role="img"
                             aria-hidden="true"
                             stroke-linecap="round"
                             stroke-linejoin="round"
                             stroke="currentColor"
                             preserveAspectRatio="xMidYMid meet">
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                        </svg>
                    </template>
                    <template v-else-if="a.icon === 'eye-off'">
                        <svg viewBox="0 0 24 24"
                             width="16"
                             height="16"
                             stroke-width="1.5"
                             fill="none"
                             class="tabler-icon"
                             role="img"
                             aria-hidden="true"
                             stroke-linecap="round"
                             stroke-linejoin="round"
                             stroke="currentColor"
                             preserveAspectRatio="xMidYMid meet">
                        <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828"></path><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87"></path><path d="M3 3l18 18"></path>
                        </svg>
                    </template>
                    <template v-else>
                        <template v-if="getIcon(a) && getIcon(a).match(/^fa/g)">
                            <i v-if="getIcon(a) && !a.run" :class="['fa', getIcon(a)]"></i>
                            <i v-if="a.run" class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                        </template>
                        <template v-else>
                            <svg-icon :name="getIcon(a)"/>
                        </template>
                    </template>

                </template>
                <template v-else>
                    <template v-if="getIcon(a) && getIcon(a).match(/^fa/g)">
                        <i v-if="getIcon(a) && !a.run" :class="['fa', getIcon(a)]"></i>
                        <i v-if="a.run" class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                    </template>
                    <template v-else>
                        <svg-icon :name="getIcon(a)"/>
                    </template>
                </template>
            </a>
        </template>
    </div>
</template>

<script>
import {getCurrentInstance, ref, nextTick, computed, watch, onBeforeMount, onMounted, inject} from "vue";
import {useRouter} from "~/vue-router";

export default {
    name: "base-GridTableActions",
    props: {
        rowData: {
            type: Object,
            required: true
        },
        rowIndex: {
            type: Number
        },
        labelColumn: {},
        fieldEvents: {
            type: Object,
            default: {}
        }
    },


    setup(props, context) {
        const inst = getCurrentInstance()
        const $root = inst.root.ctx;
        const mutatedData = ref({});
        const deleteConfirmOpen = ref(false);
        const deleteConfirmMessage = ref(null);
        const deleteConfirmItem = ref(null);
        const deleteConfirmLoading = ref(false);
        const run = ref(false);

        const router = useRouter();
        const $events = inject('$events');
        const $set = inject('$set')



        watch(() => props.rowData, (newData) => {
            mutatedData.value = newData;
            setRowClasses();
            prepareActions();
        }, {deep: true});

        const parent = computed(() => {
            return inst.parent;
        })

        const hasLabelColumn = computed(() => {
            return _.isString(props.labelColumn) && props.labelColumn;
        });

        const hasTitle = computed(() => {
            if (hasLabelColumn.value) {
                return mutatedData.value.hasOwnProperty(props.labelColumn) && mutatedData.value[props.labelColumn];
            }

            return mutatedData.value.hasOwnProperty('title') && mutatedData.value.title;
        });

        const hasUsername = computed(() => {
            if (hasLabelColumn.value && mutatedData.value.hasOwnProperty(props.labelColumn)) {
                return mutatedData.value[props.labelColumn];
            }

            return mutatedData.value.hasOwnProperty('username') && mutatedData.value.username;
        });

        const title = computed(() => {
            if (hasLabelColumn.value && mutatedData.value.hasOwnProperty(props.labelColumn)) {
                return mutatedData.value[props.labelColumn];
            }

            if (hasTitle.value) {
                return mutatedData.value.title;
            }

            if (hasUsername.value) {
                return mutatedData.value.username
            }
        });


        const getUsername = computed(() => {
            if (hasUsername.value) {
                return mutatedData.value.username
            }
        });

        const hasActions = computed(() => {
            return hasLabelColumn.value ? !_.isEmpty(mutatedData.value[props.labelColumn]) : null;

        });

        const hasNote = computed(() => {
            return (hasTitle.value || hasUsername.value) && title.value.hasOwnProperty('note') && title.value.note;
        });


        const actions = computed(() => {
            return mutatedData.value[props.labelColumn];
        });


        onBeforeMount(() => {
            mutatedData.value = props.rowData;
            setRowClasses();
        });


        onMounted(() => {
            prepareActions();
        });


        function setRowClasses() {

            let m = _.cloneDeep(mutatedData.value);

            if (!m.hasOwnProperty('dataClass')) {
                $set(m, 'dataClass', '');
            }

            if (m.dataClass.indexOf('label-column') === -1) {
                $set(m, 'dataClass', m.dataClass + ' label-column opt-column');
            }

            mutatedData.value = m;
        }


        function prepareActions() {
            // let actions = {};
            // this.actions = actions;
        }


        function getActionUrl(action) {

            let attr = getActionAttributes(action);

            if (action && action.hasOwnProperty('url') && action.url && action.url.match(/^https?:\/\//g)) {
                return action.url
            } else if (attr && attr.hasOwnProperty('request-url') && attr['request-url']) {
                return attr['request-url']
            } else if (attr && attr.hasOwnProperty('data-request-url') && attr['data-request-url']) {
                return attr['data-request-url']
            } else if (attr && attr.hasOwnProperty('data-url') && attr['data-url']) {
                return attr['data-url']
            } else if (attr && attr.hasOwnProperty('data') && attr.data && attr.data['url']) {
                return attr.data['url']
            }
            return 'javascript: void(0)';
        }


        function getActionTooltip(action) {
            if (action && action.hasOwnProperty('tooltip') && action.tooltip) {
                return $root.entityDecode(action.tooltip)
            }

            return getActionLabel(action);
        }

        function getActionLabel(action) {
            if (action && action.hasOwnProperty('label') && action.label) {
                return $root.entityDecode(action.label)
            }
            return '';
        }


        function getActionAttributes(action) {
            if (action && action.hasOwnProperty('attributes')) {

                if (action.attributes.hasOwnProperty('data') && _.isObject(action.attributes.data)) {
                    let base = {};
                    for (let key in action.attributes.data) {

                        if (key === 'request-success') {
                            continue;
                        }

                        $set(action.attributes, 'data-' + key, action.attributes.data[key]);
                    }
                    delete action.attributes.data;
                }

                if (!_.isObject(action.attributes) && _.isArray(action.attributes)) {
                    return action.attributes;
                } else if (_.isObject(action.attributes)) {
                    return action.attributes;
                }
            }

            return null
        }


        function handleAction(event, link) {

            if (event.currentTarget.getAttribute('target') === '_blank') {
                return;
            }


            event.preventDefault();
            event.stopPropagation();

            if (event.currentTarget.getAttribute('disabled')) {
                return;
            }

            let attr = getActionAttributes(link);
            if (link.type === 'delete') {
                let deleteProps = {
                    button: link,
                    row: props.rowData,
                    message: (attr.hasOwnProperty('data-confirm') ? attr['data-confirm'] : 'Auswahl wirklich löschen?')
                };
                context.emit('delete', deleteProps);
                return;
            }

            if (link.type === 'restore') {

                let url = null;
                if (link.url && !link.url.match(/^#/g) && !link.url.match(/^javascript:/g)) {
                    url = link.url
                }


                context.emit('restore', {
                    button: link,
                    row: props.rowData,
                    url: url
                });
                return;
            }

            if (link.hasOwnProperty('event') && link.event) {
                $events.$emit(link.event, props.rowData, link);
                return;
            }

            if (link.url && !link.url.match(/^#/g) && !link.url.match(/^javascript:/g)) {
                router.push(inst.root.ctx.transformUrlToRoute(link.url)).then(() => {
					if (typeof attr['afterload'] === "string") {
						if (attr['afterload'].match(/^(emit|event)@/g) ) {
							let s = attr['afterload'].split('@');
							if (s[1]) {

								let a = s[1].split(':');
								if (a[1]) {
									let eventArgs = a[1].split(',');

									eventArgs.push(props.rowData);
									eventArgs.push(link);

									$events.$emit(a[0], ...eventArgs);
								}
								else {
									$events.$emit(s[1], props.rowData, link);
								}

							}
						}
					}
                });
            }
        }


        // action icon
        function getIcon(action) {


            let icon = '';
            if (action && action.hasOwnProperty('icon') && action.icon) {
                icon = action.icon
                    .replace(/^fa-/, '')
                    .replace(/\s*fa\s{1,}/g, '');
            }

            if (icon) {
                icon = icon.replace(/^\s*fa\-/, '');
            }

			if (action.hasOwnProperty('tabler') && action.tabler) {
				return icon;
			}


            // else if (action.hasOwnProperty('icon') && action.icon) {
            //     icon = action.icon;
            // }

            if ((!icon || icon === action.type) && action && action.hasOwnProperty('type')) {

                switch (action.type) {
                    case 'edit':
                        icon = 'pencil';
                        break;

                    case 'delete':
                        icon = 'trash-o';
                        break;

                    case 'publish':

                        if (action.published === true) {
                            icon = 'eye';
                        } else if (action.published === false) {
                            icon = 'eye-slash';
                        } else if (action.published === 2) {
                            icon = 'calendar-check-o';
                        } else {
                            icon = 'eye';
                        }

                        break;

                    case 'draft':
                        icon = 'hourglass-half';
                        break;

                    case 'download':
                        icon = 'download';
                        break;

                    case 'install':
                        icon = 'plus';
                        break;

                    case 'uninstall':
                        icon = 'times';
                        break;

                    case 'perms':
                        icon = 'universal-access';
                        break;

                    case 'backendperms':
                        icon = 'user-secret';
                        break;

                    case 'setdefault':
                        icon = 'check-square';
                        break;
                }
            }

            return (icon ? 'fa-' + icon : '');
        }


        function handleDeleteCancel() {
            deleteConfirmOpen.value = false;

            nextTick(() => {
                deleteConfirmItem.value = null;
                deleteConfirmMessage.value = null;
            });
        }

        function handleDelete() {
            context.emit('delete', props.rowData, () => {
                deleteConfirmOpen.value = false;

                nextTick(() => {
                    deleteConfirmItem.value = null;
                    deleteConfirmMessage.value = null;
                });
            });
        }






        return {
            mutatedData,
            deleteConfirmOpen,
            deleteConfirmMessage,
            deleteConfirmItem,
            deleteConfirmLoading,
            run,
            parent,
            hasLabelColumn,
            hasTitle,
            title,

            hasActions,
            actions,



            getActionAttributes,
            getActionLabel,
            getUsername,
            getIcon,
            getActionTooltip,
            getActionUrl,
            handleAction,
        }
    },

    //
    // data() {
    //     return {
    //
    //         mutatedData: {},
    //
    //         deleteConfirmOpen: false,
    //         deleteConfirmMessage: null,
    //         deleteConfirmItem: null,
    //         deleteConfirmLoading: false,
    //         run: false
    //     }
    // },
    // watch: {
    //     rowData(newData) {
    //         this.mutatedData = newData;
    //
    //         this.setRowClasses();
    //         this.prepareActions();
    //     }
    // },
    //
    //
    // computed: {
    //
    //     parent() {
    //         return this.$parent;
    //     },
    //
    //     hasLabelColumn() {
    //         return _.isString(this.labelColumn) && this.labelColumn;
    //     },
    //
    //     hasTitle() {
    //
    //         if (this.hasLabelColumn) {
    //             return this.mutatedData.hasOwnProperty(this.labelColumn) && this.mutatedData[this.labelColumn];
    //         }
    //
    //         return this.mutatedData.hasOwnProperty('title') && this.mutatedData.title;
    //     },
    //     hasUsername() {
    //
    //         if (this.hasLabelColumn && this.mutatedData.hasOwnProperty(this.labelColumn)) {
    //             return this.mutatedData[this.labelColumn];
    //         }
    //
    //         return this.mutatedData.hasOwnProperty('username') && this.mutatedData.username;
    //     },
    //
    //     title() {
    //
    //         if (this.hasLabelColumn && this.mutatedData.hasOwnProperty(this.labelColumn)) {
    //             return this.mutatedData[this.labelColumn];
    //         }
    //
    //         if (this.hasTitle) {
    //             return this.mutatedData.title;
    //         }
    //
    //         if (this.hasUsername) {
    //             return this.mutatedData.username
    //         }
    //     },
    //
    //     getUsername() {
    //         if (this.hasUsername) {
    //             return this.mutatedData.username
    //         }
    //     },
    //
    //     hasActions() {
    //         return this.hasLabelColumn ? !_.isEmpty(this.mutatedData[this.labelColumn]) : null;
    //
    //     },
    //
    //     hasNote() {
    //         return (this.hasTitle || this.hasUsername) && this.title.hasOwnProperty('note') && this.title.note;
    //     },
    //
    //
    //     actions() {
    //         return this.mutatedData[this.labelColumn];
    //     }
    // },
    //
    // beforeMount() {
    //     this.mutatedData = this.rowData;
    //     this.setRowClasses();
    // },
    //
    // mounted() {
    //     this.prepareActions();
    // },
    //
    // methods: {
    //     setRowClasses() {
    //         if (!this.mutatedData.hasOwnProperty('dataClass')) {
    //             this.$set(this.mutatedData, 'dataClass', '');
    //         }
    //
    //         if (this.mutatedData.dataClass.indexOf('label-column') === -1) {
    //             this.$set(this.mutatedData, 'dataClass', this.mutatedData.dataClass + ' label-column opt-column');
    //         }
    //     },
    //
    //
    //     prepareActions() {
    //         // let actions = {};
    //         // this.actions = actions;
    //     },
    //
    //
    //     getActionUrl(action) {
    //
    //         let attr = this.getActionAttributes(action);
    //
    //         if (action && action.hasOwnProperty('url') && action.url && action.url.match(/^https?:\/\//g)) {
    //             return action.url
    //         } else if (attr && attr.hasOwnProperty('request-url') && attr['request-url']) {
    //             return attr['request-url']
    //         } else if (attr && attr.hasOwnProperty('data-request-url') && attr['data-request-url']) {
    //             return attr['data-request-url']
    //         } else if (attr && attr.hasOwnProperty('data-url') && attr['data-url']) {
    //             return attr['data-url']
    //         } else if (attr && attr.hasOwnProperty('data') && attr.data && attr.data['url']) {
    //             return attr.data['url']
    //         }
    //         return 'javascript: void(0)';
    //     },
    //
    //
    //     getActionTooltip(action) {
    //         if (action && action.hasOwnProperty('tooltip') && action.tooltip) {
    //             return this.$root.entityDecode(action.tooltip)
    //         }
    //
    //         return this.getActionLabel(action);
    //     },
    //
    //     getActionLabel(action) {
    //         if (action && action.hasOwnProperty('label') && action.label) {
    //             return this.$root.entityDecode(action.label)
    //         }
    //         return '';
    //     },
    //
    //
    //     getActionAttributes(action) {
    //         if (action && action.hasOwnProperty('attributes')) {
    //
    //             if (action.attributes.hasOwnProperty('data') && _.isObject(action.attributes.data)) {
    //                 let base = {};
    //                 for (let key in action.attributes.data) {
    //
    //                     if (key == 'request-success') {
    //                         continue;
    //                     }
    //
    //                     this.$set(action.attributes, 'data-' + key, action.attributes.data[key]);
    //                 }
    //                 delete action.attributes.data;
    //             }
    //
    //             if (!_.isObject(action.attributes) && _.isArray(action.attributes)) {
    //                 return action.attributes;
    //             } else if (_.isObject(action.attributes)) {
    //                 return action.attributes;
    //             }
    //         }
    //
    //         return null
    //     },
    //
    //
    //     handleAction(event, link) {
    //
    //         if (event.currentTarget.getAttribute('target') === '_blank') {
    //             return;
    //         }
    //
    //
    //         event.preventDefault();
    //         event.stopPropagation();
    //
    //         if (event.currentTarget.getAttribute('disabled')) {
    //             return;
    //         }
    //
    //         let attr = this.getActionAttributes(link);
    //         if (link.type === 'delete') {
    //             let deleteProps = {
    //                 row: this.rowData,
    //                 message: (attr.hasOwnProperty('data-confirm') ? attr['data-confirm'] : 'Auswahl wirklich löschen?')
    //             };
    //             this.$emit('delete', deleteProps);
    //             return;
    //         }
    //
    //         if (link.type === 'restore') {
    //
    //             let url = null;
    //             if (link.url && !link.url.match(/^#/g) && !link.url.match(/^javascript:/g)) {
    //                 url = link.url
    //             }
    //
    //
    //             this.$emit('restore', {
    //                 row: this.rowData,
    //                 url: url
    //             });
    //             return;
    //         }
    //
    //         if (link.hasOwnProperty('event') && link.event) {
    //             this.$events.$emit(link.event, this.rowData, link);
    //             return;
    //         }
    //
    //         if (link.url && !link.url.match(/^#/g) && !link.url.match(/^javascript:/g)) {
    //             this.$router.push(this.transformUrlToRoute(link.url));
    //         }
    //     },
    //
    //
    //     // action icon
    //     getIcon(action) {
    //
    //         let icon = '';
    //         if (action.hasOwnProperty('icon') && action.icon) {
    //             icon = action.icon
    //                 .replace(/^fa-/, '')
    //                 .replace(/\s*fa\s{1,}/g, '');
    //         }
    //
    //
    //         if (icon) {
    //             icon = icon.replace(/^\s*fa\-/, '');
    //         }
    //
    //
    //         // else if (action.hasOwnProperty('icon') && action.icon) {
    //         //     icon = action.icon;
    //         // }
    //
    //         if ((!icon || icon === action.type) && action.hasOwnProperty('type')) {
    //
    //             switch (action.type) {
    //                 case 'edit':
    //                     icon = 'pencil';
    //                     break;
    //
    //                 case 'delete':
    //                     icon = 'trash-o';
    //                     break;
    //
    //                 case 'publish':
    //
    //                     if (action.published === true) {
    //                         icon = 'eye';
    //                     } else if (action.published === false) {
    //                         icon = 'eye-slash';
    //                     } else if (action.published === 2) {
    //                         icon = 'calendar-check-o';
    //                     } else {
    //                         icon = 'eye';
    //                     }
    //
    //                     break;
    //
    //                 case 'draft':
    //                     icon = 'hourglass-half';
    //                     break;
    //
    //                 case 'download':
    //                     icon = 'download';
    //                     break;
    //
    //                 case 'install':
    //                     icon = 'plus';
    //                     break;
    //
    //                 case 'uninstall':
    //                     icon = 'times';
    //                     break;
    //
    //                 case 'perms':
    //                     icon = 'universal-access';
    //                     break;
    //
    //                 case 'backendperms':
    //                     icon = 'user-secret';
    //                     break;
    //
    //                 case 'setdefault':
    //                     icon = 'check-square';
    //                     break;
    //             }
    //         }
    //
    //         return (icon ? 'fa-' + icon : '');
    //     },
    //
    //
    //     handleDeleteCancel() {
    //         this.deleteConfirmOpen = false;
    //
    //         this.$nextTick(() => {
    //             this.deleteConfirmItem = null;
    //             this.deleteConfirmMessage = null;
    //         });
    //     },
    //
    //     handleDelete() {
    //         this.$emit('delete', this.rowData, () => {
    //             this.deleteConfirmOpen = false;
    //
    //             this.$nextTick(() => {
    //                 this.deleteConfirmItem = null;
    //                 this.deleteConfirmMessage = null;
    //             });
    //         });
    //     }
    //
    // }

}
</script>

<style scoped>

</style>
