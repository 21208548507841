<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default defineComponent({
    name: "medium-select",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);



        const selectOptions = ref([])

        onBeforeMount(() => {
            selectOptions.value = props.field.options;
            if (!Array.isArray(modelvalue.value)) {
                modelvalue.value = [];
            }
        });

        return {
            modelvalue,
            selectOptions,
            fieldIsDisabled
        }
    }
})
</script>

<template>

    <form-field :name="name" :field="field" :key="name"
                :validate="validate"
                :form-model="formModel"
                :responseerrors="responseerrors">
        <template v-slot:field>
        <form-checkboxlist :field="{options: selectOptions, multiple: true, horizontal: true, disabled: fieldIsDisabled }"
                           :form-model="formModel"
                           :field-value="modelvalue"
                           name="mediums"
                           @input="(v) => modelvalue = v" />
        </template>
    </form-field>
</template>

<style scoped>

</style>
