<template>
    <form-field :name="name" :form-model="formModel" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>

            <template v-if="field.type === 'password'">
                <div :class="{'with-append': true }">
                    <input
                        :type="pwvisible === true ? 'text' : 'password'"
                        :name="name"
                        ref="formfield"
                        class="form-control"
                        v-model.sync="modelvalue"
                        v-bind="fieldAttributes"
                        @input="(e) => updateValue(e.currentTarget.value)">
                    <span class="append-label"
                          v-tooltip="'Eingabe anzeigen'"
                          @click.prevent="(e) => {pwvisible = pwvisible === true ? false : true;}">
                        <svg-icon :name="pwvisible === true ? 'eye-off' : 'eye'"/>
                    </span>
                </div>
            </template>
            <template v-else>
                <div :class="{'with-prepend': field.prependlabel, 'with-append': field.appendlabel || field.type === 'password'}">
                    <span class="prepend-label" v-if="field.prependlabel" v-html="field.prependlabel"></span>
                    <input
                        :type="field.pvisibile === true ? 'text' : field.type"
                        :name="name"
                        ref="formfield"
                        class="form-control"
                        v-model.sync="modelvalue"
                        v-bind="fieldAttributes"
                        @input="(e) => updateValue(e.currentTarget.value)">
                    <template v-if="field.type === 'password'">
                    <span class="append-label"
                          v-tooltip="'Eingabe anzeigen'"
                          @click.prevent="field.pvisibile = field.pvisibile === true ? false : true;">
                        <svg-icon :name="field.pvisibile === true ? 'eye-off' : 'eye'"/>
                    </span>
                    </template>
                    <span class="append-label" v-if="field.appendlabel" v-html="field.appendlabel"></span>
                </div>
            </template>




        </template>


    </form-field>
</template>

<script>
import {nextTick, onMounted, ref} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default {
    name: "form-input",
    emits: ['input'],
    props: {
        ...defaultFieldProps,
        data: {
            required: false,
            default() {
                return undefined
            }
        },
        returnOnly: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {

        const {modelvalue, fieldAttributes} = useFormField(props, context);
        const defaultModelValue = ref(null);
        const pwvisible = ref(false)

        if (props.field.hasOwnProperty('default')) {
            defaultModelValue.value = props.field.default;
        }

        function updateValue(value) {
            if (!props.returnOnly) {
                modelvalue.value = value;
            }
            context.emit('input', value)
        }

        onMounted(() => {
            // nextTick(() => {
            //     let input = inst.vnode.el.querySelector('[type="password"]');
            //     if (input) {
            //         input.value = null;
            //     }
            // })
        })

        return {
            fieldAttributes,
            modelvalue,
            pwvisible,
            updateValue
        }
    }
}
</script>

<style scoped>

</style>
