const {ignoreModules} = require('./IgnoreModules');

if (window.Laravel && _.isArray(window.Laravel.modules)) {
    const modules = window.Laravel.modules.filter(item => !ignoreModules.includes(item));
    _.each(modules, (name) => {
            try {
                const modulStore = require('@modules/' + name + '/Assets/js/' + name + 'Store.js').default;

                if (_.isObject(modulStore)) {
                    if (!Laravision.$store.hasModule('Modul'+ name)) {
                        Laravision.$store.registerModule('Modul'+ name, modulStore);
                    }
                }
            } catch (e) {
                try {
                    const modulStore = require('@modules/' + name + '/Assets/js/store.js').default;
                    if (_.isObject(modulStore)) {
                        if (!Laravision.$store.hasModule('Modul'+ name)) {
                            Laravision.$store.registerModule('Modul'+ name, modulStore);
                        }
                    }
                } catch (e) {
                    // console.warn('Modul "'+name+'" has no components.js');
                }
            }
    });
}
