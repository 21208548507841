<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";
export default defineComponent({
    name: "question-proofreading",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },

    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;


        const hasOwnPropertyPath = inject('hasOwnPropertyPath')
        const getPropertyPath = inject('getPropertyPath')
        const setPropertyPath = inject('setPropertyPath')

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);

        const rawModel = computed(() => {
            return props.field.question;
        });


        const cleanContent = (str) => {
            str = (str + '').trim();
            return str.replace(/\r\n/g, "\n").replace(/\n\r/g, "\n").replace(/\r/g, "\n");
        }

        function useAnswer() {
            if (props.formModel.content && props.formModel.content.trim()) {
                return;
            }

            if (rawModel.value && rawModel.value.answer) {
                props.formModel.content = _.cloneDeep(rawModel.value.answer);
                context.emit('input', props.formModel.content);
            }
        }


        function moveAnswerInput() {
            if (inst.vnode.el.querySelector('.my-proofreading-answer') && inst.refs.answertext) {
                // const answerInput = inst.vnode.el.querySelector('.my-proofreading-answer');
                const answerInput = inst.vnode.el.querySelector('.my-proofreading-answer');
                const answertextarea = answerInput.querySelector('textarea')

                let section = answertextarea.closest('.form-section-content')

                let rect = inst.refs.answertext.getBoundingClientRect();
                let rect2 = answerInput.getBoundingClientRect();
                let rect3 = answertextarea.getBoundingClientRect();

                section.style.paddingTop = ( rect.top - (rect3.top + 5) ) +'px';
            }
        }


        onMounted(() => {
            nextTick(() => {
                moveAnswerInput()
            })
        })


        return {
            rawModel,
            modelvalue,
            useAnswer,
            cleanContent
        }
    }
})
</script>

<template>
    <div class="row form-row">

        <div class="col-md-12">
            <form-section :field="{label: 'Frage', class: 'my-proofreading-question'}">
                <div class="question">
                    <div class="question-text"><pre>{{ cleanContent(rawModel.question) }}</pre></div>
                </div>
            </form-section>
        </div>

        <div class="col-md-6">
            <form-section :field="{label: 'Aktuelle Antwort', class: 'my-proofreading'}">
                <div class="answer" v-if="rawModel.answer && rawModel.answer.trim() !== ''">
                    <div class="btn btn-sm"
                         v-tooltip="'Aktuelle Antwort übernehmen zum Korregieren'"
                         :disabled="formModel.content && formModel.content.trim()"
                         @click.prevent="useAnswer">
                        <svg-icon name="arrow-bar-right"/>
                    </div>
                    <div class="answer-text" ref="answertext"><pre>{{ cleanContent(rawModel.answer) }}</pre></div>
                </div>
            </form-section>
        </div>
        <div class="col-md-6">
            <form-section
                ref="my_proofreadinganswer"
                :field="{
                        label: 'Mein Korrekturvorschlag für die Antwort',
                        class: 'my-proofreading-answer no-padding'
                }">
                <form-textarea ref="contentarea" name="content" :form-model="formModel" :field="{autosize: true}"></form-textarea>
            </form-section>
        </div>






    </div>





</template>

<style scoped>

</style>
