<template>
	<modal :show="show"
		   :cancel-btn-label="cancelLabel"
		   :ok-btn-label="okLabel"
		   :show-cancel="showCancel"
		   @cancel="cancel"
		   @close="cancel"
		   @submit="submit"
		   :class="['confirm-modal', hasIcon ? 'with-icon' : 'without-icon', hasIcon ? 'mode-'+ type : '']"
	>
		<template v-slot:title>
			{{ title }}
		</template>
		<template v-slot:body>
			<template v-if="hasIcon">
				<div class="message-with-icon">
					<div class="icon">
                        <svg-icon :name="icon"/>
					</div>
					<div class="message">
						<slot name="message"></slot>
					</div>
				</div>
			</template>
			<template v-else>
				<slot name="message"></slot>
			</template>

		</template>
	</modal>
</template>

<script>
import {ref, watch, computed} from "vue";

export default {
	name: "confirm",
    emits: ['cancel', 'submit'],
	props: {
		show: {
			type: Boolean,
			required: true
		},
		title: {
			type: String,
			default() {
				return 'Bestätigen...';
			}
		},
		okLabel: {
			type: String,
			default() {
				return 'OK';
			}
		},
		cancelLabel: {
			type: String,
			default() {
				return 'Abbrechen';
			}
		},
		showCancel: {
			type: Boolean,
			required: false,
			default() {
				return true
			}
		},
		type: {
			type: String,
			default() {
				return ''
			}
		}
	},


    setup(props, context) {
        const icon = ref(null);

        watch(() => props.show, (n, o) => {
            if (n) {
                if (props.type) {
                    switch (props.type) {
                        case 'warning':
                            icon.value = 'alert-triangle'
                            break;

                        case 'error':
                            icon.value = 'alert-circle';
                            break;


                        case 'success':
                            icon.value = 'square-rounded-check';
                            break;

                        case 'info':
                        default:
                            icon.value = 'info-circle';
                            break;
                    }
                }
            }
        });


        watch(() => props.type, (n, o) => {
            if (n !== o) {
                if (n) {
                    switch (n) {
                        case 'warning':
                            icon.value = 'alert-triangle'
                            break;

                        case 'error':
                            icon.value = 'alert-circle';
                            break;

                        case 'success':
                            icon.value = 'square-rounded-check';
                            break;

                        case 'info':
                        default:
                            icon.value = 'info-circle';
                            break;
                    }
                }
                else {
                    icon.value = null;
                }
            }
        });

        const hasIcon = computed(() => {
            return _.isString(icon.value) && icon.value;
        });


        function cancel() {
            context.emit('cancel');
        }

        function submit() {
            context.emit('submit');
        }

        return {
            icon,
            hasIcon,

            cancel,
            submit
        }
    },
}
</script>

<style scoped>

</style>
