import EditAssociation from './components/EditHolder.vue';


export default [
    {
        path: '/holders',
        component: require('./components/Index.vue').default,
        name: 'admin.holders',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },

    {
        path: '/holders/edit/:id',
        component: EditAssociation,
        name: 'admin.holders.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/holders/create',
        component: EditAssociation,
        name: 'admin.holders.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
];
