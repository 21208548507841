<template>
    <li :class="itemClasses">
        <div class="nestable-item-content"
             @mouseenter="onMouseEnter"
             @mouseleave="onMouseLeave"
             @mousemove="onMouseMove">
            <slot :index="index"
                  :item="item"
                  :isChild="isChild"
                  :deepCount="deep"
            />
        </div>

        <ol class="nestable-list">
            <template v-if="hasChildren">
                <template v-for="(child, childIndex) in children" :key="child[keyProp]">
                    <NestableItem
                        :item="child"
                        :index="childIndex"
                        :parent-key-prop="parentKeyProp"
                        :parent-index="index"
                        :parent-id="parentId > 0 ? parentId : child[parentKeyProp]"
                        :last-strip-class="itemStripClass"
                        :options="options"
                        :is-copy="isCopy"
                        :is-child="true"
                        :deep="nextDeep"
                    >
                        <!-- bind scoped slots to the netable-item component -->
                        <template v-for="name in Object.keys($slots)" v-slot:[name]="scope">
                            <slot :name="name" v-bind="scope"/>
                        </template>
                    </NestableItem>
                </template>
            </template>
        </ol>
    </li>
</template>

<script type="text/babel">

import {ref, computed, inject, getCurrentInstance} from 'vue';
// import {useGroupObserver}                  from "./groups-observer.js";
import {useGroupObserver} from "./groups-observer.js";

export default {
    name: 'NestableItem',
    props: {
        item: {
            type: Object,
            required: true,
            default: () => ({})
        },
        index: {
            type: Number,
            required: false,
            default: null
        },
        parentIndex: {
            type: Number,
            required: false,
            default: 0
        },
        parentId: {
            default: null
        },
        lastStripClass: {
            type: String,
            required: false,
            default: 'nestable-item-even'
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        },
        isCopy: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Object,
            required: true,
            default: () => ({})
        },
        deep: {
            type: Number,
            default() {
                return 0
            }
        },

        parentKeyProp: {
            type: String,
            required: false,
            default: 'parent_id'
        },
    },

    inject: ['listId', 'group', 'keyProp'],

    setup(props, context) {
        const inst = getCurrentInstance();
        const {
            registerNestable,
            notifyDragStart,
            notifyMouseEnter,
            notifyMouseMove
        } = useGroupObserver(props, context, inst);

        const listId = inject('listId');
        const group = inject('group');
        const keyProp = inject('keyProp');
        const breakPoint = ref(null);
        const moveDown = ref(false);

        const slots = computed(() => {
            return inst.ctx.$slots;
        });

        const nextDeep = computed(() => {
            return props.deep + 1
        });

        const isDragging = computed(() => {
            const dragItem = props.options.dragItem;
            return !props.isCopy && !_.isEmpty(dragItem) && !_.isNull(dragItem) && dragItem[props.options.keyProp] === props.item[props.options.keyProp]
        });

        const hasChildren = computed(() => {
            return props.item.hasOwnProperty(props.options.childrenProp) && props.item[props.options.childrenProp].length > 0
        });

        const children = computed(() => {
            if (props.item[props.options.childrenProp] && !props.item[props.options.childrenProp].length) {
                return []
            }

            return props.item.hasOwnProperty(props.options.childrenProp) ? props.item[props.options.childrenProp] : []
        });

        const hasHandle = computed(() => {
            return !!slots.value.handler
        });

        const normalizedClassProp = computed(() => {
            const classProp = props.item[props.options.classProp]

            // if the classprop is not set, return an empty array
            if (!classProp) return []

            if (Array.isArray(classProp)) {
                return classProp
            } else if (typeof a === 'object') {
                return [classProp]
            } else {
                // String value
                return [classProp]
            }
        });


        const itemStripClass = computed(() => {
            if (!props.deep) {
                return props.lastStripClass;
            }

            let cls = props.index % 2 ? '-even' : '-odd';
            return `nestable-item${cls}`;
        });


        const itemClasses = computed(() => {
            const _isDragging = isDragging.value ? ['is-dragging'] : [];
            return [
                `nestable-item${props.isCopy ? '-copy' : ''}`,
                `nestable-item${props.isCopy ? '-copy' : ''}-${props.item[props.options.keyProp]}`,
                ..._isDragging,
                ...normalizedClassProp.value
            ]
        });


        function onMouseEnter(event) {
            if (!props.options.dragItem) return

            // if we don't know the direction the mouse is moving,
            // we can not calculate the offset at which we should trigger a swap
            // we we fallback to the old behavior
            if (!event.movementY) {
                return sendNotification(event)
            }

            // when the mouse enters the item we save the size of this item
            // is is to improve performance, so we do not recalculate the size on every move
            moveDown.value = event.movementY > 0

            breakPoint.value = event.target.getBoundingClientRect().height / 2
        }

        function onMouseLeave() {
            breakPoint.value = null
        }

        function onMouseMove(event) {
            // if we are not in a drag operation, we can discard the input
            if (!breakPoint.value) return

            // calculate how much the mouse is away from the center
            const delta = event.offsetY - breakPoint.value

            // if we have not reached the breakpoint, we can abort here
            if (moveDown.value && delta < breakPoint.value / 4) return
            if (!moveDown.value && delta > -breakPoint.value / 4) return

            sendNotification(event)
        }

        function sendNotification(event) {
            // reset the calculated breakpoint
            breakPoint.value = null

            // and trigger the enter event
            const it = props.item || inst.parent.item;


            notifyMouseEnter(inst.ctx.group, event, inst.ctx.listId, it)
        }

        // inject: ['listId', 'group', 'keyProp'],
        // inject('listId', inst.ctx.listId);
        // inject('group', inst.ctx.group);
        // inject('keyProp', props.options.keyProp);

        function itemKey(it, index) {
            if (it && it.hasOwnProperty(props.keyProp)) {
                return it[props.keyProp];
            }
            return index;
        }

        return {
            itemKey,
            onMouseEnter,
            onMouseLeave,
            onMouseMove,


            listId, group, keyProp,


            nextDeep,
            slots,
            isDragging,
            hasHandle,
            hasChildren,
            children,
            itemClasses,
            itemStripClass
        }
    },

}
</script>
