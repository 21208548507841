import {getCurrentInstance, computed, ref, inject} from "vue";
import {useRoute} from "~/vue-router";

export function useFormFieldConditions(props, context) {
    const inst = getCurrentInstance();
    const $root = inst.root.ctx;
    const route = useRoute();

    const hasOwnPropertyPath = inject('hasOwnPropertyPath')
    const getPropertyPath = inject('getPropertyPath')
    const setPropertyPath = inject('setPropertyPath')


    const hasDisableWhen = computed(() => {
        return props.field.hasOwnProperty('disable_when') && !_.isEmpty(props.field.disable_when);
    });

    const hasShowWhen = computed(() => {
        return props.field.hasOwnProperty('show_when');
    });

    const getDisableWhen = computed(() => {
        if (hasDisableWhen.value && !_.isEmpty(props.field.disable_when)) {
            if (_.isObject(props.field.disable_when) || _.isArray(props.field.disable_when))
            {
                let validCount = 0;
                let mustValidCount = _.size(props.field.disable_when);

                return getCondition(props.field.disable_when, validCount) === mustValidCount;
            }
            else {
                return getStringCondition(props.field.disable_when);
            }
        }

        return true
    });

    const getShowWhen = computed(() => {
        if (hasShowWhen.value) {

            if (_.isObject(props.field.show_when) || _.isArray(props.field.show_when)) {

                let valid = 0;
                let mustValidCount = _.size(props.field.show_when);
                let c = getCondition(props.field.show_when, valid);

                return c === mustValidCount;
            }
            else {
                if (props.field.show_when.match(/([\|,]+?)/g)) {
                    let conditions = props.field.show_when.split('|');
                    if (conditions.length > 1) {
                        // fieldname (0)
                        let hasShowValue = props.formModel ? hasOwnPropertyPath(props.formModel, conditions[0]) : false;
                        let showValue = props.formModel ? getPropertyPath(props.formModel, conditions[0]) : false;


                        if (hasShowValue) {

                            if (conditions.length === 3) {
                                let operator = conditions[1];
                                let value = conditions[2];
                                if (value.match(/,/g)) {
                                    value = value.split(',');
                                }
                                else if (value && value.match(/^true$/ig) ) {
                                    value = true;
                                }
                                else if (value && value.match(/^false$/ig) ) {
                                    value = false;
                                }


                                if (_.isArray(value)) {
                                    switch (operator) {
                                        case 'empty':
                                            return _.isUndefined(showValue) || _.isEmpty(showValue);

                                        case 'notempty':
                                            return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                        case 'in':
                                        case 'exists':
                                            if (_.isArray(showValue) || _.isObject(showValue)) {
                                                let found = false;
                                                _.each(showValue, (v) => {
                                                    if (!found) {
                                                        _.each(value, (item) => {
                                                            if (item == v) {
                                                                found = true;
                                                            }
                                                        });
                                                    }

                                                });

                                                return found ? showValue : null;
                                            }
                                            else {
                                                let found = false;
                                                _.each(value, (item) => {
                                                    if (!found) {
                                                        if (item == showValue) {
                                                            found = true;
                                                        }
                                                    }
                                                });

                                                return (found ? showValue : null);
                                            }

                                        case 'notin':
                                        case 'notexists':

                                            if (_.isArray(showValue) || _.isObject(showValue)) {
                                                let found = false;
                                                _.each(showValue, (v) => {
                                                    if (!found) {
                                                        _.each(value, (item) => {
                                                            if (item == v) {
                                                                found = true;
                                                            }
                                                        });
                                                    }

                                                });

                                                return !found ? showValue : null;
                                            }
                                            else {
                                                let found = false;
                                                _.each(value, (item) => {
                                                    if (!found) {
                                                        if (item == showValue) {
                                                            found = true;
                                                        }
                                                    }
                                                });

                                                return (!found ? showValue : null);
                                            }
                                    }
                                }
                                else {
                                    switch (operator) {
                                        case '>':
                                            return (showValue > value ? showValue : null);
                                        case '<':
                                            return (showValue < value ? showValue : null);
                                        case '==':
                                            return (value == showValue ? showValue : null);
                                        case '===':
                                            return (value === showValue ? showValue : null);
                                        case '!=':
                                            return (value != showValue ? showValue : null);
                                        case '!==':
                                            return (value !== showValue ? showValue : null);
                                    }
                                }
                            }
                            else if (conditions.length === 2) {

                                if (conditions[1]) {
                                    switch (conditions[1]) {
                                        case 'empty':
                                            if (_.isUndefined(showValue)) return true;


                                            if (_.isNumber(showValue)) {
                                                return showValue == 0;
                                            }
                                            else {
                                                if (_.isArray(showValue)) {
                                                    return !showValue.length;
                                                }
                                                else if (_.isObject(showValue)) {
                                                    return !_.values(showValue).length;
                                                }
                                                else {
                                                    return _.isEmpty(showValue);
                                                }
                                            }


                                        case 'notempty':


                                            if (_.isUndefined(showValue)) return false;

                                            if (_.isNumber(showValue)) {
                                                return showValue > 0;
                                            }
                                            else {
                                                if (_.isArray(showValue)) {
                                                    return showValue.length > 0;
                                                }
                                                else if (_.isObject(showValue)) {
                                                    return _.values(showValue).length > 0;
                                                }
                                                else {
                                                    return !_.isEmpty(showValue);
                                                }
                                            }

                                        case 'exists':
                                            return !_.isUndefined(showValue) && showValue;

                                        case 'notexists':
                                            return _.isUndefined(showValue) || _.isEmpty(showValue);
                                    }
                                }

                                return (showValue == conditions[1] ? showValue : null);
                            }
                        }
                    }
                }
            }

            return false;
        }
        return true;
    });

    function getCondition(conditionOptions, validCount) {
        let isEmpty;
        _.each(conditionOptions,(c) => {
            if (_.isString(c) && c)
            {
                let conditions = c.split('|');
                if (conditions.length > 1) {
                    let hasShowValue = props.formModel ? hasOwnPropertyPath(props.formModel, conditions[0]) : false;
                    let showValue = props.formModel ? getPropertyPath(props.formModel, conditions[0]) : false;
                    let operator, value;

                    if (hasShowValue) {
                        if (conditions.length === 3)
                        {
                            operator = conditions[1];
                            value = conditions[2];

                            if (value.match(/,/g)) {
                                value = value.split(',');
                            }
                            else if (value && value.match(/^true$/ig) ) {
                                value = true;
                            }
                            else if (value && value.match(/^false$/ig) ) {
                                value = false;
                            }

                            let found = false


                            if (_.isArray(value))
                            {
                                switch (operator) {

                                    case 'in':
                                    case 'exists':
                                        found = false;


                                        if (_.isArray(showValue) || _.isObject(showValue)) {

                                            _.each(showValue, (v) => {
                                                if (!found) {
                                                    _.each(value, (item) => {
                                                        if (item == v) {
                                                            found = true;
                                                        }
                                                    });
                                                }
                                            });

                                            validCount = validCount + (found ? 1 : 0);
                                        }
                                        else {

                                            _.each(value, (item) => {
                                                if (!found) {
                                                    if (item == showValue) {
                                                        found = true;
                                                    }
                                                }
                                            });

                                            validCount = validCount + (found ? 1 : 0);
                                        }
                                        break;

                                    case 'notin':
                                    case 'notexists':
                                        found = false;
                                        if (_.isArray(showValue) || _.isObject(showValue)) {

                                            _.each(showValue, (v) => {
                                                if (!found) {
                                                    _.each(value, (item) => {
                                                        if (item == v) {
                                                            found = true;
                                                        }
                                                    });
                                                }

                                            });
                                            validCount = validCount + (!found ? 1 : 0);
                                        }
                                        else {
                                            _.each(value, (item) => {
                                                if (!found) {
                                                    if (item == showValue) {
                                                        found = true;
                                                    }
                                                }
                                            });

                                            validCount = validCount + (!found ? 1 : 0);
                                        }

                                        break;
                                } // End switch
                            }
                            else {
                                switch (operator) {
                                    case '>':
                                        validCount = validCount + (showValue > value ? 1 : 0);
                                        break;
                                    case '<':
                                        validCount = validCount + (showValue < value ? 1 : 0);
                                        break;
                                    case '==':
                                        validCount = validCount + (value == showValue ? 1 : 0);
                                        break;
                                    case '===':
                                        validCount = validCount + (value === showValue ? 1 : 0);
                                        break;
                                    case '!=':
                                        validCount = validCount + (value != showValue ? 1 : 0);
                                        break;
                                    case '!==':
                                        validCount = validCount + (value !== showValue ? 1 : 0);
                                        break;
                                }
                            }

                        } // if condision length 3

                        else if (conditions.length === 2) {

                            switch (conditions[1]) {
                                case 'exists':
                                case 'notempty':


                                    if (_.isNumber(showValue)) {
                                        isEmpty = showValue > 0;
                                    }
                                    else {
                                        if (_.isArray(showValue)) {
                                            isEmpty = showValue.length > 0;
                                        }
                                        else if (_.isObject(showValue)) {
                                            isEmpty = _.values(showValue).length > 0;
                                        }
                                        else {
                                            isEmpty = !_.isEmpty(showValue);
                                        }
                                    }

                                    validCount = validCount + (isEmpty ? 1 : 0);
                                    break;

                                case 'notexists':
                                case 'empty':

                                    if (_.isNumber(showValue)) {
                                        isEmpty = showValue <= 0 ? true : false;
                                    }
                                    else {

                                        if (_.isArray(showValue)) {
                                            isEmpty = showValue.length === 0 ? true : false;
                                        }
                                        else {
                                            isEmpty = _.isEmpty(showValue);
                                        }


                                        // isEmpty = _.isEmpty(showValue);
                                    }

                                    validCount = validCount + (isEmpty === true ? 1 : 0);
                                    break;
                            }

                        }
                    }
                }
            }
        });

        return validCount;
    }

    function getStringCondition(when) {
        if (_.isString(when) && when.match(/([\|,]+?)/g)) {
            let conditions = when.split('|');
            if (conditions.length > 1) {
                // fieldname (0)
                let hasShowValue = props.formModel ? hasOwnPropertyPath(props.formModel, conditions[0]) : false;
                let showValue = props.formModel ? getPropertyPath(props.formModel, conditions[0]) : false;

                if (hasShowValue) {

                    if (conditions.length === 3) {
                        let operator = conditions[1];
                        let value = conditions[2];
                        if (value.match(/,/g)) {
                            value = value.split(',');
                        }
                        else if (value && value.match(/^true$/ig) ) {
                            value = true;
                        }
                        else if (value && value.match(/^false$/ig) ) {
                            value = false;
                        }


                        if (_.isArray(value)) {
                            switch (operator) {
                                case 'empty':
                                    return _.isUndefined(showValue) || _.isEmpty(showValue);

                                case 'notempty':
                                    return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                case 'in':
                                case 'exists':
                                    if (_.isArray(showValue) || _.isObject(showValue)) {
                                        let found = false;
                                        _.each(showValue, (v) => {
                                            if (!found) {
                                                _.each(value, (item) => {
                                                    if (item == v) {
                                                        found = true;
                                                    }
                                                });
                                            }

                                        });

                                        return found ? showValue : null;
                                    }
                                    else {
                                        let found = false;
                                        _.each(value, (item) => {
                                            if (!found) {
                                                if (item == showValue) {
                                                    found = true;
                                                }
                                            }
                                        });

                                        return (found ? showValue : null);
                                    }

                                case 'notin':
                                case 'notexists':

                                    if (_.isArray(showValue) || _.isObject(showValue)) {
                                        let found = false;
                                        _.each(showValue, (v) => {
                                            if (!found) {
                                                _.each(value, (item) => {
                                                    if (item == v) {
                                                        found = true;
                                                    }
                                                });
                                            }

                                        });

                                        return !found ? showValue : null;
                                    }
                                    else {
                                        let found = false;
                                        _.each(value, (item) => {
                                            if (!found) {
                                                if (item == showValue) {
                                                    found = true;
                                                }
                                            }
                                        });

                                        return (!found ? showValue : null);
                                    }
                            }
                        }
                        else {
                            switch (operator) {
                                case '>':
                                    return (showValue > value ? showValue : null);
                                case '>=':
                                    return (showValue >= value ? showValue : null);
                                case '<':
                                    return (showValue < value ? showValue : null);
                                case '<=':
                                    return (showValue <= value ? showValue : null);
                                case '==':
                                    return (value == showValue ? showValue : null);
                                case '===':
                                    return (value === showValue ? showValue : null);
                                case '!=':
                                    return (value != showValue ? showValue : null);
                                case '!==':
                                    return (value !== showValue ? showValue : null);
                            }
                        }
                    }
                    else if (conditions.length === 2) {

                        if (conditions[1]) {
                            switch (conditions[1]) {
                                case 'empty':
                                    return _.isUndefined(showValue) || _.isEmpty(showValue);

                                case 'notempty':
                                    return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                case 'exists':
                                    return !_.isUndefined(showValue) && showValue;

                                case 'notexists':
                                    return _.isUndefined(showValue) || _.isEmpty(showValue);
                            }
                        }

                        return (showValue == conditions[1] ? showValue : null);
                    }
                }
            }
        }


        return false;
    }

    function onComponentCreated() {
        if (hasShowWhen.value) {

            if (_.isObject(props.field.show_when) || _.isArray(props.field.show_when)) {

                _.each(props.field.show_when, (when) => {
                    if (_.isString(when) && when.match(/([\|,]+?)/g)) {
                        let separator = '|';
                        if (!when.match(/\|/g)) {
                            separator = ',';
                        }
                        let conditions = when.split(separator);


                        if (conditions[0] && !hasOwnPropertyPath(props.formModel, conditions[0])) {
                            let oldDirty = _.cloneDeep(route.meta.dirty);
                            setPropertyPath(props.formModel, conditions[0], null, true);
                            route.meta.dirty = oldDirty;
                        }
                    }
                });
            }
            else {
                if (props.field.show_when.match(/([\|,]+?)/g)) {
                    let separator = '|';
                    if (!props.field.show_when.match(/\|/g)) {
                        separator = ',';
                    }

                    let conditions = props.field.show_when.split(separator);

                    if (conditions[0]) {
                        if (!hasOwnPropertyPath(props.formModel, conditions[0])) {

                            let oldDirty = _.cloneDeep(route.meta.dirty);

                            setPropertyPath(props.formModel, conditions[0], null, true);

                            route.meta.dirty = oldDirty;
                        }
                        else {

                        }
                    }

                }
            }
        }
    }

    return {
        hasDisableWhen,
        hasShowWhen,
        getDisableWhen,
        getShowWhen,

        getCondition,
        getStringCondition,
        onComponentCreated,
    }
}
