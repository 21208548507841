/**
 *
 * @param path
 * @param data
 * @returns {Promise<any>}
 */
export function storeGridFilter(path, data) {
    return Neloh.$store.dispatch('setFilterForDataTableCache', {path: path, data: data})
}


/**
 *
 * @param path
 * @param data
 * @returns {Promise<any>}
 */
export function setDataTableCache (path, data)  {
    return Neloh.$store.dispatch('setDataTableCache', {path: path, data: data})
}

export function getDataTableCache(path)  {
    path = path.replace(/\//g, '_');

    if (Neloh.$store.getters.dataTableCache && Neloh.$store.getters.dataTableCache.hasOwnProperty(path)) {
        return Neloh.$store.getters.dataTableCache[path]
    }

    return null;
}


export function getDataTableCacheForFilter(path)  {
    path = path.replace(/\//g, '_');

    if (Neloh.$store.getters.dataTableCache && Neloh.$store.getters.dataTableCache.hasOwnProperty(path)) {
        let c = Neloh.$store.getters.dataTableCache[path];
        if (_.isObject(c)) {

            if (c.grid) {
                if (c.grid.hasOwnProperty('tableData')) {
                    delete c.grid.tableData;
                }
                if (c.grid.hasOwnProperty('tablePagination')) {
                    delete c.grid.tablePagination;
                }
            }

        }

        return c;
    }

    return null;
}

export function getDataTablePostdata (path) {
    path = path.replace(/\//g, '_');
    if (Neloh.$store.getters.dataTableCache && Neloh.$store.getters.dataTableCache.hasOwnProperty(path)) {

        let cache = _.cloneDeep(Neloh.$store.getters.dataTableCache[path]);
        let postData = {};

        if (cache && cache.grid) {
            postData = cache.grid;
            if (cache.grid.currentFilter) {
                postData = Object.assign(postData, cache.grid.currentFilter);
            }
        }

        if (postData.hasOwnProperty('currentFilter')) {
            delete postData.currentFilter;
        }

        if (postData.hasOwnProperty('tableData')) {
            delete postData.tableData;
        }

        if (postData.hasOwnProperty('tablePagination')) {
            delete postData.tablePagination;
        }

        return postData;
    }

    return {};
}

export function afterDataTableResponse (tableRef, path) {
    if (tableRef) {
        let cache = getDataTableCache(path);
        if (cache) {
            tableRef.loadCachedOptions(cache);
        }
    }
}

import {ref, computed, watch, getCurrentInstance, nextTick} from "vue";
import {useRoute} from "~/vue-router";

export function useDatatable(props, context, currentInstance) {
    const inst = getCurrentInstance();
    const route = useRoute();
    const hasTrashed = ref(false);
    const viewTrash = ref(false);
    const selectedTrashCount = ref(0);
    const cleaning = ref(false);
    // const grid = ref({
    //     header: [],
    //     data: []
    // });
    const grid = ref(null);
    const toolbarData = ref([]);
    const tempRoute = ref({});
    const fieldComponents = ref({
        'actions': ['grid-table-actions']
    })

    function handleGridFilter(filterData) {

        inst.refs.gridata.setFilter(filterData, inst.refs.toolbar);

        let cache = getDataTableCache(route.path);
        if (cache && cache.grid) {
            cache.grid.currentFilter = filterData;
            setDataTableCache(route.path, cache);
        } else {
            setDataTableCache(route.path, {grid: {currentFilter: filterData}});
        }
    }

    function handleClearGridFilter() {
        inst.refs.gridata.resetFilter();
        let cache = getDataTableCache(route.path);
        if (cache && cache.grid) {
            cache.grid.currentFilter = null;
            setDataTableCache(route.path, cache);
        }
    }

    function toggleTrash() {
        if (viewTrash.value) {
            viewTrash.value = false;
            inst.refs.gridata.refs.vuetable.currentPage = 1;
            inst.refs.gridata.refs.vuetable.appendParams.filter = {};

            nextTick(() => {
                inst.refs.gridata.refreshGrid();
            });
        } else {
            viewTrash.value = true;
            inst.refs.gridata.refs.vuetable.currentPage = 1;
            inst.refs.gridata.refs.vuetable.appendParams.filter = {
                trashed: true
            };

            nextTick(() => {
                inst.refs.gridata.refreshGrid();
            });
        }
    }

    function refreshGrid() {
        inst.refs.gridata.refreshGrid();
    }

    return {
        hasTrashed,
        viewTrash,
        selectedTrashCount,
        cleaning,
        grid,
        toolbarData,
        tempRoute,
        fieldComponents,
        refreshGrid,
        toggleTrash,
        handleGridFilter,
        handleClearGridFilter
    }
}


