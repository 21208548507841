<template>
    <form-input :name="name" :form-model="formModel" :responseerrors="responseerrors" :validate="validate" :field="field" :key="name"
                @input="(v) => $emit('input', v)"></form-input>
</template>

<script>
import {watch} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default {
    name: "form-email",
    props: {
        ...defaultFieldProps,
        field: {
            required: true,
            default() {
                return {
                    type: 'email'
                }
            }
        },
    },
    emits: ['input'],
    setup(props, context) {
        const {modelvalue} = useFormField(props, context);

        watch(() => modelvalue.value, (n) => {
            context.emit('input', n);
        });

        return {
            modelvalue,
        }
    },
}
</script>

<style scoped>

</style>
