
const userMethods = {
    isGeschaeftsfuerer: () => {

        return false;
    },
    isUsergroup: (name) => {
        let user = Neloh.$store.getters.user;
        if (user.hasOwnProperty('groups')) {

        }
        return false;
    },
    hasPermission: (key) => {
        let user = Neloh.$store.getters.user;

        const inst = Neloh.vueInstance();


        let perm = user && user.hasOwnProperty('permissions') ? inst._context.provides.getPropertyPath(user.permissions, key) : true;

        // wenn objeckt dann true, da ja der key existiert
        if (_.isObject(perm)) {
            return true;
        }

        return _.isBoolean(perm) ? perm : false;
    }
};


_.each(userMethods, (caller, k) => {
    Neloh.setPrototype(k, caller);
    Neloh.provide(k, caller);
});

Vue.mixin({
    methods: userMethods
});
