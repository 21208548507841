<script>
import {defineComponent, inject} from 'vue'

export default defineComponent({
    name: "created-updated-by-at",
    props: {
        date: {},
        user: {},

        type: {
            default: 'created'
        },

        withEditLink: {
            default: false
        }

    },
    setup() {
        const $moment = inject('$moment')


        function formatDate(val, format = 'DD.MM.YYYY') {
            if (val) {
                if (typeof val === "string" && val.match(/^\d{2}\.\d{2}\.\d{4}/g)) {
                    return val;
                }
            }

            let res = val ? $moment(val).format(format) : null;
            return res && res !== 'Invalid Date' ? res : '-'
        }


        return {
            formatDate
        }
    }
})
</script>

<template>
    <div class="created-updated-by-at">
        <template v-if="date && type === 'created'">
            <div>{{ formatDate(date, 'DD.MM.YYYY, HH:mm') }}</div>
            <template v-if="user">
                <template v-if="user.first_name">
                    <div class="createdby">
                        von:
                        <router-link v-if="user.id && withEditLink"
                                     :to="{name: 'users.edit', params: {id: user.id} }"
                                     class="label">{{ user.first_name }} {{ user.surname }}</router-link>
                        <span v-else>{{ user.first_name }} {{ user.surname }}</span>
                    </div>
                </template>
                <template v-else-if="user.username">
                    <div class="createdby">
                        von:
                        <router-link v-if="user.id && withEditLink"
                                     :to="{name: 'users.edit', params: {id: user.id} }"
                                     class="label">{{ user.username }}</router-link>
                        <span v-else>{{ user.username }}</span>
                    </div>
                </template>
            </template>
        </template>
        <template v-else-if="date && type === 'updated'">
            <div>{{ formatDate(date, 'DD.MM.YYYY, HH:mm') }}</div>
            <template v-if="user">
                <template v-if="user.first_name">
                    <div class="updatedby">
                        von:
                        <router-link v-if="user.id && withEditLink"
                                     :to="{name: 'users.edit', params: {id: user.id} }"
                                     class="label">{{ user.first_name }} {{ user.surname }}</router-link>
                        <span v-else>{{ user.first_name }} {{ user.surname }}</span>
                    </div>
                </template>
                <template v-else-if="user.username">
                    <div class="updatedby">
                        von:
                        <router-link v-if="user.id && withEditLink"
                                     :to="{name: 'users.edit', params: {id: user.id} }"
                                     class="label">{{ user.username }}</router-link>
                        <span v-else>{{ user.username }}</span>
                    </div>
                </template>
            </template>
        </template>
    </div>
</template>

<style scoped>

</style>
