export function useNestableHooks(props, context, inst) {

    function hook(name, params) {
        // If the hook has not been registered,
        // we consider the hook as successful
        if (!props.hooks[name]) return true;

        const result = props.hooks[name](params);

        // If the hook does not return anything,
        // we also consider it true
        return result || result === undefined;
    }

    return {
        hook
    };
}
