
// Initialize the annoying-background directive.
export const HtmlAttributes = {
    bind(el, binding, vnode) {
        // Allow users to customise the color by passing an expression.
        const attributes = (_.isObject(binding.value) ? binding.value : {})

        // el might not be present for server-side rendering.
        if (el && !_.isEmpty(attributes)) {
            let name;
            for (name in attributes) {
                if (attributes[name] !== null) el.setAttribute(name, attributes[name]);
            }
        }
    }
};

// You can also make it available globally.
Vue.directive('attr', HtmlAttributes);
