<script>
import {defineComponent, ref, computed, watch, getCurrentInstance, inject, nextTick, reactive, onBeforeUnmount} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {useFormDefaults} from "neloh-ui/mixins/use-form";
import {convertToFormData} from "neloh-ui/mixins/formdata";
import {useStore} from "vuex";
import {truncate} from "@/utils/truncate"
import marked from "marked";
import Prism from "prism-es6";
export default defineComponent({
    name: "ViewMessage",
    emits: ['close', 'cancel', 'success-send'],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            default: null
        },
        inRequest: {
            type: Boolean,
            default: false
        },
    },
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx
        const $http = inject('$http');
        const $events = inject('$events');
        const $moment = inject('$moment')

        const route = useRoute();
        const router = useRouter()
        const store = useStore();

        const loading = ref(false);
        const showing = ref(false);
        const showAnswerForm = ref(false);
        const messageHasSend = ref(false);

        const answerMessage = ref({})

        const message = ref({})
        const mutatedContent = ref('')



        const {
            form,
            modelKey,
            formsaving,
            rendersections,
            primaryKey,
            defaultFormUrl,
            model,
            errors,
            toolbar,
            isDirty,
            isCreate,
            saveAndNew,
            loadFormWithParams,
            loadFormWithRouteParams,
            resetDirty,
            makeDirty,
            formUrl,
            formTabs,
            formFields,
            doNotClearForm,
            primaryKeyValue,
            isUpdate,
            loadForm,
            saveForm,
            updatePrimaryKey,
            formValidationErrors,
            setFormErrors,
            formLoaded,
            onReset,
            onCancel,
            onBeforeSave,
            onAfterSaved,
            onSavedAndNew,
            handleSaveAndNew,
            backToDataTable,
            bindAutosaveEvents,
            executeLiveSave,
        } = useFormDefaults(props, context);


        if (props.inRequest) {
            doNotClearForm.value = true;
        }




        watch(() => props.show, (n, o) => {
            if (n) {
                if (props.id) loadMessage()
            }
            else {
                loading.value = false;
                showing.value = false;
                showAnswerForm.value = false;
                message.value = {};
                answerMessage.value = {}
                mutatedContent.value = null;
                messageHasSend.value = false;
            }
        });


        watch(() => showAnswerForm.value, (n) => {
            if (n) {
                $events.$on('onBeforeLoadForm', disablePageLoading);
                myLoadForm(ajaxLoaded);
            }
        })

        onBeforeUnmount(() => {
            nextTick(() => {
                if (props.inRequest) {
                    doNotClearForm.value = false;
                }
            })
        })




        function disablePageLoading() {
            $events.$emit('pageloading', false);
        }

        const canSendMessage = computed(() => {

            if (_.isString(model.value.subject)) {
                if (!model.value.subject.trim() || model.value.subject.trim().length < 3) {
                    return false;
                }
            }
            else {
                return false;
            }

            if (_.isString(model.value.content)) {
                if (!model.value.content.trim() || model.value.content.trim().length < 3) {
                    return false;
                }
            } else {
                return false;
            }

            return true;
        });


        function ajaxLoaded(response) {
            nextTick(() => {
                resetDirty();
                loading.value = false;
                $events.$emit('pageloading', false);
            });
        }

        function myLoadForm(callback) {
            loadForm(callback, $root.routes('admin.messager.reply', {message: props.id}));
        }

        function onMyCancel() {
            store.dispatch('form/resetFormModel');
            context.emit('close');
        }

        function onMyReset() {
            onReset()
            let addToModel = {};
            addToModel.attachments = reactive([]);
            addToModel.tousers = reactive([]);
            store.dispatch('form/addFormModelData', {model: addToModel});
        }

        function loadMessage() {
            if (loading.value) return;

            loading.value = true;
            showing.value = true;

            $http.post($root.routes('admin.messager.preview'), {
                id: props.id
            }).then((r) => {
                prepareContent(r.data.content);
                message.value = r.data;
                loading.value = false;
            });
        }

        function formatDate(v, format = 'DD.MM.YYYY') {
            return v ? $moment(v).format(format) : null;
        }

        function formatMessageableLabel(messageable) {
            if (messageable.hasOwnProperty('question_short')) {
                if (typeof messageable.question_short === "string" && messageable.question_short.trim()) {
                    return truncate( messageable.question_short.trim(), 100 );
                }

                if (typeof messageable.question === "string" && messageable.question.trim()) {
                    return truncate( messageable.question.trim(), 100 );
                }
            }

            return null;
        }

        function markState(newstate) {
            if (message.value) {
                $http.post($root.routes('admin.messager.change_state'), {id: props.id, state: newstate}).then((r) => {
                    message.value.status = newstate;
                });
            }
        }

        function getFormatedText(value) {
            value = value.split('---');
            return value[0];
        }

        function formatMessage(text) {
            text = _.isString(text) ? text : '';
            // return md.render(text);
            return marked.parse(text, {headerIds: false, mangle: false, xhtml: true});
        }

        function prepareContent(content) {
            if (content) {

                content = content.replace(/(\n{1,}\s*---{3,}\s*\n{1,})/g, "\n\n$1\n\n");
                content = "\n "+content+" \n";

                mutatedContent.value = formatMessage(content);

                nextTick(() => {
                    nextTick(() => {
                        nextTick(() => {
                            if (inst.refs.message) {

                                inst.refs.message.querySelectorAll('pre').forEach(el => {
                                    if (!el.classList.contains('line-numbers')) {
                                        el.classList.add('line-numbers')
                                    }
                                });

                                inst.refs.message.querySelectorAll('a').forEach(el => {
                                    if (!el.getAttribute('target')) {
                                        el.setAttribute('target', '_blank')
                                    }
                                });

                                nextTick(() => {
                                    Prism.highlightAllUnder(inst.refs.message);
                                });
                            }
                        })
                    })
                })
            }
        }



        function makeHighlightSearch(text, withoutMarkdown) {

            if (!withoutMarkdown) {
                // text = md.render(text);
                text = marked.parse(text, {headerIds: false, mangle: true, xhtml: true});
            }

            return text;
        }

        function replyMessage() {

            if (loading.value) return;

            loading.value = true;
            showAnswerForm.value = true;

            $http.post($root.routes('admin.messager.answer'), {
                id: props.id
            }).then((r) => {

                if (r.data.success) {

                    loading.value = false;
                    answerMessage.value = {};
                }

            });
        }


        function submitForm(exit)
        {
            if (formsaving.value) return;

            if (!canSendMessage.value) {
                $root.notification({notification: {msg: 'Nachricht kann nicht gesendet werden. Bitte das Formular prüfen.', type: 'warning'} });
                return;
            }

            $events.$emit('before-submit-documentform');

            let attachments = _.cloneDeep(model.value.attachments);
            let postData = _.cloneDeep(model.value);

            if (postData.attachments) {
                delete postData.attachments;
            }

            let data = new FormData();
            data = convertToFormData(postData, data);

            /*
              Iteate over any file sent over appending the files
              to the form data.
            */
            if (attachments && Array.isArray(attachments)) {
                for (let i = 0; i < attachments.length; i++) {
                    data.append('attachments[' + i + ']', attachments[i].attachment.fileData);
                }
            }

            let routeParams = {
                message: props.id
            };

            saveForm(exit, data, routeParams, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((data) => {

                formsaving.value = false;

                if (data.success) {

                    messageHasSend.value = true;
                    showAnswerForm.value = false;

                    nextTick(() => {
                        context.emit('success-send');
                    });
                }

            })
                .catch(error =>
            {
                formsaving.value = false;
                $root.notification(error);
            });
        }


        function goTo(path) {
            context.emit('close')
            nextTick(() => {
                router.push(path)
            })
        }

        return {
            loading,
            showing,
            message,
            mutatedContent,
            showAnswerForm,
            canSendMessage,


            model,
            form,
            formTabs,
            formFields,
            errors,
            isCreate,
            rendersections,
            isDirty,
            formsaving,
            messageHasSend,
            makeDirty,
            submitForm,
            resetMessage: onMyReset,

            makeHighlightSearch,
            replyMessage,
            getFormatedText,
            markState,
            formatDate,
            formatMessageableLabel,

            goTo
        }

    }
})
</script>

<template>
    <modal :show="showing"
           width="90%"
           height="96%"
           :modal-class="'show-message-modal' + (inRequest ? ' message-modal-in-request' : '')"
           :use-footer="false"
           :use-toolbar="true"
           :show-ok="false"
           @close="$emit('close')"
           @cancel="$emit('cancel')">
        <template v-slot:title>
            <div v-if="loading">Hole Nachricht ...</div>
            <div v-else>Nachricht
            <template v-if="message.id">(ID: {{ message.id }})</template>
            </div>
        </template>
        <template v-slot:toolbar>

            <template v-if="!showAnswerForm">
                <div class="btn-group" v-if="!inRequest">
                    <button class="btn btn-primary"
                            type="button"
                            v-if="message.status != 3 && !messageHasSend"
                            @click.prevent="showAnswerForm = true">
                        Antworten
                    </button>

                    <button class="btn btn-primary" type="button"
                            v-if="message.status < 1 && !messageHasSend"
                            @click.prevent="markState(1)">Als gelesen markierne</button>

                    <button class="btn btn-primary" type="button"
                            v-if="message.status == 1 && messageHasSend"
                            @click.prevent="markState(0)">Als ungelesen markierne</button>

                </div>
                <div class="btn-group">
                    <button class="btn btn-primary close-btn" type="button"
                            v-if="message.status != 3"
                            @click.prevent="markState(3)">Abschließen</button>
                    <button class="btn btn-primary close-btn" type="button"
                            v-if="message.status == 3"
                            @click.prevent="markState(1)">Zurückholen</button>
                </div>
            </template>
            <template v-else>
                <div class="btn-group">
                    <button class="btn btn-primary"
                            type="button"
                            :disabled="formsaving"
                            @click.prevent="showAnswerForm = false">
                        Abbrechen
                    </button>

                    <button type="button" class="btn btn-primary"
                            :disabled="formsaving || !isDirty"
                            @click.prevent="resetMessage">Zurücksetzen</button>

                    <button type="button" class="btn btn-primary"
                            :disabled="formsaving || !canSendMessage"
                            @click.prevent="submitForm">Nachricht senden</button>

                </div>
            </template>

        </template>
        <template v-slot:body>

            <loading :active.sync="loading"
                     color="blue"
                     :width="60"
                     :height="60"
                     :opacity="0.6"
                     :is-full-page="false">
                <template v-slot:after>Hole Nachricht ...</template>
            </loading>

            <template v-if="showing && !loading">
                <div class="message">

                    <template v-if="!showAnswerForm">
                        <form-section :field="{label: 'Nachricht'}">
                            <div class="flex flex-row flex-wrap mb-2">
                                <div class="max-md:w-full w-1/2 sender">

                                    <div class="font-semibold">Absender:</div>
                                    <div>
                                        <template v-if="message.fromuser">
                                            <div class="fromuser">
                                                <template v-if="message.fromuser.surname">
                                                    {{ message.fromuser.first_name }} {{ message.fromuser.surname }}
                                                </template>
                                                <template v-else>
                                                    {{ message.fromuser.username }}
                                                </template>
                                            </div>
                                        </template>
                                        <template v-if="message.frominstitution">
                                            <div class="frominstitution">
                                                <div>{{ message.frominstitution.name }}</div>
                                                {{ message.frominstitution.street }} {{ message.frominstitution.street_number }}<br>
                                                {{ message.frominstitution.zip }} {{ message.frominstitution.city }}
                                            </div>
                                        </template>
                                    </div>

                                </div>
                                <div class="max-md:w-full w-1/2" v-if="!inRequest">
                                    <template v-if="message.messageable && message.messageable_class === 'Neloh\\Models\\Requests\\RequestQuestions'">
                                        <div v-if="message.messageable.link">
                                            <button type="button" class="btn btn-default" @click="goTo(message.messageable.link)">zur Anfrage springen</button>
                                            <div class="text-sm italic py-1">Wenn Sie zur Anfrage springen, wird dieses Fenster automatisch geschlossen.</div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="w-full">
                                <div class="mb-2">
                                    <div class="font-semibold">Betreff:</div>
                                    <div v-html="makeHighlightSearch(message.subject, true)"></div>
                                </div>
                                <div>
                                    <div class="font-semibold">Inhalt der Nachricht:</div>
                                    <div ref="message_content">
                                        <p v-if="mutatedContent" v-html="mutatedContent"></p>
                                        <div v-else><em>Es handelt sicht hierbei um eine Anregung/Anmerkung</em></div>
                                    </div>
                                </div>
                            </div>
                        </form-section>
                        <template v-if="message.messageable && message.messageable_class === 'Neloh\\Models\\Requests\\RequestQuestions'">
                            <form-section :field="{label: 'Betrifft die Anfrage'}">
                                <div class="request-for">
                                    <div class="base">
                                        <h3>Anfrage</h3>
                                        <pre>{{ message.messageable.question_short ?? message.messageable.question }}</pre>

                                        <h3>Antwort</h3>
                                        <pre>{{ message.messageable.answer }}</pre>
                                    </div>

                                    <div class="side">
                                        <template v-if="message.messageable.opinions">
                                            <template v-if="message.messageable.opinions.question_opinion">
                                                <h3>Anregung/Anmerkung zur Anfrage</h3>
                                                <pre>{{ message.messageable.opinions.question_opinion }}</pre>
                                            </template>

                                            <template v-if="message.messageable.opinions.answer_opinion">
                                                <h3>Anregung/Anmerkung zur Antwort</h3>
                                                <pre>{{ message.messageable.opinions.answer_opinion }}</pre>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </form-section>
                        </template>
                    </template>
                    <template v-else-if="showAnswerForm && !inRequest">
                        <form-layout-content :fields="formFields"
                                             :form-model="model"
                                             :responseerrors="errors"
                                             :rendersections="rendersections"
                                             :forget-form-after-unmount="false">
                        </form-layout-content>
                    </template>

                </div>
            </template>
        </template>
    </modal>
</template>

<style scoped>

</style>
