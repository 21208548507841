<script>
import {defineComponent, computed, getCurrentInstance, onMounted, ref, watch, nextTick, onBeforeMount, inject, onBeforeUnmount} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default defineComponent({
    name: "upload-field",
    emits: ['input', 'meta', 'enter'],
    props: {
        ...defaultFieldProps,
        filename: {
            default() {
                return ''
            }
        },
        size: {
            type: Number,
            default() {
                return 0
            }
        },
        mime: {
            default() {
                return ''
            }
        },
        syncModel: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const $events = inject('$events');
        const formatFilesize = inject('formatFilesize')

        const {modelvalue, fieldAttributes, fieldIsDisabled} = useFormField(props, context);

        const uploadFiles = ref({});
        const image = ref(null);
        const mutatedFilename = ref('Noch keine Datei ausgewählt?');
        const mutatedSize = ref(0);
        const initFilename = ref('Noch keine Datei ausgewählt?');
        const initSize = ref(0);
        const isModelFile = ref(false)

        const isMultiple = computed(() => {
            return props.field.hasOwnProperty('multiple') && props.field.multiple
        });

        const isDisabled = computed(() => {
            return props.field.hasOwnProperty('disabled') && props.field.disabled
        });

        const isImage = computed(() => {
            if (uploadFiles.value && uploadFiles.value.hasOwnProperty('type') && uploadFiles.value.type && uploadFiles.value.type.match(/image.*/)) {
                return true;
            }
            return false;
        });

        const canViewFile = computed(() => {
            if (!isMultiple.value) {
                return mutatedSize.value > 0 && isModelFile.value && props.field.hasOwnProperty('viewroute') && props.field.viewroute;
            }

            return false;
        })

        onBeforeUnmount(() => {
            $events.$off('form-reset', resetUpload);
        });

        onBeforeMount(() => {
            $events.$on('form-reset', resetUpload);


            if (props.formModel.hasOwnProperty('upload_image') && _.isObject(props.formModel.upload_image)) {
                uploadFiles.value = props.formModel.upload_image;


                mutatedFilename.value = props.formModel.upload_image.name ? props.formModel.upload_image.name : (props.field.placeholder ? props.field.placeholder : 'Noch keine Datei ausgewählt?');
                mutatedSize.value = props.formModel.upload_image.size > 0 ? props.formModel.upload_image.size : (props.field.size > 0 ? props.field.size : 0);
                initFilename.value = _.cloneDeep(mutatedFilename.value);
                initSize.value = _.cloneDeep(mutatedSize.value);


                if (uploadFiles.value.type.match(/image.*/)) {
                    fileRead(0)
                } else {
                    image.value = null;
                }

            } else {
                mutatedFilename.value = props.filename ? props.filename : (props.field.placeholder ? props.field.placeholder : 'Noch keine Datei ausgewählt?');
                mutatedSize.value = props.size > 0 ? props.size : (props.field.size > 0 ? props.field.size : 0);
                initFilename.value = _.cloneDeep(mutatedFilename.value);
                initSize.value = _.cloneDeep(mutatedSize.value);

                if (props.field.hasOwnProperty('url') && props.field.hasOwnProperty('mime') && _.isString(props.field.mime)) {
                    if (props.field.mime.match(/image.*/)) {
                        uploadFiles.value.type = props.field.mime;
                        image.value = props.field.url;
                    }
                }
            }

            if (!Array.isArray(modelvalue.value) && _.isObject(modelvalue.value) && modelvalue.value) {
                if (modelvalue.value.hasOwnProperty('size')) {
                    mutatedFilename.value = modelvalue.value.filename ? modelvalue.value.filename : (props.field.placeholder ? props.field.placeholder : 'Noch keine Datei ausgewählt?');
                    mutatedSize.value = modelvalue.value.size > 0 ? modelvalue.value.size : 0;
                    initFilename.value = _.cloneDeep(mutatedFilename.value);
                    initSize.value = _.cloneDeep(mutatedSize.value);

                    isModelFile.value = true;
                }
            }

            // Wenn Datei schon existiert das Required weg lassen
            if (mutatedSize.value > 0) {
                props.field.required = null;
            }

        });


        function fileRead(key) {
            let reader = new FileReader();
            reader.addEventListener("load", (e) => {
                image.value = reader.result;

                if (uploadFiles.value) {
                    uploadFiles.value.base64 = reader.result;

                    if (_.isObject(uploadFiles.value._info)) {
                        uploadFiles.value._info.url = reader.result;
                    }
                }

                context.emit('input', uploadFiles.value);
            });

            reader.readAsDataURL(uploadFiles.value.fileData);
        }


        function resetUpload() {
            isModelFile.value = false;
            nextTick(() => {
                uploadFiles.value = {};


                if (!Array.isArray(modelvalue.value) &&
                    _.isObject(modelvalue.value) && modelvalue.value && props.field.syncModel) {
                    if (modelvalue.value.hasOwnProperty('size')) {
                        mutatedFilename.value = modelvalue.value.filename ? modelvalue.value.filename : (props.field.placeholder ? props.field.placeholder : 'Noch keine Datei ausgewählt?');
                        mutatedSize.value = modelvalue.value.size > 0 ? modelvalue.value.size : 0;
                        initFilename.value = _.cloneDeep(mutatedFilename.value);
                        initSize.value = _.cloneDeep(mutatedSize.value);
                    }
                }

                if (modelvalue.value.hasOwnProperty('size') && initSize.value) {
                    isModelFile.value = true;
                }

                if (initFilename.value && initSize.value) {
                    mutatedFilename.value = _.cloneDeep(initFilename.value);
                    mutatedSize.value = _.cloneDeep(initSize.value);
                }

                nextTick(() => {
                    if (initFilename.value && initSize.value) {
                        mutatedFilename.value = _.cloneDeep(initFilename.value);
                        mutatedSize.value = _.cloneDeep(initSize.value);
                    }
                })

            });

        }

        function updateUploadPlaceholder(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                context.emit('input', null);
                return
            }

            uploadFiles.value = {
                fieldname: props.name,
                name: files[0].name,
                lastModified: files[0].lastModified,
                lastModifiedDate: files[0].lastModifiedDate,
                webkitRelativePath: files[0].webkitRelativePath,
                size: files[0].size,
                type: files[0].type,
                fileData: files[0],
            };

            isModelFile.value = false;

            if (uploadFiles.value.type.match(/image.*/)) {
                fileRead(0)
            } else {
                image.value = null;
            }


            if (props.syncModel) {
                modelvalue.value = uploadFiles.value
            }

            if (props.field.syncModel) {
                if (uploadFiles.value.hasOwnProperty('fileData')) {
                    modelvalue.value = uploadFiles.value
                }
            }
            context.emit('input', uploadFiles.value);
            $events.$emit('uploadfile', props.name, uploadFiles.value);
        }


        function viewUploadFile() {
            if (isModelFile.value && modelvalue.value.id) {
                let params = {}, rest = [];

                if (modelvalue.value.people_id) {
                    params.people_id = modelvalue.value.people_id;
                }

                if (modelvalue.value.user_id) {
                    params.user_id = modelvalue.value.user_id;
                }

                if (Object.keys(params).length) {
                    _.each(params, (v, k) => {
                        rest.push(k + '=' + v);
                    })
                }

                window.open(app.routes(props.field.viewroute) + '?file=' + modelvalue.value.id + (rest.length ? '&' + rest.join('&') : ''))
            }
        }

        return {
            formatFilesize,
            mutatedSize,
            mutatedFilename,
            fieldAttributes,
            image,
            isImage,
            uploadFiles,
            fieldIsDisabled,
            modelvalue,
            canViewFile,
            isDisabled,
            isModelFile,
            updateUploadPlaceholder,
            resetUpload,
            viewUploadFile,
        };


    }
})
</script>
<template>
    <form-field :name="name" :form-model="formModel" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>
            <div class="upload-control" :class="{disabled: fieldIsDisabled || isDisabled}">
                <div>
                    <span class="placeholder">
                        <template v-if="!mutatedSize && !uploadFiles.size">
                            Datei auswählen
                        </template>
                        <template v-else>
                            <template v-if="uploadFiles.name">{{ uploadFiles.name }}</template>
                            <template v-else>{{ mutatedFilename }}</template>
                        </template>
                    </span>
                    <badge :classes="{hidden: !mutatedSize && !uploadFiles.size }"
                           :value="(mutatedSize || uploadFiles.size ? (uploadFiles.name ? formatFilesize(uploadFiles.size) : formatFilesize(mutatedSize)) : '???')"/>

                    <!--                    <span class="badge badge-dark" :class="{hidden: !mutatedSize && !uploadFiles.size }">-->
                    <!--	                    -->
                    <!--	                    -->
                    <!--	                    -->
                    <!--	                    -->
                    <!--                    <template v-if="mutatedSize || uploadFiles.size">-->
                    <!--                        <template v-if="uploadFiles.name">{{ formatFilesize(uploadFiles.size) }}</template>-->
                    <!--                        <template v-else>{{ formatFilesize(mutatedSize) }}</template>-->
                    <!--                    </template>-->
                    <!--                    <template v-else>???</template>-->
                    <!--                </span>-->
                    <input type="file"
                           class="form-control"
                           :name="name"
                           v-bind="fieldAttributes"
                           @change="e => updateUploadPlaceholder(e)">


                    <template v-if="canViewFile && !uploadFiles.size">
                        <button type="button"
                                class="btn btn-default btn-preview"
                                v-tooltip="'anzeigen'"
                                @click.stop="viewUploadFile">
                            <svg-icon name="search"/>
                        </button>
                    </template>


                    <button type="button" class="btn btn-primary">
                        <svg-icon name="upload"/>
                    </button>
                </div>

                <template v-if="isImage && field.showPreview && image">
                    <div class="preview">
                        <img :src="image" :class="{ 'show': image}">
                    </div>
                </template>
            </div>
        </template>
        <template v-slot:validation>
            <slot name="validationerror"></slot>
        </template>
    </form-field>
</template>
<style scoped>

</style>
