<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, defineComponent, getCurrentInstance, inject, nextTick, ref} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";

export default defineComponent({
    name: "ProofreadingIndex",
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx

        const route = useRoute();
        const router = useRouter()
        const store = useStore();

        const requestAction = inject('requestAction')
        const setPropertyPath = inject('setPropertyPath');
        const changeBreadcrumb = inject('changeBreadcrumb')
        const $http = inject('$http');
        const $events = inject('$events');

        const showDetails = ref(false);
        const detailsForRequestId = ref(null);



        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
            return true
            // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
        })


        $events.$on('request:show-details', showRequestDetails);

        loadData();


        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            if (route.query.newregistrations) {
                postData.newregistrations = true;
            }

            $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                if (!r.data.grid.url) {
                    r.data.grid.url = $root.currentRouteUrl(route.path);
                }

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                changeBreadcrumb(r.data);

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {

        }

        function handleCreate() {
            router.push({name: 'admin.requests.create'})
        }

        function handleDelete() {

        }

        function showRequestDetails(rowData, link) {
            detailsForRequestId.value = rowData.id;
            showDetails.value = true;
        }

        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,
            showDetails,
            detailsForRequestId,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreate,
            handleDelete,
        }
    }
})
</script>

<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">

<!--        <button class="btn btn-primary"-->
<!--                @click="handleCreate"-->
<!--                type="button"-->
<!--                v-if="canCreateUser">-->
<!--            <svg-icon name="plus"/>-->
<!--            Anfrage anlegen-->
<!--        </button>-->
    </toolbar>


    <data-table v-if="grid" ref="gridata"
                :field-components="fieldComponents"
                :base-data="grid"
                :toolbar="$refs.toolbar"
                @selected-count="(v) => { selectedTrashCount = v }"
                @delete="handleDelete"
                @loaded="onLoaded">

        <template v-slot:question="{row}">
            <template v-if="row.question_short">
                {{ row.question_short }}
            </template>
            <template v-else-if="row.question_long">
                {{ row.question_long }}
            </template>
        </template>

        <template v-slot:institution="{row}">
            <template v-if="row.question && row.question.institution">
                <div class="institution">{{ row.question.institution.name }}</div>
            </template>
            <template v-if="row.question && row.question.holder">
                <div class="holder">Kammerbereich: {{ row.question.holder.name }}</div>
            </template>
        </template>

        <template v-slot:subjects="{row}">
            <template v-if="row.question && row.question.subjects">
                <div class="text-small subjects">
                    <template v-for="r in row.question.subjects">
                        <span>{{ r.title }}</span>
                    </template>
                </div>
            </template>

            <template v-if="row.question && row.question.subject">
                <div class="text-small subjects">
                    <span>{{ row.question.subject.title }}</span>
                </div>
            </template>


        </template>

        <template v-slot:created_at="{row}">


            <div>{{ row.created_at }}</div>

            <template v-if="row.createdby">
                <div class="user">
                    {{ row.createdby.first_name }} {{ row.createdby.surname }}
                </div>
            </template>


        </template>

    </data-table>
</template>

<style scoped>

</style>
