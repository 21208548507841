<template>


    <form-field :name="name" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors" :form-model="formModel">

        <template v-slot:field>

            <div :class="['markdown-editor', {fullscreen: fullscreen}]">
                <div class="markdown-toolbar">
                    <div class="col-left">
                        <div class="btn-group">
                            <button class="btn btn-sm btn-primary" @click="markit('undo')" v-tooltip="'Rückgaänig'">
                                <i class="fa-undo fa" aria-hidden="true"></i>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('redo')" v-tooltip="'Wiederholen'">
                                <i class="fa-repeat fa" aria-hidden="true"></i>
                            </button>
                        </div>


                        <div class="btn-group">
                            <button class="btn btn-sm btn-primary" v-for="h in 6" type="button"
                                    @click="setHeading(h)" v-tooltip="'Überschrift '+ h">
                                H{{ h }}
                            </button>
                        </div>


                        <div class="btn-group">
                            <button class="btn btn-sm btn-primary" @click="markit('bold')" v-tooltip="'Fett'">
                                <b>B</b>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('italic')" v-tooltip="'Schräg'">
                                <em>I</em>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('underline')" v-tooltip="'Unterstrichen'">
                                <u>U</u>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('subscript')" v-tooltip="'subscript'">
                                <i class="fa fa-subscript" aria-hidden="true"></i>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('superscript')" v-tooltip="'superscript'">
                                <i class="fa fa-superscript" aria-hidden="true"></i>
                            </button>
                        </div>


                        <div class="btn-group">
                            <button class="btn btn-sm btn-primary" @click="markit('insertHorizontalRule')" v-tooltip="'Horizontale Linie'">
                                <span class="fa fa fa-minus"></span>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('insertOrderedList')" v-tooltip="'Numerische Liste'">
                                <span class="fa fa-list-ol"></span>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('insertUnorderedList')" v-tooltip="'Einfache Liste'">
                                <span class="fa fa-list"></span>
                            </button>
                        </div>


                        <button class="btn btn-sm btn-primary" @click="insertTable" v-tooltip="'Tabelle einfügen'">
                            <i class="fa fa-table" aria-hidden="true"></i>
                        </button>


                        <div class="btn-group">
                            <button class="btn btn-sm btn-primary" @click="markit('link')" v-tooltip="'Link einfügen'">
                                <span class="fa fa-link"></span>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('unlink')" v-tooltip="'Link entfernen'">
                                <span class="fa fa-chain-broken"></span>
                            </button>
                            <button class="btn btn-sm btn-primary" @click="markit('image')" v-tooltip="'Bild einfügen'">
                                <span class="fa fa-image"></span>
                            </button>
                        </div>


                    </div>
                    <div class="col-right">
                        <button class="btn btn-sm btn-primary" :class="{active: fullscreen}"
                                v-tooltip="'Vollbild'"
                                @click="toggleFullscreen">
                            <i v-if="!fullscreen" class="fa fa-arrows-alt" aria-hidden="true"></i>
                            <i v-else class="fa fa-compress" aria-hidden="true"></i>
                        </button>

                        <button class="btn btn-sm btn-primary" :class="{active: preview}"
                                v-tooltip="'Vorschau'"
                                @click="togglePreview">
                            <span class="fa fa-eye"></span>
                        </button>
                        <button v-if="preview"
                                v-tooltip="'Html Code/Vorschau'"
                                class="btn btn-sm btn-primary" :class="{active: previewhtml}"
                                @click="toggleHtml">
                            <span class="fa fa-code"></span>
                        </button>
                    </div>

                </div>
                <div class="markdown-content">
                    <div class="row">
                        <div class="col-md-12 editor editor-only" v-if="!preview">
                            <div v-if="!forceMarkdown" ref="content" contenteditable></div>
                            <textarea v-else
                                      :name="name"
                                      ref="formfield"
                                      class="form-control"
                                      v-bind="fieldAttributes"
                                      :value="mutatedValue"
                                      spellcheck="false" v-autosize
                                      @keyup="(e) => updateValue(e.currentTarget.value)"></textarea>
                        </div>
                        <template v-else>
                            <div class="col-md-6 editor">
                                <div v-if="!forceMarkdown" ref="content" contenteditable></div>
                                <textarea
                                    v-else v-autosize
                                    :name="name"
                                    ref="formfield"
                                    class="form-control"
                                    v-bind="fieldAttributes"
                                    :value="mutatedValue" spellcheck="false"
                                    @keyup="(e) => { updateValue(e.currentTarget.value); compileMarkdown() }"></textarea>
                            </div>
                            <div class="col-md-6 editor-preview">
                                <div v-if="!previewhtml" v-html="compiledMarkdown"></div>
                                <div class="html-code" v-else>
                                    <vue-code-highlight language="html">{{ compiledMarkdown }}</vue-code-highlight>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

        </template>


    </form-field>
</template>

<script>

import TurndownService from "turndown";
import RangeHandler from './Markdown/handler'
import FormField from "./../../mixins/form-field";
import marked from "marked";
import {component as VueCodeHighlight} from '~/vue-code-highlight';


export default {
    name: "markdown",
    mixins: [FormField],
    components: {
        VueCodeHighlight
    },
    props: {
        name: {
            required: true
        },
        field: {
            required: false,
            default() {
                return {}
            }
        },
        validate: {
            type: Boolean,
            default: false
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            isMounted: false,
            fullscreen: false,
            preview: false,
            previewhtml: false,
            mutatedValue: '',
            turndownService: null,
            touchHandler: null,
            range: null,
            imageUrl: '',
            compiledMarkdown: '',
        };
    },
    computed: {
        forceMarkdown() {
            return this.field.forcemarkdown === true;
        },
    },
    watch: {
        preview() {
            this.compileMarkdown();
        },
        mutatedValue() {
            this.compileMarkdown();
        }
    },
    beforeDestroy() {
        if (!this.forceMarkdown) {
            window.removeEventListener('touchend', this.touchHandler);
        }

    },
    beforeMount() {
        this.turndownService = new TurndownService;
        this.mutatedValue = _.isString(this.modelvalue) ? this.modelvalue : '';
        if (this.forceMarkdown) {
            this.mutatedValue = this.turndownService.turndown(this.mutatedValue);
        }
    },

    mounted() {
        if (!this.forceMarkdown) {
            const content = this.$refs.content;
            content.innerHTML = this.mutatedValue;
            content.addEventListener('mouseup', this.saveCurrentRange, false);
            content.addEventListener('keyup', () => {

                if (this.forceMarkdown) {
                    this.updateValue(this.turndownService.turndown(content.innerHTML));
                } else {
                    this.updateValue(content.innerHTML);
                }

                this.saveCurrentRange();
            }, false);

            content.addEventListener('mouseout', (e) => {
                if (e.target === content) {
                    this.saveCurrentRange();
                }
            }, false);

            this.touchHandler = (e) => {
                if (content.contains(e.target)) {
                    this.saveCurrentRange();
                }
            };
        }

        this.isMounted = true;
    },

    methods: {

        compileMarkdown() {
            if (this.preview) {
                if (this.previewhtml && !this.forceMarkdown) {
                    this.compiledMarkdown = this.$refs.content.innerHTML;
                } else if (this.previewhtml && this.forceMarkdown) {
                    this.compiledMarkdown = marked(this.mutatedValue, {sanitize: true});
                } else {
                    this.compiledMarkdown = marked(this.mutatedValue);
                }
            }
        },

        togglePreview() {
            this.preview = !this.preview;
        },
        toggleHtml() {
            this.previewhtml = !this.previewhtml;
        },

        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
        },

        updateValue(value) {
            this.mutatedValue = value;
            this.modelvalue = value;
            this.$emit('input', value)
        },

        focus() {
            this.$refs.content.focus()
        },

        getCurrentRange() {
            return this.range
        },

        saveCurrentRange() {
            const selection = window.getSelection ? window.getSelection() : document.getSelection()
            if (!selection.rangeCount) {
                return
            }

            const content = this.$refs.content;
            for (let i = 0; i < selection.rangeCount; i++) {
                const range = selection.getRangeAt(0);
                let start = range.startContainer;
                let end = range.endContainer;
                // for IE11 : node.contains(textNode) always return false
                start = start.nodeType === Node.TEXT_NODE ? start.parentNode : start;
                end = end.nodeType === Node.TEXT_NODE ? end.parentNode : end;
                if (content.contains(start) && content.contains(end)) {
                    this.range = range;
                    break
                }
            }
        },

        restoreSelection() {
            const selection = window.getSelection ? window.getSelection() : document.getSelection();
            selection.removeAllRanges();
            if (this.range) {
                selection.addRange(this.range);
            } else {

                if (!this.forceMarkdown) {
                    const content = this.$refs.content;
                    const div = document.createElement('div');
                    const range = document.createRange();
                    content.appendChild(div);
                    range.setStart(div, 0);
                    range.setEnd(div, 0);
                    selection.addRange(range);
                    this.range = range
                } else {
                    const range = document.createRange();
                    selection.addRange(range);
                    this.range = range;
                }


            }
        },


        createLink() {
            if (!this.url) {
                return
            }
            this.markit('createLink', this.url);
            this.url = null
        },

        insertImageUrl() {
            if (!this.imageUrl) {
                return;
            }
            this.markit('insertImage', this.imageUrl);
            this.imageUrl = null;
        },

        setHeading(heading) {
            this.markit('formatBlock', `h${heading}`)
        },

        insertTable() {
            let table = "Header 1 | Header 2\n" +
                "--------------|---------------\n" +
                "Demo Content | Demo Content\n" +
                "Demo Content | Demo Content\n";

            this.markit('insertTEXT', table)
        },
        modifySelection(command, arg) {

            let before = '',
                after = '',
                subCursorPos = 0;

            //
            switch (command) {
                case 'bold':
                    before = '**';
                    after = '**';
                    subCursorPos = 2;
                    break;

                case 'italic':
                    before = '*';
                    after = '*';

                    subCursorPos = 1;
                    break;

                case 'underline':
                    before = '---';
                    break;

                case 'insertHorizontalRule':
                    before = '---';
                    break;

                case 'insertOrderedList':
                    before = '- ';
                    break;

                case 'insertUnorderedList':
                    before = '1. ';
                    break;

                case 'formatBlock':

                    if (arg == 'h1') {
                        before = '# ';
                    } else if (arg == 'h2') {
                        before = '## ';
                    } else if (arg == 'h3') {
                        before = '### ';
                    } else if (arg == 'h4') {
                        before = '#### ';
                    } else if (arg == 'h5') {
                        before = '##### ';
                    } else if (arg == 'h6') {
                        before = '###### ';
                    }

                    break;

                case 'createLink':

                    break;

                case 'insertImage':

                    break;

                case 'insertTEXT':
                default:

                    before = arg;

                    break;
            }


            let textarea = this.$refs.formfield;

            if ('selectionStart' in textarea) {
                let newText, start = textarea.selectionStart,
                    end = textarea.selectionEnd;


                let beforeText = textarea.value.substring(0, start);
                let innerText = textarea.value.substring(start, end);
                let afterext = textarea.value.substring(end);

                newText = beforeText + before + innerText + after + afterext;

                let newCursorPos = start + (before.length) + (innerText ? innerText.length : 0) + after.length;

                textarea.value = newText;
                textarea.selectionEnd = newCursorPos - subCursorPos;

                textarea.blur();
                textarea.focus();


                // textarea.scollIntoView()
            } else {  // Internet Explorer before version 9
                // create a range from the current selection
                let textRange = document.selection.createRange();
                // check whether the selection is within the textarea
                let rangeParent = textRange.parentElement();
                if (rangeParent === textarea) {
                    textRange.text = before + textRange.text + after;
                }
            }
        },
        markit(command, arg) {
            this.restoreSelection();
            if (this.range) {

                if (this.forceMarkdown) {
                    this.modifySelection(command, arg);
                } else {
                    let range = new RangeHandler(this.range);
                    range.execCommand('insertTEXT', arg)
                }


            }

            if (this.forceMarkdown) {
                this.updateValue(this.$refs.formfield.value);
            } else {
                this.updateValue(this.$refs.content.innerHTML);
            }


            // let area = this.$refs.formfield;
            // let areaSelection = area.getSelection();
            //
            // switch (code) {
            //     case 'bold':
            //
            //         break;
            //
            //     case 'italic':
            //
            //         break;
            //
            //     case 'underline':
            //
            //         break;
            //
            //
            //
            //
            // }


        }
    }
}
</script>

<style lang="scss" scoped>
</style>
