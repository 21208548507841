<script>
import {defineComponent, getCurrentInstance, watch, ref, onMounted, onBeforeUnmount, nextTick} from 'vue'
import {createPopper} from '~/@popperjs/core';
const popperProps = {
    strategy: 'fixed',
    modifiers: [
        {
            name: 'preventOverflow',
            options: {
                altAxis: true,
                tether: true,
                boundary: document.querySelector('#app'),
            },
        },
        {
            name: 'offset',
            options: {
                offset: [5, 5],
            },
        },
        {
            name: 'flip',
            enabled: true,
            options: {
                fallbackPlacements: ['top', 'right', 'left', 'bottom'],
            },
        },
    ]
}

export default defineComponent({
    name: "popover",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        reference: {},
        horizontal: {
            type: Boolean,
            default: false
        },
    },
    emits: ['hidden'],
    setup(props, ctx) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;

        const open = ref(false);
        let popperJs = null;
        watch(() => props.show, (n) => {

            if (n) {
                open.value = n;
                createPopperInstance();
                nextTick(() => {
                    updatePopper();
                });
            }
            else {
                open.value = n;
            }
        });




        function updatePopper() {
            if (popperJs) {
                popperJs.update()
            }
        }

        function destroyPopper() {
            if (popperJs) {
                popperJs.destroy()
                popperJs = null;
            }
        }

        function createPopperInstance() {

            destroyPopper()

            // const customBoundary = this.$root.$el;

            let appEl = document.querySelector('#app');
            let opts = popperProps;

            if (appEl) {
                appEl.appendChild(inst.refs.picker);
            } else {
                $root.$el.appendChild(inst.refs.picker);
            }

			let usedReference = props.reference;

			if (Array.isArray(usedReference)) {
				 if (usedReference.length === 1) {
					 usedReference = usedReference[0]
				 }
			}

	        usedReference.classList.add('popover-handler');

	        nextTick(() => {
		        popperJs = createPopper(usedReference, inst.refs.picker, opts);

		        nextTick(() => {
			        updatePopper()
		        })
	        });


        }

        function onClose(e) {
	        if (open.value) {
		        ctx.emit('hidden');
	        }
        }

        return {
            open,
            onClose
        }
    }
})
</script>

<template>
<!--    <teleport to="#app">-->
        <div v-show="open"
             ref="picker"
             class="popover memo-popover"
             v-click-outside="{handler: onClose, exclude: ['.popover', '.btn-note', '.memo-popup', '.popover-handler', 'textarea']}">
            <div class="popover-inner" :class="{horizontal: horizontal}"><slot/></div>
        </div>
<!--    </teleport>-->
</template>

<style scoped>

</style>
