<script>
import {computed, defineComponent, getCurrentInstance, inject, ref, watch} from 'vue'
import {useRoute} from "~/vue-router";
import {defaultFieldProps, useFormField} from "@/mixins/use-form-field";
import {useStore} from "~/vuex";

export default defineComponent({
    name: "field-api-tokens",
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const store = useStore()

        const {allowMultiple, fieldIsDisabled, modelName, defaultAttributes, modelvalue, fieldAttributes} = useFormField(props, context);

        const route = useRoute();
        const $http = inject('$http');
        const $events = inject('$events');
        const loading = ref(false);

        function regenerate(e) {
            if (loading.value ) return;

            loading.value = true;

            $http.post(app.routes('admin.institutions.api-token.generate', { institution: props.formModel.id }) ).then((r) => {
                if (r.data.success) {
                    if (r.data.auth_token) {
                        app.$set(props.formModel, 'auth_token', r.data.auth_token);
                        app.$set(props.formModel, 'sec_key', r.data.sec_key);
                    }
                }

                $root.notification(r.data);

                loading.value = false;
            }).catch(e => {
                loading.value = false;
            });
        }

        return {
            loading,
            regenerate
        }
    }
})
</script>

<template>
    <form-field :name="name" :form-model="formModel" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>

            <div>
                <loading :active="loading === true"
                         color="blue"
                         :width="20"
                         :height="20"
                         :opacity="0.6"
                         :is-full-page="false">
                    <template v-slot:after>
                        <div class="m">Erzeuge neuen API-Token ...</div>
                    </template>
                </loading>

                <template v-if="formModel.auth_token && formModel.sec_key">
                    <div class="auth-token-label">Token</div>
                    <div class="auth-token"><pre>{{ formModel.auth_token }}</pre></div>

                    <div class="auth-sec-label">Secure Key</div>
                    <div class="auth-sec"><pre>{{ formModel.sec_key }}</pre></div>
                </template>
                <template v-else>
                    <div class="alert alert-info">
                        Sie haben noch keinen API-Token
                    </div>
                </template>

                <button type="button" class="btn btn-primary" :disabled="loading" @click.prevent="regenerate">
                    Neuen API-Token erzeugen
                </button>
            </div>


        </template>
    </form-field>
</template>

<style scoped>

</style>
