<template>
    <div class="grid-container layout">
        <loading :active="loading === true"
                 color="blue"
                 :width="20"
                 :height="20"
                 :opacity="0.6"
                 :is-full-page="false">
            <template v-slot:after>
                <div class="m">Hole Daten...</div>
            </template>
        </loading>

        <table :class="['table fixed-layout','vuetable', css.tableClass]">
            <thead class="grid-header">
            <tr>
                <th class="state-column"></th>

                <th v-if="hasActions" class="checkbox-column">
                    <div>
                        <div class="checkbox">
                            <div class="checkable-wrapper">
                                <input ref="check_all"
                                       type="checkbox"
                                       value="1"
                                       @change.prevent="toggleAllCheckboxes"
                                       class="nodirty transparent"/>
                                <span></span>
                            </div>
                        </div>
                    </div>

                </th>

                <th class="detail-toggle-column" v-if="useDetailRow"></th>

                <template v-for="field in tableFields" :key="'header-'+field.name">
                    <template v-if="field.visible">
                        <template v-if="isSpecialField(field.name)">
                            <th v-if="field.extractedName === '__checkbox'"
                                :class="[
                                    'text-center',
                                    'vuetable-th-checkbox',
                                    'vuetable-th-checkbox-'+trackBy,
                                    field.titleClass]"
                                :width="field.width ? field.width : '2%'">
                                <div>
                                    <div class="checkbox">
                                        <div class="checkable-wrapper">
                                            <input type="checkbox"
                                                   :name="extractArgs(field.name)"
                                                   @change="toggleAllCheckboxes(extractArgs(field.name), $event)"
                                                   :checked="checkCheckboxesState(extractArgs(field.name))"
                                                   class="nodirty transparent">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th v-if="field.extractedName === '__component'"
                                @click="orderBy(field, $event)"
                                :class="['vuetable-th-component-'+trackBy, field.titleClass, {'is-sorted': isInCurrentSortGroup(field), 'sortable': isSortable(field)}]"
                                :width="field.width ? field.width : 'auto'"
                                :ref="'head_'+ extractArgs(field.name)"
                            >
                                <div>
                                    <template v-if="isSortable(field)">

                                        <template v-if="(sd = sortDirection(field))">
                                            <svg viewBox="0 0 24 24"
                                                 width="16"
                                                 height="16"
                                                 stroke-width="1.5"
                                                 fill="none"
                                                 class="tabler-icon"
                                                 role="img"
                                                 aria-hidden="true"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 stroke="currentColor"
                                                 preserveAspectRatio="xMidYMid meet">
                                                    <template v-if="sd === 'asc'">
                                                        <path d="M4 6l7 0"></path><path d="M4 12l7 0"></path><path d="M4 18l9 0"></path><path d="M15 9l3 -3l3 3"></path><path d="M18 6l0 12"></path>
                                                    </template>
                                                    <template v-else>
                                                        <path d="M4 6l9 0"></path><path d="M4 12l7 0"></path><path d="M4 18l7 0"></path><path d="M15 15l3 3l3 -3"></path><path d="M18 6l0 12"></path>
                                                    </template>
                                            </svg>
                                        </template>
                                        <template v-else-if="defaultOrderDicection">
                                            <svg viewBox="0 0 24 24"
                                                 width="16"
                                                 height="16"
                                                 stroke-width="1.5"
                                                 fill="none"
                                                 class="tabler-icon"
                                                 role="img"
                                                 aria-hidden="true"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 stroke="currentColor"
                                                 preserveAspectRatio="xMidYMid meet">
                                                <template v-if="defaultOrderDicection === 'asc'">
                                                    <path d="M4 6l7 0"></path><path d="M4 12l7 0"></path><path d="M4 18l9 0"></path><path d="M15 9l3 -3l3 3"></path><path d="M18 6l0 12"></path>
                                                </template>
                                                <template v-else>
                                                    <path d="M4 6l9 0"></path><path d="M4 12l7 0"></path><path d="M4 18l7 0"></path><path d="M15 15l3 3l3 -3"></path><path d="M18 6l0 12"></path>
                                                </template>
                                            </svg>
                                        </template>


<!--                                        <svg-icon :name="(sortDirection(field) ? (sortDirection(field) === 'asc' ? 'sort-ascending' : 'sort-descending') : (defaultOrderDicection === 'asc' ? 'sort-ascending' : 'sort-descending'))"/>-->
                                    </template>



                                    <div class="label" v-html="renderTitle(field)"></div>
                                </div>
                            </th>
                            <th v-if="field.extractedName === '__slot'"
                                @click="orderBy(field, $event)"
                                :class="['vuetable-th-slot-'+ extractArgs(field.name), field.titleClass, {
                                'is-sorted': isInCurrentSortGroup(field),
                                'sortable': isSortable(field)
                                }]"
                                :width="field.width ? field.width : 'auto'"
                            >
                                <div>
                                    <template v-if="isSortable(field)">

                                        <template v-if="(sd = sortDirection(field))">
                                            <svg viewBox="0 0 24 24"
                                                 width="16"
                                                 height="16"
                                                 stroke-width="1.5"
                                                 fill="none"
                                                 class="tabler-icon"
                                                 role="img"
                                                 aria-hidden="true"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 stroke="currentColor"
                                                 preserveAspectRatio="xMidYMid meet">
                                                    <template v-if="sd === 'asc'">
                                                        <path d="M4 6l7 0"></path><path d="M4 12l7 0"></path><path d="M4 18l9 0"></path><path d="M15 9l3 -3l3 3"></path><path d="M18 6l0 12"></path>
                                                    </template>
                                                    <template v-else>
                                                        <path d="M4 6l9 0"></path><path d="M4 12l7 0"></path><path d="M4 18l7 0"></path><path d="M15 15l3 3l3 -3"></path><path d="M18 6l0 12"></path>
                                                    </template>
                                            </svg>
                                        </template>
                                        <template v-else-if="defaultOrderDicection">
                                            <svg viewBox="0 0 24 24"
                                                 width="16"
                                                 height="16"
                                                 stroke-width="1.5"
                                                 fill="none"
                                                 class="tabler-icon"
                                                 role="img"
                                                 aria-hidden="true"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 stroke="currentColor"
                                                 preserveAspectRatio="xMidYMid meet">
                                                <template v-if="defaultOrderDicection === 'asc'">
                                                    <path d="M4 6l7 0"></path><path d="M4 12l7 0"></path><path d="M4 18l9 0"></path><path d="M15 9l3 -3l3 3"></path><path d="M18 6l0 12"></path>
                                                </template>
                                                <template v-else>
                                                    <path d="M4 6l9 0"></path><path d="M4 12l7 0"></path><path d="M4 18l7 0"></path><path d="M15 15l3 3l3 -3"></path><path d="M18 6l0 12"></path>
                                                </template>
                                            </svg>
                                        </template>


<!--                                        <svg-icon :name="(sortDirection(field) ? (sortDirection(field) === 'asc' ? 'sort-ascending' : 'sort-descending') : (defaultOrderDicection === 'asc' ? 'sort-ascending' : 'sort-descending'))"/>-->
                                    </template>
                                    <div class="label" v-html="renderTitle(field)"></div>
                                </div>
                            </th>
                            <th v-if="field.extractedName === '__sequence'"
                                :class="['vuetable-th-sequence', field.titleClass || '']"
                                :width="field.width ? field.width : 'auto'">
                                <div>
                                    <div class="label" v-html="renderTitle(field)"></div>
                                </div>
                            </th>
                            <th v-if="notIn(field.extractedName, ['__sequence', '__checkbox', '__component', '__slot'])"
                                :class="['vuetable-th-'+field.name, field.titleClass || '']"
                                :width="field.width ? field.width : '2%'">
                                <div>
                                    <div class="label" v-html="renderTitle(field)"></div>
                                </div>
                            </th>
                        </template>
                        <template v-else>
                            <th @click.prevent="orderBy(field, $event)"
                                :id="'_' + field.name"
                                :class="['vuetable-th-'+field.name, field.titleClass,  {
                                        'is-sorted': isInCurrentSortGroup(field),
                                        'sortable': isSortable(field)
                                    }]"
                                :width="field.width ? field.width : 'auto'">
                                <div>

                                    <template v-if="isSortable(field)">


                                        <template v-if="(sd = sortDirection(field))">
                                            <svg viewBox="0 0 24 24"
                                                 width="16"
                                                 height="16"
                                                 stroke-width="1.5"
                                                 fill="none"
                                                 class="tabler-icon"
                                                 role="img"
                                                 aria-hidden="true"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 stroke="currentColor"
                                                 preserveAspectRatio="xMidYMid meet">

                                                    <template v-if="sd === 'asc'">
                                                        <path d="M4 6l7 0"></path><path d="M4 12l7 0"></path><path d="M4 18l9 0"></path><path d="M15 9l3 -3l3 3"></path><path d="M18 6l0 12"></path>
                                                    </template>
                                                    <template v-else>
                                                        <path d="M4 6l9 0"></path><path d="M4 12l7 0"></path><path d="M4 18l7 0"></path><path d="M15 15l3 3l3 -3"></path><path d="M18 6l0 12"></path>
                                                    </template>

                                            </svg>
                                        </template>
                                        <template v-else-if="defaultOrderDicection">
                                            <svg viewBox="0 0 24 24"
                                                 width="16"
                                                 height="16"
                                                 stroke-width="1.5"
                                                 fill="none"
                                                 class="tabler-icon"
                                                 role="img"
                                                 aria-hidden="true"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 stroke="currentColor"
                                                 preserveAspectRatio="xMidYMid meet">
                                                <template v-if="defaultOrderDicection === 'asc'">
                                                    <path d="M4 6l7 0"></path><path d="M4 12l7 0"></path><path d="M4 18l9 0"></path><path d="M15 9l3 -3l3 3"></path><path d="M18 6l0 12"></path>
                                                </template>
                                                <template v-else>
                                                    <path d="M4 6l9 0"></path><path d="M4 12l7 0"></path><path d="M4 18l7 0"></path><path d="M15 15l3 3l3 -3"></path><path d="M18 6l0 12"></path>
                                                </template>
                                            </svg>
                                        </template>
<!--                                        <svg-icon :name="(sortDirection(field) ? (sortDirection(field) === 'asc' ? 'sort-ascending' : 'sort-descending') : (defaultOrderDicection === 'asc' ? 'sort-ascending' : 'sort-descending'))"/>-->
                                    </template>


                                    <span class="label" v-html="renderTitle(field)"></span>
                                </div>
                            </th>
                        </template>
                    </template>
                </template>
            </tr>
            </thead>


            <tbody v-cloak class="vuetable-body grid-data">
            <template v-for="(item, index) in tableData">

                <tr @dblclick="onRowDoubleClicked(item, $event)"
                    :item-index="index"
                    @click="onRowClicked(item, $event)"
                    :render="onRowChanged(item)"
                    :class="(index%2 ? 'even ' : 'odd ')+' '+ onRowClass(item, index)">

                    <template v-if="item.hasOwnProperty('_rowstate') && isArray(item._rowstate)">
                        <td v-if="item._rowstate[1]"
                            v-tooltip.right="item._rowstate[1]"
                            class="state-column" :class="item._rowstate[0]"></td>
                        <td v-else class="state-column" :class="item._rowstate[0]"></td>
                    </template>
                    <template v-else>
                        <td class="state-column"></td>
                    </template>



                    <template v-if="hasActions">
                        <td>
                            <div class="checkbox">
                                <div class="checkable-wrapper">
                                    <input type="checkbox"
                                           @change.prevent="(e) => toggleCheckbox(item, item, e)"
                                           :checked="rowSelected(item)"
                                           class="nodirty transparent">
                                    <span></span>
                                </div>
                            </div>
                        </td>
                    </template>


                    <template v-if="useDetailRow">
                        <td class="detail-toggle-column"
                            data-label="Details"
                            :class="{'details-open': isVisibleDetailRow(item[trackBy])}">
                            <div v-if="detailRowOnly(item) && item._expandable"
                                 @click.prevent.stop="e => onToggleDetails(item, index, e)"
                                 v-tooltip="'Details'">
                                <svg viewBox="0 0 24 24"
                                     width="16"
                                     height="16"
                                     stroke-width="1.5"
                                     fill="none"
                                     class="tabler-icon"
                                     role="img"
                                     aria-hidden="true"
                                     stroke-linecap="round"
                                     stroke-linejoin="round"
                                     stroke="currentColor"
                                     preserveAspectRatio="xMidYMid meet">
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>
                            </div>
                        </td>
                    </template>



                    <template v-for="field in tableFields">
                        <template v-if="field.visible">
                            <template v-if="isSpecialField(field.name)">

                                <td v-if="field.extractedName === '__sequence'"
                                    :data-label="field.title"
                                    :class="['vuetable-sequence', titleColumnClass(field)]"
                                    :rel="'col-'+ field.name"
                                    :width="field.width ? field.width : 'auto'">
                                    <div :class="titleColumnClass(field)" v-html="renderSequence(index)"></div>
                                </td>

                                <td v-if="field.extractedName === '__handle'"
                                    :data-label="field.title"
                                    :class="['vuetable-handle',  titleColumnClass(field)]"
                                    :width="field.width ? field.width : 'auto'"
                                    :rel="'col-'+ field.name">
                                    <div :class="titleColumnClass(field)" v-html="renderIconTag(['handle-icon', css.handleIcon])"></div>
                                </td>

                                <td v-if="field.extractedName === '__checkbox'"
                                    :class="['text-center','vuetable-checkboxes',  titleColumnClass(field)]"
                                    :width="field.width ? field.width : 'auto'"
                                    :rel="'col-'+ field.name">
                                    <div class="checkbox">
                                        <div class="checkable-wrapper">
                                            <input type="checkbox"
                                                   @change="toggleCheckbox(item, field.name, $event)"
                                                   :checked="rowSelected(item, field.name)"
                                                   class="nodirty transparent">
                                            <span></span>
                                        </div>
                                    </div>
                                </td>

                                <td v-if="field.extractedName === '__component'"
                                    :data-label="field.title ? field.title : (extractArgs(field.name) === 'grid-table-actions' ? 'Actions' : '')"
                                    @click="onCellClicked(item, field, $event, index)"
                                    :class="['vuetable-component',  titleColumnClass(field)]"
                                    :width="field.width ? field.width : 'auto'"
                                    :rel="'col-'+ field.name">

                                    <template v-if="extractArgs(field.name) === 'grid-table-actions'">
                                        <div class="actions" :class="{'with-customs': hasCustomActions}">
                                            <component :is="extractArgs(field.name)"
                                                       :has-custom-action="hasCustomActions"
                                                       :row-data="item"
                                                       :row-index="index"
                                                       :row-field="field.sortField"
                                                       :label-column="labelColumn"
                                                       :field-events="fieldEvents"
                                                       :key="'component-'+ item[trackBy] +'-'+ field.name"
                                                       @delete="handleDelete"
                                                       @restore="handleRestore"
                                            ></component>

                                            <slot name="custom_actions"
                                                  v-bind:item="item"
                                                  v-bind:index="index"
                                                  v-bind:field="field"
                                                  :row-data="item"
                                                  :row-index="index"
                                                  :row-field="field.sortField"/>
                                        </div>

                                    </template>
                                    <template v-else>
                                        <component :is="extractArgs(field.name)"
                                                   :row-data="item"
                                                   :row-index="index"
                                                   :row-field="field.sortField"
                                                   :label-column="labelColumn"
                                                   :field-events="fieldEvents"
                                                   :key="'component-'+ item[trackBy] +'-'+ field.name"
                                                   @delete="handleDelete"
                                                   @restore="handleRestore"
                                        ></component>
                                    </template>
                                </td>

                                <td v-if="field.extractedName === '__slot'"
                                    :data-label="field.title"
                                    @click="onCellClicked(item, field, $event, index)"
                                    :class="['vuetable-slot', titleColumnClass(field)]"
                                    :width="field.width ? field.width : 'auto'"
                                    :rel="'col-'+ field.name">
                                    <div :class="titleColumnClass(field)">
                                        <slot :name="extractArgs(field.name)"
                                              v-bind:row="item"
                                              v-bind:index="index"
                                              v-bind:field="field"
                                              :row-data="item"
                                              :row-index="index"
                                              :row-field="field.sortField"
                                        ></slot>
                                    </div>
                                </td>
                            </template>
                            <template v-else-if="isSlotField(field.name)">
                                <td :class="['vuetable-slot', titleColumnClass(field)]"
                                    :data-label="field.title"
                                    :width="field.width ? field.width : 'auto'"
                                    @click="onCellClicked(item, field, $event, index)"
                                    :rel="'col-'+ field.name">
                                    <div :class="titleColumnClass(field)">
                                        <slot :name="field.name"
                                              v-bind:item="item"
                                              v-bind:index="index"
                                              v-bind:field="field"
                                              :row-data="item"
                                              :row-index="index"
                                              :row-field="field.sortField"
                                        ></slot>
                                    </div>
                                </td>
                            </template>
                            <template v-else>
                                <td v-if="hasCallback(field)"
                                    :class="titleColumnClass(field)"
                                    :data-label="field.title"
                                    @click="onCellClicked(item, field, $event, index)"
                                    @dblclick="onCellDoubleClicked(item, field, $event, index)"
                                    :width="field.width ? field.width : 'auto'"
                                    :rel="'col-'+ field.name">
                                    <div :class="titleColumnClass(field)" v-html="callCallback(field, item, index)"></div>
                                </td>
                                <td v-else
                                    :class="titleColumnClass(field)"
                                    :data-label="field.title"
                                    @click="onCellClicked(item, field, $event, index)"
                                    @dblclick="onCellDoubleClicked(item, field, $event, index)"
                                    :width="field.width ? field.width : 'auto'"
                                    :rel="'col-'+ field.name">
                                    <div :class="titleColumnClass(field)" v-html="getObjectValue(item, field.name, '')"></div>
                                </td>
                            </template>
                        </template>
                    </template>


                    <template v-if="useDetailRow">
                        <td class="detail-toggle-column-end"
                            data-label="Details"
                            :class="{'details-open': isVisibleDetailRow(item[trackBy])}">
                            <div v-if="detailRowOnly(item)"
                                 @click.prevent.stop="e => onToggleDetails(item, index, e)"
                                 v-tooltip="'Details anzeigen/ausblenden'">
                                <svg viewBox="0 0 24 24"
                                     width="16"
                                     height="16"
                                     stroke-width="1.5"
                                     fill="none"
                                     class="tabler-icon"
                                     role="img"
                                     aria-hidden="true"
                                     stroke-linecap="round"
                                     stroke-linejoin="round"
                                     stroke="currentColor"
                                     preserveAspectRatio="xMidYMid meet">
                                    <path d="M9 6l6 6l-6 6"></path>
                                </svg>


                                <span>{{ isVisibleDetailRow(item[trackBy]) ? 'Details ausblenden' : 'Details anzeigen' }}</span>
                            </div>
                        </td>
                    </template>


                </tr>
                <tr class="loadingrow" v-if="useDetailRow">
                    <td :colspan="countVisibleFields + 2">
                        <vue-topprogress
                            :key="'load_progress-'+ item[trackBy]"
                            :trickle-speed="100"
                            :ref="'load_progress_'+ item[trackBy]"></vue-topprogress>
                    </td>
                </tr>
                <template v-if="useDetailRow">


	                <tr :class="['data-grid-detail-row vuetable-detail-row', css.detailRowClass, {open: isVisibleDetailRow(item[trackBy])}]"
	                    @click="(e) => { if (isVisibleDetailRow(item[trackBy])) { onDetailRowClick(item, e); } }">

		                <td class="data-grid-detail-row-container" :colspan="countVisibleFields + 2" >
			                <transition name="height-fade">
				                <div v-if="isVisibleDetailRow(item[trackBy]) && item.loadingdetails === false"  class="inner">
					                <component :is="detailRowComponent"
					                           :row-data="item"
					                           :row-index="index"
					                           :key="'detailRow-'+ item[trackBy]"></component>
				                </div>

			                </transition>
		                </td>

	                </tr>



<!--                    <transition :name="detailRowTransition">-->
<!--                        <tr v-if="isVisibleDetailRow(item[trackBy])"-->
<!--                            :class="['data-grid-detail-row vuetable-detail-row', css.detailRowClass]"-->
<!--                            @click="onDetailRowClick(item, $event)">-->
<!--                            <td :colspan="countVisibleFields + 2">-->
<!--                                <component :is="detailRowComponent"-->
<!--                                           :row-data="item"-->
<!--                                           :row-index="index"-->
<!--                                           :key="'detailRow-'+ item[trackBy]"></component>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </transition>-->
                </template>
            </template>
            <template v-if="displayEmptyDataRow">
                <tr>
                    <td :colspan="countVisibleFields + 1 + (useDetailRow ? 1 : 0)" class="vuetable-empty-result">
                        <div>{{ loading ? '' : noDataTemplate }}</div>
                    </td>
                </tr>
            </template>
            <template v-if="lessThanMinRows">
                <tr v-for="i in blankRows" class="blank-row">
                    <template v-for="field in tableFields">
                        <td v-if="field.visible">&nbsp;</td>
                    </template>
                </tr>
            </template>
            </tbody>
        </table>

    </div>
</template>

<script>
let qs = require('~/qs');
import {computed, markRaw, getCurrentInstance, inject, nextTick, onBeforeMount, onMounted, onBeforeUnmount, ref, toRaw, watch} from "~/vue";
import {getDataTableCache} from "./../../mixins/data-table-mixin";
import {useRoute} from "~/vue-router";

export default {
    name: 'vuetable',
    components: {
	    'vue-topprogress': require('./../Progress/top-progress.vue').default
    },
    props: {

        fields: {
            type: [Array, Object],
            required: true
        },
        fieldTransformFunctions: {
            type: Object,
            default: {}
        },
        fieldComponents: {
            type: Object,
            default: {}
        },
        fieldEvents: {
            type: Object,
            default: {}
        },
        fieldSlots: {
            type: [Object, Array],
            default() {
                return []
            }
        },
        loadOnStart: {
            type: Boolean,
            default: true
        },
        apiUrl: {
            type: String,
            default: ''
        },
        httpMethod: {
            type: String,
            default: 'post',
            validator: (value) => {
                return ['get', 'post'].indexOf(value) > -1
            }
        },
        reactiveApiUrl: {
            type: Boolean,
            default: true
        },
        apiMode: {
            type: Boolean,
            default: true
        },
        data: {
            type: [Array, Object],
            default() {
                return null
            }
        },
        dataTotal: {
            type: Number,
            default: 0
        },
        dataManager: {
            type: Function,
            default() {
                return null
            }
        },
        dataPath: {
            type: String,
            default: 'data'
        },
        paginationPath: {
            type: [String],
            default: 'pagination'
        },
        queryParams: {
            type: Object,
            default() {
                return {
                    orderby: 'orderby',
                    sort: 'sort',
                    page: 'page',
                    perPage: 'perpage'
                }
            }
        },
        appendParams: {
            type: Object,
            default() {
                return {}
            }
        },
        httpOptions: {
            type: Object,
            default() {
                return {}
            }
        },

        perPage: {
            type: Number,

            coerce: function (val) {
                return parseInt(val)
            },

            default: function () {
                return 20
            }
        },

        sortOrder: {
            type: Array,
            default() {
                return []
            }
        },
        multiSort: {
            type: Boolean,
            default() {
                return false
            }
        },
        /*
         * physical key that will trigger multi-sort option
         * possible values: 'alt', 'ctrl', 'meta', 'shift'
         * 'ctrl' might not work as expected on Mac
         */
        multiSortKey: {
            type: String,
            default: 'alt'
        },
        /* deprecated */
        rowClassCallback: {
            type: [String, Function],
            default: ''
        },
        rowClass: {
            type: [String, Function],
            default: ''
        },
        detailRowComponent: {
            type: String,
            default: ''
        },
        detailRowTransition: {
            type: String,
            default: 'slideDown'
        },
        detailRowOnly: {
            type: Function,
            default() {
                return function (item) {
                    return true
                }
            }
        },

        trackBy: {
            type: String,
            default: 'id'
        },
        css: {
            type: Object,
            default() {
                return {
                    tableClass: 'ui blue selectable celled stackable attached table',
                    loadingClass: 'loading',
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                    detailRowClass: 'vuetable-detail-row',
                    handleIcon: 'grey sidebar icon',
                }
            }
        },
        minRows: {
            type: Number,
            default: 0
        },
        silent: {
            type: Boolean,
            default: false
        },
        noDataTemplate: {
            type: String,
            default() {
                return 'No Data Available'
            }
        },
        ajaxAction: {
            type: String,
            default: ''
        },

        requestParams: {
            type: Object,
            default() {
                return {}
            }
        },
        inPopup: {
            type: Boolean,
            default() {
                return false
            }
        },
        viewTrash: {
            type: Boolean,
            default() {
                return false
            }
        },

        toolbar: {}
    },


    emits: [
        "closepopup",
        "change-selections",
        "data-selection-action",
        "delete",
        "restore",
        "vuetable:cell-clicked",
        "vuetable:cell-dblclicked",
        "vuetable:checked",
        "vuetable:close-details",
        "vuetable:initialized",
        "vuetable:load-success",
        "vuetable:loaded",
        "vuetable:loading",
        "vuetable:open-details",
        "vuetable:pagination-data",
        "vuetable:row-clicked",
        "vuetable:row-dblclicked",
        "vuetable:visible-columns-changed",
        "vuetable:row-changed",
        "vuetable:load-error",
        "vuetable:checkbox-toggled",
        "vuetable:checkbox-toggled-all",


	    'open-detail-row',
	    'close-detail-row'
],



    setup(props, context) {
        const inst = getCurrentInstance();
        const route = useRoute();
        const $http = inject('$http');
        const $events = inject('$events');
        const setPropertyPath = inject('setPropertyPath');
        const requestAction = inject('requestAction')




        const loading = ref(false);
        const status = ref(false);
        const eventPrefix = ref('vuetable:');
        const tableFields = ref([]);
        const tableData = ref(null);
        const tablePagination = ref(null);
        const currentPage = ref(1);
        const selectedTo = ref([]);
        const visibleDetailRows = ref([]);
        const labelColumn = ref(null);
        const dataRowsHeight = ref(null);
        const filter = ref([]);
        const mutatedSortOrder = ref([]);
        const gridActionsColumnWidth = ref(false);
        const gridActions = ref([]);
        const mutatedToolbar = ref(null);

        const slots = computed(() => {
            return inst.slots;
        });


        const defaultOrderDicection = computed(() => {
            return mutatedSortOrder.value.length ? mutatedSortOrder.value[0].direction : 'asc';
        })

        const hasCustomActions = computed(() => {
            if (_.isObject(slots.value) && Object.keys(slots.value).length) {
                if (slots.value.hasOwnProperty('custom-actions') && slots.value['custom-actions']) {
                    return true;
                }
            }
            return false;
        });


        const calcDataTableRowsHeight = computed(() => {
            return;
        });

        const useDetailRow = computed(() => {
            if (tableData.value && tableData.value[0] && props.detailRowComponent !== '' && typeof tableData.value[0][props.trackBy] === 'undefined') {
                console.warn('You need to define unique row identifier in order for detail-row feature to work. Use `track-by` prop to define one!')
                return false
            }

            return props.detailRowComponent !== ''
        });


        const countVisibleFields = computed(()=> {
            return tableFields.value.filter((f) => {
                return f.visible
            }).length;
        });

        const countTableData = computed(() => {
            if (tableData.value === null) {
                return 0
            }
            return tableData.value.length;
        });

        const displayEmptyDataRow = computed(() => {
            return countTableData.value === 0 && props.noDataTemplate && props.noDataTemplate.length > 0
        });

        const lessThanMinRows = computed(() => {
            if (tableData.value === null || tableData.value.length === 0) {
                return true
            }
            return tableData.value.length < props.minRows
        });

        const blankRows = computed(() => {
            if (tableData.value === null || tableData.value.length === 0) {
                return props.minRows
            }
            if (tableData.value.length >= props.minRows) {
                return 0
            }

            return props.minRows - tableData.value.length
        });

        const isApiMode = computed(() => {
            return props.apiMode;
        });
        const isDataMode = computed(() => {
            return !isApiMode.value;
        });
        const hasActions = computed(() => {
            return mutatedToolbar.value ? (_.isArray(mutatedToolbar.value.gridActions) && mutatedToolbar.value.gridActions.length > 0) : false;
        });

        const hasTableActions = computed(() => {
            let found = false;
            _.each(tableFields.value, (field) => {
                if (extractName(field.name) === '__component' && extractArgs(field.name) === 'grid-table-actions') {
                    found = true;
                }
            });

            return found;
        });





        watch(() => props.fields, (n, o) => {
            if (n!== o && n) {
                normalizeFields();
            }
        })


        watch(() => selectedTo.value, (n) => {
            if (hasActions.value && mutatedToolbar.value) {
                mutatedToolbar.value.setDataTableSelections(n)
            }
        });

        watch(() => props.toolbar, (n) => {
            if (n !== mutatedToolbar.value) {
                mutatedToolbar.value = n;
            }
        });

        watch(() => props.sortOrder, (n) => {
            mutatedSortOrder.value = _.isArray(n) ? n : [];
        });

        // watch(() => tableFields.value, (val) => {
        //
        // });

        watch(() => props.perPage, (val, o) => {
            // let i = parseInt(val);
            // perPage.value = i ? i : 20;
            if (props.toolbar && props.toolbar.filterData) {
                filter.value = props.toolbar.filterData;
            }

            nextTick(() => {
                refresh();
            })
        });


        watch(() => props.apiUrl, (newVal, oldVal) => {
            if (props.reactiveApiUrl && newVal !== oldVal) {
                refresh();
            }
        });




        onBeforeUnmount(() => {
            $events.$on('data-table:update-settings', updateDataTableSettings );
            window.addEventListener('resize', onResize)
        })


        onBeforeMount(() => {
            mutatedToolbar.value = props.toolbar;
            mutatedSortOrder.value = props.sortOrder;

            if (props.fieldComponents) {
                // register components
                for (let i in props.fieldComponents) {
                    let field = props.fieldComponents[i];
                    if (field[0] && field[1]) {
                        Neloh.component(field[0], field[1]);
                    }
                }
            }
        });

        onMounted(() => {

            $events.$on('data-table:update-settings', updateDataTableSettings );
            window.addEventListener('resize', onResize)


            normalizeFields();

            if (props.loadOnStart) {
                loadData()
            }
            else {
                if (hasTableActions.value) {
                    nextTick(() => {
                        nextTick(() => {
                            nextTick(() => {
                                updateTableActionsColumnWidth()
                            })
                        })
                    })
                }
            }
        });




        function onResize(e) {
            updateTableActionsColumnWidth()
        }



        function updateDataTableSettings() {
            if (store.getters.user && store.getters.user.hasOwnProperty('settings')) {
                let f = _.find(store.getters.user.settings, {option: 'perpage' });
                if (f && f.option_value !== null) {

                }
            }
        }

        function updateTableActionsColumnWidth() {
            let max = 0;
            _.each(tableFields.value, (field) => {
                if (extractName(field.name) === '__component' && extractArgs(field.name) === 'grid-table-actions') {
                    if (inst.refs.hasOwnProperty('head_grid-table-actions')) {
                        let el = inst.refs['head_grid-table-actions'][0];
                        if (el) {
                            let act = inst.vnode.el.querySelectorAll('.actions');
                            _.each(act, (r) => {
                                if (max < r.offsetWidth + 8) {
                                    max = r.offsetWidth + 8;
                                }

                                el.setAttribute('width', '');
                                el.style.width = max + 'px'
                            });
                        }

                    }
                }
            });
        }

        function titleColumnClass(column) {
            if (column.hasOwnProperty('titleClass') && column.titleClass) {
                if (column.titleClass.match(/text-left/g)) {
                    return 'text-left';
                }
                if (column.titleClass.match(/text-right/g)) {
                    return 'text-right';
                }
                if (column.titleClass.match(/text-center/g)) {
                    return 'text-center';
                }
            }

            return null;
        }

        function isArray(v) {
            return _.isArray(v);
        }

        function getHeight() {
            return inst.refs.vuetable_rows.offsetHeight + 'px';
        }

        function normalizeFields() {
            if (typeof (props.fields) === 'undefined') {
                console.warn('You need to provide "fields" prop.')
                return
            }

            tableFields.value = [];
            let obj;

            if (_.isObject(props.fields)) {
                _.forEach(props.fields, (field, name) => {

                    let baseField = field, baseName = name;
                    if (typeof (field) === 'string') {


                        field = field.match(/^__slot:/g) ? extractArgs(field) : field;
                            obj = {
                            name: field,
                            title: setTitle(field),
                            titleClass: '',
                            dataClass: '',
                            callback: null,
                            visible: true,
                            isvisible: true,
                            toggleDetails: false,


                            extractedName: extractName(baseField),
                            extractedArgs: extractArgs(baseField),
                            titleColumnClass: null,
                        }
                    } else {
                        name = name.match(/^__slot:/g) ? extractArgs(name) : name;

                        obj = {
                            name: name,
                            title: (field.label === undefined) ? setTitle(name) : field.label,
                            sortField: field.sortField,
                            width: (field.width === undefined) ? '' : field.width,
                            titleClass: (field.titleClass === undefined) ? '' : field.titleClass,
                            dataClass: (field.dataClass === undefined) ? '' : field.dataClass,
                            callback: (field.callback === undefined) ? '' : field.callback,
                            visible: (field.visible === undefined) ? true : field.visible,
                            isvisible: (field.isvisible === undefined) ? true : field.isvisible,
                            toggleDetails: (field.toggleDetails === undefined) ? false : field.toggleDetails,


                            extractedName: extractName(name),
                            extractedArgs: extractArgs(name),
                            titleColumnClass: titleColumnClass((field.titleClass === undefined) ? '' : field.titleClass),
                        }
                    }


                    tableFields.value.push(obj)
                })
            } else {
                props.fields.forEach((field, i) => {
                    if (typeof (field) === 'string') {
                        obj = {
                            name: field,
                            title: setTitle(field),
                            titleClass: '',
                            dataClass: '',
                            callback: null,
                            visible: true,
                            isvisible: true,
                            toggleDetails: false
                        }
                    } else {
                        obj = {
                            name: field.name,
                            title: (field.title === undefined) ? setTitle(field.name) : field.title,
                            sortField: field.sortField,
                            width: (field.width === undefined) ? '' : field.width,
                            titleClass: (field.titleClass === undefined) ? '' : field.titleClass,
                            dataClass: (field.dataClass === undefined) ? '' : field.dataClass,
                            callback: (field.callback === undefined) ? '' : field.callback,
                            visible: (field.visible === undefined) ? true : field.visible,
                            isvisible: (field.isvisible === undefined) ? true : field.isvisible,
                            toggleDetails: (field.toggleDetails === undefined) ? false : field.toggleDetails,

                            extractedName: extractName(field.name),
                            extractedArgs: extractArgs(field.name),
                            titleColumnClass: titleColumnClass((field.titleClass === undefined) ? '' : field.titleClass),
                        }
                    }

                    tableFields.value.push(obj)
                });
            }

            for (let i in tableFields.value) {
                let field = tableFields.value[i];

                if (field.hasOwnProperty('name') && props.fieldComponents.hasOwnProperty(field.name)) {

                    // is action column component
                    if (props.fieldComponents[field.name][0] === 'grid-table-actions') {
                        labelColumn.value = field.name;
                    }


                    let s = props.fieldComponents[field.name][0];

                    tableFields.value[i].name = '__component:' + props.fieldComponents[field.name][0];
                    tableFields.value[i].extractedName = '__component';
                }
            }
        }

        function setPerpage(val) {
            props.perPage = val;
            fireEvent('refresh');
        }

        function setData(data) {
            if (_.isArray(data)) {
                tableData.value = data;
                nextTick(() => {
                    updateTableActionsColumnWidth()
                })
                return;
            }

            fireEvent('loading');

            tableData.value = getObjectValue(data, props.dataPath, null)
            tablePagination.value = getObjectValue(data, props.paginationPath, null)

            nextTick(() => {
                fireEvent('pagination-data', tablePagination.value);
                fireEvent('loaded');
                updateTableActionsColumnWidth()
            });
        }

        function setTitle(str) {
            if (isSpecialField(str)) {
                return ''
            }

            return titleCase(str)
        }

        function renderTitle(field) {
            let title = (typeof field.title === 'undefined') ? field.name.replace('.', ' ') : field.title;

            if (title.length > 0) {
                let str = '';
                if (isInCurrentSortGroup(field)) {
                    str += renderIconTag(['sort-icon', sortIcon(field)]);
                } else if (isSortable(field)) {
                    str += renderIconTag(['sort-icon', 'fa fa-sort']);
                }

                if (isInCurrentSortGroup(field)) {
                    return str + '<span>' + title + '</span>';
                } else if (isSortable(field)) {
                    return str + '<span>' + title + '</span>';
                }
            }

            return '<span>' + title + '</span>'
        }

        function renderSequence(index) {
            return tablePagination.value
                ? tablePagination.value.from + index
                : index
        }

        function isSlotField(fieldName) {
            return !!inst.slots[fieldName];
        }

        function isSpecialField(fieldName) {
            return fieldName.slice(0, 2) === '__'
        }

        function titleCase(str) {
            return str.replace(/\w+/g, (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }

        function camelCase(str, delimiter = '_') {
            return str.split(delimiter).map((item) => {
                return titleCase(item)
            }).join('')
        }

        function notIn(str, arr) {
            return arr.indexOf(str) === -1
        }


        async function loadData(success = loadSuccess, failed = loadFailed) {

            if (isDataMode.value) {
                return await callDataManager();
            }

            loading.value = true;
            await fireEvent('loading');

            await nextTick(async () => {

                let postData = getAllQueryParams();
                let headers = {
                    'X-Requested-With': 'XMLHttpRequest',
                    'content-type': 'application/json',
                };
                headers['X-CSRF-TOKEN'] = inst.root.ctx.token;

                // Scroll top before execute
                if (inst.vnode.el && inst.vnode.el.parentNode) inst.vnode.el.parentNode.scrollTo({top: 0, left: 0, behavior: "smooth"});

                // reset selections
                selectedTo.value = [];

                if (props.ajaxAction) {
                    let request = requestAction(props.ajaxAction);
                    let params = Object.assign(postData, props.requestParams);
                    if (_.isEmpty(filter.value)) {
                        params = Object.assign(params, filter.value);
                    }

                    params.dataonly = true;

                    if (props.httpMethod === 'post' || props.httpMethod !== 'get') {
                        return await request.post(props.apiUrl, params).then((r) => { success(r) }).catch(() => failed())

                    } else if (props.httpMethod === 'get') {
                        return await request.get(props.apiUrl, params).then((r) => { success(r) }).catch(() => failed())
                    }
                } else {
                    postData.dataonly = true;

                    if (_.isEmpty(filter.value)) {
                        postData = Object.assign(postData, filter.value);
                    }


                    return await $http[props.httpMethod](props.apiUrl, postData, {
                        headers: headers,
                        responseType: 'json',
                        // transformRequest: [function (data, headers) {
                        //     return qs.stringify(data);
                        // }]
                    }).then((r) => {
                        success(r)
                    }).catch((e) => failed(e))
                }
            })
        }

        async function loadSuccess(response) {
            await fireEvent('load-success', response);

            let body = response.data;

            if (props.toolbar) {
                await props.toolbar.setDataTable(inst.ctx);
            }

            if (response.data.hasOwnProperty('gridActions')) {
                await props.toolbar.setDataTableActions(response.data.gridActions);
            }


            tablePagination.value = await getObjectValue(body, props.paginationPath, {});
            if (!tablePagination.value) {
                console.warn('vuetable: pagination-path "' + props.paginationPath + '" not found. '
                    + 'It looks like the data returned from the sever does not have pagination information '
                    + "or you may have set it incorrectly.\n"
                    + 'You can explicitly suppress this warning by setting pagination-path="".'
                )
            }

            currentPage.value = tablePagination.value.current_page ?? 1;
            tableData.value = await getObjectValue(body, props.dataPath, {});

            let cache = getDataTableCache(route.path);
            if (typeof cache === "object" && cache && props.toolbar && _.isObject(cache.currentFilter)) {
                await props.toolbar.setDataTableCurrentFilter(cache.currentFilter)
            }

            loading.value = false;

            await nextTick(async () => {
                await fireEvent('pagination-data', tablePagination.value);
                await fireEvent('loaded', response.data);

                await nextTick(async () => {
                    if (hasTableActions.value) {
                        await updateTableActionsColumnWidth()
                    }
                    $events.$emit('pageloading', false);
                })
            })
        }

        async function loadFailed(response) {
            console.warn('load-error', response)
            // dataRowsHeight.value = calcDataTableRowsHeight;

            await fireEvent('load-error', response);
            await fireEvent('loaded', response);

            await nextTick(() => {
                loading.value = false;
                $events.$emit('pageloading', false);
            })

        }

        function transform(data) {
            let func = 'transform';

            if (parentFunctionExists(func)) {
                return inst.ctx.parent[func].call(inst.ctx.parent, data)
            }

            return data
        }

        function parentFunctionExists(func) {
            return (func !== '' && typeof inst.ctx.parent[func] === 'function')
        }

        function callParentFunction(func, args, defaultValue = null) {
            if (parentFunctionExists(func)) {
                return inst.ctx.parent[func].call(inst.ctx.parent, args)
            }

            return defaultValue
        }

        function fireEvent(eventName, args) {
            context.emit(eventPrefix.value + eventName, args)
        }


        function warn(msg) {
            if (!props.silent) {
                console.warn(msg)
            }
        }

        function getAllQueryParams() {
            let params = {};

            let s = null;
            if (mutatedSortOrder.value && mutatedSortOrder.value.length > 1) {
                _.each(_.cloneDeep(mutatedSortOrder.value), (item) => {
                    if (!s && item.field && item.sortField && item.direction) {
                        s = item;
                    }
                });

                if (s) {
                    mutatedSortOrder.value = [];
                    mutatedSortOrder.value.push(s);
                } else {
                    console.warn('Invalid sort order');
                }
            }


            params[props.queryParams.orderby] = (typeof mutatedSortOrder.value[0].sortField === 'undefined')
                ? mutatedSortOrder.value[0].field
                : mutatedSortOrder.value[0].sortField;
            params[props.queryParams.orderby] = params[props.queryParams.orderby].replace(/^__slot:/g, '');
            params[props.queryParams.sort] = _.toLower(mutatedSortOrder.value[0].direction);
            params[props.queryParams.page] = currentPage.value;
            params[props.queryParams.perPage] = props.perPage;

            for (let x in props.appendParams) {
                if (x !== 'filter' && props.appendParams.hasOwnProperty(x)) params[x] = props.appendParams[x];
            }


            for (let x in filter.value) {
                if (x !== 'value' && filter.value.hasOwnProperty(x)) params[x] = toRaw(filter.value[x]);
            }


            return params
        }

        function getSortParam() {
            if (!mutatedSortOrder.value || mutatedSortOrder.value.field === '') {
                return ''
            }

            if (typeof inst.ctx.parent['getSortParam'] == 'function') {
                return inst.ctx.parent['getSortParam'].call(inst.parent, mutatedSortOrder.value)
            }

            return getDefaultSortParam()
        }

        function getDefaultSortParam() {
            let result = '';

            for (let i = 0; i < mutatedSortOrder.value.length; i++) {
                let fieldName = (typeof mutatedSortOrder.value[i].sortField === 'undefined')
                    ? mutatedSortOrder.value[i].field
                    : mutatedSortOrder.value[i].sortField;

                result += fieldName + '|' + mutatedSortOrder.value[i].direction + ((i + 1) < mutatedSortOrder.value.length ? ',' : '');
            }

            return result;
        }

        function extractName(string) {
            if (string && typeof string === "string") {
                let segments = string.split(':');
                if (segments[0].match(/^__/g)) {
                    return segments[0].trim();
                }
                else {
                    return segments[0].trim();
                }
            }

            return null;
        }

        function extractArgs(string) {
            return string && typeof string === "string" ? string.split(':')[1] : null;
        }

        function isSortable(field) {
            return !_.isEmpty(field.sortField)
        }

        function isInCurrentSortGroup(field) {
            return currentSortOrderPosition(field) !== false;
        }

        function currentSortOrderPosition(field) {
            if (!isSortable(field)) {
                return false
            }

            for (let i = 0; i < mutatedSortOrder.value.length; i++) {
                if (fieldIsInSortOrderPosition(field, i) ||
                    mutatedSortOrder.value[i].sortField == field.sortField ||
                    mutatedSortOrder.value[i].sortField == field.name) {
                    return i;
                }
            }

            return false;
        }

        function fieldIsInSortOrderPosition(field, i) {
            return mutatedSortOrder.value[i].field === field.name && mutatedSortOrder.value[i].sortField === field.sortField
        }

        function orderBy(field, event) {
            if (!isSortable(field)) {
                return;
            }

            let key = props.multiSortKey.toLowerCase() + 'Key';

            if (props.multiSort && event[key]) {
                //adding column to multisort
                multiColumnSort(field);
            } else {
                //no multisort, or resetting sort
                singleColumnSort(field);
            }

            currentPage.value = 1;   // reset page index
            loadData();
        }

        function multiColumnSort(field) {
            let i = currentSortOrderPosition(field);

            if (i === false) {
                //this field is not in the sort array yet
                mutatedSortOrder.value.push({
                    field: field.name,
                    sortField: field.sortField,
                    direction: 'asc'
                });
            } else {
                //this field is in the sort array, now we change its state
                if (mutatedSortOrder.value[i].direction === 'asc') {
                    // switch direction
                    mutatedSortOrder.value[i].direction = 'desc'
                } else {
                    //remove sort condition
                    mutatedSortOrder.value.splice(i, 1);
                }
            }
        }

        function singleColumnSort(field) {
            if (mutatedSortOrder.value.length === 0) {
                clearSortOrder();
            }

            mutatedSortOrder.value.splice(1); //removes additional columns

            if (fieldIsInSortOrderPosition(field, 0)) {
                // change sort direction
                mutatedSortOrder.value[0].direction = mutatedSortOrder.value[0].direction === 'asc' ? 'desc' : 'asc';
            } else {
                // reset sort direction
                mutatedSortOrder.value[0].direction = 'asc';
            }

            mutatedSortOrder.value[0].field = field.name;
            mutatedSortOrder.value[0].sortField = field.sortField;
        }

        function clearSortOrder() {
            mutatedSortOrder.value.push({
                field: '',
                sortField: '',
                direction: 'asc'
            });
        }

        function sortIcon(field) {
            let cls = '';
            let i = currentSortOrderPosition(field);

            if (i !== false) {
                cls = (mutatedSortOrder.value[i].direction === 'asc') ? props.css.ascendingIcon : props.css.descendingIcon;
            }

            return cls;
        }

        function sortDirection(field) {
            let i = currentSortOrderPosition(field);

            if (i !== false) {
                return mutatedSortOrder.value[i].direction === 'asc' ? 'asc' : 'desc';
            }

            return '';
        }

        function sortIconOpacity(field) {
            /*
             * fields with stronger precedence have darker color
             *
             * if there are few fields, we go down by 0.3
             * ex. 2 fields are selected: 1.0, 0.7
             *
             * if there are more we go down evenly on the given spectrum
             * ex. 6 fields are selected: 1.0, 0.86, 0.72, 0.58, 0.44, 0.3
             */
            let max = 1.0,
                min = 0.3,
                step = 0.3;

            let count = mutatedSortOrder.value.length;
            let current = currentSortOrderPosition(field);


            if (max - count * step < min) {
                step = (max - min) / (count - 1);
            }

            return max - current * step;
        }


        function setFieldOrders(orders) {
            mutatedSortOrder.value = [];
            mutatedSortOrder.value.push(orders);
        }





        function hasCallback(field) {

            if (!field.callback && field.name &&
                props.fieldTransformFunctions.hasOwnProperty(field.name) &&
                _.isFunction(props.fieldTransformFunctions[field.name]))
            {
                field.callback = props.fieldTransformFunctions[field.name];
            }

            return field.callback ? true : false;
        }

        function callCallback(field, item, index) {
            if (!hasCallback(field)) return;

            if (typeof field.callback === 'function') {
                return field.callback(getObjectValue(item, field.name), field, item, index);
            }

            let args = field.callback.split('|');
            let func = args.shift();

            if (typeof inst.parent[func] === 'function') {
                let value = getObjectValue(item, field.name);

                return (args.length > 0)
                    ? inst.parent[func].apply(inst.parent, [value].concat(args))
                    : inst.parent[func].call(inst.parent, value, field, item, index);
            }

            if (typeof inst.parent.ctx.parent[func] === 'function') {
                let value = getObjectValue(item, field.name);

                return (args.length > 0)
                    ? inst.parent.ctx.parent[func].apply(inst.parent.ctx.parent, [value].concat(args))
                    : inst.parent.ctx.parent[func].call(inst.parent.ctx.parent, value, field, item, index);
            }
            return null;
        }

        function getObjectValue(object, path, defaultValue) {
            defaultValue = (typeof defaultValue === 'undefined') ? null : defaultValue;

            let obj = object;
            if (path.trim() !== '') {
                let keys = path.split('.');
                keys.forEach((key) => {
                    if (obj !== null && typeof obj[key] !== 'undefined' && obj[key] !== null) {
                        obj = obj[key];
                    } else {
                        obj = defaultValue;
                        return;
                    }
                })
            }
            return obj
        }

        function toggleCheckbox(dataItem, fieldName, event) {
            let isChecked = event.target.checked;
            let idColumn = props.trackBy;

            if (dataItem[idColumn] === undefined) {
                console.warn('__checkbox field: The "' + props.trackBy + '" field does not exist! Make sure the field you specify in "track-by" prop does exist.');
                return;
            }

            let key = dataItem[idColumn];
            if (isChecked) {
                selectId(key);
            } else {
                unselectId(key);
            }

            context.emit('vuetable:checkbox-toggled', isChecked, dataItem);
        }

        function selectId(key) {
            if (!isSelectedRow(key)) {
                selectedTo.value.push(key);
                context.emit('vuetable:checked', selectedTo.value.length)
            }
        }

        function unselectId(key) {
            selectedTo.value = selectedTo.value.filter(function (item) {
                return item !== key;
            });

            context.emit('vuetable:checked', selectedTo.value.length);
        }

        function isSelectedRow(key) {
            return selectedTo.value.indexOf(key) >= 0;
        }

        function rowSelected(dataItem, fieldName) {
            let idColumn = props.trackBy;
            let key = dataItem[idColumn];

            return isSelectedRow(key);
        }

        function checkCheckboxesState(fieldName) {
            if (!tableData.value) return;

            let idColumn = props.trackBy;
            let selector = 'th.vuetable-th-checkbox-' + idColumn + ' input[type=checkbox]';
            let els = document.querySelectorAll(selector);

            //fixed:document.querySelectorAll return the typeof nodeList not array
            if (els.forEach === undefined) {
                els.forEach = function (cb) {
                    [].forEach.call(els, cb);
                };
            }

            // count how many checkbox row in the current page has been checked
            let selected = tableData.value.filter((item) => {
                return selectedTo.value.indexOf(item[idColumn]) >= 0
            });

            // count == 0, clear the checkbox
            if (selectedTo.value.length <= 0) {
                els.forEach(function (el) {
                    el.indeterminate = false;
                });
                return false;
            }
            // count > 0 and count < perPage, set checkbox state to 'indeterminate'
            else if (selected.length < props.perPage) {
                // els.forEach(function (el) {
                // 	el.indeterminate = true;
                // });
                return true;
            }
            // count == perPage, set checkbox state to 'checked'
            else {
                els.forEach((el) => {
                    el.indeterminate = false;
                });
                return true;
            }
        }

        /**
         *
         */
        function countSelected() {
            return selectedTo.value.length;
        }

        /**
         *
         */
        function toggleAllCheckboxes(event) {
            let isChecked = event.currentTarget.checked;
            let idColumn = props.trackBy;
            let tbl = _.clone(tableData.value);

            if (isChecked) {

                tbl.forEach((dataItem) => {
                    if (dataItem[idColumn].hasOwnProperty('value')) {
                        selectId(dataItem[idColumn].value);
                    }
                    else {
                        selectId(dataItem[idColumn]);
                    }
                });

            } else {

                tbl.forEach((dataItem) => {
                    if (dataItem[idColumn].hasOwnProperty('value')) {
                        unselectId(dataItem[idColumn].value);
                    } else {
                        unselectId(dataItem[idColumn]);
                    }
                });
            }

            context.emit('change-selections', markRaw(_.clone(selectedTo.value)));
        }

        function getSelection() {
            return selectedTo.value;
        }

        function clearSelction() {
            selectedTo.value = [];
            context.emit('vuetable:selection-cleared');
        }


        function gotoPreviousPage() {
            if (currentPage.value > 1) {
                currentPage.value--;
                visibleDetailRows.value = [];

                if (props.toolbar && props.toolbar.filterData) {
                    filter.value = props.toolbar.filterData;
                }

                loadData();
            }
        }

        function gotoNextPage() {
            if (currentPage.value < tablePagination.value.last_page) {
                currentPage.value++;
                visibleDetailRows.value = [];

                if (props.toolbar && props.toolbar.filterData) {
                    filter.value = props.toolbar.filterData;
                }
                loadData();
            }
        }

        function gotoPage(page) {
            if (page !== currentPage.value && (page > 0 && page <= tablePagination.value.last_page)) {
                currentPage.value = page;
                visibleDetailRows.value = [];
                if (props.toolbar && props.toolbar.filterData) {
                    filter.value = props.toolbar.filterData;
                }
                loadData();
            }
        }


        function resetVisibleDetailRows() {
            visibleDetailRows.value = []
        }

        function isVisibleDetailRow(rowId) {
            return visibleDetailRows.value.indexOf(rowId) >= 0;
        }

        function showDetailRow(rowId) {
            if (!isVisibleDetailRow(rowId)) {
                visibleDetailRows.value.push(rowId);
            }
        }

        function hideDetailRow(rowId) {
            if (isVisibleDetailRow(rowId)) {
                visibleDetailRows.value.splice(
                    visibleDetailRows.value.indexOf(rowId),
                    1
                );
            }
        }
        function toggleDetailRow(rowId) {
            if (isVisibleDetailRow(rowId)) {
                hideDetailRow(rowId)
            } else {
                showDetailRow(rowId)
            }
        }
        function onToggleDetails(dataItem, index, event) {
            let keyId = dataItem[props.trackBy] ? dataItem[props.trackBy] : dataItem.id;


            if (!dataItem.hasOwnProperty('loadingdetails')) {
                setPropertyPath(dataItem, 'loadingdetails', true);
            }


            let open = isVisibleDetailRow(keyId);

            if (!open) {

                inst.refs['load_progress_' + keyId][0].start();

                context.emit(eventPrefix.value + 'open-details', dataItem, event);
                nextTick(() => {
                    showDetailRow(keyId);
                    inst.refs['load_progress_' + keyId][0].done();
                })
            } else {
                context.emit(eventPrefix.value + 'close-details', dataItem, event);
                nextTick(() => {
                    hideDetailRow(keyId);
                })
            }
        }


        function showField(index) {
            if (index < 0 || index > tableFields.value.length) return;

            tableFields.value[index].visible = true;
        }
        function hideField(index) {
            if (index < 0 || index > tableFields.value.length) return;

            tableFields.value[index].visible = false;
        }
        function toggleField(index) {
            if (index < 0 || index > tableFields.value.length) return;

            tableFields.value[index].visible = !tableFields.value[index].visible;
        }

        function changeTableFieldLabel(name, newlabel) {
            let tableFields = _.cloneDeep(tableFields.value);

            _.each(tableFields, (f) => {
                if (f.name === name) {
                    f.title = newlabel
                }
            });

            tableFields.value = tableFields;
        }
        function changeVisibleColumns(field) {
            // console.log(field);
            fireEvent('visible-columns-changed');
        }
        function renderIconTag(classes, options = '') {
            return typeof (props.css.renderIcon) === 'undefined'
                ? `<i class="${classes.join(' ')}" ${options}></i>`
                : props.css.renderIcon(classes, options);
        }
        function makePagination(total = null, perPage = null, currentPage = null) {
            total = total === null ? props.dataTotal : total;
            perPage = perPage === null ? props.perPage : perPage;
            currentPage = currentPage === null ? currentPage.value : currentPage;

            return {
                'total': total,
                'per_page': perPage,
                'current_page': currentPage,
                'last_page': Math.ceil(total / perPage) || 0,
                'next_page_url': '',
                'prev_page_url': '',
                'from': (currentPage - 1) * perPage + 1,
                'to': Math.min(currentPage * perPage, total)
            }
        }
        function normalizeSortOrder() {
            mutatedSortOrder.value.forEach(function (item) {
                item.sortField = item.sortField || item.field;
            });
        }

        async function callDataManager() {
            if (props.dataManager === null && props.data === null) return;

            if (Array.isArray(props.data)) {
                // console.log('data mode: array');
                await setData(props.data);
            } else {
                await normalizeSortOrder();
                await setData(props.dataManager(mutatedSortOrder.value, makePagination()));
            }
        }

        function onRowClass(dataItem, index) {
            if (props.rowClassCallback !== '') {
                warn('"row-class-callback" prop is deprecated, please use "row-class" prop instead.');
                return;
            }

            if (dataItem.hasOwnProperty('classes') && _.isObject(dataItem.classes) || _.isArray(dataItem.classes)) {
                return dataItem.classes.join(' ');
            }

            if (typeof (props.rowClass) === 'function') {
                return props.rowClass.value(dataItem, index);
            }
            return props.rowClass;
        }
        function onRowChanged(dataItem) {
            fireEvent('row-changed', dataItem);
            return true;
        }
        function onRowClicked(dataItem, event) {
            // this.toggleDetailRow(dataItem.id);
            context.emit(eventPrefix.value + 'row-clicked', dataItem, event);
            return true;
        }
        function onRowDoubleClicked(dataItem, event) {
            context.emit(eventPrefix.value + 'row-dblclicked', dataItem, event);
        }
        function onDetailRowClick(dataItem, event) {
            context.emit(eventPrefix.value + 'detail-row-clicked', dataItem, event);
        }
        function onCellClicked(dataItem, field, event, index) {
            context.emit(eventPrefix.value + 'cell-clicked', dataItem, field, event, index);
        }
        function onCellDoubleClicked(dataItem, field, event, index) {
            context.emit(eventPrefix.value + 'cell-dblclicked', dataItem, field, event, index);
        }

        /*
         * API for externals
         */
        function changePage(p) {
            visibleDetailRows.value = [];

            if (p === 'prev') {
                gotoPreviousPage();
            } else if (p === 'next') {
                gotoNextPage();
            } else {
                gotoPage(p);
            }
        }

        function reload() {
            if (props.toolbar && props.toolbar.filterData) {
                filter.value = props.toolbar.filterData;
            }
            loadData();
        }

        function refresh() {
            if (props.toolbar && props.toolbar.filterData) {
                filter.value = props.toolbar.filterData;
            }
            currentPage.value = 1;
            loadData();
        }

        function resetData() {
            tableData.value = null;
            tablePagination.value = null;
            visibleDetailRows.value = [];

            fireEvent('data-reset');
        }
        function handleDelete(row) {
            context.emit('delete', row);
        }
        function handleRestore(row) {
            context.emit('restore', row);
        }
        function stopLoading() {
            loading.value = false;
        }

        function setRowClass(index, classNames) {
            if (tableData.value[index]) {
                classNames = classNames.replace(/^\./g, ' ');

                if (_.isArray(tableData.value[index].classes)) {
                    tableData.value[index].classes.push(classNames);
                } else {
                    tableData.value[index].classes = [];
                    tableData.value[index].classes.push(classNames);
                }
            }
        }

        function removeRowClass(index, classNames) {
            if (tableData.value[index]) {
                if (_.isArray(tableData.value[index].classes)) {

                    classNames = classNames.replace(/^\./g, ' ');

                    tableData.value[index].classes = _.filter(tableData.value[index].classes, (c) => {
                        return c !== classNames
                    });
                }
            }
        }

        function handleGridAction(gridactionbtn, action) {
            context.emit('data-selection-action', gridactionbtn, action, this)
        }

        function getRows() {
            return tableData.value;
        }



        return {
            slots,
            loading,
            status,
            tableFields,
            tableData,
            tablePagination,
            currentPage,
            selectedTo,
            visibleDetailRows,
            labelColumn,
            dataRowsHeight,
            defaultOrderDicection,
            filter,
            mutatedSortOrder,


            gridActionsColumnWidth,
            gridActions,

            mutatedToolbar,
            displayEmptyDataRow,
            useDetailRow,
            lessThanMinRows,
            hasCustomActions,
            countVisibleFields,
            blankRows,
            hasTableActions,
            hasActions,
            getObjectValue,
            hasCallback,
            titleColumnClass,
            setPerpage,

            isSlotField,
            isSpecialField,


            setData,

            setTitle,
            renderTitle,
            renderSequence,
            sortDirection,


            notIn,
            titleCase,
            getAllQueryParams,



            getSortParam,
            getDefaultSortParam,

            extractArgs,
            extractName,


            isSortable,
            orderBy,
            clearSortOrder,
            sortIcon,
            sortIconOpacity,
            setFieldOrders,


            toggleCheckbox,
            selectId,
            unselectId,

            isSelectedRow,
            rowSelected,
            checkCheckboxesState,
            countSelected,
            toggleAllCheckboxes,

            getSelection,
            clearSelction,

            gotoPreviousPage,
            gotoNextPage,
            gotoPage,

            resetVisibleDetailRows,
            isVisibleDetailRow,
            showDetailRow,
            hideDetailRow,
            toggleDetailRow,
            onToggleDetails,
            changeTableFieldLabel,
            changeVisibleColumns,
            renderIconTag,
            onRowClass,
            onRowChanged,
            onRowClicked,
            onRowDoubleClicked,
            onDetailRowClick,
            onCellClicked,
            onCellDoubleClicked,
            changePage,
            reload,
            refresh,
            resetData,
            handleDelete,
            handleRestore,
            stopLoading,
            setRowClass,
            removeRowClass,
            handleGridAction,
            getRows,
            isInCurrentSortGroup,
            loadData,
            isArray,
            updateTableActionsColumnWidth
        };
    },

}
</script>

<style scoped>
[v-cloak] {
    display: none;
}

.vuetable th.sortable:hover {
    color: #2185d0;
    cursor: pointer;
}

.vuetable-actions {
    width: 15%;
    padding: 12px 0px;
    text-align: center;
}

.vuetable-pagination {
    background: #f9fafb !important;
}

.vuetable-pagination-info {
    margin-top: auto;
    margin-bottom: auto;
}

.vuetable-empty-result {
    text-align: center;
}

.expand-enter-active,
.expand-leave-active {
    transition: all .5s ease;
}

.expand-enter,
.expand-leave-active {
    height: 0;
    opacity: 0;
}

.state-column {
    padding: 0;
    width: 4px;
}
</style>
