import {markRaw, toRaw} from "vue";

function storeUiConfigToLocal(state) {
    if (localStorage) {
        localStorage.setItem('ui_config', JSON.stringify(Object.assign({}, toRaw(state))) );
    }
}



const ui = {
    namespaced: true,
    state: {
        dark: false,
        lockscreen: false,
        fullscreen: false,
        sidebarMin: false,
        visibleMenuSections: [],
        toolbarDescription: null,
        currentBreadcrumb: [],
        lastActiveFormTab: null
    },

    getters: {
        dark: (state) => state.dark,
        fullscreen: (state) => state.fullscreen,
        visibleMenuSections: (state) => state.visibleMenuSections,
        toolbarDescription: (state) => state.toolbarDescription,
        currentBreadcrumb: (state) => state.currentBreadcrumb,
        lockscreen: (state) => state.lockscreen,
        lastActiveFormTab: (state) => state.lastActiveFormTab,
        sidebarMin: (state) => state.sidebarMin
    },


    mutations: {

        INIT_UI: (state) => {
            if (localStorage) {
                if (localStorage.getItem('ui_config')) {
                    try {
                        const uiConfig = JSON.parse(localStorage.getItem('ui_config'));

                        if (uiConfig.hasOwnProperty('dark')) {
                            state.dark = uiConfig.dark;
                            if (state.dark) {
                                document.documentElement.classList.add('dark')
                            }
                            else {
                                document.documentElement.classList.remove('dark')
                            }
                        }

                        if (uiConfig.hasOwnProperty('sidebarMin')) {
                            state.sidebarMin = uiConfig.sidebarMin;
                        }

                        if (uiConfig.hasOwnProperty('visibleMenuSections')) {
                            state.visibleMenuSections = uiConfig.visibleMenuSections;
                        }

                        if (uiConfig.hasOwnProperty('lockscreen')) {
                            state.lockscreen = uiConfig.lockscreen;
                        }
                    }
                    catch (e) {
                        localStorage.removeItem('ui_config');
                    }
                }
            }
        },

        SET_LOCK_SCREEN: (state, val) => {
            state.lockscreen = val;
            storeUiConfigToLocal(state);
        },
        SET_TOOLBAR_DESCRIPTION: (state, val) => {
            state.toolbarDescription = val;
        },
        SET_BREADCRUMBS: (state, val) => {
            state.currentBreadcrumb = val;
        },
        SET_DARKMODE: (state, dark) => {

            if (dark) {
                document.documentElement.classList.add('dark')
            }
            else {
                document.documentElement.classList.remove('dark')
            }

            state.dark = dark;

            storeUiConfigToLocal(state)
        },

        SET_FULLSCREEN: (state, fullscreen) => {

            if (fullscreen) {
                document.documentElement.classList.add('fullscreen')
            }
            else {
                document.documentElement.classList.remove('fullscreen')
            }



            if (fullscreen && (!document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement)) {  // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.msRequestFullscreen) {
                    document.documentElement.msRequestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }


            state.fullscreen = fullscreen;
        },


        SET_VISIBLE_MENU_SECTIONS: (state, sections) => {
            state.visibleMenuSections = sections;

            storeUiConfigToLocal(state)
        },


        SET_LAST_ACTIVE_FORM_TAB: (state, t) => {
            state.lastActiveFormTab = t;
        },

        SET_SIDEBAR_MIN: (state, t) => {
            state.sidebarMin = t;

            storeUiConfigToLocal(state)
        },
    },
    actions: {

        initUi({ commit }) {
            commit('INIT_UI');
        },

        setSidebarMin({ commit }, val) {
            commit('SET_SIDEBAR_MIN', val);
        },

        setLockscreen({ commit }, val) {
            return new Promise(resolve => {
                commit('SET_LOCK_SCREEN', val);
                resolve();
            });
        },

        setDarkmode({ commit }, dark) {
            return new Promise(resolve => {
                commit('SET_DARKMODE', dark);
                resolve();
            });
        },

        setFullscreen({ commit }, fullscreen) {
            commit('SET_FULLSCREEN', fullscreen);
        },
        setVisibleMenuSections({ commit }, fullscreen) {
            commit('SET_VISIBLE_MENU_SECTIONS', fullscreen);
        },
        setToolbarDescription({ commit }, val) {
            commit('SET_TOOLBAR_DESCRIPTION', val);
        },
        setBreadcrumbs({ commit }, val) {
            commit('SET_BREADCRUMBS', val);
        },

        setLastActiveFormTab({ commit }, val) {
            commit('SET_LAST_ACTIVE_FORM_TAB', val);
        },
        forgetLastActiveFormTab({ commit }) {
            commit('SET_LAST_ACTIVE_FORM_TAB', null);
        }
    }
};

export default ui
