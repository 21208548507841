import {getCurrentInstance, inject} from "vue";

export function useFormConditions(props, context, root) {
    const inst = getCurrentInstance();
    const $root = !root ? inst.root.ctx : root;

    const hasOwnPropertyPath = inject('hasOwnPropertyPath')
    const getPropertyPath = inject('getPropertyPath')
    const setPropertyPath = inject('setPropertyPath')

    function disableWhen(tab) {
        let disable = false;

        if (hasDisableWhen(tab)) {
            disable = getDisableWhen(tab) === true;
        }

        return disable;
    }

    function hasDisableWhen(tab) {
        return tab && tab.hasOwnProperty('disable_when');
    }

    function getDisableWhen(tab) {

        if (tab) {
            if (_.isArray(tab.disable_when) || _.isObject(tab.disable_when)) {
                let valid = 0;
                let mustValidCount = _.size(tab.disable_when);
                return getCondition(valid, tab.disable_when) === mustValidCount;
            } else if (_.isString(tab.disable_when) && tab.disable_when) {
                return getStringCondition(tab.disable_when);
            }
        }


        return false
    }

    function hideShowWhen(tab) {
        let hide = false;
        if (hasShowWhen(tab)) {
            hide = getShowWhen(tab) !== true;
        }
        return hide;
    }

    function hasShowWhen(tab) {
        return tab.hasOwnProperty('show_when') && tab.show_when;
    }

    function getShowWhen(tab) {

        if (_.isArray(tab.show_when)) {
            let valid = 0;
            let mustValidCount = _.size(tab.show_when);
            return getCondition(valid, tab.show_when) === mustValidCount;
        } else if (_.isString(tab.show_when) && tab.show_when) {
            return getStringCondition(tab.show_when);
        }

        return _.isObject(props.formModel) && props.formModel.hasOwnProperty(tab.show_when) ? props.formModel[tab.show_when] : null;
    }

    function getStringCondition(when) {
        if (_.isString(when) && when.match(/([\|,]+?)/g)) {
            let conditions = when.split('|');
            if (conditions.length > 1) {
                // fieldname (0)


                let hasShowValue;
                let showValue;


                hasShowValue = props.formModel ? hasOwnPropertyPath(props.formModel, conditions[0]) : false;
                showValue = props.formModel ? getPropertyPath(props.formModel, conditions[0]) : false;

                if (hasShowValue) {

                    if (conditions.length === 3) {
                        let operator = conditions[1];
                        let value = conditions[2];
                        if (value.match(/,/g)) {
                            value = value.split(',');
                        } else if (value && value.match(/^true$/ig)) {
                            value = true;
                        } else if (value && value.match(/^false$/ig)) {
                            value = false;
                        }


                        if (_.isArray(value)) {
                            switch (operator) {
                                case 'empty':
                                    return _.isUndefined(showValue) || _.isEmpty(showValue);

                                case 'notempty':
                                    return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                case 'in':
                                case 'exists':
                                    if (_.isArray(showValue) || _.isObject(showValue)) {
                                        let found = false;
                                        _.each(showValue, (v) => {
                                            if (!found) {
                                                _.each(value, (item) => {
                                                    if (item == v) {
                                                        found = true;
                                                    }
                                                });
                                            }

                                        });

                                        return found ? showValue : null;
                                    } else {
                                        let found = false;
                                        _.each(value, (item) => {
                                            if (!found) {
                                                if (item == showValue) {
                                                    found = true;
                                                }
                                            }
                                        });

                                        return (found ? showValue : null);
                                    }

                                case 'notin':
                                case 'notexists':

                                    if (_.isArray(showValue) || _.isObject(showValue)) {
                                        let found = false;
                                        _.each(showValue, (v) => {
                                            if (!found) {
                                                _.each(value, (item) => {
                                                    if (item == v) {
                                                        found = true;
                                                    }
                                                });
                                            }

                                        });

                                        return !found ? showValue : null;
                                    } else {
                                        let found = false;
                                        _.each(value, (item) => {
                                            if (!found) {
                                                if (item == showValue) {
                                                    found = true;
                                                }
                                            }
                                        });

                                        return (!found ? showValue : null);
                                    }
                            }
                        } else {
                            switch (operator) {
                                case '>':
                                    return (showValue > value ? showValue : null);
                                case '>=':
                                    return (showValue >= value ? showValue : null);
                                case '<':
                                    return (showValue < value ? showValue : null);
                                case '<=':
                                    return (showValue <= value ? showValue : null);
                                case '==':
                                    return (value == showValue ? showValue : null);
                                case '===':
                                    return (value === showValue ? showValue : null);
                                case '!=':
                                    return (value != showValue ? showValue : null);
                                case '!==':
                                    return (value !== showValue ? showValue : null);
                            }
                        }
                    } else if (conditions.length === 2) {

                        if (conditions[1]) {
                            switch (conditions[1]) {
                                case 'empty':
                                    return _.isUndefined(showValue) || _.isEmpty(showValue);

                                case 'notempty':
                                    return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                case 'exists':
                                    return !_.isUndefined(showValue) && showValue;

                                case 'notexists':
                                    return _.isUndefined(showValue) || _.isEmpty(showValue);
                            }
                        }

                        return (showValue == conditions[1] ? showValue : null);
                    }
                }
            }
        }


        return false;
    }

    function getCondition(validCount = 0, whenConditions = []) {
        let isEmpty;


        _.each(whenConditions, (c) => {
            if (_.isString(c) && c) {

                // console.log('Testing condition: '+ c)


                let conditions = c.split('|');
                if (conditions.length > 1) {
                    let hasShowValue;
                    let showValue;

                        hasShowValue = props.formModel ? hasOwnPropertyPath(props.formModel, conditions[0]) : false;
                        showValue = props.formModel ? getPropertyPath(props.formModel, conditions[0]) : false;



                    let operator, value;
                    if (hasShowValue) {
                        if (conditions.length === 3) {
                            operator = conditions[1];
                            value = conditions[2];

                            if (value.match(/,/g)) {
                                value = value.split(',');
                            } else if (value && value.match(/^true$/ig)) {
                                value = true;
                            } else if (value && value.match(/^false$/ig)) {
                                value = false;
                            }

                            let found = false


                            if (_.isArray(value)) {
                                switch (operator) {

                                    case 'in':
                                    case 'exists':
                                        found = false;


                                        if (_.isArray(showValue) || _.isObject(showValue)) {

                                            _.each(showValue, (v) => {
                                                if (!found) {
                                                    _.each(value, (item) => {
                                                        if (item == v) {
                                                            found = true;
                                                        }
                                                    });
                                                }
                                            });

                                            validCount = validCount + (found ? 1 : 0);
                                        } else {

                                            _.each(value, (item) => {
                                                if (!found) {
                                                    if (item == showValue) {
                                                        found = true;
                                                    }
                                                }
                                            });

                                            validCount = validCount + (found ? 1 : 0);
                                        }
                                        break;

                                    case 'notin':
                                    case 'notexists':
                                        found = false;
                                        if (_.isArray(showValue) || _.isObject(showValue)) {

                                            _.each(showValue, (v) => {
                                                if (!found) {
                                                    _.each(value, (item) => {
                                                        if (item == v) {
                                                            found = true;
                                                        }
                                                    });
                                                }

                                            });
                                            validCount = validCount + (!found ? 1 : 0);
                                        } else {
                                            _.each(value, (item) => {
                                                if (!found) {
                                                    if (item == showValue) {
                                                        found = true;
                                                    }
                                                }
                                            });

                                            validCount = validCount + (!found ? 1 : 0);
                                        }

                                        break;
                                } // End switch
                            } else {
                                switch (operator) {
                                    case '>':
                                        validCount = validCount + (showValue > value ? 1 : 0);
                                        break;
                                    case '<':
                                        validCount = validCount + (showValue < value ? 1 : 0);
                                        break;
                                    case '==':
                                        validCount = validCount + (value == showValue ? 1 : 0);
                                        break;
                                    case '===':
                                        validCount = validCount + (value === showValue ? 1 : 0);
                                        break;
                                    case '!=':
                                        validCount = validCount + (value != showValue ? 1 : 0);
                                        break;
                                    case '!==':
                                        validCount = validCount + (value !== showValue ? 1 : 0);
                                        break;
                                }
                            }

                        } // if condision length 3

                        else if (conditions.length === 2) {

                            switch (conditions[1]) {
                                case 'exists':
                                case 'notempty':


                                    if (_.isNumber(showValue)) {
                                        isEmpty = showValue > 0 ? false : true;
                                    } else {
                                        isEmpty = _.isEmpty(showValue);
                                    }

                                    validCount = validCount + (isEmpty !== true ? 1 : 0);
                                    break;

                                case 'notexists':
                                case 'empty':

                                    if (_.isNumber(showValue)) {
                                        isEmpty = showValue <= 0 ? true : false;
                                    } else {
                                        isEmpty = _.isEmpty(showValue);
                                    }

                                    validCount = validCount + (isEmpty === true ? 1 : 0);
                                    break;
                            }
                        }
                    }
                }
            }
        });

        return validCount;
    }


    return {
        getCondition,
        hideShowWhen,
        hasDisableWhen,
        hasShowWhen,
        getShowWhen,
        getDisableWhen,
        disableWhen,
    }
}
