<template>
    <button @click.prevent="handleClick"
            type="button"
            :class="['btn btn-primary', {disabled: disabled}]"
            :disabled="disabled"
            :style="buttonStyle">
        <div class="load-animation" v-show="executing">
            <dot-loading color="#fff" size="5px"></dot-loading>
        </div>
        <div v-show="!executing" ref="label" class="btn-inner">
            <slot/>
        </div>
    </button>
</template>

<script>
import { getCurrentInstance, ref, computed, watch, onMounted, nextTick } from "vue";
import DotLoading from "./../dot-loading";
export default {
    name: "actionbutton",
    components: {DotLoading},

    emits: ['clicked'],

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },


    setup(props, context) {
        const inst = getCurrentInstance();

        const btnstyle = ref({
            width: null,
            height: null
        })

        const executing = ref(false);

        watch(() => props.loading, (n, o) => {
            executing.value = n;
        });


        const buttonStyle = computed(() => {
            return {
                width: btnstyle.value.width,
                height: btnstyle.value.height
            }
        });


        const btnSlots = computed(() => {
            return inst.slots;
        });


        onMounted(() => {
            nextTick(() => {
                nextTick(() => {
                    nextTick(() => {
                        if (inst.vnode.el) {
                            btnstyle.value.width = inst.vnode.el.offsetWidth + 'px';
                            btnstyle.value.height = inst.vnode.el.offsetHeight + 'px';
                        }

                    });
                });
            });
        });



        function handleClick(event) {
            if (inst.vnode.el) {
                btnstyle.value.width = inst.vnode.el.offsetWidth + 'px';
                btnstyle.value.height = inst.vnode.el.offsetHeight + 'px';
            }

            nextTick(() => {
                context.emit('clicked', event);
            })
        }


        return {
            executing,
            buttonStyle,
            btnSlots,
            handleClick
        }
    },

    //
    //
    //
    //
    //
    // watch: {
    //     loading(n, o) {
    //         this.executing = n;
    //     }
    // },
    // data() {
    //     return {
    //         btnstyle: {
    //             width: null,
    //             height: null
    //         },
    //         executing: false,
    //     }
    // },
    // computed: {
    //     buttonStyle() {
    //
    //         return {
    //             width: this.btnstyle.width,
    //             height: this.btnstyle.height
    //         }
    //     },
    //
    //     btnSlots() {
    //         return this.$slots;
    //     }
    // },
    // mounted() {
    //     this.$nextTick(() => {
    //         this.$nextTick(() => {
    //             this.$nextTick(() => {
    //                 this.btnstyle.width = this.$el.offsetWidth + 'px';
    //                 this.btnstyle.height = this.$el.offsetHeight + 'px';
    //             })
    //         })
    //     })
    // },
    // methods: {
    //
    //     handleClick(event) {
    //
    //         this.btnstyle.width = this.$el.offsetWidth + 'px';
    //         this.btnstyle.height = this.$el.offsetHeight + 'px';
    //
    //         this.$nextTick(() => {
    //             this.$emit('clicked', event);
    //         })
    //     }
    // }
}
</script>

<style scoped>
.btn {
    position: relative;
}

.btn .load-animation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.btn-inner {
    white-space: nowrap;
    display: block;
}

.btn-inner > * {
    display: block;
}

</style>
