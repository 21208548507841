const notificationcache = {
    state: {
        notify: [],
    },
    mutations: {
        add_notifications (state, payload) {

            const moment = Neloh.getPrototype('moment');

            let type = payload.hasOwnProperty('type') ? payload.type : 'info';
            let msg  = payload.msg;
            let time = moment().format('DD.MM.YYYY, HH:mm:ss');
            let all = _.filter(state.notify, (item) => {
                return msg !== item.msg;
            });
            _.reverse(all);

            all.push({
                type: type,
                msg: msg,
                time: time
            });

            _.reverse(all);

            state.notify = all
        },
        clear_notifications(state, payload) {
            state.notify = []
        }
    },
    actions: {
        addNotification({ commit }, props) {
            commit('add_notifications', props);
        },
        clearNotifications({ commit }, props) {
            commit('clear_notifications', props);
        }
    }
};
export default notificationcache
