import {useRouter} from "~/vue-router";

const router = require("./../router");
window.axios = require('axios');
const axios_Base = _.clone(window.axios);

let meta = document.head.querySelector(
	'meta[name="csrf-token"]'
);


axios_Base.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios_Base.defaults.headers.common['X-CSRF-TOKEN'] = meta ? meta.content : '';

const defaultInterceptorsResponse = [
	function(response) {
		if (response.data) {
			const v = Neloh.vueInstance();
			
			if (response.data.hasOwnProperty('exception')) console.error(response.data.exception);

            if (response.data.hasOwnProperty('pagetitle') && response.data.pagetitle) {
                document.querySelector('title').innerHTML = response.data.pagetitle
            }
			
			if (v) {
				if (typeof v.setPageTitle === "function") {
					v.setPageTitle(response.data);
				}
			}
		}

		return response;
	},
	error => {
		if (error.hasOwnProperty('response') && error.response) {
			const { status } = error.response;
            const v = Neloh.vueInstance();

            Neloh.$store.dispatch('setResponseError', error.response);

            if (status == 403) {
                v.$router.replace('/redirect/error-403');
            }
            else if (status == 404) {
                v.$router.replace('/redirect/error-404');
            }
            else if (status >= 500) {
                v.$router.replace('/redirect/error');
            }
		}

		return Promise.reject(error)
	}
];

window.axios.interceptors.response.use(
	defaultInterceptorsResponse[0], defaultInterceptorsResponse[1]
);

global.axiosBase = axios_Base;

Neloh.setHttp(global.axiosBase);
