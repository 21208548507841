import LinksEdit from './components/LinksEdit.vue';


export default [
    {
        path: '/links',
        component: require('./components/LinksIndex.vue').default,
        name: 'admin.links',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },



    {
        path: '/links/edit/:id',
        component: LinksEdit,
        name: 'admin.links.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },


    {
        path: '/links/create',
        component: LinksEdit,
        name: 'admin.links.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
];
