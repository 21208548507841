<template>
    <form-field :name="name" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors" :form-model="formModel">
        <template v-slot:field>

            <template v-if="isMounted">
                <div class="checkbox" :class="{disabled: fieldIsDisabled }">
                    <template v-if="field.hasOwnProperty('checkboxlabel') && field.checkboxlabel">
                        <form-checkboxfield ref="formfield"
                                            :name="name"
                                            :value="modelvalue"
                                            field-value="1"
                                            :field="{value: initVal, disabled: fieldIsDisabled, attributes: { id: checkboxId, disabled: fieldIsDisabled}}"
                                            @input="updateInput"></form-checkboxfield>
                        <label :for="fieldId(1)">
                            {{ field.checkboxlabel }}
                        </label>
                    </template>
                    <template v-else-if="$slots.default">
                        <form-checkboxfield ref="formfield"
                                            :name="name"
                                            :value="modelvalue"
                                            field-value="1"
                                            :field="{value: initVal, disabled: fieldIsDisabled, attributes: { id: checkboxId, disabled: fieldIsDisabled}}"
                                            @input="updateInput"></form-checkboxfield>
                        <label :for="fieldId(1)">
                            <slot/>
                        </label>
                    </template>
                    <template v-else>
                        <form-checkboxfield ref="formfield"
                                            :name="name"
                                            :value="modelvalue"
                                            field-value="1"
                                            :field="{value: initVal, disabled: fieldIsDisabled, attributes: { id: checkboxId, disabled: fieldIsDisabled}}"
                                            @input="updateInput"></form-checkboxfield>
                    </template>
                </div>
            </template>

        </template>


    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, nextTick, inject, onBeforeMount} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-checkbox",
    emits: ['input'],
    props: {
        ...defaultFieldProps,
        checked: {},
        trueValue: {},
        falseValue: {}
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const {fieldIsDisabled, modelName, modelvalue, fieldAttributes, allowMultiple} = useFormField(props, context);

        const isMounted = ref(false)
        const $events = inject('$events');
        const mchecked = ref(undefined);
        const initVal = ref(null);

        onBeforeMount(() => {
            initVal.value = _.cloneDeep(modelvalue.value);
        });


        onMounted(() => {
            isMounted.value = true;
            initVal.value = _.cloneDeep(modelvalue.value);

            $events.$on('form-reset', () => {
                let org = _.cloneDeep(modelvalue.value);
                mchecked.value = org ? true : false;
                modelvalue.value = org ? true : false;

                nextTick(() => {

                })
            });
        })


        const _trueValue = computed(() => {
            return typeof props.trueValue === 'string' ? props.trueValue : !!props.trueValue;
        });

        const _falseValue = computed(() => {
            return typeof props.falseValue === 'string' ? props.falseValue : !!props.falseValue;
        });

        const shouldBeChecked = computed(() => {
            if (mchecked.value === undefined)
                return mchecked.value = typeof props.value === 'string' ? true : !!props.value;
            else
                return mchecked.value;
        });

        const checkboxId = computed(() => {
            if (props.field.hasOwnProperty('attributes') && _.isObject(props.field.attributes)) {
                if (props.field.attributes.hasOwnProperty('id')) {
                    return props.field.attributes.id;
                }
            }
            return _.clone(modelName.value).replace(/\./g, '-');
        });


        watch(() => props.checked, (n, o) => {
            if (n !== o) {
                mchecked.value = n;
            }
        })


        function fieldId(value) {
            return app.transformKey(modelName.value).replace(/\./g, '-') + '_' + value;
        }

        function updateInput(checked) {
            let value = checked;
            let isChecked = checked;
            let modelValue = modelvalue.value;
            if (allowMultiple.value) {

                if (modelValue === null) {
                    modelValue = [];
                    modelvalue.value = modelValue;
                }

                if (_.isObject(modelValue)) {
                    modelValue = Object.keys(modelValue).map((key) => {
                        return modelValue[key]
                    })
                }

                if (_.isArray(modelValue)) {

                    let newValue = modelValue;

                    if (isChecked) {
                        newValue.push(value);
                    } else {
                        newValue.splice(newValue.indexOf(value), 1);
                    }

                    modelvalue.value = newValue;
                } else {
                    modelvalue.value = (isChecked ? (_trueValue.value ? props.trueValue : true) : (_falseValue.value ? props.falseValue : false));
                }
            } else {
                modelvalue.value = (isChecked ? (_trueValue.value ? props.trueValue : true) : (_falseValue.value ? props.falseValue : false));
            }

            mchecked.value = isChecked;


            if (props.field.event) {
                if (props.field.event.match(/^on([A-Z][a-zA-Z]+)/g)) {

                    // this.$events[this.field.event](isChecked)

                    eval('$events.$emit(\'' + props.field.event + "\', " + isChecked + ")");
                }
            }


            context.emit('input', mchecked.value);

            nextTick(() => {
                let event = new Event('resize');
                window.dispatchEvent(event);
            });
        }

        return {
            isMounted,
            initVal,
            modelvalue,
            fieldIsDisabled,
            fieldAttributes,
            checkboxId,

            fieldId,
            updateInput,
        }
    },

    //
    // data() {
    //     return {
    //         isMounted: false,
    //         mchecked: undefined,
    //         trueValue: null,
    //         falseValue: null,
    //         modelName: null,
    //         model: null,
    //         multiple: false,
    //         val: false,
    //         initVal: false,
    //         defaultAttributes: {}
    //     }
    // },
    // watch: {
    //     checked(v) {
    //         this.mchecked = v;
    //     },
    //     modelvalue(v, o) {
    //         this.mchecked = v;
    //         if (v !== o && this.isMounted && _.isFunction(this.makeDirty)) {
    //             if (!this.$route.meta.nodirty) {
    //                 this.makeDirty()
    //             }
    //         }
    //     }
    // },
    // computed: {
    //     _trueValue() {
    //         return typeof this.trueValue === 'string' ? this.trueValue : !!this.trueValue;
    //     },
    //     _falseValue() {
    //         return typeof this.falseValue === 'string' ? this.falseValue : !!this.falseValue;
    //     },
    //
    //     shouldBeChecked() {
    //
    //         if (this.hasFormModel) {
    //
    //             let modelValue = this.modelvalue;
    //             if (modelValue !== undefined) {
    //
    //                 // checkbox
    //                 if (modelValue instanceof Array) {
    //                     return modelValue.includes(this.value);
    //                 }
    //
    //                 if (this._trueValue) {
    //                     return modelValue === this.trueValue;
    //                 }
    //
    //                 return typeof modelValue === 'string' ? true : !!modelValue;
    //             }
    //
    //         } else {
    //             if (this.$root.model) {
    //                 let modelValue = this.modelvalue;
    //                 if (modelValue !== undefined) {
    //
    //                     // checkbox
    //                     if (modelValue instanceof Array) {
    //                         return modelValue.includes(this.value);
    //                     }
    //
    //                     if (this._trueValue) {
    //                         return modelValue === this.trueValue;
    //                     }
    //
    //                     return typeof modelValue === 'string' ? true : !!modelValue;
    //                 }
    //             }
    //         }
    //
    //
    //         if (this.mchecked === undefined)
    //             return this.mchecked = typeof this.checked === 'string' ? true : !!this.checked;
    //         else
    //             return this.mchecked;
    //     },
    //
    //     fieldAttributes() {
    //         let attrs = this.field.attributes;
    //
    //         if (_.isObject(attrs)) {
    //             return {
    //                 ...this.defaultAttributes,
    //                 ...attrs
    //             };
    //         }
    //
    //         return this.defaultAttributes;
    //     },
    //     checkboxId() {
    //
    //         if (this.field.hasOwnProperty('attributes') && _.isObject(this.field.attributes)) {
    //             if (this.field.attributes.hasOwnProperty('id')) {
    //                 return this.field.attributes.id;
    //             }
    //         }
    //
    //         // let idName = this.name.replace(/\[\]/g, '_');
    //         // idName = idName.replace(/\[/g, '-');
    //         // idName = idName.replace(/\}/g, '');
    //
    //         return _.clone(this.modelName).replace(/\./g, '-');
    //     }
    // },
    // created() {
    //     this.modelName = this.$root.createVModel(this.name);
    //
    //     if (this.name.match(/\[\]$/g) || (this.field.hasOwnProperty('multiple') && this.field.multiple)) {
    //         this.multiple = true;
    //     }
    //
    //     this.val = this.modelvalue;
    //
    //
    //     let attr = this.fieldAttributes;
    //     if (!attr.hasOwnProperty('id')) {
    //         this.defaultAttributes.id = this.checkboxId;
    //     }
    //
    //
    // },
    //
    // beforeMount() {
    //     this.initVal = _.cloneDeep(this.modelvalue);
    // },
    //
    //
    // mounted() {
    //
    //     this.isMounted = true;
    //     this.initVal = _.cloneDeep(this.modelvalue);
    //
    //     this.$root.$events.$on('form-reset', () => {
    //         let org = _.cloneDeep(this.modelvalue);
    //         this.mchecked = org ? true : false;
    //         this.val = org ? true : false;
    //
    //         this.modelvalue = org ? true : false;
    //
    //         this.dirty = false;
    //         if (this.formcontainer) {
    //             this.formcontainer.resetDirty();
    //         }
    //
    //
    //     });
    // },
    //
    // methods: {
    //     fieldId(value) {
    //         return this.transformKey(this.modelName).replace(/\./g, '-') + '_' + value;
    //     },
    //     updateInput(checked) {
    //         let value = checked;
    //         let isChecked = checked;
    //         let modelValue = this.modelvalue;
    //         if (this.multiple) {
    //
    //             if (modelValue === null) {
    //                 modelValue = [];
    //                 this.modelvalue = modelValue;
    //             }
    //
    //             if (_.isObject(modelValue)) {
    //                 modelValue = Object.keys(modelValue).map((key) => {
    //                     return modelValue[key]
    //                 })
    //             }
    //
    //             if (_.isArray(modelValue)) {
    //
    //                 let newValue = modelValue;
    //
    //                 if (isChecked) {
    //                     newValue.push(value);
    //                 } else {
    //                     newValue.splice(newValue.indexOf(value), 1);
    //                 }
    //
    //                 this.modelvalue = newValue;
    //             } else {
    //                 this.modelvalue = (isChecked ? (this._trueValue ? this.trueValue : true) : (this._falseValue ? this.falseValue : false));
    //             }
    //         } else {
    //             this.modelvalue = (isChecked ? (this._trueValue ? this.trueValue : true) : (this._falseValue ? this.falseValue : false));
    //         }
    //
    //         this.mchecked = isChecked;
    //
    //
    //         if (this.field.event) {
    //             if (this.field.event.match(/^on([A-Z][a-zA-Z]+)/g)) {
    //
    //                 // this.$events[this.field.event](isChecked)
    //
    //                 eval('this.$events.$emit(\'' + this.field.event + "\', " + isChecked + ")");
    //             }
    //         }
    //
    //
    //         this.$emit('input', this.mchecked);
    //
    //         this.$nextTick(() => {
    //             let event = new Event('resize');
    //             window.dispatchEvent(event);
    //         });
    //     }
    // }
}
</script>

<style scoped>

</style>
