import {createStore} from 'vuex';


import user from './modules/user';
import form from './modules/form';
import responseError from './modules/response-errors';
import ui from './modules/ui';
import globalSearch from './modules/globalSearch';
import DataTableCache from './modules/DataTableCache';
import notificationcache from './modules/notificationcache';
import changes from './modules/changes';
import getters from './getters'


const store = createStore({
	connectToDevTools: true,
	modules: {
        notificationcache,
        ui,
        DataTableCache,
		user,
        form,
        globalSearch,
        responseError,
        changes
	},
	getters
});
export default store;
