const changes = {
    state: {
        counters: {},
    },
    mutations: {
        UPDATE_CHANGES(state, payload) {
            state.counters = payload
        }
    },
    actions: {
        updateChanges({ commit }, props) {
            commit('UPDATE_CHANGES', props);
        },
    }
};
export default changes
