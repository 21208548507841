<template>
    <div :class="fieldColumnsClass">
        <template v-if="hasFields" v-for="(f, n) in field.fields" :key="n">
            <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
            <template v-else>
                <component v-if="f.type !== 'column' && f.type !== 'columns' && f.type !== 'section'"
                           :is="'form-'+ f.type"
                           :form-model="formModel"
                           :responseerrors="responseerrors"
                           :rendersections="rendersections"
                           :validate="validate"
                           :name="n"
                           :field="f"></component>
                <component :is="'form-'+ f.type"
                           :form-model="formModel"
                           :responseerrors="responseerrors"
                           :validate="validate"
                           :name="n"
                           :field="f"
                           :rendersections="rendersections" v-else></component>
            </template>
        </template>
    </div>
</template>

<script>

import {computed} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default {
    name: "form-column",
    props: {
        ...defaultFieldProps
    },
    setup(props, context) {
        const {hasFields, hasShowWhen, getShowWhen} = useFormField(props, context);


        const fieldColumnsClass = computed(() => {
            let extraClass = '';
            if (props.field.hasOwnProperty('class') && props.field.class) {
                extraClass += props.field.class;
            }

            if (hasShowWhen.value && !getShowWhen.value) {
                extraClass += ' hidden';
            }


            if (!props.field.hasOwnProperty('cols') || props.field.cols > 12) {
                return ' align-items-stretch col-md-12' + (extraClass ? ' ' + extraClass : '');
            } else {
                return ' align-items-stretch col-md-' + props.field.cols + (extraClass ? ' ' + extraClass : '');
            }
        });

        return {
            hasFields,
            fieldColumnsClass,
        }
    },

}
</script>

<style scoped>

</style>
