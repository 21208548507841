const globalSearch = {
    namespaced: true,
    state: {
        initDone: false,
        model: {
            q: null,
            sort: 'score',
            order: 'desc',
            published: 1,
            searchIn: [],
            perpage: 50,
            mode: '0',
            archive: false,
            viewmode: '1',
            day_begin: null,
            day_end: null
        },
        results: [],
    },

    getters: {
        isDone: (state) => state.initDone,
        model: (state) => state.model,
        results: (state) => state.results,
    },

    mutations: {
        UPDATE_MODEL: (state, nmodel) => {
            state.model = Object.assign(state.model, nmodel)
        },

        UPDATE_RESULTS: (state, results) => {
            state.results = results
        },

        INIT_DONE: (state) => {
            state.initDone = true
        }
    },

    actions: {

        setInitDone({ commit, state }) {
            commit('INIT_DONE');

        },


        updateSearchModel({ commit, state }, model) {
            return new Promise(resolve => {
                commit('UPDATE_MODEL', model);
                resolve({
                    model: state.model,
                })
            });
        },

        updateSearchResults({ commit, state }, results) {
            return new Promise(resolve => {
                commit('UPDATE_RESULTS', results);
                resolve({
                    results: state.results,
                })
            });
        },

    }
}
export default globalSearch
