<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, toRaw, defineComponent, getCurrentInstance, inject, nextTick, ref, onMounted} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";
import {parents} from "@/utils/dom";


export default defineComponent({
    name: "MediumIndex",

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx

        const route = useRoute();
        const router = useRouter()
        const store = useStore();
        const changeBreadcrumb = inject('changeBreadcrumb')
        const requestAction = inject('requestAction')
        const setPropertyPath = inject('setPropertyPath');
        const $http = inject('$http');
        const $events = inject('$events');

        const loadItem = ref(null);
        const height = ref(null)


        const treeData = ref([]);


        const showDetails = ref(false);
        const detailsForRequestId = ref(null);



        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
            return true
            // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
        })



        loadData();


        onMounted(() => {

        })




        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                if (!r.data.grid.url) {
                    r.data.grid.url = $root.currentRouteUrl(route.path);
                }

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                changeBreadcrumb(r.data)

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {
            changeBreadcrumb(data)
        }

        function handleCreate() {
            router.push({name: 'admin.system.medium.create'})
        }

        function handleDelete() {

        }

        function editEntry(row) {
            router.push({name: 'admin.system.medium.edit', params: {id: row.id}})
        }


        function updateTreeItem(item, mutateditems, o) {

            const old = toRaw(treeData.value);
            const mutateditemsRaw = toRaw(mutateditems);

            if (mutateditemsRaw === old) {
                return;
            }

            loadItem.value = toRaw(item);

            let tree = inst.refs.tree.$el;
            let el = tree.querySelector('[data-id="' + item.id + '"]');
            let org = _.cloneDeep(toRaw(item));

            if (el) {
                // item.loading = true;

                let parentNodes = parents(el, 'li');
                parentNodes.shift(); // remove self

                let p = parentNodes.shift(); // the first parent
                let cls = null;
                if (p) {
                    cls = p.className.replace(/.*nestable-item-?([\d]+)\s*.*/g, '$1');
                    cls = cls.trim();
                }

                if (cls && cls.match(/^\d+?$/g)) {
                    org.parent_id = parseInt(cls)
                } else {
                    org.parent_id = 0
                }

                $http.post($root.routes('admin.system.subjects.update-tree'), {
                        item: org,
                        id: org.id,
                        parent_id: org.parent_id,
                        items: mutateditemsRaw
                    })
                    .then((r) => {
                        treeData.value = mutateditemsRaw;
                        loadItem.value = null;
                    }).catch((e) => {
                    // item.loading = false;
                    loadItem.value = null;
                });


                return;
            }

            console.warn('Could not update updateTreeItem()');
        }





        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,
            showDetails,
            detailsForRequestId,
            treeData,
            height,
            loadItem,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreate,
            handleDelete,
            editEntry,
            updateTreeItem,
        }
    }
})
</script>

<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">
        <button class="btn btn-primary"
                @click="handleCreate"
                type="button"
                v-if="canCreateUser">
            <svg-icon name="plus"/>
            Medium anlegen
        </button>
    </toolbar>

    <data-table v-if="grid" ref="gridata"
                :field-components="fieldComponents"
                :base-data="grid"
                :toolbar="$refs.toolbar"
                @selected-count="(v) => { selectedTrashCount = v }"
                @delete="handleDelete"
                @loaded="onLoaded">

        <template v-slot:title="{row}">
            <template v-if="row.title">
                <div v-if="row.title">{{ row.title }}</div>
                <div v-if="row.description" class="text-small">{{ row.description }}</div>
            </template>
        </template>

        <template v-slot:created_at="{row}">
            <created-updated-by-at v-if="row.created_at" :date="row.created_at" :user="row.createdby" type="created"/>
        </template>

        <template v-slot:updated_at="{row}">
            <created-updated-by-at v-if="row.updated_at" :date="row.updated_at" :user="row.updatedby" type="updated"/>
        </template>

    </data-table>
</template>

<style scoped>

</style>
