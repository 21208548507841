import {
    computed,
    getCurrentInstance,
    markRaw,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    nextTick,
    inject,
    watch,
    toRaw
} from 'vue';

import {useStore} from "~/vuex";
import {useRouter, useRoute} from "~/vue-router";
import {getParents} from "./../utils/dom";


const defaultProps = {
    primaryKey: 'id',
    pageTitle: null,
    translatable: false,
    locale: null,
    default_locale: null,
    formOpen: {attributes: []},
    tabs: [],
    toolbar: {},
    formdefinition: [],
    orgmodel: {},
    formfields: {},
    formtoolbar: null,
    errors: [],
    update_route: null,
    store_route: null,
    storeModul: null,
};

export function useFormDefaults(props, context) {
    const inst = getCurrentInstance();
    const $root = inst.root.ctx;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const getPropertyPath = inject('getPropertyPath');
    const wait = inject('wait')
    const $events = inject('$events');
    const $http = inject('$http');

    const updateMenues = inject('updateMenues')

    const isMounted = ref(false);
    const loading = ref(false);
    const forceupdateurl = ref(false);
    const formname = ref(null);
    const formloading = ref(false);
    const formsaving = ref(false);
    const rendersections = ref({});
    const primaryKey = ref('id');
    const defaultFormUrl = ref('');
    const locale = ref(null);
    const default_locale = ref(null);
    const disableSync = ref(false);
    const model = ref({});
    const orgmodel = ref({});


    const store_route = ref(null);
    const update_route = ref(null);

    const saveAndNew = ref({
        name: null,
        callback: () => {
            loadForm();
        }
    });

    const loadFormWithParams = ref({});
    const loadFormWithRouteParams = ref({});


    const isReset = ref(false);
    const doNotClearForm = ref(false);


    watch(() => model.value, (n, o) => {
        if (isMounted.value && n && !isReset.value) {
            if (props.storeModul)
            {
                let oldDirtyState = store.getters[props.storeModul +'/formDirty']
                store.dispatch(props.storeModul +'/updateFormModel', n);

                if ($root.disableDirty) {
                    if (oldDirtyState !== store.getters[props.storeModul +'/formDirty']) {
                        if (!oldDirtyState) {
                            store.dispatch(props.storeModul +'/unDirty');
                        }
                        else {
                            store.dispatch(props.storeModul +'/makeDirty');
                        }
                    }
                }
            }
            else {
                let oldDirtyState = store.getters['form/formDirty'];
                store.dispatch('form/updateFormModel', n);
                if ($root.disableDirty) {


                    if (!oldDirtyState) {
                        store.dispatch('form/unDirty');
                    }
                    else {
                        store.dispatch('form/makeDirty');
                    }
                }
            }
        }
    }, {deep: true})





    const form = computed(() => {

        if (props.storeModul) {
            if (store.getters[props.storeModul +'/formDefinition']) {
                return store.getters[props.storeModul +'/formDefinition'];
            }
        }
        else {
            if (store.getters['form/formDefinition']) {
                return store.getters['form/formDefinition'];
            }
        }
        return {};
    });


    const formFields = computed(() => {

        if (props.storeModul) {
            if (store.getters[props.storeModul +'/formFields']) {
                return store.getters[props.storeModul +'/formFields'];
            }
        }
        else {
            if (store.getters['form/formFields']) {
                return store.getters['form/formFields'];
            }
        }

        return {};
    });

    const formTabs = computed(() => {

        if (props.storeModul) {
            if (store.getters[props.storeModul +'/formTabs']) {
                return store.getters[props.storeModul +'/formTabs'];
            }
        }
        else {
            if (store.getters['form/formTabs']) {
                return store.getters['form/formTabs'];
            }
        }
        return {};
    });

    const errors = computed(() => {

        if (props.storeModul) {
            if (store.getters[props.storeModul +'/formErrors']) {
                return store.getters[props.storeModul +'/formErrors'];
            }
        }
        else {
            if (store.getters['form/formErrors']) {
                return store.getters['form/formErrors'];
            }
        }
        return [];
    });

    const toolbar = computed(() => {

        if (props.storeModul) {
            if (store.getters[props.storeModul +'/formToolbar']) {
                return store.getters[props.storeModul +'/formToolbar'];
            }
        }
        else {
            if (store.getters['form/formToolbar']) {
                return store.getters['form/formToolbar'];
            }
        }
        return {};
    });

    const currentFormModel = computed(() => {

        if (props.storeModul) {
            if ( store.getters[props.storeModul +'/model'] ) {
                return store.getters[props.storeModul +'/model'];
            }
        }
        else {
            if (store.getters['form/formModel']) {
                return store.getters['form/formModel'];
            }
        }
        return null;
    });

    const isDirty = computed(() => {

        if ( props.storeModul && store.getters[props.storeModul +'/formDirty'] ) {
            return store.getters[props.storeModul +'/formDirty'];
        }

        if (store.getters['form/formDirty']) {
            return store.getters['form/formDirty'];
        }
    });

    const isCreate = computed(() => {
        if (primaryKey.value) {
            let pv = getPropertyPath(model.value, primaryKey.value, null);
            if (!pv) {
                return true;
            }
        }
        return false;
    });


    function resetDirty() {

        if (!$root.disableDirty) {

            if (props.storeModul) {
                store.dispatch(props.storeModul+'/unDirty');
            }
            else {
                route.meta.dirty = false;
                store.dispatch('form/unDirty');
            }
        }
    }

    function makeDirty() {

        if (!$root.disableDirty) {
            if (props.storeModul) {
                route.meta.dirty = true;
                store.dispatch(props.storeModul +'/makeDirty');
            }
            else {
                route.meta.dirty = true;
                store.dispatch('form/makeDirty');
            }
        }
    }

    function formUrl(params) {
        let url = $root.routes((isCreate.value ? store_route.value : update_route.value), params);
        return url ? url : $root.currentRouteUrl(route.path)
    }

    function primaryKeyValue() {
        return model.value.hasOwnProperty(primaryKey.value) ? model.value[primaryKey.value] : 0;
    }

    function isUpdate() {
        if (primaryKey.value) {
            return model.value.hasOwnProperty(primaryKey.value) && model.value[primaryKey.value] > 0;
            // return this.$root.model.hasOwnProperty(this.primaryKey) && this.$root.model[ this.primaryKey ] > 0;
        }
        return false;
    }


    function loadForm(callback, url = null, customParams = null) {
        if (formloading.value) {
            return;
        }

        formloading.value = true;

        $events.$emit('pageloading', true);

        if (props.storeModul) {
            store.dispatch(props.storeModul +'/clearFormErrors')
        }
        else store.dispatch('form/clearFormErrors')


        let params  =  loadFormWithParams.value;

        if (typeof customParams === "object") {
            params = Object.assign(params, customParams)
        }


        $http.post((url ? url : $root.currentRouteUrl(route.path)), params).then((r) => {

            loadFormWithParams.value = {};

            if (_.isObject(r.data.model)) {
                if (props.storeModul) {
                    if (primaryKey.value) {
                        store.dispatch(props.storeModul + '/setFormPrimaryKeyName', primaryKey.value)
                    }
                    store.dispatch(props.storeModul + '/setFormModel', Object.assign({}, _.cloneDeep(r.data.model)))
                }
                else {
                    if (primaryKey.value) {
                        store.dispatch('form/setFormPrimaryKeyName', primaryKey.value)
                    }

                    store.dispatch('form/setFormModel', Object.assign({}, _.cloneDeep(r.data.model)))
                }

                model.value = Object.assign({}, _.cloneDeep(r.data.model));
            }

            if (_.isObject(r.data.toolbar)) {
                if (props.storeModul) {
                    store.dispatch(props.storeModul +'/setFormToolbar', r.data.toolbar)
                }
                else store.dispatch('form/setFormToolbar', r.data.toolbar)
            }


            if (_.isObject(r.data.form) && r.data.form.hasOwnProperty('name') ) {
                if (props.storeModul) {
                    store.dispatch(props.storeModul +'/setFormName', r.data.form.name)
                }
                else store.dispatch('form/setFormName', r.data.form.name)
            }



            if (Array.isArray(r.data.breadcrumbs)) {
                try {
                    store.dispatch('ui/setBreadcrumbs', r.data.breadcrumbs)
                } catch (e) {

                }
            }
            else {
                try {
                    store.dispatch('ui/setBreadcrumbs', [])
                } catch (e) {

                }
            }


            if (_.isObject(r.data.form)) {




                // Prepare FormTabs
                if (r.data.form.hasOwnProperty('formdefinition') &&
                    r.data.form.formdefinition &&
                    r.data.form.formdefinition.hasOwnProperty('tabs') &&
                    _.isObject(r.data.form.formdefinition.tabs) && Object.keys(r.data.form.formdefinition.tabs).length) {


                    let x = 0, tabs = r.data.form.formdefinition.tabs;
                    _.each(tabs, (t) => {
                        if (x === 0 && !t.hasOwnProperty('default_tab')) {
                            t.default_tab = true;
                        }

                        x++;
                    });

                    if (props.storeModul) {
                        store.dispatch(props.storeModul +'/setFormTabs', r.data.form.formdefinition.tabs);
                    }
                    else store.dispatch('form/setFormTabs', r.data.form.formdefinition.tabs);
                }

                // Prepare FormFields
                if (r.data.form.hasOwnProperty('formdefinition') &&
                    r.data.form.formdefinition &&
                    r.data.form.formdefinition.hasOwnProperty('fields') &&
                    _.isObject(r.data.form.formdefinition.fields) && Object.keys(r.data.form.formdefinition.fields).length) {
                    if (props.storeModul) {
                        store.dispatch(props.storeModul +'/setFormFields', r.data.form.formdefinition.fields);
                    }
                    else store.dispatch('form/setFormFields', r.data.form.formdefinition.fields);
                }

                if (props.storeModul) {
                    store.dispatch(props.storeModul +'/setFormDefinition', _.cloneDeep(r.data.form));
                }
                else store.dispatch('form/setFormDefinition', _.cloneDeep(r.data.form));
            }

            if (r.data.hasOwnProperty('pagetitle') && r.data.pagetitle) {
                $root.setPageTitle(r.data.pagetitle)
            }

            if (r.data.hasOwnProperty('formurls') && _.isObject(r.data.formurls))
            {
                if (r.data.formurls.hasOwnProperty('store')) {
                    store_route.value = r.data.formurls.store;
                }

                if (r.data.formurls.hasOwnProperty('update')) {
                    update_route.value = r.data.formurls.update;
                }
            }


            formloading.value = false;

            if (_.isFunction(callback)) {
                callback(r.data);
            }

            nextTick(() => {
                $events.$emit('pageloading', false);
            });

        }).catch((e) => {
            formloading.value = false;
            console.warn(e);
            nextTick(() => {
                $events.$emit('pageloading', false);
            });
        });
    }


    function updatePrimaryKey(response) {
        if (model.value.hasOwnProperty(primaryKey.value) && response.hasOwnProperty('newid')) {
            $root.$set(model.value, primaryKey.value, response.newid);
        } else if (model.value.hasOwnProperty(primaryKey.value) && response.hasOwnProperty(primaryKey.value)) {
            $root.$set(model.value, primaryKey.value, response[primaryKey.value]);
        } else if (!model.value.hasOwnProperty(primaryKey.value) && response.hasOwnProperty('newid')) {
            $root.$set(model.value, primaryKey.value, response.newid);
        } else if (!model.value.hasOwnProperty(primaryKey.value) && response.hasOwnProperty(primaryKey.value)) {
            $root.$set(model.value, primaryKey.value, response[primaryKey.value]);
        }
    }

    function formValidationErrors(errors) {
        if (props.storeModul) {
            store.dispatch(props.storeModul +'/setFormErrors', errors)
        }
        else {
            store.dispatch('form/setFormErrors', errors)
        }
    }

    function setFormErrors(response) {
        if (response.hasOwnProperty('errors')) {
            formValidationErrors(response.errors);
        }
        return this;
    }

    function formLoaded(callback) {
        nextTick(() => {

            if (typeof callback === "function") {
                callback();
            }


            nextTick(() => {
                // this.$store.dispatch('refreshAllScrollbars');
                $root.triggerEvent(document, 'resize');
                $events.$emit('pageloading', false);

                if (props.storeModul) {
                    store.dispatch(props.storeModul +'/unDirty');
                    store.dispatch(props.storeModul +'/clearFormErrors');
                }
                else {
                    route.meta.dirty = false;
                    store.dispatch('form/unDirty');
                    store.dispatch('form/clearFormErrors');
                }
            })
        });
    }

    function saveForm(exit, postData = {}, routeParams = {}, customAxiosParams = null) {
        if (formsaving.value) {
            return;
        }

        formsaving.value = true;
        $events.$emit('pageloading', true);

        return new Promise((resolve, reject) => {
            let u = formUrl(routeParams);

            if (customAxiosParams && typeof customAxiosParams === "object") {
                $http.post(u, postData, customAxiosParams).then((r) => {

                    $root.notification(r.data);

                    if (r.data.success) {
                        $events.$emit('pageloading', false);

                        onAfterSaved(r.data);

                        resolve(r.data);
                    } else {
                        if (r.data.errors) {
                            if (!r.data.error && !r.data.msg) {
                                $root.notify('Das Formular konnte nicht gespeichert werden!', 'error');
                            }
                            setFormErrors(r.data);
                        }
                        $events.$emit('pageloading', false);
                        formsaving.value = false;

                        reject(r.data);

                    }
                }).catch(e => {

                    formsaving.value = false;
                    $events.$emit('pageloading', false);

                    if (e.response && e.response.data) {
                        setFormErrors(e.response.data);
                    }

                    reject(e);
                });
            }
            else {
                $http.post(u, postData).then((r) => {

                    $root.notification(r.data);

                    if (r.data.success) {
                        $events.$emit('pageloading', false);
                        onAfterSaved(r.data);

                        resolve(r.data);
                    } else {
                        if (r.data.errors) {
                            if (!r.data.error && !r.data.msg) {
                                $root.notify('Das Formular konnte nicht gespeichert werden!', 'error');
                            }
                            setFormErrors(r.data);
                        }
                        $events.$emit('pageloading', false);
                        formsaving.value = false;

                        reject(r.data);

                    }
                }).catch(e => {

                    formsaving.value = false;
                    $events.$emit('pageloading', false);
                    if (e.response && e.response.data) {
                        setFormErrors(e.response.data);
                        reject(e.response.data);
                    }
                    else {
                        reject(e);
                    }
                });
            }



        });
    }

    function onReset(e) {

        isMounted.value = false;
        nextTick(() => {
            isReset.value = true;
            if (props.storeModul) {
                store.dispatch(props.storeModul +'/resetFormModel');
            }
            else {
                store.dispatch('form/resetFormModel');
            }

            nextTick(() => {

                model.value = _.cloneDeep(currentFormModel.value);

                isMounted.value = true;

                nextTick(() => {

                    $events.$emit('form-reset');

                    wait(50).then(() => {

                        nextTick(() => {


                            if (props.storeModul) {
                                store.dispatch(props.storeModul +'/unDirty');
                            }
                            else {
                                store.dispatch('form/unDirty');
                                route.meta.dirty = false;
                            }

                            isReset.value = false;

                        });
                    });
                });

            });
        });
    }

    function onCancel() {

        isMounted.value = false;
        nextTick(() => {
            isReset.value = true;

            if (props.storeModul) {
                isReset.value = true;
                store.dispatch(props.storeModul +'/resetFormModel');
            }
            else {
                isReset.value = true;
                store.dispatch('form/resetFormModel');
            }


            nextTick(() => {
                isReset.value = false;
                router.back()
            })
        });
    }

    function onBeforeSave() {

    }

    function onAfterSaved(response) {

        let currentM = response.hasOwnProperty('newModel') && typeof response.newModel === "object" && response.newModel ? response.newModel : toRaw(model.value);

        if (response.hasOwnProperty('model') && typeof response.model === "object" && response.model) {
            currentM = response.model
        }

        updatePrimaryKey(response, primaryKey.value);

        if (props.storeModul) {
            store.dispatch(props.storeModul+'/setFormModel', currentM).then(() => {
                store.dispatch(props.storeModul+'/syncFormOriginalModel');
                store.dispatch(props.storeModul+'/unDirty');
                store.dispatch(props.storeModul+'/clearFormErrors');

                if (route.meta) route.meta.dirty = false;
                model.value = currentM;
                formsaving.value = false;


                $events.$emit('form-saved');


                if (props.storeModul) {
                    store.dispatch(props.storeModul+'/unDirty');
                }
                else {
                    store.dispatch('form/unDirty');
                }

                if (route.meta) route.meta.dirty = false;
                formsaving.value = false;


            });
        }
        else {
            store.dispatch('form/setFormModel', currentM).then(() => {

                store.dispatch('form/syncFormOriginalModel');
                store.dispatch('form/unDirty');
                store.dispatch('form/clearFormErrors');

                if (route.meta) {
                    route.meta.dirty = false;
                }

                model.value = currentM;
                formsaving.value = false;

                $events.$emit('form-saved');

                nextTick(() => {
                    store.dispatch('form/unDirty');

                    // if (route.meta) route.meta.dirty = false;
                    //
                    // formsaving.value = false;
                })


            })
        }

        if (response.hasOwnProperty('menues') && response.menues) {
            updateMenues(response.menues);
        }


        return this;
    }

    function onSavedAndNew(response) {
        $root.tempResponse = response;

        if (_.isString(saveAndNew.value.path) && saveAndNew.value.path && _.isFunction(saveAndNew.value.callback)) {
            handleSaveAndNew(saveAndNew.value.path, saveAndNew.value.callback);
        }

        if (_.isString(saveAndNew.value.name) && saveAndNew.value.name && _.isFunction(saveAndNew.value.callback)) {
            let r = $root.getRouteByName(saveAndNew.value.name);
            handleSaveAndNew((r.route && r.route.path ? r.route.path : null), saveAndNew.value.callback);
        }
    }

    function handleSaveAndNew(toPath, callbackSelf) {

        if (route.path === toPath) {
            if (callbackSelf) {
                callbackSelf();
            }
        } else {
            router.push({path: toPath});
        }
    }

    /**
     *
     * @param routePush
     */
    function backToDataTable(routePush) {
        $root.onRouteView = () => {
            $events.$emit('grid.refresh');
        };

        router.push(routePush);
    }

    function bindAutosaveEvents() {
        if ($root.livesaving && !isCreate.value) {
            // const form = this.formcontainer;
            //
            // if (form) {
            //     form.$el.querySelectorAll('[autosave]').forEach((el) => {
            //         const attr = el.getAttribute('autosave');
            //         if (attr) {
            //             let r = attr.split('|');
            //             if (r.length === 3) {
            //
            //                 let fieldmodelname = r[0];
            //                 let fieldtagname = r[1];
            //                 let fieldevent = r[2];
            //
            //                 // suche den container "form-field-container"
            //                 let fieldWrapper = getParents(el, '.form-field-container');
            //                 if (fieldWrapper) {
            //                     let saving = fieldWrapper.querySelector('.saving');
            //                     if (saving) {
            //
            //                         let str = 'livesaving.' + fieldmodelname;
            //                         this.$events.$on(str, (n) => {
            //                             if (n) {
            //                                 this.$refs.savingProgress.start();
            //                             }
            //                             else {
            //                                 this.$refs.savingProgress.done();
            //                             }
            //                         });
            //
            //                         el.addEventListener(fieldevent, () => {
            //                             this.executeLiveSave();
            //                         });
            //
            //                     }
            //                 }
            //
            //
            //
            //             }
            //         }
            //     })
            // }
        }
    }


    function executeLiveSave() {


        if (isCreate.value) {
            return;
        }

        // this.formcontainer.$emit('livesave', {
        //     name: this.name,
        //     value: this.modelvalue
        // });
    }




    onBeforeMount(() => {

    });

    onMounted(() => {
        const m = currentFormModel.value ?? {};
        if (props.storeModul) {
            store.dispatch(props.storeModul+'/setFormModel', m);
        }
        else {
            store.dispatch('form/setFormModel', m);
        }
        model.value = m;
        isMounted.value = true;
    });

    onBeforeUnmount(() => {

        if (!doNotClearForm.value) {
            $events.$off('formValidationErrors', formValidationErrors);
            $events.$off('afterFormSaved');

            model.value = {};
            orgmodel.value = {};

            if (props.storeModul) {
                store.dispatch(props.storeModul +'/clearForm');
            }
            else {
                store.dispatch('form/clearForm');
            }
        }




    });


    return {
        isMounted,
        loading,
        formsaving,
        rendersections,
        primaryKey,
        defaultFormUrl,
        form,
        model,
        errors,
        toolbar,
        isDirty,
        isCreate,
        saveAndNew,
        loadFormWithParams,
        loadFormWithRouteParams,
        store_route,
        update_route,
        formFields,
        formTabs,
        doNotClearForm,
        resetDirty,
        makeDirty,
        formUrl,
        primaryKeyValue,
        isUpdate,
        loadForm,
        updatePrimaryKey,
        formValidationErrors,
        setFormErrors,
        formLoaded,
        saveForm,
        onReset,
        onCancel,
        onBeforeSave,
        onAfterSaved,
        onSavedAndNew,
        handleSaveAndNew,
        backToDataTable,
        bindAutosaveEvents,
        executeLiveSave,
    }
}
