<template>
    <div class="form-wrapper" v-bind="attributes">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "form-renderer",
    props: {
        errors: {
            required: false,
            default() {
                return []
            }
        },
        attributes: {
            required: false,
            default() {
                return []
            }
        }
    }
}
</script>
