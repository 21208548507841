import messagerIndex from './components/Index.vue';


export default [
    {
        path: '/messager',
        component: messagerIndex,
        name: 'admin.messager',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
];