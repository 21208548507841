<template>
    <div class="form-errors" v-if=" mutatedErrors.length > 0">
        <div class="alert alert-danger">
            <ul style="margin-bottom: 0;">
                <li v-for="(error, fieldIndex) in mutatedErrors">
                    {{ fieldIndex + 1 }}.)
                    <a href="#" @click.prevent="(e) => jumpToError(e, error, fieldIndex)">
                        {{ transformMessage(error, fieldIndex) }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { ref,  watch, inject, nextTick} from "vue";

export default {
    props: ['errors'],
    setup(props, context) {

        const mutatedErrors = ref([]);
        const rawErrors = ref(null);
        const $events = inject('$events')

        watch(() => props.errors, (n, o) => {
            if (n !== o && n) {
                rawErrors.value = n;

                if (_.isObject(n)) {
                    //
                    let errors = [];
                    _.each(n, (v, name) => {

                        errors.push({
                            field: name,
                            value: v
                        });

                        $events.$emit('field-error', name);
                    });

                    mutatedErrors.value = errors; //this.$root.objectToArray(n);
                } else {
                    mutatedErrors.value = n;
                }
            }
        }, {deep: true, immediate: true});



        function jumpToError(event, error, fieldIndex) {
            event.stopImmediatePropagation();
            event.preventDefault();
            if (_.isObject(error)) {
                if (error.field) {
                    let f = document.querySelector('[name="' + error.field + '"]');
                    if (f) {
                        nextTick(async () => {
                            await f.scrollIntoView({behavior: 'smooth'});
                            nextTick(() => {
                                if (typeof f.focus === "function") {
                                    f.focus();

                                    if (typeof f.select === "function") {
                                        f.select()
                                    }
                                }
                            });
                        });
                    }
                    else {
                        f = document.querySelector('[data-name="' + error.field + '"]');
                        if (f) {
                            nextTick(async () => {
                                await f.scrollIntoView({behavior: 'smooth'});
                                nextTick(() => {
                                    if (typeof f.focus === "function") {
                                        f.focus();

                                        if (typeof f.select === "function") {
                                            f.select()
                                        }
                                    }
                                });
                            });
                        }
                    }
                }
            }
        }


        function transformMessage(error, field) {
            if (_.isObject(error)) {

                bindFieldWatch(error.field);

                if (error.value && _.isString(error.value)) {
                    return error.value;
                }

                if (error.value && _.isArray(error.value)) {
                    return error.value[0]
                }
            } else {
                if (_.isString(error)) {
                    return error;
                }
            }
            return 'undefined message'
        }


        function bindFieldWatch(modelpath) {

        }

        return {
            mutatedErrors,
            jumpToError,
            transformMessage
        }

    }
}
</script>

<style scoped>

</style>
