<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "~/vue"

export default defineComponent({
    name: "btn-source-details",
    props: {
        type: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        formModel: {
            type: Object,
            required: true
        },
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const $http = inject('$http')
        const open = ref(false);
        const loading = ref(false)
        const details = ref(null)
        const labels = ref(null)

        const url = computed(() => {
            switch (props.type) {
                case 'links':
                    return app.routes('admin.requests.details.links');

                case 'literatures':
                    return app.routes('admin.requests.details.literatures');

                case 'files':
                    return app.routes('admin.requests.details.files');
            }

            return null;
        });



        function loadData() {
            if (details.value !== null || loading.value || !url.value) {
                return;
            }

            open.value = true;
            nextTick(() => {
                loading.value = true;

                $http.post(url.value, {
                    item: props.id,
                    requestId: props.formModel.id
                }).then(r => {
                    if (r.data.success) {
                        details.value = r.data.details;
                        labels.value = r.data.labels
                    }

                    loading.value = false;
                }).catch(e => {
                    loading.value = false;
                });
            })
        }

        watch(() => open.value, (n) => {
            if (n) {
                loadData()
            }
        })

        const notRenderFields = ['created_by', 'updated_by', 'deleted_by', 'created_at', 'updated_at', 'deleted_at']
        function canRenderField(k) {
            return notRenderFields.indexOf(k) < 0;
        }


        return {
            open,
            loading,
            details,
            labels,
            canRenderField,
            loadData
        }
    }
})
</script>

<template>

    <div class="item-details">
        <button type="button"
                class="btn btn-primary"
                v-tooltip="'Details anzeigen'"
                @click.prevent="open = true">
            <svg-icon name="search"/>
        </button>

        <modal :use-footer="false"
               :show="open"
               :ok-btn-label="'Details schließen'"
               width="70%"
               height="80%"
               @close="open = false"
               @cancel="open = false">

            <template v-slot:title>
                <span v-if="type === 'links'">Details zum Link ...</span>
                <span v-if="type === 'literatures'">Details zur Literatur ...</span>
                <span v-if="type === 'files'">Details zur Anlage ...</span>
            </template>

            <template v-slot:body>
                <loading :active="loading === true"
                         color="blue"
                         :width="20"
                         :height="20"
                         :opacity="0.6"
                         :is-full-page="false">
                    <template v-slot:after>
                        <div class="m">Hole Details...</div>
                    </template>
                </loading>


                <template v-if="!loading && details && details.sourceable">
                    <div class="detail-entries">
                        <template v-for="(v, k) in details.sourceable">
                            <div class="entrie" v-if="canRenderField(k)">
                                <div>{{ labels.hasOwnProperty(k) ? labels[k] : k }}</div>
                                <div>{{ v }}</div>
                            </div>
                        </template>
                    </div>

                </template>
            </template>
        </modal>
    </div>

</template>
