<script>
import {computed, defineComponent, getCurrentInstance, inject, nextTick, ref, watch} from "vue";
import {defaultFieldProps, useFormField} from "@/mixins/use-form-field";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "ams-clinical-params",
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const store = useStore()

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);

        const route = useRoute();
        const $http = inject('$http');
        const $moment = inject('$moment');

        const notification = inject('notification');
        const enableEditingWatcher = ref(false);
        const editing = ref(false);
        const editingRow = ref(null);
        const editingDirty = ref(false);

        const loading = ref(false);
        const warning = ref(false);
        let newID = 0;


        watch(() => editingRow.value, (n, o) => {
            if (enableEditingWatcher.value && n !== o) {
                editingDirty.value = true
            }
        }, {deep: true})



        const abweichungen = computed(() => {
            if (Array.isArray(props.field.deviation_options)) {
                return props.field.deviation_options;
            }
            else if (typeof props.field.deviation_options === "object") {
                return props.field.deviation_options;
            }


            return [
                'ohne Bewertung',
                '+',
                '-',
                '0'
            ];
        })



        const canEdit = computed(() => {
            return editing.value === false
        })

        const warning_label = computed(() => {
            if (warning.value) {
                let r = _.find(modelvalue.value, {id: warning.value})
                if (r) {
                    return r.size +' '+ r.laboratory_value
                }
            }
            return null;
        })

        function formatDate(v, format = 'DD.MM.YYYY') {
            return v ? $moment(v).format(format) : null;
        }

        function remove(id) {
            if (editingDirty.value) return;

            if (typeof id === "string" && id.match(/^n\d+?/g)) {
                modelvalue.value = _.filter(_.cloneDeep(modelvalue.value), (r) => {
                    return id !== r.id
                })
            } else {
                warning.value = id;
            }
        }

        function confirmRemove() {
            if (warning.value > 0) {
                let item = _.find(modelvalue.value, {id: warning.value});
                if (item) {
                    loading.value = true;

                    $http.post(app.routes('admin.ams.clinical-param.remove'), {id: warning.value, case_id: props.formModel.id}).then(r => {

                        notification(r.data);

                        if (r.data.success)
                        {
                            modelvalue.value = _.filter(_.cloneDeep(modelvalue.value), (r) => {
                                return warning.value !== r.id
                            })

                            loading.value = false;
                            warning.value = false;

                        }
                        else {
                            loading.value = false;
                        }
                    }).catch(e => {
                        loading.value = false;
                    });
                }
            }
        }


        function add() {
            if (editingDirty.value) return;

            if (!Array.isArray(modelvalue.value)) {
                modelvalue.value = [];
            }

            newID++;

            modelvalue.value.push({
                'id': 'n' + newID,
                'days_from': null, // tage ab
                'laboratory_value': null, // laborwert
                'size': null, // größe
                'measurement_method': null, // messmethode
                'location': null, // ort
                'deviation': null, // abweichung
                'memo': null, // anmerkung
            });

            editing.value = 'n'+ newID;
        }

        function editEntry(id, row) {
            if (editingDirty.value) return;

            enableEditingWatcher.value = false;
            editingDirty.value = false;

            editingRow.value = row;
            editing.value = id;

            nextTick(() => {
                enableEditingWatcher.value = true;
            })
        }

        function cancelEditEntry(id) {
            if (editing.value === id) {
                enableEditingWatcher.value = false;
                editingRow.value = null;
                editing.value = false;
                editingDirty.value = false
            }
        }







        return {
            loading,
            modelvalue,
            warning,
            warning_label,
            editing,
            canEdit,
            editingDirty,
            editingRow,
            abweichungen,
            formatDate,
            add,
            remove,
            confirmRemove,

            editEntry,
            cancelEditEntry,
        }
    }
})
</script>

<template>
    <confirm
        :show="warning !== false"
        title="klinisches Parameter entfernen ..."
        ok-label="Ja diese Medikation entfernen"
        type="warning"
        @cancel="warning = false"
        @submit="confirmRemove">
        <template v-slot:message>

            <loading :active="loading === true"
                     color="blue"
                     :width="40"
                     :height="40"
                     :opacity="0.6"
                     :is-full-page="false">
            </loading>

            <p>Möchten Sie die Medikation "{{ warning_label }}" wirklich entfernen?</p>
            <p><b>Diese wird dann direkt entfernt und kann nicht wiederhergestellt werden!</b></p>
        </template>
    </confirm>

    <div class="grid-container-outer ams-clinical-params-container">
        <table class="table fixed-layout vuetable table-striped table-hovered">
            <thead class="grid-header">
                <tr>
                    <th width="80"><div></div></th>
                    <th width="120"><div>Datum</div></th>
                    <th width="180"><div>Größe / Einheit </div></th>
                    <th width="120"><div>Wert</div></th>

                    <th width="25%"><div>Messmethode/Ort</div></th>
                    <th width="140"><div>Ort der Messung</div></th>
                    <th width="110"><div>+/-/0</div></th>
                    <th width="auto"><div>Anmerkung</div></th>
                </tr>
            </thead>
            <tbody class="vuetable-body grid-data">
                <template v-if="Array.isArray(modelvalue) && modelvalue.length">
                    <template v-for="(r, i) in modelvalue">
                        <tr :class="i % 2 ? 'even' : 'odd'">
                            <td width="80" data-label="Action">
                                <div class="actions">
                                    <template v-if="r.id === editing">

                                        <button type="button" class="btn btn-sm"
                                                v-tooltip="'klinischen Parameter speichern'">
                                            <svg-icon name="device-floppy"/>
                                        </button>

                                        <button type="button" class="btn btn-sm"
                                                v-tooltip="'Bearbeitung abbrechen'"
                                                :disabled="editingDirty"
                                                @click="cancelEditEntry(r.id)">
                                            <svg-icon name="x"/>
                                        </button>

                                    </template>
                                    <template v-else>
                                        <button type="button" class="btn btn-sm"
                                                v-tooltip="'klinischen Parameter bearbeiten'"
                                                @click="editEntry(r.id, r)" :disabled="editingDirty">
                                            <svg-icon name="pencil"/>
                                        </button>
                                        <button type="button" class="btn btn-sm"
                                                v-tooltip="'klinischen Parameter entfernen'"
                                                @click="remove(r.id)" :disabled="editingDirty">
                                            <svg-icon name="trash"/>
                                        </button>
                                    </template>

                                </div>
                            </td>
                            <td width="120" data-label="Datum"><div>{{ formatDate(r.updated_at || r.created_at) }}</div></td>
                            <td width="180" data-label="Größe / Einheit"><div>
                                <input type="text" v-if="r.id === editing"
                                       v-model="r.size"
                                       placeholder="Größe / Einheit ..."
                                />
                                <span v-else>{{ r.size }}</span>
                            </div></td>

                            <td width="120" data-label="Wert">
                                <div>
                                    <input type="text" v-if="r.id === editing"
                                           v-model="r.laboratory_value"
                                           placeholder="Wert ..."
                                    />
                                    <span v-else>
                                        {{ r.laboratory_value }}
                                    </span>
                                </div>
                            </td>

                            <td width="25%" data-label="Messmethode"><div>
                                <input type="text" v-if="r.id === editing"
                                       v-model="r.measurement_method"
                                       placeholder="Messmethode ..."
                                />
                                <span v-else>
                                {{ r.measurement_method || '-'}}
                                </span>
                            </div></td>


                            <td width="140" data-label="Ort der Messung"><div>
                                <input type="text" v-if="r.id === editing"
                                       v-model="r.location"
                                       placeholder="Ort der Messung ..."
                                />
                                <span v-else>
                                {{ r.location || '-'}}
                                </span>
                            </div></td>


                            <td width="80" data-label="+/-/0"><div>
                                <select v-model="r.deviation" v-if="r.id === editing">
                                    <template v-for="(a, k) in abweichungen">
                                        <option :value="typeof a === 'object' && a.value ? a.value : k">{{ typeof a === 'object' && a.label ? a.label : a }}</option>
                                    </template>
<!--                                    <option value="+">+</option>-->
<!--                                    <option value="-">-</option>-->
<!--                                    <option value="0">0</option>-->
                                </select>
                                <span v-else>
                                    {{ r.deviation }}
                                </span>
                            </div></td>
                            <td width="auto" data-label="Anmerkung"><div>

                                <input type="text" v-if="r.id === editing"
                                       v-model="r.memo"
                                       placeholder="Anmerkung ..."
                                />
                                <span v-else>
                                    {{ r.memo }}
                                </span>





                            </div></td>
                        </tr>
                    </template>
                </template>
                <template v-else>
                    <tr>
                        <td colspan="7" class="empty"><em>es wurden keine klinischen Parameter hinterlegt</em></td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <div class="after-table-actions">
        <button type="button" class="btn btn-primary" @click="add">klinischen Parameter hinzufügen</button>
    </div>
</template>

<style scoped>

</style>
