<template>

    <form-field :name="name" :field="field" :form-model="formModel" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>
            <div>
                <div class="switcher" :style="{width: width}">
                    <input type="checkbox"
                           :name="name"
                           :id="idAttr"
                           v-attr="fieldAttributes"
                           :checked="shouldBeChecked"
                           @input="updateInput"/>

                    <span class="switch-well">
                    <span class="off" v-html="getOffLabel"></span>
                    <span class="on" v-html="getOnLabel"></span>
                        <a class="slide-button"></a>
                    </span>
                </div>

                <template v-if="field.switchlabel">
                    <label class="switch-label" :for="idAttr" >
                        {{ field.switchlabel }}
                    </label>
                </template>
            </div>


        </template>


    </form-field>
</template>

<script>

import {computed, getCurrentInstance,  ref, watch, onBeforeMount, inject} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-switch",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
        onLabel: {
            type: String,
            default() {
                return 'An'
            }
        },
        offLabel: {
            type: String,
            default() {
                return 'Aus'
            }
        },

        trueValue: {
            default() {
                return true
            }
        },
        falseValue: {
            default() {
                return false
            }
        },
    },
    setup(props, context) {
        const $set = inject('$set')


        const {modelName, allowMultiple, modelvalue, fieldAttributes} = useFormField(props, context);

        const mutatedValue = ref(null);
        const mchecked = ref(undefined);

        const getOnLabel = computed(() => {
            if (props.field.onlabel) {
                return props.field.onlabel
            }
            return props.onLabel;
        });

        const getOffLabel = computed(() => {
            if (props.field.offlabel) {
                return props.field.offlabel
            }
            return props.offLabel;
        });


        const _trueValue = computed(() => {
            return typeof props.trueValue === 'string' ? props.trueValue : !!props.trueValue;
        });

        const _falseValue = computed(() => {
            return typeof props.falseValue === 'string' ? props.falseValue : !!props.falseValue;
        });
        const idName = computed(() => {
            return modelName.value.replace(/\./g, '_') +'-'+ (new Date().getTime());
        });
        const idAttr = computed(() => {
            if (fieldAttributes.hasOwnProperty('id') && fieldAttributes.id) {
                return fieldAttributes.id;
            }
            else {
                return 'toggle-'+ idName.value;
            }
        });

        const shouldBeChecked = computed(() => {
            if (props.name) {
                let modelValue = modelvalue.value;
                if (modelValue !== undefined) {

                    // checkbox
                    if (modelValue instanceof Array) {
                        return modelValue.includes(mutatedValue.value);
                    }

                    if (_trueValue.value) {
                        return modelValue === props.trueValue;
                    }

                    return typeof modelValue === 'string' ? true : !!modelValue;
                }
            }

            // this.modelValue === undefined
            if (mchecked.value === undefined)
                return mchecked.value = typeof props.checked === 'string' ? true : !!props.checked;
            else
                return mchecked.value;
        });

        const width = computed(() => {
            return props.field.width ? props.field.width : null;
        });

        watch(() => props.value, (v) => {
            mutatedValue.value = v;
            transformValue();
        });

        watch(() => props.field.value, () => {
            if (!props.name) {
                mutatedValue.value = v;
                transformValue();
            }
        });

        watch(() => props.checked, (n) => {
            mchecked.value = n
        });

        watch(() => mchecked.value, (v) => {
            mutatedValue.value = v;
        });


        function transformValue() {
            if (mutatedValue.value === '0' || mutatedValue.value === 0 ||
                mutatedValue.value === 'false' || mutatedValue.value === false) {
                mutatedValue.value = false;
            } else if (mutatedValue.value === '1' || mutatedValue.value === 1 ||
                mutatedValue.value === 'true' || mutatedValue.value === true) {
                mutatedValue.value = true;
            }

            mchecked.value = mutatedValue.value;
        }


        function updateInput(event) {

            let modelValue = mutatedValue.value;
            let value = event.target.value;
            let isChecked = event.target.checked;
            mchecked.value = isChecked;

            if (allowMultiple.value || props.multiple) {

                if (modelValue === null) {
                    modelValue = [];
                    $set(props, 'value', modelValue, true);
                }

                if (_.isObject(modelValue)) {
                    modelValue = Object.keys(modelValue).map((key) => {
                        return modelValue[key]
                    })
                }

                if (_.isArray(modelValue)) {

                    let newValue = modelValue;

                    if (isChecked) {
                        newValue.push(value);
                    } else {
                        newValue.splice(newValue.indexOf(value), 1);
                    }


                    mutatedValue.value = newValue;

                } else {
                    mutatedValue.value = isChecked ? (_trueValue.value ? props.trueValue : true) : (_falseValue.value ? props.falseValue : false);

                }
            } else {

                mutatedValue.value = isChecked ? (_trueValue.value ? props.trueValue : true) : (_falseValue.value ? props.falseValue : false);
            }


            modelvalue.value = isChecked ? (_trueValue.value ? props.trueValue : true) : (_falseValue.value ? props.falseValue : false)

            context.emit('input', isChecked);
            context.emit('change', {
                value: isChecked,
            })
        }


        onBeforeMount(() => {
            if (props.name) {
                mchecked.value = modelvalue.value;
                mutatedValue.value = mchecked.value;
            } else {
                mutatedValue.value = props.field.value;
            }

            transformValue();
        });


        return {
            fieldAttributes,
            modelvalue,
            shouldBeChecked,
            mchecked,
            mutatedValue,
            width,

            getOffLabel,
            getOnLabel,
            idAttr,
            updateInput,
        }


    },
}
</script>

<style scoped>

</style>
