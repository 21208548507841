<template>
    <form-field :name="name" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors" :form-model="formModel">
        <template v-slot:field>
            <button
                type="button"
                @click.prevent="(e) => handleAction(e)"
                :class="field.class">
                {{ label }}
            </button>
        </template>

    </form-field>
</template>

<script>

import {computed, ref, onBeforeMount, inject} from "vue"
import { defaultFieldProps} from "@/mixins/use-form-field";


export default {
    name: "form-button",

    props: {
        ...defaultFieldProps,
        returnOnly: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {

        const label = ref('')
        const mutatedAction = ref(null);
        const $events = inject('$events')
        const hasAction = computed(() => {
            return !_.isEmpty(props.field.action);
        });

        onBeforeMount(() => {
            label.value = props.field.label;
            delete props.field.label;
            if (props.field.action) {
                if (props.field.action.match(/\s*\(\)\s*=>\s*/g) || props.field.action.match(/\$events\.\$emit/g)) {

                    let action = props.field.action.replace(/^this\.\$events/g, '$events');

                    // string to function
                    mutatedAction.value = (e, btn) => {
                        return eval(action);
                    };
                } else {
                    mutatedAction.value = props.field.action
                }
            }
        });


        function handleAction(e) {
            if (mutatedAction.value) {
                if (_.isFunction(mutatedAction.value)) {
                    mutatedAction.value(e, this)
                } else {
                    eval(mutatedAction.value);
                }
            }
        }

        return {
            label,
            hasAction,
            handleAction
        }
    },
}
</script>

<style scoped>

</style>
