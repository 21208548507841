<script>
import {defineComponent, ref, computed, watch, onBeforeUnmount} from 'vue'
import {useStore} from "~/vuex";
export default defineComponent({
    name: "ErrorPage",
    setup(props, context) {
        const store = useStore();
        const error = ref(null);

        watch(() => store.state.responseError.error, (n) => {
            error.value = n;
        }, {deep: true, immediate: true});


        const message = computed(() => {
            return error.value && error.value.data && error.value.data.message ? error.value.data.message : null;
        });

        const file = computed(() => {
            return error.value && error.value.data && error.value.data.file ? error.value.data.file : null;
        });

        const line = computed(() => {
            return error.value && error.value.data && error.value.data.hasOwnProperty('line') ? error.value.data.line : null;
        });

        const trace = computed(() => {
            return error.value && error.value.data && error.value.data.trace ? error.value.data.trace : null;
        });

        onBeforeUnmount(() => {
            error.value = null;
        })

        return {
            error,
            message,
            file,
            line,
            trace
        }
    }
})
</script>

<template>
    <div class="alert alert-danger error-page">
        <h3>Error</h3>
        <p>{{ message }}</p>

        <template v-if="file && line">
            <div>
                <div><b>File</b></div>
                <div>{{ file }} @ {{ line }}</div>
            </div>
        </template>
        <template v-if="trace">
            <div class="trace-info">
                <div><b>Trace</b></div>
	            <div>
		            <template v-if="Array.isArray(trace)">
			            <template v-for="r in trace">
				            <div class="trace-item">
					            <div class="file">{{ r.file }}@{{ r.line }}</div>
					            <template v-if="r.class && r.function">
						            <div class="function">{{ r.class }}::{{ r.function }}()</div>
					            </template>
					            <template v-if="!r.class && r.function">
						            <div class="function">{{ r.function }}()</div>
					            </template>
				            </div>
			            </template>
		            </template>
	            </div>

            </div>
        </template>
    </div>

</template>

<style scoped>

</style>
