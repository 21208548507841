<template>
    <template v-if="isMounted">
        <div class="radio-wrapper" :class="{disabled: fieldIsDisabled }">
            <input ref="formfield"
                   type="radio"
                   :name="name"
                   :value="fieldValue"
                   v-bind="fieldAttributes"
                   :checked="shouldBeChecked"
                   @change="updateInput"
                   :id="fieldId(fieldValue)"/>
            <span></span>
        </div>
    </template>

</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, nextTick, inject} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "radio-field",
    emits: ['input', 'focus', 'blur', 'updated'],
    props: {
        ...defaultFieldProps,
        id: {},
        trueValue: {},
        falseValue: {}
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const {uiq, modelName, modelvalue, fieldAttributes} = useFormField(props, context);

        const isMounted = ref(false);
        const mchecked = ref(false);
        const $events = inject('$events')

        watch(() => props.value, (n, o) => {
            if (n !== o) {
                mchecked.value = n == props.fieldValue;
            }
        });

        onMounted(() => {
            if (_.isArray(modelvalue.value)) {
                mchecked.value = _.indexOf(modelvalue.value, props.fieldValue) !== -1;
            } else {
                mchecked.value = props.fieldValue == modelvalue.value;
            }

            $events.$on('form-reset', () => {
                isMounted.value = false;

                if (_.isArray(modelvalue.value)) {
                    mchecked.value = _.indexOf(modelvalue.value, props.fieldValue) !== -1;
                } else {
                    mchecked.value = props.fieldValue == modelvalue.value;
                }

                nextTick(() => {
                    isMounted.value = true;
                })
            });


            isMounted.value = true;
        });


        const shouldBeChecked = computed(() => {
            if (modelvalue.value == props.fieldValue) return true;
            return mchecked.value;
        });


        const checkboxId = computed(() => {
            if (props.id) return props.id;
            let name = props.name;
            let idName = name.replace(/\[\]/g, '_');
            idName = idName.replace(/\[/g, '-');
            idName = idName.replace(/\}/g, '');
            return idName.replace(/\./g, '-');
        });

        const isChecked = computed(() => {
            return mchecked.value;
        });

        const myfieldAttributes = computed(() => {
            let attrs = _.cloneDeep(fieldAttributes.value);
            return {
                id: checkboxId.value,
                ...attrs
            }
        });

        const fieldIsDisabled = computed(() => {
            return myfieldAttributes.value.hasOwnProperty('disabled') || props.disabled === true;
        });


        function fieldId(value) {
            if (props.id) {
                return props.id;
            }


            return app.transformKey(modelName.value).replace(/\./g, '-') + '_' + value;
        }

        function updateInput(e) {

            mchecked.value = e.target.checked;

            context.emit('input', mchecked.value);

            if (props.field.event) {
                if (props.field.event.match(/^on([A-Z][a-zA-Z]+)/g)) {
                    //this.$events[this.field.event](this.mchecked)
                    eval('app.$events.$emit(\'' + props.field.event + "\', " + mchecked.value + ')');
                }
            }
        }

        return {
            isMounted,
            fieldIsDisabled,
            modelvalue,
            shouldBeChecked,
            checkboxId,
            fieldAttributes: myfieldAttributes,

            fieldId,
            updateInput
        }
    },
    //
    // // mixins: [FormField],
    // // props: {
    // //     name: {
    // //         required: true
    // //     },
    // //     validate: {
    // //         type: Boolean,
    // //         default: false
    // //     },
    // //     field: {
    // //         required: true,
    // //         default() {
    // //             return {
    // //                 type: 'checkbox'
    // //             }
    // //         }
    // //     },
    // //     value: {},
    // //     fieldValue: {},
    // //     disabled: {
    // //         type: Boolean,
    // //         default: false,
    // //     },
    // //     responseerrors: {
    // //         type: [Object, Array],
    // //         default() {
    // //             return {}
    // //         }
    // //     },
    // //     rendersections: {
    // //         required: false,
    // //         default() {
    // //             return {}
    // //         }
    // //     },
    // // },
    // data() {
    //     return {
    //         mchecked: undefined,
    //         trueValue: null,
    //         falseValue: null,
    //         modelName: null,
    //         model: null,
    //         multiple: false
    //     }
    // },
    //
    // mounted() {
    //
    //     if (_.isArray(this.modelvalue)) {
    //         this.mchecked = _.indexOf(this.modelvalue, this.fieldValue) !== -1;
    //     }
    //     else {
    //         this.mchecked = this.value;
    //     }
    //
    //
    //
    //
    // },
    //
    // watch: {
    //     value(v, o) {
    //         if (v !== o) {
    //             this.mchecked = v;
    //         }
    //     },
    // },
    //
    // computed: {
    //
    //     _trueValue() {
    //         return typeof this.trueValue === 'string' ? this.trueValue : !!this.trueValue;
    //     },
    //     _falseValue() {
    //         return typeof this.falseValue === 'string' ? this.falseValue : !!this.falseValue;
    //     },
    //
    //     shouldBeChecked() {
    //         if (this.mchecked === undefined)
    //             return this.mchecked = typeof this.value === 'string' ? true : !!this.value;
    //         else
    //             return this.mchecked;
    //     },
    //
    //     fieldAttributes() {
    //         let defaults = _.cloneDeep(this.defaultAttributes);
    //         let attrs = _.cloneDeep(this.mutatedAttributes);
    //
    //         return {
    //             id: this.checkboxId,
    //             ...defaults,
    //             ...attrs
    //         }
    //     },
    //     checkboxId(){
    //         if (this.id) return this.id;
    //
    //         let name = this.name;
    //         let idName = name.replace(/\[\]/g, '_');
    //         idName = idName.replace(/\[/g, '-');
    //         idName = idName.replace(/\}/g, '');
    //
    //         return this.modelName.replace(/\./g, '-');
    //     },
    //
    //     isChecked() {
    //         return this.mchecked;
    //     }
    // },
    // methods: {
    //     fieldId(value) {
    //         return this.transformKey( this.modelName ).replace(/\./g, '-') +'_'+ value;
    //     },
    //     updateInput(e) {
    //         this.mchecked = e.target.checked;
    //         this.$emit('input', this.mchecked);
    //
    //         if (this.field.event) {
    //             if (this.field.event.match(/^on([A-Z][a-zA-Z]+)/g)) {
    //                 //this.$events[this.field.event](this.mchecked)
    //                 eval('this.$events.$emit(\''+ this.field.event +"\', "+ this.mchecked +')');
    //             }
    //         }
    //     }
    // }
}
</script>

<style scoped>

</style>
