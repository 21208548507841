<template>
    <form-field :name="name" :field="field" :key="name" :validate="validate"
                :responseerrors="responseerrors"
                :form-model="formModel">

        <template v-slot:field>
            <div class="checkbox-list"
                 :class="[field.classes ?? '', { disabled: fieldIsDisabled, horizontal: isHorizontal, 'as-tree': field.tree }]">
                <template v-if="isMounted">

                    <template v-if="field.tree">
                        <template v-for="(f, k) in optionsAsTree">

                            <template v-if="f.hasOwnProperty(field.childKey)">

                                <div class="checkbox-field-tree-item">

                                    <div class="field-item">
                                        <template v-if="f.hasOwnProperty('level') && f.level > 0">
                                            <template v-for="n in parseInt(f.level)">
                                                <div class="level"></div>
                                            </template>
                                        </template>
                                        <div class="checkbox-field checkbox">
                                            <div class="checkable-wrapper">
                                                <input type="checkbox"
                                                       :name="name"
                                                       :id="fieldId(f.value)"
                                                       :value="f.value"
                                                       :disabled="fieldIsDisabled"
                                                       :checked="isChecked(f)"
                                                       @input="updateInput"/>
                                                <svg-icon name="check" v-if="isChecked(f)"/>
                                            </div>
                                        </div>
                                        <div class="checkbox-field-label">
                                            <label :for="fieldId(f.value)">{{ f.label }}</label>
                                        </div>
                                    </div>

                                    <template v-for="(f0, k) in f[field.childKey]">
                                        <div class="field-item">
                                            <template v-if="f0.hasOwnProperty('level') && f0.level > 0">
                                                <template v-for="n in parseInt(f0.level)">
                                                    <div class="level"></div>
                                                </template>
                                            </template>
                                            <div class="checkbox-field checkbox">
                                                <div class="checkable-wrapper">
                                                    <input type="checkbox"
                                                           :name="name"
                                                           :id="fieldId(f0.value)"
                                                           :value="f0.value"
                                                           :disabled="fieldIsDisabled"
                                                           :checked="isChecked(f0)"
                                                           @input="updateInput"/>
                                                    <svg-icon name="check" v-if="isChecked(f0)"/>
                                                </div>
                                            </div>
                                            <div class="checkbox-field-label">
                                                <label :for="fieldId(f0.value)">{{ f0.label }}</label>
                                            </div>
                                        </div>
                                    </template>
                                </div>



                            </template>
                            <template v-else>
                                <div class="field-item">
                                    <template v-if="f.hasOwnProperty('level') && f.level > 0">
                                        <template v-for="n in parseInt(f.level)">
                                            <div class="level"></div>
                                        </template>
                                    </template>
                                    <div class="checkbox-field checkbox">
                                        <div class="checkable-wrapper">
                                            <input type="checkbox"
                                                   :name="name"
                                                   :id="fieldId(f.value)"
                                                   :value="f.value"
                                                   :disabled="fieldIsDisabled"
                                                   :checked="isChecked(f)"
                                                   @input="updateInput"/>
                                            <svg-icon name="check" v-if="isChecked(f)"/>
                                        </div>
                                    </div>
                                    <div class="checkbox-field-label">
                                        <label :for="fieldId(f.value)">{{ f.label }}</label>
                                    </div>
                                </div>
                            </template>


                        </template>
                    </template>
                    <template v-else>
                        <template v-for="(f, k) in mutatedFieldOptions">
                            <div class="field-item">
                                <template v-if="f.hasOwnProperty('level') && f.level > 0">
                                    <template v-for="n in parseInt(f.level)">
                                        <div class="level"></div>
                                    </template>
                                </template>
                                <div class="checkbox-field checkbox">
                                    <div class="checkable-wrapper">
                                        <input type="checkbox"
                                               :name="name"
                                               :id="fieldId(f.value)"
                                               :value="f.value"
                                               :disabled="fieldIsDisabled"
                                               :checked="isChecked(f)"
                                               @input="updateInput"/>
                                        <svg-icon name="check" v-if="isChecked(f)"/>
                                    </div>
                                </div>
                                <div class="checkbox-field-label">
                                    <label :for="fieldId(f.value)">{{ f.label }}</label>
                                </div>
                            </div>
                        </template>
                    </template>


                </template>



            </div>
        </template>

    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, nextTick, inject, onBeforeMount, watch} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "checkbox-list",
    emits: ['input'],
    props: {
        ...defaultFieldProps,
        id: {},
        trueValue: {},
        falseValue: {}
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const {fieldIsDisabled, modelName, modelvalue, fieldAttributes, allowMultiple} = useFormField(props, context);

        const isMounted = ref(false);
        const isMultiple = ref(false);
        const objectToArray = inject('objectToArray')
        const $events = inject('$events')
        const mchecked = ref(undefined);
        const mutatedFieldOptions = ref([]);

        const _trueValue = computed(() => {
            return typeof props.trueValue === 'string' ? props.trueValue : !!props.trueValue;
        });

        const _falseValue = computed(() => {
            return typeof props.falseValue === 'string' ? props.falseValue : !!props.falseValue;
        });

        const shouldBeChecked = computed(() => {
            if (mchecked.value === undefined)
                return mchecked.value = typeof props.value === 'string' ? true : !!props.value;
            else
                return mchecked.value;
        });

        const isHorizontal = computed(() => {
            return props.field.hasOwnProperty('horizontal') && _.isBoolean(props.field.horizontal) ? props.field.horizontal : false
        });

        const myfieldAttributes = computed(() => {
            let attrs = _.cloneDeep(fieldAttributes.value);

            return {
                id: checkboxId.value,
                ...attrs
            }
        });

        const checkboxId = computed(() => {
            if (props.id) return props.id;
            let name = props.name;
            let idName = name.replace(/\[\]/g, '_');
            idName = idName.replace(/\[/g, '-');
            idName = idName.replace(/\}/g, '');
            return idName.replace(/\./g, '-');
        });


        const optionsAsTree = computed(() => {
            let treeDat = [];
            if (isMounted.value && props.field.hasOwnProperty('childKey') && props.field.childKey ) {

                if (Array.isArray(mutatedFieldOptions.value)) {
                    mutatedFieldOptions.value.forEach((r) => {
                        if (typeof r === "object" && r.hasOwnProperty(props.field.childKey)) {
                            treeDat.push(r);
                        }
                    });
                }
            }
            return treeDat
        })



        watch(() => modelvalue.value, (n, o) => {
            if (allowMultiple.value && props.field.minimizable && Array.isArray(n)) {
                if (n.length) props.field.extralabel = n.length +' ausgewählt';
                else {
                    props.field.extralabel = null
                }
            }
        }, {deep: true});




        onBeforeMount(() => {
            $events.$on('form-reset', () => {
                isMounted.value = false;
                mchecked.value = undefined;
                nextTick(() => {
                    isMounted.value = true;
                })
            });

            if (allowMultiple.value) {
                isMultiple.value = true;
            }
            if (props.name.match(/\[\]$/g)) {
                isMultiple.value = true;
            }

            if (props.field.options) {
                mutatedFieldOptions.value = prepareMutateFieldOptions(props.field.options);
            }
        });


        onMounted(() => {
            isMounted.value = true;
        });

        function prepareMutateFieldOptions(options) {
            let opts = [];
            options = _.isObject(options) ? objectToArray(options) : options;
            _.each(options, (item, key) => {
                if (!_.isObject(item) && _.isString(item)) {
                    opts.push({
                        value: key,
                        label: item,
                        checked: isChecked({value: key})
                    });
                } else {
                    item.checked = isChecked({value: item.value});
                    opts.push(item);
                }
            });

            return opts;
        }



        function isChecked(field) {
            let modelVal = modelvalue.value;

            if (isMultiple.value)
            {
                if (modelVal === null) {
                    return false;
                }

                if (_.isArray(modelVal)) {

                    let found = false;
                    _.each(modelVal, (v) => {
                        if (!found) {
                            found = field.value == v;
                        }
                    });

                    return found;
                }

                if (_.isObject(modelVal)) {
                    modelVal = Object.keys(modelVal).map((key) => {
                        return modelVal[key]
                    })
                }

                return false;
            } else {
                return modelVal == field.value;
            }
        }


        function fieldId(value) {
            return app.transformKey(modelName.value).replace(/\./g, '-') + '_' + value;
        }

        function updateInput(e) {
            let value = e.target.value;
            let isChecked = e.target.checked;


            if (isMultiple.value)
            {
                let modelVal = _.cloneDeep(modelvalue.value);

                if (!Array.isArray(modelVal) && ! _.isObject(modelVal)) {
                    modelVal = [];
                }

                if (!Array.isArray(modelVal) && _.isObject(modelVal)) {
                    modelVal = Object.keys(modelVal).map((key) => {
                        return modelVal[key]
                    })
                }


                let index = _.findIndex(modelvalue.value, function (v, k) {
                    return v == value;
                });

                if (isChecked) {
                    if (index === -1) {
                        modelVal.push(value);
                    }
                } else {

                    if (index !== -1) {
                        modelVal.splice(index, 1);
                    }
                }

                modelvalue.value = modelVal;

            }
            else
            {
                modelvalue.value = value;
            }

            // context.emit('input', modelvalue.value, value, checked);

        }


        return {
            isMounted,
            mchecked,
            modelvalue,
            isHorizontal,
            optionsAsTree,
            fieldAttributes: myfieldAttributes,
            fieldIsDisabled,
            mutatedFieldOptions,
            fieldId,
            isChecked,
            updateInput
        }
    },
}
</script>

<style scoped>

</style>
