<script>
import {defineComponent, getCurrentInstance, watch, inject, ref, computed, toRaw} from 'vue'
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";

export default defineComponent({
    name: "SidebarNav",
    props: {
        menuItems: {
            type: Array,
            default: []
        }
    },
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const router=useRouter()
        const route = useRoute();
        const store = useStore();



        const defaultPollingDelay = 60000; // 1 minute
        const maxPollFails = ref(10);
        const longpolls = ref({});
        const $events = inject('$events')
        const $notify = inject('$notify')
        const $http = inject('$http')


        const sections = ref(_.cloneDeep(props.menuItems))

        const expandedSections = computed(() => {
            return store.getters['ui/visibleMenuSections'] || []
        });


        const currentBreadcrumbs = computed(() => {
            return store.getters['ui/currentBreadcrumb'] || []
        });

        const changeCounters = computed(() => {
            return store.getters.changesCounters
        });

        watch(() => props.menuItems, (n, o) => {
            sections.value = n;

            updateSidebarSectionItemsActive()

        }, {deep: true});



        watch(() => route.path, (n, o) => {
            if (n) {
                let item = findMenuItemForRoutePath(n);
                if (item && item.label) {
                    store.dispatch('ui/setToolbarDescription', item.label)
                }
            }

            updateSidebarSectionItemsActive()
        });

        function updateSidebarSectionItemsActive() {

            let done = false;
            inst.refs.sidebarnav_inner.querySelectorAll('[data-menu-section]').forEach((el) => {
                el.classList.remove('active-items')

                if (!done) {
                    let label = el.dataset.menuSection;
                    let section = findSectionByLabel(label);
                    if (section && section.sidebar) {
                        let ok = hasActiveSectionItems(section.sidebar);
                        if (ok) {
                            done = true;
                            el.classList.add('active-items')
                        }
                    }
                }
            });
        }

        function hasActiveSectionItems(sectionItems) {
            let found = false;
            _.each(sectionItems, (item) => {
                if (!item.separator && !found) {
                    if (isActiveMenuitem(item)) {
                        found = true;
                    }
                }
            })

            return found;
        }


        function findSectionByLabel(label) {
            let found = null;
            _.each(sections.value, (s) => {
                if (!found && s.label === label) {
                    found = s
                }
            });
            return found;
        }





        function findMenuItemForRoutePath(findThePath) {
            let found = null;
            _.each(sections.value, (s) => {
                if (!found && s.sidebar) {
                    found = _findMenuItemForRoutePath(s.sidebar, findThePath)
                }
            });

            return found;
        }

        function _findMenuItemForRoutePath(sectionItems, findThePath) {

            let found = null;
            _.each(sectionItems, (item) => {
                if (!found) {
                    if (isMenuitemPath(item, findThePath)) {
                        found = item;
                    }
                }
            });

            return found;
        }

        function isMenuitemPath(item, path = null) {
            if (item.hasOwnProperty('url') && item.url) {
                let url = item.url;
                if (!url.match(/^javascript:/g) && !url.match(/^#/g) && !url.match(/^mail/g)) {
                    path = (!path ? route.path : path)

                    let realUrl = cleanLink(url);

                    if (realUrl && path === realUrl) {
                        return true;
                    }
                }
            }
            return false;
        }



        function toggleMinimize() {
            if (store.getters['ui/sidebarMin'] === true) {
                store.dispatch('ui/setSidebarMin', false)
            }
            else {
                store.dispatch('ui/setSidebarMin', true)
            }
        }


        function toggleMenuSection(section) {
            let newVisibleItems = _.clone(toRaw(expandedSections.value));

            if (newVisibleItems.indexOf(section) >= 0) {
                newVisibleItems = newVisibleItems.filter((v) => {
                    return v !== section
                });
            }
            else {
                newVisibleItems.push(section)
            }

            store.dispatch('ui/setVisibleMenuSections', newVisibleItems)
        }

        function cleanLink(url) {
            return $root.transformUrlToRoute(url)
        }

        function handleMenuitem(item) {
            if (item.hasOwnProperty('url') && item.url) {
                let url = item.url;

                if (!url.match(/^javascript:/g) && !url.match(/^#/g) && !url.match(/^mail/g)) {

                    let realUrl = cleanLink(url);
                    let queryParams = [];
                    if (item.params) {
                        _.each(item.params, (v, k) => {
                            if (k) queryParams.push(k + '='+ encodeURI(v));
                        });
                    }

                    if (realUrl) {

                        // close mobile menu
                        $events.$emit('close-mobile-menu')


                        router.push(realUrl + (queryParams.length ? '?'+ queryParams.join('&') : '')).then(() => {
                            store.dispatch('ui/setToolbarDescription', item.label)
                        });
                    }
                }
            }
        }



        function isActiveMenuitem(item, path = null) {

            let isAct = false;

            if (item.hasOwnProperty('url') && item.url) {
                let url = item.url;
                if (!url.match(/^javascript:/g) && !url.match(/^#/g) && !url.match(/^mail/g)) {
                    path = (!path ? route.path : path)

                    let realUrl = cleanLink(url);
                    if (realUrl && path === realUrl) {
                        isAct = true;
                    }


                    // if (!isAct && Array.isArray(currentBreadcrumbs.value)) {
                    //     currentBreadcrumbs.value.forEach((r) => {
                    //         if (!isAct && r.hasOwnProperty('url') && r.url) {
                    //             let rUrlClean = cleanLink(r.url);
                    //             if (rUrlClean && realUrl === rUrlClean) {
                    //                 isAct = true;
                    //             }
                    //         }
                    //     })
                    // }
                }
            }
            return isAct;
        }



        // ---------------- LONG Polling Functions

        function findMenuitem(items, url, pollHash) {
            let done = null;

            _.each(items, (item) => {
                if (!done) {
                    let polling = item.hasOwnProperty('longpoll') && item.longpoll.hasOwnProperty('url') && item.longpoll.url;
                    if (polling && item.longpoll.hash === pollHash) {
                        done = item;
                    }
                    if (!done && Array.isArray(item.sidebar) && item.sidebar.length) {
                        done = findMenuitem(item.sidebar, url, pollHash);
                    }
                    if (!done && Array.isArray(item.menu) && item.menu.length) {
                        done = findMenuitem(item.menu, url, pollHash);
                    }
                }
            });

            return done;
        }

        function isLongPoll(item) {
            if (!$root.use_longpoll) {
                return false;
            }

            let polling = item.hasOwnProperty('longpoll') && item.longpoll.hasOwnProperty('url') && item.longpoll.url;
            if (polling) {
                let pollHash = md5(item.longpoll.url);

                item.longpoll.hash = pollHash;

                if (!longpolls.value.hasOwnProperty(pollHash)) {

                    let delay = item.longpoll.hasOwnProperty('delay') && item.longpoll.delay > 1000 ? item.longpoll.delay : defaultPollingDelay;

                    longpolls.value[pollHash] = {
                        fails: 0,
                        timer: setInterval(() => {
                            checkPolling(item.longpoll.url, pollHash)
                        }, delay)
                    }

                    checkPolling(item.longpoll.url, pollHash)
                }
            }

            return polling;
        }

        function checkPolling(url, pollHash) {

            $http.post(url).then(d => {
                longpolls.value[pollHash].fails = 0;
                let found = findMenuitem(sections.value, url, pollHash);

                if (found && found.longpoll) {
                    if (found.longpoll.hasOwnProperty('notify') && found.longpoll.notify && d.data > 0) {
                        if (Notification.permission !== 'granted')
                            Notification.requestPermission();
                        else {
                            let notification = new Notification('Sie haben neue Nachrichten in Ihrem Prostfach', {
                                icon: '',
                                body: found.longpoll.notify.replace(':counter', d.data),
                            });
                        }
                    }


                    if (found.counter !== d.data)
                    {
                        $root.$set(found, 'counter', d.data);

                        if (found.counter > 0) {

                            if (found.longpoll.hasOwnProperty('event_has_count') &&
                                found.longpoll.event_has_count) {
                                $events.$emit(found.longpoll.event_has_count)
                            }

                            if (found.longpoll.hasOwnProperty('notify') && found.longpoll.notify) {

                                $notify({
                                    ignoreDuplicates: true,
                                    mode: 'longpoll',
                                    group: 'default',
                                    type: 'info',
                                    text: found.longpoll.notify.replace(':counter',found.counter),
                                    duration: 8000,
                                    width: 400
                                });
                            }
                        }
                        else {
                            $notify.clear('longpoll');
                        }
                    }
                    else {
                        $notify.clear('longpoll');
                    }
                }

            }).catch(e => {
                longpolls.value[pollHash].fails++;
                if (longpolls.value[pollHash].fails === maxPollFails.value) {
                    clearInterval(longpolls.value[pollHash].timer)
                }
            });
        }

        // --- END ----------- LONG Polling Functions


        return {
            sidebarIsMin: computed(() => store.getters['ui/sidebarMin']),
            sections,
            expandedSections,
            toggleMenuSection,
            handleMenuitem,
            isActiveMenuitem,
            toggleMinimize,
            hasActiveSectionItems,
            version: $root.version,
            isLongPoll,
            changeCounters
        }

    }
})
</script>

<template>
    <div class="sidebar-nav" :class="{min: sidebarIsMin}">
        <div class="sidebar-nav-header">
            <span class="a">A</span>
            <span class="amino">Amino</span>
            <span class="admin">Version <span class="version">{{ version }}</span></span>
            <div class="copyright">&copy; 2024 - <a href="//neloh.de" target="_blank">nelohSOFTWARE</a></div>
        </div>

        <div class="sidebar-nav-inner" ref="sidebarnav_inner">
            <div>
                <template v-for="s in sections" :key="s.label">
                    <template v-if="s.url && s.url !== '#' && !s.url.match(/javascript/gi)">
                        <div @click.prevent="handleMenuitem(s)" class="plain-item" :class="{ 'is-active': isActiveMenuitem(s) }">
                            <template v-if="s.icon">
                                <svg-icon :name="s.icon"/>
                            </template>
                            <span>{{ s.label }}</span>

                            <template v-if="isLongPoll(s) && s.counter > 0">
                                <span class="badge badge-info">{{ s.counter }}</span>
                            </template>

                            <template v-if="!s.longpoll && s.hasOwnProperty('change_counter') && s.change_counter && changeCounters.hasOwnProperty(s.change_counter) && changeCounters[s.change_counter] > 0">
                                <span class="badge badge-info">{{ changeCounters[s.change_counter] }}</span>
                            </template>

                        </div>
                    </template>
                    <template v-else>
                        <template v-if="Array.isArray(s.sidebar) && s.sidebar.length">
                        <div class="sidebar-nav-section"
                             :data-menu-section="s.label"
                             :class="{
                                'open': expandedSections.indexOf(s.section) >= 0,
                            }">

                            <div class="sidebar-nav-section-header"
                                 @click.prevent="toggleMenuSection(s.section)">

                                <template v-if="s.icon">
                                    <svg-icon :name="s.icon"/>
                                </template>

                                <span>{{ s.label }}</span>

                                <div class="toggle-section">
                                    <svg-icon :name="expandedSections.indexOf(s.section) >= 0 ? 'chevron-up' : 'chevron-down'"/>
                                </div>
                            </div>

                            <div class="sidebar-nav-section-items">
                                <div class="arrow"></div>
                                <div class="sidebar-nav-section-items-inner">
                                    <template v-for="item in s.sidebar">
                                        <template v-if="item.separator">
                                            <div class="separator"></div>
                                        </template>
                                        <template v-else>
                                            <div @click.prevent="handleMenuitem(item)"
                                                 class="item"
                                                 :class="{ 'is-active': isActiveMenuitem(item) }">
                                                <template v-if="item.icon">
                                                    <svg-icon :name="item.icon"/>
                                                </template>
                                                <span>{{ item.label }}</span>
                                            </div>
                                        </template>
                                    </template>
                                </div>


                            </div>
                        </div>
                        </template>
                    </template>
                </template>
            </div>

        </div>

        <div class="sidebar-nav-footer">
            <div class="sidebar-minimizer" @click="toggleMinimize" v-tooltip="(sidebarIsMin ? 'Sidebar maximieren' : 'Sidebar minimieren')">
                <svg-icon :name="(sidebarIsMin ? 'chevrons-right' : 'chevrons-left')"/>
            </div>
        </div>
    </div>
</template>
