<template>
    <div v-if="total_pages > 1 && total > 0" class="ui right floated pagination menu">
        <a href="#" @click.prevent="goToPage(1)"
           :class="['btn-nav', css.linkClass, isOnFirstPage ? css.disabledClass : '']">
            <svg-icon name="chevrons-left"/>
        </a>
        <a href="#" @click.prevent="goToPage('prev')"
           :class="['btn-nav', css.linkClass, isOnFirstPage ? css.disabledClass : '']">
            <svg-icon name="chevron-left"/>
        </a>
        <template v-if="notEnoughPages">
            <template v-for="n in totalPage">
                <a href="#" @click.prevent="goToPage(n)"
                   :class="[css.pageClass, isCurrentPage(n) ? css.activeClass : '']"
                   v-html="n">
                </a>
            </template>
        </template>
        <template v-else>
            <template v-for="n in windowSize">
                <a href="#" @click.prevent="goToPage(windowStart+n-1)"
                   :class="[css.pageClass, isCurrentPage(windowStart+n-1) ? css.activeClass : '']"
                   v-html="windowStart+n-1">
                </a>
            </template>
        </template>
        <a href="#" @click.prevent="goToPage('next')"
           :class="['btn-nav', css.linkClass, isOnLastPage ? css.disabledClass : '']">
            <svg-icon name="chevron-right"/>
        </a>
        <a href="#" @click.prevent="goToPage(totalPage)"
           :class="['btn-nav', css.linkClass, isOnLastPage ? css.disabledClass : '']">
            <svg-icon name="chevrons-right"/>
        </a>
    </div>
</template>

<script>
import {computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref, watch, nextTick} from "vue";
export default {
    name: "simple-pagination",
    emits: ['change-page'],
    props: {
        page: {},
        pageSize: {},
        total: {},
        total_pages: {},
        onEachSide: {
            type: Number,
            default() {
                return 2
            }
        }
    },
    setup(props, context) {

        const currentPage = ref(1);

        watch(() => props.page, (n, o) => {
            if (n >= 1 && n <= props.total_pages) {
                currentPage.value = n;
            }
        });



        const totalPage = computed(() => {
            return props.total_pages;
        })

        const isOnFirstPage = computed(() => {
            return props.page === 1;
        })

        const isOnLastPage = computed(() => {
            return props.page === props.total_pages;
        })

        const notEnoughPages = computed(() => {
            return totalPage.value < (props.onEachSide * 2) + 4;
        })

        const windowSize = computed(() => {
            return props.onEachSide * 2 + 1;
        })

        const windowStart = computed(() => {
            if (props.page <= props.onEachSide)
            {
                return 1;
            }
            else if (props.page >= (totalPage.value - props.onEachSide))
            {
                return totalPage.value - props.onEachSide * 2;
            }

            return props.page - props.onEachSide;
        })


        onBeforeMount(() => {
            if (props.page >= 1) {
                currentPage.value = props.page;
            }
        });


        function goToPage(pageNumber) {
            if (pageNumber === 'next') {
                if (currentPage.value +1 < props.total_pages ) {
                    context.emit('change-page', currentPage.value+1);
                }
            }
            else if (pageNumber === 'prev') {
                if (currentPage.value-1 >= 1 ) {
                    context.emit('change-page', currentPage.value-1);
                }
            }
            else {
                context.emit('change-page', pageNumber);
            }
        }



        function isCurrentPage(num) {
            return currentPage.value == num;
        }



        return {
            currentPage,
            goToPage,
            isCurrentPage,
            windowSize,
            windowStart,
            notEnoughPages,
            isOnLastPage,
            isOnFirstPage,
            totalPage,

            css: {
                wrapperClass: 'ui right floated pagination menu',
                activeClass: 'active large',
                disabledClass: 'disabled',
                pageClass: 'item',
                linkClass: 'icon item',
                paginationClass: 'ui bottom attached segment grid',
                paginationInfoClass: 'left floated left aligned six wide column',
                dropdownClass: 'ui search dropdown',
                icons: {
                    first: 'angle double left icon fa fa-angle-double-left',
                    prev: 'left chevron icon fa fa-angle-left',
                    next: 'right chevron icon fa fa-angle-right',
                    last: 'angle double right icon fa fa-angle-double-right',
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
