<template>
    <li>
        <div
            class="nestable-list-empty"
            @mouseenter.prevent="onMouseEnter"
        >
            <slot/>
        </div>
    </li>
</template>

<script type="text/babel">
import {ref, computed, getCurrentInstance, inject} from 'vue';
import {useGroupObserver} from "./groups-observer.js";
// import { notifyMouseEnter} from "./groups-observer.js";
export default {
    name: 'Placeholder',
    props: {
        index: {
            type: Number,
            required: false,
            default: null
        },
        options: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    inject: ['listId', 'group'],
    setup(props, context) {

        const inst = getCurrentInstance();
        const listId = inject('listId');
        const group = inject('group')


        const {
            registerNestable,
            notifyDragStart,
            notifyMouseEnter,
            notifyMouseMove
        } = useGroupObserver(props, context, inst);

        const isDragging = computed(() => {
            const dragItem = props.options.dragItem
            return dragItem
        });

        function onMouseEnter(event) {
            if (!props.options.dragItem) return

            notifyMouseEnter(group, event, listId, null)
        }


        return {
            onMouseEnter,
            isDragging,
        };

    }
}
</script>
