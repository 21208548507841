<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default defineComponent({
    name: "proofreading-question-info",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },

    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);

        const rawModel = toRaw(_.cloneDeep(modelvalue));

        return {
            rawModel
        }
    }

})
</script>

<template>
    <div class="row">
        <div class="col-md-12">
            <div class="question">
                <div><b>Frage</b></div>
                <div class="question-text"><pre>{{ rawModel.question }}</pre></div>
            </div>
            <div class="answer" v-if="rawModel.answer && rawModel.answer.trim() !== ''">
                <div><b>Aktuelle Antwort</b></div>
                <div class="answer-text"><pre>{{ rawModel.answer }}</pre></div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
