const storedNestables = {};

export function useGroupObserver(props, context, inst) {


    function unregisterNestable(nestable) {
        _forgetByGroup(nestable.group);
    }

    function registerNestable(nestable) {
        const storeGroup = _getByGroup(nestable.group)

        storeGroup.onDragStartListeners.push(nestable.onDragStart)
        storeGroup.onMouseEnterListeners.push(nestable.onMouseEnter)
        storeGroup.onMouseMoveListeners.push(nestable.onMouseMove)
    }

    function notifyDragStart(group, event, item) {
        const storeGroup = _getByGroup(group)

        for (const listener of storeGroup.onDragStartListeners) {
            listener(event, item)
        }
    }

    function notifyMouseEnter(group, event, eventList, item) {
        const storeGroup = _getByGroup(group)

        for (const listener of storeGroup.onMouseEnterListeners) {
            listener(event, eventList, item)
        }
    }

    function notifyMouseMove(group, event) {
        const storeGroup = _getByGroup(group)

        for (const listener of storeGroup.onMouseMoveListeners) {
            listener(event)
        }
    }

    function _forgetByGroup(group) {
        if (!storedNestables[group]) {
            return;
        }

        delete storedNestables[group];
    }

    function _getByGroup(group) {
        // the group already exists, return the reference
        if (storedNestables[group]) {
            return storedNestables[group]
        }

        // otherwise create a new object for the group
        storedNestables[group] = {
            onDragStartListeners: [],
            onMouseEnterListeners: [],
            onMouseMoveListeners: [],
            onDragStart: [],
            dragItem: null
        }

        return storedNestables[group]
    }


    return {
        unregisterNestable,
        registerNestable,
        notifyDragStart,
        notifyMouseEnter,
        notifyMouseMove
    };
}
