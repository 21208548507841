<script>
import {defineComponent, onBeforeMount, onMounted, getCurrentInstance, inject, nextTick, ref, watch, computed, provide} from "vue";
import {useStore} from "~/vuex"
import {useRoute, useRouter} from "~/vue-router";
export default defineComponent({
    name: "Navigation",
    emits: ['opensection'],
    props: {
        rootMenues: {
            required: true,
            type: [Array, Object],
        },
        sidebarMenues: {
            required: true,
            type: [Array, Object],
            default: []
        },
        selectedSection: {}
    },
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;

        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const selected = ref(null);
        const menuitem = ref([]);

        const redirect = ref(undefined);
        const otherQuery = ref(undefined);

        const showSubmenuTimer = ref(null);
        const hideSubmenuTimer = ref(null);



        watch(() => route, (r) => {
            const query = r.query
            if (query) {
                redirect.value = query.redirect
                otherQuery.value = getOtherQuery(query)
            }

            if (r.path) {
                updateSelectedMenuSection(r.path);
                updateActiveMenu(r.path);
            }
        }, {deep: true, immediate: true});


        onBeforeMount(() => {
            let m = props.rootMenues;
            m = _.orderBy(m, ['order']);
            menuitem.value = m;
            updateSelectedMenuSection(route.path);
        });

        onMounted(() => {
            if (route.path) {
                if (props.selectedSection !== selected.value) {
                    context.emit('opensection', selected.value);
                }
                updateActiveMenu(route.path);
            }
        });



        function updateActiveMenu(path) {
            let fullUrl = $root.currentRouteUrl(path);
            if (fullUrl) {
                let found;

                if (selected.value) {
                    found = _.filter(menuitem.value, (menu) => {
                        return menu.section === selected.value
                    });
                }

                if (!Array.isArray(found) || (Array.isArray(found) && !found.length)) {
                    found = _.filter(menuitem.value, (menu) => {
                        return menu.url === fullUrl
                    });
                }


                if (!Array.isArray(found) || (Array.isArray(found) && !found.length)) {
                    if (Array.isArray(props.sidebarMenues) && props.sidebarMenues.length) {
                        _.each(props.sidebarMenues, (r) => {
                            if (r.hasOwnProperty('menu') && Array.isArray(r.menu) && !found) {
                                _.each(r.menu, (m) => {
                                    if (!found && m.hasOwnProperty('url') && m.url) {
                                        if (!m.url.match(/^(javascript|#)/g)) {

                                            let a = findUrlInRootMenu(path, m.url)
                                            if (a) {
                                                found = a;
                                            }
                                        }
                                    }
                                });
                            }
                        })
                    }
                }





                if (found && found.length === 1) {
                    selected.value = found[0].section;
                }
                else if (((!found || (found && found.length === 0)) && path === '/')) {
                    selected.value = 'dashboard';
                }
            }


            if (selected.value) {

                if (props.selectedSection !== selected.value) {
                    context.emit('opensection', selected.value);
                }
            }
        }


        function updateSelectedMenuSection(routePath) {
            let activeSection = null;
            if (routePath === '/') {
                selected.value = 'dashboard';



                return;
            }

            let menues = _.clone(menuitem.value);
            let baseLength = $root.backendUrl.length;
            let pathStr = '';

            _.each(menues, (r) => {
                if (!activeSection) {
                    if (r.url && !r.url.match(/^(javascript|#)/g))
                    {
                        pathStr = ''+ r.url;
                        if (pathStr === routePath) {
                            activeSection = r.section;
                        }
                        else {
                            if (pathStr.length > baseLength) {
                                pathStr = pathStr.substring(baseLength);
                                if (!pathStr.match(/^\//)) {
                                    pathStr = '/'+ pathStr;
                                }
                            }

                            if (pathStr === routePath) {
                                activeSection = r.section;
                            }
                            else if (routePath.substring(0, pathStr.length) === pathStr ) {
                                activeSection = r.section;
                            }
                        }
                    }
                    else if ( r.gotourl && !r.gotourl.match(/^(javascript|#)/g) ){
                        pathStr = ''+ r.gotourl;
                        if (pathStr === routePath) {
                            activeSection = r.section;
                        }
                        else {
                            if (pathStr.length > baseLength) {
                                pathStr = pathStr.substring(baseLength);
                                if (!pathStr.match(/^\//)) {
                                    pathStr = '/' + pathStr;
                                }
                            }

                            if (pathStr === routePath) {
                                activeSection = r.section;
                            } else if (routePath.substring(0, pathStr.length) === pathStr) {
                                activeSection = r.section;
                            }
                        }
                    }


                }
            });



            if (!activeSection && Array.isArray(props.sidebarMenues) && props.sidebarMenues.length) {
                _.each(props.sidebarMenues, (r) => {
                    if (r.hasOwnProperty('menu') && Array.isArray(r.menu) && !activeSection) {
                        _.each(r.menu, (m) => {
                            if (!activeSection && m.hasOwnProperty('url') && m.url) {
                                if (!m.url.match(/^(javascript|#)/g)) {

                                    let a = findUrlInRootMenu(routePath, m.url)
                                    if (a) {
                                        activeSection = a;
                                    }
                                }
                            }
                        });
                    }
                })
            }

            if (activeSection) {
                selected.value = activeSection
            }
            else {
                selected.value = 'dashboard';
            }
        }


        function findUrlInRootMenu(routePath, url) {
            let menues = _.clone(menuitem.value);
            let realUrl = cleanLink(url), activeSection = null;

            _.each(menues, (r) => {
                if (!activeSection) {
                    if (r.url && !r.url.match(/^(javascript|#)/g))
                    {
                        let aa = $root.transformUrlToRoute(r.url)
                        if (aa === realUrl) {
                            activeSection = r.section;
                        }

                    }
                    else if ( r.hasOwnProperty('gotourl') && typeof r.gotourl === "string" && !r.gotourl.match(/^(javascript|#)/g) ){
                        let aa = $root.transformUrlToRoute(r.gotourl)
                        if (aa === realUrl) {
                            activeSection = r.section;
                        }
                    }
                }
            });

            return activeSection;
        }

        function getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== 'redirect') {
                    acc[cur] = query[cur]
                }
                return acc
            }, {})
        }

        function showSubmenuTransition(item, i) {
            if (_.isObject(menuitem.value[i]) && inst.refs.hasOwnProperty('submenu' + i)) {
                if (hideSubmenuTimer.value) {
                    clearTimeout(hideSubmenuTimer.value);
                }

                inst.refs['submenu' + i][0].classList.add('show-menu');
            }
        }

        function hideSubmenuTransition(item, i) {
            if (_.isObject(menuitem.value[i]) && inst.refs.hasOwnProperty('submenu' + i)) {
                inst.refs['submenu' + i][0].classList.remove('show-menu');
            }
        }



        function cleanLink(url) {
            return $root.transformUrlToRoute(url)
        }



        function openSection(item, force, sub) {

            // if (hasDirtyForm.value && !force) {
            //     warningModal.value = item;
            //     return;
            // }
            //
            // warningModal.value = false;


            if (sub) {
                if (sub.section === selected.value) {
                    // this.selected = null;
                } else {
                    selected.value = sub.section;
                }
            } else {
                if (item.section === selected.value) {
                    // this.selected = null;
                } else {
                    selected.value = item.section;
                }
            }


            // $ls.set('menusection', selected.value);

            let url = item.url;
            if (url) {

                if (!url.match(/^javascript:/g) && !url.match(/^#/g) && !url.match(/^mail/g)) {
                    let realUrl = cleanLink(url);

                    if (realUrl !== route.fullPath) {

                        $root.setPageTitle(item.label);


                        let queryParams = '';
                        // if (item.hasOwnProperty('change_counter') && changeCounters.value.hasOwnProperty(item.change_counter)) {
                        //     if (changeCounters.value[item.change_counter]) {
                        //         queryParams = '?show=changes'
                        //     }
                        // }



                        // $ls.set('currentLink', null);
                        context.emit('opensection', null);
                        router.push(realUrl + queryParams);

                        return;
                    }
                }
            } else if (item.gotourl) {
                if (!item.gotourl.match(/^javascript:/g) && !item.gotourl.match(/^#/g) && !item.gotourl.match(/^mail/g)) {
                    let realUrl0 = cleanLink(item.gotourl);

                    if (realUrl0 !== route.fullPath) {
                        let sect = selected.value;
                        // $ls.set('currentLink', null);
                        context.emit('opensection', selected.value);

                        // $ls.set('menusection', selected.value);
                        // $ls.set('currentSidebarLink', null);

                        $root.setPageTitle(item.label);




                        let queryParams = '';
                        // if (item.hasOwnProperty('change_counter') && changeCounters.value.hasOwnProperty(item.change_counter)) {
                        //     if (changeCounters.value[item.change_counter]) {
                        //         if (!_.isObject(otherQuery.value)) {
                        //             otherQuery.value = {}
                        //         }
                        //
                        //         otherQuery.value = Object.assign(otherQuery.value, {show: 'changes'});
                        //     }
                        // }


                        router.replace({path: '/redirect/' + sect + realUrl0, query: otherQuery.value}).then(() => {
                            updateActiveMenu(route.path);

                            inst.parent.ctx.opensection(sect);

                            nextTick(() => {
                                context.emit('opensection', sect);
                            })

                        })
                        return;
                    }
                }
            }
            else {
                // $ls.set('menusection', selected.value);
                // $ls.set('currentSidebarLink', null);
            }

            context.emit('opensection', selected.value);
        }



        return {
            selected,
            menuitem,

            showSubmenuTransition,
            hideSubmenuTransition,

            openSection
        }
    }
})
</script>

<template>
    <ul class="navbar-nav">

        <li v-for="(item, i) in menuitem"
            :class="{active: selected === item.section}"
            :ref="'menuitem'+ i"
            @mouseenter="showSubmenuTransition(item, i)"
            @mouseleave="hideSubmenuTransition(item, i)">

            <div @click.prevent="openSection(item)">
                {{ item.label }}
            </div>

        </li>




<!--        <li class="nav-item">-->
<!--            <div @click.prevent="$router.push({name: 'home'})">Home</div>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--            <div>Personen</div>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--            <div>Organisationen</div>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--            <div>Dateien</div>-->
<!--        </li>-->
        <li class="nav-item">
            <div @click.prevent="$router.push({name: 'demo'})">Demo</div>
        </li>
    </ul>
</template>

<style scoped>

</style>
