<template>
    <div draggable
         class="nestable-handle"
         @mousedown="dragstart"
         @dragstart="dragstart"
         @touchstart="dragstart"
         @touchend="touchend"
         @touchmove="touchmove"
    >
        <slot/>
    </div>
</template>

<script type="text/babel">


import {useGroupObserver} from './groups-observer.js'
import {getCurrentInstance, inject} from "vue";
// import { notifyDragStart, notifyMouseMove } from "./groups-observer.js";
export default {
    name: 'VueNestableHandle',

    props: {
        item: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    inject: ['group', 'onDragEnd'],

    setup(props, context) {
        const inst = getCurrentInstance();
        const {
            registerNestable,
            notifyDragStart,
            notifyMouseEnter,
            notifyMouseMove
        } = useGroupObserver(props, context);


        const group = inject('group');
        const onDragEnd = inject('onDragEnd')

        function dragstart(event) {
            const item = props.item ?? inst.parent.item
            notifyDragStart(group, event, item)
        }

        function touchend(event) {
            if (onDragEnd) onDragEnd(event)
        }

        function touchmove(event) {
            notifyMouseMove(group, event)
        }


        return {
            dragstart,
            touchend,
            touchmove
        }

    },
}
</script>
