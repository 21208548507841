<template>
    <transition
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        v-on:after-enter="afterEnter"
        v-bind:css="false">
        <div class="top-progress" :style="barStyle" v-show="show">
            <div class="peg" :style="pegStyle">
            </div>
        </div>
    </transition>
</template>

<script>
import {computed, ref} from "vue";

function clamp(n, min, max) {
    if (n < min) {
        return min
    }
    if (n > max) {
        return max
    }
    return n
}

let queue = (() => {
    let pending = []

    function next() {
        let fn = pending.shift()
        if (fn) {
            fn(next)
        }
    }

    return fn => {
        pending.push(fn)
        if (pending.length === 1) {
            next()
        }
    }
})()

export default {
    name: 'TopProgress',
    // data () {
    //     return {
    //         error: false,
    //         show: false,
    //         progress: 0,
    //         opacity: 1,
    //         status: null,
    //         isPaused: false
    //     }
    // },
    props: {
        speed: {
            type: Number,
            default: 250
        },
        color: {
            type: String,
            default: '#29d'
        },
        colorShadow: String,
        errorColor: {
            type: String,
            default: '#f44336'
        },
        trickle: {
            type: Boolean,
            default: true
        },
        trickleSpeed: {
            type: Number,
            default: 25
        },
        easing: {
            type: String,
            default: 'linear'
        },
        height: {
            type: Number,
            default: 3
        },
        minimum: {
            type: Number,
            default: 1
        },
        maximum: {
            type: Number,
            default: 97.5
        },
        zIndex: {
            type: Number,
            default: 9999
        },
        position: {
            type: String,
            default: 'fixed'
        }
    },


    setup(props, context) {

        const error = ref(false);
        const show = ref(false);
        const progress = ref(0);
        const opacity = ref(1);
        const status = ref(null);
        const isPaused = ref(false)


        const progressColor = computed(() => {
            return error.value ? props.errorColor : props.color
        })

        const isStarted = computed(() => {
            return typeof status.value === 'number'
        })

        const boxShadow = computed(() => {
            return props.colorShadow ? props.colorShadow : progressColor.value
        })

        const barStyle = computed(() => {
            return {
                position: props.position,
                top: '0',
                left: '0',
                right: '0',
                width: `${progress.value}%`,
                height: `${props.height}px`,
                backgroundColor: progressColor.value,
                transition: `width ${props.speed}ms ${props.easing}, opacity ${props.speed}ms ${props.easing}`,
                opacity: `${opacity.value}`,
                zIndex: `${props.zIndex}`
            }
        });

        const pegStyle = computed(() => {
            return {
                display: 'block',
                position: 'absolute',
                right: '0',
                width: '100px',
                height: '100%',
                opacity: progress.value ? '1' : '0',
                boxShadow: `0 0 10px ${boxShadow.value}, 0 0 5px ${boxShadow.value}`,
                transform: 'rotate(3deg) translate(0px, -4px)'
            }
        })


        function done() {
            set(100)
        }

        function beforeEnter(el) {
            opacity.value = 0
            progress.value = 0

        }

        function enter(el, done1) {
            opacity.value = 1;
            progress.value = 1
            if (done1) done1()
        }

        function afterEnter(el) {
            _runStart()
        }

        function _work() {
            setTimeout(() => {
                if (!isStarted.value || isPaused.value) {
                    return
                }
                increase()
                _work()
            }, props.trickleSpeed)
        }

        function _runStart() {
            status.value = (progress.value === 100 ? null : progress.value)
            if (status.value) {
                _work()
            }
        }

        function start() {
            isPaused.value = false
            if (show.value) {
                _runStart()
            } else {
                show.value = true;
                progress.value = 1;
            }
        }

        function set(amount) {
            isPaused.value = false
            let o
            if (isStarted.value) {
                o = amount < progress.value
                    ? clamp(amount, 0, 100)
                    : clamp(amount, props.minimum, 100)
            } else {
                o = 0
            }
            status.value = (o === 100 ? null : o)
            queue(next => {
                progress.value = o
                if (o === 100) {
                    setTimeout(() => {
                        opacity.value = 0
                        setTimeout(() => {
                            show.value = false
                            error.value = false
                            next()
                        }, props.speed)
                    }, props.speed)
                } else {
                    setTimeout(next, props.speed)
                }
            })
        }

        function increase(amount) {
            let o = progress.value
            if (o < 100 && typeof amount !== 'number') {
                if (o >= 0 && o < 25) {
                    amount = Math.random() * 3 + 3
                } else if (o >= 25 && o < 50) {
                    amount = Math.random() * 3
                } else if (o >= 50 && o < 85) {
                    amount = Math.random() * 2
                } else if (o >= 85 && o < 99) {
                    amount = 0.5
                } else {
                    amount = 0
                }
            }
            set(clamp(o + amount, 0, props.maximum))
        }

        function decrease(amount) {
            if (progress.value === 0) {
                return
            }
            increase(-amount)
        }

        function getProgress() {
            return status.value ? progress.value : 0
        }

        function pause() {
            isPaused.value = true
        }

        function fail() {
            error.value = true
            done()
        }


        return {
            show: show,
            barStyle: barStyle,
            pegStyle: pegStyle,
            isStarted: isStarted,

            beforeEnter: beforeEnter,
            enter: enter,
            afterEnter: afterEnter,

            start: start,
            pause: pause,
            done: done,
            fail: fail,
            decrease: decrease,
            increase: increase,
            set: set,
            getProgress: getProgress,
        }
    },

    //
    //
    //
    // computed: {
    //     progressColor () {
    //         return this.error ? this.errorColor : this.color
    //     },
    //     isStarted () {
    //         return typeof this.status === 'number'
    //     },
    //     boxShadow () {
    //         return this.colorShadow || this.progressColor
    //     },
    //     barStyle () {
    //         return {
    //             position: this.position,
    //             top: '0',
    //             left: '0',
    //             right: '0',
    //             width: `${this.progress}%`,
    //             height: `${this.height}px`,
    //             backgroundColor: this.progressColor,
    //             transition: `all ${this.speed}ms ${this.easing}`,
    //             opacity: `${this.opacity}`,
    //             zIndex: `${this.zIndex}`
    //         }
    //     },
    //     pegStyle () {
    //         return {
    //             display: 'block',
    //             position: 'absolute',
    //             right: '0',
    //             width: '100px',
    //             height: '100%',
    //             opacity: this.progress ? '1' : '0',
    //             boxShadow: `0 0 10px ${this.boxShadow}, 0 0 5px ${this.boxShadow}`,
    //             transform: 'rotate(3deg) translate(0px, -4px)'
    //         }
    //     }
    // },
    // methods: {
    //     beforeEnter (el) {
    //         this.opacity = 0
    //         this.progress = 0
    //         this.width = 0
    //     },
    //     enter (el, done) {
    //         this.opacity = 1
    //         done()
    //     },
    //     afterEnter (el) {
    //         this._runStart()
    //     },
    //     _work () {
    //         setTimeout(() => {
    //             if (!this.isStarted || this.isPaused) {
    //                 return
    //             }
    //             this.increase()
    //             this._work()
    //         }, this.trickleSpeed)
    //     },
    //     _runStart () {
    //         this.status = (this.progress === 100 ? null : this.progress)
    //         if (this.trickle) {
    //             this._work()
    //         }
    //     },
    //     start () {
    //         this.isPaused = false
    //         if (this.show) {
    //             this._runStart()
    //         } else {
    //             this.show = true
    //         }
    //     },
    //     set (amount) {
    //         this.isPaused = false
    //         let o
    //         if (this.isStarted) {
    //             o = amount < this.progress
    //                 ? clamp(amount, 0, 100)
    //                 : clamp(amount, this.minimum, 100)
    //         } else {
    //             o = 0
    //         }
    //         this.status = (o === 100 ? null : o)
    //         queue(next => {
    //             this.progress = o
    //             if (o === 100) {
    //                 setTimeout(() => {
    //                     this.opacity = 0
    //                     setTimeout(() => {
    //                         this.show = false
    //                         this.error = false
    //                         next()
    //                     }, this.speed)
    //                 }, this.speed)
    //             } else {
    //                 setTimeout(next, this.speed)
    //             }
    //         })
    //     },
    //     increase (amount) {
    //         let o = this.progress
    //         if (o < 100 && typeof amount !== 'number') {
    //             if (o >= 0 && o < 25) {
    //                 amount = Math.random() * 3 + 3
    //             } else if (o >= 25 && o < 50) {
    //                 amount = Math.random() * 3
    //             } else if (o >= 50 && o < 85) {
    //                 amount = Math.random() * 2
    //             } else if (o >= 85 && o < 99) {
    //                 amount = 0.5
    //             } else {
    //                 amount = 0
    //             }
    //         }
    //         this.set(clamp(o + amount, 0, this.maximum))
    //     },
    //     decrease (amount) {
    //         if (this.progress === 0) {
    //             return
    //         }
    //         this.increase(-amount)
    //     },
    //     done () {
    //         this.set(100)
    //     },
    //     getProgress () {
    //         return this.status ? this.progress : 0
    //     },
    //     pause () {
    //         this.isPaused = true
    //     },
    //     fail () {
    //         this.error = true
    //         this.done()
    //     }
    // }
}
</script>
