export default [
    {
        name: 'error403',
        path: '/error-403',
        component: require('./../views/Error403.vue').default,
        meta: {
            nocache: true
        },
    },
    {
        name: 'error404',
        path: '/error-404',
        component: require('./../views/Error404.vue').default,
        meta: {
            nocache: true
        },
    },

    {
        name: 'error',
        path: '/error',
        component: require('./../views/ErrorPage.vue').default,
        meta: {
            nocache: true
        },
    },


	// {
	// 	name: 'home',
	// 	path: '/',
	// 	component: require('./../views/Home.vue').default,
	// 	meta: {
	// 		nocache: true
	// 	},
	// },

	{
		name: 'auth',
		path: '/auth',
		component: require('./../views/AuthPage.vue').default,
		meta: {
			nocache: true
		},
	},


    {
        name: 'reset-password',
        path: '/reset-password',
        component: require('./../views/PasswordReset.vue').default,
        meta: {
            nocache: true
        },
    },

    {
        name: 'reset-password.verify',
        path: '/reset-password/verify/:hash',
        component: require('./../views/VerifyReset.vue').default,
        meta: {
            nocache: true
        },
    },

    {
        name: 'verify-reset',
        path: '/verify-reset',
        component: require('./../views/VerifyReset.vue').default,
        meta: {
            nocache: true
        },
    },
    {
        name: 'verify-email',
        path: '/verify-email',
        component: require('./../views/VerifyEmail.vue').default,
        meta: {
            nocache: true
        },
    },
	{
		name: 'redirect',
		path: '/redirect/:path*',
		component: require('./../views/simple-redirect.vue').default,
		hidden: true,
		meta: {
			nocache: true
		},
	},



    {
        name: 'demo',
        path: '/demo',
        component: require('./../views/Demo.vue').default,
        meta: {
            nocache: true
        },
    },

];
