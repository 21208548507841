<template>
    <layout v-if="isMounted"></layout>
</template>
<script>
import {getCurrentInstance, onMounted, onBeforeMount, ref, provide, computed, watch, inject} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import Cookies from '~/js-cookie';
import {useStore} from "~/vuex";


export default {
    name: 'App',
    components: {
        'layout': require('./layout.vue').default
    },
    inject: ['$store',  '$set', 'moment', '$ls'],
    setup(props, context) {

        const inst = getCurrentInstance();
        const $root = inst.root.ctx;

        const store = useStore();
        const $events = inject('$events');
        const $notify = inject('$notify')
        const $ls = inject('$ls')




        const backendPrefix = ref('');
        const backendUrl = ref(null)
        const isMounted = ref(false);
        const system_date = ref(null);
        const token = ref(null);
        const website_url = ref(null);
        const version = ref('');
        const appname = ref('');
        const baseurl = ref('');
        const routes = ref([]);
        const menuitems = ref([]);
        const requestSubjects = ref([]);
        const disableDirty = ref(false);
        const route = useRoute();
        const router = useRouter();
        const use_longpoll = ref(true);


        provide('use_longpoll', use_longpoll.value)
        provide('menuitems', menuitems)

        onBeforeMount(() => {


            if (window.options) {
                website_url.value = window.options.url;
                system_date.value = window.options.system_date;
                appname.value = window.options.name;
                version.value = window.options.version;
                token.value = window.options.token;

                requestSubjects.value = window.options.requestSubjects;

                backendPrefix.value = window.options.backendPrefix;
                baseurl.value = window.options.baseurl;
                backendUrl.value = window.options.backendUrl;

                routes.value = window.options.routes;


                if (  window.options.formErrors ) {
                    store.dispatch('form/setFormErrors', window.options.formErrors)
                }

                provide('unisearchForm', window.options.unisearchForm)

                if (window.options.formErrors) {
                    store.dispatch('form/setFormErrors', window.options.formErrors)
                }

                if (typeof window.options.user === "object") {
                    store.dispatch('user/setUser', window.options.user)
                }

                if (typeof window.options.menues === "object") {
                    menuitems.value = window.options.menues;
                }

            }

            store.dispatch('ui/initUi');



            if (($ls && $ls.get('ls')) || store.getters['user/isLocked']) {
                store.dispatch('ui/setLockscreen', true);
            }
            else {
                store.dispatch('ui/setLockscreen', false);
            }


        });

        onMounted(() => {



            let bootAnimation = document.getElementById('boot-animation')
            if (bootAnimation) {
                bootAnimation.remove()
            }

            Neloh.provide('appRoutes', routes.value);

            provide('requestSubjects', requestSubjects.value)
            provide('system_date', system_date.value);
            provide('version', version.value);
            provide('appname', appname.value);
            provide('token', token.value);
            provide('baseurl', baseurl.value)
            provide('backendUrl', backendUrl.value)
            provide('backendPrefix', backendPrefix.value)

            provide('disableDirty', disableDirty.value)

            Neloh.provide('baseurl', baseurl.value);
            Neloh.provide('backendUrl', backendUrl.value);
            Neloh.provide('backendPrefix', backendPrefix.value);


            if (window.options.verify) {
                router.push({name: 'verify-reset'});
            }
            else if (window.options.verifyEmail) {
                router.push({name: 'verify-email'});
            }

            isMounted.value = true;

        });

        const pagetitle = ref(null);

        watch(() => pagetitle.value, (n, o) => {
            if (o !== n) {
                document.title = n;
                document.querySelector('title').innerHTML = n;
            }
        });

        watch(() => route.path, (n, o) => {
            store.dispatch('ui/setBreadcrumbs', [])
        }, {deep: true});


        function setPageTitle(value) {
            if (value) {
				if (typeof value === "string") {
					store.dispatch('ui/setToolbarDescription', value);
					pagetitle.value = value;
				}
            }
        }

        function transformKey(value) {
            if (value && typeof value === "string") {
                if (value && value.indexOf('[') >= 0) {
                    value = value.replace(/\[\]/g, '');
                    value = value.replace(/\[/g, '.').replace(/]/g, '')
                }
            }

            return value;
        }

        function cleanModelKey(name) {
            return name.replace(/\//g, '_').replace(/\./g, '-').replace(/^_/g, '');
        }

        function createVModel(key) {
            return (key ? transformKey(key) : key);
        }


        Neloh.provide('transformKey', transformKey)
        Neloh.provide('cleanModelKey', cleanModelKey)
        Neloh.provide('createVModel', createVModel)


        function notification(responseData) {
            if (_.isObject(responseData.notification) && responseData.notification.hasOwnProperty('msg')) {

                store.dispatch('addNotification', responseData.notification);


                if (responseData.notification.hasOwnProperty('type')) {
                    notify(responseData.notification.msg, responseData.notification.type);
                } else {
                    notify(responseData.notification.msg);
                }
            } else if (_.isObject(responseData.errors)) {

                let errors = [];
                _.each(responseData.errors, (value, key) => {
                    if (_.isArray(value)) {
                        errors.push(value[0]);
                    }
                });

                if (errors.length) {
                    notify(errors.join('<br>'), 'error');
                }
            } else if (typeof responseData.error === 'string') {
                notify(responseData.error, 'error');
            }

        }

        /**
         * Show notification message
         *
         * @param message
         * @param type
         * @param duration
         */
        function notify(message, type, duration) {

            duration = (!duration ? 5000 : duration);

            switch (type) {
                case 'error':
                case 'danger':
                    $events.$emit('update-notifications', message, 'error');

                    $notify({
                        group: 'default',
                        type: 'error',
                        text: message,
                        duration: duration,
                        width: 400
                    });
                    break;

                case 'warning':

                    $events.$emit('update-notifications', message, 'warning');

                    $notify({
                        group: 'default',
                        type: 'warn',
                        text: message,
                        duration: duration,
                        width: 400
                    });
                    break;

                case 'success':

                    $events.$emit('update-notifications', message, 'success');


                    $notify({
                        group: 'default',
                        type: 'success',
                        text: message,
                        duration: duration,
                        width: 400
                    });
                    break;

                case 'info':
                default:
                    $events.$emit('update-notifications', message, 'info');

                    $notify({
                        group: 'default',
                        type: 'info',
                        text: message,
                        duration: duration,
                        width: 400
                    });
                    break;
            }
        }

        Neloh.provide('notification', notification)



        /**
         * Html entity decode
         *
         * @param html
         * @returns {string}
         */
        function entityDecode(html) {
            let decoder =  document.createElement('div');
            decoder.innerHTML = html;
            return decoder.textContent;
        }

        Neloh.provide('entityDecode', entityDecode)


        function updateMenues(m) {
            menuitems.value = m;
        }
        Neloh.provide('updateMenues', updateMenues)


        function changeBreadcrumb(data) {
            if (data) {
                if (Array.isArray(data.breadcrumbs)) {
                    try {
                        store.dispatch('ui/setBreadcrumbs', data.breadcrumbs)
                    } catch (e) {

                    }
                }
                else {
                    try {
                        store.dispatch('ui/setBreadcrumbs', [])
                    } catch (e) {

                    }
                }
            }
        }

        Neloh.provide('changeBreadcrumb', changeBreadcrumb)


        const wait = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        Neloh.setPrototype('wait', wait);
        // Vue.provide('wait', wait);

        return {
            isMounted,
            menuitems,
            system_date,
            version,
            appname,
            token,
            backendUrl,
            routes,
            requestSubjects,
            disableDirty,
            use_longpoll,
            wait,
            updateMenues,
            setPageTitle,
            cleanModelKey,
            transformKey,
            createVModel,
            entityDecode,
            notification,
            notify,
        }
    }
};
</script>
