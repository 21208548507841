<script>
import {defineComponent, getCurrentInstance, inject, computed} from 'vue'

export default defineComponent({
	name: "IndexUserDetails",
	props: {
		rowData: {},
		rowIndex: {}
	},
	setup(props, context) {
		const inst = getCurrentInstance();
		const $root = inst.root.ctx;
		const $events = inject('$events');


		const hasDetails = computed(() => {
			return !_.isEmpty(props.rowData.rowdetails)
		})



		function webLink(link) {
			return link ? link.replace(/^www\.?/g, '//www.') : null
		}

		function mailLink(mail) {
			return 'mailto:' + mail;
		}

		function phoneLink(phone) {
			let numbers = phone.replace(/[^\d]/g, '');
			return 'tel:' + numbers;
		}

		function formatPhoneNumber(value) {
			let numbers = value.match(/\//g) ? value.replace(/[^\d\/]/g, '') : value.replace(/[^\d]/g, '');
			numbers = numbers.trim();

			let str = '', nums = 0;

			if (numbers.match(/^0([1-9])/g)) {
				str = '';
				for (let i = 0; i < numbers.length; i++) {
					if (numbers[i] === '/') {
						str += ' / ';
						nums = 0;
					} else {
						nums++
						str += numbers[i];

						if (nums === 2) {
							str += ' ';
							nums = 0;
						}
					}
				}
			} else if (numbers.match(/^49([1-9])/g)) {
				str = '+49 ';
				for (let i = 2; i < numbers.length; i++) {

					str += numbers[i] + (i % 2 ? ' ' : '');
				}
			}
			else if (numbers.match(/^([1-9])/g)) {
				for (let i = 2; i < numbers.length; i++) {
					str += numbers[i] + (i % 2 ? ' ' : '');
				}
			}
			else {
				str = numbers;
			}

			return str;
		}


		return {
			hasDetails,


			webLink,
			mailLink,
			phoneLink,
			formatPhoneNumber
		}
	}
})
</script>

<template>
	<div class="user-detail-row" :class="{load: rowData.loadingdetails}">
		<template v-if="hasDetails">

			<div class="user-details">
				<b>{{ rowData.first_name }} {{ rowData.surname }}</b>
                <div v-if="!rowData.street && !rowData.city">
                    <em>keine Adressdaten vorhanden</em>
                </div>
                <template v-else>
                    <div v-if="rowData.street">
                        {{ rowData.street }} {{ rowData.street_number }}
                    </div>
                    <div v-if="rowData.city">
                        {{ rowData.zip }} {{ rowData.city }}
                    </div>
                </template>

			</div>


			<div class="user-communiation">
				<b>Kommunikation</b>

				<template v-if="(rowData.phone_prefix && rowData.phone) ||
                (rowData.phone_prefix && rowData.fax) || rowData.email || rowData.www">
					<div class="communiation">
						<div v-if="rowData.phone">
							<b>Tel:</b> <span>{{ formatPhoneNumber(rowData.phone) }}</span>
						</div>
						<div v-if="rowData.fax && formatPhoneNumber(rowData.fax)">
							<b>Fax:</b> <span>{{ formatPhoneNumber(rowData.fax) }}</span>
						</div>
						<div v-if="rowData.email">
							<b>E-Mail:</b> <a :href="mailLink(rowData.email)" target="_blank">{{ rowData.email }}</a>
						</div>
					</div>
				</template>
				<template v-else>
					<div><em>keine Angaben vorhanden</em></div>
				</template>
			</div>
		</template>
	</div>
</template>

<style scoped>

</style>
