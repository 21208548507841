<template>
    <div class="form-fields-div" :class="formatFieldClass" v-attr="fieldAttributes">
        <template v-if="hasFields">
            <template v-for="(f, n) in field.fields" :key="n">
                <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
                <template v-else>
                    <component v-if="f.type !== 'column' && f.type !== 'columns' && f.type !== 'section'"
                               :is="'form-'+ f.type"
                               :responseerrors="responseerrors"
                               :rendersections="rendersections"
                               :form-model="formModel"
                               :validate="validate"
                               :name="n"
                               :field="f"></component>
                    <component :is="'form-'+ f.type"
                               :responseerrors="responseerrors"
                               :validate="validate"
                               :form-model="formModel"
                               :name="n"
                               :field="f"
                               :rendersections="rendersections"
                               v-else-if="f.type === 'column' || f.type === 'columns' || f.type === 'section'"></component>
                </template>
            </template>
        </template>


        <template v-else>
            <div class="section-component" ref="component" v-if="getRenderSection">
                <component :is="'section-'+ getRenderSection"
                           :field="field"
                           :name="'section_'+getRenderSection"
                           :validate="validate"
                           :responseerrors="responseerrors"
                           :rendersections="rendersections"
                           :form-model="formModel"></component>
            </div>
            <template v-if="hasFields">
                <template v-for="(f, n) in field.fields" :key="n">
                    <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
                    <component v-else :form-model="formModel"
                               :responseerrors="responseerrors"
                               :rendersections="rendersections"
                               :is="'form-'+ f.type"
                               :validate="validate"
                               :name="n"
                               :field="f"
                    ></component>
                </template>
            </template>
            <template v-else>

                <div v-if="field.hasOwnProperty('label') && field.label" class="form-label text-bold">{{ field.label }}</div>

                <div v-if="field.description" v-html="field.description"></div>
                <div v-else-if="field.content" v-html="field.content"></div>
                <template v-else>
                    <slot/>
                </template>
            </template>
        </template>


    </div>
</template>

<script>


import {computed,  onMounted, ref} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";
export default {
    name: "FormDiv",
    props: {
        ...defaultFieldProps,
        mode: {}
    },

    setup(props, context) {
        const {
            hasShowWhen,
            getShowWhen,
            hasFields, hasColumns, hasComponents, getRenderSection, isColumns, formatCols, fieldAttributes} = useFormField(props, context);


        const isMounted = ref(false);

        onMounted(() => {
            isMounted.value = true;
        });


        const formatFieldClass = computed(() => {



            let cls = '';

            if (props.field.hasOwnProperty('classes') ) {
                cls += props.field.classes + ' ';
            }
            if (props.field.hasOwnProperty('class') ) {
                cls += props.field.class + ' ';
            }

            if (isColumns.value) {
                cls += formatCols.value + ' ';
            }

            if (hasShowWhen.value) {
                if (!getShowWhen.value) {
                    cls = 'hidden';
                }
            }
            return cls;



            // if (props.field.hasOwnProperty('classes') ) {
            //     return props.field.classes;
            // }
            // else if (props.field.hasOwnProperty('class') ) {
            //     return props.field.class;
            // }
            //
            // return null;
        })

        function hasMode(field) {
            if (props.mode === 'main') {
                return field.mode === 'main' || _.isUndefined(field.mode)
            } else {
                return field.mode === this.mode
            }
        }

        function fieldComponentName(field) {
            if (field.type === 'tab') {
                return null;
            }
            return 'form-' + field.type;
        }

        return {
            hasFields,
            hasColumns,
            hasComponents,
            getRenderSection,
            formatFieldClass,
            isColumns,
            formatCols,
            fieldAttributes,

            hasMode,
            fieldComponentName
        }
    },

    //
    //
    // computed: {
    //     hasFields() {
    //         return this.field.hasOwnProperty('fields') && !_.isEmpty(this.field.fields);
    //     },
    //     hasColumns() {
    //         if (this.hasFields) {
    //             return _.some(this.field.fields, {type: 'columns'});
    //         }
    //
    //         return false;
    //     },
    //     hasComponents() {
    //         if (this.field && this.field.hasOwnProperty('rendersection') && this.field.rendersection) {
    //             return true
    //         }
    //         return this.field.hasOwnProperty('rendersection') && this.field.rendersection;
    //     },
    //     getRenderSection() {
    //         if (this.field && this.field.hasOwnProperty('rendersection') && this.field.rendersection) {
    //             return this.field.rendersection
    //         }
    //         return null;
    //     },
    //     formatFieldClass() {
    //         let cls = '';
    //
    //         if (this.field.classes) {
    //             cls += this.field.classes + ' ';
    //         }
    //
    //         if (this.isColumns) {
    //             cls += this.formatCols + ' ';
    //         }
    //
    //         if (this.hasShowWhen) {
    //             if (!this.getShowWhen) {
    //                 cls = 'hidden';
    //             }
    //         }
    //         return cls;
    //     },
    //
    //     isColumns() {
    //         return this.field.hasOwnProperty('cols') && this.field.cols >= 1;
    //     },
    //
    //     formatCols() {
    //
    //         if (!this.field.hasOwnProperty('cols')) {
    //             return 'col-md-12'
    //         }
    //
    //
    //         return this.field.cols > 12 || this.field.cols < 1 ? 'col-md-12' : 'col-md-' + this.field.cols;
    //     },
    // },
    // beforeMount() {
    //     if (!_.isEmpty(this.rendersections)) {
    //         for (let k in this.rendersections) {
    //             Neloh.component('section-' + k, this.rendersections[k]);
    //         }
    //     }
    // },
    // mounted() {
    //
    //     this.isMounted = true;
    // },
    //
    // methods: {
    //     hasMode(field) {
    //         if (this.mode === 'main') {
    //             return field.mode === 'main' || _.isUndefined(field.mode)
    //         } else {
    //             return field.mode === this.mode
    //         }
    //     },
    //     fieldComponentName(field) {
    //         if (field.type === 'tab') {
    //             // console.log(field);
    //             return null;
    //         }
    //         return 'form-' + field.type;
    //     },
    // }
}
</script>

<style scoped>

</style>
