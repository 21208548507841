<script>
import {
    defineComponent,
    onMounted,
    getCurrentInstance,
    inject,
    ref,
    watch,
    computed,
    provide,
    onBeforeMount
} from "vue";
import {useStore} from "~/vuex"
import {useRoute, useRouter} from "~/vue-router";
export default defineComponent({
	name: "layout",
    components: {
        'locked-screen': require("./locked-screen").default,
    },
	setup(props, context) {
		const inst = getCurrentInstance();
		const $root = inst.root.ctx;
		const pageLoading = ref(false);
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const $events = inject('$events');
		const $http = inject('$http');
        const $ls = inject('$ls')
        const transformUrlToRoute = inject('transformUrlToRoute')

        const selectedSection = ref(null);
        const sidebarOpen = ref(true);
        const sidebarToggleOver = ref(false);

		const fromRoute = ref(null);
		const toRoute = ref(null);
		const bodyClass = ref(null)

        const sidebarMobileVisible = ref(false);


        const form = computed(() => {
            if (store.getters['form/formDefinition']) {
                return store.getters['form/formDefinition'];
            }
            return {};
        });


        const currentFormModel = computed(() => {
            if (store.getters['form/formModel']) {
                return store.getters['form/formModel'];
            }
            return null;
        });


        const formPrimaryKey = computed(() => {
            return form.value ? form.value.primaryKey : 'id'
        })



		const routeIsCachable = computed(() => {
			return toRoute.value && toRoute.value.meta.nocache === true;
		});

		const mustAuth = computed(() => {
			return store.getters['user/user'].id <= 0;
		})

        const isLockScreen = computed(() => {
            return store.getters['ui/lockscreen'];
        })

        const rootMenu = computed(() => {
            let rootItems = [];
            _.each($root.menuitems, (item) => {
                rootItems.push(item);
            });
            return rootItems
        });

        const hasMenues = computed(() => {
            if (selectedSection.value) {
                let roots = _.find(rootMenu.value, {section: selectedSection.value});
                return roots && roots.hasOwnProperty('primaryMenu') && roots.primaryMenu.length > 0;
            }
            return false;
        });

        const hasSidebarMenues = computed(() => {
            if (selectedSection.value) {
                let roots = _.find(rootMenu.value, {section: selectedSection.value});
                return roots && roots.hasOwnProperty('sidebar') && roots.sidebar.length > 0;
            }
            return false;
        });

        const sidebarMenu = computed(() => {
            if (selectedSection.value) {
                let roots = _.find(rootMenu.value, {section: selectedSection.value});
                return roots && roots.hasOwnProperty('sidebar') ? roots.sidebar : [];
            }
            return [];
        });

        const username = computed(() => {
            return store.getters['user/user'].username;
        });

        const realname = computed(() => {
            const s = store.getters['user/user'];
            return s.first_name + ' '+  s.surname;
        })

        const toolbarDescription = computed(() => {
            return store.getters['ui/toolbarDescription'];
        });

        const sidebarMin = computed(() => {
            return store.getters['ui/sidebarMin'];
        });

		watch(() => route, (n, o) => {
			if (n !== o) {
				fromRoute.value = o;
			}
			toRoute.value = n;
		});


        watch(() => sidebarMin.value, (n) => {
            if (n) {
                document.body.classList.add('sidebar-minimized');
            }
            else {
                document.body.classList.remove('sidebar-minimized');
            }
        });


        watch(() => sidebarMobileVisible.value, (n) => {
            if (n) {
                document.addEventListener('click', handleMobileMenuOutsideClick)
            }
        });

		watch(() => route.path, (n, o) => {
			if (n && n !== o) {
				prepareBodyClass(n)
			}
		}, {immediate: true});


        onBeforeMount(() => {
            if (sidebarMin.value) {
                document.body.classList.add('sidebar-minimized');
            }
        })

		onMounted(() => {
			prepareBodyClass(route.path)

            $events.$on('close-mobile-menu', () => {
                sidebarMobileVisible.value = false
            })

			$events.$on('pageloading', (value) => {
				pageLoading.value = value
			});

            if (!mustAuth.value) {
                loadChangesCounters()
            }

			router.beforeEach((to, from, next) => {
				pageLoading.value = true;
				next();
			});

			router.afterEach((to, from) => {
				// Call other functions after route is changed
				handleRouteViewing();
                pageLoading.value = false;
			});
		});

		function prepareBodyClass(path) {
			if (!path || path === '/') {
				bodyClass.value = 'page-dashboard';
			}
			else {
				let segments = path.split('/');
				let strArr = [];
				segments.forEach((s) => {
					if (s && strArr.length < 3) {
						strArr.push(s)
					}
				})

				if (strArr.length) {
					bodyClass.value = 'page-'+ strArr.join('-');
				}
			}
		}

        function handleMobileMenuOutsideClick(e) {
            if (e.target) {
                if (!e.target.closest('.sidebar-nav')) {
                    sidebarMobileVisible.value = false;
                    document.removeEventListener('click', handleMobileMenuOutsideClick)
                }
            }
        }


		function handleRouteViewing() {
			// after route is changed .....
		}

		function enablePageloading() {
			pageLoading.value = true;
		}

		function disablePageloading() {
			pageLoading.value = false;
		}

		provide('enablePageloading', enablePageloading);
		provide('disablePageloading', disablePageloading);

		function toggleNav() {
			if (inst.refs.nav.classList.contains('open')) {
				inst.refs.nav.classList.remove('open')
			}
			else {
				inst.refs.nav.classList.add('open')
			}
		}

        const isDarkmode = computed(() => {
            return store.getters['ui/dark'];
        });

        const isFullscreen = computed(() => {
            return store.getters['ui/fullscreen'];
        });

        const breadcrumbs = computed(() => {
            return store.getters['ui/currentBreadcrumb'];
        })

        const hasBreadcrumbs = computed(() => {
            return Array.isArray(breadcrumbs.value) && breadcrumbs.value.length > 0;
        })


        const loadingDataTable = computed(() => {
            return store.getters.dataTableLoading;
        });


        const isVerifyScreen = computed(() => {
            return window.options.verify || window.options.verifyEmail
        });


        function toggleDarkmode() {
            store.dispatch('ui/setDarkmode', !store.getters['ui/dark'])
        }

        function toggleFullscreen() {
            store.dispatch('ui/setFullscreen', !store.getters['ui/fullscreen'])
        }

        function toggleSidebar(e) {
            e.stopImmediatePropagation();
            sidebarMobileVisible.value = !sidebarMobileVisible.value;
        }


        function opensection(section) {
            if (selectedSection.value === section) {
                selectedSection.value = null;
            } else {
                selectedSection.value = section;
            }
        }

        function handleBreadcrumb(item) {
            if (item.url && !item.url.match(/^(#|java|mailto)/gi) ) {
                router.push({path: transformUrlToRoute(item.url) });
            }
        }

        function logout() {
            window.location.href = $root.routes('admin.auth.logout');
        }

        function lockAccount() {
            $http.post($root.routes('admin.lock')).then(r => {
                if (r.data.success) {
                    $ls.set('ls', true);
                    store.dispatch('user/lock')
                    store.dispatch('ui/setLockscreen',true);
                }
            });
        }

        function goToMessage() {
            router.push({ name: 'admin.messager' });
        }

        function loadChangesCounters() {
            $http.post( $root.routes('admin.system.changes-counter') ).then((r) => {
                if (r.data.success) store.dispatch('updateChanges', r.data.counters)
            });
        }

		return {
            formPrimaryKey,
            currentFormModel,

			routeIsCachable,
			pageLoading,
			bodyClass,
			mustAuth,
			toggleNav,
            username,
            realname,
            toolbarDescription,
            selectedSection,
            rootMenu,
            hasMenues,
            hasSidebarMenues,
            sidebarMenu,
            hasBreadcrumbs,
            breadcrumbs,
            opensection,
            loadingDataTable,
            sidebarMobileVisible,
            isDarkmode,
            isFullscreen,
            isLockScreen,
            isVerifyScreen,
            handleBreadcrumb,
            toggleDarkmode,
            toggleFullscreen,
            toggleSidebar,

            lockAccount,
            logout,


            goToMessage
		}
	}
})
</script>

<template>
	<main :class="[bodyClass, {
		'auth-page': mustAuth,
		 'sidebar-visible': sidebarMobileVisible,
		 'screen-locked': isLockScreen
	}]">
        <notifications group="default"/>

        <template v-if="isLockScreen">
            <locked-screen/>
        </template>
        <template v-else-if="mustAuth || isVerifyScreen">
            <router-view v-slot="{ Component, route }">
                <component :is="Component" :key="route.fullPath+'_'+route.name"/>
            </router-view>
        </template>
        <template v-else>
            <sidebar-navigation :menu-items="rootMenu"/>
            <div class="content-container">
                <div class="header">
                    <ul>
                        <li @click.prevent="toggleSidebar" :class="['mobile-menu', {active: sidebarMobileVisible}]">
                            <svg-icon :name="sidebarMobileVisible ? 'x' : 'menu-2'"/>
                        </li>

                        <li v-if="hasBreadcrumbs" class="breadcrumbs">
                            <template v-for="r in breadcrumbs">
                                <a v-if="r.url" href="javascript: void(0)" @click.stop="handleBreadcrumb(r)">{{ r.title }}</a>
                                <span v-else-if="!r.url && r.title">{{ r.title }}</span>
                            </template>


                            <template v-if="currentFormModel && formPrimaryKey && currentFormModel.hasOwnProperty(formPrimaryKey) && currentFormModel[formPrimaryKey] > 0">
                                <span class="edit-entry-id">ID: {{ currentFormModel[formPrimaryKey]}}</span>
                            </template>


                        </li>
                        <li v-if="toolbarDescription && !hasBreadcrumbs" class="toolbar-desc">
                            {{ toolbarDescription }}
                        </li>
                    </ul>


                    <ul>

                        <li @click.prevent="goToMessage" v-tooltip="'Nachrichten'">
                            <svg-icon name="bubble-text"/>
<!--                            <template v-if="isLongPoll(s) && s.counter > 0">-->
<!--                                <span class="badge badge-info">{{ s.counter }}</span>-->
<!--                            </template>-->
                        </li>


                        <li @click.prevent="toggleFullscreen" v-tooltip="'Vollbild an & ausschalten'">
                            <svg-icon :name="(isFullscreen ? 'maximize-off' : 'maximize')"/>
                        </li>

                        <li @click.prevent="toggleDarkmode" v-tooltip="'Zwischen dunkelen & hellen Modus umschalten'">
                            <svg-icon :name="(isDarkmode ? 'sun' : 'moon')"/>
                        </li>

                        <li @click.prevent="lockAccount" v-tooltip="'Bildschirm Sperren'">
                            <svg-icon name="lock"/>
                        </li>

                        <li class="user">
                            <dropdown-button :tooltip="'Ihr Account - ID: '+ $store.getters['user/user'].id">
                                <template v-slot:trigger>
                                    <svg-icon name="user"/>
                                    <div>
                                        <div class="realname">{{ realname }}</div>
                                        <div class="username">{{ username }}</div>
                                    </div>
                                </template>

                                <template v-slot:content>
                                    <ul>
                                        <li dropdown-closer @click.prevent="$router.push({name: 'admin.account.personal-data'})">Persönliche Daten</li>
                                        <li dropdown-closer @click.prevent="$router.push({name: 'admin.account.settings.edit'})">Account Einstellungen</li>
	                                    <li dropdown-closer @click.prevent="$router.push({name: 'admin.account.username'})">Benutzername ändern</li>
                                        <li dropdown-closer @click.prevent="$router.push({name: 'admin.account.password'})">Passwort ändern</li>
                                        <li dropdown-closer @click.prevent="$router.push({name: 'admin.account.email'})">E-Mail Adresse ändern</li>
<!--                                        <li dropdown-closer @click.prevent="$router.push({name: 'admin.account.api-token'})">Mein Amino API-Token</li>-->
                                        <li class="separator"></li>
                                        <li dropdown-closer @click.prevent="logout">Abmelden</li>
                                    </ul>
                                </template>
                            </dropdown-button>
                        </li>
                    </ul>
                </div>

                <div class="content" :class="{'data-table-loading': loadingDataTable}">

                    <loading :active="pageLoading"
                             :width="80"
                             :height="80"
                             :opacity="0.6"
                             :stroke-width="2"
                             :is-full-page="false"
                             class="without-text"
                    ></loading>

                    <router-view v-slot="{ Component, route }">
                        <component :is="Component" :key="route.fullPath+'_'+route.name"/>
                    </router-view>
                </div>
            </div>
        </template>
	</main>
</template>

<style scoped>

</style>
