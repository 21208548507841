<script>

import livesavingprogress from "./../livesaving-progress"
import {
    defineComponent,
    getCurrentInstance,
    ref,
    computed,
    watch,
    nextTick,
    onBeforeMount,
    onMounted,
    inject
} from "vue";

export default defineComponent({
	name: "memo-popup",
	components: {
		'live-savingprogress': livesavingprogress
	},
	props: {
		isopen: {
			type: Boolean,
			required: true,
		},
		memo: {
			type: String,
		},
		requestEvent: {
			type: String,
			required: false,
		},
		updateUrl: {
			type: String,
			required: true,
		},
		updateParams: {
			type: Object,
			default() {
				return {}
			}
		},
		position: {
			type: String,
			required: false,
			default() {
				return 'left';
			}
		},
		isDeleted: {
			default: null
		}
	},


	emits: ['done', 'update'],

	setup(props, context) {
		const inst = getCurrentInstance();
		const app = inst.root.ctx;

        const $http = inject('$http');
        const wait = inject('wait');

		const mutatedMemo = ref('');
		const changed = ref(false);
		const isMounted = ref(false);

		const node = computed(() => {
			if (isMounted.value) {
				return inst.vnode.el
			}

			return null;
		});

		const popupClass = computed(() => {
			let cls = 'memo-popup';

			if (props.position) {
				cls += ' pos-' + props.position;
			}

			if (props.isopen === true) {
				cls += ' open';
			}

			return cls;
		});

		watch(() => props.memo, (n, o) => {
			if (n !== mutatedMemo.value) {
				mutatedMemo.value = n;
				changed.value = false;
			}
		}, {immediate: true});

		watch(() => props.isopen, (n, o) => {
			if (n) {
				nextTick(() => {
					wait(100).then(() => {
						inst.refs.memo_active.focus()
					})
				})
			}
		});


		function onClose(e) {
			if (props.isopen && !changed.value) {
				if (e.target &&
					e.target.tagName !== 'TEXTAREA' &&
					!e.target.classList.contains('btn-note') &&
					!e.target.classList.contains('popover-handler') &&
					!e.target.closest('textarea') &&
					!e.target.closest('.btn-note') &&
					!e.target.closest('.popover-handler')) {
					context.emit('done');
				}
			}
		}

		function updateMemo() {

			if (!changed.value || props.isDeleted) { // gelöschte können nicht geändert werden!!!
				context.emit('done');
				return;
			}


			let ref = inst.refs.save_memo_active;
			if (ref) {
				ref.start();
			}

			context.emit('update', _.cloneDeep(mutatedMemo.value));

			let updateParams = props.updateParams;
			updateParams.memo = mutatedMemo.value;


			wait(10).then(() => {

				if (props.requestEvent) {
					app.requestAction(props.requestEvent)
						.post(props.updateUrl, updateParams)
						.then((r) => {
							changed.value = false;

							nextTick(() => {

								wait(200).then(() => {
									ref.done();

									wait(200).then(() => {
										context.emit('done', mutatedMemo.value);
									});
								});

							});
						});
				} else {
					$http.post(props.updateUrl, updateParams)
						.then((r) => {

							changed.value = false;


							nextTick(() => {

								wait(200).then(() => {
									ref.done();

									wait(200).then(() => {
										context.emit('done', mutatedMemo.value);
									});
								});

							});
						});
				}
			});
		}

		onBeforeMount(() => {
			changed.value = false;
			mutatedMemo.value = _.cloneDeep(props.memo);
		});

		onMounted(() => {
			isMounted.value = true;
		})

		return {
			isMounted,
			node,
			popupClass,
			changed,
			mutatedMemo,
			onClose,
			updateMemo,

		};
	},
})
</script>
<template>
    <div :class="popupClass"
         role="tooltip"
         v-click-outside="{handler: onClose, exclude: ['.popover', '.memo-popup', '.popover-handler', 'textarea']}">
        <!--		<span class="arrow-left"></span>-->
        <div id="arrow" data-popper-arrow></div>
        <textarea ref="memo_active"
                  v-model="mutatedMemo"
                  @keyup="changed = true"
                  @blur="updateMemo"
                  rows="6"
                  :disabled="isDeleted !== null"></textarea>
        <div class="saving">
            <live-savingprogress ref="save_memo_active"></live-savingprogress>
        </div>
    </div>
</template>



<style scoped>

</style>
