<template>
    <transition :name="transition" ref="root">
        <div tabindex="0"
             :class="['vld-overlay', {'is-active':isActive, 'is-full-page': isFullPage, 'hide': !isActive }]"
             :aria-busy="isActive"
             :v-show="isActive"
             aria-label="Loading"
             :style="{ zIndex }">
            <div class="vld-background" @click.prevent="handleCancel" :style="bgStyle"></div>
            <div class="vld-icon" :class="{'has-after': hasAfterSlot === true}">
                <slot name="before"/>
                <slot name="default">
                    <component :is="loader"
                               :color="color"
                               :width="width"
                               :height="height"
                               :stroke-width="strokeWidth"
                    />
                </slot>
                <slot name="after"/>
            </div>
        </div>
    </transition>
</template>

<script>

import {removeElement} from './helpers.js'
import Loaders from '../loaders/index.js';
import {computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref, watch} from "vue";

export default {
    name: 'vue-loading',
    props: {
        active: {
            type: Boolean,
            default: false
        },
        programmatic: {
            type: Boolean,
            default: false
        },
        container: [Object, Function, HTMLElement],
        isFullPage: {
            type: Boolean,
            default: true
        },
        enforceFocus: {
            type: Boolean,
            default: true
        },
        lockScroll: {
            type: Boolean,
            default: false
        },
        transition: {
            type: String,
            default: 'fade'
        },
        /**
         * Allow user to hide the loader
         */
        canCancel: {
            type: Boolean,
            default: false
        },
        /**
         * Do something on cancel
         */
        onCancel: {
            type: Function,
            default: () => {
            }
        },
        color: String,
        backgroundColor: String,
        blur: {
            type: String,
            default: '2px'
        },
        opacity: Number,
        width: Number,
        height: Number,
        zIndex: Number,
        strokeWidth: {
            type: Number,
            default: 2
        },
        loader: {
            type: String,
            default: 'spinner'
        }
    },

    emits: ['hide', 'update:active'],

    components: Loaders,

    setup(props, context) {
        let ct;
        const inst = getCurrentInstance();
        const isActive = ref(false);
        const allowCancel = ref(false);

        const bgStyle = computed(() => {
            return {
                background: props.backgroundColor,
                opacity: props.opacity,
                backdropFilter: `blur(${props.blur})`
            };
        });


        const hasAfterSlot = computed(() => {
            return inst.slots.hasOwnProperty('after') && typeof inst.slots.after === "function";
        });

        onBeforeUnmount(() => {
            if (props.enforceFocus) {
                document.removeEventListener('focusin', focusIn);
            }
            document.removeEventListener('keyup', keyPress);
        });

        onBeforeMount(() => {
            isActive.value = props.active;
        });

        onMounted(async () => {

            if (props.enforceFocus) {
                document.addEventListener('focusin', focusIn);
            }

            // Insert the component in DOM when called programmatically
            if (props.programmatic) {
                if (props.container) {
                    props.isFullPage = false;
                    props.container.appendChild(inst.refs.root);
                } else {
                    document.body.appendChild(inst.refs.root);
                }
            }

            // Activate immediately when called programmatically
            if (props.programmatic) {
                isActive.value = true;
            }

            document.addEventListener('keyup', keyPress);
        });

        watch(() => props.canCancel, async (value) => {
            allowCancel.value = value ? true : false;
        }, {immediate: true});

        watch(() => isActive.value, async (value) => {
            if (value) {
                await disableScroll();
            } else {
                await enableScroll();
            }
        }, {immediate: true});

        watch(() => props.active, async (n) => {
            if (ct) clearTimeout(ct)

            if (n) {
                isActive.value = n;
            }
            else  {
                ct = setTimeout(() => {
                    isActive.value = n;
                }, 200);
            }
        }, {immediate: true});

        // watch(() => props.loading, async (n) => {
        //     if (ct) clearTimeout(ct)
        //
        //     if (n) {
        //         isActive.value = n;
        //     }
        //     else  {
        //         ct = setTimeout(() => {
        //             isActive.value = n;
        //         }, 200);
        //     }
        // });

	    async function focusIn(event) {
            // Ignore when loading is not active
            if (!isActive.value) return;

            if (
                // Event target is the loading div element itself
                event.target === inst.refs.root ||
                // Event target is inside the loading div
                (inst.refs.root && inst.refs.root.contains(event.target))
            ) return;

            if (!inst.refs.root) return;


            // Use container as parent when available otherwise use parent element when isFullPage is false
            let parent = props.container ? props.container : (props.isFullPage ? null : inst.refs.root.parentElement);

            if (
                // Always prevent when loading is full screen
                props.isFullPage ||
                // When a parent exist means loader is running inside a container
                // When loading is NOT full screen and event target is inside the given container
                (parent && parent.contains(event.target))
            ) {
                event.preventDefault();
                await inst.refs.root.focus();
            }
        }

	    async function handleCancel() {
            if (!allowCancel.value || !isActive.value) {
                return;
            }
            await hide();

            await props.onCancel.apply(null, arguments);
        }

        /**
         * Hide and destroy component if it's programmatic.
         */
        async function hide() {
            await context.emit('hide');
            await context.emit('update:active', false);

            // Timeout for the animation complete before destroying
            if (props.programmatic) {
                isActive.value = false;
                setTimeout(async () => {
                    //todo not working vue 3
                    // $destroy();
                    await removeElement(inst.refs.root);
                }, 150);
            }
        }

	    async function disableScroll() {
            if (props.isFullPage && props.lockScroll) {
                await document.body.classList.add('vld-shown');
            }
        }

	    async function enableScroll() {
            if (props.isFullPage && props.lockScroll) {
                await document.body.classList.remove('vld-shown');
            }
        }

        /**
         * Key press event to hide on ESC.
         *
         * @param event
         */
        async function keyPress(event) {
            // todo keyCode is deprecated
            if (event && event.keyCode === 27) {
                if (hide) await hide();
            }
        }

        return {
            isActive,
            bgStyle,
            hasAfterSlot,
            disableScroll,
            enableScroll,
            hide,
            handleCancel
        };
    }
}
</script>
<style scoped>

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}


.fade-enter-to {
    opacity: 1;
}
</style>
