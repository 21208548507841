<template>
    <form-field :name="name" :field="mutatedField" :key="name" :validate="validate"
                :form-model="formModel"
                :responseerrors="responseerrors">

        <template v-slot:field>
            <div class="select-autocomplete"
                 v-if="isMounted"
                 :class="{multiple: isMultiple}" :data-name="name">
                <v-select ref="select"
                          :get-option-label="getOptionLabel"
                          :filterable="false"
                          :options="selectOptions"
                          :model-value="selectedValue"
                          :disabled="isDisabled"
                          :multiple="isMultiple"
                          @input="updateModelValue"
                          @search="onSearch"
                          append-to-body
                >

                    <template v-slot:no-options>
                        <slot name="no-options">
                            keine Person gefunden...
                        </slot>
                    </template>

                    <template v-slot:selected-option="option">
                        <slot name="selectedoption" v-bind:option="option">
                            <div v-if="option && option.id">
                                <div class="selected-people">
                                    <span class="selected-prefix">Pid: {{ option.id }}</span>
                                    <span>{{ option.surname }} {{ option.first_name }}</span>
                                    <span v-if="option.street || option.street_nr"> | {{
                                            option.street
                                        }} {{ option.street_nr }}</span>
                                    <span v-if="option.zip || option.city"> | {{ option.zip }} {{ option.city }}</span>
                                </div>
                            </div>
                        </slot>
                    </template>

                    <template v-slot:option="option">
                        <div class="selected-people" v-if="option && option.id">
                            <div class="prefix-spacer"><span class="selected-prefix" v-if="option.id">Pid: {{ option.id }}</span></div>
                            <div>
                                <div>{{ option.surname }} {{ option.first_name }}</div>
                                <small v-if="option.street || option.zip || option.city">
                                    <span v-if="option.street">{{ option.street }} {{ option.street_nr }}</span>
                                    <span v-if="option.zip || option.city">{{ option.zip }} {{ option.city }}</span>
                                </small>
                            </div>
                        </div>
                    </template>
                </v-select>
            </div>
        </template>


    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, onBeforeMount, inject} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default {
    name: "PeopleSelect",
    props: {
        ...defaultFieldProps,
        returnOnly: {
            type: Boolean,
            default: false
        }
    },

    emits: ['input'],

    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const $events = inject('$events');
        const $http = inject('$http');

        const hasOwnPropertyPath = inject('hasOwnPropertyPath')
        const setPropertyPath = inject('setPropertyPath')

        const {allowMultiple, modelvalue,  fieldIsDisabled} = useFormField(props, context);

        const isMounted = ref(false);
        const mutatedField = ref({});
        const fieldOptions = ref([]);
        const selectedValue = ref([]);
        const initValue = ref(null);
        const cancel = ref(null)

        initValue.value = {
            value: null,
            fieldOptions: null
        };

        watch(() => props.field, (n) => {
            mutatedField.value = n;
        }, {deep: true, immediate: true});

        const selectOptions = computed(() => {
            if (!isMounted.value) return [];
            return fieldOptions.value
        });

        const autocompleteUrl = computed(() => {
            if (props.field.hasOwnProperty('route')) {
                return app.routes(props.field.route);
            } else if (props.field.hasOwnProperty('url')) {
                return props.field.url;
            }
        });

        const hasSelectedSlot = computed(() => {
            return context.slots.hasOwnProperty('selectedoption')
        });

        onBeforeMount(() => {
            selectedValue.value = null;
            isMounted.value = false;

            initDefaults();

            $events.$on('form-reset', () => {
                selectedValue.value = !_.isNull(initValue.value.value) ? _.cloneDeep(initValue.value.value) : [];
                // this.fieldOptions = _.cloneDeep(this.initValue.fieldOptions);
            });
        });

        onMounted(() => {
            isMounted.value = true;

            if (props.field.hasOwnProperty('watchview') && props.field.watchview) {

                if (props.formModel.hasOwnProperty(props.field.watchview)) {
                    if (_.isObject(props.formModel[props.field.watchview])) {
                        selectedValue.value = Object.assign({}, props.formModel[props.field.watchview]);
                    }
                }


                watch(() => props.formModel[props.field.watchview], (n) => {
                    if (_.isObject(n)) {
                        selectedValue.value = Object.assign({}, n);
                    }
                }, {deep: true});
            }
        });

        function getOptionLabel(option) {
            let label = '';
            if (typeof option === 'object') {
                if (option.hasOwnProperty('first_name')) {
                    label += option.first_name
                }
                if (option.hasOwnProperty('surname')) {
                    label += option.surname
                }
            }
            return '';
        }

        function initDefaults() {

            mutatedField.value = props.field;

            if (props.field.hasOwnProperty('options') && !_.isEmpty(props.field.options)) {
                fieldOptions.value = _.isArray(props.field.options) ? props.field.options : [props.field.options];
            }

            if (props.field.hasOwnProperty('selected')) {
                selectedValue.value = props.field.selected
            } else {
                if (_.isObject(props.field.value)) {
                    selectedValue.value = Object.assign({}, props.field.value);
                } else if (_.isArray(props.field.value)) {
                    selectedValue.value = Object.assign({}, props.field.value);
                } else {
                    selectedValue.value = modelvalue.value;
                }
            }


            if (!props.returnOnly) {
                if (allowMultiple.value) {
                    if (!_.isArray(modelvalue.value)) {
                        modelvalue.value = [];
                    }
                } else {
                    if (_.isArray(modelvalue.value)) {
                        modelvalue.value = null;
                    }
                }
                if (_.isObject(mutatedField.value.value)) {
                    selectedValue.value = Object.assign({}, mutatedField.value.value);
                }
                $events.$on('clear-form', () => {
                    updateModelValue(null);
                });
            }

            initValue.value = {
                value: _.cloneDeep(selectedValue.value),
                fieldOptions: _.cloneDeep(fieldOptions.value)
            };

        }

        function reset() {
            selectedValue.value = [];
        }


        function transformFieldOptions() {
            let options = [];
            _.each(mutatedField.value.options, (label, value) => {

                if (label.hasOwnProperty('label') && label.hasOwnProperty('value')) {
                    options.push(label);
                } else {
                    if ((_.isString(value) && value.match(/^\d{1,}/g)) || _.isNumber(value)) {

                        options.push({label: label, value: parseInt(value)});
                    } else {
                        options.push({label: label, value: value});
                    }
                }
            });

            return options;
        }

        let t;

        function onSearch(search, loading) {

            clearTimeout(t)

            if (cancel.value) {
                cancel.value.cancel();
                cancel.value = null;
                loading(false);
            }


            if (!search) {
                return;
            }

            t = setTimeout(() => {
                callSearch(loading, search)
            }, 300);
        }

        function callSearch(loading, search) {

            const axiosSource = axios.CancelToken.source();
            cancel.value = {cancel: axiosSource.cancel, msg: "Loading..."};

            let postData;
            if (mutatedField.value.hasOwnProperty('postparams') && _.isObject(mutatedField.value.postparams)) {
                postData = Object.assign({}, mutatedField.value.postparams, {q: search})
            } else {
                postData = Object.assign({}, {q: search});
            }

            loading(true);

            $http.post(autocompleteUrl.value, postData, {cancelToken: axiosSource.token}).then(r => {
                if (r.data) {
                    fieldOptions.value = r.data;
                }

                loading(false);
            }).catch(e => {
                console.warn('Error:', e.statusText);
                loading(false);
            });
        }


        function updateModelValue(option) {


            if ((_.isArray(props.field.fill_on_select) || _.isObject(props.field.fill_on_select))) {
                _.each(props.field.fill_on_select, (fill, key) => {

                    if ((_.isObject(option) && option.hasOwnProperty('id'))) {
                        if (option.hasOwnProperty(key)) {
                            if (hasOwnPropertyPath(props.formModel, fill)) {
                                setPropertyPath(props.formModel, fill, option[key])
                            }
                        } else {
                            if (hasOwnPropertyPath(props.formModel, fill)) {
                                setPropertyPath(props.formModel, fill, null)
                            }
                        }
                    } else {
                        if (hasOwnPropertyPath(props.formModel, fill)) {
                            setPropertyPath(props.formModel, fill, null)
                        }
                    }

                })
            }


            if (!props.returnOnly) {

                if (allowMultiple.value) {

                    if (Array.isArray(option)) {
                        selectedValue.value = option;
                        modelvalue.value = option;
                    }
                    else {
                        selectedValue.value = [];
                        modelvalue.value = []
                    }
                }
                else {
                    if (_.isObject(option) && option.hasOwnProperty('id')) {
                        selectedValue.value = option;
                        modelvalue.value = option.id;

                        if (option.hasOwnProperty('user') && _.isObject(option.user)) {
                            if (props.formModel.hasOwnProperty('people') && props.formModel.people.hasOwnProperty('user')) {
                                props.formModel.people.user = option.user
                            }
                        }

                        if (option.hasOwnProperty('organization') && _.isObject(option.organization) && !_.isEmpty(option.organization)) {
                            if (mutatedField.value.hasOwnProperty('select_people') && !_.isEmpty(mutatedField.value.select_people)) {
                                $events.$emit(mutatedField.value.select_people, {
                                    people: option,
                                    organization: option.organization
                                })
                            }
                        } else {

                            if (mutatedField.value.hasOwnProperty('clear_people') && !_.isEmpty(mutatedField.value.clear_people)) {
                                $events.$emit(mutatedField.value.clear_people, null)
                            }
                        }


                    }
                    else {
                        selectedValue.value = [];
                        modelvalue.value = null
                        if (props.formModel.hasOwnProperty('people') && props.formModel.people.hasOwnProperty('user')) {
                            props.formModel.people.user = {}
                        }
                    }
                }








                if (props.field.hasOwnProperty('onSelect') &&
                    _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval(props.field.onSelect);
                    } else {
                        if (_.isFunction(props.field.onSelect)) {
                            props.field.onSelect();
                        }
                    }
                }


                context.emit('input', selectedValue.value);
                return;
            } else {
                selectedValue.value = option ? option : [];

                if (props.field.hasOwnProperty('onSelect') &&
                    _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval(props.field.onSelect);
                    } else {
                        if (_.isFunction(props.field.onSelect)) {
                            props.field.onSelect();
                        }
                    }
                }
            }

            if (option) {
                //if (option.hasOwnProperty('user') && _.isObject(option.user)) {
                context.emit('input', option);
                //}
            }

        }


        function peopleChanged() {
            $events.$emit('people-changed', selectedValue.value);
        }


        return {
            isMounted,
            mutatedField,
            isDisabled: fieldIsDisabled,
            isMultiple: allowMultiple,
            selectedValue,
            fieldOptions,
            selectOptions,
            hasSelectedSlot,

            getOptionLabel,
            reset,
            onSearch,
            updateModelValue,
            peopleChanged,
        }

    },


    // data() {
    // 	return {
    // 		isMounted: false,
    // 		mutatedField: {},
    // 		modelName: null,
    // 		units: [],
    // 		defaultAttributes: {},
    // 		fieldOptions: [],
    // 		searchInput: '',
    // 		selectedValue: [],
    // 		initValue: null
    // 	}
    // },
    //
    // watch: {
    // 	field(n, o) {
    // 		this.mutatedField = n;
    // 	}
    // },
    // computed: {
    // 	isDisabled() {
    //
    // 		if (this.field.hasOwnProperty('disabled')) {
    // 			return this.field.disabled;
    // 		}
    //
    // 		let attr = this.fieldAttributes;
    // 		return attr.hasOwnProperty('disabled') && attr.disabled ? true : false;
    // 	},
    // 	selectOptions() {
    // 		if (!this.isMounted) return [];
    // 		return this.fieldOptions
    // 	},
    //
    // 	isMultiple() {
    // 		return this.mutatedField.hasOwnProperty('multiple') && this.mutatedField.multiple
    // 	},
    //
    // 	autocompleteUrl() {
    // 		if (this.mutatedField.hasOwnProperty('route')) {
    // 			return this.route(this.mutatedField.route);
    // 		} else if (this.mutatedField.hasOwnProperty('url')) {
    // 			return this.mutatedField.url;
    // 		}
    // 	},
    // 	hasSelectedSlot() {
    // 		return this.$slots.hasOwnProperty('selectedoption')
    // 	}
    // },
    //
    // // watch: {
    // //     fieldOptions(n, o) {
    // //         if (n !== o) {
    // //
    // //         }
    // //     }
    // // },
    //
    // created() {
    // 	this.selectedValue = [];
    // 	this.fieldOptions = [];
    // },
    //
    // beforeMount() {
    //
    // 	this.initDefaults();
    //
    // 	this.$events.$on('form-reset', () => {
    // 		this.selectedValue = !_.isNull(this.initValue.value) ? _.cloneDeep(this.initValue.value) : [];
    // 		// this.fieldOptions = _.cloneDeep(this.initValue.fieldOptions);
    // 	});
    //
    // 	if (this.field.hasOwnProperty('watchview') && this.field.watchview) {
    //
    // 		if (this.hasFormModel) {
    // 			this.$watch('formModel.' + this.field.watchview, (n) => {
    // 				if (_.isObject(n)) {
    // 					this.selectedValue = Object.assign({}, n);
    // 				}
    // 			});
    //
    //
    //
    // 			if (this.formModel.hasOwnProperty(this.field.watchview) ) {
    // 				if (_.isObject(this.formModel[this.field.watchview])) {
    // 					this.selectedValue = Object.assign({}, this.formModel[this.field.watchview]);
    // 				}
    // 			}
    //
    //
    //
    // 		} else {
    // 			watch('$root.model.' + this.field.watchview, (n) => {
    // 				if (_.isObject(n)) {
    // 					this.selectedValue = Object.assign({}, n);
    // 				}
    // 			})
    // 		}
    //
    //
    // 	}
    // },
    //
    // mounted() {
    // 	this.isMounted = true;
    // },
    //
    // methods: {
    // 	getOptionLabel(option) {
    // 		let label = '';
    // 		if (typeof option === 'object') {
    // 			if (option.hasOwnProperty('first_name')) {
    // 				label += option.first_name
    // 			}
    // 			if (option.hasOwnProperty('surname')) {
    // 				label += option.surname
    // 			}
    // 		}
    // 		return '';
    // 	},
    // 	initDefaults() {
    //
    // 		// this.fieldOptions = this.transformFieldOptions();
    // 		this.mutatedField = this.field;
    //
    // 		if (this.field.hasOwnProperty('options') && !_.isEmpty(this.field.options)) {
    // 			this.fieldOptions = _.isArray(this.field.options) ? this.field.options : [this.field.options];
    // 		}
    //
    // 		if (this.field.hasOwnProperty('selected')) {
    // 			this.selectedValue = this.field.selected
    // 		} else {
    // 			if (_.isObject(this.field.value)) {
    // 				this.selectedValue = Object.assign({}, this.field.value);
    // 			} else if (_.isArray(this.field.value)) {
    // 				this.selectedValue = Object.assign({}, this.field.value);
    // 			} else {
    // 				this.selectedValue = this.modelvalue;
    // 			}
    // 		}
    //
    //
    // 		if (!this.returnOnly) {
    // 			if (this.isMultiple) {
    // 				if (!_.isArray(this.modelvalue)) {
    // 					this.modelvalue = [];
    // 				}
    // 			} else {
    // 				if (_.isArray(this.modelvalue)) {
    // 					this.modelvalue = null;
    // 				}
    // 			}
    // 			if (_.isObject(this.mutatedField.value)) {
    // 				this.selectedValue = Object.assign({}, this.mutatedField.value);
    // 			}
    // 			this.$events.$on('clear-form', () => {
    // 				this.updateModelValue(null);
    // 			});
    // 		}
    //
    // 		this.initValue = {
    // 			value: _.cloneDeep(this.selectedValue),
    // 			fieldOptions: _.cloneDeep(this.fieldOptions)
    // 		};
    //
    // 	},
    //
    // 	reset() {
    // 		this.selectedValue = [];
    // 	},
    //
    //
    // 	transformFieldOptions() {
    //
    // 		let options = [];
    // 		_.each(this.mutatedField.options, (label, value) => {
    //
    // 			if (label.hasOwnProperty('label') && label.hasOwnProperty('value')) {
    // 				options.push(label);
    // 			} else {
    // 				if ((_.isString(value) && value.match(/^\d{1,}/g)) || _.isNumber(value)) {
    //
    // 					options.push({label: label, value: parseInt(value)});
    // 				} else {
    // 					options.push({label: label, value: value});
    // 				}
    // 			}
    // 		});
    //
    // 		return options;
    // 	},
    //
    //
    // 	onSearch(search, loading) {
    //
    //
    // 		if (!search) {
    // 			return;
    // 		}
    //
    // 		loading(true);
    // 		this.search(loading, search, this);
    // 	},
    //
    // 	search: _.debounce((loading, search, vm) => {
    //
    // 		let postData;
    //
    //
    // 		if (vm.mutatedField.hasOwnProperty('postparams') && _.isObject(vm.mutatedField.postparams)) {
    // 			postData = Object.assign({}, vm.mutatedField.postparams, {q: search})
    // 		} else {
    // 			postData = Object.assign({}, {q: search});
    // 		}
    //
    // 		window.axios.post(vm.autocompleteUrl, postData).then(r => {
    // 			if (r.data) {
    // 				vm.fieldOptions = r.data;
    // 			}
    //
    // 			loading(false);
    // 		}).catch(e => {
    // 			console.warn('Error:', e.statusText);
    // 			loading(false);
    // 		});
    // 	}, 350),
    //
    //
    // 	updateModelValue(option) {
    //
    //
    //         if ((_.isArray(this.field.fill_on_select) || _.isObject(this.field.fill_on_select)) ) {
    //             _.each(this.field.fill_on_select, (fill, key) => {
    //
    //                 if ((_.isObject(option) && option.hasOwnProperty('id')) ) {
    //                     if (option.hasOwnProperty(key)) {
    //                         if (this.hasOwnPropertyPath(this.formModel, fill)) {
    //                             this.setPropertyPath(this.formModel, fill, option[key])
    //                         }
    //                     }
    //                     else {
    //                         if (this.hasOwnPropertyPath(this.formModel, fill)) {
    //                             this.setPropertyPath(this.formModel, fill, null)
    //                         }
    //                     }
    //                 }
    //                 else {
    //                     if (this.hasOwnPropertyPath(this.formModel, fill)) {
    //                         this.setPropertyPath(this.formModel, fill, null)
    //                     }
    //                 }
    //
    //             })
    //         }
    //
    //
    // 		if (!this.returnOnly) {
    // 			if (_.isObject(option) && option.hasOwnProperty('id')) {
    // 				this.selectedValue = option;
    // 				this.modelvalue = option.id;
    //
    // 				if (option.hasOwnProperty('user') && _.isObject(option.user)) {
    // 					if (this.hasFormModel) {
    // 						if (this.formModel.hasOwnProperty('people') && this.formModel.people.hasOwnProperty('user')) {
    // 							this.formModel.people.user = option.user
    // 						}
    // 					} else {
    // 						if (this.$root.model.hasOwnProperty('people') && this.$root.model.people.hasOwnProperty('user')) {
    // 							this.$root.model.people.user = option.user
    // 						}
    // 					}
    //
    // 				}
    //
    // 				if (option.hasOwnProperty('organization') && _.isObject(option.organization) && !_.isEmpty(option.organization)) {
    // 					if (this.mutatedField.hasOwnProperty('select_people') && !_.isEmpty(this.mutatedField.select_people)) {
    // 						this.$events.$emit(this.mutatedField.select_people, {
    // 							people: option,
    // 							organization: option.organization
    // 						})
    // 					}
    // 				} else {
    //
    // 					if (this.mutatedField.hasOwnProperty('clear_people') && !_.isEmpty(this.mutatedField.clear_people)) {
    // 						this.$events.$emit(this.mutatedField.clear_people, null)
    // 					}
    // 				}
    //
    //
    // 			} else {
    // 				this.selectedValue = [];
    // 				this.modelvalue = null
    //
    // 				if (this.hasFormModel) {
    // 					if (this.formModel.hasOwnProperty('people') && this.formModel.people.hasOwnProperty('user')) {
    // 						this.formModel.people.user = {}
    // 					}
    // 				} else {
    // 					if (this.$root.model.hasOwnProperty('people') && this.$root.model.people.hasOwnProperty('user')) {
    // 						this.$root.model.people.user = {}
    // 					}
    // 				}
    //
    //
    // 			}
    //
    //
    // 			if (this.field.hasOwnProperty('onSelect') &&
    // 				_.isString(this.field.onSelect)) {
    // 				if (this.field.onSelect.match(/^\$emit/g)) {
    // 					eval(this.field.onSelect);
    // 				} else {
    // 					if (_.isFunction(this[this.field.onSelect])) {
    // 						this[this.field.onSelect]();
    // 					}
    // 				}
    // 			}
    //
    //
    // 			this.$emit('input', this.selectedValue);
    // 			return;
    // 		} else {
    // 			this.selectedValue = option ? option : [];
    //
    // 			if (this.field.hasOwnProperty('onSelect') &&
    // 				_.isString(this.field.onSelect)) {
    // 				if (this.field.onSelect.match(/^\$emit/g)) {
    // 					eval(this.field.onSelect);
    // 				} else {
    // 					if (_.isFunction(this[this.field.onSelect])) {
    // 						this[this.field.onSelect]();
    // 					}
    // 				}
    // 			}
    // 		}
    //
    // 		if (option) {
    // 			//if (option.hasOwnProperty('user') && _.isObject(option.user)) {
    // 			this.$emit('input', option);
    // 			//}
    // 		}
    //
    // 	},
    //
    //
    // 	peopleChanged() {
    // 		this.$events.$emit('people-changed', this.selectedValue);
    // 	}
    //
    // }
}
</script>

<style scoped>

</style>
