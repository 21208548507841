<script>
import {computed, defineComponent, getCurrentInstance, inject, nextTick} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {getDataTableCacheForFilter, useDatatable} from "@/mixins/data-table-mixin";


export default defineComponent({
	name: "IndexLogs",
	setup(props, context) {
		const inst = getCurrentInstance();
		const $root = inst.root.ctx
		const $http = inject('$http');
		const $events = inject('$events');
		const route = useRoute();
		const router = useRouter()
		const store = useStore();
		const requestAction = inject('requestAction')
		const setPropertyPath = inject('setPropertyPath');

		const $moment = inject('$moment')
		const {
			hasTrashed,
			viewTrash,
			selectedTrashCount,
			cleaning,
			grid,
			toolbarData,
			tempRoute,
			fieldComponents,
			handleClearGridFilter,
			handleGridFilter,
			refreshGrid,
			back
		} = useDatatable(props, context, inst);

		const canCreateUser = computed(() => {
			return true
			// return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
		})

		loadData();


		function loadData() {
			let cache = getDataTableCacheForFilter(route.path);

			let postData = {};
			if (cache && cache.grid) {
				postData = cache.grid;
				if (cache.grid.currentFilter) {
					postData = Object.assign(postData, cache.grid.currentFilter);
				}
			}


			$http.post($root.currentRouteUrl(route.path), postData).then((r) => {

				if (!r.data.grid.url) {
					r.data.grid.url = $root.currentRouteUrl(route.path);
				}

				hasTrashed.value = r.data.hastrashed;
				toolbarData.value = r.data.grid.filter;
				grid.value = r.data.grid;

				nextTick(() => {
					$events.$emit('pageloading', false);
				})

			}).catch((e) => {
				console.warn(e);
				nextTick(() => {
					$events.$emit('pageloading', false);
				})
			});
		}


		function onLoaded(data) {

		}

		function handleCreate() {

			$http.post($root.routes('admin.system.logs.truncate') ).then((r) => {
				$events.$emit('pageloading', false);
				$root.notification(r.data);
				if (r.data.success) {
					refreshGrid();
				}
			});

		}

		function handleDelete(row, btn, callback) {

			if (btn.url) {
				callback();

				$events.$emit('pageloading', true);
				$http.post(btn.url).then((r) => {
					$events.$emit('pageloading', false);

					$root.notification(r.data);

					if (r.data.success) {
						refreshGrid();
					}
				}).catch((e) => {
					callback();

					$events.$emit('pageloading', false);
				});
			}
		}

		function formatDate(v, format = 'DD.MM.YYYY') {
			return v ? $moment(v).format(format) : null;
		}


		function openDetails(row, event) {
			setPropertyPath(row, 'loadingdetails', true);

			if (row.hasOwnProperty('rowdetails')) {
				setPropertyPath(row, 'loadingdetails', false);
				return;
			}

			requestAction('onLoadLogDetails').post($root.currentRouteUrl(route.path), {log: row.id}).then((r) => {
				setPropertyPath(row, 'rowdetails', r.data);
				setPropertyPath(row, 'loadingdetails', false);
			});
		}

		function closeDetails(row, event) {

		}



		return {
			grid,
			cleaning,
			toolbarData,
			fieldComponents,
			canCreateUser,
			selectedTrashCount,
			back,
			onLoaded,
			handleGridFilter,
			handleClearGridFilter,
			handleCreate,
			handleDelete,

			formatDate,
			openDetails,
			closeDetails,
		}
	}
})
</script>

<template>
	<toolbar ref="toolbar"
	         :filters="toolbarData"
	         @clearfilter="handleClearGridFilter"
	         @executefilter="handleGridFilter">
		<button class="btn btn-primary"
		        @click="handleCreate"
		        type="button"
		        v-if="canCreateUser">
			<svg-icon name="trash"/>
			Logs leeren
		</button>
	</toolbar>

	<data-table v-if="grid" ref="gridata"
	            :field-components="fieldComponents"
	            :base-data="grid"
	            :toolbar="$refs.toolbar"
	            detail-row-component="LogsDetails"
	            @selected-count="(v) => { selectedTrashCount = v }"
	            @open-detail-row="openDetails"
	            @close-detail-row="closeDetails"
	            @delete="handleDelete"
	            @loaded="onLoaded">

		<template v-slot:user="{row}">
			<template v-if="row.user">
				<div class="log-username">
					<div class="prefix-spacer">
						<span class="badge badge-dark">Uid: {{ row.user.id }}</span>
					</div>
					<div>
						<b>{{ row.user.username }}</b>
						<div class="text-small">{{ row.user.first_name }} {{ row.user.surname }}</div>
					</div>
				</div>
			</template>
		</template>


		<template v-slot:message="{row}">
			<div>{{ row.message }}</div>
			<div v-if="row.edit_url">
				<router-link :to="{path: row.edit_url}">Datensatz anzeigen/bearbeiten</router-link>
			</div>
		</template>

		<template v-slot:request_url="{row}">
			<div>
				<div class="request-url" v-if="row.request_url">{{ row.request_url }}</div>
				<div class="request-useragent" v-if="row.user_agent">{{ row.user_agent }}</div>
			</div>
		</template>

	</data-table>
</template>

<style scoped>

</style>
