import {markRaw, getCurrentInstance, computed, ref, onBeforeMount, inject, watch} from "vue";
import {useFormFieldConditions} from "@/mixins/use-form-field-conditions";
import { useRoute} from "~/vue-router";
import { useStore} from "~/vuex";

export const defaultFieldProps = {
    name: {
        required: false
    },
    field: {
        required: false,
        default() {
            return {};
        }
    },
    formKey: {
        required: false,
        type: String
    },
    formModel: {
        type: [Object, Array],
        default() {
            return {};
        }
    },
    errors: {
        required: false,
        default() {
            return [];
        }
    },
    value: {},
    fieldValue: {},
    disabled: {
        type: Boolean,
        default: false,
    },
    rendersections: {
        required: false,
        default() {
            return {};
        }
    },
    responseerrors: {
        required: false,
        default() {
            return [];
        }
    },
    validate: {
        type: Boolean,
        default: false
    },
    returnOnly: {
        type: Boolean,
        default: false
    },
    mode: {},
    storeModul: {}
};



export function useFormField(props, context) {

    const inst = getCurrentInstance();
    const $root = inst.root.ctx;
    const modelName = ref(null);
    const defaultModelValue = ref(null)
    const route = useRoute();
    const store = useStore();


    const hasOwnPropertyPath = inject('hasOwnPropertyPath')
    const getPropertyPath = inject('getPropertyPath')
    const setPropertyPath = inject('setPropertyPath')


    const syncModel = ref(true);
    const units = ref([]);

    modelName.value = $root.createVModel(props.name);

    const defaultAttributes = ref({});
    const mutatedAttributes = ref({});



    const allowMultiple = computed(() => {
        return (props.field && props.field.multiple) || (fieldAttributes.hasOwnProperty('multiple') && fieldAttributes.multiple);
    });

    const hasFields = computed(() => {
        return props.field.hasOwnProperty('fields') && !_.isEmpty(props.field.fields);
    });

    const hasColumns = computed(() => {
        if (hasFields.value) {
            return _.some(props.field.fields, {type: 'columns'});
        }

        return false;
    });

    const hasComponents = computed(() => {
        if (props.field && props.field.hasOwnProperty('rendersection') && props.field.rendersection) {
            return true
        }

        return false;
    });

    const getRenderSection = computed(() => {
        if (props.field && props.field.hasOwnProperty('rendersection') && props.field.rendersection) {
            return props.field.rendersection
        }
        return null;
    });


    const isColumns = computed(() => {
        return props.field.hasOwnProperty('cols') && props.field.cols >= 1;
    });

    const formatCols = computed(() => {
        if (!props.field.hasOwnProperty('cols')) {
            return 'col-md-12'
        }
        return props.field.cols > 12 || props.field.cols < 1 ? 'col-md-12' : 'col-md-' + props.field.cols;
    });


    const formatFieldClass = computed(() => {
        let cls = isColumns.value ? formatCols.value : 'form-field';

        if (!fieldIsVisible.value || (hasShowWhen.value && !getShowWhen.value)) {
            cls += ' hidden';
        }

        if (fielderror.value) {
            cls += ' has-field-error';
        }

        return cls;
    });



    const modelvalue = computed({
        get: () => {

            if (props.field.type === 'section' || props.field.type === 'columns' || props.field.type === 'column') {
                return undefined;
            }

            if (props.field.hasOwnProperty('metadata')) {
                return props.field.metadata;
            }
            else {
                if (_.isString(modelName.value) && modelName.value.match(/^([a-z0-9_\.]+)$/ig) ) {
                    // @todo remove old root model !!!
                    let v = getPropertyPath(props.formModel, modelName.value);
                    return typeof v !== "undefined" ? v : defaultModelValue.value;
                }

                return defaultModelValue.value;
            }

        },
        set: (val) => {

            if (props.field.type === 'section' || props.field.type === 'columns' || props.field.type === 'column') {
                return;
            }

            if (!props.formModel) return;


            if (props.field.hasOwnProperty('metadata')) {
                context.emit('meta', val);
                return;
            }

            if (_.isObject(val) || Array.isArray(val)) {
            }
            else {
                if (modelvalue.value === val) {
                    return;
                }
            }


            if ( !route.meta.nodirty && !props.field.nodirty ) {

                if (props.storeModul) {
                    store.dispatch(props.storeModul +'/makeDirty');
                    route.meta.dirty = true;
                }
                else {
                    store.dispatch('form/makeDirty');
                    route.meta.dirty = true;
                }

            }

            if (props.formModel && modelName.value) {
                setPropertyPath(props.formModel, modelName.value, val, true);
            }

        }
    })

    const fieldAttributes = computed(() => {
        let defaults = _.cloneDeep(defaultAttributes.value);
        let attrs = _.cloneDeep(mutatedAttributes.value);
        let isDisabled = null;

        if (hasDisableWhen.value) {
            isDisabled = getDisableWhen.value;
        }


        if (!attrs.hasOwnProperty('id') ) {
            attrs.id = 'field-'+ fieldId('').replace(/_$/g, '');
        }

        if (_.isObject(attrs) || _.isArray(attrs)) {

            if (attrs.hasOwnProperty('disabled') && !attrs.disabled) {
                delete attrs.disabled;
            }

            if (props.field.hasOwnProperty('placeholder') && props.field.placeholder && !attrs.hasOwnProperty('placeholder')) {
                attrs.placeholder = props.field.placeholder;
            }

            if (hasDisableWhen.value && isDisabled !== null) {
                attrs.disabled = isDisabled;
            }

            return {
                ...defaults,
                ...attrs
            };
        }


        if (hasDisableWhen.value && isDisabled !== null) {
            defaults.disabled = isDisabled;
        }

        if (props.field.hasOwnProperty('placeholder') && props.field.placeholder) {
            defaults.placeholder = props.field.placeholder;
        }

        return defaults;
    });

    const getUnits = computed(() => {
        return units.value;
    });

    const unitValue = computed({
        get() {
            let u = getUnits.value;
            if (!u || (_.isArray(u) && !u.length)) {
                return;
            }
            let v = getPropertyPath(props.formModel, modelName.value +'unit');
            return typeof v !== "undefined" ? v : null;
        },
        set: async (value) => {
            let u = getUnits.value;
            if (!u || (_.isArray(u) && !u.length)) {
                return;
            }

            await setPropertyPath(props.formModel, modelName.value +'unit', value);

        }
    });


    const fieldConfig = computed({
        get() {
            if (props.field.hasOwnProperty('config') && _.isObject(props.field.config)) {
                return props.field.config;
            }

            return null;
        },
        set: async (v) => {
            await setPropertyPath(props.field, 'config', v);
        }
    });

    const fieldIsDisabled = computed(() => {
        if (fieldAttributes.value)
        {
            if (fieldAttributes.value.hasOwnProperty('disabled')) {
                return fieldAttributes.value.disabled === true;
            }
        }

        return props.disabled === true || props.field.disabled === true;
    });



    watch(() => allowMultiple.value, (n, o) => {
        let val = _.clone(modelvalue.value);
        if (n === true) {
            if (!Array.isArray(modelvalue.value)) {
                if (modelvalue.value) {
                    modelvalue.value = [];
                    modelvalue.value.push(val);
                }
            }
        }
        else {
            if (Array.isArray(modelvalue.value)) {
                modelvalue.value = val.pop();
            }
        }
    })



    function getFieldValidationError(errors, modelName0) {
        if (_.isObject(errors) && !_.isEmpty(errors) && modelName0) {
            if (hasOwnPropertyPath(errors, modelName0)) {
                let e = getPropertyPath(errors, modelName0);
                if (_.isArray(e) && e[0]) {
                    return e[0];
                }
            }
            else if (typeof errors[modelName0] !== 'undefined') {
				let e = errors[modelName0];
                if (_.isArray(e) && e[0]) {
                    return e[0];
                }

            }
        }
        return false;
    }


    function fieldId(value) {
        return props.name ? $root.transformKey(props.name).replace(/\./g, '-') + '_' + value : '_'+value;
    }

    const {
        hasDisableWhen,
        hasShowWhen,
        getDisableWhen,
        getShowWhen,
        getCondition,
        getStringCondition,
        onComponentCreated,
    } = useFormFieldConditions(props, context)


    onBeforeMount(() => {

        // Register components
        if (!_.isEmpty(props.rendersections) && props.rendersections) {
            for (let k in props.rendersections) {
                Neloh.component('section-' + k,  props.rendersections[k] );
            }
        }

        mutatedAttributes.value = props.field && props.field.hasOwnProperty('attributes') ? props.field.attributes : {};
    });


    return {
        syncModel,
        defaultAttributes,
        defaultModelValue,
        modelName,
        modelvalue,
        unitValue,

        hasFields,
        hasColumns,
        hasComponents,
        getRenderSection,


        isColumns,
        formatCols,


        formatFieldClass,
        hasDisableWhen,
        hasShowWhen,
        getDisableWhen,
        getShowWhen,
        getUnits,
        units,
        fieldConfig,
        fieldAttributes,
        allowMultiple,
        fieldIsDisabled,

        fieldId,
        getFieldValidationError,


        // --
        getCondition,
        getStringCondition,
        onComponentCreated,
    }


}





