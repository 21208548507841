const escapeStringRegexp = require('./../utils/escape-string-regexp');




const dotProp = require('~/dot-prop');
Neloh.setPrototype('$dotProp', dotProp);

window.md5 = global.md5 = require('~/js-md5');
Neloh.setPrototype('md5', md5);


import Vue3Storage from './../vendor/vue-localstorage'
Neloh.use(Vue3Storage, {
    name: 'ls',
    bind: true //created computed members from your variable declarations
});




import dayjs from '~/dayjs';
const utc = require('~/dayjs/plugin/utc');
const timezone = require('~/dayjs/plugin/timezone'); // dependent on utc plugin
const customParseFormat = require("~/dayjs/plugin/customParseFormat");
import '~/dayjs/locale/de'; // load on demand


dayjs.extend(customParseFormat)
function parseDate(value = null) {
    if (!value) {
        value = new Date();
    }
    return dayjs( value );
}

global.dayjs = dayjs;

global.dayjs.extend(utc);
global.dayjs.extend(timezone);
global.dayjs.locale('de');





Neloh.setPrototype('moment', parseDate);
Neloh.setPrototype('$moment', parseDate);
Vue.provide('moment', parseDate)
Vue.provide('$moment', parseDate)




const formatFilesize = (num) => {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    num = parseInt(num);

    let neg = num < 0;
    let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    let exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    let unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
}
Neloh.setPrototype('formatFilesize', formatFilesize);
Vue.provide('formatFilesize', formatFilesize)




const slug = (value, delimiter ) => {

    if (!value) return '';

    delimiter = delimiter ? delimiter : '-';

    value = value.replace(/ä/g, 'ae').replace(/Ä/g, 'Ae');
    value = value.replace(/ö/g, 'oe').replace(/Ä/g, 'Oe');
    value = value.replace(/ü/g, 'ue').replace(/Ü/g, 'Ue').replace(/ß/g, 'ss');

    value = value.replace(/[^a-zA-Z\d]+/g, delimiter ? delimiter : '-');
    value = value.replace(/\\/g, '').replace(/\\/g, '');

    let escapedSeparator = escapeStringRegexp(delimiter);
    return value.replace(new RegExp(`^${escapedSeparator}|${escapedSeparator}$`, 'g'), '')
}
Neloh.setPrototype('slug', slug);
Vue.provide('slug', slug)





const hasPermission =(key) => {
    let user = app.$store.getters.user;
    let perm = app.getPropertyPath(user.permissions, key);

    // wenn objeckt dann true, da ja der key existiert
    if (_.isObject(perm)) {
        return true;
    }

    return _.isBoolean(perm) ? perm : false;
};

Neloh.setPrototype('hasPermission', hasPermission)
Neloh.provide('hasPermission', hasPermission)


function getKeysFromPath (path) {
    return path.split ? path.split(/\.|\//) : path
}
Neloh.setPrototype('pathToProp', (obj, propertyPath) => {
    const keys = getKeysFromPath(propertyPath)
    let p
    for (p = 0; p < keys.length; p++) {
        obj = (obj ? obj[keys[p]] : undefined)
    }
    return obj === undefined ? undefined : obj
});


const setPropertyPath = function (obj, propertyPath, value, createPath = true) {
    if (!_.isString(propertyPath) || propertyPath === '')
    {
        return false;
    }

    let properties = propertyPath.split('.');
    let maxLength = properties.length;

    for (let i = 0; i < maxLength; i++)
    {
        let prop = properties[i];

        if (!obj)
        {
            if (!createPath) {
                return undefined;
            }
            else {
                obj = {}
            }
        }

        if (!_.isObject(obj[prop]))
        {
            if (!createPath) {
                if (typeof obj[prop] === undefined) {
                    return undefined;
                }
            }
            else {
                obj[prop] = {}
            }
        }

        if (!obj)
        {
            return false;
        }
        else
        {
            if ((maxLength - 1) === i)
            {
                obj[prop] = value;
                return true;
            }

            obj = obj[prop];
        }
    }

    return false;
};




const getPropertyPath = function (obj, propertyPath, defaultValue)
{
    if (!propertyPath)
        return defaultValue !== undefined ? defaultValue : undefined;

    let properties = propertyPath.split('.');

    for (let i = 0; i < properties.length; i++)
    {
        let prop = properties[i];

        if (!obj)
        {
            return defaultValue !== undefined ? defaultValue : undefined;
        }

        if (!obj.hasOwnProperty(prop))
        {
            return defaultValue !== undefined ? defaultValue : undefined;
        }

        if (!obj)
        {
            return defaultValue !== undefined ? defaultValue : undefined;
        } else
        {
            obj = obj[prop];
        }
    }

    return obj;
};



const hasOwnPropertyPath = function (obj, propertyPath)
{
    if (!propertyPath)
        return false;

    let properties = propertyPath.split('.');

    for (let i = 0; i < properties.length; i++)
    {
        let prop = properties[i];

        if (!obj)
        {
            return undefined;
        }

        if (!obj.hasOwnProperty(prop))
        {
            return undefined;
        }

        if (!obj)
        {
            return false;
        }
        else
        {
            obj = obj[prop];
        }
    }

    return true;
};


Neloh.setPrototype('setPropertyPath', setPropertyPath);
Neloh.setPrototype('getPropertyPath', getPropertyPath);
Neloh.setPrototype('getOwnPropertyPath', getPropertyPath);
Neloh.setPrototype('hasOwnPropertyPath', hasOwnPropertyPath);
Neloh.setPrototype('$set', setPropertyPath);

Vue.provide('$set', setPropertyPath);
Vue.provide('getPropertyPath', getPropertyPath);
Vue.provide('setPropertyPath', setPropertyPath);
Vue.provide('getOwnPropertyPath', getPropertyPath);
Vue.provide('hasOwnPropertyPath', hasOwnPropertyPath);


const wait = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
Neloh.setPrototype('wait', wait);
Neloh.provide('wait', wait);


const transformKey = (value) => {
    if (value && value.indexOf('[') >= 0 )
    {
        value = value.replace(/\[\]/g, '');
        value = value.replace(/\[/g, '.').replace(/]/g, '')
    }
    return value;
};

Neloh.setPrototype('transformKey',transformKey);
Neloh.provide('transformKey', transformKey);


const nl2br =  (str, is_xhtml = false) => {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Philip Peterson
    // +   improved by: Onno Marsman
    // +   improved by: Atli Þór
    // +   bugfixed by: Onno Marsman
    // +      input by: Brett Zamir (http://brett-zamir.me)
    // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Brett Zamir (http://brett-zamir.me)
    // +   improved by: Maximusya
    // *     example 1: nl2br('Kevin\nvan\nZonneveld');
    // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
    // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
    // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
    // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
    // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}
Neloh.setPrototype('nl2br',nl2br);
Neloh.provide('nl2br', nl2br)






const objectToArray = (obj) => {
    return Object.keys(obj).map(function (key) { return obj[key]; });
};
Neloh.setPrototype('objectToArray',objectToArray);
Neloh.provide('objectToArray',objectToArray);







const formatDate = (date, format = 'DD.MM.YYYY') => {
    return date ? dayjs(date).format(format) : null;
}

Neloh.setPrototype('formatDate', formatDate);
Neloh.provide('formatDate', formatDate);




const str_replace = (search, replace, subject, countObj) => {
    let i = 0;
    let j = 0;
    let temp = '';
    let repl = '';
    let sl = 0;
    let fl = 0;
    let f = [].concat(search);
    let r = [].concat(replace);
    let s = subject;
    let ra = Object.prototype.toString.call(r) === '[object Array]';
    let sa = Object.prototype.toString.call(s) === '[object Array]';
    s = [].concat(s);

    let $global = (typeof window !== 'undefined' ? window : global);
    $global.$locutus = $global.$locutus || {};
    let $locutus = $global.$locutus;
    $locutus.php = $locutus.php || {};

    if (typeof (search) === 'object' && typeof (replace) === 'string') {
        temp = replace;
        replace = [];
        for (i = 0; i < search.length; i += 1) {
            replace[i] = temp;
        }
        temp = '';
        r = [].concat(replace);
        ra = Object.prototype.toString.call(r) === '[object Array]';
    }

    if (typeof countObj !== 'undefined') {
        countObj.value = 0;
    }

    for (i = 0, sl = s.length; i < sl; i++) {
        if (s[i] === '') {
            continue;
        }
        for (j = 0, fl = f.length; j < fl; j++) {
            temp = s[i] + '';
            repl = ra ? (r[j] !== undefined ? r[j] : '') : r[0];
            s[i] = (temp).split(f[j]).join(repl);
            if (typeof countObj !== 'undefined') {
                countObj.value += ((temp.split(f[j])).length - 1);
            }
        }
    }
    return sa ? s : s[0];
};




Neloh.setPrototype('str_replace', str_replace);
Neloh.provide('str_replace', str_replace)


const requestAction = (onEvent, timeout) => {
    let token = Neloh.vueInstance()._context.provides.token;
    timeout = (timeout ? timeout : 10000);

    return window.axios.create({
               timeout: timeout,
               headers: {
                   'X-Requested-With': 'XMLHttpRequest',
                   'X-NELOH-AJAX': onEvent,
                   'X-CSRF-TOKEN': token
               }
           });
}

Neloh.setPrototype('requestAction', requestAction);
Neloh.provide('requestAction', requestAction)
