<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, defineComponent, getCurrentInstance, inject, nextTick, ref, watch} from "~/vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";

export default defineComponent({
	name: "ShowRequestDetails",
	props: {
		show: {
			type: Boolean,
			default: false
		},
		requestId: {
			type: Number,
			default: 0
		}
	},


    emits: ['close'],

	setup(props, context) {
		const inst = getCurrentInstance();
		const $root = inst.root.ctx

		const route = useRoute();
		const router = useRouter()
		const store = useStore();

		const requestAction = inject('requestAction')
		const setPropertyPath = inject('setPropertyPath');
		const $http = inject('$http');
		const $events = inject('$events');


        const sendingMessage = ref(false);
		const loadingDetails = ref(false);
		const requestDetails = ref(null);

        const showMessenger = ref(false);
        const messangerModel = ref({
            subject: null,
            content: null,
            question_opinion: null,
            answer_opinion: null,
        })

        const canWriteMessage = computed(() => {
            return requestDetails.value && requestDetails.value.can_send_opinions
        });

		watch(() => props.show, (n,o) => {
			if (n) {
				loadDetails();

			}
			else {
				requestDetails.value = null;
			}
		});

		function loadDetails() {
			loadingDetails.value = true;

			$http.post($root.routes('admin.requests.details', {item: props.requestId } )).then((r) => {
				if (r.data.success) {
					requestDetails.value = r.data.entry;
					loadingDetails.value = false;

                    nextTick(() => {
                        bindPlaceholderClickEvents()
                    })
				}
			}).catch(e => {
				$root.notification(e);
			});
		}

        function makePlaceholderId(old_placeholder) {
            if (old_placeholder) {
                return md5(old_placeholder);
            }

            return old_placeholder
        }

        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }

        function makeSourceLinks(content, entry) {

            if ( Array.isArray(entry.customsources) && entry.customsources.length) {
                _.each(entry.customsources, (r) => {
                    if (r.placeholder) {
                        content = content.replace(new RegExp(escapeRegExp(r.placeholder), 'g'), '<a href="javascript: void(0)" data-customsources="customsource_'+ r.id +'">'+ r.placeholder +'</a>');
                    }
                })
            }

            if ( Array.isArray(entry.links) && entry.links.length) {

            }

            if ( Array.isArray(entry.links) && entry.links.length) {

            }

            return content
        }

        function bindPlaceholderClickEvents() {
            if (inst.refs.answer) {
                inst.refs.answer.querySelectorAll('[data-customsources]').forEach((el) => {
                    el.addEventListener('click', (e) => {
                        let itemContainer = inst.refs.sidebar;
                        let customsource = e.target.dataset.customsources;
                        if (customsource && itemContainer) {
                            let s = itemContainer.querySelector('[data-source="'+ customsource +'"]');
                            if (s) {
                                // Scroll to source and hightlight it after scrolled
                                s.scrollIntoView({block: "start"});

                                nextTick(() => {
                                    s.classList.add('hightlight-source');
                                    setTimeout(() => s.classList.remove('hightlight-source'), 500)

                                    // $root.wait(500).then(() => {
                                    //     s.classList.remove('hightlight')
                                    // });
                                })
                            }
                        }
                    })
                })
            }
        }


        const canSubmitMessage = computed(() => {
            if (messangerModel.value) {
                if ((typeof messangerModel.value.question_opinion === "string" && messangerModel.value.question_opinion.trim().length > 3) ||
                    (typeof messangerModel.value.answer_opinion === "string" && messangerModel.value.answer_opinion.trim().length > 3)) {
                    return true;
                }
            }

            return false;
        });

        function submitMessage() {
            sendingMessage.value = true;

            $http.post($root.routes('admin.requests.opinion.store', {item: props.requestId}), messangerModel.value)
                .then(r => {
                    if (r.data.success) {
                        $root.notification(r.data);
                        requestDetails.value.can_send_opinions = false;

                        showMessenger.value = false;
                        messangerModel.value = {
                            subject: null,
                            content: null,
                            question_opinion: null,
                            answer_opinion: null,
                        };
                    }

                    sendingMessage.value = false;
                }).catch(e => {
                sendingMessage.value = false;
            });
        }

		return {
            sendingMessage,
            canWriteMessage,
			loadingDetails,
			requestDetails,
            makeSourceLinks,

            canSubmitMessage,
            submitMessage,
            showMessenger,
            messangerModel
		}
	}
})
</script>

<template>


    <modal
        :show="show"
        :useToolbar="true"
        :use-footer="false"
        :show-ok="false"
        okBtnLabel="Schließen"
        height="99%"
        width="99%"
        modalClass="request-details-modal"
        @submit="$emit('close')"
        @close="$emit('close')"
        @cancel="$emit('close')"
    >

        <template v-slot:title>
            Details ...
        </template>
        <template v-slot:body>
            <div class="requests-details" v-if="show">
                <loading :active="loadingDetails === true"
                         color="blue"
                         :width="20"
                         :height="20"
                         :opacity="0.6"
                         :is-full-page="false">
                    <template v-slot:after>
                        <div class="m">Hole Daten...</div>
                    </template>
                </loading>

                <template v-if="show && requestDetails && !loadingDetails">
                    <div class="requests-details-content-container">
                        <div class="content-pos">
                            <div class="question">
                                <b>Frage</b>
                                <div><pre>{{ requestDetails.question }}</pre></div>
                            </div>
                            <div class="answer" ref="answer">
                                <b>Antwort</b>
                                <div><pre v-html="makeSourceLinks(requestDetails.answer ?? 'Noch keine Antwort vorhanden', requestDetails)"></pre></div>
                            </div>
                        </div>
                        <div class="side-pos" ref="sidebar">


                            <loading :active="sendingMessage"
                                     color="blue"
                                     :width="20"
                                     :height="20"
                                     :opacity="0.6"
                                     :is-full-page="false">
                            </loading>

                            <div class="side-pos-tools">
                                <template v-if="canWriteMessage">
                                    <button type="button"
                                            class="btn btn-primary"
                                            v-if="!showMessenger"
                                            @click="showMessenger = true">Meinung Schreiben</button>
                                    <button type="button"
                                            class="btn btn-primary"
                                            v-if="showMessenger"
                                            @click="showMessenger = false">Formular schließen</button>
                                </template>

                                <template v-if="requestDetails.has_send_opinions">
                                    <div class="alert alert-info">Sie haben bereits eine Meinung zur Anfrage gesendet.</div>
                                </template>
                            </div>

                            <template v-if="showMessenger && canWriteMessage">

                                <form-textarea name="question_opinion"
                                               :form-model="messangerModel"
                                               :field="{ label: 'Meinung/Anregung zur Frage', attributes: {rows: 4} }"></form-textarea>

                                <form-textarea name="answer_opinion"
                                               :form-model="messangerModel"
                                               :field="{ label: 'Meinung/Anregung Zur Antwort', attributes: {rows: 4} }"></form-textarea>

                                <div class="message-submit">
                                    <button type="button"
                                            class="btn btn-primary"
                                            :disabled="!canSubmitMessage || sendingMessage"
                                            @click="submitMessage">Nachricht abschicken</button>
                                </div>

                            </template>
                            <template v-else>
                                <template v-if="requestDetails.mediums && Array.isArray(requestDetails.mediums) && requestDetails.mediums.length">
                                    <div class="mediums">
                                        <div><b>Medium</b></div>
                                        <template v-for="r in requestDetails.mediums">
                                            <div class="entry">{{ r.title }}</div>
                                        </template>
                                    </div>
                                </template>
                                <template v-if="requestDetails.keywords">
                                    <div class="keywords">
                                        <div><b>Stichworte</b></div>
                                        <div>{{ requestDetails.keywords ?? '-' }}</div>
                                    </div>
                                </template>
                                <template v-if="Array.isArray(requestDetails.subjects) && requestDetails.subjects.length">
                                    <div class="subjects">
                                        <div><b>Themen</b></div>
                                        <div>
                                            <span v-for="r in requestDetails.subjects">{{ r.label }}</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="Array.isArray(requestDetails.links) && requestDetails.links.length">
                                    <div class="links">
                                        <div><b>Links</b></div>
                                        <div>
                                            <template v-for="r in requestDetails.links">
                                                <div class="link-entry">
                                                    <a :href="r.url" target="_blank">{{ r.title ?? r.url }}</a>
                                                    <div class="desc" v-if="r.description">{{ r.description }}</div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="Array.isArray(requestDetails.literatures) && requestDetails.literatures.length">
                                    <div class="literatures">
                                        <div><b>Literatur</b></div>
                                        <div>
                                            <template v-for="r in requestDetails.literatures">
                                                <div class="literature-entry">
                                                    {{ r.title }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="Array.isArray(requestDetails.customsources) && requestDetails.customsources.length">
                                    <div class="customsources">
                                        <div><b>sonstige Quellen</b></div>
                                        <div>
                                            <div v-for="(s, x) in requestDetails.customsources" :data-source="'customsource_'+ s.id">
                                                <span class="key" v-if="s.placeholder">{{ s.placeholder }}</span>
                                                <template v-if="s.medium_id === 21">
                                                    <a :href="s.content" target="_blank">{{ s.title || s.content }}</a>
                                                </template>
                                                <template v-else-if="s.medium_id === 3">
                                                    <a :href="s.content" target="_blank">{{ s.title || s.content }}</a>
                                                </template>
                                                <template v-else>
                                                    <span class="s" v-if="s.content">{{ s.content }}</span>
                                                    <span class="s" v-else-if="s.title">{{ s.title }}</span>
                                                    <span class="s" v-else> --- </span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </modal>
</template>

<style scoped>

</style>
