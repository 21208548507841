<template>
    <div class="inner-wrapper">
        <div class="tab-content" v-if="hasFields">
            <slot name="before-fields"></slot>
            <div :class="['tab-pane', {
                    active: true,
                    hidden: hideShowWhen(currentFields, formTabActive)
                    }]" :ref="'tabsection-'+ formTabActive">

                <template v-if="(hasSidebar || hasSlotSidebar)">
                    <div class="row">
                        <div class="col-md-9">
                            <form-fields :fields="contentFields"
                                         :responseerrors="responseerrors"
                                         :rendersections="rendersections"
                                         :form-model.sync="formModel"
                                         :validate="validate"
                                         mode="main"
                            ></form-fields>
                        </div>
                        <div class="col-md-3 form-sidebar">
                            <template v-if="sidebarFields">
                                <slot name="sidebar"></slot>
                                <form-fields :fields="sidebarFields"
                                             :responseerrors="responseerrors"
                                             :rendersections="rendersections"
                                             :form-model.sync="formModel"
                                             :validate="validate"
                                             mode="sidebar"></form-fields>
                            </template>
                            <template v-else-if="hasSlotSidebar">
                                <slot name="sidebar"></slot>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <form-fields :fields="contentFields"
                                 :responseerrors="responseerrors"
                                 :rendersections="rendersections"
                                 :form-model.sync="formModel"
                                 :validate="validate"
                                 mode="main"
                    ></form-fields>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {getCurrentInstance, computed, ref, onBeforeUnmount, onBeforeMount, inject, watch, nextTick} from "vue";
import {useStore} from "~/vuex";
import {useFormConditions} from "@/mixins/use-form-tab-conditions";

export default {
    name: "form-layout-content",
    props: {
        name: {
            required: false,
        },
        fields: {
            required: true,
            type: [Object, Array]
        },
        validate: {
            type: Boolean,
            default: false
        },
        rendersections: {
            required: false,
            default() {
                return {}
            }
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        },
        forgetFormAfterUnmount: {
            type: Boolean,
            default: true
        },
    },


    setup(props, context) {
        const inst = getCurrentInstance();
        const store = useStore();
        const $root = inst.root.ctx;
        const {
            getCondition,
            hasDisableWhen,
            hasShowWhen,
            getShowWhen,
            getDisableWhen,
            hideShowWhen,
            disableWhen,
        } = useFormConditions(props, context, $root);

        const hasSlotSidebar = ref(false)

        const formTabActive = computed(() => {
            return store.getters['form/formTabActive'];
        });

        const currentFields = computed(() => {
            if (formTabActive.value && props.fields)
            {
                // name based
                if (props.fields.hasOwnProperty(formTabActive.value)) {
                    return props.fields[formTabActive.value];
                }

                // id based
                let found = _.find(props.fields, {id: formTabActive.value});
                if (found) {
                    return found
                }
            }


            return {}
        })

        const sidebarFields = computed(() => {
            if (currentFields.value) {
                if (currentFields.value.hasOwnProperty('sidebar_fields')) {
                    return currentFields.value['sidebar_fields'];
                }
            }
            return null;
        });

        const contentFields = computed(() => {
            if (currentFields.value) {
                if (currentFields.value.hasOwnProperty('content_fields')) {
                    return currentFields.value['content_fields'];
                }
                else if(!Object.keys(currentFields.value).length && Object.keys(props.fields).length) {
                    return props.fields;
                }
            }
            return null;
        });


        const hasSidebar = computed(() => {
            return sidebarFields.value !== null && _.isObject(sidebarFields.value) && Object.keys(sidebarFields.value).length > 0
        });

        const hasFields = computed(() => {
            return hasSidebar.value || !_.isEmpty(contentFields.value);
        });


        function checkSlotContent() {
            if (inst.slots && inst.slots.sidebar) {
                // Hier prüfen wir, ob der Slot-Inhalt nicht leer ist
                const slotContent = inst.slots.sidebar?.();

                hasSlotSidebar.value = slotContent && slotContent.some(vnode => {
                    // console.log('VNode type:', vnode.type);
                    // console.log('VNode children:', vnode.children);
                    // console.log('VNode text:', vnode.text);
                    // vnode ist der Virtual DOM Node
                    // Prüfen, ob es ein Element ist und sichtbar ist
                    return vnode.type !== Comment && (vnode.children || vnode.text);
                })
            } else {
                hasSlotSidebar.value = false;
            }
        }


        watch(() => formTabActive.value, checkSlotContent, {immediate: true});


        onBeforeMount(() => {
            if (!props.forgetFormAfterUnmount) {
                store.dispatch('form');
            }
        })



        return {
            currentFields,
            sidebarFields,
            contentFields,
            hasSidebar,
            hasFields,
            hasSlotSidebar,
            formTabActive,


            getCondition,
            hasDisableWhen,
            hasShowWhen,
            getShowWhen,
            getDisableWhen,
            hideShowWhen,
            disableWhen,
        }

    },
}
</script>

<style scoped>

</style>
