<script>
import {computed, defineComponent, getCurrentInstance, inject, nextTick, ref, watch} from 'vue'
import {defaultFieldProps, useFormField} from "@/mixins/use-form-field";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "ams-solutions",
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const store = useStore()

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);

        const route = useRoute();
        const $http = inject('$http');
        const $events = inject('$events');
        const loading = ref(false);
        const notification = inject('notification');

        const enableEditingWatcher = ref(false);
        const editing = ref(false);
        const editingRow = ref(null);
        const editingDirty = ref(false);

        const warning = ref(false);

        watch(() => editingRow.value, (n, o) => {
            if (enableEditingWatcher.value && n !== o) {
                editingDirty.value = true
            }
        }, {deep: true})

        const warning_label = computed(() => {
            if (warning.value) {
                let r = _.find(modelvalue.value, {id: warning.value})
                if (r) {
                    return r.solution
                }
            }
            return null;
        })

        const canEdit = computed(() => {
            return editing.value === false
        })

        let newID = 0;


        function remove(id) {
            if (editingDirty.value) return;

            if (typeof id === "string" && id.match(/^n\d+?/g)) {
                modelvalue.value = _.filter(_.cloneDeep(modelvalue.value), (r) => {
                    return id !== r.id
                })
            } else {
                warning.value = id;
            }
        }

        function confirmRemove() {
            if (warning.value > 0) {
                let item = _.find(modelvalue.value, {id: warning.value});
                if (item) {
                    loading.value = true;

                    $http.post(app.routes('admin.ams.solution.remove'), {id: warning.value, case_id: props.formModel.id}).then(r => {

                        notification(r.data);

                        if (r.data.success)
                        {
                            modelvalue.value = _.filter(_.cloneDeep(modelvalue.value), (r) => {
                                return warning.value !== r.id
                            })

                            loading.value = false;
                            warning.value = false;

                        }
                        else {
                            loading.value = false;
                        }
                    }).catch(e => {
                        loading.value = false;
                    });
                }
            }
        }

        function add() {
            if (editingDirty.value) return;

            if (!Array.isArray(modelvalue.value)) {
                modelvalue.value = [];
            }

            newID++;

            modelvalue.value.push({
                'id': 'n' + newID,
                'solution': null, //
            });


            editing.value = 'n' + newID;
        }

        function editEntry(id, row) {
            if (editingDirty.value) return;

            enableEditingWatcher.value = false;
            editingDirty.value = false;

            editingRow.value = row;
            editing.value = id;

            nextTick(() => {
                enableEditingWatcher.value = true;
            })
        }

        function cancelEditEntry(id) {
            if (editing.value === id) {
                enableEditingWatcher.value = false;
                editingRow.value = null;
                editing.value = false;
                editingDirty.value = false
            }
        }

        return {
            loading,
            modelvalue,
            editing,
            canEdit,
            editingDirty,
            editingRow,
            warning,
            warning_label,

            add,
            remove,
            confirmRemove,

            editEntry,
            cancelEditEntry,
        }
    }
})
</script>

<template>
    <confirm
        :show="warning !== false"
        title="Lösung entfernen ..."
        ok-label="Ja diese Lösung entfernen"
        type="warning"
        @cancel="warning = false"
        @submit="confirmRemove">
        <template v-slot:message>
            <loading :active="loading === true"
                     color="blue"
                     :width="40"
                     :height="40"
                     :opacity="0.6"
                     :is-full-page="false">
            </loading>

            <p>Möchten Sie die Lösung "{{ warning_label }}" wirklich entfernen?</p>
            <p><b>Diese wird dann direkt entfernt und kann nicht wiederhergestellt werden!</b></p>
        </template>
    </confirm>


    <div class="grid-container-outer ams-clinical-params-container">
        <table class="table fixed-layout vuetable table-striped table-hovered">
            <thead class="grid-header">
            <tr>
                <th width="80"><div></div></th>
                <th width="120"><div>Datum</div></th>
                <th width="auto"><div>Lösung</div></th>
            </tr>
            </thead>
            <tbody class="vuetable-body grid-data">
            <template v-if="Array.isArray(modelvalue) && modelvalue.length">
                <template v-for="(r, i) in modelvalue">
                    <tr :class="i % 2 ? 'even' : 'odd'">
                        <td width="80" data-label="Action">
                            <div class="actions">
                                <template v-if="r.id === editing">

                                    <button type="button" class="btn btn-sm"
                                            v-tooltip="'klinischen Parameter speichern'">
                                        <svg-icon name="device-floppy"/>
                                    </button>

                                    <button type="button" class="btn btn-sm"
                                            v-tooltip="'Bearbeitung abbrechen'"
                                            :disabled="editingDirty"
                                            @click="cancelEditEntry(r.id)">
                                        <svg-icon name="x"/>
                                    </button>

                                </template>
                                <template v-else>
                                    <button type="button" class="btn btn-sm"
                                            v-tooltip="'klinischen Parameter bearbeiten'"
                                            @click="editEntry(r.id, r)" :disabled="editingDirty">
                                        <svg-icon name="pencil"/>
                                    </button>
                                    <button type="button" class="btn btn-sm"
                                            v-tooltip="'klinischen Parameter entfernen'"
                                            @click="remove(r.id)" :disabled="editingDirty">
                                        <svg-icon name="trash"/>
                                    </button>
                                </template>

                            </div>
                        </td>
                        <td width="120" data-label="Datum"><div>{{ formatDate(r.updated_at || r.created_at) }}</div></td>
                        <td width="auto" data-label="Lösung"><div>
                            <input type="text" v-if="r.id === editing"
                                   v-model="r.solution"
                                   placeholder="Lösung ..."
                            />
                            <span v-else>{{ r.solution }}</span>
                        </div></td>
                    </tr>
                </template>
            </template>
            <template v-else>
                <tr>
                    <td colspan="3" class="empty"><em>keine Lösungen bekannt</em></td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>

    <div class="after-table-actions">
        <button type="button" class="btn btn-primary" @click="add">Lösung hinzufügen</button>
    </div>
</template>

<style scoped>

</style>
