<template>
    <form-field :name="name" :form-model="formModel" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>
            <template v-if="canAutosize">
                <textarea v-autosize ref="formfield" :name="name" class="form-control"
                          v-model="modelvalue" @input="updateValue"
                          v-bind="fieldAttributes" spellcheck="false"></textarea>
            </template>
            <template v-else>
                <textarea ref="formfield" :name="name" class="form-control"
                          v-model="modelvalue" @input="updateValue"
                          v-bind="fieldAttributes" spellcheck="false"></textarea>
            </template>


<!--          <textarea ref="formfield" :name="name" class="form-control"-->
<!--                    v-model="modelvalue" @input="updateValue"-->
<!--                    v-bind="fieldAttributes" spellcheck="false"></textarea>-->
        </template>

    </form-field>
</template>

<script>

import {computed, getCurrentInstance, onMounted, ref, watch, nextTick} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-textarea",
    emits: ['input', 'focus', 'blur', 'updated'],
    props: {
        ...defaultFieldProps,
        id: {}
    },
    setup(props, context) {
        const {defaultAttributes, modelvalue, fieldAttributes} = useFormField(props, context);

        defaultAttributes.value = {
            rows: 1
        };

        const canAutosize = computed(() => {
            return props.field.autosize === true;
        })

        function updateValue(e) {
            context.emit('input', e.currentTarget.value)
        }


        return {
            modelvalue,
            canAutosize,
            fieldAttributes,
            updateValue
        }
    },
}
</script>

<style scoped>

</style>
