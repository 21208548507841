import {useTippy} from '~/vue-tippy/dist/vue-tippy.mjs';


const defaultTippyTooltipOptions = {
    trigger: 'mouseenter focus',
    arrow: true,
    inertia: true,
    interactive: false,
    animation: 'shift-toward',
    animateFill: false,
    theme: 'tooltip-dark',
    appendTo: () => document.body,
    allowHTML: true,
    delay: [100, 0],
    duration: [300, 0],
    popperOptions: {
        strategy: 'fixed',
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['bottom', 'right'],
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    tether: false,
                },
            },
        ]
    }
};

export const VTooltip = {

    created(el, binding, vnode, prevVnode) {

        if (el.created) {
            return;
        }

        el.created = true;
        let opts = {
            content: binding.value ? binding.value : '',
            theme: 'tooltip-dark',
            arrow: true,
            placement: 'top'
        };

        if (typeof opts === 'object') {
            opts = Object.assign(defaultTippyTooltipOptions, opts);
        }

        if (binding.modifiers) {
            if (binding.modifiers.bottom) {
                opts.placement = 'bottom';
            }
            else if ( binding.modifiers.bottomEnd ) {
                opts.placement = 'bottom-end';
            }
            else if ( binding.modifiers.bottomStart ) {
                opts.placement = 'bottom-start';
            }

            else if (binding.modifiers.top) {
                opts.placement = 'top';
            }
            else if ( binding.modifiers.topEnd ) {
                opts.placement = 'top-end';
            }
            else if ( binding.modifiers.topStart ) {
                opts.placement = 'top-start';
            }


            else if (binding.modifiers.left) {
                opts.placement = 'left';
            }
            else if ( binding.modifiers.leftEnd ) {
                opts.placement = 'left-end';
            }
            else if ( binding.modifiers.leftStart ) {
                opts.placement = 'left-start';
            }
            else if (binding.modifiers.right) {
                opts.placement = 'right';
            }
            else if ( binding.modifiers.rightEnd ) {
                opts.placement = 'right-end';
            }
            else if ( binding.modifiers.rightStart ) {
                opts.placement = 'right-start';
            }
        }
    },


    mounted(el, binding, vnode) {
        if (el.mounted) {
            return;
        }

        el.mounted = true;

        let opts = {
            content: binding.value ? binding.value : '',
            arrow: true,
            placement: 'top'
        };
        if (typeof opts === 'object') {
            opts = Object.assign(defaultTippyTooltipOptions, opts);
        }

        if (binding.modifiers) {
            if (binding.modifiers.bottom) {
                opts.placement = 'bottom';
            }
            else if ( binding.modifiers.bottomEnd ) {
                opts.placement = 'bottom-end';
            }
            else if ( binding.modifiers.bottomStart ) {
                opts.placement = 'bottom-start';
            }

            else if (binding.modifiers.top) {
                opts.placement = 'top';
            }
            else if ( binding.modifiers.topEnd ) {
                opts.placement = 'top-end';
            }
            else if ( binding.modifiers.topStart ) {
                opts.placement = 'top-start';
            }


            else if (binding.modifiers.left) {
                opts.placement = 'left';
            }
            else if ( binding.modifiers.leftEnd ) {
                opts.placement = 'left-end';
            }
            else if ( binding.modifiers.leftStart ) {
                opts.placement = 'left-start';
            }
            else if (binding.modifiers.right) {
                opts.placement = 'right';
            }
            else if ( binding.modifiers.rightEnd ) {
                opts.placement = 'right-end';
            }
            else if ( binding.modifiers.rightStart ) {
                opts.placement = 'right-start';
            }
        }


        if (vnode.props && vnode.props.onTippyShow) {
            opts.onShow = function (...args) {
                return vnode.props.onTippyShow(...args);
            };
        }

        if (vnode.props && vnode.props.onTippyShown) {
            opts.onShown = function (...args) {
                return vnode.props.onTippyShown(...args);
            };
        }

        if (vnode.props && vnode.props.onTippyHidden) {
            opts.onHidden = function (...args) {
                return vnode.props.onTippyHidden(...args);
            };
        }

        if (vnode.props && vnode.props.onTippyHide) {
            opts.onHide = function (...args) {
                return vnode.props.onTippyHide(...args);
            };
        }

        if (vnode.props && vnode.props.onTippyMount) {
            opts.onMount = function (...args) {
                return vnode.props.onTippyMount(...args);
            };
        }

        if (el.getAttribute('content') && !opts.content) {
            opts.content = el.getAttribute('content');
        }

        useTippy(el, opts);
    },

    beforeUnmount(el) {
        if (el.$tippy) {
            el.$tippy.destroy();
        } else if (el._tippy) {
            el._tippy.destroy();
        }
    },
    updated(el, binding) {
        const opts = typeof binding.value === "string" ? { content: binding.value } : binding.value || {};
        // const opts = {content: binding.value};

        if (el.getAttribute('title') && !opts.content) {
            opts.content = el.getAttribute('title');
            el.removeAttribute('title');
        }

        if (el.getAttribute('content') && !opts.content) {
            opts.content = el.getAttribute('content');
        }

        if (el.$tippy) {
            el.$tippy.setProps(opts || {});
        } else if (el._tippy) {
            el._tippy.setProps(opts || {});
        }
    },
};

Vue.directive('tooltip', VTooltip);
