export default {
    position: ['top', 'right'],
    cssAnimation: 'vn-fade',
    velocityAnimation: {
        enter: (el) =>
        {
            let height = el.clientHeight;

            return {
                translateY: [ 0, 0 - height ],
                // height: [height, 0],
                opacity: [1, 0]
            };
        },


        leave: (el) => {
            let height = el.clientHeight;

            return {
                translateX: [0, el.clientWidth ],
                height: [0, height],
                opacity: [0, 1]
            };
        }


        // leave: {
        //     height: 0,
        //     opacity: [0, 1]
        // }
    }
};
