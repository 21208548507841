<template>
    <form-input :responseerrors="responseerrors"
                :name="name"
                :validate="validate"
                :field="field"
                :key="name"
                :data="modelvalue"
                :form-model="formModel"
                :return-only="getReturnOnly"
                @input="(v) => $emit('input', v)"></form-input>
</template>

<script>
import {computed, getCurrentInstance} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default {
    name: "form-number",
    emits: ['input'],
    props: {
        ...defaultFieldProps,
        returnOnly: {
            type: Boolean,
            default: false
        }
    },

    setup(props, context) {
        const {modelvalue} = useFormField(props, context);

        const getReturnOnly = computed(() => {
            if (props.field.hasOwnProperty('returnonly') && props.field.returnonly !== props.returnOnly) {
                return props.field.returnonly;
            }
            return props.returnOnly;
        });
        function updateValue(value) {
            context.emit('input', value);
        }

        return {
            modelvalue,
            getReturnOnly,
            updateValue,
        }

    },

}
</script>

<style scoped>

</style>
