<template>

</template>

<script>
import {useRoute, useRouter} from "vue-router";
export default {
    name: "simple-redirect",
    setup(props, context) {
        const router = useRouter();
        const route = useRoute();
        const { params, query } = route;
        let to = Array.isArray(params.path) ? params.path[0] : params.path;

        if (!to) {
            to = '/';
        }

        if (!to.match(/^\/.*/g) ) {
            to = '/'+ to
        }

        router.replace({ path: to, query })
    }
}
</script>

<style scoped>

</style>
