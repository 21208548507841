import { getCurrentInstance} from "~/vue"
const Notify = {
  install(Vue, args = {}) {
    if (this.installed) {
      return;
    }

    const inst = getCurrentInstance();
    this.installed = true;
    this.params = args;

    Vue.component(args.componentName || 'notifications', require('./Notifications.vue').default );

    const notify = (params) => {
      if (typeof params === 'string') {
        params = { title: '', text: params };
      }

      if (typeof params === 'object') {
        Neloh.$events.$emit('addNotification', params);
      }
    };


    notify.close = function (id) {
      Neloh.$events.$emit('closeNotification', id);
    };

    const name = '$notify';
    const $notify = notify

    Vue[name] = notify;
    Vue.config.globalProperties[ name] = notify;

    Vue.provide(name, notify);
    
    Neloh.mixin({
      methods: {
        $notify: $notify
      }
    });
  }
};

export default Notify;
