import '~/regenerator-runtime/runtime';
global.__VUE_PROD_DEVTOOLS__ = true;
const __VUE_PROD_DEVTOOLS__ = true;
require('neloh-ui/plugins/lodash');

import {createApp} from "vue";
import App from './views/App';
const Vue = createApp(App);

window.Vue = global.Vue = Vue;

Vue.config.devtools = true;
Vue.config.performance = true;

Vue.__VUE_OPTIONS_API__ = true;
Vue.__VUE_PROD_DEVTOOLS__ = true;

import NelohClass from './Neloh';
import store from './store';

const Neloh = new NelohClass(Vue, store);

global.Neloh = Neloh;




require('./plugins/axios');

require('./mixins/vue-mixins');
require('./mixins/user')

require('./directives');
require('./mixins/routing');
require('./components');

require('./module-stores');
require('./module-components');

document.onreadystatechange = async function() {
	if (document.readyState !== "complete") {

	}
	else {
		await Neloh.start('#app');
	}
};
