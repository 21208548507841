const responseError = {
    state: {
        error: null,
    },

    mutations: {
        SET_ERROR: (state, error) => {
            state.error = error;
        },

        CLEAR_ERROR: (state) => {
            state.error = null;
        },
    },

    actions: {
        setResponseError({ commit }, props) {
            return new Promise(resolve => {
                commit('SET_ERROR', props);
                resolve();
            });
        },

        clearResponseError({ commit }, props) {
            return new Promise(resolve => {
                commit('CLEAR_ERROR');
                resolve();
            });
        },
    }
}

export default responseError
