<script>
import {computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw, defineComponent} from "~/vue"
import {useFormField, defaultFieldProps} from "./../../mixins/use-form-field";


export default defineComponent({
    name: "subjects-select",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const {allowMultiple, fieldIsDisabled, modelName, defaultAttributes, modelvalue, fieldAttributes} = useFormField(props, context);


        const $http = inject('$http');
        const $events = inject('$events')
        const getPropertyPath = inject('getPropertyPath');
        const setPropertyPath = inject('setPropertyPath');
        const transformKey = inject('transformKey')
        const isMounted = ref(false)
        const units = ref([])


        const fieldOptionsCache = ref([])
        const fieldOptions = ref([])

        const searchInput = ref('')
        const currentSelected = ref(null);
        const cancel = ref(null)

        watch(() => allowMultiple.value, (n, o) => {
            if (n) {
                if (!Array.isArray(modelvalue.value)) {
                    modelvalue.value = [toRaw(modelvalue.value)];
                    currentSelected.value = modelvalue.value
                }
            }
            else {
                if (Array.isArray(modelvalue.value)) {
                    modelvalue.value = modelvalue.value[0];
                    currentSelected.value = modelvalue.value
                }
            }
        });

        watch(() => props.field.options, (n, o) => {
            if (n && n !== o) {
                nextTick(() => {
                    fieldOptionsCache.value = toRaw(_.cloneDeep(transformFieldOptions()));
                    fieldOptions.value = _.cloneDeep(fieldOptionsCache.value);
                })
            }
        }, {deep: true});


        const selectWrapClasses = computed(() => {
            let classes = ['select-wrap'];
            if (fieldAttributes.value && fieldAttributes.value.hasOwnProperty('class')) {
                classes.push(fieldAttributes.value.class);
            }

            return classes;
        });

        const sizeStyle = computed(() => {
            if (!isMounted.value) return {};

            if (!fieldAttributes.value.hasOwnProperty('size')) return {};


            return {height: fieldAttributes.value.size * 37 + 'px', overflow: 'auto'};
        });

        const isAutocomplete = computed(() => {
            return props.field.hasOwnProperty('autocomplete') && props.field.autocomplete
        });

        const autocompleteUrl = computed(() => {
            if (props.field.hasOwnProperty('route')) {
                return app.routes(props.field.route);
            } else if (props.field.hasOwnProperty('url')) {
                return props.field.url;
            }
        });

        const isMultiple = computed(() => {
            return (props.field.hasOwnProperty('multiple') && props.field.multiple) || allowMultiple.value
        });
        const hasTriggerOptions = computed(() => {
            return props.field.hasOwnProperty('trigger_options');
        });

        const selectOptions = computed(() => {
            return fieldOptions.value
        });


        onBeforeUnmount(() => {
            fieldOptions.value = [];
            fieldOptionsCache.value = [];
                currentSelected.value = null
        });

        onBeforeMount(() => {
            fieldOptionsCache.value = toRaw(_.cloneDeep(transformFieldOptions()));
            fieldOptions.value = transformFieldOptions();

            if (isMultiple.value) {
                if (!_.isArray(modelvalue.value)) {
                    modelvalue.value = [];
                }

                currentSelected.value = _.cloneDeep(modelvalue.value);
                defaultAttributes.value = {
                    size: 6
                };
            } else {
                // Wenn wert ein array ist aber keine Mehrfachauswahl erlaubt ist dann den ersten wert
                // im array übernehmen
                if (_.isArray(modelvalue.value)) {
                    modelvalue.value = _.cloneDeep(modelvalue.value).pop();
                }
                currentSelected.value = _.cloneDeep(modelvalue.value);
            }
        });


        onMounted(() => {

            isMounted.value = true;
        });


        function getOptionLabel(option) {

            if (_.isFunction(props.field.labelOption)) {
                return props.field.labelOption(option);
            }


            let label = '';
            if (typeof option === 'object') {

                if (option.hasOwnProperty('name1') || option.hasOwnProperty('name2')) {
                    if (option.hasOwnProperty('name1')) {
                        label += option.name1
                    }
                    if (option.hasOwnProperty('name2')) {
                        label += option.name2
                    }
                } else if (option.hasOwnProperty('display_name')) {
                    label += option.display_name
                } else if (option.hasOwnProperty('label')) {
                    label += option.label
                } else if (option.hasOwnProperty('title')) {
                    label += option.title
                }

            }
            return '';
        }

        function getOptionKey(option) {
            if (typeof option !== 'object') {
                return console.warn('getOptionKey', option)
            }

            try {
                if (option.hasOwnProperty('id')) {
                    return option.id
                } else if (option.hasOwnProperty('value')) {
                    return option.value
                }
            } catch (e) {
                const warning =
                    `[vue-select warn]: Could not stringify this option ` +
                    `to generate unique key. Please provide'getOptionKey' prop ` +
                    `to return a unique key for each option.\n` +
                    'https://vue-select.org/api/props.html#getoptionkey'
                return console.warn(warning, option, e)
            }
        }

        function fieldId(value) {
            return transformKey(props.name).replace(/\./g, '-') + '_' + value;
        }


        function transformFieldOptions() {

            let options = [];
            _.each(props.field.options, (label, value) => {

                if (label && typeof label === 'object') {
                    options.push(label);
                }
            });

            return options;
        }


        let t;
        function onSearch(search, loading) {

            if (props.field.filterable) {

                if (!search) {
                    fieldOptions.value = _.cloneDeep(fieldOptionsCache.value)
                    return;
                }


                let options = [];
                let regex = new RegExp(search.toLowerCase(), 'ig')
                _.each(toRaw(_.cloneDeep(fieldOptionsCache.value)), (label, value) => {
                    if (label && typeof label === 'object') {
                        if (typeof label.label === "string") {
                            if (regex.test(label.label.toLowerCase())) {
                                options.push(label);
                            }
                        }
                    }
                });

                fieldOptions.value = options
                return;
            }




            clearTimeout(t)

            if (cancel.value) {
                cancel.value.cancel();
                cancel.value = null;
                loading(false);
            }

            t = setTimeout(() => {
                callSearch(loading, search)
            }, 300);

        }

        function callSearch(loading, search) {


            loading(true);

            const axiosSource = axios.CancelToken.source();
            cancel.value = {cancel: axiosSource.cancel, msg: "Loading..."};


            $http.post(autocompleteUrl.value, {q: search}, {cancelToken: axiosSource.token})
                .then(r => {
                    if (r.data) {
                        fieldOptions.value = r.data;
                    }

                    loading(false);
                }).catch(e => {
                loading(false);
            });

        }


        function transformAutocomplete(result) {

        }

        function isChecked(value) {
            let model = (props.field.usemodel ? props.field.usemodel : props.formModel);
            let modelValue = getPropertyPath(model, modelName.value);
            if (modelValue === null || typeof modelValue == "undefined") {
                return false;
            }


            if (!_.isArray(modelValue)) {
                return modelValue == value;
            }


            let index = _.findIndex(modelValue, function (v, k) {
                return v == value;
            });

            return index !== -1;
        }


        function handleAutocompleteChanged(val) {
            if (!isMounted.value) return;

            let model = (props.field.usemodel ? props.field.usemodel : props.formModel);

            if (isMultiple.value) {

                let modelValue = getPropertyPath(model, modelName.value);

                if (props.field.max >= 0)
                {

                    if (Array.isArray(val) && val.length > parseInt(props.field.max)) {
                        return;
                    }


                }

                setPropertyPath(model, modelName.value, val);
                context.emit('input', val)
            } else {
                if (!isAutocomplete.value) {
                    setPropertyPath(model, modelName.value, val.value);
                } else {
                    setPropertyPath(model, modelName.value, val.value);
                    currentSelected.value = val.value
                }

                context.emit('input', val.value)
            }
        }


        function handleChanged(e) {

            if (!isMounted.value) return;

            let model = (props.field.usemodel ? props.field.usemodel : props.formModel);
            let value = e.currentTarget.value;

            if (isMultiple.value) {
                let modelValue = getPropertyPath(model, modelName.value);

                if (modelValue === null || typeof modelValue == "undefined") {
                    modelValue = [];
                    setPropertyPath(model, modelName.value, modelValue);
                }

                if (_.isObject(modelValue)) {
                    modelValue = Object.keys(modelValue).map((key) => {
                        return modelValue[key]
                    })
                }


                if (_.isArray(modelValue) && props.field.max >= 0) {
                    if (modelValue.length >= parseInt(props.field.max)) {
                        return;
                    }
                }


                if (_.isArray(modelValue) || _.isObject(modelValue)) {

                    let newValue = modelValue;

                    if (e.currentTarget.checked) {
                        newValue.push(e.currentTarget.value);
                    } else {

                        let index = _.findIndex(modelValue, function (v, k) {
                            return v == value;
                        });
                        while (index !== -1) {
                            newValue.splice(index, 1);
                            index = _.findIndex(modelValue, function (v, k) {
                                return v == value;
                            });
                        }
                    }


                    setPropertyPath(model, modelName.value, newValue);
                    setPropertyPath(props.field, 'value', newValue);


                    if (props.field.hasOwnProperty('onSelect') && _.isString(props.field.onSelect)) {
                        if (props.field.onSelect.match(/^\$emit/g)) {
                            eval('$events.' + props.field.onSelect);
                        }
                    }
                }
            } else {


                if (!isAutocomplete.value) {
                    setPropertyPath(model, modelName.value, e.currentTarget.value);
                    setPropertyPath(props.field, 'value', e.currentTarget.value);
                } else {
                    setPropertyPath(model, modelName.value, e.currentTarget.value);
                    setPropertyPath(props.field, 'value', e.currentTarget.value);

                    currentSelected.value = e.currentTarget.value;
                }

                if (props.field.hasOwnProperty('onSelect') && _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval('$events.' + props.field.onSelect);
                    }
                }
            }

            if (!isAutocomplete.value) {
                context.emit('input', value)
            } else {
                context.emit('input', modelvalue.value)
            }
        }


        return {
            allowMultiple,
            currentSelected,
            fieldAttributes,
            fieldIsDisabled,
            fieldOptions,
            isAutocomplete,
            selectWrapClasses,
            isMultiple,
            modelvalue,
            selectOptions,
            sizeStyle,
            getOptionLabel,
            getOptionKey,
            onSearch,
            handleAutocompleteChanged,

            fieldId,
            isChecked,
            handleChanged,
        }


    },
})
</script>

<template>
    <form-field :name="name" :field="field" :key="name"
                :validate="validate"
                :form-model="formModel"
                :responseerrors="responseerrors">

        <template v-slot:field>
            <div class="select-autocomplete"
                 :class="{multiple: isMultiple}"
                 :data-name="name"
            >
                <v-select
                    :get-option-label="getOptionLabel"
                    :get-option-key="getOptionKey"
                    :model-value="modelvalue"
                    :options="selectOptions"
                    :filterable="false"
                    :disabled="fieldIsDisabled === true"
                    @search="onSearch"
                    @input="handleAutocompleteChanged"
                    :multiple="isMultiple"
                    :class="{
                        multiple: isMultiple
                    }"
                    :append-to-body="true">

                    <template v-slot:no-options>
                        <slot name="no-options">bitte Suchbegriff eingeben...</slot>
                    </template>

                    <template v-slot:selected-option="option">
                        <slot name="selected-option" :option="option" v-bind="option">
                            <div class="selected-people">
                                <div>{{ option.label }}</div>
                            </div>
                        </slot>
                    </template>

                    <template v-slot:option="option">
                        <slot name="option" :option="option" v-bind="option">
                            <div>
                                <div>{{ option.label }}</div>
                            </div>
                        </slot>
                    </template>
                </v-select>
            </div>
        </template>


    </form-field>
</template>

<style scoped>

</style>
