import Cookies from '~/js-cookie';
const TokenKey = 'XSRF-TOKEN';

export function getToken() {
	let t = Cookies.get(TokenKey);
	return typeof t === "string" ? t : null;
}


export function setCsrfToken(token, xsrf_token) {
	let meta = document.head.querySelector('meta[name="csrf-token"]');
	meta.setAttribute('content', token);

	if (window.Laravel && window.Laravel.hasOwnProperty('csrfToken')) {
		window.Laravel.csrfToken = token;
	}

	global.axiosBase.defaults.headers.common['X-CSRF-TOKEN'] = token;
	global.axiosBase.defaults.headers.common['X-XSRF-TOKEN'] = xsrf_token;

	window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
	window.axios.defaults.headers.common['X-XSRF-TOKEN'] = xsrf_token;

	Neloh.setHttp(global.axiosBase);
}

export function getHtmlCsrfToken() {
    let meta = document.head.querySelector('meta[name="csrf-token"]');
    return meta ? meta.content : null;
}

export function getCsrfToken() {
	return Cookies.get('XSRF-TOKEN');
}

export function setToken(token) {
	Neloh.$http.defaults.headers.common['X-API-TOKEN'] = token;
}

export function removeToken() {
	Cookies.remove(TokenKey);

	let http = Neloh.getPrototype('$http');
	http.defaults.headers.common['X-API-TOKEN'] = null;
	Neloh.setPrototype('$http', http);
}
