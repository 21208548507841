<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, toRaw, defineComponent, getCurrentInstance, inject, nextTick, ref, onMounted} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";
import {parents} from "@/utils/dom";


export default defineComponent({
    name: "OptionsAnswerWayIndex",

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx

        const route = useRoute();
        const router = useRouter()
        const store = useStore();
        const changeBreadcrumb = inject('changeBreadcrumb')
        const requestAction = inject('requestAction')
        const setPropertyPath = inject('setPropertyPath');
        const $http = inject('$http');
        const $events = inject('$events');

        const loadItem = ref(null);
        const height = ref(null)


        const treeData = ref([]);


        const showDetails = ref(false);
        const detailsForRequestId = ref(null);



        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
            return true
            // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
        })



        loadData();


        onMounted(() => {

        })




        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                if (!r.data.grid.url) {
                    r.data.grid.url = $root.currentRouteUrl(route.path);
                }

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                changeBreadcrumb(r.data)

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {
            changeBreadcrumb(data)
        }

        function handleCreate() {
            router.push({name: 'admin.system.options.answerway.create'})
        }

        function handleDelete() {

        }

        function editEntry(row) {
            router.push({name: 'admin.system.option.answerway.edit', params: {id: row.id}})
        }






        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,
            showDetails,
            detailsForRequestId,
            treeData,
            height,
            loadItem,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreate,
            handleDelete,
            editEntry,
        }
    }
})
</script>

<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">
        <button class="btn btn-primary"
                @click="handleCreate"
                type="button"
                v-if="canCreateUser">
            <svg-icon name="plus"/>
            Antwort-Weg anlegen
        </button>
    </toolbar>

    <data-table v-if="grid" ref="gridata"
                :field-components="fieldComponents"
                :base-data="grid"
                :toolbar="$refs.toolbar"
                @selected-count="(v) => { selectedTrashCount = v }"
                @delete="handleDelete"
                @loaded="onLoaded">

        <template v-slot:title="{row}">
            <template v-if="row.title">
                <div v-if="row.title">{{ row.title }}</div>
                <div v-if="row.description" class="text-small">{{ row.description }}</div>
            </template>
        </template>


        <template v-slot:created_at="{row}">
            <created-updated-by-at v-if="row.created_at" :date="row.created_at" :user="row.createdby" type="created"/>
        </template>

        <template v-slot:updated_at="{row}">
            <created-updated-by-at v-if="row.updated_at" :date="row.updated_at" :user="row.updatedby" type="updated"/>
        </template>


    </data-table>
</template>

<style scoped>

</style>
