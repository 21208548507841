import {createRouter, createWebHashHistory} from "vue-router";

import store from './../store'
import auth from './middleware/auth';
import {middlewarePipeline} from './middlewarePipeline';


const routes = require('./routes.js').default;
const modulRoutes = require('./../module-routes.js').default;

let allRoutes = [];

if (Array.isArray(routes) && Array.isArray(modulRoutes)) {
    allRoutes = routes.concat(modulRoutes);
}
else if (Array.isArray(routes)) {
    allRoutes = routes;
}

if (Array.isArray(allRoutes)) {

    allRoutes.forEach((r) => {




		if (
            r.path !== '/auth' &&
            r.path !== '/404' &&
            r.path !== '/403' &&
            r.path !== '/redirect' &&
            r.path !== '/simple-redirect' &&
            r.path !== '/reset-password' &&
            r.path !== '/verify-password' &&
            r.path !== '/verify-reset' &&
            r.name !== 'reset-password.verify' &&
            r.path !== '/verify-email'
        ) {

			if (!r.meta) {
				r.meta = {};
			}

			if (!r.meta.hasOwnProperty('middleware') ) {
				r.meta.middleware = [];
				r.meta.middleware.push(auth);
			}
			else {
				let add = true;
				r.meta.middleware.forEach((middleware) => {
					if (middleware === auth) {
						add = false;
					}
				});

				if (add) {
					r.meta.middleware.push(auth);
				}
			}
		}





	});
}

const router = createRouter({
	history: createWebHashHistory(),
    routes: allRoutes
});


router.beforeEach((to, from, next) => {

    if (typeof Neloh !== "undefined") {
        Neloh.$events.$emit('pageloading', true);

        if (!Neloh.$store.getters['ui/lastActiveFormTab']) {
            let formName = Neloh.$store.getters['formName'];
            let lastActiveFormTab = Neloh.$store.getters['formTabActive'];

            Neloh.$store.dispatch('ui/setLastActiveFormTab', lastActiveFormTab && formName ? {
                name: formName,
                tab: lastActiveFormTab
            } : null);
        }


        Neloh.$store.dispatch('ui/setToolbarDescription', null);
        Neloh.$store.dispatch('ui/setBreadcrumbs', []);
    }

	if (!to.meta.hasOwnProperty('middleware') || (to.meta.hasOwnProperty('middleware') && !to.meta.middleware) ) {
		return next();
	}
	else {
		const context = {
			to,
			from,
			next,
			store
		};

		const middleware = to.meta.middleware;

		return middleware[0]({
			...context,
			next: middlewarePipeline(context, middleware, 1)
		});
	}
});

router.afterEach((to, from) => {

});

export default router
