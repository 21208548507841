<template>
    <form-field :name="name" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors" :form-model="formModel">
        <template v-slot:field>
            <div class="checkbox" :class="{disabled: fieldIsDisabled }">
                <template v-if="field.hasOwnProperty('checkboxlabel') && field.checkboxlabel">
                    <form-radiofield ref="formfield"
                                     :name="name"
                                     :value="modelvalue"
                                     :field-value="fieldAttributeValue"
                                     :field="{
                                         value: initVal,
                                         disabled: fieldIsDisabled,
                                         attributes: { id: checkboxId, disabled: fieldIsDisabled}
                                     }"
                                     :form-model="formModel"
                                     @input="updateInput"></form-radiofield>
                    <label :for="fieldId(fieldAttributeValue)">
                        {{ field.checkboxlabel }}
                    </label>
                </template>
                <template v-else-if="$slots.default">
                    <form-radiofield ref="formfield"
                                     :name="name"
                                     :value="modelvalue"
                                     :field-value="fieldAttributeValue"
                                     :field="{
                                         value: initVal,
                                         disabled: fieldIsDisabled,
                                         attributes: { id: checkboxId, disabled: fieldIsDisabled}
                                     }"
                                     :form-model="formModel"
                                     @input="updateInput"></form-radiofield>
                    <label :for="fieldId(fieldAttributeValue)">
                        <slot/>
                    </label>
                </template>
                <template v-else>
                    <form-radiofield ref="formfield"
                                     :name="name"
                                     :value="modelvalue"
                                     :field-value="fieldAttributeValue"
                                     :field="{
                                         value: initVal,
                                         disabled: fieldIsDisabled,
                                         attributes: { id: checkboxId, disabled: fieldIsDisabled}
                                     }"
                                     :form-model="formModel"
                                     @input="updateInput"></form-radiofield>
                </template>
            </div>
        </template>


    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, nextTick, inject, onBeforeMount} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-radio",
    props: {
        ...defaultFieldProps
    },
    emits: ['input'],
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const { allowMultiple, fieldIsDisabled, modelName, modelvalue, fieldAttributes} = useFormField(props, context);

        const mchecked = ref(false);
        const initVal = ref(null);



        if (props.name.match(/\[\]$/g) || (props.field.hasOwnProperty('multiple') && props.field.multiple)) {
            allowMultiple.value = true;
        }

        const fieldAttributeValue = computed(() => {
            return fieldAttributes.value.hasOwnProperty('value') ? fieldAttributes.value.value : 1;
        });

        const checkboxId = computed(() => {

            if (props.id) return props.id;


            if (fieldAttributes.value.hasOwnProperty('id')) {
                return fieldAttributes.value.id;
            }


            let name = props.name;
            let idName = name.replace(/\[\]/g, '_');
            idName = idName.replace(/\[/g, '-');
            idName = idName.replace(/\}/g, '');

            return idName.replace(/\./g, '-');
        });



        function fieldId(value) {
            return app.transformKey( modelName.value ).replace(/\./g, '-') +'_'+ value;
        }


        function shouldBeChecked() {

        }

        function updateInput(checked) {
            let value = fieldAttributeValue.value;
            let isChecked = checked;
            let modelValue = modelvalue.value;


            if (allowMultiple.value) {
                if (modelValue === null) {
                    modelValue = [];
                    modelvalue.value = modelValue;
                }

                if (_.isObject(modelValue)) {
                    modelValue = Object.keys(modelValue).map((key) => {
                        return modelValue[key]
                    })
                }

                if (_.isArray(modelValue)) {

                    let newValue = modelValue;

                    if (isChecked) {
                        newValue.push(value);
                    } else {
                        newValue.splice(newValue.indexOf(value), 1);
                    }

                    modelvalue.value = newValue;
                } else {
                    if (isChecked) {
                        modelvalue.value = value;
                    }
                }
            }
            else {
                if (isChecked) {
                    modelvalue.value = value;
                }
            }

            mchecked.value = isChecked;

            if (props.field.event) {
                if (props.field.event.match(/^on([A-Z][a-zA-Z]+)/g)) {
                    eval('$events.$emit(\''+ props.field.event +"\', "+ isChecked +")");
                }
            }


            context.emit('input', mchecked.value);

            // nextTick(() => {
            //     let event = new Event('resize');
            //     window.dispatchEvent(event);
            // });
        }


        watch(() => modelvalue.value, (n, o) => {
            if (n == fieldAttributeValue.value) {
                mchecked.value = true;
            }
            else {
                mchecked.value = false;
            }
        });

        onBeforeMount(() => {
            initVal.value = _.cloneDeep(modelvalue.value);
        });

        onMounted(() => {

        })

        return {
            modelvalue,
            mchecked,
            fieldIsDisabled,
            checkboxId,
            fieldAttributeValue,
            initVal,
            fieldId,
            shouldBeChecked,
            updateInput,
        }

    },




}
</script>

<style scoped>

</style>
