<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Error404"
})
</script>

<template>
    <div class="shadow-2xl mx-auto my-auto p-8 bg-red-600 text-white rounded">
        <h1 class="text-2xl text-center font-bold">Seite nicht gefunden</h1>
        <p class="mt-3">Die von Ihnen aufgerufene Seite existiert nicht!</p>
    </div>
</template>

<style scoped>

</style>
