<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">
        <button class="btn btn-primary"
                @click="handleCreate"
                type="button"
                v-if="canCreateUser">
            <svg-icon name="plus"/>
            AMS eintragen
        </button>
    </toolbar>

    <data-table v-if="grid" ref="gridata"
                :field-components="fieldComponents"
                :base-data="grid"
                :toolbar="$refs.toolbar"
                @selected-count="(v) => { selectedTrashCount = v }"
                @delete="handleDelete"
                @loaded="onLoaded">

        <template v-slot:apo_number="{row}">
            <template v-if="row.apo_number">
                <div class="label">
                    <div>{{ row.apo_number }}</div>
                    <div v-if="Array.isArray(row.clinicalparams)">
                        <template v-for="rs in row.clinicalparams">
                            <div>
                                <span class="size">{{ rs.size }}</span>
                                <span class="laboratory_value">{{ rs.laboratory_value ?? '-' }}</span>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </template>


        <template v-slot:created_at="{row}">
            <created-updated-by-at v-if="row.created_at" :date="row.created_at" :user="row.createdby" type="created"/>
        </template>

        <template v-slot:updated_at="{row}">
            <created-updated-by-at v-if="row.updated_at" :date="row.updated_at" :user="row.updatedby" type="updated"/>
        </template>

    </data-table>
</template>

<script>
import {computed, defineComponent, getCurrentInstance, inject, nextTick} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {getDataTableCacheForFilter, useDatatable} from "@/mixins/data-table-mixin";

    export default defineComponent({
        name: "amsIndex",
        setup(props, context) {
            const inst = getCurrentInstance();
            const $root = inst.root.ctx
            const $http = inject('$http');
            const $events = inject('$events');
            const route = useRoute();
            const router = useRouter()
            const store = useStore();
            const requestAction = inject('requestAction')
            const setPropertyPath = inject('setPropertyPath');
            const changeBreadcrumb = inject('changeBreadcrumb')

            const $moment = inject('$moment')
            const {
                hasTrashed,
                viewTrash,
                selectedTrashCount,
                cleaning,
                grid,
                toolbarData,
                tempRoute,
                fieldComponents,
                handleClearGridFilter,
                handleGridFilter,
                refreshGrid,
                back
            } = useDatatable(props, context, inst);

            const canCreateUser = computed(() => {
                return true
                // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
            })

            loadData();


            function loadData() {
                let cache = getDataTableCacheForFilter(route.path);

                let postData = {};
                if (cache && cache.grid) {
                    postData = cache.grid;
                    if (cache.grid.currentFilter) {
                        postData = Object.assign(postData, cache.grid.currentFilter);
                    }
                }


                $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                    if (!r.data.grid.url) {
                        r.data.grid.url = $root.currentRouteUrl(route.path);
                    }

                    hasTrashed.value = r.data.hastrashed;
                    toolbarData.value = r.data.grid.filter;
                    grid.value = r.data.grid;

                    changeBreadcrumb(r.data)

                    nextTick(() => {
                        $events.$emit('pageloading', false);
                    })

                }).catch((e) => {
                    console.warn(e);
                    nextTick(() => {
                        $events.$emit('pageloading', false);
                    })
                });
            }


            function onLoaded(data) {
                changeBreadcrumb(data)
            }

            function handleCreate() {
                router.push({name: 'admin.ams.create'})
            }

            function handleDelete(row, btn, callback) {

                if (btn.url) {
                    callback();

                    $events.$emit('pageloading', true);
                    $http.post(btn.url).then((r) => {
                        $events.$emit('pageloading', false);

                        $root.notification(r.data);

                        if (r.data.success) {
                            refreshGrid();
                        }
                    }).catch((e) => {
                        callback();

                        $events.$emit('pageloading', false);
                    });
                }
            }

            function formatDate(v, format = 'DD.MM.YYYY') {
                return v ? $moment(v).format(format) : null;
            }


            return {
                grid,
                cleaning,
                toolbarData,
                fieldComponents,
                canCreateUser,
                selectedTrashCount,
                back,
                onLoaded,
                handleGridFilter,
                handleClearGridFilter,
                handleCreate,
                handleDelete,

                formatDate
            }
        }
    })
</script>

<style scoped>

</style>
