<template>
    <modal :show="show"
           :use-footer="false"
           :use-toolbar="true"
           :dirty="isDirty"
           :saving="formsaving"
           @close="$emit('close')"
           @submit="sendMessage"
           @cancel="onCancel"
           @reset="resetMessage"
           ok-btn-label="Nachricht senden"
           width="90%"
           height="96%"
           modal-class="reply-messages"
    >
        <template v-slot:title>
            <template v-if="loading || !show">Loading Message ...</template>
            <template v-else>
                <template v-if="model && show">
                    Nachricht "{{ currentMessage.subject }}" beantworten
                </template>
            </template>
        </template>


        <template v-slot:tabs>
            <ul class="nav-tabs">
                <li class="nav-item"
                    :class="{ active: currentTab === 'basic' }"
                    @click.prevent="currentTab = 'basic'">Nachricht</li>
<!--                <li class="nav-item"-->
<!--                    :class="{ active: currentTab === 'history' }"-->
<!--                    @click.prevent="currentTab = 'history'">Nachrichtenverlauf</li>-->
            </ul>
        </template>

        <template v-slot:body>

            <template v-if="loading">
                <loading :active.sync="loading"
                         color="blue"
                         :width="40"
                         :height="40"
                         :opacity="0.5"
                         :is-full-page="false"></loading>
            </template>


            <template v-if="!loading && model && show">
                <div class="tab-content">
                    <div class="tab-pane" :class="{ active: currentTab === 'basic' }">
                        <div class="message-container" :class="{answerfocus: answerFocus}">
                            <div class="message-view-container">
                                <div class="message-toolbar">
                                    <template v-if="mutatedViewMessage.message">
                                        <div class="message-meta">
                                            <div class="message-fromusers"
                                                 v-if="mutatedViewMessage.fromuser">
                                                <b>Von:</b>
                                                <div>
                                                    <template v-if="mutatedViewMessage.fromuser.people">
                                                        {{ mutatedViewMessage.fromuser.people.first_name }} {{ mutatedViewMessage.fromuser.people.surname }}
                                                    </template>
                                                    <template v-else>{{ mutatedViewMessage.fromuser.username }}</template>
                                                </div>
                                            </div>
                                            <template v-else>
                                                <div class="message-fromusers" v-if="mutatedViewMessage.frompeoplename || mutatedViewMessage.message">
                                                    <b>Von:</b>
                                                    <div>
                                                        <template v-if="mutatedViewMessage.frompeoplename">{{ mutatedViewMessage.frompeoplename }}</template>
                                                        <template v-else>{{ mutatedViewMessage.message.frompeoplename }}</template>
                                                    </div>
                                                </div>
                                            </template>


                                            <div class="message-tousers" v-if="mutatedViewMessage.touserslist">
                                                <b>An:</b>
                                                <div>
                                                    <ul class="list-unstyled">
                                                        <template v-for="u in mutatedViewMessage.touserslist">
                                                            <li v-if="u.people">
                                                                {{ u.people.first_name }} {{ u.people.surname }}
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div>
                                                <b>Datum:</b>
                                                <div>{{ formatDate(mutatedViewMessage.message.created_at) }}</div>
                                            </div>
                                        </div>

                                        <span class="fa fa-close"
                                              v-tooltip="'zurück zur aktuellen Nachricht'"
                                              v-if="mutatedViewMessage.id !== mutatedMessage.id"
                                              @click="closeViewMessage"></span>
                                    </template>


                                </div>
                                <div class="message" ref="message">

                                    <div class="message-subject">
                                        <template v-if="mutatedViewMessage && mutatedViewMessage.message && mutatedViewMessage.message.subject">
                                            {{ mutatedViewMessage.message.subject }}
                                        </template>
                                    </div>
                                    <div>
                                        <div v-html="displayContent"></div>
                                        <template v-if="displayParents && displayParents.length">
                                            <div class="parent-messages-list">
                                                <template v-for="r in displayParents">
                                                    <div class="item"
                                                         :style="{marginLeft: (r.level ? (20 * r.level) : 0) +'px'}">
                                                        <div class="itm-arrow">
                                                            <span></span>
                                                        </div>
                                                        <div class="itm">

                                                            <div class="s">{{ r.subject }}</div>
                                                            <div class="f">
                                                                <template v-if="r.from && r.from.username">
                                                                    <template v-if="r.from.people">
                                                                        {{ r.from.people.first_name }} {{ r.from.people.surname }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ r.from.username }}
                                                                    </template>
                                                                </template>, {{ formatDate(r.date) }}
                                                            </div>
                                                            <div class="m" v-html="formatDisplayMessage(r.content)"></div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <template v-if="previewHasAttachments">
                                    <div class="attachments">
                                        <ul>
                                            <template v-for="a in mutatedViewMessage.attachments">
                                                <li v-if="a && a.file">
                                                    <div @click="download(a.file)">
                                                        <div>{{ a.file.filename }}</div>
                                                        <div>
                                                            <span class="badge badge-dark">{{ formatFilesize(a.file.size) }}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </template>
                            </div>



                            <div class="message-answer-container">
                                <div class="header">Antworten</div>

                                <form-layout-content :fields="formFields"
                                                     :form-model="answer"
                                                     :responseerrors="errors"
                                                     :rendersections="{}">
                                </form-layout-content>

                                <loading :active.sync="formsaving"
                                         color="blue"
                                         :width="60"
                                         :height="60"
                                         :opacity="0.6"
                                         :is-full-page="false"></loading>
                            </div>


                            <loading :active.sync="loading"
                                     color="blue"
                                     :width="60"
                                     :height="60"
                                     :opacity="0.6"
                                     :is-full-page="false"></loading>
                        </div>
                    </div>
<!--                    <div class="tab-pane" :class="{ active: currentTab === 'history' }">-->
<!--                        <message-history :last-sended-messages="lastSendedMessages" :sorted-history="sortedHistory"/>-->
<!--                    </div>-->
                </div>
            </template>
        </template>
    </modal>
</template>

<script>

const prismLangs = [
    'markup',
    'markup-templating',
    'clike',
    'bash',
    'css',
    'javascript',
    'php',
    'sql',
    'xml',
    'markdown',
];
import marked from "marked";
import {convertToFormData} from "neloh-ui/mixins/formdata";
import {toRaw, reactive, watch, ref, computed, nextTick, inject, getCurrentInstance, onMounted, onBeforeUnmount, onBeforeMount} from 'vue'
import {useRoute, useRouter} from "~/vue-router";
import {useFormDefaults} from "neloh-ui/mixins/use-form";
import {useStore} from "vuex";


import '@/directives/PrismLineNumbers';


export default {
    name: "ViewMessageModal",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        currentMessage: {
            type: Object,
            default: {}
        },
        replyType: {
            type: String,
            default: 'view'
        }
    },

    emits: ['success', 'close'],

    setup(props, context) {
        const inst = getCurrentInstance()
        const $root = inst.root.ctx;
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const currentMessage = ref({});
        const uploadFiles = ref([]);
        const peoples = ref([]);
        const mutatedHistory = ref([]);

        const currentTab = ref('basic')

        const mutatedMessage = ref({
            subject: '',
            message: '',
        });

        const answer = ref({
            tousers: [],
            content: '',
            subject: '',
            attachments: []
        });

        const mutatedViewMessage = ref({
            subject: '',
            message: {
                subject: '',
                message: '',
                created_at: null,
            },
            attachments: []
        });


        const displayContent = ref('');
        const displayParents = ref([])
        const mutatedContent = ref('');
        const lastSendedMessages = ref([]);
        const answerFocus = ref(false);
        const viewMode = ref(null);
        const sending = ref(false);

        const $moment = inject('$moment')
        const $events = inject('$events');
        const $http = inject('$http');
        const routes = inject('routes');
        const wait = inject('wait');

        const {
            form,
            modelKey,
            isMounted,
            loading,
            formsaving,
            rendersections,
            primaryKey,
            defaultFormUrl,
            model,
            errors,
            toolbar,
            isDirty,
            isCreate,
            saveAndNew,
            loadFormWithParams,
            loadFormWithRouteParams,
            resetDirty,
            makeDirty,
            formUrl,
            store_route,
            update_route,
            formTabs,
            formFields,
            primaryKeyValue,
            isUpdate,
            loadForm,
            saveForm,
            updatePrimaryKey,
            formValidationErrors,
            setFormErrors,
            formLoaded,
            onReset,
            onCancel,
            onBeforeSave,
            onAfterSaved,
            onSavedAndNew,
            handleSaveAndNew,
            backToDataTable,
            bindAutosaveEvents,
            executeLiveSave,
        } = useFormDefaults(props, context);


        watch(() => props.show, (n, o) => {
            if (n) {
                loadMessage();
                $events.$emit('pageloading', false);
            }
            else {

                currentTab.value = 'basic'


                nextTick(() => {
                    store.dispatch('form/clearForm')
                })
            }
        });

        const sortedHistory = computed(() => {
            return _.sortBy(mutatedHistory.value, ['created_at']).reverse();
        });

        const canSend = computed(() => {
            return answer.value.content && answer.value.content.trim() !== '' && numSelectedPeoples.value > 0
        });

        const numSelectedPeoples = computed(() => {
            if (!_.isArray(answer.value.tousers)) return 0;
            return answer.value.tousers.length;
        });

        const previewHasAttachments = computed(() => {
            if (_.isObject(mutatedViewMessage.value.attachments) && !_.isArray(mutatedViewMessage.value.attachments)) {
                return Object.keys(mutatedViewMessage.value.attachments).length > 0;
            }
            if (_.isArray(mutatedViewMessage.value.attachments)) {
                return mutatedViewMessage.value.attachments.length > 0
            }
            return false
        });








        onBeforeMount(() => {
            prepareMessage(model.value);

            nextTick(() => {
                if (inst.refs.message) {
                    inst.refs.message.querySelectorAll('pre').forEach(el => {
                        if (!el.classList.contains('line-numbers')) {
                            el.classList.add('line-numbers')
                        }
                    });


                    inst.refs.message.querySelectorAll('a').forEach(el => {
                        if (!el.getAttribute('target')) {
                            el.setAttribute('target', '_blank')
                        }
                    });

                    Prism.highlightAllUnder(inst.refs.message)
                }


            });
        });

        onMounted(() => {
            // watch(() => answer.value, () => {
            //     isDirty.value = true;
            // }, {deep: true});
        })

        _.each(prismLangs, (language) => {
            if (!Prism.languages[language]) {
                require(`prismjs/components/prism-${language}`)
            }
        });




        function ajaxLoaded(response) {

            if (response.hasOwnProperty('peoples')) {
                let sorted = [];

                const orderedArray = _.chain(response.peoples)
                    .toPairs()
                    .map(([key, ...obj]) => ({
                        key,
                        ...obj[0],
                        first_name: obj[0].people.first_name,
                        surname: obj[0].people.surname
                    }))
                    .orderBy(['surname', 'first_name'], ['asc', 'asc'])
                    .map(({surname, ...obj}) => obj)
                    .value()


                peoples.value = orderedArray;
            }

            if (response.hasOwnProperty('model')) {
                let addToModel = {};

                if (!response.model.hasOwnProperty('attachments')) {
                    addToModel.attachments = reactive([]);
                }

                if (!response.model.hasOwnProperty('tousers')) {
                    addToModel.tousers = reactive([]);
                }

                if (Object.keys(addToModel).length) {
                    store.dispatch('addFormModelData', {model: addToModel, status: false}).then((r, c) => {
                        if (r[1].formModels.hasOwnProperty( modelKey.value )) {
                            model.value = r[1].formModels[modelKey.value];
                        }
                    });
                    store.dispatch('form/syncFormOriginalModel', modelKey.value)
                }
            }

            nextTick(() => {
                resetDirty();
                $events.$emit('pageloading', false);
            });
        }

        function onMyReset() {
            onReset();
            let addToModel = {};
            addToModel.attachments = reactive([]);
            addToModel.tousers = reactive([]);
            store.dispatch('form/addFormModelData', {model: addToModel});
            uploadFiles.value = [];
        }

        function isSelectedPeople(user) {
            if (!user) return false;

            if (!answer.value.tousers) {
                return false
            }
            return answer.value.tousers.indexOf(user.people_id) !== -1;
        }

        function toggleSelection(user) {

            if (isSelectedPeople(user)) {
                answer.value.tousers = _.filter(_.cloneDeep(answer.value.tousers), (item) => {
                    return item != user.people_id;
                })
            } else {
                answer.value.tousers.push(user.people_id);
            }

            makeDirty()
        }

        function removeSelectedPeople(user) {
            if (isSelectedPeople(user)) {
                answer.value.tousers = _.filter(_.cloneDeep(answer.value.tousers), (item) => {
                    return item != user.people_id;
                });

                makeDirty()
            }
        }

        function changeUploadFile(fieldname, uploadfile) {
            let field = inst.vnode.el.querySelector('[name="' + fieldname + '"]');
            if (field) {
                uploadFiles.value.push(uploadfile);
                makeDirty();
            }
        }

        function addAttachment() {

            if (!answer.value.attachments) answer.value.attachments = []

            answer.value.attachments.push({
                attachment: {
                    name: null,
                    lastModified: null,
                    lastModifiedDate: null,
                    webkitRelativePath: null,
                    size: null,
                    type: null,
                    fileData: null,
                }
            });
            makeDirty()
        }

        function removeAttachment(index) {

            if (!answer.value.attachments) return;

            let a = toRaw(_.cloneDeep(answer.value.attachments));
            let newArr = a.splice(index, 1);


            answer.value.attachments = a;

            makeDirty()
        }


        function setUpload(data, index) {
            answer.value.attachments[index].attachment = {
                fieldname: data.fieldname,
                name: data.name,
                lastModified: data.lastModified,
                lastModifiedDate: data.lastModifiedDate,
                webkitRelativePath: data.webkitRelativePath,
                size: data.size,
                type: data.type,
                fileData: data.fileData
            }

            makeDirty();
        }



        function closeViewMessage() {
            prepareMessage();

            nextTick(() => {
                if (inst.refs.message) {
                    inst.refs.message.querySelectorAll('pre').forEach(el => {
                        if (!el.classList.contains('line-numbers')) {
                            el.classList.add('line-numbers')
                        }
                    });


                    inst.refs.message.querySelectorAll('a').forEach(el => {
                        if (!el.getAttribute('target')) {
                            el.setAttribute('target', '_blank')
                        }
                    });

                    Prism.highlightAllUnder(inst.refs.message)
                }
            });
        }


        function viewMessage(item, historyMode) {
            viewMode.value = (historyMode ? 'history' : null);

            if (item.hasOwnProperty('content')) {
                displayContent.value = item.content ? formatDisplayMessage(item.content, item) : '';

                mutatedViewMessage.value = Object.assign(item, {message: item});
            } else {

                displayContent.value = item.message ? formatDisplayMessage(item.message.content, item) : '';


                mutatedViewMessage.value = item;
            }

            nextTick(() => {
                if (inst.refs.message) {
                    inst.refs.message.querySelectorAll('pre').forEach(el => {
                        if (!el.classList.contains('line-numbers')) {
                            el.classList.add('line-numbers')
                        }
                    });


                    inst.refs.message.querySelectorAll('a').forEach(el => {
                        if (!el.getAttribute('target')) {
                            el.setAttribute('target', '_blank')
                        }
                    });


                    Prism.highlightAllUnder(inst.refs.message);
                }
            });
        }

        function formatDate(value) {
            return value ? $moment(value).format('DD.MM.YYYY, HH:mm') : ''
        }


        function prepareMessage() {
            let clone = _.cloneDeep(currentMessage.value);

            mutatedHistory.value = _.cloneDeep(clone.all);
            lastSendedMessages.value = _.cloneDeep(clone.last_sended_messages);

            delete clone.all;

            mutatedMessage.value = clone;

            mutatedViewMessage.value = _.cloneDeep(mutatedMessage.value);

            displayContent.value = mutatedViewMessage.value.message ? formatDisplayMessage(mutatedMessage.value.message.content, mutatedMessage.value) : '';

            displayParents.value = Array.isArray(mutatedViewMessage.value.parents) ? mutatedViewMessage.value.parents : [];
        }


        function formatDisplayMessage(text, item) {
            text = _.isString(text) ? text.trim() : '';

            let lines = text.split("\n");
            let formated = [];
            let foundSeparator = false;

            _.each(lines, (line, index) => {
                if (foundSeparator === false && line.match(/^\s*\-{6,}\s*$/g)) {
                    foundSeparator = index
                }
            });

            _.each(lines, (line, index) => {
                if (foundSeparator !== false && index < foundSeparator ) {
                    formated.push(line);
                }
                else if(foundSeparator === false) {
                    formated.push(line);
                }
            });

            return "\n" + marked(formated.join("\n")) +"\n"
        }


        /**
         *
         * @param parents
         */
        function formatParentMessages(parents) {
            let allParents = [];
            _.each(parents, (row) => {
                allParents.push( formatDisplayMessage(row.content) );
            });

            return "\n" + allParents.join("\n") +"\n"
        }



        function prepareReplyMessage(content) {
            if (content)
            {
                return '';


                let m = _.clone(content);
                let lines = m.split("\n");
                let formated = [];
                // let foundSeparator = false;
                //
                // _.each(lines, (line, index) => {
                //     if (foundSeparator === false && line.match(/^\s*\-{4,}\s*$/g)) {
                //         foundSeparator = index
                //     }
                // });
                //
                // _.each(lines, (line, index) => {
                //     if (foundSeparator !== false && index < foundSeparator ) {
                //         formated.push(line);
                //     }
                //     else if(foundSeparator === false) {
                //         formated.push(line);
                //     }
                // });


                if (lines.length > 0) {
                    formated.push('');
                    formated.push('-------------------------------------------');
                    formated.push('> ');
                }

                _.each(lines, (line) => {
                    formated.push('> '+ line)
                });

                return "\n" + formated.join("\n") +"\n"
            }

            return content;
        }

        function loadMessage() {
            sending.value = false;
            loading.value = true;


            $http.get($root.routes('admin.messenger.view', {message: props.currentMessage.id})).then((r) => {
                loading.value = false;
                let message = r.data.message;
                message.tousers = [];

                if (r.data.to_all_users)
                {
                    // Allen Antworten
                    _.each(r.data.to_all_users, (id) => {
                        message.tousers.push(id);
                    });

                    // Benutzer auswählen, von welchem die Message kam (Antwort)
                    if (message.hasOwnProperty('from_user') && message.from_user != $root.user.userid) {
                        message.tousers.push(message.from_user);
                    }
                } else {
                    // Benutzer auswählen, von welchem die Message kam (Antwort)
                    if (message.hasOwnProperty('from_user') && message.from_user != $root.user.userid) {
                        message.tousers.push(message.from_user);
                    }
                }

                currentMessage.value = message

                prepareMessage();

                answer.value.subject = mutatedViewMessage.value.message ? 'RE: ' + mutatedViewMessage.value.message.subject : null;

                answer.value.content = mutatedViewMessage.value.message ? prepareReplyMessage(mutatedViewMessage.value.message.content) : null;


                if (message.tousers.length) {
                    answer.value.tousers = message.tousers
                }

                if (_.isObject(r.data.model))
                {
                    let clonedMessage = _.cloneDeep(r.data.model);
                    if (clonedMessage.hasOwnProperty('attachments')) {
                        clonedMessage.attachments = []
                    }

                    clonedMessage = Object.assign(clonedMessage, answer.value)

                    store.dispatch('form/setFormModel', Object.assign({}, clonedMessage))

                    model.value = Object.assign({}, clonedMessage);
                }

                if (_.isObject(r.data.toolbar)) {
                    store.dispatch('form/setFormToolbar', r.data.toolbar)
                }

                if (_.isObject(r.data.form)) {

                    // Prepare FormTabs
                    if (r.data.form.hasOwnProperty('formdefinition') &&
                        r.data.form.formdefinition &&
                        r.data.form.formdefinition.hasOwnProperty('tabs') &&
                        _.isObject(r.data.form.formdefinition.tabs) && Object.keys(r.data.form.formdefinition.tabs).length) {


                        let x = 0, tabs = r.data.form.formdefinition.tabs;
                        _.each(tabs, (t) => {
                            if (x === 0 && !t.hasOwnProperty('default_tab')) {
                                t.default_tab = true;
                            }

                            x++;
                        });

                        store.dispatch('form/setFormTabs', r.data.form.formdefinition.tabs);
                    }

                    // Prepare FormFields
                    if (r.data.form.hasOwnProperty('formdefinition') &&
                        r.data.form.formdefinition &&
                        r.data.form.formdefinition.hasOwnProperty('fields') &&
                        _.isObject(r.data.form.formdefinition.fields) && Object.keys(r.data.form.formdefinition.fields).length) {
                        store.dispatch('form/setFormFields', r.data.form.formdefinition.fields);
                    }

                    store.dispatch('form/setFormDefinition', _.cloneDeep(r.data.form));
                }

                if (r.data.hasOwnProperty('pagetitle') && r.data.pagetitle) {
                    $root.setPageTitle(r.data.pagetitle)
                }

                if (r.data.hasOwnProperty('formurls') && _.isObject(r.data.formurls)) {
                    if (r.data.formurls.hasOwnProperty('store')) {
                        store_route.value = r.data.formurls.store;
                    }
                    if (r.data.formurls.hasOwnProperty('update')) {
                        update_route.value = r.data.formurls.update;
                    }
                }

                nextTick(() => {
                    resetDirty();
                    loading.value = false;

                    if (inst.refs.message) {
                        inst.refs.message.querySelectorAll('pre').forEach(el => {
                            if (!el.classList.contains('line-numbers')) {
                                el.classList.add('line-numbers')
                            }
                        })
                        Prism.highlightAllUnder(inst.refs.message)
                    }
                });

                $events.$emit('pageloading', false);
            }).catch((e) => {
                loading.value = false;
                console.warn(e);
                $events.$emit('pageloading', false);
            });
        }


        function download(attachment) {
            window.open(attachment.download_url);
        }

        function sendMessage() {

            if (formsaving.value || !canSend.value) {
                return;
            }

            /*
              Initialize the form data
            */
            const postData = {
                answer: answer.value.content,
                subject: answer.value.subject,
                answer_to: answer.value.tousers
            };

            let formData = new FormData();
            formData = convertToFormData(postData, formData);

            /*
              Iteate over any file sent over appending the files
              to the form data.
            */
            for (let i = 0; i < answer.value.attachments.length; i++) {
                formData.append('attachments[' + i + ']', answer.value.attachments[i].attachment.fileData);
            }


            formsaving.value = true;

            $http.post($root.routes('admin.messenger.answer', {message: mutatedMessage.value.id}), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((r) => {

                formsaving.value = false;

                if (r.data.success) {
                    onAfterSaved(r.data);

                    context.emit('success');

                } else {
                    if (r.data.errors) {
                        if (!r.data.error && !r.data.msg) {
                            $root.notify('Das Formular konnte nicht gespeichert werden!', 'error');
                        }
                        setFormErrors(r.data);
                    }
                }

            }).catch((e) => {
                formsaving.value = false;
                if (e.response.data) {
                    setFormErrors(e.response.data);
                }
            });
        }


        function resetMessage() {

        }


        function cancel() {
            store.dispatch('form/resetFormModel');
            context.emit('close');
        }


        return {
            model,
            currentTab,
            formFields,
            form,
            errors,
            isDirty,
            uploadFiles,
            peoples,
            formsaving,
            loading,
            answerFocus,
            answer,

            lastSendedMessages,


            mutatedMessage,
            displayContent,
            displayParents,
            mutatedViewMessage,
            mutatedHistory,

            previewHasAttachments,
            sortedHistory,
            canSend,
            numSelectedPeoples,
            viewMode,

            formatDate,
            formatDisplayMessage,
            viewMessage,
            closeViewMessage,
            makeDirty,
            onCancel: cancel,
            onReset,
            toggleSelection,
            isSelectedPeople,
            removeSelectedPeople,
            changeUploadFile,

            setUpload,
            addAttachment,
            removeAttachment,
            sendMessage,
            download,
            resetMessage,
            formatFilesize: inject('formatFilesize')
        }
    }
}
</script>

<style scoped>

</style>
