<template>
    <div class="form-label">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "field-label"
    }
</script>

<style scoped>

</style>