<template>
	<transition name="fade" appear>
		<div role="dialog"
		     aria-labelledby="exampleModalLabel"
		     aria-hidden="true"
		     :class="['modal fade', {in: isOpen, show: isOpen, 'modal-open': isOpen, 'with-toolbar':useToolbar}]"
		     v-show="isOpen"
		     :style="getStyle"
		     @mousedown="mouseDown"
		     ref="modal">
			<div ref="modaldialog" class="modal-dialog" role="document" :class="getClass" :style="modalsize">
				<transition name="slideDown" appear>
					<div v-show="isOpen" ref="popupwrapper"
					     class="modal-content"
					     :style="contentsize">

						<loading :active.sync="saving"
						         color="blue"
						         :width="40"
						         :height="40"
						         :opacity="0.5"
						         :is-full-page="false"></loading>

						<div ref="popupheader" class="modal-header"
						     @dblclick="toggleFullSize">
							<h5 class="modal-title">
								<template v-if="hasTitle">
									<div v-html="title"></div>
								</template>
								<slot v-else name="title"></slot>
							</h5>
							<a class="close" aria-label="Close"
							   v-tooltip="'Schließen'"
							   @click.stop="handleCancel">
								<span aria-hidden="true">×</span>
							</a>
						</div>

						<div v-if="useToolbar" ref="popuptoolbar" class="modal-toolbar">
							<div class="c">
								<div class="btn-group">
									<button v-if="showCancel"
									        type="button"
									        class="btn btn-primary"
									        @click="handleCancel"
									        data-dismiss="modal">
										<span v-html="cancelBtnLabel ? cancelBtnLabel : 'Abbrechen'"></span>
									</button>

									<template v-if="watchDirty">
										<button v-if="showReset"
										        type="button"
										        class="btn btn-primary"
										        @click="handleReset"
										        :disabled="isDirty !== true">
											<span v-html="resetBtnLabel ? resetBtnLabel : 'Reset'"></span>
										</button>

										<action-button
											v-if="showOk && isOpen"
											:loading="saving"
											@clicked="handleSubmit"
											:disabled="!isDirty && watchDirty">
											<span v-html="okBtnLabel ? okBtnLabel : 'Ok'"></span>
										</action-button>

									</template>
									<template v-else>
										<action-button
											v-if="showOk && isOpen"
											:loading="saving"
											@clicked="handleSubmit"
											:disabled="!isDirty && watchDirty"
										>
											<span v-html="okBtnLabel ? okBtnLabel : 'Ok'"></span>
										</action-button>
									</template>
								</div>

							</div>
							<slot name="toolbar"></slot>
						</div>
						<div v-if="$slots.tabs" ref="popuptabs" class="modal-tabs">
							<slot name="tabs"></slot>
						</div>

						<div ref="popupcontent"
						     class="modal-body" :class="bodyClass"
						     :style="{overflowY: overflow ? overflow : (scrollable ? 'auto' : null)}">
							<div v-if="hasContent" v-html="content"></div>
							<slot v-else name="body"></slot>
						</div>

						<div ref="popupfooter" class="modal-footer" v-if="useFooter">
							<slot name="footer"></slot>
							<button v-if="showCancel"
							        type="button"
							        class="btn btn-secondary float-left"
							        @click="handleCancel"
							        data-dismiss="modal"
							>
								<span v-html="cancelBtnLabel ? cancelBtnLabel : 'Schließen'"></span>
							</button>

							<template v-if="watchDirty">
								<action-button
									v-if="showOk && isOpen"
									:loading="saving"
									@clicked="handleSubmit"
								>
									<span v-html="okBtnLabel ? okBtnLabel : 'Ok'"></span>
								</action-button>


							</template>
							<template v-else>
								<action-button
									v-if="showOk && isOpen"
									:loading="saving"
									@clicked="handleSubmit"
								>
									<span v-html="okBtnLabel ? okBtnLabel : 'Ok'"></span>
								</action-button>
							</template>

						</div>
					</div>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>
const modals = {
	count: 0
};
import {ref, computed, nextTick, inject, watch, getCurrentInstance, onBeforeUnmount, onMounted} from "vue";
import {mixin as VueClickAway} from '@/vendor/vue-click-away'

export default {
	name: "modal",
	mixins: [VueClickAway],
	props: {
		show: {
			type: Boolean,
			required: false
		},
		useFooter: {
			type: Boolean,
			default() {
				return true;
			}
		},
		useToolbar: {
			type: Boolean,
			default() {
				return false;
			}
		},
		title: {
			type: String,
			default() {
				return '';
			}
		},
		content: {
			type: String,
			default() {
				return '';
			}
		},

		showOk: {
			type: Boolean,
			default() {
				return true;
			}
		},
		showReset: {
			type: Boolean,
			default() {
				return false;
			}
		},

		showCancel: {
			type: Boolean,
			default() {
				return true;
			}
		},
		cancelBtnLabel: {
			type: String,
			default() {
				return 'Schließen';
			}
		},
		okBtnLabel: {
			type: String,
			default() {
				return 'Ok';
			}
		},
		resetBtnLabel: {
			type: String,
			default() {
				return 'Zurücksetzen';
			}
		},
		width: {
			type: String,
			default() {
				return '30%'
			}
		},
		height: {
			type: String,
		},
		closeOnClickOut: {
			type: Boolean,
			default() {
				return true
			},
		},
		scrollable: {
			type: Boolean,
			default() {
				return true
			},
		},
		draggable: {
			type: [Boolean, String],
			default() {
				return false
			},
		},
		resizable: {
			type: Boolean,
			default() {
				return false
			},
		},
		minHeight: {
			type: String,
			default() {
				return '200px'
			}
		},
		minWidth: {
			type: String,
			default() {
				return '370px'
			}
		},
		transition: {
			type: String,
			default: 'translate-fade'
		},

		modalClass: {
			type: String,
			default: ''
		},

		watchDirty: {
			type: Boolean,
			default() {
				return false
			},
		},
		dirty: {
			type: Boolean,
			default() {
				return false
			},
		},

		saving: {
			type: Boolean,
			default() {
				return false
			},
		},

		overflow: {
			type: String,
			default() {
				return ''
			},
		},

		duration: {
			default() {
				return 300;
			}
		},

		bodyClass: {
			type: [String, Object],
			default() {
				return ''
			},
		}
	},

	emits: ['show', 'close', 'confirm-close', 'cancel', 'reset', 'submit'],

	setup(props, context) {

		const inst = getCurrentInstance()

		let backdrop;
		const zIndex = ref(1000)
		const modals = ref(0)
		const openChildModal = ref(false)
		const resizing = ref(false)
		const modalInstance = ref(null)
		const isOpen = ref(false)
		const isDirty = ref(false)
		const isFullSize = ref(false)

		const shift = ref({
			left: 0,
			top: 0
		})

		const modalpos = ref({
			left: 0,
			top: 0
		})

		const modalcontentsize = ref({
			width: null,
			height: null,
			minWidth: null,
			minHeight: null
		})

		const modalsizes = ref({
			width: null,
			height: null,
			minWidth: null,
			minHeight: null
		});

		const totalModals = computed(() => {
			return modals.value;
		});

		const getStyle = computed(() => {
			let style = {}
			if (props.show)
				style.display = 'block'

			style['z-index'] = 1048 + zIndex.value * 2 + 1

			style.animationDuration = `${props.duration}ms`;

			return style
		})

		const getClass = computed(() => {
			let classes = []

			let idx = totalModals.value - zIndex.value

			classes.push('modal-stack-' + idx)
			classes.push('modal-order-' + zIndex.value)

			if (zIndex.value !== totalModals.value) {
				classes.push('aside')
			}

			if (_.isString(props.modalClass)) {
				classes.push(props.modalClass)
			} else if (_.isObject(props.modalClass)) {
				classes.push(props.modalClass)
			}
			return classes
		})


		const hasTitle = computed(() => {
			return props.title.trim() !== '';
		})

		const hasContent = computed(() => {
			return props.content.trim() !== '';
		})

		const contentsize = computed(() => {
			let size = {
				zIndex: zIndex.value
			};
			if (props.height) {
				size.height = props.height;
			}
			size.animationDuration = `${props.duration}ms`;

			return size;
		})

		const modalsize = computed(() => {
			let size = {};
			if (props.width) {
				size.width = props.width;
			}
			if (props.height) {
				size.height = props.height;
			}
			return size;
		})

		const modalHeaderHeight = computed(() => {
			if (!inst.refs.popupheader) return null;
			return inst.refs.popupheader.clientHeight;
		})

		const modalFooterHeight = computed(() => {
			if (!inst.refs.popupfooter) return null;
			return inst.refs.popupfooter.clientHeight;
		})


		watch(() => props.dirty, (n, o) => {
			if (props.watchDirty) {
				isDirty.value = n;
			}
		})

		watch(() => props.show, (n, o) => {
			if (!n) {
				if (modals.value === 0) {
					document.body.classList.remove('modal-open');
				}
				nextTick(() => {
					window.removeEventListener('mousemove', resize, false);
					window.removeEventListener('mouseup', stopResize, false);
					isOpen.value = false;
					checkBackdrop();
				});
			} else {
				modals.value++;
				zIndex.value = modals.value;
				isOpen.value = true;

				checkBackdrop();
				addDraggableListeners();
				bindResizable();


				nextTick(() => {
					initSize();
				});
			}
		})


		onBeforeUnmount(() => {
			if (props.show) {
				modals.value--;
				zIndex.value = modals.value;
				context.emit('show', false, zIndex.value, modals.value);
			}

			if (backdrop && props.show) {
				document.body.removeChild(backdrop);
			}

			if (modals.value === 0) {
				document.body.classList.remove('modal-open')
			}
		});

		onMounted(() => {
			document.querySelector('#app').appendChild(inst.refs.modal)

			if (props.show) {
				document.body.classList.add('modal-open')
				modals.value++
				zIndex.value = modals.value

				context.emit('show', true, zIndex.value, modals.value);
			}

			checkBackdrop();
		})


		function handleEscape(e) {
			if (props.show && e.keyCode === 27 && zIndex.value === totalModals.value) {
				context.emit('close')
			}
		}

		function checkBackdrop() {
			// if (!this.hasBackdrop)
			//     return

			if (props.show && zIndex.value === 1) {
				document.body.classList.add('modal-open')
			} else if (!props.show && zIndex.value === totalModals.value) {
				// enableScroll()
			}

			if (props.show) {
				backdrop = document.createElement('div')
				backdrop.classList.add('modal-backdrop', 'fade', 'in', 'show')
				backdrop.style.zIndex = 1048 + zIndex.value * 2;
				document.body.appendChild(backdrop)
			} else {
				if (backdrop) {
					document.body.removeChild(backdrop)
					document.body.classList.remove('modal-open')
				}
			}
		}

		function mouseDown(e) {
			if (inst.refs.modal === e.target) {
				e.preventDefault();
				e.stopImmediatePropagation();
			}
		}


		function nWinddowResizeModal() {
			if (inst.refs.popupcontent) {
				let h = null;
				if (!props.scrollable) {
					if (props.resizable) {

						if (!inst.refs.popupwrapper.style.height && props.height && parseInt(props.height)) {
							inst.refs.popupcontent.style.height = props.height;
						} else {
							inst.refs.popupcontent.style.height = '100%';
						}

						inst.refs.popupcontent.style.maxHeight = null;

						if (props.height && parseInt(props.height)) {
							inst.refs.popupcontent.style.minHeight = props.height;
						}
						return;
					} else {
						if (props.height && parseInt(props.height) > modalsizes.value.minHeight) {
							h = parseInt(props.height) ? props.height : modalsizes.value.minHeight + 'px';
						} else {
							h = props.height && parseInt(props.height) ? props.height : null;
						}
					}
				} else {
					if (props.resizable) {
						h = inst.refs.popupwrapper.clientHeight > modalsizes.value.minHeight ? '100%' : modalsizes.value.minHeight + 'px';
					}
				}

				if (modalsizes.value.minHeight > 0) {
					if (props.height && parseInt(props.height) > modalsizes.value.minHeight) {
						h = parseInt(props.height) ? props.height : modalsizes.value.minHeight + 'px';
					} else {
						h = props.height && parseInt(props.height) ? props.height : null;
					}
				} else {
					h = props.height && parseInt(props.height) ? props.height : null;
				}

				if (!h && props.scrollable) {
					if (props.height && parseInt(props.height)) {
						h = props.height;
					} else {
						let popupcontent_body = inst.refs.popupcontent_body;
						let innerHeight = $(popupcontent_body).innerHeight() + 10;
						h = innerHeight + 'px';
					}
				}

				inst.refs.popupcontent.style.height = h;
				inst.refs.popupcontent.style.maxHeight = null;
			}
		}

		function getDraggableElement() {

			const selector = typeof props.draggable !== 'string'
				? '.modal-content'
				: props.draggable;
			return selector
				? inst.refs.modal.querySelector(selector)
				: null
		}

		function addDraggableListeners() {
			if (!props.draggable || !inst.refs.modal || !_.isFunction(inst.refs.modal.querySelector)) {
				return
			}

			let dragger = getDraggableElement();


			if (dragger) {
				let startX = 0;
				let startY = 0;
				let cachedShiftX = 0;
				let cachedShiftY = 0;

				const getPosition = event => {
					return event.touches && event.touches.length > 0
						? event.touches[0]
						: event
				};


				const handleDraggableMousemove = event => {
					let {clientX, clientY} = getPosition(event);
					let newX = cachedShiftX + clientX - startX, newY = cachedShiftY + clientY - startY;

					shift.value.left = newX;
					shift.value.top = newY;

					inst.refs.popupwrapper.style.left = newX + 'px';
					inst.refs.popupwrapper.style.top = newY + 'px';

					event.preventDefault()
				};

				const handleDraggableMouseup = event => {
					document.removeEventListener('mousemove', handleDraggableMousemove)
					document.removeEventListener('touchmove', handleDraggableMousemove)
					document.removeEventListener('mouseup', handleDraggableMouseup)
					document.removeEventListener('touchend', handleDraggableMouseup)
					event.preventDefault();

					inst.refs.modal.classList.remove('dragging');

				};


				const handleDraggableMousedown = event => {
					let target = event.target;
					if (target && (
						target.nodeName === 'INPUT' ||
						target.nodeName === 'SELECT' ||
						target.nodeName === 'TEXTAREA'
					)
					) {
						return
					}

					if (!target.classList.contains('modal-title') && !target.classList.contains('modal-header') &&
						!target.parentNode.classList.contains('modal-header') &&
						!target.parentNode.parentNode.classList.contains('modal-header')) {
						return
					}

					inst.refs.modal.classList.add('dragging');

					let {clientX, clientY} = getPosition(event);

					document.addEventListener('mousemove', handleDraggableMousemove);
					document.addEventListener('touchmove', handleDraggableMousemove);
					document.addEventListener('mouseup', handleDraggableMouseup);
					document.addEventListener('touchend', handleDraggableMouseup);

					startX = clientX;
					startY = clientY;

					cachedShiftX = shift.value.left;
					cachedShiftY = shift.value.top;
				};

				dragger.addEventListener('mousedown', handleDraggableMousedown);
				dragger.addEventListener('touchstart', handleDraggableMousedown);
			}
		}

		function removeDraggableListeners() {
			/**
			 * Ideally this is not needed because "dragger" will be unmounted anyway.
			 */
		}


		function bindResizable() {

			if (!props.resizable || !inst.refs.modal) {
				return;
			}

			if (inst.refs.modal.querySelector('.modal-content > .resizer')) {
				return;
			}

			//create box in bottom-left
			let resizer = document.createElement('div');
			// resizer.style.width = '10px';
			// resizer.style.height = '10px';
			// resizer.style.background = 'transparent';
			resizer.style.position = 'absolute';
			resizer.style.right = 0;
			resizer.style.bottom = 0;
			resizer.style.cursor = 'se-resize';
			resizer.setAttribute('class', 'resizer');

			//Append Child to Element
			inst.refs.popupwrapper.appendChild(resizer);

			//box function onmousemove
			resizer.addEventListener('mousedown', initResize, false);
		}

		function initResize() {
			window.addEventListener('mousemove', resize, false);
			window.addEventListener('mouseup', stopResize, false);
		}

		function initSize() {
			modalsizes.value.width = inst.refs.popupwrapper.offsetWidth;
			modalsizes.value.height = inst.refs.popupwrapper.offsetHeight;

			shift.value.left = inst.refs.popupwrapper.offsetLeft;
			shift.value.top = inst.refs.popupwrapper.offsetTop;
		}

		function resize(e) {
			let w = e.pageX - inst.refs.popupwrapper.getBoundingClientRect().left;
			let h = e.pageY - inst.refs.popupwrapper.getBoundingClientRect().top;

			let minH = parseInt(props.minHeight);
			if (h > minH) {
				inst.refs.popupwrapper.style.height = h + 'px';
				inst.refs.popupwrapper.style.document = h + 'px';

				modalsizes.value.height = h;
			}

			let minW = parseInt(props.minWidth);
			if (w > minW) {
				inst.refs.popupwrapper.style.width = w + 'px';

				modalsizes.value.width = w;
			}
		}

		function stopResize() {
			window.removeEventListener('mousemove', resize, false);
			window.removeEventListener('mouseup', stopResize, false);
		}


		function toggleFullSize(e) {
			e.stopPropagation();

			if (!props.resizable) {
				return;
			}


			if (!isFullSize.value) {
				let left = inst.refs.popupwrapper.style.left, top = inst.refs.popupwrapper.style.top;
				if (!left) {
					left = inst.refs.modaldialog.offsetLeft;
				}
				if (!top) {
					top = inst.refs.modaldialog.offsetTop;
				}


				inst.refs.modal.classList.add('fullscreen');


				inst.refs.popupwrapper.oldleft = parseInt(left);
				inst.refs.popupwrapper.oldtop = parseInt(top);


				inst.refs.popupwrapper.style.left = '0px';
				inst.refs.popupwrapper.style.top = '0px';
				inst.refs.popupwrapper.style.width = '100%';
				inst.refs.popupwrapper.style.height = '100%';
				inst.refs.modaldialog.style.width = '100%';
				inst.refs.modaldialog.style.height = '100%';

				isFullSize.value = true;
			} else {
				inst.refs.modal.classList.remove('fullscreen');


				inst.refs.popupwrapper.style.left = inst.refs.popupwrapper.oldleft + 'px';
				inst.refs.popupwrapper.style.top = inst.refs.popupwrapper.oldtop + 'px';
				inst.refs.popupwrapper.style.width = modalsizes.value.width + 'px';
				inst.refs.popupwrapper.style.height = modalsizes.value.height + 'px';

				inst.refs.modaldialog.style.width = '';
				inst.refs.modaldialog.style.height = '';

				isFullSize.value = false;
			}

		}


		// function createModalInstance() {
		//     if (this.modalInstance) return;
		//
		//     if (!this.closeOnClickOut) {
		//         this.modalInstance = new Modal(this.$el, {backdrop: 'static'});
		//     } else {
		//         this.modalInstance = new Modal(this.$el);
		//     }
		// }

		function handleSubmit() {
			context.emit('submit');
		}

		function handleReset() {
			context.emit('reset');
		}

		function handleCancel(e) {

			if (isDirty.value && props.watchDirty) {
				context.emit('confirm-cancel', this);
			} else {
				e.preventDefault();
				context.emit('cancel');
			}
		}

		function close(e) {
			if (isDirty.value && props.watchDirty) {
				context.emit('confirm-close', this);
			} else {
				e.preventDefault();
				inst.refs.modal.classList.add('fade');
			}
		}

		function forceClose() {
			context.emit('close');
			isOpen.value = false;
		}

		function closeClickAway(e) {
			if (!isOpen.value) {
				return;
			}

			if (!props.closeOnClickOut || resizing.value || (isDirty.value && props.watchDirty)) return;


			let target = e.target;
			while (true) {

				if (!target) {
					break;
				}

				if (target.classList.contains('modal-content')) {
					return;
				}

				if (target.tagName === 'BODY') {
					break;
				}

				target = target.parentElement;
			}


			context.emit('close');
			isOpen.value = false;
		}


		return {
			isDirty,
			isFullSize,
			isOpen,
			getStyle,
			modalsize,
			getClass,
			contentsize,
			hasTitle,
			hasContent,
			toggleFullSize,
			mouseDown,
			handleCancel,
			handleReset,
			handleSubmit,


		}


	}
}
</script>

<style scoped>

</style>
