<template>
    <div :class="formatFieldClass" :id="fieldIDContainer">
        <div v-if="isColumns" class="form-field" :class="field.classes">
            <div class="form-label"
                 :class="{'is-minimizable': field.minimizable, minimized: minimized }"
                 v-if="field.hasOwnProperty('label') && field.label"
                 @click.prevent="handleMinimize">


                <svg-icon name="chevron-down" v-if="field.minimizable"/>


                <template v-if="field.type !== 'checkboxlist' && field.type !== 'radiolist' && field.type !== 'radio' && field.type !== 'ckeckbox'">
                    <label :for="fieldID" @click.prevent="focusField">
                        <span v-html="field.label"></span>
                        <span v-if="field.extralabel" class="extralabel" v-html="field.extralabel"></span>
                    </label>
                </template>
                <template v-else>
                    <span v-html="field.label"></span>
                    <span v-if="field.extralabel" class="extralabel" v-html="field.extralabel"></span>
                </template>
                <span class="required" v-if="isRequired">*</span>
            </div>

            <div v-if="field.hasOwnProperty('description') && field.description && (showDescriptionAfterLabel || isTextarea)"
                 class="form-field-description" v-html="field.description"></div>

            <div :class="fieldWrapperClass" ref="formfieldcontainer">

                <div class="prepend" v-if="$slots.prepend || field.prepend">
                    <slot v-if="$slots.prepend" name="append"></slot>
                    <div v-if="field.prepend" class="prepend-label" v-html="field.prepend"></div>
                </div>

                <!--				<template ref="f">-->
                <!--					<slot name="formfield"></slot>-->
                <!--				</template>-->
                <slot name="field"></slot>
                <slot v-if="$slots.append" name="append"></slot>
                <div v-if="field.append" class="append-label" v-html="field.append"></div>
                <!--                <div v-if="useLiveSaving" class="saving">-->
                <!--                    <live-savingprogress ref="savingProgress"></live-savingprogress>-->
                <!--                </div>-->


                <div v-if="field.type !== 'checkboxlist' && hasMaxLength && !isDisabled"
                     class="max-length-trigger">{{ textLengthTrigger }}</div>
            </div>

            <div v-if="validationError !== false" class="validation-error" v-html="validationError"></div>



            <div v-if="field.hasOwnProperty('description') && field.description && !showDescriptionAfterLabel && !isTextarea"
                 class="form-field-description" v-html="field.description"></div>

        </div>
        <template v-else>

            <div class="form-label"
                 :class="{'is-minimizable': field.minimizable, minimized: minimized }"
                 v-if="field.hasOwnProperty('label') && field.label" @click.prevent="handleMinimize">
                <svg-icon name="chevron-down" v-if="field.minimizable"/>
                <template v-if="field.type !== 'checkboxlist' && field.type !== 'radiolist' && field.type !== 'radio' && field.type !== 'ckeckbox' ">
                    <label :for="fieldID" @click.prevent="focusField">
                        <span v-html="field.label"></span>
                        <span v-if="field.extralabel" class="extralabel" v-html="field.extralabel"></span>
                    </label>
                </template>
                <template v-else>
                    <span v-html="field.label"></span>
                    <span v-if="field.extralabel" class="extralabel" v-html="field.extralabel"></span>
                </template>

                <span class="required" v-if="isRequired">*</span>
            </div>

            <div v-if="field.hasOwnProperty('description') && field.description && (showDescriptionAfterLabel || isTextarea)"
                 class="form-field-description" v-html="field.description"></div>

            <div :class="fieldWrapperClass" ref="formfieldcontainer">
                <div class="prepend" v-if="$slots.prepend || field.prepend">
                    <slot v-if="$slots.prepend" name="append"></slot>
                    <div v-if="field.prepend" class="prepend-label" v-html="field.prepend"></div>
                </div>

                <!--				<template ref="f">-->
                <!--					<slot name="formfield"></slot>-->
                <!--				</template>-->

                <slot name="field"></slot>

                <slot v-if="$slots.append" name="append"></slot>
                <div v-if="field.append" class="append-label" v-html="field.append"></div>

                <!--                <div v-if="useLiveSaving" class="saving">-->
                <!--                    <live-savingprogress ref="savingProgress"></live-savingprogress>-->
                <!--                </div>-->


                <div v-if="field.type !== 'checkboxlist' && hasMaxLength && !isDisabled" class="max-length-trigger">{{ textLengthTrigger }}</div>

            </div>

            <div v-if="validationError !== false" class="validation-error" v-html="validationError"></div>
            <slot name="validation"></slot>

            <div v-if="field.hasOwnProperty('description') && field.description && !showDescriptionAfterLabel && !isTextarea"
                 class="form-field-description"
                 v-html="field.description"></div>

        </template>
    </div>
</template>

<script>


// import livesavingprogress from "./../livesaving-progress"

import {
    createError,
    find,
    normalizeRules
} from '@/validation/utils';


import {getCurrentInstance, ref, watch, nextTick, computed, onMounted, inject} from "vue";
import {useFormField} from "@/mixins/use-form-field";

export default {
    name: "field",
    props: {
        name: {
            type: String,
            default() {
                return '';
            }
        },
        validate: {
            type: Boolean,
            default: false
        },
        field: {
            type: Object,
            required: true
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        }
    },


    setup(props, context) {
        let timer = null;
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const $events = inject('$events');
        const isMounted = ref(false);


        const {
            modelName,
            modelvalue,
            hasDisableWhen,
            hasShowWhen,
            getShowWhen,
            getFieldValidationError,
            onComponentCreated,
        } = useFormField(props, context);


        const fieldState = ref({
            focus: false,
            focusin: false,
            blured: null,
            dirty: false,
            valid: true
        });

        const minimized = ref(false);
        const fielderror = ref(false);
        const fieldHasChanged = ref(false);
        const fieldValidation = ref(null);
        const validationError = ref(false);
        const fieldIsVisible = ref(true);
        const defaultAttributes = ref(null);

        const hasValidation = computed(() => {
            return props.field.hasOwnProperty('validation') && !_.isEmpty(props.field.validation);
        });

        const isColumns = computed(() => {
            return props.field.hasOwnProperty('cols') && props.field.cols >= 1;
        });

        const formatCols = computed(() => {
            return props.field.cols > 12 || props.field.cols < 1 ? 'col-md-12' : 'col-md-' + props.field.cols;
        });

        const fieldID = computed(() => {
            if (!_.isString(props.name)) {
                return null;
            }

            if (props.field.type === 'checkbox' && props.field.label) {
                return null
            }


            return 'field-' + props.name.replace(/\[/g, '.').replace(/\]/g, '');
        })

        const fieldIDContainer = computed(() => {
            if (!_.isString(props.name)) {
                return null;
            }
            return 'field-container-' + props.name.replace(/\[/g, '.').replace(/\]/g, '');
        })

        const isRequired = computed(() => {
            if (props.field.hasOwnProperty('validation') &&
                _.isString(props.field.validation) &&
                props.field.validation.match(/required/ig)) {
                return true;
            }
            return false;
        });

        const isDisabled = computed(() => {
            if ((props.field.hasOwnProperty('disabled') && props.field.disabled) || (props.field.hasOwnProperty('attributes') && props.field.attributes && props.field.attributes.disabled )) {
                return true;
            }

            return false
        })



        function findParentComponent(componentName, maxDeep = null) {
            let component = null;
            let parent = inst.parent;
            let inDepp = 0;

            while (parent && !component) {
                if (!parent || inDepp >= maxDeep) {
                    break;
                }

                if (parent.type && parent.type.name === componentName) {
                    component = parent;
                }
                parent = parent.parent;
                inDepp++;
            }

            return component
        }

        const parentIsRow = computed(() => {
            if (!isMounted.value) {
                return false
            }
            let parent = inst.parent.field;
            return !!(parent && parent.type === 'form-columns');
        });

        const parentIsColumn = computed(() => {
            if (!isMounted.value) {
                return false
            }

            return findParentComponent('form-column', 5) !== null;
        });

        const formatFieldClass = computed(() => {
            let cls = isColumns.value && !parentIsRow.value ? formatCols.value : 'form-field';

            if (inst.parent.type.name) {
                cls += ' fieldtype-'+ inst.parent.type.name.replace(/^form-/g, '');
            }

            if (!fieldIsVisible.value || (hasShowWhen.value && !getShowWhen.value)) {
                cls += ' hidden';
            }

            if (fielderror.value) {
                cls += ' has-field-error';
            }

            return cls;
        });

        const fieldWrapperClass = computed(() => {
            let cls = 'form-field-container';

            if (inst.slots.prepend || props.field.prepend) {
                cls += ' with-prepend';
            }

            if (inst.slots.append || props.field.append) {
                cls += ' with-append';
            }

            if (validationError.value !== false) {
                cls += ' has-error';
            }

            if (minimized.value) {
                cls += ' is-minimized';
            }

            return cls;
        });

        const showDescriptionAfterLabel = computed(() => {
            return props.field.description && props.field.type === 'checkboxlist'
        });

        const isTextarea = computed(() => {
            return props.field.type === 'ace' || props.field.type === 'textarea';
        });


        const fieldAttributes = computed(() => {
            if (!isMounted.value || !props.field) return {}

            const attrs = props.field.hasOwnProperty('attributes') && _.isObject(props.field.attributes) ? props.field.attributes : null;

            if (_.isObject(defaultAttributes.value) && attrs) {
                return {
                    ...defaultAttributes.value,
                    ...attrs,
                };
            }

            if (attrs) {
                return attrs;
            }

            if (_.isObject(defaultAttributes.value)) {
                return defaultAttributes.value
            }

            return {}
        })

        const hasMaxLength = computed(() => {
            return props.field.maxlength > 0 || (fieldAttributes.value.hasOwnProperty('maxlength') && fieldAttributes.value.maxlength > 0);
        });


        const textLengthTrigger = computed(() => {
            if (hasMaxLength.value) {
                let max = props.field.maxlength > 0 ? props.field.maxlength : (fieldAttributes.value.hasOwnProperty('maxlength') ? fieldAttributes.value.maxlength : 0);
                if (max > 0 && typeof modelvalue.value === "string") {
                    if (modelvalue.value.length < max) {
                        return modelvalue.value.length +' von '+ max +' Zeichen geschrieben';
                    }
                    else {
                        return 'Maximum von '+ max +' Zeichen geschrieben.';
                    }
                }
                else {
                    return '0 von '+ max +' Zeichen geschrieben';
                }
            }
            return ''
        });


        watch(() => props.validate, (n) => {
            if (n === true) {
                // let v = modelvalue.value;
                handleFieldChange();
            } else {
                validationError.value = false;
            }
        });

        watch(() => fieldState.blured, (n) => {
            if (n === true) {
                handleFieldChange(v);
            }
        });

        watch(() => fieldState.valid, (n) => {
            if (!n) {
                $events.$emit('form-field-invalid', props.name);
            } else {
                $events.$emit('form-field-valid', props.name);
            }
        });

        watch(() => props.responseerrors, (n) => {
            validationError.value = false;

            // nextTick(() => {
                if (_.isObject(n) || Array.isArray(n)) {
                    validationError.value = getFieldValidationError(n, modelName.value)
                }
            // })
        }, {deep: true, immediate:true});


        onMounted(() => {
            isMounted.value = true;

            onComponentCreated();

            if (props.field.minimizable) {
                if (props.field.hasOwnProperty('minimized') && typeof props.field.minimized === "boolean") {
                    minimized.value = props.field.minimized
                }
            }




            if (hasValidation.value) {
                fieldValidation.value = {
                    name: props.name || '{field}',
                    rules: normalizeRules(props.field.validation),
                    bails: true,
                    forceRequired: false,
                    isRequired() {
                        return !!this.rules.required || this.forceRequired;
                    }
                };
            }
        });

        function blurEvent() {
            fieldState.value.blured = true;
            fieldState.value.focus = false
        }

        function focusEvent() {
            fieldState.value.blured = false;
            fieldState.value.focus = true
        }

        /**
         * We need to convert any object param to an array format
         * since the locales do not handle params as objects yet.
         */
        function convertParamArrayToObj(paramNames, ruleName) {

            if (!paramNames) {
                return paramNames;
            }

            if (_.isObject(paramNames)) {
                // check if the object is either a config object or a single parameter that is an object.
                const hasKeys = _.each(paramNames, name => Object.keys(paramNames).indexOf(name) !== -1);
                // if it has some of the keys, return it as is.
                if (hasKeys) {
                    return paramNames;
                }
                // otherwise wrap the object in an array.
                paramNames = [paramNames];
            }

            // Reduce the paramsNames to a param object.
            return paramNames.reduce((prev, value, idx) => {
                prev[paramNames[idx]] = value;
                return prev;
            }, {});
        }


        /**
         * Resolves an appropriate display name, first checking 'data-as' or the registered 'prettyName'
         */
        function _getFieldDisplayName(field) {
            return field.label || field.name;
        }

        /**
         * We need to convert any object param to an array format
         * since the locales do not handle params as objects yet.
         */
        function _convertParamObjectToArray(obj, paramNames, ruleName) {
            if (Array.isArray(obj)) {
                return obj;
            }

            if (!paramNames || !_.isObject(obj)) {
                return obj;
            }

            return paramNames.reduce((prev, paramName) => {
                if (paramName in obj) {
                    prev.push(obj[paramName]);
                }

                return prev;
            }, []);
        }


        /**
         * Translates the parameters passed to the rule (mainly for target fields).
         */
        function _getLocalizedParams(rule, targetName) {
            let params = _convertParamObjectToArray(rule.params, rule.name);
            if (rule.options.hasTarget && params && params[0]) {
                return [targetName].concat(params.slice(1));
            }
            return params;
        }

        /**
         * Formats an error message for field and a rule.
         */
        function _formatErrorMessage(field, rule, data, targetName) {
            const name = _getFieldDisplayName(field);
            const validationLocalize = Neloh.getPrototype('validationLocalize');

            if (validationLocalize.hasOwnProperty($root.locale)) {
                let dict = validationLocalize[$root.locale];

                if (dict && dict.messages.hasOwnProperty(rule.name)) {
                    let msg = dict.messages[rule.name];
                    let p = convertParamArrayToObj(rule.params);
                    return _.isFunction(msg) ? msg(name, ...p) : msg;
                }
            }

            return name;
        }


        /**
         * Creates a Field Error Object.
         */
        function _createFieldError(field, rule, data, targetName) {
            return {
                id: field.id,
                field: field.name,
                msg: _formatErrorMessage(field, rule, data, targetName),
                rule: rule.name,
                regenerate: () => {
                    return _formatErrorMessage(field, rule, data, targetName);
                }
            };
        }

        /**
         * Date rules need the existence of a format, so date_format must be supplied.
         */
        function _getDateFormat(validations) {
            let format = null;
            if (validations.date_format && _.isArray(validations.date_format)) {
                format = validations.date_format[0];
            }

            return format || 'yyyy-mm-dd';
        }


        /**
         * Tests a single input value against a rule.
         */
        function _test(field, value, rule, validator) {

            let params = _.isArray(rule.params) ? rule.params : [];
            if (!params) {
                params = [];
            }

            params = _.cloneDeep(params);

            let targetName = null;
            if (!validator || typeof validator !== 'function') {
                return Promise.reject(createError(`No such validator '${rule.name}' exists.`));
            }

            // has field dependencies.
            if (rule.options.hasTarget && field.dependencies) {
                const target = find(field.dependencies, d => d.name === rule.name);
                if (target) {
                    targetName = target.field.alias;
                    params = [target.field.value].concat(params.slice(1));
                }
            } else if (rule.name === 'required' && field.rejectsFalse) {
                // invalidate false if no args were specified and the field rejects false by default.
                params = params.length ? params : [true];
            } else if (rule.options.hasTarget && rule.name === 'required_if') {
                if (params.length > 1) {
                    let fieldName = params[0];
                    if (fieldName.match(/^([a-z][a-z0-9\._]*)$/g)) {
                        if ($root.hasOwnPropertyPath(props.formModel, fieldName)) {
                            params[0] = $root.getPropertyPath(props.formModel, fieldName);
                        }
                    }
                }

                // invalidate false if no args were specified and the field rejects false by default.
                params = params.length ? params : [true];
            }


            if (rule.options.isDate) {
                const dateFormat = _getDateFormat(field.rules);
                if (rule.name !== 'date_format') {
                    params.push(dateFormat);
                }
            }

            let p = convertParamArrayToObj(params, rule.name);

            let result = validator(value, ...p);

            // If it is a promise.
            if (_.isFunction(result.then)) {
                return result.then(values => {
                    let allValid = true;
                    let data = {};
                    if (Array.isArray(values)) {
                        allValid = values.every(t => (_.isObject(t) ? t.valid : t));
                    } else { // Is a single object/boolean.
                        allValid = _.isObject(values) ? values.valid : values;
                        data = values.data;
                    }

                    return {
                        valid: allValid,
                        data: result.data,
                        errors: allValid ? [] : [_createFieldError(field, rule, data, targetName)]
                    };
                });
            }

            if (!_.isObject(result)) {
                result = {valid: result, data: {}};
            }

            return {
                valid: result.valid,
                data: result.data,
                errors: result.valid ? [] : [_createFieldError(field, rule, result.data, targetName)]
            };
        }


        function handleFieldChange(value) {

            if (!hasValidation.value) return;

            if (_.isUndefined(value)) {
                value = null;
            }


            let useRules = [];
            const promises = [];
            const errors = [];
            const field = this;
            const validationRules = Neloh.getPrototype('validationRules');

            let isExitEarly = false, that = this;

            if (fieldValidation.value && fieldValidation.value.rules) {

                useRules = Object.keys(fieldValidation.value.rules).filter((rule) => {
                    return validationRules.hasOwnProperty(rule);
                });

                // use of '.some()' is to break iteration in middle by returning true
                useRules.some(rule => {
                    let fieldRuleParams = fieldValidation.value.rules[rule]
                    let r = validationRules[rule];
                    const ruleOptions = r.options;
                    let clone = _.cloneDeep(that.field);

                    if (!clone.hasOwnProperty('name')) {
                        clone.name = that.name
                    }

                    const result = _test(clone, value, {
                        name: rule,
                        params: fieldRuleParams,
                        options: ruleOptions
                    }, r.validate);

                    if (_.isFunction(result.then)) {
                        promises.push(result);
                    } else if (!result.valid) {
                        errors.push(...result.errors);
                        if (rule !== 'nullable') {
                            isExitEarly = true;
                        }
                    } else {
                        // promisify the result.
                        promises.push(new Promise(resolve => resolve(result)));
                    }

                    return isExitEarly;
                });

            }


            if (isExitEarly) {
                fieldState.value.valid = false;
                validationError.value = 'Error';
                if (errors && errors.length) {
                    let nullInUseRules = useRules.indexOf('nullable') !== -1;
                    let hasNullError = _.find(errors, {rule: 'nullable'});

                    if (nullInUseRules) {
                        if (hasNullError && useRules.length > 1) {

                            if (errors.length === 1) {
                                if (errors[0].rule === 'nullable') {
                                    errors[0].msg += ' ist nicht gültig';
                                }
                            } else if (errors.length > 1) {
                                if (errors[0].rule === 'nullable') {
                                    errors[0].msg = errors[1].msg;
                                }
                            }

                            validationError.value = errors[0].msg;

                        } else {
                            fieldState.value.valid = true;
                            validationError.value = false;
                        }
                    } else {
                        validationError.value = errors[0].msg;
                    }
                }

                // return Promise.resolve({ valid: false, errors, id: field.id, field: field.name, scope: field.scope });
                return;
            }

            Promise.all(promises).then(results => {
                return results.reduce((prev, v) => {
                    if (!v.valid) {
                        prev.errors.push(...v.errors);
                    }

                    prev.valid = prev.valid && v.valid;

                    return prev;
                }, {valid: true, errors, id: field.id, field: field.name, scope: field.scope});
            }).then(() => {
                fieldState.value.valid = true;
                validationError.value = false;
            });

        }



        function focusField(e) {
            if (e.currentTarget.getAttribute('for')) {
                if (inst.vnode.el) {

                    let forStr = e.currentTarget.getAttribute('for');
                    forStr = forStr.replace(/\./g, '-')

                    const element = inst.vnode.el.querySelector('#'+ forStr);
                    if (element) {
                        element.focus()
                    }
                    else {
                        if (inst.vnode.el.querySelector('.vs__search')) {
                            inst.vnode.el.querySelector('.vs__search').focus()
                        }
                        else if (inst.vnode.el.querySelector('.vdp-datepicker')) {
                            inst.vnode.el.querySelector('.vdp-datepicker input').focus()
                        }
                    }
                }
            }
        }


        function handleMinimize(e){
            if (props.field.minimizable) {
                minimized.value = !minimized.value
            }
        }



        return {
            isDisabled,
            fieldState,
            modelName,
            modelvalue,
            fieldID,
            fieldIDContainer,
            formatFieldClass,
            fieldWrapperClass,
            showDescriptionAfterLabel,
            hasValidation,
            isColumns,
            isRequired,
            isTextarea,
            hasShowWhen,
            hasDisableWhen,
            getShowWhen,
            validationError,

            minimized,
            hasMaxLength,
            textLengthTrigger,

            focusField,
            handleMinimize
        }
    },
}
</script>

<style scoped>

</style>
