<template>
    <div class="form-field">
        <template v-if="$slots.label"><slot name="label"/></template>

        <div class="form-field-container">
            <slot name="default"/>
        </div>

        <template v-if="$slots.description">
            <div class="form-field-description">
                <slot name="description"/>
            </div>
        </template>

    </div>
</template>

<script>
export default {
    name: "field"
}
</script>

<style scoped>

</style>
