<script>
import {defineComponent, getCurrentInstance, inject, computed} from 'vue'

export default defineComponent({
    name: "HolderDetailRow",
    props: {
        rowData: {},
        rowIndex: {}
    },
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const $events = inject('$events');


        const hasDetails = computed(() => {
            return !_.isEmpty(props.rowData.rowdetails)
        })



        function webLink(link) {
            return link ? link.replace(/^www\.?/g, '//www.') : null
        }

        function mailLink(mail) {
            return 'mailto:' + mail;
        }

        function phoneLink(phone) {
            let numbers = phone.replace(/[^\d]/g, '');
            return 'tel:' + numbers;
        }

        function formatPhoneNumber(value) {
            let numbers = value.match(/\//g) ? value.replace(/[^\d\/]/g, '') : value.replace(/[^\d]/g, '');
            numbers = numbers.trim();

            let str = '', nums = 0;

            if (numbers.match(/^0([1-9])/g)) {
                str = '';
                for (let i = 0; i < numbers.length; i++) {
                    if (numbers[i] === '/') {
                        str += ' / ';
                        nums = 0;
                    } else {
                        nums++
                        str += numbers[i];

                        if (nums === 2) {
                            str += ' ';
                            nums = 0;
                        }
                    }
                }
            } else if (numbers.match(/^49([1-9])/g)) {
                str = '+49 ';
                for (let i = 2; i < numbers.length; i++) {

                    str += numbers[i] + (i % 2 ? ' ' : '');
                }
            }
            else if (numbers.match(/^([1-9])/g)) {
                for (let i = 2; i < numbers.length; i++) {
                    str += numbers[i] + (i % 2 ? ' ' : '');
                }
            }
            else {
                str = numbers;
            }

            return str;
        }


        return {
            hasDetails,


            webLink,
            mailLink,
            phoneLink,
            formatPhoneNumber
        }
    }
})
</script>

<template>
    <div class="association-detail-row" :class="{load: rowData.loadingdetails}">
        <template v-if="hasDetails">

<!--            <div class="association-details">-->
<!--                <b>{{ rowData.name }}</b>-->
<!--&lt;!&ndash;                <div v-if="rowData.street">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ rowData.street }} {{ rowData.street_number }}&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div v-if="rowData.city">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ rowData.zip }} {{ rowData.city }}&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </div>-->


<!--            <div class="association-communiation">-->
<!--&lt;!&ndash;                <b>Kommunikation/Erreichbarkeit</b>&ndash;&gt;-->
<!--&lt;!&ndash;                <template v-if="(rowData.phone_prefix && rowData.phone) ||&ndash;&gt;-->
<!--&lt;!&ndash;                (rowData.phone_prefix && rowData.fax) || rowData.email || rowData.www">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="communiation">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div v-if="rowData.phone_prefix && rowData.phone">&ndash;&gt;-->
<!--&lt;!&ndash;                            <b>Tel:</b> <span>{{ rowData.phone_prefix+' / '+ formatPhoneNumber(rowData.phone) }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div v-if="rowData.phone_prefix && formatPhoneNumber(rowData.fax)">&ndash;&gt;-->
<!--&lt;!&ndash;                            <b>Fax:</b> <span>{{ rowData.phone_prefix+' / '+ formatPhoneNumber(rowData.fax) }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div v-if="rowData.email">&ndash;&gt;-->
<!--&lt;!&ndash;                            <b>E-Mail:</b> <a :href="mailLink(rowData.email)" target="_blank">{{ rowData.email }}</a>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div v-if="rowData.www">&ndash;&gt;-->
<!--&lt;!&ndash;                            <b>Web:</b> <a :href="webLink(rowData.www)" target="_blank">{{ rowData.www }}</a>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                </template>&ndash;&gt;-->
<!--&lt;!&ndash;                <template v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div><em>keine Angaben vorhanden</em></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </template>&ndash;&gt;-->
<!--            </div>-->

            <div class="association-institutions ">
	            <b><h4>zugehörige Einrichtungen</h4></b>
                <template v-for="i in rowData.rowdetails.institutions">
                    <div class="institution">
                        <div><b>{{ i.name }}</b></div>
                        <template v-if="(i.phone_prefix && i.phone) || (i.phone_prefix && i.fax) || i.email || i.www">
                            <div class="communiation">
                                <div v-if="i.phone_prefix && i.phone">
                                    <b>Tel:</b> <span>{{ i.phone_prefix+' / '+ formatPhoneNumber(i.phone) }}</span>
                                </div>
                                <div v-if="i.phone_prefix && i.fax">
                                    <b>Fax:</b> <span>{{ i.phone_prefix+' / '+ formatPhoneNumber(i.fax) }}</span>
                                </div>
                                <div v-if="i.email">
                                    <b>E-Mail:</b> <a :href="mailLink(i.email)" target="_blank">{{ i.email }}</a>
                                </div>
                                <div v-if="i.www">
                                    <b>Web:</b> <a :href="webLink(i.www)" target="_blank">{{ i.www }}</a>
                                </div>
                            </div>

                        </template>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>
