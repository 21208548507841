import {
    each
} from '~/lodash-es';


let modulRoutes = [];
const {ignoreModules} = require('./IgnoreModules');

if (window.Laravel && Array.isArray(window.Laravel.modules)) {
    //
    const modules = window.Laravel.modules.filter(item => !ignoreModules.includes(item));

    // console.log('modules', modules, ignoreModules)
    each(modules, (name) => {
        try {
            let filesbasepage1 = require('@modules/'+ name +'/Assets/js/'+ name +'Routes.js').default;
            if (Array.isArray(filesbasepage1)) {
                for (let i = 0; i < filesbasepage1.length; i++ ) {
                    if (filesbasepage1[i]) {
                        modulRoutes.push(filesbasepage1[i]);
                    }
                }
            }
            else {

            }
        } catch (e) {



            try {
                let filesbasepage1 = require('@modules/'+ name +'/Assets/js/routes.js').default;
                if (Array.isArray(filesbasepage1)) {
                    for (let i = 0; i < filesbasepage1.length; i++ ) {
                        if (filesbasepage1[i])  {
                            modulRoutes.push(filesbasepage1[i]);
                        }
                    }
                }
                else {

                }
            } catch (e) {
            }
        }
    });
}

export default modulRoutes;
