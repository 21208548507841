const {ignoreModules} = require('./IgnoreModules');
const path = require("path");

const s = require('@modules/../modules_statuses.json');

if (typeof s === "object") {

    // const modules = s.filter((item, k) => !ignoreModules.includes(k));

    _.each(s, (n, name) => {
        try {
            let filesbasepage1 = require('@modules/' + name + '/Assets/js/' + name + 'Components.js');
            if (_.isObject(filesbasepage1)) {
                _.each(filesbasepage1, (component, name) => {
                    Neloh.component(name, component);
                });

                console.log('%c🔥 loading Components '+ name+' from @modules/' + name + '/Assets/js/' + name + 'Components.js', "color:Green");

            } else {
                console.log('%cℹ️ '+ name+' has no Components', "color:blue");
            }
        } catch (e) {
            try {
                let filesbasepage1 = require('@modules/' + name + '/Assets/js/components.js');
                if (_.isObject(filesbasepage1)) {
                    _.each(filesbasepage1, (component, name) => {
                        Neloh.component(name, component);
                    });
                    console.log('%c🔥 loading Components '+ name+' from @modules/' + name + '/Assets/js/components.js', "color:Green");
                } else {
                    console.log('%cℹ️ '+ name+' has no Components', filesbasepage1);
                }
            } catch (e) {
                console.log('%cℹ️ Modul "'+name+'" has no components.js', "color:blue");
            }
        }

    });
}


//
//
//
// if (window.Laravel && _.isArray(window.Laravel.modules)) {
//     const modules = window.Laravel.modules.filter(item => !ignoreModules.includes(item));
//     _.each(modules, (name) => {
//             try {
//                 let filesbasepage1 = require('@modules/' + name + '/Assets/js/' + name + 'Components.js');
//                 if (_.isObject(filesbasepage1)) {
//                     _.each(filesbasepage1, (component, name) => {
//                         Laravision.component(name, component);
//                     });
//                 } else {
//                     console.warn(name+' has no Components', filesbasepage1);
//                 }
//             } catch (e) {
//                 try {
//                     let filesbasepage1 = require('@modules/' + name + '/Assets/js/components.js');
//                     if (_.isObject(filesbasepage1)) {
//                         _.each(filesbasepage1, (component, name) => {
//                             Laravision.component(name, component);
//                         });
//                     } else {
//                          console.warn(name+' has no Components', filesbasepage1);
//                     }
//                 } catch (e) {
//                      console.warn('Modul "'+name+'" has no components.js');
//                 }
//             }
//
//     });
//
// }
