<template>
    <div class="form-tabs" :class="{hide: !hasVisibleTabs }">
        <ul class="nav-tabs">
            <template v-for="(field, k) in fields">
                <template v-if="tabHasFields(k) && typeof field === 'object'">
                    <li
                        :class="['nav-item', {
                            active: (!inModal && formTabActive === field.name || formTabActive === field.id) || (inModal && field.active === true),
                            hidden: hideShowWhen(field),
                            disabled: disableWhen(field)
                        }]"
                        @click.prevent="toggleTab(k, field)"
                        :id="field.id ? 'tab_'+ field.id : (field.name ? 'tab_'+field.name : null)"
                        :ref="'tab_'+ ( field.id ? field.id : field.name )"
                        v-html="renderTabLabel(field)">
                    </li>
                </template>

            </template>

        </ul>
    </div>
</template>

<script>
import {getCurrentInstance, watch, ref, computed, onMounted, onBeforeUnmount, onBeforeMount, inject, nextTick, provide} from "vue";
import {useStore} from "~/vuex";
import {useFormConditions} from "./../mixins/use-form-tab-conditions";
import {useRoute} from "~/vue-router";

export default {
    name: "form-layout-tabs",
    props: {
        name: {
            required: false,
        },
        fields: {
            required: true,
            type: Object
        },
        validate: {
            type: Boolean,
            default: false
        },
        rendersections: {
            required: false,
            default() {
                return {}
            }
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        formFields: {
            default() {
                return null
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        },
        inModal: {
            required: false,
            default() {
                return false
            }
        },
        storeModul: {
            required: false,
            default() {
                return null
            }
        }
    },

    emits: ['show-tab'],

    setup(props, context) {

        const inst = getCurrentInstance();
        const store = useStore();
        const route = useRoute();
        const $root = inst.root.ctx;

        const $events = inject('$events')
        const hasOwnPropertyPath = inject('hasOwnPropertyPath')
        const isMounted = ref(false);

        const formTabActive = computed(() => {
            if (typeof props.storeModul === 'string') {
                return store.getters[props.storeModul +"/formTabActive"];
            }

            return store.getters['form/formTabActive'];
        });

        const storeFormFields = computed(() => {
            if (typeof props.storeModul === 'string') {
                return store.getters[props.storeModul +"/formFields"];
            }

            if (props.inModal) {
                return props.formFields;
            }

            return store.getters['form/formFields'];
        });



        const hasVisibleTabs = computed(() => {
            let v = false;
            _.each(props.fields, (field, k) => {
                if (!v) {
                    if ( tabHasFields(k) && typeof field === 'object' ) {
                        v = true;
                    }
                }
            });

            return v;
        });



		const currentTabs = computed(() => {
			if (typeof props.storeModul === 'string') {
				return store.getters[props.storeModul +"/formFields"];
			}


			return store.getters["form/formFields"];
		})


        watch(() => props.fields, (n, o) => {
            if (n !== o) {
                isMounted.value = false;
                nextTick(() => {
                    isMounted.value = false;
                })
            }
        }, {deep: true});

        const {
            getCondition,
            hasDisableWhen,
            hasShowWhen,
            getShowWhen,
            getDisableWhen,
            hideShowWhen,
            disableWhen,
        } = useFormConditions(props, context, $root);


	    function tabHasFields(k) {
		    return storeFormFields.value && storeFormFields.value.hasOwnProperty(k);
	    }


        function bindTabEvents() {
            currentTabs.value = _.cloneDeep(store.state.form.formTabs);

            _.each(currentTabs.value, (tab, k) => {

                if (hasDisableWhen(tab)) {
                    if (_.isArray(tab.disable_when)) {
                        _.each(tab.disable_when, (when) => {
                            if (when.match(/([\|,]+?)/g)) {
                                let separator = '|';
                                if (!when.match(/\|/g)) {
                                    separator = ',';
                                }
                                let conditions = when.split(separator);


                                if (tab.disable_whenWatcher) tab.disable_whenWatcher()


                                if (conditions[0] && !hasOwnPropertyPath(props.formModel, conditions[0])) {
                                    let oldDirty = _.cloneDeep(route.meta.dirty);

                                    $root.$set(props.formModel, conditions[0], null, true);

                                    route.meta.dirty = oldDirty;
                                } else if (conditions[0]) {

                                }
                            }
                        });
                    }
                }

                if (hasShowWhen(tab)) {

                    if (_.isArray(tab.show_when)) {
                        _.each(tab.show_when, (when) => {
                            if (when.match(/([\|,]+?)/g)) {
                                let separator = '|';
                                if (!when.match(/\|/g)) {
                                    separator = ',';
                                }
                                let conditions = when.split(separator);

                                if (tab.show_whenWatcher) tab.show_whenWatcher()


                                if (conditions[0] && !hasOwnPropertyPath(props.formModel, conditions[0])) {

                                    let oldDirty = _.cloneDeep(route.meta.dirty);

                                    $root.$set(props.formModel, conditions[0], null, true);

                                    route.meta.dirty = oldDirty;


                                } else if (conditions[0]) {

                                }

                            }
                        });
                    }
                }


            });
        }


        function onResetForm() {
            // const currentTab = this.currentTabData;
            //
            // nextTick(() => {
            //     let found = false;
            //     if (this.currentTabData) {
            //         let tabs = this.getTabs;
            //         _.each(tabs, (tab, idx) => {
            //             if (tab.label === this.currentTabData.label) {
            //                 found = idx;
            //             }
            //         });
            //     }
            //
            //     if (found === false) {
            //         toggleTab(0, this.$store.getters.formTabs[0], true);
            //     } else {
            //         toggleTab(found, this.$store.getters.formTabs[found], true);
            //     }
            // });
        }




        function hideTab(tabid) {
            let t = inst.vnode.el.querySelector('#tab_' + tabid);
            if (t) {
                t.classList.add('hidden')
            }
        }

        function showTab(tabid) {
            let t = inst.vnode.el.querySelector('#tab_' + tabid);
            if (t) {
                t.classList.remove('hidden')
            }
        }


        function openTab(tab) {
            if (inst.refs.hasOwnProperty(tab.name)) {
                let tab = inst.refs[tab.name];
                if (tab[0] && tab[0].dataset.hasOwnProperty('index')) {
                    toggleTab(parseInt(tab[0].dataset.index), storeTabs.value[parseInt(tab[0].dataset.index)]);
                }
            }
            else {

                let foundIndex = null;
                let allTabs = $root.objectToArray(_.clone(props.fields));

                if (tab.hasOwnProperty('name')) {
                    foundIndex = _.findIndex(allTabs, {name: tab.name})
                }

                if ((foundIndex === null || foundIndex < 0) && tab.hasOwnProperty('id')) {
                    foundIndex = _.findIndex(allTabs, {id: tab.id})
                }

                if (foundIndex >= 0) {
                    toggleTab(allTabs[foundIndex].name, allTabs[foundIndex]);
                }

            }
        }

        function renderTabLabel(tab) {
            return tab.label + (tab.badgeLabel ? tab.badgeLabel : '');
        }

        function isSubFields(field) {
            return !field || field && !field.hasOwnProperty('type')
        }

        function getRenderSections(field) {
            if (field && field.hasOwnProperty('rendersections') && field.rendersections) {
                return Object.assign({}, props.rendersections, field.rendersections);
            }

            return props.rendersections;
        }

        function tabHasRendersection(tab) {
            return tab.hasOwnProperty('rendersections') && !_.isEmpty(tab.rendersections);
        }



        function setActiveTab(tabID) {
            if (tabID) {
                if (typeof props.storeModul === 'string') {
                    store.dispatch(props.storeModul +'/changeActiveTab', tabID);
                }
                else {
                    store.dispatch('form/changeActiveTab',tabID );
                }
            }
        }


        function toggleTab(index, tab, forceReset) {
            if (disableWhen(tab)) {
                return;
            }

            let useTabKey = tab.id ? tab.id : index

            if (props.inModal) {

                _.each(props.fields, (t, i) => {
                    if (i !== index) {
                        t.active = false;
                    } else {
                        t.active = true;
                    }
                });

                _.each(props.formFields, (t, i) => {
                    if (i !== index) {
                        t.active = false;
                    } else {
                        t.active = true;
                    }
                });

                context.emit('show-tab', tab);
                return;
            }


			let currentDirtyState;
			if (typeof props.storeModul === 'string') {
		        currentDirtyState = store.getters[props.storeModul +'/formDirty'];
	        }
			else {
		        currentDirtyState = store.getters['form/formDirty'];
	        }


            // const oldActiveTab = store.getters.formTabActive;
            if (typeof props.storeModul === 'string') {
                store.dispatch(props.storeModul + '/changeActiveTab', useTabKey);
            } else {
                store.dispatch('form/changeActiveTab', useTabKey);
            }

            nextTick(() => {
                nextTick(() => {
                    nextTick(() => {
                        context.emit('show-tab', tab);
                        $events.$emit('tab-shown', tab);

                        // Form dirty patch
                        nextTick(() => {
                            nextTick(() => {
                                if (!currentDirtyState) {
                                    if (typeof props.storeModul === 'string') {
                                        store.dispatch(props.storeModul + '/unDirty')
                                    } else {
                                        store.dispatch('form/unDirty')
                                    }
                                }

                            })
                        })
                    });
                });
            });
        }


        onBeforeMount(() => {
            $events.$on('hide-tab', hideTab);
            $events.$on('show-tab', showTab);
            $events.$on('open-tab', openTab);
            $events.$on('form-reset', onResetForm);
        })

        onMounted(() => {
            isMounted.value = true;
        });

        onBeforeUnmount(() => {
            $events.$off('hide-tab', hideTab);
            $events.$off('show-tab', showTab);
            $events.$off('open-tab', openTab);
            $events.$off('form-reset', onResetForm);
        });


        return {
            isMounted,
            formTabActive,
            storeFormFields,
            hasVisibleTabs,
            tabHasFields,
            getCondition,
            hasDisableWhen,
            hasShowWhen,
            getShowWhen,
            getDisableWhen,
            hideShowWhen,
            disableWhen,
            toggleTab,
            renderTabLabel
        }
    },
}
</script>

<style scoped>

</style>
