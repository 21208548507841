<template>
    <div class="dropdown-button">

        <button ref="savebtn"
                type="button"
                :class="['btn btn-primary', {'disabled': loading || disabled }]"
                :style="buttonStyle"
                @click="save"
                :disabled="loading || disabled">
            <div class="load-animation" v-if="loading">
                <dot-loading color="#fff" size="5px"></dot-loading>
            </div>
            <div v-if="!loading" ref="label" class="btn-inner">Speichern</div>
        </button>
        <tippy ref="tippy"
               tag="span"
               trigger="click"
               :class="{active: isVisible}"
               placement="bottom"
               animation="shift-toward-extreme"
               theme="dropdown-button"
               :interactive="true"
               :hide-on-click="true"
               @click.prevent="sendTrigger"
               @hide="handleHide"
               :append-to="appendToApp"
               arrow>
            <template #default>
                <button type="button" :class="['btn btn-primary', {'disabled': loading || disabled }]" :disabled="loading || disabled">
                    <svg-icon name="chevron-down" />
                </button>
            </template>
            <template #content="{ state, hide }">
                <div class="dropdown-btn-context" :class="{'with-arrow': arrow}"
                     ref="toggledropdown">
                    <div class="arrow" v-if="arrow"></div>

<!--                    <a class="dropdown-item" href="#" @click.prevent="saveExit">Speichern & Schließen</a>-->
                    <a v-if="saveNewBtn" class="dropdown-item" href="#" @click.prevent="saveNew">Speichern & Neu</a>
                    <a v-if="saveBackBtn" class="dropdown-item" href="#" @click.prevent="saveExit">Speichern & Übersicht</a>
                    <a v-if="saveHomeBtn" class="dropdown-item" href="#" @click.prevent="saveHome">Speichern & Startseite</a>
                </div>
            </template>

        </tippy>
    </div>




<!--    <div class="dropdown" v-click-outside="hideDD">-->
<!--        <button ref="savebtn"-->
<!--                type="button"-->
<!--                :class="['btn btn-primary', {'disabled': loading || disabled }]"-->
<!--                :style="buttonStyle"-->
<!--                @click="save"-->
<!--                :disabled="loading || disabled">-->
<!--            <div class="load-animation" v-if="loading">-->
<!--                <dot-loading color="#fff" size="5px"></dot-loading>-->
<!--            </div>-->
<!--            <div v-if="!loading" ref="label" class="btn-inner">Speichern</div>-->
<!--        </button>-->

<!--        <button type="button"-->
<!--                :class="['btn btn-primary dropdown-toggle dropdown-toggle-split', {'disabled': loading || disabled } ]"-->
<!--                :disabled="loading || disabled"-->
<!--                id="dropdownSave"-->
<!--                data-toggle="dropdown"-->
<!--                aria-haspopup="true"-->
<!--                aria-expanded="false"-->
<!--                data-reference="parent"-->
<!--                ref="dd"-->
<!--                @click.prevent="ddVisible = true">-->
<!--            <span class="sr-only">Toggle Dropdown</span>-->
<!--        </button>-->

<!--        <div class="dropdown-menu" :class="{show: ddVisible}" aria-labelledby="dropdownSave">-->
<!--            <a class="dropdown-item" href="#"-->
<!--               @click.prevent="saveExit">Speichern & Schließen</a>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>



const defaultPopperProps = {
    padding: 8,
    rootBoundary: 'document',
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [10, 10],
            },
        },

        {
            name: 'flip',
            enabled: true,
        },

        // {
        //     name: 'styles',
        //     enabled: true,
        //     phase: 'beforeWrite',
        //     fn: ({state}) => {
        //         if (state.elements.popper) {
        //             state.elements.popper.classList.add('show');
        //         }
        //         console.log('styles', state)
        //     }
        // },
        {
            name: 'styles',
            enabled: true,
            phase: 'afterWrite',
            fn: ({state}) => {
                if (state.elements.popper) {
                    state.elements.popper.classList.add('show');
                }
                console.log('styles', state)
            }
        },
        {
            name: 'preventOverflow',
            options: {
                tetherOffset: ({popper, reference, placement}) => popper.width / 2,
            },
        },
    ],
}


import DotLoading from "./../dot-loading";
import { getCurrentInstance, ref, computed, watch, onMounted, nextTick } from "vue";
import {createPopper} from "@popperjs/core";
export default {
    name: "save-button",
    components: {DotLoading},
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        popperProps: {
            type: Object,
            default: {}
        },
        position: {
            type: String,
            default: 'center'
        },
        arrow: {
            type: Boolean,
            default: true
        },

        saveExitBtn: {
			type: Boolean,
			default: true
		},
        saveBackBtn: {
            type: Boolean,
            default: true
        },
        saveNewBtn: {
            type: Boolean,
            default: false
        },
        saveHomeBtn: {
            type: Boolean,
            default: false
        },
    },

    emits: ['save'],

    setup(props, context) {
        let popperJs;
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const appendToApp = () => document.querySelector('#app');
        const btnstyle = ref({
            width: null,
            height: null
        })
        const visible = ref(false);
        const ddVisible = ref(false);
        const executing = ref(false);

        watch(() => props.loading, (n, o) => {
            executing.value = n;
        });

        const hasTriggerSlot = computed(() => {
            return context.slots.trigger && !_.isEmpty(context.slots.trigger);
        });



        const buttonStyle = computed(() => {
            return {
                width: btnstyle.value.width,
                height: btnstyle.value.height
            }
        });


        const btnSlots = computed(() => {
            return inst.slots;
        });


        onMounted(() => {
            nextTick(() => {
                nextTick(() => {
                    nextTick(() => {
                        btnstyle.value.width = inst.refs.savebtn.offsetWidth + 'px';
                        btnstyle.value.height = inst.refs.savebtn.offsetHeight + 'px';
                    });
                });
            });
        });


        function sendTrigger(event) {
            visible.value = true;
        }

        function handleHide(event) {
            visible.value = false;
        }


        function createPopperInstance() {
            destroyPopper()

            nextTick(() => {
                if ($root.vnode.el) {
                    let opts = Object.assign({}, defaultPopperProps, { rootBoundary: document.querySelector('#app') }, props.popperProps);

                    $root.appendToAppNode(inst.refs.toggledropdown);

                    // Add start style
                    let triggerPos = inst.refs.trigger.getBoundingClientRect();
                    let w = inst.refs.toggledropdown.offsetWidth;

                    inst.refs.toggledropdown.style.position = 'fixed';
                    popperJs = createPopper(inst.refs.trigger, inst.refs.toggledropdown, opts);
                }

            })
        }


        function updatePopper() {
            if (popperJs) {
                popperJs.scheduleUpdate()
            }
        }

        function destroyPopper() {
            if (popperJs) {
                if (inst.refs.toggledropdown) {
                    inst.refs.toggledropdown.classList.remove('show')
                }

                popperJs.destroy();
                popperJs = null;
            }
        }


        function open() {
            visible.value = true;

            createPopperInstance();

        }

        function close(event, el) {
            destroyPopper()

            if (event) {
                if (el === event.target && event.type === 'click') {
                    event.preventDefault();
                    return event;
                }
            }

            if (!visible.value) {
                return event;
            }

            visible.value = false;
        }
















        function handleClick(event) {

            btnstyle.value.width = inst.refs.savebtn.offsetWidth + 'px';
            btnstyle.value.height = inst.refs.savebtn.offsetHeight + 'px';

            nextTick(() => {
                context.emit('clicked', event);
            })
        }


        function save() {
            if (props.executing || props.disabled) return;
            return context.emit('save', false)
        }

        function saveExit() {
            if (props.executing || props.disabled) return;
            ddVisible.value = false;
            return context.emit('save', true)
        }

        function saveNew() {
            if (props.executing || props.disabled) return;
            ddVisible.value = false;
            return context.emit('save', 'new')
        }

        function saveHome() {
            if (props.executing || props.disabled) return;
            ddVisible.value = false;
            return context.emit('save', 'home')
        }


        function hideDD() {
            ddVisible.value = false;
        }

        return {
            isVisible: visible,
            hasTriggerSlot,
            open,
            close,
            sendTrigger,
            appendToApp,
            handleHide,



            ddVisible,
            executing,
            buttonStyle,
            btnSlots,
            handleClick,

            save,
            saveExit,
            saveNew,
            saveHome,
            hideDD
        }
    },

    //
    //
    // data() {
    //     return {
    //         btnstyle: {
    //             width: null,
    //             height: null
    //         },
    //         executing: false,
    //         dd: null
    //     }
    // },
    // computed: {
    //     buttonStyle() {
    //         return {
    //             width: this.btnstyle.width,
    //             height: this.btnstyle.height
    //         }
    //     }
    // },
    //
    // beforeDestroy() {
    //
    // },
    // mounted() {
    //     this.btnstyle.width = this.$refs.savebtn.offsetWidth + 'px';
    //     this.btnstyle.height = this.$refs.savebtn.offsetHeight + 'px';
    //
    //     if (this.$refs.dd) {
    //         let dd = this.$refs.dd;
    //         if (dd) {
    //             new Dropdown(dd);
    //         }
    //     }
    // },
    // methods: {
    //     save() {
    //         if (this.executing || this.disabled) return;
    //
    //         return this.$emit('save', false)
    //     },
    //     saveExit() {
    //         if (this.executing || this.disabled) return;
    //
    //         return this.$emit('save', true)
    //     },
    // }
}
</script>
