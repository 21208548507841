<template>
	<template v-if="container">
		<div class="badge-placeholder text-right pr-5">
		    <span class="badge badge-dark">
		        FID: {{ val }}
		    </span>
		</div>
	</template>
	<template v-else>
		<span class="badge badge-dark">
	        FID: {{ val }}
	    </span>
	</template>
</template>
<script>
import {watch, ref} from "vue"

export default {
	name: 'organisation-badge',
	props: {
		container: {
			type: Boolean,
			default: false
		},
		id: {}
	},
	setup(props, context) {
		const val = ref(null);
		watch(() => props.id, (n, o) => {
			if (n) {
				if (_.isString(n) || _.isNumber(n)) {
					val.value = n;
				} else {
					val.value = null;
				}
			} else {
				val.value = null;
			}
		}, {immediate: true});

		return {
			val
		}
	}
}
</script>
<style scoped>

</style>
