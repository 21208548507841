
import serialize from "@octetstream/object-to-form-data"
export function convertToFormData(data, formData, parentKey) {
    // var parsedobj = JSON.parse(JSON.stringify(data))
    // return serialize(parsedobj);
    //



    formData = formData || new FormData();
    if(data === null || data === undefined) {
        formData.append(parentKey, null);
        return formData;
    }

    if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            // if (data[key] instanceof File) {
            //
            // }
            // else {
                convertToFormData(data[key], formData, (!parentKey ? key : (data[key] instanceof File ? `${parentKey}[${key}]` : `${parentKey}[${key}]`)))
            // }
        });
    } else {

        // if (data instanceof File) {
        //     formData.append(parentKey, data.fileData);
        // }
        // else {
        //     formData.append(parentKey, data);
        // }

        formData.append(parentKey, data);
    }

    return formData;
}
