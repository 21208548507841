<script>
import {computed, defineComponent, getCurrentInstance, inject, markRaw, nextTick} from 'vue'
import {useStore} from "~/vuex";
import {useFormDefaults} from "neloh-ui/mixins/use-form";
import {useRouter} from "~/vue-router";

import userpermissionsfields from './user-permissions-fields.vue'

export default defineComponent({
    name: "UserEdit",
    props: {},
    setup(props, context) {

        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const store = useStore();
        const router = useRouter();
        const $events = inject('$events');
        const changeBreadcrumb = inject('changeBreadcrumb')

        const {
            formsaving,
            rendersections,
            model,
            errors,
            isDirty,
            isCreate,
            formFields,
            formTabs,
            loading,
            resetDirty,
            primaryKeyValue,
            isUpdate,
            loadForm,
            saveForm,
            onReset,
            onCancel,
        } = useFormDefaults(props, context);

        rendersections.value = markRaw({
            'user-permissions-fields': userpermissionsfields
        });


        loadForm(ajaxLoaded);

        const toolbarDescription = computed(() => {
            if (loading.value) return ''

            if (isCreate.value) return 'Benutzer anlegen';
            else return 'Benutzer "' + model.value.username + '" bearbeiten';
        })

        function ajaxLoaded(response) {
            nextTick(() => {
                store.dispatch('form/resetFormModel');
                changeBreadcrumb(response)
            })
        }

        function submitForm(exit) {

            let routeParams = {};

            if (isUpdate()) {
                routeParams = {user: primaryKeyValue()};
            }

            console.log(model);

            saveForm(exit, model.value, routeParams).then((data) => {
                if (exit === true) {
                    router.replace({name: 'admin.users'});
                }
                else if (exit === 'new') {

                    nextTick(() => {
                        resetDirty()
                    });

                    router.replace({name: 'admin.users.create'});
                }
                else if (exit === 'home') {

                    nextTick(() => {
                        resetDirty()
                    });

                    router.replace({name: 'admin.dashboard'});
                }
                else {
                    nextTick(() => {
                        resetDirty()
                    });
                }
            }).catch(error => {
                $root.notification(error);
            })
        }


        return {
            errors,
            formTabs,
            formFields,
            model,
            rendersections,
            isDirty,
            formsaving,
            isCreate,
            toolbarDescription,
            submitForm,
            onCancel,
            onReset,
        }

    }
})
</script>

<template>
    <form-container :errors="errors" ref="formcontainer">
        <toolbar ref="toolbar"
                 :description="toolbarDescription"
                 @submit="submitForm">
            <div class="btn-toolbar" role="toolbar">
                <div class="btn-group mr-5">
                    <button @click="onCancel"
                            type="button"
                            :disabled="isDirty"
                            class="btn btn-primary">
                        Abbrechen
                    </button>
                </div>
                <div class="btn-group mr-5">
                    <action-button :disabled="!isDirty"
                                   @clicked="onReset">
                        Zurücksetzen
                    </action-button>
                    <save-button :disabled="isDirty !== true"
                                 :loading="formsaving"
                                 @save="submitForm"></save-button>
                </div>
            </div>


            <template v-slot:description>
            </template>
        </toolbar>


        <form-layout-tabs :fields="formTabs"
                          :form-fields="formFields"
                          :form-model.sync="model"
                          :responseerrors="errors"
                          ref="formtabs"
        ></form-layout-tabs>

        <form-layout-content
            :fields="formFields"
            :form-model.sync="model"
            :rendersections="rendersections"
            :responseerrors="errors"
        >
        </form-layout-content>


    </form-container>
</template>

<style scoped>

</style>
