<template>
	<div class="auth-container">
		<div class="auth-form-container">
            <div class="auth-form">



                <form ref="form" action="backend/auth/login" method="post">
                    <input type="hidden" name="_token" value="" ref="token_field">


	                <div class="login-container">
		                <div class="login-header">Amino Login</div>
		                <div class="login-content">

                            <loading :active="loading === true"
                                     color="blue"
                                     :width="40"
                                     :height="40"
                                     :opacity="0.6"
                                     :is-full-page="false">
                            </loading>


			                <template v-if="errors">
				                <form-errors :errors="errors"/>
			                </template>

			                <form-text
                                name="username"
                                :form-model="model"
                                :field="{label: 'Benutzername'}"
                                @enter="(e) => {
                                    if (model.username && model.password) {
                                        login(e)
                                    }
                                }"/>

			                <form-password
                                name="password"
                                :form-model="model"
                                :field="{label: 'Passwort'}"
                                @enter="(e) => {
                                    if (model.username && model.password) {
                                        login(e)
                                    }
                                }"
                            />

			                <div class="btns mt-4">
				                <button type="button"
				                        class="btn btn-primary"
				                        :disabled="!model.username || !model.password"
				                        @click.prevent="login">Login</button>
				                <button type="button" class="btn btn-primary" @click.prevent="lostPassword">
					                Passwort vergessen
				                </button>
			                </div>
		                </div>


		                <div class="copyright">
			                <small>&copy; 2024 by Neloh&trade; Software - Version {{ version }}
<!--				                <br/>written by Marcel Domke, <a href="https://lara.vision" target="_blank">Lara.Vision</a>-->
			                </small>
		                </div>

	                </div>
                </form>
            </div>
		</div>
	</div>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted, computed, inject, ref, onBeforeMount} from 'vue'
import {useStore} from "~/vuex"
import {useRoute, useRouter} from "~/vue-router";
import {getHtmlCsrfToken} from "./../utils/auth";

export default defineComponent({
	name: "AuthPage",
	setup(props, context) {
        const inst = getCurrentInstance();
		const store = useStore();
		const router = useRouter();

        const appname = inject('appname');
        const version = inject('version');

        const model = ref({
            password: null,
            username: null
        });

        const loading = ref(false)


        const formErrors = computed(() => {
            return store.getters['form/formErrors']
        })

        const errors = computed(() => {
            if (!_.isArray(formErrors.value) && _.isObject(formErrors.value) && Object.keys(formErrors.value).length) {
                return formErrors.value;
            }
            else if (_.isArray(formErrors.value) && formErrors.value.length) {
                return formErrors.value;
            }
            return null;
        });



        onBeforeMount(() => {
            if (store.getters['user/isLoggedIn']) {
                router.push({name: 'admin.dashboard'})
            }
        })



        onMounted(() => {
            if (inst.refs.token_field) {
                inst.refs.token_field.value = getHtmlCsrfToken();
            }
        })

		function login(evt) {
            evt.preventDefault();
            loading.value = true;
            const form = new FormData();

            form.append('username', model.value.username);
            form.append('password', model.value.password);
            form.append('_token', getHtmlCsrfToken());


            inst.refs.form.submit();

			// store.dispatch('setUser', {
			// 	user_id: 1
			// });
            //
			// nextTick(() => {
			// 	router.replace({name: 'redirect', params: {path: '/'} });
			// })
		}


        function lostPassword() {
            router.push({name: 'reset-password'})
        }

		// const enablePageloading = inject('enablePageloading')
		// enablePageloading();

		return {
            model,
            errors,
            loading,
			login,
            lostPassword,
            appname,
            version
		}
	}
})
</script>


