<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Demo"
})
</script>

<template>
    DEMO VIEW jhkhhkki
</template>

<style scoped>

</style>
