import AmsIndex from './components/AmsIndex.vue';
import AmsEdit from './components/AmsEdit.vue';

export default [
    {
        path: '/ams',
        component: AmsIndex,
        name: 'admin.ams',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },

    {
        path: '/ams/edit/:item',
        component: AmsEdit,
        name: 'admin.ams.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },


    {
        path: '/ams/create',
        component: AmsEdit,
        name: 'admin.ams.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
];
