const getters = {
    notificationcache: state => state.notificationcache.notify,


    // formDefinition: state => state.form.formDefinition,
    // formTabs: state => state.form.formTabs,
    // formTabActive: state => state.form.activeTab,
    // formFields: state => state.form.formFields,
    // formModel: state => state.form.model,
    // formDirty: state => state.form.dirty,
    // formErrors: state => state.form.errors,
    // formToolbar: state => state.form.toolbar,
    // formName: state => state.form.formName,



    dataTableCache: state => state.DataTableCache.cache,
    dataTableFilters: state => state.DataTableCache.filters,
    dataTableLoading: state => state.DataTableCache.loading,


    responseError: state => state.responseError.error,


    changesCounters: state => state.changes.counters,
}
export default getters;
