<template>
	<ul class="nav nav-tabs nav-justified">
		<li v-for="(tab, index) in mutatedTabs"
			:class="{ active: activeItem === tab.id }"
			@click="setActive(tab)">
			{{ tab.label }}
		</li>
	</ul>
</template>

<script>
import {watch, ref, getCurrentInstance} from "vue";

export default {
	name: "Tabs",
	props: {
		tabs: {
			type: Array,
			required: true
		},
		active: {

		}
	},
	data() {
		return {
			t: [],
			mutatedTabs: [],
			activeItem: null
		}
	},

	watch: {
		active(n, o){
			if (n !== o && n) {
				this.activeItem = n;
			}
		},
		tabs(n, o) {
			this.t = n;
			this.$nextTick(() => {

				this.activeItem = null;

				if (_.isNumber(this.active)) {
					this.activeItem = this.active
				}
				else if (_.isString(this.active)) {
					this.activeItem = this.active
				}

				this.prepareMutatedTabs();
			})
		}
	},
	beforeMount() {

		this.activeItem = null;

		if (_.isNumber(this.active)) {
			this.activeItem = this.active
		}
		else if (_.isString(this.active)) {
			this.activeItem = this.active
		}

		this.t = _.cloneDeep(this.tabs);

		this.prepareMutatedTabs();
	},

	mounted() {
		this.$nextTick(() => {
			this.$emit('input', this.activeItem)
		})
	},

	methods: {
		prepareMutatedTabs() {
			let mutatedTabs = [];
			_.each(_.cloneDeep(this.t), (tab, index) => {

				if (_.isObject(tab) && !tab.hasOwnProperty('active')) {
					tab.active = false;
				}

				if (this.activeItem !== null) {
					if (_.isNumber(this.activeItem) && this.activeItem === index) {
						tab.active = true;
					}
					else if (_.isString(this.activeItem) && this.activeItem === tab.id) {
						tab.active = true;
					}
				}

				mutatedTabs.push(tab);
			});

			this.mutatedTabs = mutatedTabs;
		},

		isActive (tab) {
			return tab.active
		},

		setActive (tab) {

			_.each(this.mutatedTabs, (tab) => {
				if (tab.active) {
					tab.active = false;
				}
			});

			tab.active = true;
			this.activeItem = tab.id;
			this.$emit('input', this.activeItem)
		}
	}

}
</script>

<style scoped>

</style>
