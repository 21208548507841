<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "~/vue"
import {useFormField, defaultFieldProps} from "neloh-ui/mixins/use-form-field";


const addModelDummy = {
    usage_status: null,
    sourceable: null,
};

const usage_status_options = [
    {label: '-- Nutzungsstatus auswählen --', value: null},
    {label: 'Hat geholfen', value: 1},
    {label: 'Hat nicht geholfen (nicht hilfreich/erfolglos)', value: 2},
    {label: 'Für mich nicht verfügbar', value: 3},
]


export default defineComponent({
    name: "source-options",
    props: {
        ...defaultFieldProps,
    },
    components: {
        'upload-field': require('neloh-ui/components/forms/upload.vue').default
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        let cacheNewId = 1;
        const isMounted = ref(false);

        function updateModel(value, key) {
            if (props.field.disabled) return;

            if (props.formModel.hasOwnProperty(key)) {
                props.formModel[key] = value;
            }
        }

        function addModel(key) {
            if (props.field.disabled) return;

            if (!Array.isArray(props.formModel[key])) {
                props.formModel[key] = [];
            }


            if (props.formModel.hasOwnProperty(key)) {
                cacheNewId++;

                let n = Object.assign(_.cloneDeep(addModelDummy), {
                    id: 'n'+ cacheNewId
                });

                if (key === 'files') {
                    n.uploadfile = null;
                    n.useUpload = false
                }

                props.formModel[key].push(n);
            }
        }

        function removeEntry(key, id) {
            if (props.field.disabled) return;

            if (props.formModel.hasOwnProperty(key)) {
                props.formModel[key] = _.filter(_.cloneDeep(props.formModel[key]), (r) => {
                    return r.id !== id;
                });
            }
        }

        function hasSources(type) {
            return props.formModel.hasOwnProperty(type) && Array.isArray(props.formModel[type]) && props.formModel[type].length > 0
        }


        onMounted(() => {
            isMounted.value = true;
        })


        return {
            updateModel,
            addModel,
            removeEntry,
            hasSources,
            usage_status_options
        }

    }
})
</script>

<template>
    <div class="add-source-buttons">
        <div class="btn-group">
            <button type="button" class="btn btn-primary"
                    :class="{'btn-disabled': field.disabled }"
                    :disabled="field.disabled"
                    @click.prevent="addModel('links')">Link hinzufügen</button>
            <button type="button" class="btn btn-primary"
                    :class="{'btn-disabled': field.disabled }"
                    :disabled="field.disabled"
                    @click.prevent="addModel('literatures')">Literatur hinzufügen</button>
            <button type="button" class="btn btn-primary"
                    :class="{'btn-disabled': field.disabled }"
                    :disabled="field.disabled"
                    @click.prevent="addModel('files')">Anlage hinzufügen</button>
        </div>
    </div>



    <form-section :field="{label: 'Links', class: 'source-options source-links'}" v-if="Array.isArray(formModel.links) && formModel.links.length > 0">
        <div class="array-items" :class="{disabled: field.disabled}">
            <template v-for="(r, index) in formModel.links">
                <div class="array-item" v-if="r && r.id">
                    <div class="remove" :class="{disabled: field.disabled}">
                        <svg-icon name="trash" v-if="!field.disabled" v-tooltip="'Link entfernen'" @click.prevent="removeEntry('links', r.id)"/>
                    </div>




                    <div>
                        <form-select name="sourceable"
                                     :form-model="r"
                                     :field="{
                                     label: 'Link',
                                     route: 'admin.requests.autocomplete.links',
                                     autocomplete: true,
                                     value: r.sourceable,
                                     class: 'source',
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }
                                 }"
                                     :responseerrors="responseerrors"
                                     @input="(value) => updateModel(value, 'links.sourceable['+ index +']')">
                        </form-select>
                    </div>



                    <template v-if="r.sourceable && r.id > 0">
                            <source-details-btn
                                :entry="r"
                                :form-model="formModel"
                                type="links"
                                :id="r.id"/>
                    </template>


                    <form-select name="usage_status"
                                 :form-model="r"
                                 :responseerrors="responseerrors"
                                 :field="{label: 'Nutzungsstatus', options: usage_status_options,
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }}"></form-select>
                </div>
            </template>
        </div>

    </form-section>

    <form-section :field="{label: 'Literatur', class: 'source-options source-literatures'}" v-if="Array.isArray(formModel.literatures) && formModel.literatures.length > 0">
        <div class="array-items" :class="{disabled: field.disabled}">
            <template v-for="(r, index) in formModel.literatures">
                <div class="array-item" v-if="r && r.id">
                    <div class="remove" :class="{disabled: field.disabled}">
                        <svg-icon name="trash" v-if="!field.disabled" v-tooltip="'Literatur entfernen'" @click.prevent="removeEntry('literatures', r.id)"/>
                    </div>

                    <form-select name="sourceable"
                                 :form-model="r"
                                 :field="{
                                     label: 'Literatur',
                                     route: 'admin.requests.autocomplete.literatures',
                                     autocomplete: true,
                                     value: r.sourceable,
                                     class: 'source',
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }
                                 }"
                                 :responseerrors="responseerrors"
                                 @input="(value) => updateModel(value, 'literatures.sourceable['+ index +']')">
                    </form-select>

                    <template v-if="r.sourceable && r.id > 0">
                            <source-details-btn
                                :entry="r"
                                :form-model="formModel"
                                type="literatures"
                                :id="r.id"/>
                    </template>

                    <form-select name="usage_status"
                                 :form-model="r"
                                 :responseerrors="responseerrors"
                                 :field="{label: 'Nutzungsstatus', options: usage_status_options,
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }}"></form-select>
                </div>
            </template>
        </div>
    </form-section>

<!--    <form-section :field="{label: 'News', class: 'source-options'}">-->
<!--        <div class="array-items">-->
<!--            <template v-for="(r, index) in usedModel.newspapers">-->
<!--                <div class="array-item" v-if="r && r.id">-->
<!--                    <div class="remove">-->
<!--                        <svg-icon name="trash" v-tooltip="'News entfernen'" @click.prevent="removeEntry('newspapers', r.id)"/>-->
<!--                    </div>-->

<!--                    <form-select name="sourceable"-->
<!--                                 :form-model="r"-->
<!--                                 :field="{-->
<!--                                     label: 'News',-->
<!--                                     route: 'admin.requests.autocomplete.newspapers',-->
<!--                                     autocomplete: true,-->
<!--                                     value: r.sourceable,-->
<!--                                     class: 'source'-->
<!--                                 }"-->
<!--                                 @input="(value) => updateModel(value, 'newspapers.sourceable['+ index +']')">-->
<!--                    </form-select>-->
<!--                    <form-select name="usage_status"-->
<!--                                 :form-model="r"-->
<!--                                 :field="{label: 'Nutzungsstatus', options: usage_status_options}"></form-select>-->
<!--                </div>-->
<!--            </template>-->

<!--            <template v-if="!hasSources('newspapers')">-->
<!--                <div class="array-empty">-->
<!--                    Es sind keine News Quellen hinterlegt-->
<!--                </div>-->
<!--            </template>-->
<!--        </div>-->
<!--        <div class="array-add">-->
<!--            <button type="button" class="btn btn-primary" @click.prevent="addModel('newspapers')">News hinzufügen</button>-->
<!--        </div>-->
<!--    </form-section>-->

    <form-section :field="{label: 'Anlagen', class: 'source-options source-files'}" v-if="Array.isArray(formModel.files) && formModel.files.length > 0">
        <div class="array-items" :class="{disabled: field.disabled}">
            <template v-for="(r, index) in formModel.files">
                <div class="array-item" v-if="r && r.id">
                    <div class="remove" :class="{disabled: field.disabled}">
                        <svg-icon name="trash" v-if="!field.disabled" v-tooltip="'Datei entfernen'" @click.prevent="removeEntry('files', r.id)"/>
                    </div>
                    <template v-if="!r.useUpload">
                        <form-select
                            name="sourceable"
                            :form-model="r"
                            :field="{
                                     label: 'Ablage',
                                     route: 'admin.requests.autocomplete.files',
                                     autocomplete: true,
                                     value: r.sourceable,
                                     class: 'source',
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }
                                 }"
                            :responseerrors="responseerrors"
                            @input="(value) => updateModel(value, 'files.sourceable['+ index +']')">
                        </form-select>
                    </template>
                    <template v-else>
                        <upload-field
                            name="uploadfile"
                            :form-model="r"
                            :responseerrors="responseerrors"
                            :field="{label: 'Datei Upload', class: 'source',
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }}"
                            @input="(value) => {
                                r.uploadfile = value && value.fileData ? value.fileData : null;
                            }"
                        ></upload-field>
                    </template>



                    <div class="opt-buttons">
                        <button type="button"
                                class="btn btn-primary"
                                :class="{'btn-disabled': field.disabled }"
                                :disabled="field.disabled"
                                v-tooltip="'Datei Upload'" v-if="!r.useUpload" @click.prevent="r.useUpload = true">
                            <svg-icon name="upload"/>
                        </button>

                        <button type="button"
                                class="btn btn-primary"
                                :class="{'btn-disabled': field.disabled }"
                                :disabled="field.disabled"
                                v-tooltip="'Datei auswahl'" v-if="r.useUpload" @click.prevent="r.useUpload = false">
                            <svg-icon name="file"/>
                        </button>
                    </div>

                    <template v-if="r.sourceable && r.id > 0">

                            <source-details-btn
                                :entry="r"
                                :form-model="formModel"
                                type="files"
                                :id="r.id"/>
                    </template>

                    <form-select name="usage_status"
                                 :form-model="r"
                                 :field="{label: 'Nutzungsstatus', options: usage_status_options,
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }}"></form-select>
                </div>
            </template>

        </div>

    </form-section>
</template>

<style scoped>

</style>
