<template>
	<div v-if="tablePagination && tablePagination.last_page > 1" :class="css.wrapperClass">
		<a href="#" @click.prevent="loadPage(1)"
		   :class="['btn-nav', css.linkClass, isOnFirstPage ? css.disabledClass : '']">
			<i v-if="css.icons.first !== ''" :class="[css.icons.first]"></i>
			<span v-else>&laquo;</span>
		</a>
		<a href="#" @click.prevent="loadPage('prev')"
		   :class="['btn-nav', css.linkClass, isOnFirstPage ? css.disabledClass : '']">
			<i v-if="css.icons.next !== ''" :class="[css.icons.prev]"></i>
			<span v-else>&nbsp;&lsaquo;</span>
		</a>
		<template v-if="notEnoughPages">
			<template v-for="n in totalPage">
				<a href="#" @click.prevent="loadPage(n)"
				   :class="[css.pageClass, isCurrentPage(n) ? css.activeClass : '']"
				   v-html="n">
				</a>
			</template>
		</template>
		<template v-else>
			<template v-for="n in windowSize">
				<a href="#" @click.prevent="loadPage(windowStart+n-1)"
				   :class="[css.pageClass, isCurrentPage(windowStart+n-1) ? css.activeClass : '']"
				   v-html="windowStart+n-1">
				</a>
			</template>
		</template>
		<a href="#" @click.prevent="loadPage('next')"
		   :class="['btn-nav', css.linkClass, isOnLastPage ? css.disabledClass : '']">
			<i v-if="css.icons.next !== ''" :class="[css.icons.next]"></i>
			<span v-else>&rsaquo;&nbsp;</span>
		</a>
		<a href="#" @click.prevent="loadPage(totalPage)"
		   :class="['btn-nav', css.linkClass, isOnLastPage ? css.disabledClass : '']">
			<i v-if="css.icons.last !== ''" :class="[css.icons.last]"></i>
			<span v-else>&raquo;</span>
		</a>
	</div>
</template>

<script>
import {computed, watch, getCurrentInstance, ref, onMounted} from "vue";

export default {
	props: {
		currentPageNumber: {
			default() {
				return 1
			}
		},
		css: {
			type: Object,
			default() {
				return {
					wrapperClass: 'ui right floated pagination menu',
					activeClass: 'active large',
					disabledClass: 'disabled',
					pageClass: 'item',
					linkClass: 'icon item',
					paginationClass: 'ui bottom attached segment grid',
					paginationInfoClass: 'left floated left aligned six wide column',
					dropdownClass: 'ui search dropdown',
					icons: {
						first: 'angle double left icon',
						prev: 'left chevron icon',
						next: 'right chevron icon',
						last: 'angle double right icon',
					}
				}
			}
		},
		onEachSide: {
			type: Number,
			default() {
				return 2
			}
		},
		pagination: {
			type: [Object, Array],
			default() {
				return {
					total: 0
				}
			}
		}
	},



    setup(props, context) {
        const inst = getCurrentInstance();
        const eventPrefix = 'vuetable-pagination:';
        const tablePagination = ref(null);


        watch(props.pagination, (n, o) => {
            tablePagination.value = n;
        });

        const totalPage = computed(() => {
            return !_.isObject(tablePagination.value) ? 0 : tablePagination.value.last_page;
        });

        const isOnFirstPage = computed(() => {
            return !_.isObject(tablePagination.value) ? false : tablePagination.value.current_page === 1;
        });

        const isOnLastPage = computed(() => {
            return !_.isObject(tablePagination.value) ? false : tablePagination.value.current_page === tablePagination.value.last_page;
        });

        const notEnoughPages = computed(() => {
            return totalPage.value < (props.onEachSide * 2) + 4;
        });

        const windowSize = computed(() => {
            return props.onEachSide * 2 + 1;
        });

        const windowStart = computed(() => {
            if (!tablePagination.value || tablePagination.value.current_page <= props.onEachSide)
            {
                return 1;
            } else if (tablePagination.value.current_page >= (totalPage.value - props.onEachSide))
            {
                return totalPage.value - props.onEachSide * 2;
            }

            return tablePagination.value.current_page - props.onEachSide;
        });


        onMounted(() => {
            tablePagination.value = props.pagination;

            watch(() => props.pagination, (n, o) => {
                if (n !== o) {
                    tablePagination.value = n;
                }
            }, {deep: true});
        });


        function loadPage(p) {
            context.emit(eventPrefix + 'change-page', p);
        }

        function isCurrentPage(p) {
            return p === tablePagination.value.current_page;
        }

        function setPaginationData(tablePagination) {
            this.tablePagination.value = tablePagination;
        }

        function resetData() {
            tablePagination.value = null;
        }

        return {
            totalPage,
            isOnFirstPage,
            isOnLastPage,
            notEnoughPages,
            windowStart,
            windowSize,

            tablePagination,
            loadPage,
            isCurrentPage,
            setPaginationData,
            resetData
        }
    },


    //
    //
    //
    //
    //
    //
    //
	// watch: {
	// 	pagination(n) {
	// 		this.tablePagination = n;
	// 	}
	// },
	// data: function (){
	// 	return {
	// 		eventPrefix: 'vuetable-pagination:',
	// 		tablePagination: null
	// 	}
	// },
	// computed: {
	// 	totalPage() {
	// 		return this.tablePagination === null
	// 			? 0
	// 			: this.tablePagination.last_page;
	// 	},
	// 	isOnFirstPage() {
	// 		return this.tablePagination === null ? false : this.tablePagination.current_page === 1;
	// 	},
	// 	isOnLastPage() {
	// 		return this.tablePagination === null ? false : this.tablePagination.current_page === this.tablePagination.last_page;
	// 	},
	// 	notEnoughPages() {
	// 		return this.totalPage < (this.onEachSide * 2) + 4;
	// 	},
	// 	windowSize() {
	// 		return this.onEachSide * 2 + 1;
	// 	},
	// 	windowStart() {
	// 		if (!this.tablePagination || this.tablePagination.current_page <= this.onEachSide)
	// 		{
	// 			return 1;
	// 		} else if (this.tablePagination.current_page >= (this.totalPage - this.onEachSide))
	// 		{
	// 			return this.totalPage - this.onEachSide * 2;
	// 		}
    //
	// 		return this.tablePagination.current_page - this.onEachSide;
	// 	},
	// },
    //
    //
    // mounted() {
    //     this.tablePagination = this.pagination;
    //
    //     this.$watch('pagination', (n, o) => {
    //         if (n !== o) {
    //             this.tablePagination = n;
    //         }
    //     }, {deep: true});
    // },
    //
    // methods: {
	// 	loadPage(page) {
	// 		//this.$parent.$refs.vuetable.changePage(page)
	// 		this.$emit(this.eventPrefix + 'change-page', page);
	// 	},
	// 	isCurrentPage(page) {
	// 		// if (this.currentPageNumber > 1) {
	// 		//     return page === this.currentPageNumber;
	// 		// }
	// 		return page === this.tablePagination.current_page;
	// 	},
	// 	setPaginationData(tablePagination) {
	// 		this.tablePagination = tablePagination;
	// 		this.$nextTick(() => {
	// 			this.$forceUpdate();
	// 		});
	// 	},
	// 	resetData() {
	// 		this.tablePagination = null;
	// 		this.$nextTick(() => {
	// 			this.$forceUpdate();
	// 		});
	// 	}
	// }
}
</script>
