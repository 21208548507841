<script>
import {computed, defineComponent, getCurrentInstance, inject, onBeforeMount, onMounted, nextTick, ref, toRaw} from 'vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default defineComponent({
    name: "LiteratureAnalyse",
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const store = useStore();
        const router = useRouter();
        const $http = inject('$http');
        const changeBreadcrumb = inject('changeBreadcrumb')
        const isInitLoaded = ref(false);
        const loading = ref(false);
        const loadedData = ref(false);

        const viewMode = ref('table');


        const newspapers = ref([]);
        const analyseModel = ref({
            begin_date: null,
            end_date: null,
            newspaper: null
        });

        const analyseData = ref({
            model: null,
            data: null,
            apexData: {
                series: []
            }
        });




        const chartOptions = ref({
            plotOptions: {
                bar: {
                    hideZeroBarsWhenGrouped: true,
                    horizontal: true,
                    barHeight: '90%',
                    columnWidth: '94%',
                    distributed: false,
                }
            },
            // stroke: {
            //     width: 1,
            //     colors: ['#fff']
            // },
            chart: {
                id: 'vuechart-example',
                stacked: false,
                animations: {
                    enabled: false
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 150,
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                }
            },

            yaxis: {
                show: true,
                min: 0,
                labels: {
                    minWidth: 600
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#78909C',
                    width: 15,
                    offsetX: 0,
                    offsetY: 0
                },
            },
            xaxis: {

                categories: [],
                min: 0,
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#78909C',
                    width: 15,
                    offsetX: 0,
                    offsetY: 0
                },
            },
        })

        const hasAnalyseData = computed(() => {
             return analyseData.value.model && analyseData.value.data;
        });

        const canSubmit = computed(() => {
            return analyseModel.value.begin_date && analyseModel.value.newspaper;
        });

        function initForm() {
            $http.post( $root.routes('admin.literature.analyse.index') ).then((r) => {
                if (r.data.success) {
                    newspapers.value = r.data.newspaper;
                    isInitLoaded.value = true;


                    changeBreadcrumb(r.data)
                }
            });
        }


        function prepareApexData(analyse, m) {
            if (m.newspaper && m.newspaper.id === 999999) {



                let series = [];
                if (analyse && Array.isArray(analyse)) {

                    _.each(analyse, (r) => {

                        let s1 = [], s2 = []

                        _.each(r.entries, (ts) => {
                            s1.push(ts.total);
                            // s2.push(ts.issues);
                        });

                        chartOptions.value.xaxis.categories.push(r.title)
                        series.push({
                            data: s1,
                            group: 'total',
                            name: 'Total'
                        });
                        // series.push({
                        //     data: s2,
                        //     group: 'issues',
                        //     name: 'Issues'
                        // });
                    });
                }

                return { series: series }
            }

            return { series: [] }
        }

        function getAnalyseData() {

            if (loading.value) return;

            loading.value = true;

            $http.post( $root.routes('admin.literature.analyse.analyse'), analyseModel.value ).then((r) => {

                loading.value = false;

                if (r.data.success) {

                    viewMode.value = 'table'


                    let m =  toRaw(_.cloneDeep(analyseModel.value));
                    let preparedApexData = prepareApexData(r.data.analyse, m);
                    analyseData.value = {
                        model: toRaw(_.cloneDeep(analyseModel.value)),
                        data: r.data.analyse,
                        apexData: {
                            series: r.data.series,
                        }
                    };

                    chartOptions.value.xaxis.categories = r.data.categories

                    changeBreadcrumb(r.data)

                    loadedData.value = true;
                }
                else {
                    analyseData.value = {}
                }

            }).catch(() => {
                loading.value = false;
                analyseData.value = {}
            });
        }


        onBeforeMount(() => {
            initForm()
        });

        function cloneDeep(val) {
            return _.cloneDeep(val)
        }


        return {
            isInitLoaded,
            loading,
            viewMode,
            newspapers,
            analyseModel,
            analyseData,
            hasAnalyseData,
            canSubmit,
            chartOptions,
            cloneDeep,
            getAnalyseData
        }
    }
})
</script>

<template>
    <div class="literature-analyse">
        <loading :active="loading === true"
                 color="blue"
                 :width="20"
                 :height="20"
                 :opacity="0.6"
                 :is-full-page="false">
            <template v-slot:after>
                <div class="m">Hole Daten...</div>
            </template>
        </loading>
        <div class="literature-analyse-filters" v-if="isInitLoaded">
            <form-section :field="{label: 'Auswerten', class: 'analyse-filters' }">
                <div>
                    <form-select-newspaper
                        name="newspaper"
                        :form-model="analyseModel"
                        @input="(v) => { analyseModel.newspaper = v; }"
                        :field="{
                                label: 'Zeitschrift',
                                autocomplete: true,
                                placeholder: 'Zeitschrift auswählen',
                                options: newspapers,
                                cols: 12,
                                filterable: true
                        }"/>

                    <form-date name="begin_date" :form-model="analyseModel" :field="{ label: 'Datum ab', cols: 6 }"/>
                    <form-date name="end_date" :form-model="analyseModel" :field="{ label: 'Datum bis', cols: 6 }"/>
                </div>
                <div>
                    <button type="button"
                            class="btn btn-primary"
                            :disabled="!canSubmit"
                            @click.prevent="getAnalyseData">Auswerten</button>
                </div>
            </form-section>
        </div>

        <div class="literature-analyse-results" v-if="isInitLoaded">
            <template v-if="hasAnalyseData && analyseData.data">



                <div class="content-tabs">
                    <ul>
                        <li :class="{active: viewMode === 'table'}" @click="viewMode = 'table'">Tabellen Ansicht</li>
                        <li :class="{active: viewMode === 'diagram'}" @click="viewMode = 'diagram'">Diagram Ansicht</li>
                    </ul>
                </div>

                <div class="tab-content">
                    <template v-if="analyseData.model.newspaper.id === 999999">

                        <div class="tab-pane" :class="{active: viewMode === 'table'}">
                            <div class="grid-form">
                                <div class="grid-container-outer">
                                    <table class="table">
                                        <thead class="grid-header">
                                        <tr>
                                            <th><div>Zeitung</div></th>
                                            <template v-if="analyseData.data[0]">
                                                <template v-for="entry in analyseData.data[0].entries">
                                                    <th><div>{{ entry.year }}</div></th>
                                                </template>
                                            </template>
                                        </tr>
                                        </thead>
                                        <tbody class="grid-data">
                                        <template v-for="r in analyseData.data">
                                            <tr>
                                                <td data-label="Zeitung">
                                                    <div>{{ r.title }}</div>
                                                </td>
                                                <template v-for="entry in r.entries">
                                                    <td :data-label="entry.year">
                                                        <div class="text-small">
                                                            <div>{{ entry.issues }} Ausg.</div>
                                                            <div>{{ entry.total }} Eintr.</div>
                                                        </div>
                                                    </td>
                                                </template>
                                            </tr>
                                        </template>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" :class="{active: viewMode === 'diagram'}">
                            <apexchart type="bar"
                                       v-if="loading !== true"
                                       width="100%" height="2600px"
                                       :options="chartOptions"
                                       :series="analyseData.apexData.series"/>
                        </div>
                    </template>
                    <template v-else-if="analyseData.model.newspaper.id === 999998">

                    </template>
                    <template v-else>
                        <div class="tab-pane" :class="{active: viewMode === 'table'}">
                            <p>
                                Ausgewertet wurden <b>{{ analyseData.data.total }}</b> Einträge für die Zeitschrift "<b>{{ analyseData.data.title }}</b>"
                            </p>
                            <div class="grid-form">
                                <div class="grid-container-outer">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th><div>Ausgabe</div></th>
                                            <th><div>Anzahl Einträge</div></th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <template v-for="r in analyseData.data.entries">
                                            <tr>
                                                <td data-label="Ausgabe">
                                                    <div>{{ r.vintage }} / {{ r.issue_no }} (Jahrgang {{ r.year }})</div>
                                                </td>
                                                <td data-label="Anzahl Einträge">
                                                    <div>{{ r.total }}</div>
                                                </td>
                                            </tr>
                                        </template>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane" :class="{active: viewMode === 'diagram'}">
                            <apexchart type="bar"
                                       v-if="loading !== true"
                                       width="100%" height="600px"
                                       :options="Object.assign(cloneDeep(chartOptions), {
                                           chart: {
                                                id: 'vuechart-example',
                                                animations: {
                                                    enabled: true
                                                }
                                            },
                                           plotOptions: {
                                                bar: {
                                                    hideZeroBarsWhenGrouped: false,
                                                    horizontal: false,
                                                    barHeight: '90%',
                                                    columnWidth: '90%',
                                                    distributed: false,
                                                }
                                            },
                                            yaxis: {
                                                show: true,
                                                min: 0,
                                                labels: {
                                                    minWidth: 10
                                                },
                                            },
                                            dataLabels: {
                                              enabled: true
                                            },
                                       })"
                                       :series="analyseData.apexData.series"/>
                        </div>

                    </template>
                </div>
            </template>
            <template v-else>
                <em v-if="analyseData.data && typeof analyseData.data === 'object'">Es konnten keine Daten für die Auswertung gefunden werden</em>
            </template>
        </div>
    </div>
</template>

<style scoped>

</style>
