import RecipesIndex from './components/RecipesIndex.vue';
import RecipeEdit from './components/RecipeEdit.vue';

export default [
    {
        path: '/recipes',
        component: RecipesIndex,
        name: 'admin.recipes',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },

    {
        path: '/recipes/edit/:item',
        component: RecipeEdit,
        name: 'admin.recipes.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },

    {
        path: '/recipes/create',
        component: RecipeEdit,
        name: 'admin.recipes.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
];
