<template>
    <ul v-if="hasRows">
        <li
                v-for="(r,k) in mutated"
                v-bind="rowAttributes(r)"
                v-bind:key="k">
            <div>
                <table width="100%">
                    <tbody>
                    <tr>
                        <td v-if="isSortable" width="45">
                            <div>
                                <span class="drag-handler">
                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </span>
                            </div>
                        </td>

                        <slot name="column" :row-data="r" :cc="r"></slot>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="hasChildren(r)">
                <tree-table-row :rows="r.children"
                                :headers="headers"
                                :is-sortable="isSortable"
                                :is-root="isRootList">
                    <template slot="column" slot-scope="{ rowData }">
                        <slot name="column" :row-data="rowData"></slot>
                    </template>
                </tree-table-row>
            </div>
        </li>
    </ul>
    <div v-else>
        <div v-if="isRootList" class="text-center uppercase padding-10 text-muted">Keine Daten vorhanden</div>
    </div>
</template>

<script>
    export default {
        name: "TreeTableRow",
        props: {
            headers: {
                required: true,
                type: [Array, Object],
                default() {
                    return {}
                }
            },
            rows: {
                type: [Array, Object],
                required: true,
                default() {
                    return {}
                }
            },

            isSortable: {
                required: true,
                type: Boolean,
            },


            renderRowAttributes: {
                type: Function,
                default: () => {}
            },

            renderColumn: {
                type: Function,
                default: () => {}
            },

            isRoot: {
                required: false,
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                isRootList: true,
                mutated: []
            }
        },

        watch: {
            rows() {
                this.makeMutated()
            }
        },


        computed: {
            hasRows() {
                let rows = this.rows;
                if (!_.isArray(rows)) {
                    rows = _.toArray(rows);
                }

                return !_.isEmpty(rows) && rows.length > 0;
            }
        },

        mounted() {

            this.isRootList = this.isRoot


            this.makeMutated()
        },

        methods: {

            makeMutated() {
                this.mutated = [];

                let rows = this.rows;
                if (!_.isArray(this.rows)) {
                    rows = _.toArray(this.rows);
                    // this.$set(this, 'rows', rows);
                }

                for (let r in rows)
                {
                    let c, columns = [];
                    for (c in this.headers) {
                        if (c.hasOwnProperty('field') ) {
                            if (r.hasOwnProperty(c.field))
                            {
                                columns.push(
                                    r[c.field]
                                );
                            }
                        }
                    }
                }

                this.mutated = rows
            },

            hasChildren(row) {
                return (row.hasOwnProperty('children') && row.children);
            },


            hasColumnLabel(column) {

            },
            _renderColumnLabel(column) {

            },

            hasColumnNote(column) {

            },
            _renderColumnNote(column) {

            },

            _renderColumnAction(action) {
                let data = '';

                if (action) {
                    if (action.hasOwnProperty('icon')) {
                        data += '<i class="fa fa-'+ action.icon +'"></i>';
                    }

                    if (action.hasOwnProperty('label')) {
                        data += action.label;
                    }
                }

                return data;
            },

            rowAttributes(row) {
                if (this.renderRowAttributes) {
                    return this.renderRowAttributes(row);
                }

                if (row.hasOwnProperty('attributes') && row.attributes) {
                    return row.attributes;
                }

                return []
            },

            getAttributes(row) {
                if (row.hasOwnProperty('attributes') && row.attributes) {
                    return row.attributes;
                }

                return []
            }
        }
    }
</script>

<style scoped>

</style>