<template>
    <template v-if="isMounted">
        <div class="checkable-wrapper" :class="{disabled: fieldIsDisabled }">
            <input ref="formfield"
                   type="checkbox"
                   :name="name"
                   :value="fieldValue"
                   :disabled="fieldIsDisabled"
                   v-bind="fieldAttributes"
                   v-model="mchecked"
                   :checked="shouldBeChecked"
                   @change="updateInput"
                   :id="fieldId(fieldValue)"/>
            <svg-icon name="check" v-show="shouldBeChecked"/>
        </div>
    </template>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, nextTick, inject, onBeforeMount} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-checkboxfield",
    emits: ['input'],
    props: {
        ...defaultFieldProps,
        value: {},
        fieldValue: {},
        disabled: {
            type: Boolean,
            default: false,
        },
        trueValue: {},
        falseValue: {}
    },


    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const {fieldIsDisabled, modelName, modelvalue, fieldAttributes, allowMultiple} = useFormField(props, context);

        const isMounted = ref(false);
        const $events = inject('$events')
        const mchecked = ref(undefined);
        const isMultiple = ref(false);

        onBeforeMount(() => {
            if (allowMultiple.value) {
                isMultiple.value = true;
            }


            if (props.name.match(/\[\]$/g)) {
                isMultiple.value = true;
            }
        })


        onMounted(() => {
            $events.$on('form-reset', () => {
                isMounted.value = false;

                if (_.isArray(modelvalue.value)) {
                    mchecked.value = _.indexOf(modelvalue.value, props.fieldValue) !== -1;
                } else {
                    mchecked.value = props.value;
                }

                nextTick(() => {
                    isMounted.value = true;
                })
            });

            if (props.name.match(/\[\]$/g)) {
                isMultiple.value = true;
            }

            if (_.isArray(modelvalue.value)) {
                mchecked.value = _.indexOf(modelvalue.value, props.fieldValue) !== -1;
            } else {
                mchecked.value = props.value;
            }

            isMounted.value = true
        });


        watch(() => props.value, (v, o) => {
            if (v !== o) {
                mchecked.value = v;
            }
        });

        const shouldBeChecked = computed(() => {
            if (mchecked.value === undefined)
                return mchecked.value = typeof props.value === 'string' ? true : !!props.value;
            else
                return mchecked.value;
        });

        const myfieldAttributes = computed(() => {
            let attrs = _.cloneDeep(fieldAttributes.value);

            return {
                id: checkboxId.value,
                ...attrs
            }
        });

        const checkboxId = computed(() => {
            if (props.id) return props.id;
            let name = props.name;

            let idName = name.replace(/\[\]/g, '_');
            idName = idName.replace(/\[/g, '-');
            idName = idName.replace(/\}/g, '');

            return 'field-'+ idName.replace(/\./g, '-');
        });

        const isChecked = computed(() => {
            return mchecked.value;
        });

        function fieldId(value) {
            return app.transformKey(modelName.value).replace(/\./g, '-') + '_' + value;
        }

        function updateInput(e) {

            mchecked.value = e.target.checked;

            context.emit('input', mchecked.value);

            if (props.field.event) {
                if (props.field.event.match(/^on([A-Z][a-zA-Z]+)/g)) {
                    //this.$events[this.field.event](this.mchecked)
                    eval('$events.$emit(\'' + props.field.event + "\', " + mchecked.value + ')');
                }
            }
        }


        return {
            isMounted,
            checkboxId,
            isChecked,
            fieldAttributes: myfieldAttributes,
            shouldBeChecked,
            mchecked,
            fieldIsDisabled,
            fieldId,
            updateInput,
        }
    }
}
</script>

<style scoped>

</style>
