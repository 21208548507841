<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, defineComponent, getCurrentInstance, inject, nextTick} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";

export default defineComponent({
    name: "Mediamanager",
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx

        const route = useRoute();
        const router = useRouter()
        const store = useStore();

        const requestAction = inject('requestAction')
        const setPropertyPath = inject('setPropertyPath');
        const $http = inject('$http');
        const $events = inject('$events');


        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
            return true
            // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
        })

        loadData();


        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            if (route.query.newregistrations) {
                postData.newregistrations = true;
            }

            $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                if (!r.data.grid.url) {
                    r.data.grid.url = $root.currentRouteUrl(route.path);
                }

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {

        }



        function handleDelete() {

        }

        function openDetails(row, event) {
            setPropertyPath(row, 'loadingdetails', true);

            if (row.hasOwnProperty('rowdetails')) {
                setPropertyPath(row, 'loadingdetails', false);
                return;
            }

            requestAction('onLoadHolderDetails').post($root.currentRouteUrl(route.path), {holder: row.id}).then((r) => {
                setPropertyPath(row, 'rowdetails', r.data);
                setPropertyPath(row, 'loadingdetails', false);
            });
        }

        function closeDetails(row, event) {

        }


        function getMimeIcon(row) {
            if (row.mime && typeof row.mime === "string") {
                if (row.mime.match(/image\/.+/ig)) {
                    return 'photo'
                }
                if (row.mime.match(/application\/pdf/ig)) {
                    return 'file-type-pdf'
                }

                if (row.mime.match(/application\/msword/ig)) {
                    return 'file-type-docx'
                }

                if (row.mime.match(/application\/(zip|x-apple-diskimage|x-rar-compressed|x-gtar|gzip|vnd\.rar|x-7z-compressed|x-bzip|x-bzip2)/ig)) {
                    return 'file-zip'
                }
            }

            if (row.ext && typeof row.ext === "string") {
                if (row.ext.match(/(xlt|XLSM|xlsx|xls)$/ig)) {
                    return 'file-spreadsheet'
                }

                if (row.ext.match(/(doc(x)|rtf|pages|odt)$/ig)) {
                    return 'file-text'
                }

                if (row.ext.match(/(pptx|ppsx|ppt|pps)$/ig)) {
                    return 'file-type-ppt'
                }
            }

            return null
        }


        function filesize(num) {
            // jacked from: https://github.com/sindresorhus/pretty-bytes
            num = parseInt(num);

            let neg = num < 0;
            let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            if (neg) {
                num = -num;
            }

            if (num < 1) {
                return (neg ? '-' : '') + num + ' B';
            }

            let exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
            num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
            let unit = units[exponent];

            return (neg ? '-' : '') + num + ' ' + unit;
        }

        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleDelete,


            openDetails,
            closeDetails,

            filesize,
            getMimeIcon
        }
    }
})
</script>

<template>
    <div class="media-container">
        <toolbar ref="toolbar"
                 :filters="toolbarData"
                 @clearfilter="handleClearGridFilter"
                 @executefilter="handleGridFilter">
        </toolbar>


        <data-table v-if="grid" ref="gridata"
                    :field-components="fieldComponents"
                    :base-data="grid"
                    :toolbar="$refs.toolbar"
                    @selected-count="(v) => { selectedTrashCount = v }"
                    @delete="handleDelete"
                    @loaded="onLoaded">
            <template v-slot:name="{row}">

                <div class="n">
                    <svg-icon :name="(icn = getMimeIcon(row)) ? icn : 'file-unknown'"/>
                    {{ row.name }}
                </div>

                <div class="question" v-if="row.question">
                    <span>Anlage zur Frage:</span>
                    <router-link :to="{name: 'admin.requests.edit', params: {id: row.entry_id}}">{{ row.question }}</router-link>
                </div>
            </template>

            <template v-slot:size="{row}">
                <span class="badge badge-dark">{{ filesize(row.size) }}</span>
            </template>

            <template v-slot:created_at="{row}">
                <created-updated-by-at v-if="row.created_at" :date="row.created_at" :user="row.createdby" type="created"/>
            </template>
        </data-table>
    </div>
</template>

<style scoped>

</style>
