import EditRequestSubject from "./components/EditRequestSubject.vue";
import EditRecipeTheme from "./components/EditRecipeTheme.vue";
import EditMedium from "./components/EditMedium.vue";



export default [


    {
        path: '/system/info',
        component: require('./components/Info/SystemInfo.vue').default,
        name: 'admin.system.info',
        meta: {
            title: 'System Info',
            nocache: false,
            grid: false
        }
    },

    {
        path: '/system/logs',
        component: require('./components/Logs/IndexLogs.vue').default,
        name: 'admin.system.logs',
        meta: {
            title: 'System Logs',
            nocache: false,
            grid: true
        }
    },







    {
        path: '/system/subjects',
        component: require('./components/RequestsSubjectsIndex.vue').default,
        name: 'admin.system.subjects',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/system/subjects/edit/:id',
        component: EditRequestSubject,
        name: 'admin.system.subjects.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/system/subjects/create',
        component: EditRequestSubject,
        name: 'admin.system.subjects.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },










    {
        path: '/system/recipe',
        component: require('./components/RecipeThemesIndex.vue').default,
        name: 'admin.system.recipe',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/system/recipe/edit/:id',
        component: EditRecipeTheme,
        name: 'admin.system.recipe.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/system/recipe/create',
        component: EditRecipeTheme,
        name: 'admin.system.recipe.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },





    {
        path: '/system/medium',
        component: require('./components/MediumIndex.vue').default,
        name: 'admin.system.medium',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/system/medium/edit/:id',
        component: EditMedium,
        name: 'admin.system.medium.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/system/medium/create',
        component: EditMedium,
        name: 'admin.system.medium.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },









    {
        path: '/system/options/answer-ways',
        component: require('./components/Options/OptionsAnswerWayIndex.vue').default,
        name: 'admin.system.options.answerway',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/system/options/answer-ways/edit/:id',
        component: require('./components/Options/EditAnswerWay.vue').default,
        name: 'admin.system.options.answerway.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/system/options/answer-ways/create',
        component: require('./components/Options/EditAnswerWay.vue').default,
        name: 'admin.system.options.answerway.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },




    {
        path: '/system/options/question-ways',
        component: require('./components/Options/OptionsQuestionWayIndex.vue').default,
        name: 'admin.system.options.questionway',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/system/options/question-ways/edit/:id',
        component: require('./components/Options/EditQuestionWay.vue').default,
        name: 'admin.system.options.questionway.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/system/options/question-ways/create',
        component: require('./components/Options/EditQuestionWay.vue').default,
        name: 'admin.system.options.questionway.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },







    {
        path: '/system/options/questioner-state',
        component: require('./components/Options/OptionsQuestionerStateIndex.vue').default,
        name: 'admin.system.options.questionerstate',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/system/options/questioner-state/edit/:id',
        component: require('./components/Options/EditQuestionerState.vue').default,
        name: 'admin.system.options.questionerstate.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/system/options/questioner-state/create',
        component: require('./components/Options/EditQuestionerState.vue').default,
        name: 'admin.system.options.questionerstate.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },










    {
        path: '/system/options/linktypes',
        component: require('./components/Options/OptionsLinkTypesIndex.vue').default,
        name: 'admin.system.options.linktype',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/system/options/linktypes/edit/:id',
        component: require('./components/Options/EditLinkType.vue').default,
        name: 'admin.system.options.linktype.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/system/options/linktypes/create',
        component: require('./components/Options/EditLinkType.vue').default,
        name: 'admin.system.options.linktype.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
];
