<template>

    <template v-if="parentIsRow">
        <span class="fragment" :class="getColumnClass"
              :key="getFragmentKey">
            <div ref="section" v-if="parentIsRow && isMounted"
                 :class="[getColumnClass, {expanded: expanded && isExpandable}]">
                <div :class="['form-fields-section', getClass, {'form-section-expandable': isExpandable}, {hidden: (hasShowWhen ? !getShowWhen : false) }]" v-attr="fieldAttributes">
                    <div v-if="hasLabel" class="form-section-label" @click.prevent="toggleExpand">
                        <h4>
                            <span v-if="field.icon" :class="['mr-5 fa', field.icon]"></span>
                            <span v-html="sectionLabel" class="section-label"></span>

                            <template v-if="$slots.appendlabel">
                                <span class="float-right"><slot name="appendlabel"></slot></span>
                            </template>

                        </h4>
                        <span v-if="isExpandable" :class="['fa', {'fa-angle-up': expanded, 'fa-angle-down': !expanded}]"></span>
                    </div>
                    <div class="form-section-description"
                         :class="sectionDescriptionMode"
                         v-if="hasDescription">
                        <div v-if="sectionDescriptionMode">
                            <svg-icon v-if="sectionDescriptionMode === 'mode-warning'" name="alert-triangle"/>
                            <svg-icon v-else-if="sectionDescriptionMode === 'mode-error'" name="exclamation-circle"/>
                            <svg-icon v-else name="info-circle"/>
                        </div>
                        <div v-html="field.description"></div>
                    </div>

                    <div ref="form_section_content"
                         :class="[
                             'form-section-content',
                             {
                                 hidden: !expanded,
                                 'without-columns': !hasColumns
                             }
                         ]"
                         v-if="hasFields || hasComponents || hasSlot">

                        <div class="section-component" ref="component" v-if="getRenderSection">
                            <component :is="'section-'+ getRenderSection"
                                       :field="field"
                                       :name="'section_'+getRenderSection"
                                       :validate="validate"
                                       :responseerrors="responseerrors"
                                       :form-model.sync="formModel"
                                       :rendersections="rendersections"
                                       @appendlabel="(v) => { appendToLabel(v) }"></component>
                        </div>

                        <template v-if="hasFields" v-for="(f, n)  in field.fields" :key="n">
                            <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
                            <component v-else
                                       :form-model.sync="formModel"
                                       :responseerrors="responseerrors"
                                       :rendersections="rendersections"
                                       :is="'form-'+ f.type"
                                       :validate="validate"
                                       :name="n"
                                       :field="f"></component>
                        </template>

                        <template v-if="hasSlot && $slots.default">
                            <slot><p>Slot Content</p></slot>
                        </template>
                    </div>
                </div>
            </div>
            <template v-else>
                <div
                    ref="section"
                    :class="['form-fields-section', getClass, {'form-section-expandable': isExpandable, expanded: expanded && isExpandable}, {hidden: (hasShowWhen ? !getShowWhen : false) }]"
                    v-bind="fieldAttributes">
                    <div v-if="hasLabel" class="form-section-label" @click.prevent="toggleExpand">
                        <h4>
                            <span v-if="field.icon" :class="['mr-5 fa', field.icon]"></span>
                            <span v-html="sectionLabel" class="section-label"></span>

                            <template v-if="$slots.appendlabel">
                                <span class="float-right"><slot name="appendlabel"></slot></span>
                            </template>

                        </h4>
                        <span v-if="isExpandable" :class="['fa', {'fa-angle-up': expanded, 'fa-angle-down': !expanded}]"></span>
                    </div>
                    <div class="form-section-description"
                         :class="sectionDescriptionMode"
                         v-if="hasDescription">
                        <div v-if="sectionDescriptionMode">
                            <svg-icon v-if="sectionDescriptionMode === 'mode-warning'" name="alert-triangle"/>
                            <svg-icon v-else-if="sectionDescriptionMode === 'mode-error'" name="exclamation-circle"/>
                            <svg-icon v-else name="info-circle"/>
                        </div>
                        <div v-html="field.description"></div>
                    </div>

                    <div ref="form_section_content"
                         :class="[
                             'form-section-content',
                             {
                                 hidden: !expanded,
                                'without-columns': !hasColumns
                             }]"
                         v-if="hasFields || hasComponents || hasSlot">

                        <div class="section-component" ref="component" v-if="getRenderSection">
                            <component :is="'section-'+ getRenderSection"
                                       :field="field"
                                       :form-model.sync="formModel"
                                       :name="'section_'+getRenderSection"
                                       :validate="validate"
                                       :responseerrors="responseerrors"
                                       :rendersections="rendersections"
                                       @appendlabel="(v) => { appendToLabel(v) }"></component>
                        </div>

                        <template v-if="hasFields" v-for="(f, n) in field.fields">
                            <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
                            <component v-else
                                       :form-model.sync="formModel"
                                       :responseerrors="responseerrors"
                                       :rendersections="rendersections"
                                       :is="'form-'+ f.type"
                                       :validate="validate"
                                       :name="n"
                                       :field="f"
                                       :key="n"></component>
                        </template>

                        <template v-if="hasSlot && $slots.default">
                            <slot><p>Slot Content</p></slot>
                        </template>
                    </div>
                </div>
            </template>
        </span>
    </template>
    <template v-else>
        <div ref="section" v-if="parentIsRow && isMounted" :class="[getColumnClass, {expanded: expanded && isExpandable}]">
            <div :class="['form-fields-section', getClass, {'form-section-expandable': isExpandable}, {hidden: (hasShowWhen ? !getShowWhen : false) }]" v-attr="fieldAttributes">
                <div v-if="hasLabel" class="form-section-label" @click.prevent="toggleExpand">
                    <h4>
                        <span v-if="field.icon" :class="['mr-5 fa', field.icon]"></span>
                        <span v-html="sectionLabel" class="section-label"></span>

                        <template v-if="$slots.appendlabel">
                            <span class="float-right"><slot name="appendlabel"></slot></span>
                        </template>

                    </h4>
                    <span v-if="isExpandable" :class="['fa', {'fa-angle-up': expanded, 'fa-angle-down': !expanded}]"></span>
                </div>
                <div class="form-section-description"
                     :class="sectionDescriptionMode"
                     v-if="hasDescription">
                    <div v-if="sectionDescriptionMode">
                        <svg-icon v-if="sectionDescriptionMode === 'mode-warning'" name="alert-triangle"/>
                        <svg-icon v-else-if="sectionDescriptionMode === 'mode-error'" name="exclamation-circle"/>
                        <svg-icon v-else name="info-circle"/>
                    </div>
                    <div v-html="field.description"></div>
                </div>

                <div ref="form_section_content" :class="['form-section-content', {hidden: !expanded}, {'without-columns': !hasColumns}]"
                     v-if="hasFields || hasComponents || hasSlot">

                    <div class="section-component" ref="component" v-if="getRenderSection">
                        <component :is="'section-'+ getRenderSection"
                                   :field="field"
                                   :name="'section_'+getRenderSection"
                                   :validate="validate"
                                   :responseerrors="responseerrors"
                                   :form-model.sync="formModel"
                                   :rendersections="rendersections"
                                   :store-modul="storeModul"
                                   @appendlabel="(v) => { appendToLabel(v) }"></component>
                    </div>

                    <template v-if="hasFields" v-for="(f, n)  in field.fields" :key="n">
                        <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
                        <component v-else
                                   :form-model.sync="formModel"
                                   :responseerrors="responseerrors"
                                   :rendersections="rendersections"
                                   :store-modul="storeModul"
                                   :is="'form-'+ f.type" :validate="validate" :name="n" :field="f"></component>
                    </template>

                    <template v-if="hasSlot && $slots.default">
                        <slot><p>Slot Content</p></slot>
                    </template>
                </div>
            </div>
        </div>
        <template v-else>
            <div
                ref="section"
                :class="['form-fields-section', getClass, {'form-section-expandable': isExpandable, expanded: expanded && isExpandable}, {hidden: (hasShowWhen ? !getShowWhen : false) }]"
                v-attr="fieldAttributes">
                <div v-if="hasLabel" class="form-section-label" @click.prevent="toggleExpand">
                    <h4>
                        <span v-if="field.icon" :class="['mr-5 fa', field.icon]"></span>
                        <span v-html="sectionLabel" class="section-label"></span>

                        <template v-if="$slots.appendlabel">
                            <span class="float-right"><slot name="appendlabel"></slot></span>
                        </template>

                    </h4>
                    <span v-if="isExpandable" :class="['fa', {'fa-angle-up': expanded, 'fa-angle-down': !expanded}]"></span>
                </div>
                <div class="form-section-description"
                     :class="sectionDescriptionMode"
                     v-if="hasDescription">
                    <div v-if="sectionDescriptionMode">
                        <svg-icon v-if="sectionDescriptionMode === 'mode-warning'" name="alert-triangle"/>
                        <svg-icon v-else-if="sectionDescriptionMode === 'mode-error'" name="exclamation-circle"/>
                        <svg-icon v-else name="info-circle"/>
                    </div>
                    <div v-html="field.description"></div>
                </div>

                <div ref="form_section_content"
                     :class="['form-section-content', {hidden: !expanded}, {'without-columns': !hasColumns}]"
                     v-if="hasFields || hasComponents || hasSlot">

                    <div class="section-component" ref="component" v-if="getRenderSection">
                        <component :is="'section-'+ getRenderSection"
                                   :field="field"
                                   :form-model.sync="formModel"
                                   :name="'section_'+getRenderSection"
                                   :validate="validate"
                                   :responseerrors="responseerrors"
                                   :rendersections="rendersections"
                                   :store-modul="storeModul"
                                   @appendlabel="(v) => { appendToLabel(v) }"></component>
                    </div>

                    <template v-if="hasFields" v-for="(f, n) in field.fields">
                        <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
                        <component v-else
                                   :form-model.sync="formModel"
                                   :responseerrors="responseerrors"
                                   :rendersections="rendersections"
                                   :store-modul="storeModul"
                                   :is="'form-'+ f.type" :validate="validate" :name="n" :field="f" :key="n"></component>
                    </template>

                    <template v-if="hasSlot && $slots.default">
                        <slot name="default"><p>Slot Content</p></slot>
                    </template>
                </div>
            </div>
        </template>
    </template>

</template>

<script>
import smoothHeight from 'vue-smooth-height';

import {getCurrentInstance, ref, watch, computed, onBeforeMount, onBeforeUnmount, inject, onMounted, nextTick} from "vue";
import {useFormField} from "@/mixins/use-form-field";

export default {
    name: "form-section",
    mixins: [smoothHeight],
    props: {
        name: {
            required: false,
        },
        field: {
            required: true,
            type: Object
        },
        validate: {
            type: Boolean,
            default: false
        },
        mode: {},
        rendersections: {
            required: false,
            visible: true,
            default() {
                return {}
            }
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        },
	    storeModul: {
		    required: false,
		    default() {
			    return null
		    }
	    },
    },

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const $events = inject('$events');
        const isMounted = ref(false);
        const appendLabel = ref(null);
        const expanded = ref(true);
        const watchers = ref([])

        const {
            defaultModelValue,
            modelName,
            modelvalue,
            unitValue,
            hasDisableWhen,
            hasShowWhen,
            getDisableWhen,
            getShowWhen,
            getUnits,
            units,
            fieldConfig,
            fieldAttributes,
            getFieldValidationError,
            getCondition,
            getStringCondition,
            onComponentCreated,
        } = useFormField(props, context);

        const sectionLabel = computed(() => {
            if (!isMounted.value) {
                return ''
            }
            return getSectionLabel();
        });

        const parentIsRow = computed(() => {
            if (!isMounted.value) {
                return false
            }
            let parent = inst.parent.field;
            return !!(parent && parent.type === 'columns');
        });

        const parentIsColumn = computed(() => {
            if (!isMounted.value) {
                return false
            }
            let parent = inst.parent.field;
            return !!(parent && parent.type === 'column' || (parent.field.cols <= 12 && parent.field.cols >= 1));
        });

        const getColumnClass = computed(() => {
            let addCols = '';
            if (props.field && props.field.hasOwnProperty('cols')) {
                if (props.field.cols > 12 || props.field.cols < 1) {
                    addCols = 'col-md-12';
                } else {
                    addCols = 'col-md-' + props.field.cols;
                }
            }
            return addCols ? addCols : (parentIsRow.value ? 'col-md-12' : '');
        });

        const getClass = computed(() => {
            if (!isMounted.value) {
                return ''
            }
            return props.field && props.field.hasOwnProperty('class') && props.field.class ? props.field.class : '';
        });

        const isExpandable = computed(() => {
            if (!isMounted.value) {
                return false
            }
            return props.field && props.field.hasOwnProperty('expandable') && props.field.expandable;
        });

        const hasLabel = computed(() => {
            if (!isMounted.value) {
                return false
            }
            return props.field && props.field.hasOwnProperty('label') && props.field.label !== '';
        });

        const hasDescription = computed(() => {
            if (!isMounted.value) {
                return false
            }
            return props.field && props.field.hasOwnProperty('description') && props.field.description;
        });

        const hasFields = computed(() => {
            if (!isMounted.value) {
                return false
            }
            return props.field && props.field.hasOwnProperty('fields') && !_.isEmpty(props.field.fields);
        });

        const hasSlot = computed(() => {
            if (!isMounted.value) {
                return false
            }
            return context.slots.hasOwnProperty('default') && context.slots.default
        });


        const hasColumns = computed(() => {
            if (!isMounted.value) {
                return false
            }

            if (props.field && hasFields.value) {
                return _.some(props.field.fields, {type: 'columns'}) || _.filter(props.field.fields, (r) => { return r.cols > 0; }).length;
            }

            if (props.field && (props.field.cols > 12 || props.field.cols < 1)) {
                return true;
            }

            return false;
        });

        const hasComponents = computed(() => {
            if (!isMounted.value) {
                return false
            }
            if (props.field && props.field.hasOwnProperty('rendersection') && props.field.rendersection) {
                return true
            }
            return props.field.hasOwnProperty('rendersection') && props.field.rendersection;
        });

        const getRenderSection = computed(() => {
            if (!isMounted.value) {
                return false
            }
            if (props.field && props.field.hasOwnProperty('rendersection') && props.field.rendersection) {
                return props.field.rendersection
            }

            return false;
        });

        const sectionDescriptionMode = computed(() => {
            if (!isMounted.value || !hasDescription.value) {
                return null
            }
            if (props.field['view-mode']) {
                return 'mode-' + props.field['view-mode']
            }
            return 'mode-info';
        });


        watchers.value = [];
        isMounted.value = false;
        appendLabel.value = null;
        expanded.value = true;


        onBeforeUnmount(() => {
            isMounted.value = false;

            _.each(watchers.value, (fnc) => {
                if (_.isFunction(fnc)) {
                    fnc();
                }
            });

            watchers.value = [];
        });


        onBeforeMount(() => {
            if (isExpandable.value && props.field.hasOwnProperty('expanded') && _.isBoolean(props.field.expanded)) {
                expanded.value = props.field.expanded;
            }

            if (!_.isEmpty(props.rendersections)) {
                for (let k in props.rendersections) {
                    Neloh.component('section-' + k, props.rendersections[k]);
                }
            }
        });

        onMounted(() => {
            isMounted.value = true;

            if (inst.refs.form_section_content && isExpandable.value) {
                inst.ctx.$smoothElement({
                    el: inst.refs.refs.form_section_content,
                    transition: 'height .3s',
                    hideOverflow: true
                })
            }

            //
            if (hasShowWhen) {

                if (_.isArray(props.field.show_when)) {
                    _.each(props.field.show_when, (c) => {
                        if (c.match(/([\|,]+?)/g)) {
                            let separator = '|';
                            if (!c.match(/\|/g)) {
                                separator = ',';
                            }

                            let conditions = c.split(separator);

                            if (conditions[0]) {
                                watchers.value.push(
                                    watch(() => 'formModel.' + conditions[0], (n, o) => {

                                    })
                                );
                            }

                        }
                    })
                } else if (_.isString(props.field.show_when)) {

                    if (props.field.show_when.match(/([\|,]+?)/g)) {
                        let separator = '|';
                        if (!props.field.show_when.match(/\|/g)) {
                            separator = ',';
                        }

                        let conditions = props.field.show_when.split(separator);


                        watchers.value.push(watch(() => props.formModel[conditions[0]], (n, o) => {

                        }));

                    }
                }
            }


            nextTick(() => {
                if (inst.refs['section'].querySelector('.form-fields-section')) {
                    inst.refs['section'].classList.add('has-child-sections');
                }
            })

        });


        function getSectionLabel() {
            if (!props.field.label) {
                return ''
            }
            if (!isMounted.value) {
                return props.field.label;
            }
            return _.isString(appendLabel.value) ?
                props.field.label + ' ' + appendLabel.value :
                props.field.label;
        }

        function fieldColumnsClass(f) {
            if (!f.hasOwnProperty('cols') || f.cols > 12) {
                return 'col-md-12';
            } else {
                return 'col-md-' + f.cols;
            }
        }

        function toggleExpand() {
            if (isExpandable.value) {
                expanded.value = !expanded.value;
            }
        }

        function appendToLabel(value) {
            appendLabel.value = value;
        }

        const getFragmentKey = computed(() => {
            return 'fragment-' + (new Date).getTime()
        })


        return {
            isMounted,
            appendLabel,
            expanded,
            fieldColumnsClass,
            getSectionLabel,
            toggleExpand,
            appendToLabel,
            sectionLabel,
            hasLabel,
            hasColumns,
            isExpandable,
            getColumnClass,
            parentIsRow,
            getClass,
            sectionDescriptionMode,
            hasDescription,
            hasFields,
            hasComponents,
            hasSlot,
            getRenderSection,
            getFragmentKey,

            defaultModelValue,
            modelName,
            modelvalue,
            unitValue,
            hasDisableWhen,
            hasShowWhen,
            getDisableWhen,
            getShowWhen,
            getUnits,
            units,
            fieldConfig,
            fieldAttributes,
            getFieldValidationError,
            getCondition,
            getStringCondition,
            onComponentCreated,
        }


    },
    //
    // data() {
    //     return {
    //         isMounted: false,
    //         appendLabel: null,
    //         expanded: true,
    //
    //         watchers: []
    //     }
    // },
    //
    // computed: {
    //
    //     getFragmentKey() {
    //         return 'fragment-' + (new Date).getTime()
    //     },
    //
    //     sectionLabel() {
    //         if (!this.isMounted) {
    //             return ''
    //         }
    //         return this.getSectionLabel();
    //     },
    //     parentIsRow() {
    //
    //         if (!this.isMounted) {
    //             return false
    //         }
    //
    //         let parent = this.$parent.field;
    //         return !!(parent && parent.type === 'columns');
    //     },
    //
    //     parentIsColumn() {
    //
    //         if (!this.isMounted) {
    //             return false
    //         }
    //
    //         let parent = this.$parent.field;
    //
    //         return !!(parent && parent.type === 'column' || (parent.field.cols <= 12 && parent.field.cols >= 1));
    //     },
    //
    //     getColumnClass() {
    //         let addCols = '';
    //         if (this.field && this.field.hasOwnProperty('cols')) {
    //             if (this.field.cols > 12 || this.field.cols < 1) {
    //                 addCols = 'col-md-12';
    //             } else {
    //                 addCols = 'col-md-' + this.field.cols;
    //             }
    //         }
    //         return addCols ? addCols : (this.parentIsRow ? 'col-md-12' : '');
    //     },
    //     getClass() {
    //         if (!this.isMounted) {
    //             return ''
    //         }
    //         return this.field && this.field.hasOwnProperty('class') && this.field.class ? this.field.class : '';
    //     },
    //     isExpandable() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //         return this.field && this.field.hasOwnProperty('expandable') && this.field.expandable;
    //     },
    //     hasLabel() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //         return this.field && this.field.hasOwnProperty('label') && this.field.label !== '';
    //     },
    //     hasDescription() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //         return this.field && this.field.hasOwnProperty('description') && this.field.description;
    //     },
    //     hasFields() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //         return this.field && this.field.hasOwnProperty('fields') && !_.isEmpty(this.field.fields);
    //     },
    //
    //     hasSlot() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //         return this.$slots.default && this.$slots.default.length > 0;
    //     },
    //
    //
    //     hasColumns() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //
    //         if (this.field && (this.field.cols > 12 || this.field.cols < 1)) {
    //             return true;
    //         }
    //
    //         if (this.field && this.hasFields) {
    //             return _.some(this.field.fields, {type: 'columns'});
    //
    //             let found = _.find(this.field.fields, (r) => {
    //                 return r.hasOwnProperty('cols')
    //             });
    //
    //             if (!found.length) {
    //                 return _.some(this.field.fields, {type: 'columns'});
    //             }
    //
    //             return found.length > 0;
    //         }
    //
    //         return false;
    //     },
    //
    //     hasComponents() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //         if (this.field && this.field.hasOwnProperty('rendersection') && this.field.rendersection) {
    //             return true
    //         }
    //         return this.field.hasOwnProperty('rendersection') && this.field.rendersection;
    //     },
    //     getRenderSection() {
    //         if (!this.isMounted) {
    //             return false
    //         }
    //         if (this.field && this.field.hasOwnProperty('rendersection') && this.field.rendersection) {
    //             return this.field.rendersection
    //         }
    //
    //         return false;
    //     },
    //
    //     sectionDescriptionMode() {
    //         if (!this.isMounted || !this.hasDescription) {
    //             return null
    //         }
    //         if (this.field['view-mode']) {
    //             return 'mode-' + this.field['view-mode']
    //         }
    //         return 'mode-info';
    //     },
    // },
    //
    // beforeDestroy() {
    //     this.isMounted = false;
    //
    //     _.each(this.watchers, (fnc) => {
    //         if (_.isFunction(fnc)) {
    //             fnc();
    //         }
    //     });
    //
    //     this.watchers = [];
    //
    // },
    //
    // beforeCreate() {
    //     this.watchers = [];
    //     this.isMounted = false;
    //     this.appendLabel = null;
    //     this.expanded = true;
    // },
    //
    // beforeMount() {
    //
    //     if (this.isExpandable && this.field.hasOwnProperty('expanded') && _.isBoolean(this.field.expanded)) {
    //         this.expanded = this.field.expanded;
    //     }
    //
    //     if (!_.isEmpty(this.rendersections)) {
    //         for (let k in this.rendersections) {
    //             Vue.component('section-' + k, this.rendersections[k]);
    //         }
    //     }
    // },
    //
    // mounted() {
    //     this.isMounted = true;
    //
    //     if (this.$refs.form_section_content && this.isExpandable) {
    //         this.$smoothElement({
    //             el: this.$refs.form_section_content,
    //             transition: 'height .3s',
    //             hideOverflow: true
    //         })
    //     }
    //
    //     //
    //     if (this.hasShowWhen) {
    //
    //         if (_.isArray(this.field.show_when)) {
    //             _.each(this.field.show_when, (c) => {
    //                 if (c.match(/([\|,]+?)/g)) {
    //                     let separator = '|';
    //                     if (!c.match(/\|/g)) {
    //                         separator = ',';
    //                     }
    //
    //                     let conditions = c.split(separator);
    //
    //                     if (conditions[0]) {
    //                         if (this.hasFormModel) {
    //                             this.watchers.push(
    //                                 this.$watch('formModel.' + conditions[0], (n, o) => {
    //                                     this.$nextTick(() => {
    //                                         this.$forceUpdate();
    //                                     })
    //                                 })
    //                             );
    //                         } else {
    //                             this.watchers.push(this.$watch('$root.model.' + conditions[0], (n, o) => {
    //                                 this.$nextTick(() => {
    //                                     this.$forceUpdate();
    //                                 })
    //                             }));
    //                         }
    //                     }
    //
    //                 }
    //             });
    //         } else if (_.isString(this.field.show_when)) {
    //
    //             if (this.field.show_when.match(/([\|,]+?)/g)) {
    //                 let separator = '|';
    //                 if (!this.field.show_when.match(/\|/g)) {
    //                     separator = ',';
    //                 }
    //
    //                 let conditions = this.field.show_when.split(separator);
    //
    //                 if (this.hasFormModel) {
    //                     this.watchers.push(this.$watch('formModel.' + conditions[0], (n, o) => {
    //                         this.$nextTick(() => {
    //                             this.$forceUpdate();
    //                         })
    //                     }));
    //                 } else {
    //                     this.watchers.push(this.$watch('$root.model.' + conditions[0], (n, o) => {
    //                         this.$nextTick(() => {
    //                             this.$forceUpdate();
    //                         })
    //                     }));
    //                 }
    //             }
    //         }
    //     }
    //
    //
    //     this.$nextTick(() => {
    //         if (this.$refs['section'].querySelector('.form-fields-section')) {
    //             this.$refs['section'].classList.add('has-child-sections');
    //         }
    //     })
    //
    //
    // },
    // methods: {
    //
    //     getSectionLabel() {
    //         if (!this.field.label) {
    //             return ''
    //         }
    //         if (!this.isMounted) {
    //             return this.field.label;
    //         }
    //         return _.isString(this.appendLabel) ?
    //             this.field.label + ' ' + this.appendLabel :
    //             this.field.label;
    //     },
    //
    //
    //     fieldColumnsClass(f) {
    //         if (!f.hasOwnProperty('cols') || f.cols > 12) {
    //             return 'col-md-12';
    //         } else {
    //             return 'col-md-' + f.cols;
    //         }
    //     },
    //     toggleExpand() {
    //         if (this.isExpandable) {
    //             this.expanded = !this.expanded;
    //         }
    //     },
    //     appendToLabel(value) {
    //         this.appendLabel = value;
    //     },
    //
    //
    // }
}
</script>

<style scoped>

</style>
