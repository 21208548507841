<script>
import {defineComponent, getCurrentInstance, computed, inject, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default defineComponent({
    name: "search-viewmode",
    props: {
        ...defaultFieldProps,
    },
    emits: ['input'],
    setup(props, context) {
        const inst = getCurrentInstance();
        const { fieldIsDisabled, modelvalue} = useFormField(props, context);

        const options = [
             {value: 1, label:  'vollständig'},
            {value: 2, label:  'Die ersten 250 Zeichen der Antwort anzeigen'},
            {value: 3, label:  'kurz (o. Antwort, Quelle, Monographie, Frager, Bearbeiter)'},
        ];

        return {
            modelvalue,
            fieldIsDisabled,
            options,
        }
    }
})
</script>

<template>
    <form-field :responseerrors="responseerrors" :name="name" :field="field" :key="name" :validate="validate" :form-model="formModel">
        <template v-slot:field>
            <form-radiolist :form-model="formModel"
                            name="viewmode"
                            :currentValue="modelvalue"
                            :field="{options: options}"/>
        </template>
    </form-field>
</template>

<style scoped>

</style>
