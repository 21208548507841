<script>
import {
    computed,
    defineComponent,
    getCurrentInstance,
    inject,
    onMounted,
    ref,
    watch,
    nextTick,
    onBeforeMount
} from 'vue'
import {useStore} from "~/vuex";
export default defineComponent({
    name: "Search",
    props: {},
    components: {
        'search-result-item': require('./search-result-item.vue').default
    },
    setup() {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const store = useStore();
        const $http = inject('$http')
        const $events = inject('$events')

        const showDetails = ref(false);
        const detailsForRequestId = ref(null)
        const loading = ref(false)
        const searched = ref(false);
        const pageNumber = ref(1)



        const filterOnlyKey = ref(null);
        const resultsSectionsFound = ref([])

        const userSettings = computed(() => {
            if (_.isObject(store.getters['user/user'].settings.search) ) {
                return store.getters['user/user'].settings.search;
            }
            return null;
        });

        const searchResults = computed(() => {
            return store.getters['globalSearch/results'];
        });

        const searchModel = computed(() => {
            return store.getters['globalSearch/model'];
        });

        watch(() => searchModel.value.q, (n, o) => {
            if (n !== o) {
                pageNumber.value = 1;
            }
        })


        $events.$on('run-search', handleSearch);



        function filterOnly(v, k) {
            filterOnlyKey.value = k === 'all' ? null : k;
            handleSearch(1)
        }



        function handleSearch(p = null) {
            if (loading.value) return;
            loading.value = true;
            if (typeof searchModel.value.q === "string" && searchModel.value.q.trim()) {

                if (p >= 1) {
                    pageNumber.value = p;
                }

                let s = Object.assign({}, searchModel.value, { page: pageNumber.value, filterOnly: filterOnlyKey.value });

                $http.post($root.routes('admin.dashboard.search'), s).then(r => {
                    searched.value = true;

                    if (r.data.success) {
                        resultsSectionsFound.value = r.data.founds || []
                        store.dispatch('globalSearch/updateSearchResults', r.data.results);

                        nextTick(() => {
                            bindPlaceholderClickEvents()
                        })
                    }

                    loading.value = false;
                }).catch(() => {
                    loading.value = false;
                });
            }
            else {
                loading.value = false;
                store.dispatch('globalSearch/updateSearchResults', []);
                pageNumber.value = 1;
            }
        }

        function changePage(p) {

            // handle scroll top
            pageNumber.value = p;
            scrollToTop()
            handleSearch()
        }


        function scrollToTop() {
            if (inst.refs.appsearch) {
                inst.refs.appsearch.scrollTo(0, 0)
            }
        }

        function stripHtml(value) {
            let d = document.createElement('div');
            d.innerHTML = value;
            return (d.textContent || d.innerText);
        }

        function truncatedContent(value, limit = 300, dropHtml = false) {

            if (typeof value === "string") {
                if (dropHtml) {
                    value = stripHtml(value);
                }
                value = value.trim();
                value = value.length > limit ? value.substring(0, limit) +' ...' : value;
            }

            return value;
        }


        function scrollTop(e) {
            e.stopImmediatePropagation()

            if (inst.refs.appsearch) {
                inst.refs.appsearch.scrollTop = 0
            }
        }

        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }

        function makeSourceLinks(value, entry) {

            if (typeof value === "string") {
                if ( Array.isArray(entry.customsources) && entry.customsources.length) {
                    _.each(entry.customsources, (r) => {
                        if (r.placeholder) {
                            value = value.replace(new RegExp(escapeRegExp(r.placeholder), 'g'), '<a href="javascript: void(0)"' +
                                ' data-customsources="customsource_'+ r.id +'">'+ r.placeholder +'</a>');
                        }
                    })
                }

                if ( Array.isArray(entry.links) && entry.links.length) {

                }

                if ( Array.isArray(entry.links) && entry.links.length) {

                }
            }

            return value;
        }

        function bindPlaceholderClickEvents() {
            if (inst.refs.appsearch) {
                inst.refs.appsearch.querySelectorAll('[data-customsources]').forEach((el) => {
                    el.addEventListener('click', (e) => {
                        let itemContainer = e.target.closest('.item');
                        let customsource = e.target.dataset.customsources;
                        if (customsource && itemContainer) {
                            let s = itemContainer.querySelector('[data-source="'+ customsource +'"]');
                            if (s) {
                                // Scroll to source and hightlight it after scrolled
                                // s.scrollIntoView();

                                nextTick(() => {
                                    s.classList.add('hightlight-source');
                                    setTimeout(() => s.classList.remove('hightlight-source'), 500)
                                })
                            }
                        }
                    })
                })
            }
        }

        function showRequestDetails(rowData, link) {
            detailsForRequestId.value = rowData.id;
            showDetails.value = true;
        }

        function entryHasSources(r) {
            if ((Array.isArray(r.literatures) && r.literatures.length ) ||
                (Array.isArray(r.links) && r.links.length) || (Array.isArray(r.customsources) && r.customsources.length) ) {
                return true;
            }
            return false
        }


        function initDefaults() {
            if (store.getters['globalSearch/isDone']) {
                return;
            }

            // Update user search form defaults
            if (_.isObject(store.getters['user/user'].settings.search) ) {

                if (store.getters['user/user'].settings.search.hasOwnProperty('mode')) {
                    store.dispatch('globalSearch/updateSearchModel', {mode: store.getters['user/user'].settings.search.mode })
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('searchIn')) {
                    store.dispatch('globalSearch/updateSearchModel', {searchIn: store.getters['user/user'].settings.search.searchIn})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('searchView')) {
                    store.dispatch('globalSearch/updateSearchModel', {viewmode: store.getters['user/user'].settings.search.searchView})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('perpage')) {
                    store.dispatch('globalSearch/updateSearchModel', {perpage: store.getters['user/user'].settings.search.perpage})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('sort')) {
                    store.dispatch('globalSearch/updateSearchModel', {sort: store.getters['user/user'].settings.search.sort})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('order')) {
                    store.dispatch('globalSearch/updateSearchModel', {order: store.getters['user/user'].settings.search.order})
                }

                store.dispatch('globalSearch/setInitDone')

            }

        }

        onBeforeMount(() => {
            initDefaults()
        });

        onMounted(() => {
             store.dispatch('ui/setToolbarDescription', 'Universal-Suchmaske')
        });


        return {
            userSettings,
            loading,
            searchModel,
            searchResults,
            searched,
            pageNumber,
            resultsSectionsFound,
            detailsForRequestId,
            showDetails,
            filterOnlyKey,
            scrollTop,
            handleSearch,
            changePage,
            scrollToTop,
            truncatedContent,
            makeSourceLinks,
            showRequestDetails,
            filterOnly,
            entryHasSources,
            formatDate: inject('formatDate')
        }
    }
});
</script>

<template>
    <div ref="appsearch" class="app-search">
        <loading :active="loading === true"
                 color="blue"
                 :width="20"
                 :height="20"
                 :opacity="0.6"
                 :is-full-page="false">
            <template v-slot:after>
                <div>lade Suchergebnisse ...</div>
            </template>
        </loading>

        <div class="widget search">
            <div class="title">Universal-Suchmaske</div>
            <div class="content">
                <search-form :search-model="searchModel"
                             @search="() => { filterOnlyKey = null; handleSearch(1) }"/>
            </div>
        </div>

        <template v-if="searchResults && searchResults.hasOwnProperty('data') && searchResults.data.length">

            <div class="search-results-container">
                <div class="search-results">
                    <template v-for="r in searchResults.data">

                        <search-result-item
                            :searchModel="searchModel"
                            :item="r"
                            @scrollTop="scrollTop"
                            @details="showRequestDetails"
                        />

                    </template>
                </div>
                <div class="search-results-sidebar">
                    <template v-if="resultsSectionsFound">
                        <form-section :field="{label: ''}">
                            <template v-for="(v, k) in resultsSectionsFound">
                                <div @click.prevent="filterOnly(v, k)">
                                    <template v-if="typeof v === 'object'">
                                        <div>{{ v.label }}</div>
                                        <div class="badge badge-dark">{{ v.total }}</div>
                                    </template>
                                    <template v-else>
                                        <div>Überall</div>
                                        <div class="badge badge-dark">{{ v }}</div>
                                    </template>
                                </div>
                            </template>
                        </form-section>
                    </template>
                </div>
            </div>




        </template>
        <template v-else>
            <div class="search-results empty" v-if="searched && searchModel.q">
                <em>Die Suche nach "{{ searchModel.q }}" konnte keine Ergebnisse liefern</em>
            </div>
        </template>
    </div>

    <div v-if="searchResults && searchResults.hasOwnProperty('data') && searchResults.data.length" class="search-footer">

        <div>
            <simple-pagination :page="pageNumber"
                               :total="searchResults.total"
                               :page-size="3"
                               :total_pages="searchResults.last_page"
                               :onEachSide="3"
                               @change-page="changePage"/>


            <div class="info">
                gefunden in {{ searchResults.total }} Datensätzen
            </div>
        </div>

        <div @click.prevent="scrollToTop" class="scroll-top" v-tooltip="'Zum Seitenanfang'">
            <svg-icon name="arrow-badge-up"/>
        </div>
    </div>


    <show-request-details :show="showDetails"
                          :request-id="detailsForRequestId"
                          @close="showDetails = false; detailsForRequestId = null" />
</template>

<style scoped>

</style>
