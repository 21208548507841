import {nextTick, toRaw} from "vue";

const form = {
    namespaced: true,
    state: {
        toolbar: {},
        formDefinition: {},
        formTabs: [],
        formFields: {},
        model: {},
        originalModel: {},
        dirty: false,
        errors: [],
        primaryKeyName: 'id',
        formName: null,
        activeTab: null
    },

    getters: {
        formDefinition: state => state.formDefinition,
        formTabs: state => state.formTabs,
        formTabActive: state => state.activeTab,
        formFields: state => state.formFields,
        formModel: state => state.model,
        formDirty: state => state.dirty,
        formErrors: state => state.errors,
        formToolbar: state => state.toolbar,
        formName: state => state.formName,
        formPrimaryKeyName: state => state.primaryKeyName,
    },

    mutations: {
        clearFormData(state) {
            state.formDefinition = {};
            state.formTabs = {};
            state.formFields = {};
            state.model = {};
            state.originalModel = {};
            state.toolbar = {};
            state.errors = [];
            state.dirty = false;
            state.activeTab = null;
            state.primaryKeyName = 'id'
        },

        set_form_options(state, payload) {
            state.formDefinition = payload.formDefinition;
            state.formTabs = payload.formTabs;
            state.formFields = payload.formFields;
            state.model = payload.model;
            state.originalModel = payload.originalModel;
            state.toolbar = payload.toolbar;
            state.errors = payload.errors;
            state.activeTab = payload.activeTab;
            state.formName = payload.formName;
            state.primaryKeyName = payload.primaryKeyName ? payload.primaryKeyName : 'id';


            nextTick(() => {
                nextTick(() => {
                    nextTick(() => {
                        nextTick(() => {
                            nextTick(() => {
                                state.dirty = payload.dirty ?? false;
                            });
                        });
                    });
                });
            });
        },


        ADD_MODEL_DATA(state, { model, status = null }) {
            state.model = Object.assign(toRaw(state.model), model);

            if (_.isBoolean(status)) {
                state.dirty = status;
            }
        },

        set_form_primaryKeyName(state, payload) {
            state.primaryKeyName = payload;
        },

        set_form_model(state, payload) {
            if (Object.keys(state.originalModel).length) {
                state.originalModel = Object.assign(state.originalModel, toRaw(_.cloneDeep(payload)));
                state.model = Object.assign(state.model, toRaw(_.cloneDeep(payload)) );
            }
            else {
                state.originalModel = toRaw(_.cloneDeep(payload));
                state.model = toRaw(_.cloneDeep(payload));
            }

            state.dirty = false;
        },

        set_form_errors(state, payload) {
            state.errors = payload;
        },

        clear_form_errors(state) {
            state.errors = [];
        },

        update_form_model(state, payload) {

            if (payload.hasOwnProperty('model') && _.isObject(payload.model)) {
                state.model = Object.assign({}, state.model, payload.model);
            }
            else {
                state.model = Object.assign({}, state.model, payload);
            }

            state.dirty = true;
        },

        reset_form_model(state) {

            state.model = Object.assign({}, state.originalModel);

            state.dirty = false;
        },

        sync_form_org_model(state) {
            state.originalModel = Object.assign({}, state.originalModel, _.cloneDeep(state.model));
        },

        sync_to_org_model(state) {
            state.originalModel = Object.assign({}, state.originalModel, _.cloneDeep(state.model));
        },

        set_form(state, payload) {
            state.formDefinition = payload;

            if (payload.hasOwnProperty('formName')) {
                state.formName = payload.formName
            }
        },

        set_form_tabs (state, payload) {

            _.each(payload, (tab) => {
                if (tab && tab.hasOwnProperty('active') && tab.active) {
                    state.activeTab = tab.id ? tab.id : tab.name;
                }
            });

            state.formTabs = payload;
        },

        set_form_fields (state, payload) {
            state.formFields = payload;
        },

        set_form_toolbar(state, payload) {
            state.toolbar = payload;
        },

        set_form_name(state, payload) {
            state.formName = payload;
        },

        set_form_dirty (state, payload) {
            state.dirty = payload;
        },

        set_tab_badge(state, payload) {
            let index = _.findIndex(state.formTabs, (r, i) => {
                if (payload.hasOwnProperty('id')) {
                    return payload.id == r.id
                }
                return payload.index == i
            });

            if (index !== -1) {
                if (payload.badge >= 0) {
                    state.formTabs[index].badgeLabel = ' <span class="badge badge-primary">'+  payload.badge +'</span>';
                }
                else {
                    state.formTabs[index].badgeLabel = '';
                }
            }
        },


        change_active_tab(state, payload) {
            state.activeTab = payload
        }

    },



    actions: {
        addFormModelData({ commit, state }, model, status = null) {
            return new Promise(resolve => {
                commit('ADD_MODEL_DATA', model, status);

                resolve({
                    model: state.model,
                    dirty: state.dirty,
                    formFields: state.formFields,
                    formTabs: state.formTabs,
                })
            });
        },

        setFormOptions({ commit }, props) {
            commit('set_form_options', props);
        },

        setFormModel({ commit, state  }, props) {

            return new Promise(resolve => {
                commit('set_form_model', props);

                resolve({
                    model: state.model,
                    dirty: state.dirty,
                })
            });

            // commit('set_form_model', props);
        },


        setFormPrimaryKeyName({ commit }, name) {
            commit('set_form_primaryKeyName', name);
        },

        setFormErrors({ commit }, errors) {
            commit('set_form_errors', errors);
        },

        setFormToolbar({ commit, state }, errors) {
            return new Promise(resolve => {
                commit('set_form_toolbar', errors);
                resolve({
                    model: state.model,
                    dirty: state.dirty,
                    formName: state.formName,
                    formDefinition: state.formDefinition,
                    formFields: state.formFields,
                    formTabs: state.formTabs,
                })
            })

        },

        clearFormErrors({ commit, state }) {
            return new Promise(resolve => {
                commit('clear_form_errors');
                resolve({
                    model: state.model,
                    dirty: state.dirty,
                    formName: state.formName,
                    formDefinition: state.formDefinition,
                    formFields: state.formFields,
                    formTabs: state.formTabs,
                })
            });
        },

        updateFormModel({ commit, state }, props) {
            return new Promise(resolve => {
                commit('update_form_model', props);

                resolve({
                    model: state.model,
                    dirty: state.dirty,
                });
            });
        },

        syncToOriginalModel({ commit }) {
            commit('sync_to_org_model');
        },

        syncFormOriginalModel({ commit, state }) {
            return new Promise(resolve => {
                commit('sync_form_org_model');
                resolve({
                    model: state.model,
                    dirty: state.dirty,
                });
            });
        },

        resetFormModel({ commit, state }) {
            commit('reset_form_model');
        },

        makeDirty({ commit, state }) {
            return new Promise(resolve => {
                commit('set_form_dirty', true);
                resolve({
                    model: state.model,
                    dirty: state.dirty,
                });
            });
        },

        unDirty({ commit, state }) {
            return new Promise(resolve => {
                commit('set_form_dirty', false);
                resolve({
                    model: state.model,
                    dirty: state.dirty,
                });
            });

        },

        setFormDefinition({ commit, state }, props) {
            return new Promise(resolve => {
                commit('set_form', props);
                resolve({
                    model: state.model,
                    dirty: state.dirty,
                });
            });
        },


        setFormTabs({ commit, state }, props) {
            return new Promise(resolve => {
                commit('set_form_tabs', props);
                resolve({
                    formTabs: state.formTabs,
                });
            });
        },

        setFormFields({ commit, state }, props) {
            return new Promise(resolve => {
                commit('set_form_fields', props);
                resolve({
                    formFields: state.formFields,
                });
            });
        },

        setFormName({ commit, state }, props) {
            return new Promise(resolve => {
                commit('set_form_name', props);
                resolve({
                    formName: state.formName,
                });
            });
        },

        changeActiveTab({ commit, state }, props) {
            return new Promise(resolve => {
                commit('change_active_tab', props);
                resolve({
                    activeTab: state.activeTab,
                });
            });
        },

        setTabBadge({ commit }, props) {
            commit('set_tab_badge', props);
        },

        clearForm({ commit }, props) {
            commit('clearFormData', props);
        },

    }
};

export default form
