<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";



export default defineComponent({
    name: "proofreading-message-tree",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);



        const rawTree = toRaw(_.cloneDeep(modelvalue));


        return {
            rawTree,
            fieldIsDisabled
        }
    }
})
</script>

<template>
    <div class="answer-tree">
        <template v-if="Array.isArray(rawTree) && rawTree.length">
            <template v-for="r in rawTree">
                <proofreading-message-tree-item
                    :form-model="formModel"
                    :item="r" />
            </template>
        </template>
        <template v-else>
            <em>Es wurden noch keine Korrekturen hinterlegt</em>
        </template>
    </div>
</template>

<style scoped>

</style>
