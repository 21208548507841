<template>
    <div :class="{'input-group' : bootstrapStyling}">
        <!-- Calendar Button -->
        <span v-if="calendarButton" class="vdp-datepicker__calendar-button"
              :class="{'input-group-prepend' : bootstrapStyling}" @click="showCalendar"
              v-bind:style="{'cursor:not-allowed;' : disabled}">
      <span :class="{'input-group-text' : bootstrapStyling}">
        <i :class="calendarButtonIcon">
          {{ calendarButtonIconContent }}
          <span v-if="!calendarButtonIcon">&hellip;</span>
        </i>
      </span>
    </span>
        <!-- Input -->
        <input
            ref="datefield"
            :type="inline ? 'hidden' : 'text'"
            :class="computedInputClass"
            :name="name"
            :ref="refName"
            :id="id"
            :value="formattedValue"
            :open-date="openDate"
            :placeholder="placeholder"
            :clear-button="clearButton"
            :disabled="disabled"
            :required="required"
            :readonly="!typeable"
            @click="showCalendar"
            @keyup="parseTypedDate"
            @blur="inputBlurred"
            autocomplete="off">
        <!-- Clear Button -->
        <span v-if="clearButton && selectedDate" class="vdp-datepicker__clear-button"
              :class="{'input-group-append' : bootstrapStyling}" @click="clearDate()">
      <span :class="{'input-group-text' : bootstrapStyling}">
        <i :class="clearButtonIcon">
          <span v-if="!clearButtonIcon">&times;</span>
        </i>
      </span>
    </span>
        <slot name="afterDateInput"></slot>
    </div>
</template>
<script>
import {makeDateUtils} from './utils/DateUtils'
import {getCurrentInstance, nextTick, computed, inject, onBeforeMount, ref, watch, onMounted} from "vue";
import {parseDate} from "@/utils/date-parser";

export default {
    props: {
        selectedDate: Date,
        resetTypedDate: [Date],
        format: [String, Function],
        translation: Object,
        inline: Boolean,
        id: String,
        name: String,
        refName: String,
        openDate: Date,
        placeholder: String,
        inputClass: [String, Object, Array],
        clearButton: Boolean,
        clearButtonIcon: String,
        calendarButton: Boolean,
        calendarButtonIcon: String,
        calendarButtonIconContent: String,
        disabled: Boolean,
        required: Boolean,
        typeable: {
            type: Boolean,
            default: true
        },
        bootstrapStyling: Boolean,
        useUtc: Boolean
    },
    emits: ['showCalendar', 'closeCalendar', 'selectDate', 'closed', 'clearDate', 'typedDate'],
    setup(props, context) {
        const inst = getCurrentInstance();
        const constructedDateUtils = makeDateUtils(props.useUtc)
        const input = ref(null)
        const typedDate = ref(false);
        const utils = constructedDateUtils

        const $moment = inject('$moment')

        const formattedValue = computed(() => {
            if (!props.selectedDate) {
                return null
            }
            if (typedDate.value) {
                return typedDate.value
            }
            return typeof props.format === 'function'
                ? props.format(props.selectedDate)
                : utils.formatDate(new Date(props.selectedDate), props.format, props.translation)
        });

        const computedInputClass = computed(() => {
            if (props.bootstrapStyling) {
                if (typeof props.inputClass === 'string') {
                    return [props.inputClass, 'form-control'].join(' ')
                }
                return {'form-control': true, ...props.inputClass}
            }
            return props.inputClass
        });


        watch(() => props.resetTypedDate, () => {
            typedDate.value = false
        })

        function showCalendar() {
            context.emit('showCalendar')
        }

        /**
         * Attempt to parse a typed date
         * @param {Event} event
         */
        function parseTypedDate(event) {
            // close calendar if escape or enter are pressed
            if ([
                27, // escape
                13 // enter
            ].includes(event.keyCode)) {
                input.value.blur()
            }

            if (props.typeable) {

                let p = input.value.value ? parseDate(input.value.value) : null;
                const typedD = p; // Date.parse(input.value.value)
                if (!isNaN(typedD) && typedD) {
                    typedDate.value = input.value.value
                    context.emit('typedDate', typedD)
                }
            }
        }

        /**
         * nullify the typed date to defer to regular formatting
         * called once the input is blurred
         */
        function inputBlurred(e) {
            if (props.typeable) {
                let p = input.value.value ? parseDate(input.value.value) : null;

                const typedD = p; // Date.parse(input.value.value)
                if (isNaN(typedD) || typedD === null) {
                    clearDate()
                    input.value.value = null;
                    typedDate.value = false;

                    context.emit('closeCalendar')
                } else {
                    typedDate.value = input.value.value;
                    context.emit('typedDate', typedD);

                    nextTick(() => {
                        context.emit('closeCalendar')
                    });
                }
            }

        }

        /**
         * emit a clearDate event
         */
        function clearDate() {
            context.emit('clearDate');
        }

        onMounted(() => {
            input.value = inst.refs.datefield
        })


        return {
            computedInputClass,
            formattedValue,
            parseTypedDate,
            showCalendar,
            inputBlurred,
            clearDate,
        }
    },


}
// eslint-disable-next-line
;
</script>
