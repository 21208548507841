<script>
import {defineComponent, getCurrentInstance, onMounted, ref, inject} from 'vue'


export default defineComponent({
    name: "SystemInfo",
    setup(props, context) {

        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const $http= inject('$http')

        const loading = ref(false)
        const phpinfo = ref([])


        onMounted(() => {
            loadInfos()
        });



        function loadInfos() {
            if (loading.value) return;

            loading.value = true;

            $http.get($root.routes('admin.system.info')).then(r => {
                phpinfo.value = r.data.phpinfo;

                loading.value = false;
            }).catch(() => {
                loading.value = false;
            });
        }

        function renderValue(value) {
            return _.isArray(value) ? value.join(', ') : value;
        }


        return {
            loading,
            phpinfo,
            renderValue
        }

    }
})
</script>

<template>
    <div class="inner-wrapper">
        <div class="tab-content">
            <div class="php-infos" v-if="!loading">
                <div v-for="(r, section) in phpinfo">
                    <h4>{{ section }}</h4>
                    <table class="table table-striped">
                        <tr v-for="(value, key) in r">
                            <th>{{ key }}</th>
                            <td>{{ renderValue(value) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </div>


</template>

<style scoped>

</style>
