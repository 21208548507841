<script>
import {computed, defineComponent, getCurrentInstance, inject, nextTick, ref, toRaw} from 'vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {useFormDefaults} from "@/mixins/use-form";

export default defineComponent({
    name: "EditRequest",
    setup(props, context) {

        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const store = useStore();
        const router = useRouter();
        const $events = inject('$events');

        const showSuccessSubmitted = ref(false);
        const currentModel = ref(null);
        const markAsEditorial = ref(true);

        const changeBreadcrumb = inject('changeBreadcrumb')


        const {
            formsaving,
            rendersections,
            model,
            errors,
            isDirty,
            isCreate,
            formFields,
            formTabs,
            loading,
            resetDirty,
            primaryKeyValue,
            isUpdate,
            loadForm,
            saveForm,
            onReset,
            onCancel,
        } = useFormDefaults(props, context);

        loadForm(ajaxLoaded);


        const activeTabId = computed(() => {
            if (loading.value || !store.getters["form/formTabActive"]) return false;
            return store.getters["form/formTabActive"]
        });


        const userIsModelInstitution = computed(() => {
            if (loading.value || !store.getters["user/user"]) return false;
            return store.getters["user/user"].institution_id == model.value.institution_id || isCreate.value
        });

        const toolbarDescription = computed(() => {
            if (loading.value) return ''

            if (isCreate.value) return 'Anfrage anlegen';
            else return 'Anfrage bearbeiten';
        })

        function ajaxLoaded(response) {
            nextTick(() => {
                store.dispatch('form/resetFormModel');

                if (store.getters['ui/lastActiveFormTab'] && store.getters['ui/lastActiveFormTab'].tab &&
                    store.getters['formName'] === store.getters['ui/lastActiveFormTab'].name) {
                    store.dispatch('form/changeActiveTab', store.getters['ui/lastActiveFormTab'].tab);
                    store.dispatch('ui/forgetLastActiveFormTab');
                }

                changeBreadcrumb(response);
            })
        }

        function submitForm(exit) {

            let routeParams = {};

            if (isUpdate()) {
                routeParams = {item: primaryKeyValue()};
            }

            let postData = Object.assign(model.value, { markAsEditorial: markAsEditorial.value })

            saveForm(exit, postData, routeParams, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((data) => {

                if (exit === true) {
                    router.replace({name: 'admin.requests'});
                }
                else if (exit === 'new') {

                    nextTick(() => {
                        resetDirty()
                    });

                    router.replace({name: 'admin.requests.create'});
                }
                else if (exit === 'home') {

                    nextTick(() => {
                        resetDirty()
                    });

                    router.replace({name: 'admin.dashboard'});
                }
                else {
                    model.value = toRaw(_.cloneDeep(data.model));

                    nextTick(() => {
                        resetDirty()
                    });
                }
            }).catch(error => {
                $root.notification(error);
            })
        }


        function renderDate(val, format = 'DD.MM.YYYY') {
            return val ? $root.moment(val).format(format) : null;
        }

        return {
            errors,
            formTabs,
            formFields,
            model,
            rendersections,
            isDirty,
            formsaving,
            isCreate,
            toolbarDescription,
            showSuccessSubmitted,
            currentModel,
            userIsModelInstitution,
            markAsEditorial,
            activeTabId,
            renderDate,
            submitForm,
            onCancel,
            onReset,
        }

    }
})
</script>

<template>
    <form-container :errors="errors" ref="formcontainer">
        <toolbar ref="toolbar"
                 :description="toolbarDescription"
                 @submit="submitForm">

            <div class="btn-toolbar" role="toolbar">
                <div class="btn-group mr-5">
                    <button @click="onCancel"
                            type="button"
                            :disabled="isDirty"
                            class="btn btn-primary">
                        Abbrechen
                    </button>
                </div>
                <div class="btn-group mr-5">
                    <action-button :disabled="!isDirty"
                                   @clicked="onReset">
                        Zurücksetzen
                    </action-button>
                    <save-button :disabled="isDirty !== true"
                                 :loading="formsaving"
                                 :save-new-btn="true"
                                 :save-home-btn="true"
                                 @save="submitForm"></save-button>
                </div>
            </div>


            <template v-slot:after-actions>

                <form-switch name="markAsEditorial"
                             :form-model="{markAsEditorial: markAsEditorial}"
                             :field-value="markAsEditorial"
                             :field="{nodirty: true, switchlabel: 'Fachlich/inhaltlicher Stand', disabled: !isDirty }"
                             @input="(c) => { markAsEditorial = c }"/>


<!--                <form-checkbox-->
<!--                    name="markAsEditorial"-->
<!--                    :form-model="{markAsEditorial: markAsEditorial}"-->
<!--                    :field-value="markAsEditorial"-->
<!--                    :field="{nodirty: true, checkboxlabel: 'Fachlich/inhaltlicher Stand', disabled: !isDirty }"-->
<!--                    @input="(c) => { markAsEditorial = c }"/>-->
            </template>


            <template v-slot:description>{{ toolbarDescription }}</template>
        </toolbar>

        <form-layout-tabs :fields="formTabs"
                          :form-fields="formFields"
                          :form-model.sync="model"
                          :responseerrors="errors"
                          ref="formtabs"
        ></form-layout-tabs>


        <template v-if="userIsModelInstitution">
            <form-layout-content
                :fields="formFields"
                :form-model.sync="model"
                :rendersections="rendersections"
                :responseerrors="errors">

                <template v-slot:before-fields>
                    <template v-if="model.answer_closed_date">
                        <div class="alert alert-info mb-4">
                            <b>Hinweis:</b> Die Anfrage wurde am {{ renderDate(model.answer_closed_date) }} geschlossen und kann nicht mehr bearbeitet werden! Bitte wenden Sie sich ggf. an den Administrator.
                        </div>
                    </template>
                </template>

                <template v-if="activeTabId === 'rt1'" v-slot:sidebar>

                    <div v-if="activeTabId === 'rt1'">
                        <form-memofield
                            mode="section"
                            label="Interne Notiz"
                            name="internal_memo"
                            :field="{}"
                            :form-model="model"/>
                    </div>

                </template>

            </form-layout-content>
        </template>
        <template v-else>
            <form-layout-content
                :fields="formFields"
                :form-model.sync="model"
                :rendersections="rendersections"
                :responseerrors="errors">

                <template v-slot:before-fields>
                    <template v-if="model.answer_closed_date">
                        <div class="alert alert-info mb-4">
                            <b>Hinweis:</b> Die Anfrage wurde am {{ renderDate(model.answer_closed_date) }} geschlossen und kann nicht mehr bearbeitet werden! Bitte wenden Sie sich ggf. an den Administrator.
                        </div>
                    </template>
                </template>


                <template v-if="activeTabId === 'rt1'" v-slot:sidebar>

                    <div v-if="activeTabId === 'rt1'">
                        <form-memofield
                            mode="section"
                            label="Interne Notiz"
                            name="internal_memo"
                            :field="{}"
                            :form-model="model"/>
                    </div>

                </template>
            </form-layout-content>
        </template>


    </form-container>
</template>

<style scoped>

</style>
