<template>
    <form-field :responseerrors="responseerrors" :name="name" :field="field" :key="name" :validate="validate" :form-model="formModel">
        <template v-slot:field>
            <div ref="formfield">
                <date-picker
                    @input="handleDateSelect"
                    format="dd.MM.yyyy"
                    :name="name"
                    :disabled="fieldIsDisabled"
                    :value="currentValue"
                    :placeholder="getPlaceholder()"
                    :popper-props="popperProps"
                    :typeable="typeable"
                    :min-date="minDate"
                    :max-date="maxDate"></date-picker>
            </div>
        </template>
    </form-field>
</template>

<script>

import {getCurrentInstance, computed, inject, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-date",
    components: {
        'date-picker': require("../date-picker").default
    },

    props: {
        ...defaultFieldProps,
        popperProps: {
            type: Object,
            default() {
                return {}
            }
        },
        minDate: {},
        maxDate: {}
    },
    emits: ['input'],
    setup(props, context) {
        const inst = getCurrentInstance()
        const $moment = inject('$moment');

        const { fieldIsDisabled,  modelvalue} = useFormField(props, context);
        const currentValue = ref('');

        const watchField = watch(() => props.field, (n, o) => {
            if (n!==o) {
                initField();
            }
        }, {deep: true});

        const watchFieldModel = watch(() => modelvalue.value, (n, o) => {
            if (n!==o && n !== currentValue.value) {
                currentValue.value = n
            }
        });


        const typeable = computed(() => {
            if (props.field.hasOwnProperty('typeable')) {
                return props.field.typeable
            }
            return false;
        });

        onBeforeUnmount(() => {
            if (watchFieldModel) watchFieldModel()
            if (watchField) watchField();
        });

        onBeforeMount(() => {
            initField();
            addModelWatcher();
        });


        function initField() {


            if (props.field.value) {
                currentValue.value = $moment(props.field.value).format('YYYY-MM-DD');
            }
            else if (!currentValue.value && modelvalue.value) {
                currentValue.value = $moment(modelvalue.value).format('YYYY-MM-DD');
            }

            if (!currentValue.value && props.field.default) {
                currentValue.value = $moment(props.field.default).format('YYYY-MM-DD');
            }
        }

        function addModelWatcher() {

        }

        function getPlaceholder() {
            return props.field.hasOwnProperty('placeholder') ? props.field.placeholder : null;
        }

        function customFormatter(date) {
            return $moment(date).format('dd MM yyyy, h:mm');
        }

        function handleDateSelect(date) {
            if (date) {
                modelvalue.value = date;
            } else {
                modelvalue.value = null;
            }
            context.emit('input', date ? date : null);
        }

        function changeValue() {
            modelvalue.value = inst.refs.formfield.value;
        }

        return {
            modelvalue,
            currentValue,
            fieldIsDisabled,
            typeable,
            handleDateSelect,
            changeValue,
            getPlaceholder,
        }

    },

}
</script>

<style scoped>
.vdp-datepicker input {
    width: 100%
}
</style>
