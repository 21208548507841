<template>
    <div
        class="vue-notification-group"
        :style="styles"
    >
        <component
            :is="componentName"
            :name="animationName"
            @enter="enter"
            @leave="leave"
            @after-leave="clean"
        >
            <div
                v-for="item in active"
                class="vue-notification-wrapper"
                :style="notifyWrapperStyle(item)"
                :key="item.id"
                :data-id="item.id"
            >
                <slot
                    name="body"
                    :class="[classes, item.type]"
                    :item="item"
                    :close="() => destroy(item)"
                >
                    <!-- Default slot template -->
                    <div
                        :class="notifyClass(item)"
                        @click="destroyIfNecessary(item)"
                    >
                        <div
                            v-if="item.title"
                            class="notification-title"
                            v-html="item.title"
                        >
                        </div>
                        <div
                            class="notification-content"
                            v-html="item.text"
                        >
                        </div>
                    </div>
                </slot>
            </div>
        </component>
    </div>
</template>
<script>
import plugin                                         from './index';
import {Id, listToDirection}                          from './util';
import defaults                                       from './defaults';
import VelocityGroup                                  from './VelocityGroup.vue';
import CssGroup                                       from './CssGroup.vue';
import parseNumericValue                              from './parser';
import {computed, getCurrentInstance, onMounted, ref} from "vue";

const STATE = {
    IDLE: 0,
    DESTROYED: 2
}

export default {
    name: 'Notifications',
    inject: ['$events'],
    components: {
        VelocityGroup,
        CssGroup
    },
    props: {
        group: {
            type: String,
            default: ''
        },

        width: {
            type: [Number, String],
            default: 300
        },

        reversed: {
            type: Boolean,
            default: false
        },

        position: {
            type: [String, Array],
            default: () =>
            {
                return defaults.position;
            }
        },

        classes: {
            type: String,
            default: 'vue-notification'
        },

        animationType: {
            type: String,
            default: 'css',
            validator(value) {
                return value === 'css' || value === 'velocity';
            }
        },

        animation: {
            type: Object,
            default() {
                return defaults.velocityAnimation;
            }
        },

        animationName: {
            type: String,
            default: defaults.cssAnimation
        },

        speed: {
            type: Number,
            default: 300
        },
        /* Todo */
        cooldown: {
            type: Number,
            default: 0
        },

        duration: {
            type: Number,
            default: 3000
        },

        delay: {
            type: Number,
            default: 0
        },

        max: {
            type: Number,
            default: Infinity
        },

        ignoreDuplicates: {
            type: Boolean,
            default: false
        },

        closeOnClick: {
            type: Boolean,
            default: true
        }
    },


    setup(props, context) {
        const inst = getCurrentInstance();
        const app = inst.root.ctx;
        const list = ref([]);
        const velocity = plugin.params.velocity;


        const actualWidth = computed(() => {
            return parseNumericValue(props.width);
        })
        const isVA = computed(() => {
            return props.animationType === 'velocity';
        })
        const componentName = computed(() => {
            return isVA.value  ? 'VelocityGroup' : 'CssGroup';
        })
        const styles = computed(() => {
            const {x, y} = listToDirection(props.position);
            const width = actualWidth.value.value;
            const suffix = actualWidth.value.type;

            let styles = {
                width: width + suffix,
                [y]: '0px'
            };

            if (x === 'center') {
                styles['left'] = `calc(50% - ${width / 2}${suffix})`;
            } else {
                styles[x] = '0px';
            }

            return styles;
        });

        const active = computed(() => {
            return list.value.filter(v => v.state !== STATE.DESTROYED);
        });
        const botToTop = computed(() => {
            return styles.value.hasOwnProperty('bottom');
        });


        onMounted(() => {
            if (Neloh.$events) {
                Neloh.$events.$on('addNotification', addItem);
                Neloh.$events.$on('closeNotification', closeItem);
            }
        });




        function destroyIfNecessary(item) {
            if (props.closeOnClick) {
                destroy(item);
            }
        }

        function addItem(event) {
            event.group = event.group || '';

            if (props.group !== event.group) {
                return;
            }

            if (event.clean || event.clear) {
                destroyAll();
                return;
            }

            const duration = typeof event.duration === 'number'
                ? event.duration
                : props.duration;

            const speed = typeof event.speed === 'number'
                ? event.speed
                : props.speed;

            const ignoreDuplicates = typeof event.ignoreDuplicates === 'boolean'
                ? event.ignoreDuplicates
                : props.ignoreDuplicates;

            let {title, text, type, data, id} = event;

            const item = {
                id: id || Id(),
                title,
                text,
                type,
                state: STATE.IDLE,
                speed,
                length: duration + 2 * speed,
                data
            };

            if (duration >= 0) {
                item.timer = setTimeout(() =>
                {
                    destroy(item);
                }, item.length);
            }

            let direction = props.reversed
                ? !botToTop.value
                : botToTop.value;

            let indexToDestroy = -1;

            const isDuplicate = active.value.some(item =>
            {
                return item.title === event.title && item.text === event.text;
            });

            const canAdd = ignoreDuplicates ? !isDuplicate : true;

            if (!canAdd) return;

            if (direction) {
                list.value.push(item);

                if (active.value.length > props.max) {
                    indexToDestroy = 0;
                }
            } else {
                list.value.unshift(item);

                if (active.value.length > props.max) {
                    indexToDestroy = active.value.length - 1;
                }
            }

            if (indexToDestroy !== -1) {
                destroy(active.value[indexToDestroy]);
            }
        }

        function closeItem(id) {
            destroyById(id);
        }

        function notifyClass(item) {
            return [
                'vue-notification-template',
                props.classes,
                item.type
            ];
        }

        function notifyWrapperStyle(item) {
            return isVA.value
                ? null
                : {transition: `all ${item.speed}ms`};
        }

        function destroy(item) {
            if (item.timer) {
                clearTimeout(item.timer);
            }
            item.state = STATE.DESTROYED;

            if (!isVA.value) {
                clean();
            }
        }

        function destroyById(id) {
            const item = list.value.find(v => v.id === id);

            if (item) {
                destroy(item);
            }
        }

        function destroyAll() {
            active.value.forEach(destroy);
        }

        function getAnimation(index, el) {
            const animation = props.animation[index];

            return typeof animation === 'function'
                ? animation.call(inst.ctx, el)
                : animation;
        }

        function enter(el, complete) {
            const animation = getAnimation('enter', el);

            velocity(el, animation, {
                duration: props.speed,
                complete
            });
        }

        function leave(el, complete) {
            let animation = getAnimation('leave', el);

            velocity(el, animation, {
                duration: props.speed,
                complete
            });
        }

        function clean() {
            list.value = list.value.filter(v => v.state !== STATE.DESTROYED);
        }


        return {
            active,
            styles,
            componentName,
            clean,
            notifyClass,
            notifyWrapperStyle,
            destroyIfNecessary,
            destroy,
            leave,
            enter
        };
    }
}

</script>
<style>
</style>
