<script>
import {defineComponent, getCurrentInstance, inject, computed, nextTick, onBeforeMount, ref, onMounted} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";

export default defineComponent({
    name: "dashboardIndex",
    setup() {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx
        const $http = inject('$http');
        const $events = inject('$events');
        const $moment = inject('$moment')
        const route = useRoute();
        const router = useRouter()
        const store = useStore();

        const searchModel = store.getters['globalSearch/model'];
        const widgetData = ref({})
        const last_requests = ref([])


        const userSettings = computed(() => {
            if (_.isObject(store.getters['user/user'].settings.search) ) {
                return store.getters['user/user'].settings.search;
            }
            return null;
        });


        onBeforeMount(() => {
            initDefaults()
        });


        onMounted(() => {
            loadData();
        })


        function initDefaults()
        {

            if (store.getters['globalSearch/isDone']) {
                return;
            }


            // Update user search form defaults
            if (_.isObject(store.getters['user/user'].settings.search) )
            {
                if (store.getters['user/user'].settings.search.hasOwnProperty('mode')) {
                    store.dispatch('globalSearch/updateSearchModel', {mode: store.getters['user/user'].settings.search.mode })
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('searchIn')) {
                    store.dispatch('globalSearch/updateSearchModel', {searchIn: store.getters['user/user'].settings.search.searchIn})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('searchView')) {
                    store.dispatch('globalSearch/updateSearchModel', {viewmode: store.getters['user/user'].settings.search.searchView})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('perpage')) {
                    store.dispatch('globalSearch/updateSearchModel', {perpage: store.getters['user/user'].settings.search.perpage})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('sort')) {
                    store.dispatch('globalSearch/updateSearchModel', {sort: store.getters['user/user'].settings.search.sort})
                }

                if (store.getters['user/user'].settings.search.hasOwnProperty('order')) {
                    store.dispatch('globalSearch/updateSearchModel', {order: store.getters['user/user'].settings.search.order})
                }
                store.dispatch('globalSearch/setInitDone')
            }

        }

        function loadData() {
            $http.post($root.currentRouteUrl(route.path)).then((r) => {

                if (r.data.success) {
                    widgetData.value = r.data.widget_data;
                    last_requests.value = r.data.last_requests;
                }

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function formatDate(date, format) {
            return date ? $moment(date).format(format ? format : 'DD.MM.YYYY') : '-'
        }

        function handleSearch() {
            router.push({name: 'admin.search'}).then(() => {
                $events.$emit('run-search')
            });
        }

        return {
            userSettings,
            widgetData,
            last_requests,
            searchModel,
            formatDate,
            handleSearch
        }
    }
})
</script>
<template>
    <div class="dashboard-container">

        <div class="widget basic" v-if="$store.getters['user/user'].is_admin">
            <div class="title">
                Allgemeine Statistik
            </div>
            <div class="content">
                <template v-for="r in widgetData">
                    <div class="stats-item">
                        <span v-if="r.icon">
                            <svg-icon :name="r.icon"/>
                        </span>
                        <span class="label">{{ r.label }}</span>
                        <span class="count">{{ r.count }}</span>
                    </div>
                </template>
            </div>
        </div>


<!--        <div class="widget requests">-->
<!--            <div class="title">-->
<!--                Die letzten Anfragen-->
<!--            </div>-->
<!--            <div class="content">-->
<!--                <template v-if="Array.isArray(last_requests) && last_requests.length">-->
<!--                    <template v-for="r in last_requests">-->
<!--                        <div class="item">-->
<!--                            <div>-->
<!--                                <router-link :to="{name: 'admin.requests.edit', params: {id: r.id}}">{{ r.question }}</router-link>-->
<!--                            </div>-->
<!--                            <div class="user">-->
<!--                                <span v-if="r.createdby">{{ r.createdby.username }}</span>-->
<!--                                {{ formatDate(r.created_at, 'DD.MM.YYYY, HH:mm') }}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                    <div><em>keine Anfragen vorhanden...</em></div>-->
<!--                </template>-->
<!--            </div>-->
<!--        </div>-->

        <div class="widget search">
            <div class="title">Universal-Suchmaske</div>
            <div class="content">
                <search-form :search-model="searchModel" @search="handleSearch"/>
            </div>
        </div>
    </div>
</template>
