<script>
import {defineComponent, computed, ref, inject, onMounted, getCurrentInstance, nextTick} from 'vue'
import {useStore} from "~/vuex";

export default defineComponent({
    name: "search-form",
    emits: ['search'],
    setup(props, context) {
        const inst = getCurrentInstance();
        const store = useStore();
        const searchModel = ref(store.getters['globalSearch/model']);
        const unisearchForm = inject('unisearchForm')
        const requestSubjects = inject('requestSubjects')

        const form = computed(() => {
            if (unisearchForm && unisearchForm.hasOwnProperty('fields')) {
                return unisearchForm.fields;
            }

            return null;
        })

        const canSearch = computed(() => {
            return searchModel.value.q && searchModel.value.q.length >= 3
        })




        function handleSearch() {
            if (canSearch.value) {
                context.emit('search')
            }
        }


        function handleKey(e) {
            if (e && e.keyCode === 13) {
                handleSearch();
            }
        }

        onMounted(() => {
            if (inst.refs.searchform) {
                nextTick(() => {
                    let s = inst.refs.searchform.querySelector('[name=q]');
                    s.addEventListener('keyup', handleKey);
                })
            }
        });

        return {
            handleSearch,
            canSearch,
            form,
            searchModel,
            requestSubjects
        }
    }
});
</script>
<template>
    <div ref="searchform" class="search-form global-search">
        <template v-if="form">
            <form-fields :fields="form"
                         :form-model="searchModel"
                         mode="main"></form-fields>
        </template>
        <template v-else>
            <input type="text" v-model="searchModel.q"
                   @keyup.enter="handleSearch" placeholder="Suchen nach ..."/>

            <div class="row">
                <div class="col-md-3">
                    <form-select :field="{ label: 'Sortieren nach', options: {
                            date: 'Datum',
                            title: 'Titel',
                        } }" name="sort" :form-model="searchModel"></form-select>
                </div>
                <div class="col-md-3">
                    <form-select :field="{ label: 'Sortierung', options: {
                            asc: 'aufsteigend',
                            desc: 'absteigend',
                        } }" name="order" :form-model="searchModel"></form-select>
                </div>
                <div class="col-md-3">
                    <form-select :field="{ label: 'mit Status', options: {
                            1: 'offen',
                            2: 'intern',
                            3: 'veröffentlicht',
                        } }" name="published" :form-model="searchModel"></form-select>
                </div>

            </div>
            <div class="row">
                <form-checkboxlist
                    name="searchIn"
                    :form-model="searchModel"
                    :field="{ label: 'Suche in', multiple: true, options: requestSubjects, horizontal: true }"/>
            </div>
        </template>


        <button type="button"
                class="btn btn-primary block"
                :disabled="!canSearch"
                @click.prevent="handleSearch">Suchen</button>
    </div>
</template>

<style scoped>

</style>
