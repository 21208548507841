<template>
    <form-field :name="name" :form-model="formModel" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors">

        <template v-slot:field>
            <div class="select-autocomplete">
                <v-select ref="select"
                          :label="field.labelField"
                          :get-option-label="getOptionLabel"
                          :filterable="false"
                          :options="selectOptions"
                          :model-value="selectedValue"
                          :disabled="isDisabled"
                          :clearable="isDisabled ? false : canClear"
                          :multiple="allowMultiple"
                          :placeholder="field.hasOwnProperty('placeholder') ? field.placeholder : ''"
                          append-to-body
                          @search:focus="onInputFocus"
                          @input="updateModelValue"
                          @search="onSearch">

                    <template v-slot:no-options>
                        keine Datei gefunden...
                    </template>

                    <template v-slot:custom-actions="option">
                        <template v-if="canDownloadFiles">
                            <button type="button"
                                    v-tooltip="'Datei anzeigen'"
                                    @click.prevent="displayFile(option)"><span class="fa fa-search"></span></button>
                        </template>
                    </template>

                    <template v-slot:selected-option="option">

                        <div class="selected-people" v-if="option && option.id">
                            <div class="prefix-spacer">
                                <span class="selected-prefix">File: {{ option.id }}</span>
                            </div>
                            <div>
                                <span v-if="field.labelField !== 'title' && field.labelField && option[field.labelField]">
                                    {{ option[field.labelField] }}
                                </span>
                                <span v-else-if="option.title">
                                    {{ option.title }}
                                </span>
                                <small v-if="option.original_filename !== option.original_filename">
                                    <span>{{ option.original_filename }} {{ option.original_filename }}</span>
                                </small>
                                <small v-if="option.original_filename">
                                    <span>{{ option.original_filename }}</span>
                                </small>


                            </div>
                        </div>
                    </template>

                    <template v-slot:option="option">
                        <slot name="option" v-bind:option="option">
                            <div class="selected-people" v-if="option && option.id">
                                <div class="prefix-spacer"><span class="selected-prefix">File: {{ option.id }}</span></div>
                                <div>
                                    <template v-if="field.labelField !== 'title' && field.labelField && option[field.labelField]">
                                        <div v-if="option[field.labelField]">{{ option[field.labelField] }}</div>
                                    </template>
                                    <template v-else>
                                        <div v-if="option.title">{{ option.title }}</div>
                                        <div v-if="option.original_filename !== option.original_filename">
                                            {{ option.original_filename }} {{ option.original_filename }}</div>
                                        <div v-else>{{ option.original_filename }}</div>
                                    </template>
                                </div>
                            </div>
                        </slot>
                    </template>
                </v-select>
            </div>
        </template>

    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, onBeforeMount, inject, nextTick, toRaw} from "vue"
import {useFormField, defaultFieldProps} from "./../../mixins/use-form-field";


export default {
    name: "fileselect",

    props: {
        ...defaultFieldProps,
        returnOnly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],

    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const $events = inject('$events');
        const $http = inject('$http');

        const setPropertyPath = inject('setPropertyPath')

        const {allowMultiple, modelName, modelvalue, fieldIsDisabled} = useFormField(props, context);

        const isMounted = ref(false);
        const mutatedField = ref({});
        const fieldOptions = ref([]);
        const searchInput = ref('');
        const selectedValue = ref([]);
        const initValue = ref(null);
        const cancel = ref(null)


        watch(() => props.field, (n) => {
            mutatedField.value = n;
        }, {deep: true});

        watch(() => modelvalue.value, (n) => {
            if (typeof n === "object") selectedValue.value = n;
            onInputFocus()
        });


        const selectOptions = computed(() => {
            if (!isMounted.value) return [];
            return fieldOptions.value
        });

        const autocompleteUrl = computed(() => {
            if (props.field.hasOwnProperty('route')) {
                return app.routes(props.field.route);
            } else if (props.field.hasOwnProperty('url')) {
                return props.field.url;
            } else {
                return app.routes('admin.mediamanager.search');
            }
        });

        const canDownloadFiles = computed(() => {
            return props.field.displaylink === true && props.field.displayroute;
        });

        const canClear = computed(() => {
            return !props.field.disabled && !fieldIsDisabled.value && !props.field.clearable;
        });



        onBeforeMount(() => {

            // if (allowMultiple.value) {
            //     if (!_.isArray(modelvalue.value)) {
            //         modelvalue.value = [];
            //         selectedValue.value = [];
            //     }
            // } else {
            //     if (_.isArray(modelvalue.value) && !_.isObject(modelvalue.value)) {
            //         modelvalue.value = null;
            //         selectedValue.value = null;
            //     }
            // }

            initDefaults();

            $events.$on('form-reset', () => {
                selectedValue.value = !_.isNull(initValue.value.value) ? _.cloneDeep(initValue.value.value) : [];
                // this.fieldOptions = _.cloneDeep(this.initValue.fieldOptions);

                onInputFocus()
            });

            if (props.field.hasOwnProperty('watchview') && props.field.watchview) {
                watch(() => props.formModel[props.field.watchview], (n) => {
                    if (_.isObject(n)) {
                        selectedValue.value = Object.assign({}, n);
                    }
                }, {deep: true});

                if (props.formModel.hasOwnProperty(props.field.watchview)) {
                    if (_.isObject(props.formModel[props.field.watchview])) {
                        selectedValue.value = Object.assign({}, props.formModel[props.field.watchview]);
                    }
                }
            }
        });

        onMounted(() => {
            isMounted.value = true;
        });



        function initDefaults() {

            mutatedField.value = props.field;

            if (props.field.hasOwnProperty('options') && !_.isEmpty(props.field.options)) {
                fieldOptions.value = _.isArray(props.field.options) ? props.field.options : [props.field.options];
            }

            if (props.field.hasOwnProperty('selected')) {
                selectedValue.value = toRaw(props.field.selected)
            } else {

                if (_.isArray(props.field.value)) {
                    selectedValue.value = toRaw(props.field.value);
                }
                else if (_.isObject(props.field.value)) {
                    selectedValue.value = Object.assign({}, props.field.value);
                } else {
                    selectedValue.value = toRaw(modelvalue.value);
                }
            }


            if (!props.returnOnly) {
                if (allowMultiple.value) {
                    if (!_.isArray(toRaw(modelvalue.value))) {
                        modelvalue.value = [];
                    }
                } else {
                    if (_.isArray(toRaw(modelvalue.value))) {
                        modelvalue.value = null;
                    }
                }

                if (_.isObject(mutatedField.value.value)) {
                    selectedValue.value = Object.assign({}, mutatedField.value.value);
                }

                $events.$on('clear-form', () => {
                    updateModelValue(null);
                });
            }

            initValue.value = {
                value: _.cloneDeep(toRaw(selectedValue.value)),
                fieldOptions: _.cloneDeep(toRaw(fieldOptions.value))
            };

        }

        function reset() {
            selectedValue.value = [];
        }

        function getOptionLabel(option) {
            return option;
        }

        function transformFieldOptions() {

            let options = [];
            _.each(mutatedField.value.options, (label, value) => {

                if (label.hasOwnProperty('label') && label.hasOwnProperty('value')) {
                    options.push(label);
                } else {
                    if ((_.isString(value) && value.match(/^\d{1,}/g)) || _.isNumber(value)) {

                        options.push({label: label, value: parseInt(value)});
                    } else {
                        options.push({label: label, value: value});
                    }
                }
            });

            return options;
        }



        let t;

        function onSearch(search, loading) {

            clearTimeout(t)

            if (cancel.value) {
                cancel.value.cancel();
                cancel.value = null;
                loading(false);
            }


            if (!search) {
                return;
            }

            t = setTimeout(() => {
                callSearch(loading, search)
            }, 300);
        }

        function callSearch(loading, search) {

            const axiosSource = axios.CancelToken.source();
            cancel.value = {cancel: axiosSource.cancel, msg: "Loading..."};

            let postData;
            if (mutatedField.value.hasOwnProperty('postparams') && _.isObject(mutatedField.value.postparams)) {
                postData = Object.assign({}, mutatedField.value.postparams, {q: search})
            } else {
                postData = Object.assign({}, {q: search});
            }

            loading(true);

            $http.post(autocompleteUrl.value, postData, {cancelToken: axiosSource.token}).then(r => {
                if (r.data) {
                    fieldOptions.value = r.data;
                }

                loading(false);
            }).catch(e => {
                console.warn('Error:', e.statusText);
                loading(false);
            });
        }

        function updateModelValue(option) {


            if (!props.returnOnly) {

                modelvalue.value = option;

                if (allowMultiple.value) {

                    if (_.isArray(option)) {
                        // let ids = [];
                        // _.each(option, (item) => {
                        //     if (item.id) {
                        //         ids.push(item.id);
                        //     }
                        // });
                        //
                        // setPropertyPath(props.formModel, modelName.value, ids);


                        modelvalue.value = option;
                        selectedValue.value = option;
                    } else {
                        selectedValue.value = option;
                        modelvalue.value = option;

                        // if (option === null) {
                        //     selectedValue.value = option;
                        //     modelvalue.value = option;
                        // } else {
                        //     modelvalue.value.push(option.id);
                        //     selectedValue.value = option;
                        // }
                    }
                } else {

                    selectedValue.value = option;
                    modelvalue.value = option;
                }


                if (props.field.hasOwnProperty('onSelect') &&
                    _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval(props.field.onSelect);
                    } else {
                        if (_.isFunction(inst[props.field.onSelect])) {
                            inst[props.field.onSelect]();
                        }
                    }
                }
            } else {
                selectedValue.value = option;
                modelvalue.value = option;

                if (props.field.hasOwnProperty('onSelect') &&
                    _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval(props.field.onSelect);
                    } else {
                        if (_.isFunction(inst[props.field.onSelect])) {
                            inst[props.field.onSelect]();
                        }
                    }
                }
            }

            context.emit('input', option);
        }

        function fileChanged() {
            $events.$emit('fileselect-changed', selectedValue.value);
        }


        function onInputFocus() {
            // if (!allowMultiple.value) {
            //     if (inst.refs.select) {
            //         let r = inst.refs.select.$.refs
            //
            //         if (r.toggle) {
            //             r.toggle.style.minHeight = '';
            //             r.search.style.height = '';
            //         }
            //
            //         nextTick(() => {
            //             if (r.search) {
            //                 r.toggle.style.minHeight = r.toggle.offsetHeight +'px';
            //                 r.search.style.height = r.selectedOptions.offsetHeight +'px';
            //             }
            //         })
            //
            //
            //     }
            // }
        }


        function displayFile(option) {
            window.open(app.routes(props.field.displayroute, {file: option.id}))
        }

        return {
            isMounted,
            allowMultiple,
            mutatedField,
            canClear,
            modelvalue,
            isDisabled: fieldIsDisabled,
            isMultiple: allowMultiple,
            selectedValue,
            fieldOptions,
            selectOptions,
            onInputFocus,
            getOptionLabel,
            reset,
            onSearch,
            updateModelValue,
            fileChanged,


            canDownloadFiles,
            displayFile,
        }

    },


}
</script>

<style scoped>
.vs__dropdown-menu {
    z-index: 99999;
}
</style>
