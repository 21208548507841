<template>
    <div :class="['nestable', `nestable-${group}`, rtl ? 'nestable-rtl' : '']">
        <ol class="nestable-list nestable-group">

            <!-- No items in list -->
            <Placeholder v-if="listIsEmpty" :options="itemOptions">
                <slot name="placeholder">
                    No content
                </slot>
            </Placeholder>

            <!-- Render items  :key="itemKey(item, index)"-->
            <template v-for="(item, index) in value" :key="item[keyProp]">
                <NestableItem
                    :index="index"
                    :item="item"
                    :options="itemOptions"
                    :last-strip-class="itemStripClass(index)"
                    :parent-key-prop="parentKeyProp"
                    :parent-id="parentId > 0 ? parentId : item[parentKeyProp]"
                    :deep="0">
                    <template v-slot="scope">
                        <slot v-bind="scope"/>
                    </template>

                    <!-- bind scoped slots to the netable-item component -->
                    <template v-for="(s, name) in Object.keys(slots)" v-slot:[name]="scope">
                        <slot :name="name" v-bind="scope"/>
                    </template>

                </NestableItem>
            </template>
        </ol>

        <template v-if="dragItem">
            <div class="nestable-drag-layer">
                <ol
                    :style="listStyles"
                    class="nestable-list"
                >
                    <NestableItem
                        :item="dragItem"
                        :options="itemOptions"
                        :parent-id="parentId > 0 ? parentId : item[parentKeyProp]"
                        :is-copy="true"
                        :deep="0"
                    >
                        <template v-slot="scope">
                            <slot v-bind="scope"/>
                        </template>

                        <!-- bind scoped slots to the netable-item component -->
                        <template v-for="(s, name) in Object.keys(slots)" v-slot:[name]="scope">
                            <slot :name="name" v-bind="scope"/>
                        </template>
                    </NestableItem>
                </ol>
            </div>
        </template>
    </div>
</template>

<script type="text/babel">

import NestableItem from './NestableItem.vue'
import Placeholder from './Placeholder.vue'
import updateimmutability from '../../utils/immutability-helper'

import {
    provide,
    getCurrentInstance,
    ref,
    computed,
    onBeforeUnmount,
    onBeforeMount,
    nextTick,
    onMounted
} from 'vue';


import {useGroupObserver} from "./groups-observer.js";

import {useNestableHooks} from "./calls-hooks.js";

import {useNestableHelpers} from "./nestable-helpers.js";

import {
    closest,
    getOffsetRect,
    getTransformProps,
    listWithChildren,
} from "./utils.js";

export default {
    name: 'VueNestable',
    components: {
        NestableItem,
        Placeholder
    },

    emits: ['input', 'change'],

    props: {
        value: {
            type: Array,
            required: true,
            default: () => ([])
        },
        threshold: {
            type: Number,
            required: false,
            default: 30
        },
        maxDepth: {
            type: Number,
            required: false,
            default: 10
        },
        keyProp: {
            type: String,
            required: false,
            default: 'id'
        },
        parentKeyProp: {
            type: String,
            required: false,
            default: 'parent_id'
        },

        classProp: {
            type: String,
            required: false,
            default: null
        },
        group: {
            type: [String, Number],
            required: false,
            default: () => Math.random().toString(36).slice(2)
        },

        childrenProp: {
            type: String,
            required: false,
            default: 'children'
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        hooks: {
            type: Object,
            required: false,
            default: () => ({})
        },
        rtl: {
            type: Boolean,
            required: false,
            default: false
        },
        parentKey: {
            default: null
        }
    },

    setup(props, context) {
        const inst = getCurrentInstance();

        const {hook} = useNestableHooks(props, context, inst);
        //
        const {
            getPathById,
            getItemByPath,
            getItemDepth,
            getSplicePath,
            getRealNextPath
        } = useNestableHelpers(props, context, inst, isCollapsed);

        const {
            unregisterNestable,
            registerNestable,
        } = useGroupObserver(props, context, inst);


        const itemsOld = ref(null); // revert to copy in case of cancelling drag
        const dragItem = ref(null);

        const mouse = ref({
            last: {x: 0},
            shift: {x: 0}
        });

        const el = ref(null);
        const elCopyStyles = ref(null);
        const isDirty = ref(false);
        const collapsedGroups = ref([]);
        const listId = Math.random().toString(36).slice(2);
        const dragcontext = ref(null);
        const mutatedValue = ref([]);
        const _pathTo = ref(null);


        inst.ctx.listId = listId;
        inst.ctx.group = props.group;
        inst.ctx.keyProp = props.keyProp;
        inst.ctx.onDragEnd = onDragEnd;


        inst.ctx.onDragStart = onDragStart;
        inst.ctx.onMouseEnter = onMouseEnter;
        inst.ctx.onMouseMove = onMouseMove;


        const slots = computed(() => {
            return context.slots;
        });

        const listIsEmpty = computed(() => {
            return props.value.length === 0
        });

        const itemOptions = computed(() => {
            return {
                dragItem: dragItem.value,
                keyProp: props.keyProp,
                classProp: props.classProp,
                childrenProp: props.childrenProp,
                onDragEnd: onDragEnd
            }
        });

        const parentId = computed(() => {
            if (!dragItem.value) return null;
            if (!dragItem.value.hasOwnProperty(props.keyProp)) return null;
            return dragItem.value[props.keyProp]
        })

        const listStyles = computed(() => {
            if (!_.isObject(dragItem.value)) return {}
            if (!dragItem.value.hasOwnProperty(props.keyProp)) return {}


            const el = document.querySelector('.nestable-' + props.group + ' .nestable-item-' + dragItem.value[props.keyProp])

            let listStyles = {}
            if (el) {
                listStyles.width = `${el.clientWidth}px`
            }

            if (elCopyStyles.value) {
                listStyles = {
                    ...listStyles,
                    ...elCopyStyles.value
                }
            }

            return listStyles
        });


        // registerNestable(inst.ctx);


        onMounted(() => {
            const items = listWithChildren(props.value, props.childrenProp);
            registerNestable(inst.ctx);
        });


        onBeforeUnmount(() => {
            // dragItem.value = null;
            // unregisterNestable(inst.ctx);
            stopTrackMouse();
        });


        function itemKey(item, index) {
            if (item && item.hasOwnProperty(props.keyProp)) {
                return item[props.keyProp];
            }
            return index;
        }


        function startTrackMouse() {
            document.addEventListener('mousemove', onMouseMove)
            document.addEventListener('mouseup', onDragEnd)
            document.addEventListener('touchend', onDragEnd)
            document.addEventListener('touchcancel', onDragEnd)
            document.addEventListener('keydown', onKeyDown)
        }

        function stopTrackMouse() {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onDragEnd)
            document.removeEventListener('touchend', onDragEnd)
            document.removeEventListener('touchcancel', onDragEnd)
            document.removeEventListener('keydown', onKeyDown)

            elCopyStyles.value = null
        }


        function onDragStart(event, it) {
            if (event) {
                event.preventDefault()
                event.stopPropagation()
            }

            el.value = closest(event.target, '.nestable-item')
            if (!el.value) return;

            stopTrackMouse();
            startTrackMouse()

            dragItem.value = _.clone(it)
            itemsOld.value = _.clone(props.value);

            // Trigger a mouseMove event to update the ghost item with the mouse
            // position
            nextTick(() => {
                onMouseMove(event)
            })
        }

        /**
         *
         * @param {MouseEvent} event
         * @param isCancel
         */
        function onDragEnd(event, isCancel) {

            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }

            stopTrackMouse();

            el.value = null

            isCancel
                ? dragRevert()
                : dragApply()
        }

        function onKeyDown(event) {
            if (event.which === 27) {
                // ESC
                onDragEnd(null, true)
            }
        }

        function getXandYFromEvent(event) {
            let {clientX, clientY} = event

            // get touch event
            const {targetTouches} = event

            // if there is a touch event, use this
            if (targetTouches) {
                const touch = targetTouches[0]
                clientX = touch.clientX
                clientY = touch.clientY

                // we rely on the mouseenter event to track if a node should be moved
                // since this event does not exist, we need to simulate it.
                const event = new Event('mouseenter')
                const element = document.elementFromPoint(clientX, clientY)
                const touchElement = element && (element.closest('.nestable-item-content') || element.closest('.nestable-list-empty'))
                if (touchElement) touchElement.dispatchEvent(event)
            }

            return {clientX, clientY}
        }

        /**
         *
         * @param {MouseEvent} event
         */
        function onMouseMove(event) {
            if (event) {
                event.preventDefault()
            }
            const {clientX, clientY} = getXandYFromEvent(event)

            // initialize the initial mouse positoin on the first drag operation
            if (mouse.value.last.x === 0) {
                mouse.value.last.x = clientX
            }

            const transformProps = getTransformProps(clientX, clientY)

            // In some cases the drag-layer might not be at the top left of the window,
            // we need to find, where it acually is, and incorperate the position into the calculation.
            const elDragLayer = document.querySelector('.nestable-' + props.group + ' .nestable-drag-layer')
            if (!elDragLayer || elDragLayer === null) return;


            const elHeight = el.value.offsetHeight;

            const {top: dragLayerTop, left: dragLayerLeft} = elDragLayer.getBoundingClientRect()

            const elCopy = document.querySelector('.nestable-' + props.group + ' .nestable-drag-layer > .nestable-list')
            if (!elCopy) {
                console.warn('Invalid "elCopy" selector: ' + '.nestable-' + props.group + ' .nestable-drag-layer > .nestable-list');
                return;
            }
            // if (!elCopy ||(elCopy && !elCopy.hasOwnProperty('style') )) {
            //     return;
            // }
            //
            //
            //

            try {

                if (!elCopyStyles.value) {
                    const offset = getOffsetRect(el.value)

                    elCopyStyles.value = {
                        height: elHeight + 'px',
                        marginTop: `${offset.top - clientY - dragLayerTop}px`,
                        marginLeft: `${offset.left - clientX - dragLayerLeft}px`,
                        ...transformProps
                    }
                } else {
                    elCopyStyles.value = {
                        height: elHeight + 'px',
                        ...elCopyStyles.value,
                        ...transformProps
                    }

                    if (elCopy) {
                        for (const key in transformProps) {
                            if (Object.prototype.hasOwnProperty.call(transformProps, key)) {
                                elCopy.style[key] = transformProps[key]
                            }
                        }
                    }

                    const diffX = props.rtl ? mouse.value.last.x - clientX : clientX - mouse.value.last.x
                    if (
                        (diffX >= 0 && mouse.value.shift.x >= 0) ||
                        (diffX <= 0 && mouse.value.shift.x <= 0)
                    ) {
                        mouse.value.shift.x += diffX
                    } else {
                        mouse.value.shift.x = 0
                    }
                    mouse.value.last.x = clientX

                    if (Math.abs(mouse.value.shift.x) > props.threshold) {
                        if (mouse.value.shift.x > 0) {
                            tryIncreaseDepth(dragItem.value)
                        } else {
                            tryDecreaseDepth(dragItem.value)
                        }

                        mouse.value.shift.x = 0
                    }
                }

            } catch (e) {
                // console.warn('EEEE', e)
            }
        }

        function moveItem({dragItm, pathFrom, pathTo}, prevSibling = null) {
            // the remove action might affect the next position,
            // so update next coordinates accordingly
            const realPathTo = getRealNextPath(pathFrom, pathTo);

            const removePath = getSplicePath(pathFrom, {
                numToRemove: 1,
                childrenProp: props.childrenProp
            });

            const insertPath = getSplicePath(realPathTo, {
                numToRemove: 0,
                itemsToInsert: [dragItm],
                childrenProp: props.childrenProp
            });

            if (!hook('beforeMove', {dragItm, pathFrom, pathTo: realPathTo})) return;

            let items = _.cloneDeep(props.value);

            items = updateimmutability(items, removePath);
            items = updateimmutability(items, insertPath);

            isDirty.value = true;
            _pathTo.value = realPathTo;

            context.emit('input', items);
        }

        function tryIncreaseDepth(dragItm) {


            if (!dragItm.hasOwnProperty(props.keyProp)) {
                return
            }

            const pathFrom = getPathById(dragItm[props.keyProp]);
            const itemIndex = pathFrom[pathFrom.length - 1];
            const newDepth = pathFrom.length + getItemDepth(dragItm);

            // has previous sibling and isn't at max depth
            if (itemIndex > 0 && newDepth <= props.maxDepth) {
                const prevSibling = getItemByPath(pathFrom.slice(0, -1).concat(itemIndex - 1));

                if (prevSibling.hasOwnProperty(props.childrenProp) && (!prevSibling[props.childrenProp].length || !isCollapsed(prevSibling))) {
                    const pathTo = pathFrom
                        .slice(0, -1)
                        .concat(itemIndex - 1)
                        .concat(prevSibling[props.childrenProp].length);

                    moveItem({dragItm, pathFrom, pathTo});
                } else {
                    const pathTo = [itemIndex - 1, 0];
                    prevSibling[props.childrenProp] = [];

                    moveItem({dragItm, pathFrom, pathTo}, prevSibling);
                }
            }
        }

        function tryDecreaseDepth(dragItm) {
            if (!dragItm.hasOwnProperty(props.keyProp)) {
                return
            }
            const pathFrom = getPathById(dragItm[props.keyProp]);
            const itemIndex = pathFrom[pathFrom.length - 1];

            // has parent
            if (pathFrom.length > 1) {
                const parent = getItemByPath(pathFrom.slice(0, -1));

                // is last (by order) item in array
                if (itemIndex + 1 === parent[props.childrenProp].length) {
                    const pathTo = pathFrom.slice(0, -1);
                    pathTo[pathTo.length - 1] += 1;

                    // if collapsed by default
                    // and is last (by count) item in array
                    // remove this node from list of open nodes
                    // let collapseProps = {};
                    // if (collapsed && parent[this.childrenProp].length == 1) {
                    //   collapseProps = this.onToggleCollapse(parent, true);
                    // }

                    // this.moveItem({ dragItem, pathFrom, pathTo }, collapseProps)
                    moveItem({dragItm, pathFrom, pathTo});
                }
            }
        }

        function onMouseEnter(event, eventList, item) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }

            const dragItm = dragItem.value;

            // In some cases, this event fires after the drag operation was already
            // completed, in which case we can ignore it
            if (!dragItm) return;

            // if the event does not have a valid item that belongs to this list, ignore it
            if (item !== null && dragItm[props.keyProp] === item[props.keyProp]) return;

            // calculate the path the item is comming from
            const pathFrom = getPathById(dragItm[props.keyProp]);

            // if the event is not emitted from this list and the item was not removed from this list,
            // we can ignore this event
            if (eventList !== listId.value && pathFrom.length === 0) return;

            let pathTo;
            // if we are dragging to an empty list, we need to remove
            // the item from the origin list and append it to the start of the new list
            if (item === null) {
                pathTo = pathFrom.length > 0 ? [] : [0];
            } else {
                pathTo = getPathById(item[props.keyProp]);
            }

            // if the move to the new depth is greater than max depth,
            // don't move
            const newDepth = getRealNextPath(pathFrom, pathTo).length + (getItemDepth(dragItm) - 1);
            if (newDepth > props.maxDepth) {
                return;
            }

            // if collapsed by default
            // and move last (by count) child
            // remove parent node from list of open nodes
            let collapseProps = {}
            if (props.collapsed && pathFrom.length > 1) {
                const parent = getItemByPath(pathFrom.slice(0, -1));

                if (parent[props.childrenProp].length === 1) {
                    collapseProps = onToggleCollapse(parent, true);
                }
            }

            moveItem({dragItm, pathFrom, pathTo}, collapseProps);
        }

        function isCollapsed(item) {
            return !!((collapsedGroups.value.indexOf(item[props.keyProp]) > -1) ^ props.collapsed)
        }

        function dragApply() {
            context.emit('change', dragItem.value, {items: props.value, pathTo: _pathTo.value, oldItems: itemsOld.value})

            _pathTo.value = null
            itemsOld.value = null
            dragItem.value = null
            isDirty.value = false
        }

        function dragRevert() {
            context.emit('input', itemsOld.value)

            _pathTo.value = null
            itemsOld.value = null
            dragItem.value = null
            isDirty.value = false
        }

        function onToggleCollapse() {

        }


        function itemStripClass(index) {
            let cls = index % 2 ? '-odd' : '-even';

            return `nestable-item${cls}`;
        }


        provide('listId', listId);
        provide('group', props.group);
        provide('keyProp', props.keyProp);
        provide('onDragEnd', onDragEnd);


        return {
            slots,
            isDirty,
            listIsEmpty,
            itemOptions,
            listStyles,
            dragItem,
            parentId,
            itemKey,
            onKeyDown,
            onDragStart,
            dragApply,
            dragRevert,
            isCollapsed,
            moveItem,
            itemStripClass

        }
    }
}
</script>
