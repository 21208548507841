<template>
    <form-field :name="name" :field="field" :key="name" :validate="validate"
                :responseerrors="responseerrors"
                :form-model="formModel">
        <template v-slot:field>
            <template v-if="isMounted">
                <div class="radio-list" :class="{ disabled: fieldIsDisabled, horizontal: isHorizontal }">
                    <template v-for="(f, k) in mutatedFieldOptions">
                        <div class="field-item">
                            <template v-if="f.hasOwnProperty('level') && f.level > 0">
                                <template v-for="n in parseInt(f.level)">
                                    <div class="level"></div>
                                </template>
                            </template>
                            <div class="radio-field radio">
                                <form-radiofield
                                    :field="f"
                                    :field-value="f.value"
                                    :value="isChecked(f) || f.checked || (value !== null && value == f.value)"
                                    :name="name"
                                    :responseerrors="responseerrors"
                                    :rendersections="rendersections"
                                    :form-model="formModel"
                                    :id="fieldId(f.value, f._index)"
                                    @input="(checked) => { updateInput(checked, f) }"
                                    :validate="validate"></form-radiofield>
                            </div>
                            <div class="radio-field-label">
                                <label :for="fieldId(f.value, f._index)">{{ f.label }}</label>
                            </div>
                        </div>
                    </template>
                </div>
            </template>

        </template>

    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, onBeforeMount, ref, nextTick, inject} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "radio-list",
    props: {
        ...defaultFieldProps,
        currentValue: {}
    },
    emits: ['input'],
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const {fieldIsDisabled, allowMultiple, modelName, modelvalue} = useFormField(props, context);
        const $events = inject('$events')
        const objectToArray = inject('objectToArray')
        const isMounted = ref(false)
        const mchecked = ref(undefined);
        const multiple = ref(false)








        onMounted(() => {


            isMounted.value = true;
            // $events.$on('form-reset', () => {
            //     isMounted.value = false;
            //     nextTick(() => {
            //         isMounted.value = true;
            //     })
            // });
        })

        const mutatedFieldOptions = computed(() => {
            return mutateFieldOptions(props.field.options);
        });

        const isHorizontal = computed(() => {
            return props.field.hasOwnProperty('horizontal') && _.isBoolean(props.field.horizontal) ? props.field.horizontal : false
        });

        if (props.name.match(/\[\]$/g) || allowMultiple.value) {
            multiple.value = true;
        }


        function fieldId(value, index) {
            return app.transformKey(modelName.value).replace(/\./g, '-') + '_' + value +'_'+ index;
        }

        function mutateFieldOptions(options) {
            let opts = [];
            let index = 0;
            options = _.isObject(options) ? objectToArray(options) : options;

            _.each(options, (item, key) => {
                if (!_.isObject(item) && _.isString(item)) {
                    opts.push({
                        value: key,
                        label: item,
                        checked: isChecked({value: key}),
                        _index: index
                    });
                } else {
                    item.checked = isChecked({value: item.value});

                    if (!item.hasOwnProperty('_index')) {
                        item._index = index;
                    }

                    opts.push(item);
                }
                index++;
            });


            // if (props.value !== null) {
            //     let found = _.find(opts, {value: props.value});
            //     if (found) {
            //         found.checked = true;
            //     }
            // }


            return opts;
        }

        function isChecked(field) {


            if (multiple.value) {
                let modelValue = modelvalue.value;
                if (modelValue === null) {
                    return false;
                }

                if (_.isObject(modelValue)) {
                    modelValue = Object.keys(modelValue).map((key) => {
                        return modelValue[key]
                    })
                }

                if (_.isArray(modelValue)) {
                    return _.indexOf(modelValue, field.value) !== -1;
                }

                return false;
            }
            else {

                if (props.value !== null && props.value == field.value) {
                    if (modelvalue.value !== props.value) {
                        modelvalue.value = props.value
                    }
                }

                return modelvalue.value == field.value;
            }
        }


        function updateInput(checked, field) {
            let value = field.value;
           //  let isChecked = checked;
            modelvalue.value = value
            context.emit('input', field.value);
        }

        return {
            isMounted,
            fieldIsDisabled,
            modelvalue,
            isHorizontal,
            mutatedFieldOptions,
            updateInput,
            isChecked,
            fieldId,

        }
    }
}
</script>

<style scoped>

</style>
