<template>
    <form :class="['toolbar', {'with-description': hasSlotDescriptionToolbar}]"
          v-if="isMounted && (hasGridToolbar || isSlotToolbar || isStringToolbar || hasSlotDescriptionToolbar || hasSlotNewlineToolbar)"
          @submit.prevent="handleSubmit">

        <div class="line-toolbar" :class="{'has-after-actions': $slots.hasOwnProperty('after-actions') }">
            <button v-if="showBackButton" @click="handleBack" type="button" class="btn btn-primary back-btn">Zurück</button>
            <div class="string-toolbar" v-if="!isSlotToolbar && !hasObjectToolbar && !hasGridToolbar && isStringToolbar" v-html="toolbar"></div>
            <div class="slot-toolbar" v-if="isSlotToolbar">
                <slot name="default"/>
            </div>

            <template v-if="hasGridToolbar">
                <div v-if="!dataTableSelections.length" class="grid-filters">

                    <template v-for="f in mutatedFilters">

                        <div v-if="f.type === 'newline'" class="newline"></div>
                        <div v-else class="filter-item">
                            <template v-if="f._section && f.items">
                                <template v-for="section in f.items">
                                    <div class="filter-group">
                                        <template v-if="section.label"><span class="text-nowrap mr-5">{{ section.label }}</span></template>
                                        <div v-if="section.type == 'checkbox' || section.type == 'radio'"
                                             :class="['field-'+ section.type, 'checkbox']">
                                            <div class="checkable-wrapper">
                                                <input :id="transformKey(section.name)"
                                                       v-model="filterData[section.name]"
                                                       :name="section.name"
                                                       :type="section.type"
                                                       :value="section.value"
                                                       :checked="section.default"
                                                       @change="handleSubmit">
                                                <svg-icon name="check" v-show="filterData[section.name]"/>
                                            </div>
                                            <label v-if="section.label" :for="transformKey(section.name)" v-html="section.label"></label>
                                        </div>
                                        <div class="org-select" v-else-if="section.type == 'organisationselect'">
                                            <form-organisationselect
                                                :field="section"
                                                :name="section.name"
                                                :form-model="filterData"
                                                @input="(v) => { filterData[section.name] = v; handleSubmit() }"></form-organisationselect>
                                        </div>
                                        <div class="org-select" v-else-if="section.type == 'fileselect'">
                                            <form-fileselect
                                                :field="section"
                                                :name="section.name"
                                                :form-model="filterData"
                                                :return-only="true"
                                                @input="(v) => { filterData[section.name] = v; handleSubmit() }"></form-fileselect>
                                        </div>

                                        <div v-else-if="section.type == 'select'" class="select-wrap">
                                            <select :name="section.name"
                                                    v-model="filterData[section.name]"
                                                    @change="handleSubmit"
                                                    v-bind="section.attributes">

                                                <template v-for="(l, v) in sortFilterSelectOptions(section.options)">
                                                    <template v-if="typeof l === 'object'">
                                                        <template v-if="l.items">
                                                            <optgroup v-if="l.value === -1" :label="l.label">
                                                                <option v-for="item in l.items" :value="item.value" v-html="item.label"></option>
                                                            </optgroup>
                                                        </template>
                                                        <template v-else>
                                                            <option :value="l.value" :disabled="l.disabled === true" v-html="l.label"></option>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="v === -1">
                                                            <optgroup v-html="l"></optgroup>
                                                        </template>
                                                        <template v-else>
                                                            <option :value="v" v-html="l"></option>
                                                        </template>

                                                    </template>
                                                </template>
                                            </select>
                                            <svg-icon name="chevron-down"/>
                                        </div>
                                        <div v-else-if="section.type == 'date'">
                                            <date-picker
                                                @input="(v) => { filterData[section.name] = v ? v : null; handleSubmit() }"
                                                format="dd.MM.yyyy"
                                                :placeholder="section.placeholder"
                                                :name="section.name"
                                                :v="filterData[section.name]"
                                                :value="filterData[section.name]"></date-picker>
                                        </div>
                                        <div v-else-if="section.type == 'spinner' || section.type == 'number' ">
                                            <input :name="section.name"
                                                   v-model="filterData[section.name]"
                                                   type="number"
                                                   :placeholder="section.placeholder"
                                                   v-bind="section.attributes"
                                                   @keyup.enter="handleSubmit"
                                                   @change="handleSubmit"
                                                   :min="section.min"
                                                   :max="section.max"
                                                   class="form-control"
                                                   style="width: 120px;">
                                        </div>
                                        <div v-else-if="section.type == 'switch'">
                                            <form-switch
                                                :name="section.name"
                                                :form-model="filterData"
                                                v-bind="section.attributes"
                                                @input="handleSubmit"
                                                :onLabel="section.onlabel ? section.onlabel : 'An'"
                                                :off-label="section.offlabel ? section.offlabel : 'Aus'"
                                            ></form-switch>
                                        </div>
                                        <input v-else :name="section.name"
                                               v-model="filterData[section.name]"
                                               :type="section.type"
                                               :placeholder="section.placeholder"
                                               v-bind="section.attributes"
                                               @keyup.enter="handleSubmit"
                                               @change="handleSubmit"
                                               class="form-control">
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div v-if="f.type == 'checkbox' || f.type == 'radio'" :class="['field-'+ f.type, 'checkbox']">
                                    <div class="checkable-wrapper">
                                        <input :id="transformKey(f.name)"
                                               v-model="filterData[f.name]"
                                               :name="f.name"
                                               :type="f.type"
                                               :value="f.value"
                                               :checked="f.default"
                                               @change="handleSubmit">
                                        <svg-icon name="check" v-show="filterData[f.name]"/>
                                    </div>
                                    <label v-if="f.label" :for="transformKey(f.name)" v-html="f.label"></label>
                                </div>
                                <div v-else-if="f.type == 'select'" class="select-wrap">
                                    <select :name="f.name"
                                            v-model="filterData[f.name]"
                                            @change="handleSubmit"
                                            v-bind="f.attributes">
                                        <template v-for="(l, v) in sortFilterSelectOptions(f.options)">
                                            <template v-if="typeof l === 'object'">
                                                <template v-if="l.items">
                                                    <optgroup v-if="l.value === -1" :label="l.label">
                                                        <option v-for="item in l.items" :value="item.value" :disabled="item.disabled === true" v-html="item.label"></option>
                                                    </optgroup>
                                                </template>
                                                <template v-else>
                                                    <option :value="l.value" :disabled="l.disabled === true" v-html="l.label"></option>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <optgroup v-if="v === -1" v-html="l"></optgroup>
                                                <option v-else :value="v" v-html="l"></option>

                                            </template>
                                        </template>
                                    </select>
                                    <svg-icon name="chevron-down"/>
                                </div>
                                <div v-else-if="f.type == 'date'">
                                    <date-picker
                                        @input="(v) => { filterData[f.name] = v ? v : null;  handleSubmit() }"
                                        format="dd.MM.yyyy"
                                        :placeholder="f.placeholder"
                                        :name="f.name"
                                        :value="filterData[f.name]"></date-picker>
                                </div>
                                <div class="org-select" v-else-if="f.type == 'organisationselect'">
                                    <form-organisationselect
                                        :field="f"
                                        :name="f.name"
                                        :form-model="filterData"
                                        @input="(v) => { filterData[f.name] = v; handleSubmit() }"></form-organisationselect>
                                </div>
                                <div class="org-select" v-else-if="f.type == 'fileselect'">
                                    <form-fileselect
                                        :field="f"
                                        :return-only="true"
                                        :name="f.name"
                                        :form-model="filterData"
                                        @input="(v) => { filterData[f.name] = v; handleSubmit() }"></form-fileselect>
                                </div>
                                <div v-else-if="f.type == 'spinner' || f.type == 'number' ">
                                    <input :name="f.name"
                                           v-model="filterData[f.name]"
                                           type="number"
                                           :placeholder="f.placeholder"
                                           v-bind="f.attributes"
                                           @keyup.enter="handleSubmit"
                                           @change="handleSubmit"
                                           :min="f.min"
                                           :max="f.max"
                                           class="form-control"
                                           style="width: 120px;">
                                </div>
                                <div v-else-if="f.type == 'switch'">
                                    <form-switch
                                        :name="f.name"
                                        :form-model="filterData"
                                        v-bind="f.attributes"
                                        @input="handleSubmit"
                                        :onLabel="f.onlabel ? f.onlabel : 'An'"
                                        :off-label="f.offlabel ? f.offlabel : 'Aus'"
                                    ></form-switch>
                                </div>
                                <input v-else :name="f.name"
                                       v-model="filterData[f.name]"
                                       :type="f.type"
                                       :placeholder="f.placeholder"
                                       v-bind="f.attributes"
                                       @keyup.enter="handleSubmit"
                                       @change="handleSubmit"
                                       class="form-control">
                            </template>
                        </div>
                    </template>


                    <div class="filter-item extra-buttons" v-if="$slots.grid_extra_buttons">
                        <slot name="grid_extra_buttons"></slot>
                    </div>

                    <div class="filter-buttons">
                        <button v-if="usedFilter"
                                type="button"
                                @click.prevent="handleClearFilter"
                                class="btn btn-primary"
                                v-tooltip.left="'Filter zurücksetzen'">
                            &times;
                        </button>
                        <button type="button" @click="handleFilter" class="btn btn-primary">Filtern</button>
                    </div>


                </div>
                <div class="grid-toolbar-actions" v-if="dataTableSelections.length > 0">
                    <div class="selection-label pr-5">
                        {{ dataTableSelections.length }} Einträge ausgewahlt
                    </div>

                    <div class="select-wrap mr-5">
                        <select name="action"
                                class="nodirty"
                                ref="gridaction"
                                v-model="gridAction"
                                :disabled="dataTableSelections.length < 1">
                            <option value="">-- Aktion wählen --</option>
                            <template v-for="action in gridActions">
                                <option :value="action.value">{{ action.label }}</option>
                            </template>
                        </select>
                        <span></span>
                    </div>

                    <action-button ref="gridactionbtn"
                                   :disabled="!gridAction"
                                   :loading="isRunningGridAction"
                                   @clicked="e => { executeGridAction(e) }"
                    >Ausführen
                    </action-button>


                    <!--                    <working-button ref="gridactionbtn"-->
                    <!--                                    label="Ausführen"-->
                    <!--                                    :classes="['btn', 'btn-primary', {disabled: !gridAction}]"-->
                    <!--                                    :attributes="{'data-control': 'grid-action'}"-->
                    <!--                                    :running.sync="isRunningGridAction"-->
                    <!--                                    @execute="e => { executeGridAction(e) }"-->
                    <!--                    ></working-button>-->

                </div>
            </template>
            <template v-else>
                <template v-if="$slots['after-actions']">
                   <div class="after-actions"><slot name="after-actions"/></div>
                </template>
            </template>

        </div>

        <template v-if="hasSlotNewlineToolbar">
            <div class="line-toolbar">
                <slot name="newline"/>
            </div>
        </template>

        <template v-if="hasSlotDescriptionToolbar">
            <div class="toolbar-description">
                <slot name="description"/>
            </div>
        </template>

    </form>
</template>

<script>
import datepicker from "../date-picker";
import {
	getCurrentInstance,
	inject,
	ref,
	computed,
	watch,
	nextTick,
	onBeforeMount,
	onBeforeUnmount,
	onMounted,
	unref, toRaw
} from "vue";
import {useRouter, useRoute} from "~/vue-router";
import {storeGridFilter, getDataTableCache, setDataTableCache, getDataTablePostdata} from "./../../mixins/data-table-mixin";
import {useStore} from "~/vuex";

export default {
    name: "base-toolbar",
    components: {
        'date-picker': datepicker
    },
    props: {
        toolbar: {
            required: false,
            default() {
                return null
            }
        },

        filters: {
            type: [Array, Object],
            default() {
                return []
            }
        },

        btnBack: {
            required: false,
            type: Boolean,
            default() {
                return false
            }
        },
	    description: {
		    required: false,
		    default() {
			    return null
		    }
	    },
    },


    setup(props, context) {
        const inst = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
		const store = useStore()

        const wait = inject('wait')
        const $events = inject('$events');
        const transformKey = inject('transformKey');


        const watchChangeFilter = ref(false);
        const isMounted = ref( false);
        const mutatedFilters = ref( []);
        const prevRoute = ref( null);
        const filterData = ref( {});
        const defaultFilterData = ref( null);
        const cacheFilterData = ref( null);
        const usedFilter = ref( false);
        const dataTable = ref( null);
        const gridActions = ref( []);
        const gridAction = ref( '');
        const dataTableSelections = ref( []);


        const toolbarHeight = computed(() => {
            if (!isMounted.value || inst.vnode.el.nodeType === 8) return null;

            return inst.vnode.el.offsetHeight;
        });

        const isRunningGridAction = computed(() => {
            if (inst.refs.gridactionbtn) {
                return inst.refs.gridactionbtn.executing === true
            }
            return false
        });

        const hasFilters = computed(() => {
            if (!isMounted.value) return false;
            return !_.isEmpty(mutatedFilters.value)
        });

        const isStringToolbar = computed(() => {
            return _.isString(props.toolbar) && !_.isEmpty(props.toolbar);
        });

        const hasObjectToolbar = computed(() => {
            return _.isObject(props.toolbar) && !_.isEmpty(props.toolbar);
        });

        const isSlotToolbar = computed(() => {
            return !!context.slots.default
        });

        const hasSlotNewlineToolbar = computed(() => {
            return !!context.slots.newline
        });

        const hasSlotDescriptionToolbar = computed(() => {
            return !!context.slots.description
        });

        const hasGridToolbar = computed(() => {
            return hasFilters.value;
        });

        const showBackButton = computed(() => {
            return props.btnBack; // && this.prevRoute
        });



        watch(() => props.filters, (n, o) => {
            if (isMounted.value && watchChangeFilter.value && mutatedFilters.value !== n) {
                watchChangeFilter.value = false;

                mutatedFilters.value = _.cloneDeep(n);


                prepareFilterData(true);

                if (defaultFilterData.value === null) {
                    defaultFilterData.value = getDefaultFilterData(_.cloneDeep(n));
                }



                nextTick(() => {

                    nextTick(() => {
                        watchChangeFilter.value = true;

                        hasUsedFilter();
                    });
                });
            }
            else if (isMounted.value && !watchChangeFilter.value)
            {
                if (defaultFilterData.value === null) {
                    defaultFilterData.value = getDefaultFilterData(_.cloneDeep(n));
                }
            }
        }, {deep: true});



        watch(() => filterData.value, (n) => {
            if (isMounted.value && watchChangeFilter.value && mutatedFilters.value !== n) {
                prepareMutatedFilters();
                context.emit('changefilter', n);
            }
        }, {deep: true});


	    watch(() => props.description, (n) => {
		    try {
			    store.dispatch('ui/setToolbarDescription', n)
		    } catch (e) {
			   console.warn('Invalid UI store', e)
		    }
	    });


        onBeforeUnmount(() => {
            $events.$off('use-grid-filters', bindGridFilterEvent);
        });


        onBeforeMount(() => {
            $events.$on('use-grid-filters', bindGridFilterEvent);
            mutatedFilters.value = _.cloneDeep(props.filters);
        });


        onMounted(() => {

	        try {
		        store.dispatch('ui/setToolbarDescription', props.description || null)
	        } catch (e) {
		        console.warn('Invalid UI store', e)
	        }

            nextTick(() => {
                prepareFilterData(true);

                if (defaultFilterData.value === null) {
                    defaultFilterData.value = getDefaultFilterData(_.cloneDeep(props.filters));
                }

                isMounted.value = true;
                watchChangeFilter.value = true;

                nextTick(() => {
                    hasUsedFilter();
                });
            });
        });




        function prepareMutatedFilters() {
            let all = [];
            let fdata = _.clone(filterData.value);
            let cfilters = _.cloneDeep(mutatedFilters.value);
            _.each(cfilters, (r) => {

                let item = r;

                if (item._section && item.items) {
                    _.each(item.items, (rs) => {
                        if (rs.type === 'organisationselect') {
                            if (fdata.hasOwnProperty(rs.name) && fdata.hasOwnProperty(rs.name + '_option')) {
                                rs.value = fdata[rs.name + '_option']
                            }
                        }
                    });
                } else {
                    if (item.type === 'organisationselect') {
                        if (fdata.hasOwnProperty(item.name) && fdata.hasOwnProperty(item.name + '_option')) {
                            item.value = fdata[item.name + '_option']
                        }
                    }
                }

                all.push(item)
            });

            mutatedFilters.value = all;
        }

        function setDataTableCurrentFilter(filter) {
            filterData.value = _.cloneDeep(filter);
            nextTick(() => {
                prepareMutatedFilters();
            })
        }

        function setDataTable(a) {
            dataTable.value = a
        }

        function setDataTableSelections(n) {
            dataTableSelections.value = n;
        }


        function setDataTableActions(a) {
            let actions = [];
            if (a && _.isArray(a)) {
                _.each(a, (v) => {
                    if (_.isObject(v) && _.isObject(v.data)) {
                        v.attributes = v.data;
                        delete v.data;
                    }
                    actions.push(v);
                });
            }

            gridActions.value = actions;
        }

        function prepareDataTableActions(gr) {
            let actions = [];

            if (gr && _.isArray(gr.gridActions)) {
                _.each(gr.gridActions, (v) => {
                    if (_.isObject(v) && _.isObject(v.data)) {
                        v.attributes = v.data;
                        delete v.data;
                    }
                    actions.push(v);
                });
            }

            gridActions.value = actions;
        }

        function hasUsedFilter() {
            let str1 = JSON.stringify(Object.assign({}, defaultFilterData.value));
            let str2 = JSON.stringify(Object.assign({}, filterData.value));
            if (str1 !== str2 || !_.isEmpty(cacheFilterData.value)) {
                usedFilter.value = true;
            }

            let cache = getDataTableCache(route.path);
            if (cache && _.isObject(cache.currentFilter)) {
                setDataTableCurrentFilter(cache.currentFilter)
            }
        }

        function sortFilterSelectOptions(options) {
            let opts = _.cloneDeep(Object.assign({}, options));
            let arr = [];

            let total = 0, totalObjects = 0;
            _.each(opts, (v, k) => {
                total++;
            });

            _.each(opts, (v, k) => {
                if (!_.isObject(v)) {
                    arr.push({
                        label: v,
                        value: k
                    });
                }
                else {
                    totalObjects++
                    arr.push(v);
                }
            });

            if (Object.keys(opts).length !== arr.length) {
                return opts;
            }

            if (totalObjects === total){
                return arr;
            }


            return _.sortBy(arr, ['value'], ['asc']);
        }

        function defaultFilterValue(f) {
            if (f.hasOwnProperty('default')) {
                if (f.hasOwnProperty('min') && _.isNumber(f.default) && f.min != f.default) {
                    return f.min;
                } else {
                    return f.default;
                }
            }
            return null;
        }

        function setGridFilters(filters) {
            if (_.isObject(filters) && watchChangeFilter.value) {
                cacheFilterData.value = filters;
                hasUsedFilter();
            }
        }


        function setGrid(grid) {
            dataTable.value = grid;
        }

        function prepareFilterData(force) {
            if (_.isObject(filterData.value) && !force) return;

            if (_.isObject(cacheFilterData.value)) {
                filterData.value = Object.assign({}, cacheFilterData.value);
                // this.handleFilter();
            } else {
                // this.filterData = this.getDefaultFilterData();

                if (!_.isEmpty(mutatedFilters.value)) {
                    _.each(mutatedFilters.value, (item) => {

                        if (item._section && item.items) {
                            _.each(item.items, (it) => {
                                if (it.name) {

                                    if (it.type === 'select') {
                                        if (it.hasOwnProperty('default')) {
                                            filterData.value[it.name] = defaultFilterValue(it);
                                        } else {
                                            let opts = sortFilterSelectOptions(it.options);
                                            filterData.value[it.name] = opts[0].value;
                                        }
                                    } else if (it.type === 'organisationselect') {
                                        filterData.value[it.name] = defaultFilterValue(it);
                                    } else if (it.type === 'spinner' || it.type === 'number') {
                                        filterData.value[it.name] = defaultFilterValue(it);
                                    } else if (it.type === 'checkbox' || it.type === 'text') {
                                        filterData.value[it.name] = defaultFilterValue(it);
                                    } else if (it.type === 'date') {
                                        filterData.value[it.name] = defaultFilterValue(it);
                                    } else {
                                        filterData.value[it.name] = null;
                                    }
                                }
                            })
                        } else {
                            if (item.name) {

                                if (item.type === 'select') {
                                    if (item.hasOwnProperty('default')) {
                                        filterData.value[item.name] = defaultFilterValue(item);
                                    } else {
                                        let opts = sortFilterSelectOptions(item.options);
                                        filterData.value[item.name] = opts[0].value;
                                    }
                                } else if (item.type === 'organisationselect') {
                                    filterData.value[item.name] = defaultFilterValue(item);
                                } else if (item.type === 'spinner' || item.type === 'number') {
                                    filterData.value[item.name] = defaultFilterValue(item);
                                } else if (item.type === 'checkbox' || item.type === 'text') {
                                    filterData.value[item.name] = defaultFilterValue(item);
                                } else if (item.type === 'date') {
                                    filterData.value[item.name] = defaultFilterValue(item);
                                } else {
                                    filterData.value[item.name] = null;
                                }
                            }
                        }
                    });
                }
            }
        }

        function getDefaultFilterData(mutatedFilters) {

            if (!_.isEmpty(mutatedFilters)) {
                let defaultFilter = {};

                _.each(_.cloneDeep(mutatedFilters), (item) => {

                    if (item._section && item.items) {
                        _.each(item.items, (it) => {
                            if (it.name) {

                                if (it.type === 'select') {
                                    if (it.hasOwnProperty('default')) {
                                        defaultFilter[it.name] = defaultFilterValue(it);
                                    } else {
                                        let opts = sortFilterSelectOptions(it.options);
                                        defaultFilter[it.name] = opts[0].value;
                                    }
                                } else if (it.type === 'checkbox' || it.type === 'text') {
                                    defaultFilter[it.name] = defaultFilterValue(it);
                                } else if (it.type === 'organisationselect') {
                                    defaultFilter[it.name] = defaultFilterValue(it);
                                } else if (it.type === 'spinner' || it.type === 'number') {
                                    defaultFilter[it.name] = defaultFilterValue(it);
                                } else if (it.type === 'date') {
                                    defaultFilter[it.name] = defaultFilterValue(it);
                                } else {
                                    defaultFilter[it.name] = null;
                                }
                            }
                        })
                    } else {
                        if (item.name) {

                            if (item.type === 'select') {
                                if (item.hasOwnProperty('default')) {
                                    defaultFilter[item.name] = defaultFilterValue(item);
                                } else {
                                    let opts = sortFilterSelectOptions(item.options);
                                    defaultFilter[item.name] = opts[0].value;
                                }
                            } else if (item.type === 'organisationselect') {
                                defaultFilter[item.name] = defaultFilterValue(item);
                            } else if (item.type === 'checkbox' || item.type === 'text') {
                                defaultFilter[item.name] = defaultFilterValue(item);
                            } else if (item.type === 'spinner' || item.type === 'number') {
                                defaultFilter[item.name] = defaultFilterValue(item);
                            } else if (item.type === 'date') {
                                defaultFilter[item.name] = defaultFilterValue(item);
                            } else {
                                defaultFilter[item.name] = null;
                            }
                        }
                    }
                });

                return defaultFilter;
            }

            return null;
        }

        function updateGridFilter(newfilters) {
            let f = _.cloneDeep(mutatedFilters.value);
            let newFilters = [];
            for (let i = 0; i < f.length; i++) {

                let filter = _.find(newfilters, {name: f[i].name});

                if (!_.isEmpty(filter)) {
                    newFilters.push(Object.assign(f[i], filter));
                } else {
                    newFilters.push(f[i]);
                }
            }

            mutatedFilters.value = _.cloneDeep(newFilters);
            prepareFilterData();
        }

        function bindGridFilterEvent(f) {
            usedFilter.value = _.isEmpty(f) ? false : true;
        }

        function handleFilter() {
            if (hasGridToolbar.value) {
				let orgFilter = toRaw(defaultFilterData.value);
				let nF = toRaw(filterData.value);
				let changed = false;

				_.each(nF, (v, k) => {
					if (!orgFilter.hasOwnProperty(k) || (orgFilter.hasOwnProperty(k) && orgFilter[k] !== v) ||
						(orgFilter.hasOwnProperty(k) && orgFilter[k] == v))  {
						changed = true;
					}
				})

				if (!changed) return;

                context.emit('executefilter', filterData.value);
                usedFilter.value = true;
            }
        }

        function handleClearFilter() {
            watchChangeFilter.value = false;
            usedFilter.value = false;
            inst.vnode.el.reset();


            wait(1).then(() => {
                context.emit('clearfilter', filterData.value);
            }).then(() => {

                cacheFilterData.value = null;
                filterData.value = Object.assign({}, _.cloneDeep(defaultFilterData.value));
                watchChangeFilter.value = true;
                prepareFilterData(true);
            });
        }

        function handleSubmit() {
            if (hasGridToolbar.value) {
                handleFilter();
            } else {
                context.emit('submit');
            }

            nextTick(() => {
                context.emit('changefilter', filterData.value);
            });
        }

        function getGridFilter() {
            return filterData.value;
        }

        function handleBack() {
            router.back()
        }

        function executeGridAction(e) {
            if (!e.target.classList.contains('disabled')) {
                if (dataTable.value && gridAction.value) {
                    let found = _.find(gridActions.value, {value: gridAction.value});
                    if (found) {
                        dataTable.value.handleGridAction(inst.refs.gridactionbtn, found);
                    }
                }
            }
        }


        return {
            isMounted,
            toolbarHeight,
            mutatedFilters,
            prevRoute,
            filterData,
            defaultFilterData,
            cacheFilterData,
            usedFilter,
            dataTable,
            gridActions,
            gridAction,
            dataTableSelections,

            isRunningGridAction,
            showBackButton,
            isStringToolbar,
            hasObjectToolbar,
            hasSlotDescriptionToolbar,
            isSlotToolbar,
            hasSlotNewlineToolbar,
            transformKey,
            hasGridToolbar,




            sortFilterSelectOptions,
            setDataTableCurrentFilter,
            setDataTable,
            setDataTableSelections,
            setDataTableActions,
            hasUsedFilter,
            setGridFilters,
            setGrid,
            updateGridFilter,
            bindGridFilterEvent,
            handleFilter,
            handleClearFilter,
            handleSubmit,
            getGridFilter,
            handleBack,
            executeGridAction
        }



    },


    //
    //
    //
    // data() {
    //     return {
    //         watchChangeFilter: false,
    //         isMounted: false,
    //         mutatedFilters: [],
    //         prevRoute: null,
    //         filterData: {},
    //         defaultFilterData: null,
    //         cacheFilterData: null,
    //         usedFilter: false,
    //         dataTable: null,
    //         gridActions: [],
    //         gridAction: '',
    //         dataTableSelections: [],
    //     }
    // },
    // computed: {
    //
    //
    //     isRunningGridAction() {
    //         if (this.$refs.gridactionbtn) {
    //             return this.$refs.gridactionbtn.executing === true
    //         }
    //
    //         return false
    //     },
    //     hasFilters() {
    //
    //         if (!this.isMounted) return false;
    //
    //         return !_.isEmpty(this.mutatedFilters)
    //     },
    //     isStringToolbar() {
    //         return _.isString(this.toolbar) && !_.isEmpty(this.toolbar);
    //     },
    //
    //     hasObjectToolbar() {
    //         return _.isObject(this.toolbar) && !_.isEmpty(this.toolbar);
    //     },
    //     isSlotToolbar() {
    //         return !!this.$slots.default
    //     },
    //     hasSlotNewlineToolbar() {
    //         return !!this.$slots.newline
    //     },
    //     hasSlotDescriptionToolbar() {
    //         return !!this.$slots.description
    //     },
    //     hasGridToolbar() {
    //         return this.hasFilters;
    //     },
    //     showBackButton() {
    //         return this.btnBack; // && this.prevRoute
    //     }
    // },
    // watch: {
    //     filters(n, o) {
    //         if (this.isMounted && this.watchChangeFilter) {
    //
    //             if (this.defaultFilterData === null) {
    //                 this.defaultFilterData = this.getDefaultFilterData(_.cloneDeep(n));
    //             }
    //
    //
    //
    //             this.mutatedFilters = n;
    //             this.$nextTick(() => {
    //                 // this.defaultFilterData = this.getDefaultFilterData(_.cloneDeep(n));
    //
    //                 this.prepareFilterData(true);
    //
    //                 this.$nextTick(() => {
    //                     this.hasUsedFilter();
    //                     this.$forceUpdate();
    //                 });
    //             });
    //         }
    //         else if (this.isMounted && !this.watchChangeFilter) {
    //             if (this.defaultFilterData === null) {
    //                 this.defaultFilterData = this.getDefaultFilterData(_.cloneDeep(n));
    //             }
    //         }
    //     },
    //
    //     filterData(n) {
    //         if (this.isMounted && this.watchChangeFilter) {
    //             this.prepareMutatedFilters();
    //             this.$emit('changefilter', n);
    //         }
    //     },
    // },
    //
    //
    // beforeRouteEnter(to, from, next) {
    //     next(vm => {
    //         vm.prevRoute = from
    //     })
    // },
    //
    // beforeDestroy() {
    //     this.$events.$off('use-grid-filters', this.bindGridFilterEvent);
    // },
    //
    // beforeMount() {
    //     this.$events.$on('use-grid-filters', this.bindGridFilterEvent);
    //     this.mutatedFilters = _.cloneDeep(this.filters);
    // },
    //
    // mounted() {
    //
    //     // this.defaultFilterData = this.getDefaultFilterData(_.cloneDeep(this.filters));
    //
    //     this.$nextTick(() => {
    //         this.prepareFilterData(true);
    //
    //         this.isMounted = true;
    //         this.watchChangeFilter = true;
    //
    //         this.$nextTick(() => {
    //             this.hasUsedFilter();
    //         });
    //     });
    // },
    //
    // methods: {
    //
    //     prepareMutatedFilters() {
    //         let all = [];
    //         let fdata = _.clone(this.filterData);
    //         let filters = _.cloneDeep(this.mutatedFilters);
    //         _.each(filters, (r) => {
    //
    //             let item = r;
    //
    //             if (item._section && item.items) {
    //                 _.each(item.items, (rs) => {
    //                     if (rs.type == 'organisationselect') {
    //                         if (fdata.hasOwnProperty(rs.name) && fdata.hasOwnProperty(rs.name + '_option')) {
    //                             rs.value = fdata[rs.name + '_option']
    //                         }
    //                     }
    //                 });
    //             } else {
    //                 if (item.type == 'organisationselect') {
    //                     if (fdata.hasOwnProperty(item.name) && fdata.hasOwnProperty(item.name + '_option')) {
    //                         item.value = fdata[item.name + '_option']
    //                     }
    //                 }
    //             }
    //
    //             all.push(item)
    //         });
    //
    //         this.mutatedFilters = all;
    //     },
    //
    //     setDataTableCurrentFilter(filter) {
    //         this.filterData = _.cloneDeep(filter);
    //         this.$nextTick(() => {
    //             this.prepareMutatedFilters();
    //         })
    //     },
    //
    //     setDataTable(a) {
    //         this.dataTable = a
    //     },
    //
    //     setDataTableSelections(n) {
    //         this.dataTableSelections = n;
    //     },
    //
    //
    //     setDataTableActions(a) {
    //         let actions = [];
    //         if (a && _.isArray(a)) {
    //             _.each(a, (v) => {
    //                 if (_.isObject(v) && _.isObject(v.data)) {
    //                     v.attributes = v.data;
    //                     delete v.data;
    //                 }
    //                 actions.push(v);
    //             });
    //         }
    //
    //         this.gridActions = actions;
    //     },
    //
    //     prepareDataTableActions(grid) {
    //         let actions = [];
    //
    //         if (grid && _.isArray(grid.gridActions)) {
    //             _.each(grid.gridActions, (v) => {
    //                 if (_.isObject(v) && _.isObject(v.data)) {
    //                     v.attributes = v.data;
    //                     delete v.data;
    //                 }
    //                 actions.push(v);
    //             });
    //         }
    //
    //         this.gridActions = actions;
    //     },
    //
    //     hasUsedFilter() {
    //         let str1 = JSON.stringify(Object.assign({}, this.defaultFilterData));
    //         let str2 = JSON.stringify(Object.assign({}, this.filterData));
    //         if (str1 != str2 || !_.isEmpty(this.cacheFilterData)) {
    //             this.usedFilter = true;
    //         }
    //
    //
    //         let cache = this.getDataTableCache(this.$route.path);
    //         if (cache && _.isObject(cache.currentFilter)) {
    //             this.setDataTableCurrentFilter(cache.currentFilter)
    //         }
    //
    //
    //     },
    //
    //     sortFilterSelectOptions(options) {
    //         let opts = _.cloneDeep(Object.assign({}, options));
    //         let arr = [];
    //         _.each(opts, (v, k) => {
    //             if (!_.isObject(v)) {
    //                 arr.push({
    //                     label: v,
    //                     value: k
    //                 });
    //             }
    //             else {
    //                 arr.push(v);
    //             }
    //         });
    //
    //         if (Object.keys(opts).length !== arr.length) {
    //             return opts;
    //         }
    //
    //         return _.sortBy(arr, ['value'], ['asc']);
    //     },
    //
    //     defaultFilterValue(filter) {
    //         if (filter.hasOwnProperty('default')) {
    //             if (filter.hasOwnProperty('min') && _.isNumber(filter.default) && filter.min != filter.default) {
    //                 return filter.min;
    //             } else {
    //                 return filter.default;
    //             }
    //         }
    //
    //         // if (filter.hasOwnProperty('min')) {
    //         //     return filter.min;
    //         // }
    //
    //         return null;
    //     },
    //
    //     setGridFilters(filters) {
    //         if (_.isObject(filters) && this.watchChangeFilter) {
    //             this.cacheFilterData = filters;
    //             this.hasUsedFilter();
    //         }
    //     },
    //
    //
    //     setGrid(grid) {
    //         this.dataTable = grid;
    //     },
    //
    //
    //     prepareFilterData(force) {
    //         if (_.isObject(this.filterData) && !force) return;
    //
    //         if (_.isObject(this.cacheFilterData)) {
    //             this.filterData = Object.assign({}, this.cacheFilterData);
    //             // this.handleFilter();
    //         } else {
    //             // this.filterData = this.getDefaultFilterData();
    //
    //             if (!_.isEmpty(this.mutatedFilters)) {
    //                 _.each(this.mutatedFilters, (item) => {
    //
    //                     if (item._section && item.items) {
    //                         _.each(item.items, (it) => {
    //                             if (it.name) {
    //
    //                                 if (it.type === 'select') {
    //                                     if (it.hasOwnProperty('default')) {
    //                                         this.filterData[it.name] = this.defaultFilterValue(it);
    //                                     } else {
    //                                         let opts = this.sortFilterSelectOptions(it.options);
    //                                         this.filterData[it.name] = opts[0].value;
    //                                     }
    //                                 } else if (it.type === 'organisationselect') {
    //                                     this.filterData[it.name] = this.defaultFilterValue(it);
    //                                 } else if (it.type === 'spinner' || it.type === 'number') {
    //                                     this.filterData[it.name] = this.defaultFilterValue(it);
    //                                 } else if (it.type === 'checkbox' || it.type === 'text') {
    //                                     this.filterData[it.name] = this.defaultFilterValue(it);
    //                                 } else if (it.type === 'date') {
    //                                     this.filterData[it.name] = this.defaultFilterValue(it);
    //                                 } else {
    //                                     this.filterData[it.name] = null;
    //                                 }
    //                             }
    //                         })
    //                     } else {
    //                         if (item.name) {
    //
    //                             if (item.type === 'select') {
    //                                 if (item.hasOwnProperty('default')) {
    //                                     this.filterData[item.name] = this.defaultFilterValue(item);
    //                                 } else {
    //                                     let opts = this.sortFilterSelectOptions(item.options);
    //                                     this.filterData[item.name] = opts[0].value;
    //                                 }
    //                             } else if (item.type === 'organisationselect') {
    //                                 this.filterData[item.name] = this.defaultFilterValue(item);
    //                             } else if (item.type === 'spinner' || item.type === 'number') {
    //                                 this.filterData[item.name] = this.defaultFilterValue(item);
    //                             } else if (item.type === 'checkbox' || item.type === 'text') {
    //                                 this.filterData[item.name] = this.defaultFilterValue(item);
    //                             } else if (item.type === 'date') {
    //                                 this.filterData[item.name] = this.defaultFilterValue(item);
    //                             } else {
    //                                 this.filterData[item.name] = null;
    //                             }
    //                         }
    //                     }
    //                 });
    //             }
    //         }
    //     },
    //
    //
    //     getDefaultFilterData(mutatedFilters) {
    //         let defaultFilter = {};
    //         if (!_.isEmpty(mutatedFilters)) {
    //             _.each(_.cloneDeep(mutatedFilters), (item) => {
    //
    //                 if (item._section && item.items) {
    //                     _.each(item.items, (it) => {
    //                         if (it.name) {
    //
    //                             if (it.type === 'select') {
    //                                 if (it.hasOwnProperty('default')) {
    //                                     defaultFilter[it.name] = this.defaultFilterValue(it);
    //                                 } else {
    //                                     let opts = this.sortFilterSelectOptions(it.options);
    //                                     defaultFilter[it.name] = opts[0].value;
    //                                 }
    //                             } else if (it.type === 'checkbox' || it.type === 'text') {
    //                                 defaultFilter[it.name] = this.defaultFilterValue(it);
    //                             } else if (it.type === 'organisationselect') {
    //                                 defaultFilter[it.name] = this.defaultFilterValue(it);
    //                             } else if (it.type === 'spinner' || it.type === 'number') {
    //                                 defaultFilter[it.name] = this.defaultFilterValue(it);
    //                             } else if (it.type === 'date') {
    //                                 defaultFilter[it.name] = this.defaultFilterValue(it);
    //                             } else {
    //                                 defaultFilter[it.name] = null;
    //                             }
    //                         }
    //                     })
    //                 } else {
    //                     if (item.name) {
    //
    //                         if (item.type === 'select') {
    //                             if (item.hasOwnProperty('default')) {
    //                                 defaultFilter[item.name] = this.defaultFilterValue(item);
    //                             } else {
    //                                 let opts = this.sortFilterSelectOptions(item.options);
    //                                 defaultFilter[item.name] = opts[0].value;
    //                             }
    //                         } else if (item.type === 'organisationselect') {
    //                             defaultFilter[item.name] = this.defaultFilterValue(item);
    //                         } else if (item.type === 'checkbox' || item.type === 'text') {
    //                             defaultFilter[item.name] = this.defaultFilterValue(item);
    //                         } else if (item.type === 'spinner' || item.type === 'number') {
    //                             defaultFilter[item.name] = this.defaultFilterValue(item);
    //                         } else if (item.type === 'date') {
    //                             defaultFilter[item.name] = this.defaultFilterValue(item);
    //                         } else {
    //                             defaultFilter[item.name] = null;
    //                         }
    //                     }
    //                 }
    //             });
    //         }
    //         return defaultFilter;
    //     },
    //
    //
    //     updateGridFilter(newfilters) {
    //         let f = _.cloneDeep(this.mutatedFilters);
    //         let newFilters = [];
    //         for (let i = 0; i < f.length; i++) {
    //
    //             let filter = _.find(newfilters, {name: f[i].name});
    //
    //             if (!_.isEmpty(filter)) {
    //                 newFilters.push(Object.assign(f[i], filter));
    //             } else {
    //                 newFilters.push(f[i]);
    //             }
    //         }
    //
    //         this.mutatedFilters = _.cloneDeep(newFilters);
    //         this.prepareFilterData();
    //
    //         // this.defaultFilterData = this.getDefaultFilterData(_.cloneDeep(this.mutatedFilters));
    //     },
    //
    //     bindGridFilterEvent(filters) {
    //         this.usedFilter = _.isEmpty(filters) ? false : true;
    //     },
    //
    //     handleFilter() {
    //         if (this.hasGridToolbar) {
    //             this.$emit('executefilter', this.filterData);
    //             this.usedFilter = true;
    //         }
    //     },
    //
    //     handleClearFilter() {
    //         this.watchChangeFilter = false;
    //         this.usedFilter = false;
    //         this.$el.reset();
    //
    //
    //         this.wait(1).then(() => {
    //             this.$emit('clearfilter', this.filterData);
    //         }).then(() => {
    //
    //             this.cacheFilterData = null;
    //             this.filterData = Object.assign({}, _.cloneDeep(this.defaultFilterData));
    //             this.watchChangeFilter = true;
    //             this.prepareFilterData(true);
    //             this.$forceUpdate();
    //         });
    //     },
    //
    //     handleSubmit() {
    //         if (this.hasGridToolbar) {
    //             this.handleFilter();
    //         } else {
    //             this.$emit('submit');
    //         }
    //
    //         this.$nextTick(() => {
    //             this.$emit('changefilter', this.filterData);
    //         });
    //     },
    //
    //     getGridFilter() {
    //         return this.filterData;
    //     },
    //
    //     handleBack() {
    //         this.$router.back()
    //     },
    //
    //
    //     executeGridAction(e) {
    //         if (!e.target.classList.contains('disabled')) {
    //             if (this.dataTable && this.gridAction) {
    //
    //                 let found = _.find(this.gridActions, {value: this.gridAction});
    //
    //                 if (found) {
    //                     this.dataTable.handleGridAction(this.$refs.gridactionbtn, found);
    //                 }
    //
    //
    //             }
    //         }
    //     }
    // }
}
</script>

<style scoped>

</style>
