import dashboardIndex from './components/Index.vue';


export default [
    {
        path: '/',
        component: dashboardIndex,
        name: 'admin.dashboard',
        meta: {
            title: '',
            nocache: true,
            grid: false
        }
    },
    {
        path: '/search',
        component: require('./components/Search.vue').default,
        name: 'admin.search',
        meta: {
            title: 'Suche',
            nocache: false,
            grid: false
        }
    },

];
