import {nextTick, provide, getCurrentInstance} from "vue";


const EventHub = require("./utils/eventHub").default;
import store from './store';
const router = require('./router').default;

function NelohConfig(lara) {
	this.inst = lara;
}

NelohConfig.prototype = {
	store: null,
	inst: null,
	setStore() {
		this.store = store;
	},

	all() {
		return this.store.state.config.config;
	},

	get(key, fallback) {
		return data_get(this.all(), key, fallback);
	},

	set(key, value) {
		this.store.dispatch('configValue', {key, value});
	},
}



export default class NelohClass {
	constructor(app, store) {
		// this.$store = null;
		this.$router = router;
		this.$http = null;
		this.$app = null;
		this.$store = store;

		this.$events = EventHub;
		this.$on = EventHub.$on;
		this.$off = EventHub.$off;
		this.$emit = EventHub.$emit;

		this.$vueapp = app;

		this.setStore(store);

		this.$vueapp.$events = this.$events
		this.$vueapp.$nextTick = nextTick
        this.$vueapp.$router = router

		this.setPrototype('$nextTick', nextTick);
		this.setPrototype('$events', this.$events);
        this.setPrototype('$router', router);

        this.provide('$router', router);
		this.provide('$events', this.$events);
		this.provide('$nextTick', nextTick);
	}


	setHttp(http) {
		this.$http = http;

		if (this.$vueapp) {
			this.setPrototype('$http', this.$http);
			this.provide('$http', this.$http);
		}

		return this;
	}

	setStore(store) {
		this.$store = store;

		this.$vueapp.use(this.$store);
		this.setPrototype('$store', store);
		this.provide('$store', store);
		return this;
	}

	setRouter(router) {
		this.$router = router;
		this.$vueapp.use(this.$router);
		this.$vueapp.provide('$router', router);
		return this;
	}


	setPrototype(name, value) {

		if (this.$vueapp.config && !this.$vueapp.config.globalProperties.hasOwnProperty(name)) {
			this.$vueapp.config.globalProperties[name] = value;
			// this.provide(name, this.$vueapp.config.globalProperties[name]);
		}
		else if (this.$vueapp.config && this.$vueapp.config.globalProperties.hasOwnProperty(name)) {
			delete this.$vueapp.config.globalProperties[name];
			this.$vueapp.config.globalProperties[name] = value;
		}

		return this;
	}

	hasPrototype(name) {
		return this.$vueapp && this.$vueapp.config.globalProperties.hasOwnProperty(name);
	}

	getPrototype(name) {
		return this.$vueapp.config.globalProperties[name];
	}

	getPropertyPath(obj, name, defaultValue = null) {
		return this.$vueapp.config.globalProperties.getPropertyPath(obj, name, defaultValue);
	}

	setPropertyPath(obj, propertyPath, value, createIfNotExists = false) {
		this.$vueapp.config.globalProperties.setPropertyPath(obj, propertyPath, value, createIfNotExists);
	}

	hasOwnPropertyPath(obj, name) {
		return this.$vueapp.config.globalProperties.hasOwnPropertyPath(obj, name);
	}


	use(options, props1= {}) {
		try {
			this.$vueapp.use(options, props1);
		} catch (e ) {
			console.warn(e, options);
		}
		return this;
	}

	hasProvide(name, value) {
		return this.$vueapp && this.$vueapp._context.provides[name] === value;
	}

	replaceProvide(name, value) {
		if (this.hasProvide(name, value)) {
			delete this.$vueapp._context.provides[name];
		}

		this.provide(name, value);
	}

	provide(name, value) {

		if (typeof this.$vueapp._context.provides[name] !== "undefined") {
			delete this.$vueapp._context.provides[name];
		}

		if (!this.hasProvide(name, value)) {
			this.$vueapp.provide(name, value);
		}
	}

	mixin(options) {
		this.$vueapp.mixin(options);

		if (options.hasOwnProperty('methods') && _.isObject(options.methods)) {
			_.each(options.methods, (call, name) => {
				this.setPrototype(name, call);
				this.replaceProvide(name, call);
			});
		}

		return this;
	}



	component(name, component) {

		if (this.$vueapp._context && this.$vueapp._context.components.hasOwnProperty(name)) {
			return this;
		}


		if (this.$vueapp._context && !this.$vueapp._context.components.hasOwnProperty(name)) {
			this.$vueapp.component(name, component);
		}
		return this;
	}

	hasComponent(name) {
		return this.$vueapp && this.$vueapp._context && this.$vueapp._context.components.hasOwnProperty(name);
	}



	hasDirective(name) {
		return this.$vueapp._context ? this.$vueapp._context.directives.hasOwnProperty(name) : null;
	}

	directive() {
		if (!this.hasDirective(arguments[0])) {
			this.$vueapp.directive(...arguments);
		}
		return this;
	}

	vueInstance() {
		return this.$vueapp;
	}

	root() {
		return this.vueInstance()._context.app
	}

	globalProperties() {
		return this.vueInstance().config.globalProperties
	}

	$set(object, path, value) {
		return this.setPropertyPath(object, path, value, true);
	}


	async start(elementId= '#app') {



		this.$vueapp.use(router);

		if (!router.hasOwnProperty('app')) {
			router.app = this.$vueapp;
		}

		// this.setRouter(router);

		this.$app = this.$vueapp.mount(elementId);
	}

	async mount(elementId = '#app') {
		// this.setRouter(router);
		this.$app = this.$vueapp.mount(elementId);
	}
}
