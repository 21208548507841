const instances = new Map();
function attachedRoot (node) {
    /* istanbul ignore next */
    if (typeof node.getRootNode !== 'function') {
        // Shadow DOM not supported (IE11), lets find the root of this node
        while (node.parentNode) node = node.parentNode

        // The root parent is the document if the node is attached to the DOM
        if (node !== document) return null

        return document
    }

    const root = node.getRootNode()

    // The composed root node is the document if the node is attached to the DOM
    if (root !== document && root.getRootNode({ composed: true }) !== document) return null

    return root
}
function defaultConditional () {
    return true
}
function checkIsActive (e, binding) {
    const isActive = (typeof binding === 'object' && binding.closeConditional) || defaultConditional;
    return isActive(e);
}

function handleShadow (el, callback) {
    const root = attachedRoot(el);

    callback(document);

    if (typeof ShadowRoot !== 'undefined' && root instanceof ShadowRoot) {
        callback(root);
    }
}
/**
 *
 * @param {PointerEvent} event
 * @param {HTMLElement} el
 * @param handler
 * @returns {null|*}
 * @private
 */
function _clickOutsideEvent({ event, el, handler }) {


    // The include element callbacks below can be expensive
    // so we should avoid calling them when we're not active.
    // Explicitly check for false to allow fallback compatibility
    // with non-toggleable components
    if (!event || checkIsActive(event, handler) === false) return false


    // If we're clicking inside the shadowroot, then the app root doesn't get the same
    // level of introspection as to _what_ we're clicking. We want to check to see if
    // our target is the shadowroot parent container, and if it is, ignore.
    const root = attachedRoot(el)
    if (
        typeof ShadowRoot !== 'undefined' &&
        root instanceof ShadowRoot &&
        root.host === event.target
    ) return false



    // Check if additional elements were passed to be included in check
    // (click must be outside all included elements, if any)
    const elements = ((typeof handler === 'object' && handler.include) || (() => []))();
    // Add the root element for the component this directive was defined on
    elements.push(el);

    // Check if it's a click outside our elements, and then if our callback returns true.
    // Non-toggleable components should take action in their callback and return falsy.
    // Toggleable can return true if it wants to deactivate.
    // Note that, because we're in the capture phase, this callback will occur before
    // the bubbling click event on any outside elements.
    const out = !elements.some(el => el.contains(event.target));
    return out;

    const isClickOutside =
        event.target !== el
        && !el.contains(event.target);

    if (!isClickOutside) {
        return event;
    }

    return handler(event, el);
}

//Validator function
function processArgs(value) {
    const isFunction = typeof value === 'function';

    if (!isFunction && typeof value !== 'object') {
        throw new Error(`v-click-outside: Binding value should be a function or an object, ${typeof bindingValue} given`)
    }

    return {
        handler: isFunction ? value : value.handler,
    };
}

//Now need adapter to handle several events for one Map element
/**
 *
 * @param events
 * @param el
 * @param handler
 * @returns {*}
 */
function eventAdapter(events, { el, handler }) {
    return events.map((eventName) => ({
        event: eventName,
        handler: (event) => _clickOutsideEvent({ event, el, handler })
    }));
}

//Requires loop to toggle events for several listeners of an element
function toggleEventListeners(eventHandlers) {
    return (action) => {
        eventHandlers.forEach(({ event, handler }) => {
            document[`${action}EventListener`](event, handler, true);
        });
    };
}


const outside_events = ['click'];

Neloh.directive('click-outside', {
    mounted(el, binding, vNode) {
        el.clickOutsideEvent = function(event) {

            let exclude, handler = binding.value;
            let clickedOnExcludedEl = false;

            if (typeof binding.value === "object") {
                if (binding.value.hasOwnProperty('handler')) {
                    handler = binding.value.handler;
                }

                if (binding.value.hasOwnProperty('exclude')) {
                    exclude = binding.value.exclude;
                }

                if (exclude && _.isArray(exclude)) {
                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            let excludeEl = vNode.el.querySelector(refName);
                            if (excludeEl) {
                                clickedOnExcludedEl = excludeEl.contains(event.target);
                            }
                        }
                    });
                }
            }

            if ( event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && event.target.tagName !== 'SELECT' && event.target.tagName !== 'LABEL' && !el.contains(event.target) && !clickedOnExcludedEl ) {
                handler(event, el);
            }
        };

        document.addEventListener('click', el.clickOutsideEvent, false);
    },


    beforeUnmount(el) {
        // Remove Event Listeners
        if (el.clickOutsideEvent) document.removeEventListener('click', el.clickOutsideEvent);
        el.clickOutsideEvent = null;
    },

    beforeMount(el, binding, vNode) {

    },

    unmounted(el, binding, vNode) {

    },

    stopProp(event) {
        event.stopPropagation();
    }
})
Neloh.directive('clickaway', {
    mounted(el, binding, vNode) {
        el.clickOutsideEvent = function(event) {

            let exclude, handler = binding.value;
            let clickedOnExcludedEl = false;

            if (typeof binding.value === "object") {
                if (binding.value.hasOwnProperty('handler')) {
                    handler = binding.value.handler;
                }

                if (binding.value.hasOwnProperty('exclude')) {
                    exclude = binding.value.exclude;
                }

                if (exclude && _.isArray(exclude)) {
                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            let excludeEl = vNode.el.querySelector(refName);
                            if (excludeEl) {
                                clickedOnExcludedEl = excludeEl.contains(event.target);
                            }
                        }
                    });
                }
            }

            if ( event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && event.target.tagName !== 'SELECT' && event.target.tagName !== 'LABEL' && !el.contains(event.target) && !clickedOnExcludedEl ) {
                handler(event, el);
            }
        };

        document.addEventListener('click', el.clickOutsideEvent, false);
    },


    beforeUnmount(el) {
        // Remove Event Listeners
        if (el.clickOutsideEvent) document.removeEventListener('click', el.clickOutsideEvent);
        el.clickOutsideEvent = null;
    },

    beforeMount(el, binding, vNode) {

    },

    unmounted(el, binding, vNode) {

    },

    stopProp(event) {
        event.stopPropagation();
    }
})
