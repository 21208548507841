export function parseDate(value) {
    if (value) {
        if (value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/g)) {
            let segments = value.split('.');

            let d = segments[0].trim();
            let m = segments[1].trim();
            let y = segments[2].trim();

            return new Date(y +'-'+ m +'-'+ d);
        }
        else if (value.match(/^\d{1,2}\.\d{1,2}\.\d{4}([,\s]?).*$/g)) {
            let segments = value.split('.');

            let d = segments[0].trim();
            let m = segments[1].trim();
            let y = segments[2].trim();

            return new Date(y +'-'+ m +'-'+ d);
        }
    }

    return null;
}
