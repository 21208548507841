<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, toRaw, defineComponent, getCurrentInstance, inject, nextTick, ref, onMounted} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";
import {parents} from "@/utils/dom";

export default defineComponent({
    name: "RequestsSubjectsIndex",

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx

        const route = useRoute();
        const router = useRouter()
        const store = useStore();
        const changeBreadcrumb = inject('changeBreadcrumb')
        const requestAction = inject('requestAction')
        const setPropertyPath = inject('setPropertyPath');
        const $http = inject('$http');
        const $events = inject('$events');

        const loadItem = ref(null);
        const height = ref(null)


        const treeData = ref([]);


		const showDetails = ref(false);
		const detailsForRequestId = ref(null);



        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
            return true
            // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
        })



        loadData();


        onMounted(() => {
            nextTick(() => {
                updateHeight()
            })
        })


        function updateHeight() {
            if (!inst.refs.toolbar || inst.refs.toolbar && !inst.refs.toolbar.toolbarHeight) {
                height.value = null;
            } else {
                height.value = (inst.vnode.el.offsetHeight - inst.refs.toolbar.toolbarHeight) - 45 + 'px';
            }
        }

        function loadData() {
            $http.post($root.currentRouteUrl(route.path)).then((r) => {

                treeData.value = r.data.tree;

                changeBreadcrumb(r.data)

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {
            changeBreadcrumb(data)
        }

        function handleCreate() {
            router.push({name: 'admin.system.subjects.create'})
        }

        function handleDelete() {

        }


        function editEntry(row) {


            router.push({name: 'admin.system.subjects.edit', params: {id: row.id}})


        }


        function updateTreeItem(item, mutateditems, o) {

            const old = toRaw(treeData.value);
            const mutateditemsRaw = toRaw(mutateditems);

            if (mutateditemsRaw === old) {
                return;
            }

            loadItem.value = toRaw(item);

            let tree = inst.refs.tree.$el;
            let el = tree.querySelector('[data-id="' + item.id + '"]');
            let org = _.cloneDeep(toRaw(item));

            if (el) {
                // item.loading = true;

                let parentNodes = parents(el, 'li');
                parentNodes.shift(); // remove self

                let p = parentNodes.shift(); // the first parent
                let cls = null;
                if (p) {
                    cls = p.className.replace(/.*nestable-item-?([\d]+)\s*.*/g, '$1');
                    cls = cls.trim();
                }

                if (cls && cls.match(/^\d+?$/g)) {
                    org.parent_id = parseInt(cls)
                } else {
                    org.parent_id = 0
                }

                $http.post($root.routes('admin.system.subjects.update-tree'), {
                        item: org,
                        id: org.id,
                        parent_id: org.parent_id,
                        items: mutateditemsRaw
                    })
                    .then((r) => {
                        treeData.value = mutateditemsRaw;
                        loadItem.value = null;
                    }).catch((e) => {
                    // item.loading = false;
                    loadItem.value = null;
                });


                return;
            }

            console.warn('Could not update updateTreeItem()');
        }





        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,
	        showDetails,
	        detailsForRequestId,
            treeData,
            height,
            loadItem,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreate,
            handleDelete,
            editEntry,
            updateTreeItem,
        }
    }
})
</script>

<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">
        <button class="btn btn-primary"
                @click="handleCreate"
                type="button"
                v-if="canCreateUser">
            <svg-icon name="plus"/>
            Thema anlegen
        </button>
    </toolbar>

    <div class="inner-wrapper padding-0">

        <tree-view ref="tree"
                   :load="loadItem"
                   :tree-data="treeData"
                   key-name="id"
                   child-key-name="children"
                   parent-key-name="parent_id"
                   :height="height"
                   :max-deep="20"
                   @input="(item, items, a) => {  updateTreeItem(item, items, a) }">

            <template v-slot:placeholder>
                <div class="p-15">Keine Themen vorhanden</div>
            </template>

            <template v-slot:header>
                <table class="table">
                    <thead>
                    <tr>
                        <th>Titel</th>
                    </tr>
                    </thead>
                </table>
            </template>

            <template v-slot:content="{ row, index, isChild }">
                <table class="table"
                       :data-id="row.id || 0">

                    <tr :key="'item-'+ (row.id || 0)">
                        <td @click="editEntry(row)">
                            <div class="label-column">
                                <div class="id">
                                    {{ row.id }}
                                </div>
                                <div class="l" @click.prevent="editEntry(row)">
                                    <div>{{ row.title }}</div>
                                    <div class="nav_label">{{ row.nav_label }}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </template>

        </tree-view>
    </div>

<!--    <data-table v-if="grid" ref="gridata"-->
<!--                :field-components="fieldComponents"-->
<!--                :base-data="grid"-->
<!--                :toolbar="$refs.toolbar"-->
<!--                @selected-count="(v) => { selectedTrashCount = v }"-->
<!--                @delete="handleDelete"-->
<!--                @loaded="onLoaded">-->

<!--        <template v-slot:title="{row}">-->
<!--            <template v-if="row.title">-->
<!--                <div v-if="row.title">{{ row.title }}</div>-->
<!--                <div v-if="row.description" class="text-small">{{ row.description }}</div>-->
<!--            </template>-->
<!--        </template>-->


<!--        <template v-slot:created_by="{row}">-->
<!--            <template v-if="row.createdby">-->
<!--                <div>{{ row.createdby.username }}</div>-->
<!--                <div class="text-small">-->
<!--                    {{ row.createdby.first_name }} {{ row.createdby.surname }}-->
<!--                </div>-->
<!--            </template>-->
<!--        </template>-->


<!--    </data-table>-->

</template>

<style scoped>

</style>
