<template>
    <div class="form-tabs">
        <ul class="nav-tabs">
            <template v-for="(field, n) in mutatedTabs">
                <li
                    v-if="hasFields && field.type === 'tab'"
                    :class="['nav-item', {
                	active: currentTab === n,
                	hidden: hideShowWhen(field),
                	disabled: disableWhen(field)
                }]"
                    @click.prevent="toggleTab(n, field)"
                    :id="field.id ? 'tab_'+field.id : null"
                    :ref="field.id ? 'tab_'+ field.id : 'tab_'+ n"
                    :data-index="n"
                    v-html="renderTabLabel(field)">
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: "modal-form-tabs",
    props: {
        name: {
            required: false,
        },
        fields: {
            required: true,
            type: Object
        },
        validate: {
            type: Boolean,
            default: false
        },
        rendersections: {
            required: false,
            default() {
                return {}
            }
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        }
    },

    data() {
        return {
            currentTab: 0,
            mutatedTabs: [],
        }
    },

    watch: {
        fields(n, o) {
            if (n !== o && n) {
                if (n.fields && n.fields.fields) {
                    this.prepareTabs();
                }
                else {
                    this.mutatedTabs = []
                }
            }
        },
    },
    computed: {
        hasFormModel() {
            return _.isObject(this.formModel);
        },
        hasFields() {
            return !_.isEmpty(this.fields.fields);
        },
    },

    beforeMount() {

        if (this.hasFields) {
            this.prepareTabs( _.cloneDeep(this.fields.fields));
        }


    },

    methods: {
        getTabs() {
            return _.filter(this.mutatedTabs, (r) => {
                return r.type === 'tab';
            })
        },
        prepareTabs(fields) {

            this.mutatedTabs = fields;


            let tabs = this.getTabs();
            _.each(tabs, (tab, idx) => {
                if (idx !== this.currentTab) {
                    tab.active = false;
                }
                else {
                    tab.active = true;
                }
            });

            if (tabs.length) {
                if (this.currentTab >= 0 && tabs[this.currentTab]){
                    this.$emit('tab-shown', tabs[this.currentTab])
                }
            }
        },








        disableWhen(tab) {
            let disable = false;

            if (this.hasDisableWhen(tab)) {
                disable = this.getDisableWhen(tab) === true;
            }

            return disable;
        },

        hasDisableWhen(tab) {
            return tab && tab.hasOwnProperty('disable_when');
        },
        getDisableWhen(tab) {

            if (tab) {
                if (_.isArray(tab.disable_when) || _.isObject(tab.disable_when)) {
                    let valid = 0;
                    let mustValidCount = _.size(tab.disable_when);
                    return this.getCondition(valid, tab.disable_when) === mustValidCount;
                }
                else if (_.isString(tab.disable_when) && tab.disable_when) {
                    return this.getStringCondition(tab.disable_when);
                }
            }



            return false
        },












        hideShowWhen(tab) {
            let hide = false;
            if (this.hasShowWhen(tab)) {
                hide = this.getShowWhen(tab) !== true;
            }
            return hide;
        },

        hasShowWhen(tab) {
            return tab.hasOwnProperty('show_when');
        },
        getShowWhen(tab) {

            if (_.isArray(tab.show_when)) {
                let valid = 0;
                let mustValidCount = _.size(tab.show_when);
                return this.getCondition(valid, tab.show_when) === mustValidCount;
            }

            else if (_.isString(tab.show_when) && tab.show_when) {
                return this.getStringCondition(tab.show_when);
            }

            return this.formModel.hasOwnProperty(tab.show_when) ? this.formModel[tab.show_when] : null;
        },









        getStringCondition(when) {
            if (_.isString(when) && when.match(/([\|,]+?)/g)) {
                let conditions = when.split('|');
                if (conditions.length > 1) {
                    // fieldname (0)


                    let hasShowValue;
                    let showValue;


                    hasShowValue = this.hasOwnPropertyPath(this.formModel, conditions[0]);
                    showValue = this.getPropertyPath(this.formModel, conditions[0]);

                    if (hasShowValue) {

                        if (conditions.length === 3) {
                            let operator = conditions[1];
                            let value = conditions[2];
                            if (value.match(/,/g)) {
                                value = value.split(',');
                            }
                            else if (value && value.match(/^true$/ig) ) {
                                value = true;
                            }
                            else if (value && value.match(/^false$/ig) ) {
                                value = false;
                            }


                            if (_.isArray(value)) {
                                switch (operator) {
                                    case 'empty':
                                        return _.isUndefined(showValue) || _.isEmpty(showValue);

                                    case 'notempty':
                                        return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                    case 'in':
                                    case 'exists':
                                        if (_.isArray(showValue) || _.isObject(showValue)) {
                                            let found = false;
                                            _.each(showValue, (v) => {
                                                if (!found) {
                                                    _.each(value, (item) => {
                                                        if (item == v) {
                                                            found = true;
                                                        }
                                                    });
                                                }

                                            });

                                            return found ? showValue : null;
                                        }
                                        else {
                                            let found = false;
                                            _.each(value, (item) => {
                                                if (!found) {
                                                    if (item == showValue) {
                                                        found = true;
                                                    }
                                                }
                                            });

                                            return (found ? showValue : null);
                                        }

                                    case 'notin':
                                    case 'notexists':

                                        if (_.isArray(showValue) || _.isObject(showValue)) {
                                            let found = false;
                                            _.each(showValue, (v) => {
                                                if (!found) {
                                                    _.each(value, (item) => {
                                                        if (item == v) {
                                                            found = true;
                                                        }
                                                    });
                                                }

                                            });

                                            return !found ? showValue : null;
                                        }
                                        else {
                                            let found = false;
                                            _.each(value, (item) => {
                                                if (!found) {
                                                    if (item == showValue) {
                                                        found = true;
                                                    }
                                                }
                                            });

                                            return (!found ? showValue : null);
                                        }
                                }
                            }
                            else {
                                switch (operator) {
                                    case '>':
                                        return (showValue > value ? showValue : null);
                                    case '>=':
                                        return (showValue >= value ? showValue : null);
                                    case '<':
                                        return (showValue < value ? showValue : null);
                                    case '<=':
                                        return (showValue <= value ? showValue : null);
                                    case '==':
                                        return (value == showValue ? showValue : null);
                                    case '===':
                                        return (value === showValue ? showValue : null);
                                    case '!=':
                                        return (value != showValue ? showValue : null);
                                    case '!==':
                                        return (value !== showValue ? showValue : null);
                                }
                            }
                        }
                        else if (conditions.length === 2) {

                            if (conditions[1]) {
                                switch (conditions[1]) {
                                    case 'empty':
                                        return _.isUndefined(showValue) || _.isEmpty(showValue);

                                    case 'notempty':
                                        return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                    case 'exists':
                                        return !_.isUndefined(showValue) && showValue;

                                    case 'notexists':
                                        return _.isUndefined(showValue) || _.isEmpty(showValue);
                                }
                            }

                            return (showValue == conditions[1] ? showValue : null);
                        }
                    }
                }
            }


            return false;
        },
        getCondition(validCount = 0, whenConditions = []) {
            let isEmpty;


            _.each(whenConditions,(c) => {
                if (_.isString(c) && c)
                {

                    // console.log('Testing condition: '+ c)


                    let conditions = c.split('|');
                    if (conditions.length > 1) {
                        let hasShowValue;
                        let showValue;

                        if (this.hasFormModel) {
                            hasShowValue = this.hasOwnPropertyPath(this.formModel, conditions[0]);
                            showValue = this.getPropertyPath(this.formModel, conditions[0]);
                        }
                        else {
                            hasShowValue = this.hasOwnPropertyPath(this.$root.model, conditions[0]);
                            showValue = this.getPropertyPath(this.$root.model, conditions[0]);
                        }


                        let operator, value;
                        if (hasShowValue) {
                            if (conditions.length === 3)
                            {
                                operator = conditions[1];
                                value = conditions[2];

                                if (value.match(/,/g)) {
                                    value = value.split(',');
                                }
                                else if (value && value.match(/^true$/ig) ) {
                                    value = true;
                                }
                                else if (value && value.match(/^false$/ig) ) {
                                    value = false;
                                }

                                let found = false


                                if (_.isArray(value))
                                {
                                    switch (operator) {

                                        case 'in':
                                        case 'exists':
                                            found = false;


                                            if (_.isArray(showValue) || _.isObject(showValue)) {

                                                _.each(showValue, (v) => {
                                                    if (!found) {
                                                        _.each(value, (item) => {
                                                            if (item == v) {
                                                                found = true;
                                                            }
                                                        });
                                                    }
                                                });

                                                validCount = validCount + (found ? 1 : 0);
                                            }
                                            else {

                                                _.each(value, (item) => {
                                                    if (!found) {
                                                        if (item == showValue) {
                                                            found = true;
                                                        }
                                                    }
                                                });

                                                validCount = validCount + (found ? 1 : 0);
                                            }
                                            break;

                                        case 'notin':
                                        case 'notexists':
                                            found = false;
                                            if (_.isArray(showValue) || _.isObject(showValue)) {

                                                _.each(showValue, (v) => {
                                                    if (!found) {
                                                        _.each(value, (item) => {
                                                            if (item == v) {
                                                                found = true;
                                                            }
                                                        });
                                                    }

                                                });
                                                validCount = validCount + (!found ? 1 : 0);
                                            }
                                            else {
                                                _.each(value, (item) => {
                                                    if (!found) {
                                                        if (item == showValue) {
                                                            found = true;
                                                        }
                                                    }
                                                });

                                                validCount = validCount + (!found ? 1 : 0);
                                            }

                                            break;
                                    } // End switch
                                }
                                else {
                                    switch (operator) {
                                        case '>':
                                            validCount = validCount + (showValue > value ? 1 : 0);
                                            break;
                                        case '<':
                                            validCount = validCount + (showValue < value ? 1 : 0);
                                            break;
                                        case '==':
                                            validCount = validCount + (value == showValue ? 1 : 0);
                                            break;
                                        case '===':
                                            validCount = validCount + (value === showValue ? 1 : 0);
                                            break;
                                        case '!=':
                                            validCount = validCount + (value != showValue ? 1 : 0);
                                            break;
                                        case '!==':
                                            validCount = validCount + (value !== showValue ? 1 : 0);
                                            break;
                                    }
                                }

                            } // if condision length 3

                            else if (conditions.length === 2) {

                                switch (conditions[1]) {
                                    case 'exists':
                                    case 'notempty':


                                        if (_.isNumber(showValue)) {
                                            isEmpty = showValue > 0 ? false : true;
                                        }
                                        else {
                                            isEmpty = _.isEmpty(showValue);
                                        }

                                        validCount = validCount + (isEmpty !== true ? 1 : 0);
                                        break;

                                    case 'notexists':
                                    case 'empty':

                                        if (_.isNumber(showValue)) {
                                            isEmpty = showValue <= 0 ? true : false;
                                        }
                                        else {
                                            isEmpty = _.isEmpty(showValue);
                                        }

                                        validCount = validCount + (isEmpty === true ? 1 : 0);
                                        break;
                                }

                            }
                        }
                    }
                }
            });

            return validCount;
        },





        hideTab(tabid) {
            let t = this.$el.querySelector('#tab_'+ tabid);
            if (t) {
                t.classList.add('hidden')
            }
        },

        showTab(tabid) {
            let t = this.$el.querySelector('#tab_'+ tabid);
            if (t) {
                t.classList.remove('hidden')
            }
        },
    }
}
</script>

<style scoped>

</style>
