<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";
import {diff_match_patch} from "@/utils/diff_match_patch"
import {compareText} from "@/utils/text2diff";
const Diff = require('diff');
export default defineComponent({
    name: "proofreading-compare",
    emits: ['input', 'focus', 'blur', 'updated', 'change'],
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);
        const showDiff = ref(false)
        const selectedProofReader = ref(0)

        const hasProfreaders = computed(() => {
            return props.field.proofreaders && !_.isEmpty(props.field.proofreaders)
        });

        const proofreaders = computed(() => {
            return hasProfreaders.value ? props.field.proofreaders : []
        });

        const anwerField = computed(() => {
            return props.field.answer || {}
        });

        const answerCols = computed(() => {
            return hasProfreaders.value && proofreaders.value.length > 0 && selectedProofReader.value > 0 ? 6 : 12;
        });

        const selectedProofReading = computed(() => {
            if (selectedProofReader.value) {
                let found = _.find(props.field.proofreaders, {id: selectedProofReader.value});
                return found ? found.proofreading : 'Kein Korrekturvorschlag von %s vorhanden';
            }
            return null;
        });

        const selectedProofReaderHasContent = computed(() => {
            if (selectedProofReader.value) {
                let found = _.find(props.field.proofreaders, {id: selectedProofReader.value});
                return found && found.proofreading && found.proofreading.trim() ? true : false;
            }
            return false;
        });

        const answerModel = computed(() => {
            return props.formModel.answer ? props.formModel.answer : ''
        });


        const diffOptions = {
            ignoreCase: false,
            ignoreWhitespace: false,
            ignoreNewlineAtEof: true,
            stripTrailingCr: true,
            newlineIsToken: true,
        }

        const cleanContent = (str) => {
            str = (str + '');
            return str.replace(/\r\n/g, "\n").replace(/\n\r/g, "\n").replace(/\r/g, "\n");
        }

        const nl2br = (str) => {
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n){1}/g, '$1<br>$2');
        }

        const diffContent = ref(null);
        watch(() => selectedProofReaderHasContent.value, (n, o) => {
            showDiff.value = false;

            if (!n) {
                diffContent.value = null;

                nextTick(() => {
                    // trigger resize event
                    const res = new Event('resize');
                    window.dispatchEvent(res)
                })
            }
            else {
                // let dmp = new diff_match_patch();

                let span = null;
                let org = cleanContent(answerModel.value);
                let changes = selectedProofReading.value;


                let orgLines = org.split("\n");
                const diff = Diff.diffChars(org, changes, diffOptions);
                let data = [];

                diff.forEach((part) => {
                    // green for additions, red for deletions
                    // grey for common parts
                    const color = part.added === true ? 'green' : part.removed === true ? 'red' : 'grey';

                    let isNl = part.value.match(/^\n$/g),
                        isNlStart = part.value.match(/^\n/g),
                        isNlEnd = part.value.match(/\n\s*$/g),
                        containsNl = part.value.match(/\n/g);


                    // if (part.value ) {
                    //     part.value = part.value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
                    // }

                    if (part.added || part.removed) {

                        if (part.value !== '\r') {
                            if (part.removed && part.value.match(/^\n$/g)) {
                                data.push('<span class="c-'+ color + (isNl ? ' as-newline' : '') +'">'+ part.value +'</span>');
                            }
                            else if(part.added && part.value.match(/^\n$/g)) {
                                data.push('<span class="c-'+ color + (isNl ? ' as-newline' : '') +'">'+ part.value +'</span>');
                            }
                            else {
                                if (isNlStart && isNlEnd) {
                                    data.push('<span class="c-'+ color +' as-newline-begin-end">'+ part.value +'</span>');
                                }
                                else if (!isNlStart && isNlEnd) {
                                    data.push('<span class="c-'+ color +' as-newline-end">'+ part.value +'</span>');
                                }
                                else if (isNlStart && !isNlEnd) {
                                    data.push('<span class="c-'+ color +' as-newline-begin">'+ part.value +'</span>');
                                }
                                else {
                                    data.push('<span class="c-'+ color + (isNl ? ' as-newline' : '') +'">'+ part.value +'</span>');
                                }
                            }
                        }


                    }
                    else {
                        data.push(part.value)
                    }

                    // span = document.createElement('span');
                    // span.style.color = color;
                    // span.appendChild(document.createTextNode(part.value));
                    // fragment.appendChild(span);
                });

                diffContent.value = data.join(""); // compareText(cleanContent(answerModel.value), cleanContent(selectedProofReading.value) );


                nextTick(() => {
                    // trigger resize event
                    const res = new Event('resize');
                    window.dispatchEvent(res)
                })
            }
        });




        onMounted(() => {
            nextTick(() => {
                // trigger resize event
                const res = new Event('resize');
                document.body.dispatchEvent(res)
            })
        });

        return {
            showDiff,
            answerModel,
            answerCols,
            hasProfreaders,
            proofreaders,
            anwerField,
            selectedProofReader,
            selectedProofReading,
            selectedProofReaderHasContent,
            diffContent
        }

    }
})
</script>

<template>
    <template v-if="hasProfreaders">
        <div class="row">
            <div class="col-md-6">
                <div class="diff-header-dummy"></div>
            </div>
            <div class="col-md-6">
                <div class="diff-header">

                    <div class="btn-group" v-show="selectedProofReader > 0">
                        <button type="button" class="btn btn-primary"
                                v-tooltip="'Änderungen herforheben'"
                                :class="{active: showDiff}" @click.prevent="showDiff = true">
                            <svg-icon name="git-compare"/>
                        </button>
                        <button type="button" class="btn btn-primary"
                                v-tooltip="'Änderungen wie eingegeben anzeigen'"
                                :class="{active: !showDiff}" @click.prevent="showDiff = false">
                            <svg-icon name="align-justified"/>
                        </button>
                    </div>

                    <div class="select-wrap">
                        <select ref="formfield"
                                :name="name"
                                class="form-control"
                                :disabled="field.disabled"
                                v-model.number="selectedProofReader">
                            <option value="0">-- Korrekturvorschlag der Person --</option>
                            <template v-for="r in proofreaders">
                                <option :value="r.id">
                                    {{ r.first_name }} {{ r.surname }}
                                    <template v-if="r.institution"> | {{ r.institution.name }} | {{ r.institution.street }} {{ r.institution.street_number }} | {{ r.institution.zip }} {{ r.institution.city }} </template>
                                </option>
                            </template>
                        </select>
                    </div>

                </div>
            </div>
        </div>
    </template>
    <div class="row">
        <div :class="{ 'col-md-12': answerCols === 12, 'col-md-6': answerCols === 6}">
            <form-textarea
                :field="{
                    label: 'Antwort',
                    description: 'Bitte denken Sie daran, das Referenzen <b>IMMER</b> einzeln geschrieben werden! Sollten Sie mehrer Referenzen an einer Stelle benötigen, muss dies wie folgt aussehen: [1], [2] oder auch [1][2]<br>Nicht erlaubt sind Bsp: (1.) , [1-3] , [1 und 2]',
                    autosize: true,
                    attributes: {rows: 8, disabled: field.answer.disabled}
                }"
                :form-model="formModel"
                name="answer" @input="(v) => $emit('input', v)"></form-textarea>
        </div>
        <template v-if="hasProfreaders && selectedProofReader">
            <div class="col-md-6">
                <form-field :name="name" :form-model="formModel" :field="{label: 'Änderungs Vorschlag'}">
                    <template v-slot:field>
                        <div class="pre-text-diff" v-show="showDiff"><span v-html="diffContent"></span></div>
                        <div v-show="!showDiff" v-html="selectedProofReading" class="pre-text-diff"></div>
                    </template>
                </form-field>
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>
