<script>
import {computed, defineComponent, getCurrentInstance, inject, ref} from 'vue'
import {useRoute} from "~/vue-router";
import {defaultFieldProps, useFormField} from "@/mixins/use-form-field";
import {useStore} from "~/vuex";

export default defineComponent({
    name: "user-permissions-fields",
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const store = useStore()

        const {allowMultiple, fieldIsDisabled, modelName, defaultAttributes, modelvalue, fieldAttributes} = useFormField(props, context);

        const route = useRoute();
        const cachedTamplates = ref({})
        const $http = inject('$http');
        const $events = inject('$events');

        if (store.state.form.formDefinition &&
            store.state.form.formDefinition.hasOwnProperty('permission_template_fields') &&
            store.state.form.formDefinition.hasOwnProperty('permission_template')) {
            cachedTamplates.value[store.state.form.formDefinition.permission_template] = store.state.form.formDefinition.permission_template_fields;
        }

        const permissionForFields = computed(() => {

            if (cachedTamplates.value.hasOwnProperty(props.formModel.template)) {
                return cachedTamplates.value[props.formModel.template];
            }

            return [];
        })


        const hasPermissionForFields = computed(() => {
            if (permissionForFields.value) {
                if (!_.isArray(permissionForFields.value)) {
                    return Object.keys(permissionForFields.value).length > 0
                }
                else {
                    return permissionForFields.value.length > 0;
                }
            }

            return false;
        });


        $events.$on('users.load-permission-templates', loadPermissionTemplate)

        function loadPermissionTemplate(val) {

            if (cachedTamplates.value.hasOwnProperty(val)) {
                return;
            }

            $http.post(app.routes('admin.users.load-permission-template'), {template: val}).then(r => {
                if ( r.data.success ) {
                    cachedTamplates.value[val] = r.data.template;
                }
            });
        }


        function fieldComponentName(field) {
            if (field.type === 'tab') {
                return null;
            }
            return 'form-' + field.type;
        }

        return {
            hasPermissionForFields,
            permissionForFields,
            fieldComponentName
        }
    }
})
</script>

<template>

    <template v-if="hasPermissionForFields">
        <div class="row">
            <template v-for="(f, n) in permissionForFields" :key="'permissions.'+ n">
                <component v-if="fieldComponentName(f)"
                           :is="fieldComponentName(f)"
                           :name="'permissions.'+ n"
                           :field="f"
                           :mode="mode"
                           :validate.sync="validate"
                           :rendersections="rendersections"
                           :responseerrors.sync="responseerrors"
                           :form-model.sync="formModel"
                ></component>
            </template>
        </div>
    </template>

</template>

<style scoped>

</style>
