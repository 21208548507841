<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">

        <!--        <button @click="back" type="button" class="btn btn-primary">Zurück</button>-->

        <button class="btn btn-primary"
                @click="handleCreateInstitution"
                type="button"
                v-if="canCreateUser">
            <svg-icon name="plus"/>
            Institution anlegen
        </button>

    </toolbar>


    <data-table v-if="grid" ref="gridata"
                :field-components="fieldComponents"
                :base-data="grid"
                :toolbar="$refs.toolbar"
                detail-row-component="InstitutionDetailRow"
                @open-detail-row="openDetails"
                @close-detail-row="closeDetails"
                @selected-count="(v) => { selectedTrashCount = v }"
                @delete="handleDelete"
                @loaded="onLoaded">

        <template v-slot:name="{row}">
            <div class="institution">
	            <div>
		            <div>{{ row.name }}</div>
		            <div class="text-small">{{ row.street }}<br v-if="row.street">{{ row.zip }} {{ row.city }}</div>
	            </div>
	            <div class="badge-placeholder">
		            <div class="badge badge-dark">
			            {{ row.is_holder ? 'Kammerbereich' : 'Infostelle' }}
		            </div>
	            </div>
            </div>

        </template>


        <template v-slot:holder="{row}">
            <template v-if="row.holder">
                <div>{{ row.holder.name }}</div>

<!--                <div class="text-small">{{ row.holder.street }} {{ row.holder.street_number }} <br>{{ row.holder.zip }} {{ row.holder.city }}</div>-->
            </template>
        </template>

        <template v-slot:created_at="{row}">
            <created-updated-by-at v-if="row.created_at" :date="row.created_at" :user="row.createdby" type="created"/>
        </template>

        <template v-slot:updated_at="{row}">
            <created-updated-by-at v-if="row.updated_at" :date="row.updated_at" :user="row.updatedby" type="updated"/>
        </template>
    </data-table>
</template>

<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, defineComponent, getCurrentInstance, inject, nextTick} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";


export default defineComponent({
        name: "institutionsIndex",
        setup(props, context) {
            const inst = getCurrentInstance();
            const $root = inst.root.ctx
            const $http = inject('$http');
            const $events = inject('$events');
            const route = useRoute();
            const router = useRouter()
            const store = useStore();
            const requestAction = inject('requestAction')
            const setPropertyPath = inject('setPropertyPath');
            const changeBreadcrumb = inject('changeBreadcrumb')

            const {
                hasTrashed,
                viewTrash,
                selectedTrashCount,
                cleaning,
                grid,
                toolbarData,
                tempRoute,
                fieldComponents,
                handleClearGridFilter,
                handleGridFilter,
                back
            } = useDatatable(props, context, inst);

            const canCreateUser = computed(() => {

                return store.getters['user/user'].is_admin;

                // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
            })

            loadData();


            function loadData() {
                let cache = getDataTableCacheForFilter(route.path);

                let postData = {};
                if (cache && cache.grid) {
                    postData = cache.grid;
                    if (cache.grid.currentFilter) {
                        postData = Object.assign(postData, cache.grid.currentFilter);
                    }
                }

                if (route.query.newregistrations) {
                    postData.newregistrations = true;
                }

                $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                    if (!r.data.grid.url) {
                        r.data.grid.url = $root.currentRouteUrl(route.path);
                    }

                    hasTrashed.value = r.data.hastrashed;
                    toolbarData.value = r.data.grid.filter;
                    grid.value = r.data.grid;

                    changeBreadcrumb(r.data)

                    nextTick(() => {
                        $events.$emit('pageloading', false);
                    })

                }).catch((e) => {
                    console.warn(e);
                    nextTick(() => {
                        $events.$emit('pageloading', false);
                    })
                });
            }


            function onLoaded(data) {
                changeBreadcrumb(data)
            }

            function handleCreateInstitution() {
                router.push({name: 'admin.institutions.create'})
            }

            function handleDelete() {

            }


            function openDetails(row, event) {
                setPropertyPath(row, 'loadingdetails', true);

                if (row.hasOwnProperty('rowdetails')) {
                    setPropertyPath(row, 'loadingdetails', false);
                    return;
                }

                requestAction('onLoadInstitutionDetails').post($root.currentRouteUrl(route.path), {institution: row.id}).then((r) => {
                    setPropertyPath(row, 'rowdetails', r.data);
                    setPropertyPath(row, 'loadingdetails', false);
                });
            }

            function closeDetails(row, event) {

            }

            return {
                grid,
                cleaning,
                toolbarData,
                fieldComponents,
                canCreateUser,
                selectedTrashCount,
                back,
                onLoaded,
                handleGridFilter,
                handleClearGridFilter,
                handleCreateInstitution,
                handleDelete,

                openDetails,
                closeDetails,
            }
        }
    });
</script>

<style scoped>

</style>
