<template>
    <div class="dropdown-button">
        <tippy ref="tippy"
               tag="span"
               trigger="click"
               :class="{active: isVisible}"
               placement="bottom"
               animation="shift-toward-extreme"
               :interactive="true"
               :hide-on-click="true"
               :duration="[200,200]"
               @click.prevent="sendTrigger"
               @hide="handleHide"
               :append-to="appendToApp"
               theme="dropdown"
               arrow>
            <template #default>
                <template v-if="tooltip">
                    <div class="trigger" :class="classes"
                         ref="trigger"
                         v-tooltip="tooltip"
                    >
                        <template v-if="hasTriggerSlot">
                            <slot name="trigger"></slot>
                        </template>
                        <template v-else>
                            <button type="button"
                                    ref="togglebtn"
                                    :class="['btn dropdown-toggle toggle-arrow-only', {'disabled': disabled }, classes]"
                                    :style="{display: isHide ? 'none' : null}"></button>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="trigger"
                         :class="classes"
                         ref="trigger">
                        <template v-if="$slots.trigger">
                            <slot name="trigger"></slot>
                        </template>
                        <template v-else>
                            <button type="button"
                                    ref="togglebtn"
                                    :class="['btn dropdown-toggle toggle-arrow-only', {'disabled': disabled }, classes]"
                                    :style="{display: isHide ? 'none' : null}"></button>
                        </template>
                    </div>
                </template>
            </template>

            <template #content="{ state, hide }">
	            	                <span data-popper-arrow>
		                <div class="arrow" v-if="arrow"></div>
	                </span>

                <div class="contextmenu dropdown-btn-context"
                     :class="{show: isVisible, 'with-arrow': arrow}"
                     ref="toggledropdown">
                    <slot name="content"
                          v-bind:state="state"
                          v-bind:hide="hide"
                    ></slot>
                </div>
            </template>

        </tippy>
    </div>
</template>

<script>
import {computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch, nextTick} from "vue";
import {closest} from "../utils/dom"
import {createPopper} from "~/@popperjs/core";


const defaultPopperProps = {
    padding: 8,
    rootBoundary: 'document',
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [10, 10],
            },
        },
        {
            name: 'flip',
            enabled: true,
        },
        {
            name: 'preventOverflow',
            options: {
                tetherOffset: ({popper, reference, placement}) => popper.width / 2,
            },
        },
    ],
}

export default {
    name: "dropdown-button",
    emits: ['visible', 'focus'],
    props: {
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isHide: {
            type: Boolean,
            default: false
        },
        classes: {
            type: [Array, Object],
            default() {
                return []
            }
        },
        tooltip: {
            type: String,
            default: ''
        },
        position: {
            type: String,
            default: 'center'
        },
        arrow: {
            type: Boolean,
            default: true
        },
        // appendTo: {
        //
        // },
        popperProps: {
            type: Object,
            default: {}
        },
        withDropdownCloser: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root;
        const visible = ref(false);
        const reposDone = ref(false);

        let popperJs;
        const current = ref(0);
        const items = ref(null);
        const appendToApp = () => document.querySelector('#app');

        watch(() => visible.value, (n) => {
            context.emit('visible', n);
        });

        const hasTriggerSlot = computed(() => {
            return context.slots.trigger && !_.isEmpty(context.slots.trigger);
        });

        const inSlidePanel = computed(() => {
            let found = closest(inst.refs.toggledropdown, '.slide-panel');
            return found ? found : false;
        });

        onBeforeUnmount(() => {

        });


        onMounted(() => {
            dropdownCloser()
        });


        const dropdownCloser = () => {
            if (props.withDropdownCloser) {
                const dropdown = inst.refs.toggledropdown

                dropdown.querySelectorAll('[dropdown-closer]').forEach(element => {
                    element.addEventListener('click', (e) => {
                        hideMenu(e)
                    })
                })
            }
        }

        const closeDropdownOnPopState = () => {
            window.addEventListener('popstate', () => {
                if (visible.value) {
                    visible.value = false
                }
            })
        }

        const destroyCloseDropdownOnPopState = () => {
            window.removeEventListener('popstate', closeDropdownOnPopState)
        }

        function sendTrigger(event) {
            visible.value = true;
        }

        function handleHide(event) {
            visible.value = false;
        }


        function enterFrom() {
            if (inst.refs.toggledropdown) {
                console.log('enterFrom', inst.refs.toggledropdown)
            }
        }

        function onEnter() {


            if (inst.refs.toggledropdown) {
                let pos = inst.refs.toggledropdown.dataset.popperPlacement;
                /**
                 * @var pos {DOMStringMap}
                 */
                if (pos) {

                    // pos = pos.replace(/^(top|right|bottom|left).*/g, '$1');
                    switch (pos) {
                        case 'top':

                            break;
                        case 'bottom':

                            break;
                        case 'left':

                            break;
                        case 'right':

                            break;
                    }

                }
                console.log('onEnter', pos)
            }
            // inst.refs.toggledropdown.style = Object.assign(inst.refs.toggledropdown.style, {
            //
            // });

        }

        function onLeave() {
            console.log('onLeave')
        }

        function createPopperInstance() {
            destroyPopper()

            nextTick(() => {
                if ($root.vnode.el) {
                    let opts = Object.assign({}, defaultPopperProps, { rootBoundary: document.querySelector('#app') }, props.popperProps);

                    $root.ctx.appendToAppNode(inst.refs.toggledropdown);

                    // Add start style
                    let triggerPos = inst.refs.trigger.getBoundingClientRect();
                    let w = inst.refs.toggledropdown.offsetWidth;

                    inst.refs.toggledropdown.style.position = 'fixed';
                    popperJs = createPopper(inst.refs.trigger, inst.refs.toggledropdown, opts);
                }

            })
        }

        function updatePopper() {
            if (popperJs) {
                popperJs.scheduleUpdate()
            }
        }

        function destroyPopper() {
            if (popperJs) {
                if (inst.refs.toggledropdown) {
                    inst.refs.toggledropdown.classList.remove('show')
                }

                popperJs.destroy();
                popperJs = null;
            }
        }


        function reposition() {
            return;

            const body = document.querySelector('body');
            const dummy = inst.refs.toggledropdown.cloneNode(true);
            body.appendChild(dummy);
            dummy.style.display = '';
            const toggledropdownWidth = dummy.offsetWidth;
            dummy.remove();


            if (inSlidePanel.value) {
                const left = inst.refs.toggledropdown.offsetLeft;
                const w = inSlidePanel.value.offsetWidth;

                if (left + w > toggledropdownWidth) {
                    inst.refs.toggledropdown.style.right = 0
                    inst.refs.toggledropdown.style.left = 'auto';
                    return;
                } else if (left - w / 2 <= 0) {
                    inst.refs.toggledropdown.style.right = 'auto'
                    inst.refs.toggledropdown.style.left = 5;
                    return;
                }
            }

            if (props.position === 'center') {

                // let body = document.querySelector('body');
                // let dummy = inst.refs.toggledropdown.cloneNode(true);
                // body.appendChild(dummy);
                // dummy.style.display = '';
                // let w = dummy.offsetWidth;
                // dummy.remove();
                let computedStyle = window.getComputedStyle(inst.refs.toggledropdown);
                const trigger = inst.refs.trigger;
                const triggerHalf = (trigger.offsetWidth / 2);


                const computedLeft = computedStyle.left;
                const computedRight = computedStyle.right;

                if ((computedRight !== 'auto' && computedLeft !== 'auto') || computedRight === 'auto' && computedLeft === 'auto') {
                    inst.refs.toggledropdown.style.left = triggerHalf - (toggledropdownWidth / 2) + 'px';
                    inst.refs.toggledropdown.style.right = 'auto';
                } else if (computedRight === 'auto' && computedLeft !== 'auto') {
                    inst.refs.toggledropdown.style.left = triggerHalf - (toggledropdownWidth / 2) + 'px';
                    inst.refs.toggledropdown.style.right = 'auto';
                } else if (computedRight !== 'auto' && computedLeft === 'auto') {
                    inst.refs.toggledropdown.style.right = triggerHalf - (toggledropdownWidth / 2) + 'px';
                    inst.refs.toggledropdown.style.left = 'auto';
                }
            } else if (props.position === 'right') {
                inst.refs.toggledropdown.style.right = 'auto'
                inst.refs.toggledropdown.style.left = 0;
            } else if (props.position === 'left') {
                inst.refs.toggledropdown.style.right = 0;
                inst.refs.toggledropdown.style.left = 'auto';
            }

            reposDone.value = true;
        }

        function beforeEnter(el) {
            if (props.position === 'center') {

            }
        }

        function enter(el, done) {
            if (done) done();
        }

        function beforeLeave(el) {
        }

        function leave(el, done) {
            if (done) done()
        }

        function bindTriggerClick() {
            let trigger = inst.vnode.el ? inst.vnode.el.querySelector('.dropdown-button > .trigger') : null;
            if (trigger && !trigger.classList.contains('dropdown-menu')) {
                trigger.addEventListener('click', toggleButtonMenu)
            }

            let items = inst.refs.toggledropdown ? inst.refs.toggledropdown.querySelectorAll('li') : null;
            if (items) {
                items.forEach((item) => {
                    item.addEventListener('click', hideMenu);
                });
            }
        }


        function unbindTriggerClick() {
            let trigger = inst.vnode.el ? inst.vnode.el.querySelector('.dropdown-button > .trigger') : null;
            if (trigger && !trigger.classList.contains('dropdown-menu')) {
                trigger.removeEventListener('click', toggleButtonMenu)
            }

            let items = inst.refs.toggledropdown ? inst.refs.toggledropdown.querySelectorAll('li') : null;
            if (items) {
                items.forEach((item) => {
                    item.removeEventListener('click', hideMenu);
                });
            }
        }

        function hideMenu(e) {
            // e.preventDefault();
            visible.value = false;

            if (inst.refs.toggledropdown) {

                inst.refs.tippy.hide();
                inst.refs.toggledropdown.classList.remove('show')
            }
        }

        /**
         *
         * @param {Event} e
         */
        function toggleButtonMenu(e) {
            e.preventDefault();

            if (e.target.disabled || e.target.querySelector('[disabled]')) return;

            context.emit('focus');

            if (!visible.value && !reposDone.value) reposition();

            visible.value = !visible.value;

            if (visible.value) {
                createPopperInstance();
            } else {
                destroyPopper()
            }
        }

        function open() {
            visible.value = true;

            createPopperInstance();

        }

        function close(event, el) {
            destroyPopper()

            if (event) {
                if (el === event.target && event.type === 'click') {
                    event.preventDefault();
                    return event;
                }
            }

            if (!visible.value) {
                return event;
            }

            visible.value = false;
        }


        function focus(n = 0) {
            if (items.value[n] && items.value[n].focus) {
                items.value.forEach((el, i) => {
                    if (i !== current.value) {
                        el.classList.remove('focus');
                    }
                });

                current.value = n;
                items.value[n].classList.add('focus');
                items.value[n].focus();
            }
        }

        function navigate(e) {
            if (!visible.value) return;

            /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
            switch (e.code) {
                case "Escape":
                    hideMenu(e);
                    break;

                case "ArrowUp":
                    e.stopPropagation();
                    while (true) {
                        current.value--;
                        if (current.value < 0) {
                            hideMenu(e);
                            current.value = 0;
                            break;
                        }

                        if (items.value[current.value] &&
                            !items.value[current.value].classList.contains('disabled') &&
                            !items.value[current.value].classList.contains('separator')) {
                            focus(current.value);
                            break;
                        }
                    }
                    break;

                case "ArrowDown":
                    e.stopPropagation();

                    while (true) {
                        current.value++;

                        if (current.value > items.value.length - 1) {

                            const enabled = items.value.filter((x) => {
                                return !x.classList.contains('disabled');
                            });

                            current.value = items.value.indexOf(enabled[enabled.length - 1]);

                            break;
                        }

                        if (
                            items.value[current.value] &&
                            !items.value[current.value].classList.contains('disabled') &&
                            !items.value[current.value].classList.contains('separator')
                        ) {
                            focus(current.value);
                            break;
                        }
                    }
                    break;

                case "Tab":
                    while (true) {
                        current.value++;
                        if (current.value > items.value.length - 1) {
                            hideMenu(e);
                            break;
                        }
                        if (
                            items.value[current.value] &&
                            !items.value[current.value].classList.contains('disabled') &&
                            !items.value[current.value].classList.contains('separator')
                        ) {
                            break;
                        }
                    }
                    break;
            }
        }

        return {
            isVisible: visible,
            hasTriggerSlot,
            open,
            close,
            toggleButtonMenu,
            defaultPopperProps,
            enterFrom,
            onEnter,
            onLeave,
            appendToApp,
            sendTrigger,
            handleHide
        }
    }
}
</script>

<style scoped>

</style>
