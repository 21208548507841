import {nextTick} from "vue";
import autosize from 'autosize';
export const Autosizer = {

    created(el, binding, vnode, prevVnode) {
        if (el.created) {
            return;
        }

        el.created = true;
    },

    mounted(el, binding, vnode) {
        if (el.mounted) {
            return;
        }

        el.mounted = true;

        if (el.tagName === 'TEXTAREA') {
            nextTick(() => {
                autosize(el);
            });
        }
    },

    beforeUnmount(el) {
        autosize.destroy(el);
    },

    updated(el, binding) {
        if (el.tagName === 'TEXTAREA') {
            nextTick(() => {
                autosize.update(el);
            });
        }
    }
}

Vue.directive('autosize', Autosizer);
