<template>
    <div>
        <div class="tab-content" v-if="hasTabs"></div>
        <div v-else>
            <validation-errors :errors="responseerrors"></validation-errors>
            <template v-show="tabHasFields(fields)">
                <form-fields :fields="fields"
                             :responseerrors="responseerrors"
                             :rendersections="rendersections"
                             :form-model.sync="formModel"
                             :validate="validate"
                             mode="main"
                ></form-fields>
            </template>
        </div>
    </div>
</template>

<script>
import {ref, watch, computed, getCurrentInstance, onBeforeMount} from "vue";

export default {
    name: "modal-form-content",
    props: {
        name: {
            required: false,
        },
        fields: {
            required: true,
            type: [Object, Array]
        },
        validate: {
            type: Boolean,
            default: false
        },
        rendersections: {
            required: false,
            default() {
                return {}
            }
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        }
    },

    setup(props, context) {
        const inst = getCurrentInstance();
        const currentTab = ref(0)
        const mutatedTabs = ref([])

        watch(() => props.fields, (n, o) => {
            if (n !== o && n) {
                if (n.fields && n.fields.fields) {
                    prepareTabs();
                }
                else {
                    mutatedTabs.value = []
                }
            }
        });



        const hasFormModel = computed(() => {
            return _.isObject(props.formModel);
        })

        const hasFields = computed(() => {
            return !_.isEmpty(props.fields.fields);
        })

        const hasTabs = computed(() => {
            return _.filter(mutatedTabs.value, (r) =>
            {
                return r.type === 'tab';
            }).length > 0
        })


        onBeforeMount(() => {
            if (hasFields.value) {
                prepareTabs( _.cloneDeep(props.fields.fields));
            }
        })
        function getTabs() {
            return _.filter(mutatedTabs.value, (r) =>
            {
                return r.type === 'tab';
            })
        }

        function prepareTabs(fields) {
            mutatedTabs.value = fields;
        }

        function tabHasFields(tab) {
            return tab.hasOwnProperty('fields') && !_.isEmpty(tab.fields);
        }


        return {
            currentTab,
            mutatedTabs,
            hasFormModel,
            hasFields,
            hasTabs,
            tabHasFields,
            getTabs,
        }
    }

}
</script>

<style scoped>

</style>
