<template>
    <form-field :name="name" :form-model="formModel" :field="mutatedField" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>

                <input
                    :type="pwvisible === true ? 'text' : 'password'"
                    :name="name"
                    ref="formfield"
                    class="form-control"
                    autocomplete="new-password"
                    v-model.sync="modelvalue"
                    v-bind="fieldAttributes"
                    @input="(e) => updateValue(e.currentTarget.value)"
                    @keyup.enter="(e) => $emit('enter', e)"
                >
        </template>



        <template v-slot:append>
            <span class="append-label cursor-pointer" v-tooltip="'Eingabe anzeigen/ausblenden'"
                  @click.prevent="(e) => {pwvisible = pwvisible === true ? false : true;}">
                <svg-icon :name="pwvisible === true ? 'eye-off' : 'eye'"/>
            </span>
        </template>


    </form-field>

</template>
<script>
import {nextTick, onMounted, ref} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-password",
    emits: ['input', 'enter'],
    props: {
        ...defaultFieldProps,
        data: {
            required: false,
            default() {
                return undefined
            }
        },
        returnOnly: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {

        const {modelvalue, fieldAttributes} = useFormField(props, context);
        const defaultModelValue = ref(null);
        const pwvisible = ref(false);


        const mutatedField = props.field

        if (props.field.hasOwnProperty('default')) {
            defaultModelValue.value = props.field.default;
        }

        function updateValue(value) {
            if (!props.returnOnly) {
                modelvalue.value = value;
            }
            context.emit('input', value)
        }

        return {
            mutatedField,
            fieldAttributes,
            modelvalue,
            pwvisible,
            updateValue
        }
    }
}
</script>

<style scoped>

</style>
