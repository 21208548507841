<template>
    <div class="forms-row row align-items-stretch"
         :class="{hidden: (hasShowWhen ? !getShowWhen : false)}">
        <template v-if="hasFields" v-for="(f, n) in field.fields" :key="n">
            <input type="hidden" v-if="f.type === 'hidden'" :name="n"/>
            <template v-else>
                <component v-if="f.type !== 'column' && f.type !== 'columns' && f.type !== 'section'"
                           :is="'form-'+ f.type"
                           :responseerrors="responseerrors"
                           :form-model="formModel"
                           :validate="validate" :name="n" :field="f"></component>
                <component :is="'form-'+ f.type"
                           :responseerrors="responseerrors"
                           :validate="validate"
                           :form-model="formModel"
                           :name="n"
                           :field="f" :rendersections="rendersections" v-else-if="f.type === 'column' || f.type === 'columns' || f.type === 'section'"></component>
            </template>
        </template>
        <template v-else>DDD</template>
    </div>
</template>

<script>


import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "form-columns",
    props: {
        ...defaultFieldProps
    },
    setup(props, context) {
        const {hasFields, hasShowWhen, getShowWhen} = useFormField(props, context);

        return {
            hasFields,
            hasShowWhen,
            getShowWhen,
        }
    },
}
</script>

<style scoped>

</style>
