import mediamanagerIndex from './components/Mediamanager.vue';


export default [
    {
        path: '/mediamanager',
        component: mediamanagerIndex,
        name: 'admin.mediamanager',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
];
