
// This hash will be used as the first element in every LCS.
const hash = "e6783d7aaf8bb4";

// Divide string into sentences or words
function divideStringIntoSentences(string) {
    return string.match(/[^\.!\?]+[\.!\?]+/g)
        ? string.match(/[^\.!\?]+[\.!\?]+/g).map((sen) => sen.split(" "))
        : string.split(" ");
}

// Compare multiple sentences and output differences
function compareMultipleSentences(listX, listY) {
    listX.forEach((sen) => sen.unshift(hash));
    listY.forEach((sen) => sen.unshift(hash));


    if (listX.length !== listY.length) {
        return `<h2 style="color:#ff1145;text-align: center;">Text boxes must have the same number of sentences!</h2>`
    }
    return listX
        .map((sen, i) =>
            getDiff(lcsLengths(sen, listY[i]), sen, listY[i]).join(" ")
        )
        .join(" ")

}

// Compare a single sentence and output differences
function compareSingleSentence(listX, listY) {
    listX.unshift(hash);
    listY.unshift(hash);

    return getDiff(lcsLengths(listX, listY), listX, listY).join(" ");
}


/**
 *
 * @param {String} leftInput
 * @param {String} rightInput
 * @return {string|*|string}
 */
export function compareText(leftInput, rightInput) {
    const listX = divideStringIntoSentences(
        leftInput
    );

    const listY = divideStringIntoSentences(
        rightInput
    );


    if (Array.isArray(listX[0]) && Array.isArray(listY[0])) {
        return compareMultipleSentences(listX, listY)
    }

    return compareSingleSentence(listX, listY);
}

// Create a matrix and map LCS lengths onto it
function lcsLengths(listX, listY) {
    const lenX = listX.length;
    const lenY = listY.length;

    // Initialize matrix with zeros
    const memo = Array.from({ length: lenX + 1 }, () => Array(lenY + 1).fill(0));

    // Populate matrix with LCS lengths
    for (let i = 1; i <= lenX; i++) {
        for (let j = 1; j <= lenY; j++) {
            memo[i][j] =
                listX[i - 1] === listY[j - 1]
                    ? memo[i - 1][j - 1] + 1
                    : Math.max(memo[i][j - 1], memo[i - 1][j]);
        }
    }

    return memo;
}

// Determine string differences and return the final string
function getDiff(memo, listX, listY) {
    const finalString = [];

    // Backtrack values from the matrix to determine differences
    function lcsBackTrack(memo, listX, listY, posX, posY) {
        if (posX === 0 || posY === 0) return "";

        if (
            listX[posX - 1] === listY[posY - 1] &&
            memo[posX][posY - 1] < memo[posX][posY]
        ) {
            finalString.push(listX[posX - 1]);
            return (
                lcsBackTrack(memo, listX, listY, posX - 1, posY - 1) + listX[posX - 1]
            );
        } else {
            if (memo[posX][posY - 1] > memo[posX - 1][posY]) {
                finalString.push(`<ins>${listY[posY - 1]}</ins>`);
                return lcsBackTrack(memo, listX, listY, posX, posY - 1);
            } else {
                finalString.push(`<del>${listX[posX - 1]}</del>`);
                return lcsBackTrack(memo, listX, listY, posX - 1, posY);
            }
        }
    }

    lcsBackTrack(memo, listX, listY, listX.length, listY.length);
    return finalString.filter((word) => word !== hash).reverse();
}


/**
 *
 * @param {String} oldText
 * @param {String} newText
 * @param {HTMLElement} diffOutputHtmlEl
 */
function computeDiff(oldText, newText, diffOutputHtmlEl) {
    const dmp = new diff_match_patch();
    const diff = dmp.diff_main(oldText, newText);
    dmp.diff_cleanupSemantic(diff);

    diffOutputHtmlEl.innerHTML = '';

    diff.forEach((part) => {
        const color = part[0] === 1 ? 'insert' : part[0] === -1 ? 'delete' : '';
        const span = document.createElement('span');
        span.className = color;
        span.appendChild(document.createTextNode(part[1]));
        diffOutputHtmlEl.appendChild(span);
    });
}
