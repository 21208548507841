<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "~/vue"
import {useFormField, defaultFieldProps} from "neloh-ui/mixins/use-form-field";
import {getDataTableCache, setDataTableCache, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {useRouter, useRoute} from "~/vue-router";
import {truncate} from "@/utils/truncate"
export default defineComponent({
    name: "question-opinions",
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const $root = inst.root.ctx;
        const $events = inject('$events')
        const router= useRouter();
        const route = useRoute();
        const $moment = inject('$moment')
        const $http = inject('$http');
        const wait = inject('wait')
        const notification = inject('notification')

        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            toggleTrash,
            // handleGridFilter,
            // handleClearGridFilter
        } = useDatatable(props, context, inst);

        const loading = ref(false)
        const statuslist= ref( []);
        const mutatedMessages = ref([])
        const selectedCount= ref( 0);

        const preview = ref(null);
        const loadingPreview = ref(false);


        loadDataTable();


        function onDatatableLoaded() {
            nextTick(() => {
                $events.$emit('pageloading', false);
            })
        }


        function loadDataTable()
        {
            if (!props.formModel.id) {
                loading.value = false;
                return;
            }

            if (loading.value) {
                return;
            }

            loading.value = true;



            let cache = getDataTableCache(route.path);
            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            let url = $root.routes('admin.requests.opinion.index', {item: props.formModel.id});
            try {
                $http.post(url, postData).then((r) => {

                    if (!r.data.grid.url) {
                        r.data.grid.url = url;
                    }

                    statuslist.value = r.data.statuslist;
                    toolbarData.value = r.data.grid.filter;
                    grid.value = r.data.grid;
                    hasTrashed.value = false;

                    nextTick(() => {
                        if (inst.refs.gridata) {
                            // this.$refs.gridata.loadCachedOptions(cache);
                            $events.$on('grid.refresh', inst.refs.gridata.refreshGrid);
                        }

                        loading.value = false;
                    });

                }).catch((e) => {
                    loading.value = false;
                    console.warn(e);
                });
            } catch (e) {
                loading.value = false;
            }

        }


        function handleGridFilter(filterData) {
            let url = $root.routes('admin.requests.opinion.index', {item: props.formModel.id});
            let cache = getDataTableCache(url);

            inst.refs.gridata.setFilter(filterData, inst.refs.gridtoolbar);

            if (cache && cache.grid) {
                cache.grid.currentFilter = filterData;
                setDataTableCache(url, cache);
            } else {
                setDataTableCache(url, {grid: {currentFilter: filterData}});
            }
        }

        function handleClearGridFilter() {
            inst.refs.gridata.resetFilter();
            let url = $root.routes('admin.requests.opinion.index', {item: props.formModel.id});
            let cache = getDataTableCache(url);
            if (cache && cache.grid) {
                cache.grid.currentFilter = null;
                setDataTableCache(url, cache);
            }
        }

        function rowClicked(dataItem, event) {
            return;
        }

        function openMessage(rowData, link) {
            preview.value = rowData.id;
        }


        function onSuccessSendReply() {

        }


        onMounted(() => {
            $events.$on('messanger:show-message', openMessage);
        })

        onBeforeUnmount(() => {
            $events.$off('messanger:show-message', openMessage);
        })



        return {
            loading,
            toolbarData,
            grid,
            fieldComponents,
            preview,
            truncate,
            handleClearGridFilter,
            handleGridFilter,
            selectedCount,
            onDatatableLoaded,
            rowClicked,
            onSuccessSendReply
        }
    }
})
</script>

<template>
    <div class="opinions-list">
        <div ref="grid_toolbar" class="grid-toolbar">
            <toolbar ref="gridtoolbar"
                     :filters="toolbarData"
                     @clearfilter="handleClearGridFilter"
                     @executefilter="handleGridFilter"></toolbar>
        </div>


        <div class="loading-opinions" v-if="loading">
            <loading :active.sync="loading"
                     color="blue"
                     :width="60"
                     :height="60"
                     :opacity="0.6"
                     :is-full-page="false"></loading>
        </div>


        <data-table v-if="grid"
                    ref="gridata"
                    :toolbar="$refs.gridtoolbar"
                    :field-components="fieldComponents"
                    :base-data="grid"
                    :show-pagination="false"
                    @selected-count="c => { selectedCount = c}"
                    @row-click="rowClicked"
                    @loaded="onDatatableLoaded">


            <template v-slot:subject="{row}">
                <template v-if="row.subject">
                    <div>{{ row.subject }}</div>
                </template>


                <template v-if="row.opinion">
                    <div v-if="row.opinion.question_opinion" class="question_opinion">
                        <b>Zur Frage</b> <div>{{ truncate(row.opinion.question_opinion, 100) }}</div>
                    </div>
                    <div v-if="row.opinion.answer_opinion" class="answer_opinion">
                        <b>Zur Antwort</b> <div>{{ truncate(row.opinion.answer_opinion, 100) }}</div>
                    </div>
                </template>


            </template>



            <template v-slot:sender="{row}">
                <div>
                    <template v-if="row.fromuser">
                        <div class="fromuser">
                            <div v-if="!row.fromuser.surname">{{ row.fromuser.username }}</div>
                            <div v-if="row.fromuser.surname">{{ row.fromuser.first_name }} {{ row.fromuser.surname }}</div>
                        </div>
                    </template>
                    <template v-if="row.frominstitution && row.frominstitution.name">
                        <div class="frominstitution">
                            {{ row.frominstitution.name }}
                            <br>{{ row.frominstitution.street }} {{ row.frominstitution.street_number }}
                            <br>{{ row.frominstitution.zip }} {{ row.frominstitution.city }}
                        </div>
                    </template>
                </div>
            </template>


            <template v-slot:to="{row}">
                <div v-if="row.to">
                    <template v-if="row.to.touser">
                        <div class="fromuser">
                            <div v-if="!row.to.touser.surname">{{ row.to.touser.username }}</div>
                            <div v-if="row.to.touser.surname">{{ row.to.touser.first_name }} {{ row.to.touser.surname }}</div>
                        </div>
                    </template>
                    <template v-if="row.to.toinstitution && row.to.toinstitution.name">
                        <div class="frominstitution">
                            {{ row.to.toinstitution.name }}
                            <br>{{ row.to.toinstitution.street }} {{ row.to.toinstitution.street_number }}
                            <br>{{ row.to.toinstitution.zip }} {{ row.to.toinstitution.city }}
                        </div>
                    </template>
                </div>
            </template>



        </data-table>

        <view-message
            :show="preview > 0"
            :id="preview"
            :in-request="true"
            @success-send="onSuccessSendReply"
            @close="preview = null"
            @cancel="preview = null" />

    </div>
</template>

<style scoped>

</style>
