<template>
	<div class="data-table-columns">
		<div v-on-clickaway="closeClickAway">
			<button type="button"
					class="btn btn-sm btn-primary"
					v-tooltip="'Spalten anzeigen/ausblenden'"
					@click.prevent="dropDownVisible = true">
				<span class="fa fa-columns"></span>
			</button>

			<div v-if="vueTable" class="dropdown-menu" :class="{show: dropDownVisible}">
				<ul>
					<li v-for="field in vueTable.tableFields"
						v-if="field.name !== '__component:grid-table-actions' && field.title"
						:class="['dropdown-item', {selected: field.visible}]"
						@click="changeVisible(field)"
					>
						{{ field.title }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mixin as VueClickAway} from '@/vendor/vue-click-away'
export default {
	name: "DataTableColumnsSelector",
	mixins: [VueClickAway],
	props: {
		dataTable: {}
	},
	data() {
		return {
			isMounted: false,
			dropDownVisible: false,
			vueTable: null
		}
	},
	computed: {

	},

	watch: {
		dataTable(n, o) {
			if (n) {
				this.vueTable = n.vueTable;
			}
		}
	},



	mounted() {
		this.isMounted = true;

		this.$nextTick(() => {
			this.$nextTick(() => {
				this.$nextTick(() => {
					this.$nextTick(() => {
						this.$nextTick(() => {
							this.wait(500).then(() => {
								this.vueTable = this.dataTable.vueTable;
							});
						})
					})
				})
			})
		})
	},

	methods:  {
		closeClickAway() {
			this.dropDownVisible = false;
		},

		changeVisible(field) {

			this.dropDownVisible = false;

			field.visible = !field.visible;

			if (this.vueTable.apiUrl) {

				let all = [];
				_.each(this.vueTable.tableFields, (f) => {
					if (f.visible) {
						all.push(f.name);
					}
				});

				this.$http.post( this.vueTable.apiUrl, { visible_columns: all} ).then(r => {

				}).catch(e => {

				});
			}
		}
	}
}
</script>

<style scoped>

</style>
