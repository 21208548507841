<script>
import {defineComponent, computed, getCurrentInstance, ref, watch, nextTick, onMounted, onBeforeMount, inject, onBeforeUnmount, toRaw} from "~/vue"
import {useFormField, defaultFieldProps} from "neloh-ui/mixins/use-form-field";
import {unref} from "vue";

const addModelDummy = {
    _open: true,
    medium_id: 70,
    content: null,
    uploadfile: null,
    sourceable: null
};

const usage_status_options = [
    {label: '-- Nutzungsstatus auswählen --', value: null},
    {label: 'Hat geholfen', value: 1},
    {label: 'Hat nicht geholfen (nicht hilfreich/erfolglos)', value: 2},
    {label: 'Für mich nicht verfügbar', value: 3},
]

export default defineComponent({
    name: "customsources",
    props: {
        ...defaultFieldProps,
    },
    components: {
        'upload-field': require('neloh-ui/components/forms/upload.vue').default
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const $events = inject('$events')


        const changefield = props.field.changefield
        const openSources = ref([])

        let cacheNewId = 0;
        const isMounted = ref(false);
        const usedModel = ref({
            customsources: [],
        });

        const lastPlaceholder = computed(() => {
            if (Array.isArray(usedModel.value.customsources)) {
                let max = 0;
                usedModel.value.customsources.forEach((r) => {
                    if (typeof r.placeholder === "string" && r.placeholder.match(/\[\d{1,}\]/g)) {
                        let i = parseInt(r.placeholder.replace(/\[(\d{1,})\]/g, '$1'));

                        if (i > max) {
                            max = i;
                        }
                    }
                })
                return max
            }

            return 0;
        });



        const mediums = computed(() => {
            if (!isMounted.value) return [];

            let all = [];

            if (Array.isArray(props.field.medium)) {

                _.each(props.field.medium, (r) => {
                    if (r.id) {
                        all.push({
                            label: r.title,
                            value: r.id
                        })
                    }
                });
            }

            return all;
        })


        function updateRealModels(n) {
            if (props.field.disabled) return;

            if (n) {
                if (n.hasOwnProperty('customsources')) {

                    let s = toRaw(_.cloneDeep(n.customsources));
                    s.sort((a, b) => {
                        return parseInt(a.placeholder) - parseInt(b.placeholder);
                    });

                    props.formModel.customsources = s;
                }
                context.emit('input');
            }
        }

        function updateLiteraturModel(value, row) {
            if (value) {
                row.title = value.title;
                row.sourceable = value;
            }
            else {
                row.title = null;
                row.uploadfile = null;
                row.sourceable = null;
            }
        }

        function updateFileModel(value, row, uploadFile = false) {
            if (value) {

                if (uploadFile) {
                    row.title = value.name;
                    row.uploadfile = value && value.fileData ? value.fileData : null;
                    row.sourceable = null;
                }
                else {
                    row.title = value.name;
                    row.uploadfile = null;
                    row.sourceable = value;
                }
            }
            else {
                row.title = null;
                    row.uploadfile = null;
                row.sourceable = null;
            }
        }



        function dropBrackets(value) {
            if (typeof value === "string") {
                let newVal = _.clone(value).trim();
                newVal = newVal.replace(/^\s*\[{1,}/g, '');
                newVal = newVal.replace(/\]{1,}$/g, '');

                return newVal;
            }
            return value;
        }

        function addBrackets(value) {
            if (typeof value === "string") {
                value = dropBrackets(value)
                return '['+ value.trim() +']';
            }
            else if (typeof value === "number") {
                return '['+ value +']';
            }
            return value;
        }

        function updatePlaceholderModel(evt, row) {


            if ( (evt.charCode < 48 || evt.charCode > 57) && (evt.charCode < 96 || evt.charCode > 105) ) {
                return;
            }


            if (evt.target.value) {
                let value = dropBrackets(evt.target.value);
                if (value && value !== '[]') {
                    value = addBrackets(value);

                    if (Array.isArray(usedModel.value['customsources'])) {
                        if (value) {
                            for (let i = 0; i < usedModel.value['customsources'].length; i++) {
                                let r = usedModel.value['customsources'][i]

                                let x = dropBrackets( r.placeholder );

                                if (x > 0 && (value === r.placeholder || x == value) && r.id !== row.id) {
                                    app.notify('Sie haben schon einen Platzhalter mit dieser bezeichnung! Bitte benutzen Sie einen anderen.', 'error', 2000);
                                    return;
                                }
                            }
                        }
                    }

                    const oldPlacholder = row.placeholder
                    row.placeholder = value;

                    updateContentPlaceholders(oldPlacholder, value);
                }
            }

        }

        function updateModel(value, key, row) {
            if (props.field.disabled) return;


            if (usedModel.value.hasOwnProperty(key)) {
                usedModel.value[key] = value;
            }

            if (key.match(/medium_id$/g) && typeof row === "object" && row ) {
                // reset
                if (row.hasOwnProperty('uploadfile')) {
                    row.uploadfile = null;
                }

                if (row.hasOwnProperty('sourceable')) {
                    row.sourceable = null;
                }
            }

        }

        function addModel() {
            if (props.field.disabled) return;

            if (usedModel.value.hasOwnProperty('customsources')) {
                cacheNewId++;
                let n = Object.assign(_.cloneDeep(addModelDummy), {
                    id: 'n'+ cacheNewId,
                    placeholder: '['+ (lastPlaceholder.value === 0 ? 1 : (lastPlaceholder.value + 1)) +']'
                });

                usedModel.value['customsources'].push(n);
                openSources.value.push(usedModel.value['customsources'].length - 1)
            }
        }

        function removeEntry(id, index) {
            if (props.field.disabled) return;

            if (usedModel.value.hasOwnProperty('customsources'))
            {
                closeOpenSource(index);

                usedModel.value['customsources'] = _.filter(_.cloneDeep(usedModel.value['customsources']), (r) => {
                    return r.id !== id;
                });
            }
        }

        function closeOpenSource(index) {
            if (openSources.value.indexOf(index) >= 0) {
                openSources.value.splice(openSources.value.indexOf(index), 1)
            }
        }

        function toggleOpenSource(index) {
            if (openSources.value.indexOf(index) < 0) {
                openSources.value.push(index)
            }
            else {
                openSources.value.splice(openSources.value.indexOf(index), 1)
            }
        }



        function hasSources() {
            return usedModel.value.hasOwnProperty('customsources') && Array.isArray(usedModel.value['customsources']) && usedModel.value['customsources'].length > 0
        }

        function onUpdateOrder(event) {
            let val = unref(_.cloneDeep(usedModel.value['customsources']));

            const old = val;

            let newPosEl = _.cloneDeep(old[event.newIndex]);
            let oldPosEl = _.cloneDeep(old[event.oldIndex]); // drag element

            // Den Platzhalter, an dem das Drag element geschoben wurde
            const newPlaceholder = val[event.newIndex]?.placeholder;

            // Den Platzhalter des Drag elements
            const oldPlaceholder = val[event.oldIndex]?.placeholder;

            if (newPlaceholder && oldPlaceholder) {

                val[event.oldIndex] = newPosEl;
                val[event.oldIndex].placeholder = oldPlaceholder;

                val[event.newIndex] = oldPosEl;
                val[event.newIndex].placeholder = newPlaceholder;
            }

            usedModel.value['customsources'] = Object.assign([], val);
            updateContentPlaceholders(oldPlaceholder, newPlaceholder);
        }

        function updateContentPlaceholders(oldPlaceholder, newPlaceholder) {
            if (newPlaceholder && oldPlaceholder) {
                if (changefield && props.formModel.hasOwnProperty(changefield)) {
                    let o = dropBrackets(oldPlaceholder);
                    let n = dropBrackets(newPlaceholder);

                    let str = props.formModel[changefield];

                    if (o && n && typeof str === "string" && str) {

                        const oldP = addBrackets( parseInt(o) );
                        const newP = addBrackets( parseInt(n) );

                        const regexOld = new RegExp('\\['+ parseInt(o) +'\\]', "g");
                        const regexNew = new RegExp('\\['+ parseInt(n) +'\\]', "g");

                        str = str.replace(regexOld, "[____TEMP]"); // replace old with temp placeholder
                        str = str.replace(regexNew, oldP);         // replace new placeholder with old placeholder
                        str = str.replace(/\[____TEMP\]/g, newP); // replace temp placeholder with new placeholder

                        props.formModel[changefield] = str;
                    }
                }
            }
        }


        function insertPlaceholder(row) {
            let id = null;
            if (row.id) {
                if (typeof row.id === "string" && !row.match(/^n/g)) {
                    id = parseInt(row.id);
                }
                else if (typeof row.id === "number") {
                    id = parseInt(row.id);
                }
            }

            let placeholderNumber = null;

            if (row.placeholder) {
                if (typeof row.placeholder === "string" && row.placeholder.trim().match(/^\d{1,}$/g)) {
                    placeholderNumber = parseInt(row.placeholder.trim());
                }
                else if (typeof row.placeholder === "string" && row.placeholder.trim().match(/^\[\d{1,}\]$/g)) {
                    placeholderNumber = parseInt(row.placeholder.trim().replace(/^\[(\d{1,})\]$/g, '$1'));
                }
                else if (typeof row.placeholder === "number") {
                    placeholderNumber = parseInt(row.placeholder);
                }
            }

            if (!placeholderNumber) {
                app.notify('Damit der Platzhalter eingefügt werden kann, muss dieser eine Nummer haben! ', 'info', 3000);
                return;
            }

            let answerField = document.querySelector('#field-answer');
            if (changefield && props.formModel.hasOwnProperty(changefield)) {
                let text = '['+ placeholderNumber +']';
                const position = answerField.selectionStart+1;

                let val = props.formModel[changefield]

                // Get the text before and after the cursor position
                const before = val.substring(0, position);
                const after = val.substring(position, val.length);

                // Insert the new text at the cursor position
                let newVal = before + text + after;
                answerField.value = newVal;

                // Set the cursor position to after the newly inserted text
                answerField.selectionStart = answerField.selectionEnd = position + text.length;

                // now update the model "answer"
                props.formModel[changefield] = newVal
            }
        }

        function deepEqual(x, y) {
            const ok = Object.keys, tx = typeof x, ty = typeof y;
            return x && y && tx === 'object' && tx === ty ? (
                ok(x).length === ok(y).length &&
                ok(x).every(key => deepEqual(x[key], y[key]))
            ) : (x === y);
        }


        watch(() => usedModel.value, (n, o) => {
            if (isMounted.value) {
                updateRealModels(n);
            }
        }, {deep: true});

        watch(() => props.formModel, (n, o) => {
            if (n && n.hasOwnProperty('customsources') && (typeof n.customsources === "object" || Array.isArray(n.customsources)) ) {
                if ( !deepEqual(n.customsources, usedModel.value.customsources) ) {

                    let s = n.customsources;
                    s.sort((a, b) => {
                        return parseInt(a.placeholder) - parseInt(b.placeholder);
                    });

                    usedModel.value.customsources = s; //toRaw(_.cloneDeep(n.customsources));
                    openSources.value = []
                }
            }
        }, {deep: true, immediate: true});

        onMounted(() => {
            isMounted.value = true;
        })

        return {
            mediums,
            usedModel,
            updateModel,
            addModel,
            removeEntry,
            hasSources,
            lastPlaceholder,
            usage_status_options,
            onUpdateOrder,
            openSources,
            toggleOpenSource,
            updatePlaceholderModel,
            updateLiteraturModel,
            updateFileModel,
            insertPlaceholder
        }
    }
});
</script>

<template>
    <div class="source-options">
        <!--  v-sortable="{handle: '.array-header > .title', delay: 100, draggable: '.array-item', onUpdate: onUpdateOrder}" -->
        <div class="array-items" v-sortable="{handle: '.array-header > .title', delay: 100, draggable: '.array-item', onUpdate: onUpdateOrder}">
            <template v-for="(r, index) in usedModel.customsources" :key="r.id">
                <div class="array-item" v-if="r && r.id" :class="{open: openSources.indexOf(index) >= 0 }">
                    <div class="array-header">
                        <div class="arrow" @click.prevent="toggleOpenSource(index)">
                            <svg-icon name="chevron-down"/>
                        </div>
                        <div class="placeholder" v-tooltip="'Diese Reference am Cursor einfügen'">
                            <template v-if="r.placeholder">
                                <span @click.prevent="insertPlaceholder(r)">[{{ r.placeholder.replace(/\[/g, '').replace(/\]/g, '') }}]</span>
                            </template>
                            <template v-else>[xyz]</template>
                        </div>
                        <div class="title" @click.prevent="toggleOpenSource(index)">
                            <div>{{ r.title || 'neuer Eintrag' }}</div>
                        </div>
                        <div class="remove-btn">
                            <svg-icon name="trash"
                                      v-tooltip="'Quelle entfernen'"
                                      @click.prevent="removeEntry(r.id, index)"/>
                        </div>
                    </div>
                    <div class="array-content">
                        <div class="source-fields">
                            <div class="placeholder-medium">


                                <div class="form-field fieldtype-text" id="field-container-placeholder">
                                    <div class="form-label">
                                        <label for="field-placeholder">
                                            <span>Platzhalter</span>
                                        </label>
                                    </div>
                                    <div class="form-field-container">
                                        <input type="text"
                                               name="placeholder"
                                               class="form-control"
                                               id="field-placeholder"
                                               :value="r.placeholder"
                                               @focus="(e) => { e.target.select() }"
                                               @input="(e) => {
                                                   updatePlaceholderModel(e, r);
                                                   e.target.value = r.placeholder
                                               }"
                                        >
                                    </div>
                                </div>



<!--                                <form-text name="placeholder"-->
<!--                                           :form-model="r"-->
<!--                                           :field="{-->
<!--                                                label: 'Platzhalter',-->
<!--                                                disabled: field.disabled,-->
<!--                                                attributes: {-->
<!--                                                    disabled: field.disabled,-->
<!--                                                }-->
<!--                                           }"-->
<!--                                           :responseerrors="responseerrors"-->
<!--                                           @input="(value) => updatePlaceholderModel(value, r)"></form-text>-->

                                <form-select name="medium_id"
                                             :form-model="r"
                                             :field="{
                                                 label: 'Medium', options: mediums,
                                                disabled: field.disabled,
                                                attributes: {
                                                    disabled: field.disabled,
                                                 }}"
                                             :responseerrors="responseerrors"
                                ></form-select>
                            </div>



                            <template v-if="r.medium_id === 1">
                                <!-- links -->

                                <form-select name="sourceable"
                                             :form-model="r"
                                             :field="{
                                     label: 'Link',
                                     route: 'admin.requests.autocomplete.links',
                                     autocomplete: true,
                                     value: r.sourceable,
                                     class: 'source',
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }
                                 }"
                                             :responseerrors="responseerrors"
                                             @input="(value) => updateLiteraturModel(value, r)">
                                </form-select>




                            </template>
                            <template v-else-if="r.medium_id === 2">
                                <!-- literatur -->

                                <div class="literature-control">
                                    <form-select name="sourceable"
                                                 :form-model="r"
                                                 :field="{
                                                     label: 'Literatur',
                                                     route: 'admin.requests.autocomplete.literatures',
                                                     autocomplete: true,
                                                     value: r.sourceable,
                                                     class: 'source',
                                                     disabled: field.disabled,
                                                        attributes: {
                                                            disabled: field.disabled,
                                                         }
                                                 }"
                                                 :responseerrors="responseerrors"
                                                 @input="(value) => updateLiteraturModel(value, r)">
                                    </form-select>

                                </div>




                            </template>
                            <template v-else-if="r.medium_id === 3">
                                <!-- file -->


                                <div class="file-control">
                                    <div class="file-field-container">
                                        <template v-if="!r.useUpload">
                                            <form-select
                                                name="sourceable"
                                                :form-model="r"
                                                :field="{
                                                     label: 'Anlage',
                                                     route: 'admin.requests.autocomplete.files',
                                                     autocomplete: true,
                                                     value: r.sourceable,
                                                     class: 'source',
                                                     disabled: field.disabled,
                                                        attributes: {
                                                            disabled: field.disabled,
                                                         }
                                                 }"
                                                :responseerrors="responseerrors"
                                                @input="(value) => updateFileModel(value, r)">
                                            </form-select>
                                        </template>
                                        <template v-else>
                                            <upload-field
                                                name="uploadfile"
                                                :form-model="r"
                                                :responseerrors="responseerrors"
                                                :field="{
                                                    label: 'Datei Upload',
                                                     class: 'source',
                                                     disabled: field.disabled,
                                                        attributes: {
                                                            disabled: field.disabled,
                                                         }
                                                }"
                                                @input="(value) => {
                                                    updateFileModel(value, r, true);
                                                }"
                                            ></upload-field>
                                        </template>
                                    </div>

                                    <div class="opt-buttons">
                                        <button type="button"
                                                class="btn btn-primary"
                                                :class="{'btn-disabled': field.disabled }"
                                                :disabled="field.disabled"
                                                v-tooltip="'Datei Upload'" v-if="!r.useUpload" @click.prevent="r.useUpload = true">
                                            <svg-icon name="upload"/>
                                        </button>

                                        <button type="button"
                                                class="btn btn-primary"
                                                :class="{'btn-disabled': field.disabled }"
                                                :disabled="field.disabled"
                                                v-tooltip="'Datei auswahl'" v-if="r.useUpload" @click.prevent="r.useUpload = false">
                                            <svg-icon name="file"/>
                                        </button>
                                    </div>


                                </div>





                            </template>
                            <template v-else>
                                <form-text :name="'customsources.'+ index +'.title'"
                                           :form-model="usedModel"
                                           :field="{
                                         label: 'Titel der Quelle',
                                         value: r.title,
                                         validation: 'required',
                                        disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }
                                     }"
                                           :responseerrors="responseerrors"
                                           @input="(value) => updateModel(value, 'customsources['+ index +'].title')"
                                ></form-text>
                                <form-textarea :name="'customsources.'+ index +'.content'"
                                               :form-model="usedModel"
                                               :responseerrors="responseerrors"
                                               :field="{
                                         label: 'Inhalt der Quelle',
                                         value: r.content,
                                         class: 'source',
                                          validation: 'required',
                                        disabled: field.disabled,
                                         attributes: {
                                             rows: 2,
                                        disabled: field.disabled,
                                         }
                                     }" @input="(value) => updateModel(value, 'customsources['+ index +'].content')"></form-textarea>
                            </template>


                            <form-select name="usage_status"
                                         :form-model="r"
                                         :field="{label: 'Nutzungsstatus', options: usage_status_options,
                                     disabled: field.disabled,
                                        attributes: {
                                            disabled: field.disabled,
                                         }}"></form-select>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="array-add">
            <button type="button"
                    class="btn btn-primary"
                    :class="{'btn-disabled': field.disabled }"
                    :disabled="field.disabled"
                    @click.prevent="addModel()">Quelle hinzufügen</button>
        </div>
    </div>
</template>

<style scoped>

</style>
