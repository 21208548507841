export default {
    computed: {
        '$route.meta.dirty'(n, o) {
            this.dirty = n;
        },

        hasDisableWhen() {
            return this.field.hasOwnProperty('disable_when') && !_.isEmpty(this.field.disable_when);
        },

        getDisableWhen() {
            if (this.hasDisableWhen && !_.isEmpty(this.field.disable_when)) {
                if (_.isObject(this.field.disable_when) || _.isArray(this.field.disable_when)) {
                    let validCount = 0;
                    let mustValidCount = _.size(this.field.disable_when);

                    return this.getCondition(this.field.disable_when, validCount) === mustValidCount;
                }
                else {
                    return this.getStringCondition(this.field.disable_when);
                }
            }

            return true
        },

        hasShowWhen() {
            return this.field.hasOwnProperty('show_when');
        },

        getShowWhen() {

            if (this.hasShowWhen && this.field.show_when) {

                if (_.isObject(this.field.show_when) || _.isArray(this.field.show_when)) {

                    let valid = 0;
                    let mustValidCount = _.size(this.field.show_when);
                    return this.getCondition(this.field.show_when, valid) === mustValidCount;
                }
                else {
                    if (this.field.show_when.match(/([\|,]+?)/g)) {
                        let conditions = this.field.show_when.split('|');
                        if (conditions.length > 1) {
                            // fieldname (0)


                            let hasShowValue;
                            let showValue;

                            if (this.hasFormModel) {
                                hasShowValue = this.hasOwnPropertyPath(this.formModel, conditions[0]);
                                showValue = this.getPropertyPath(this.formModel, conditions[0]);
                            }
                            else {
                                hasShowValue = this.hasOwnPropertyPath(this.$root.model, conditions[0]);
                                showValue = this.getPropertyPath(this.$root.model, conditions[0]);
                            }



                            if (hasShowValue) {

                                if (conditions.length === 3) {
                                    let operator = conditions[1];
                                    let value = conditions[2];
                                    if (value.match(/,/g)) {
                                        value = value.split(',');
                                    }
                                    else if (value && value.match(/^true$/ig) ) {
                                        value = true;
                                    }
                                    else if (value && value.match(/^false$/ig) ) {
                                        value = false;
                                    }


                                    if (_.isArray(value)) {
                                        switch (operator) {
                                            case 'empty':
                                                return _.isUndefined(showValue) || _.isEmpty(showValue);

                                            case 'notempty':
                                                return !_.isUndefined(showValue) || !_.isEmpty(showValue);

                                            case 'in':
                                            case 'exists':
                                                if (_.isArray(showValue) || _.isObject(showValue)) {
                                                    let found = false;
                                                    _.each(showValue, (v) => {
                                                        if (!found) {
                                                            _.each(value, (item) => {
                                                                if (item == v) {
                                                                    found = true;
                                                                }
                                                            });
                                                        }

                                                    });

                                                    return found ? showValue : null;
                                                }
                                                else {
                                                    let found = false;
                                                    _.each(value, (item) => {
                                                        if (!found) {
                                                            if (item == showValue) {
                                                                found = true;
                                                            }
                                                        }
                                                    });

                                                    return (found ? showValue : null);
                                                }

                                            case 'notin':
                                            case 'notexists':

                                                if (_.isArray(showValue) || _.isObject(showValue)) {
                                                    let found = false;
                                                    _.each(showValue, (v) => {
                                                        if (!found) {
                                                            _.each(value, (item) => {
                                                                if (item == v) {
                                                                    found = true;
                                                                }
                                                            });
                                                        }

                                                    });

                                                    return !found ? showValue : null;
                                                }
                                                else {
                                                    let found = false;
                                                    _.each(value, (item) => {
                                                        if (!found) {
                                                            if (item == showValue) {
                                                                found = true;
                                                            }
                                                        }
                                                    });

                                                    return (!found ? showValue : null);
                                                }
                                        }
                                    }
                                    else {
                                        switch (operator) {
                                            case '>':
                                                return (showValue > value ? showValue : null);
                                            case '<':
                                                return (showValue < value ? showValue : null);
                                            case '==':
                                                return (value == showValue ? showValue : null);
                                            case '===':
                                                return (value === showValue ? showValue : null);
                                            case '!=':
                                                return (value != showValue ? showValue : null);
                                            case '!==':
                                                return (value !== showValue ? showValue : null);
                                        }
                                    }
                                }
                                else if (conditions.length === 2) {

                                    if (conditions[1]) {
                                        switch (conditions[1]) {
                                            case 'empty':
                                                if (_.isUndefined(showValue)) return true;


                                                if (_.isNumber(showValue)) {
                                                    return showValue == 0;
                                                }
                                                else {
                                                    if (_.isArray(showValue)) {
                                                        return !showValue.length;
                                                    }
                                                    else if (_.isObject(showValue)) {
                                                        return !_.values(showValue).length;
                                                    }
                                                    else {
                                                        return _.isEmpty(showValue);
                                                    }
                                                }


                                            case 'notempty':


                                                if (_.isUndefined(showValue)) return false;

                                                if (_.isNumber(showValue)) {
                                                    return showValue > 0;
                                                }
                                                else {
                                                    if (_.isArray(showValue)) {
                                                        return showValue.length > 0;
                                                    }
                                                    else if (_.isObject(showValue)) {
                                                        return _.values(showValue).length > 0;
                                                    }
                                                    else {
                                                        return !_.isEmpty(showValue);
                                                    }
                                                }

                                            case 'exists':
                                                return !_.isUndefined(showValue) && showValue;

                                            case 'notexists':
                                                return _.isUndefined(showValue) || _.isEmpty(showValue);
                                        }
                                    }

                                    return (showValue == conditions[1] ? showValue : null);
                                }
                            }
                        }
                    }
                }

                return false;
            }

            return true;
        }
    },

    methods: {

        getCondition(conditionOptions, validCount) {
            let isEmpty;
            _.each(conditionOptions,(c) => {
                if (_.isString(c) && c)
                {

                    // console.log('Testing condition: '+ c)


                    let conditions = c.split('|');
                    if (conditions.length > 1) {
                        let hasShowValue;
                        let showValue;

                        if (this.hasFormModel) {
                            hasShowValue = this.hasOwnPropertyPath(this.formModel, conditions[0]);
                            showValue = this.getPropertyPath(this.formModel, conditions[0]);
                        }
                        else {
                            hasShowValue = this.hasOwnPropertyPath(this.$root.model, conditions[0]);
                            showValue = this.getPropertyPath(this.$root.model, conditions[0]);
                        }


                        let operator, value;
                        if (hasShowValue) {
                            if (conditions.length === 3)
                            {
                                operator = conditions[1];
                                value = conditions[2];

                                if (value.match(/,/g)) {
                                    value = value.split(',');
                                }
                                else if (value && value.match(/^true$/ig) ) {
                                    value = true;
                                }
                                else if (value && value.match(/^false$/ig) ) {
                                    value = false;
                                }

                                let found = false


                                if (_.isArray(value))
                                {
                                    switch (operator) {

                                        case 'in':
                                        case 'exists':
                                            found = false;


                                            if (_.isArray(showValue) || _.isObject(showValue)) {

                                                _.each(showValue, (v) => {
                                                    if (!found) {
                                                        _.each(value, (item) => {
                                                            if (item == v) {
                                                                found = true;
                                                            }
                                                        });
                                                    }
                                                });

                                                validCount = validCount + (found ? 1 : 0);
                                            }
                                            else {

                                                _.each(value, (item) => {
                                                    if (!found) {
                                                        if (item == showValue) {
                                                            found = true;
                                                        }
                                                    }
                                                });

                                                validCount = validCount + (found ? 1 : 0);
                                            }
                                            break;

                                        case 'notin':
                                        case 'notexists':
                                            found = false;
                                            if (_.isArray(showValue) || _.isObject(showValue)) {

                                                _.each(showValue, (v) => {
                                                    if (!found) {
                                                        _.each(value, (item) => {
                                                            if (item == v) {
                                                                found = true;
                                                            }
                                                        });
                                                    }

                                                });
                                                validCount = validCount + (!found ? 1 : 0);
                                            }
                                            else {
                                                _.each(value, (item) => {
                                                    if (!found) {
                                                        if (item == showValue) {
                                                            found = true;
                                                        }
                                                    }
                                                });

                                                validCount = validCount + (!found ? 1 : 0);
                                            }

                                            break;
                                    } // End switch
                                }
                                else {
                                    switch (operator) {
                                        case '>':
                                            validCount = validCount + (showValue > value ? 1 : 0);
                                            break;
                                        case '<':
                                            validCount = validCount + (showValue < value ? 1 : 0);
                                            break;
                                        case '==':
                                            validCount = validCount + (value == showValue ? 1 : 0);
                                            break;
                                        case '===':
                                            validCount = validCount + (value === showValue ? 1 : 0);
                                            break;
                                        case '!=':
                                            validCount = validCount + (value != showValue ? 1 : 0);
                                            break;
                                        case '!==':
                                            validCount = validCount + (value !== showValue ? 1 : 0);
                                            break;
                                    }
                                }

                            } // if condision length 3

                            else if (conditions.length === 2) {

                                switch (conditions[1]) {
                                    case 'exists':
                                    case 'notempty':


                                        if (_.isNumber(showValue)) {
                                            isEmpty = showValue > 0;
                                        }
                                        else {
                                            if (_.isArray(showValue)) {
                                                isEmpty = showValue.length > 0;
                                            }
                                            else if (_.isObject(showValue)) {
                                                isEmpty = _.values(showValue).length > 0;
                                            }
                                            else {
                                                isEmpty = !_.isEmpty(showValue);
                                            }
                                        }

                                        validCount = validCount + (isEmpty ? 1 : 0);
                                        break;

                                    case 'notexists':
                                    case 'empty':

                                        if (_.isNumber(showValue)) {
                                            isEmpty = showValue <= 0 ? true : false;
                                        }
                                        else {

                                            if (_.isArray(showValue)) {
                                                isEmpty = showValue.length === 0 ? true : false;
                                            }
                                            else {
                                                isEmpty = _.isEmpty(showValue);
                                            }


                                            // isEmpty = _.isEmpty(showValue);
                                        }

                                        validCount = validCount + (isEmpty === true ? 1 : 0);
                                        break;
                                }

                            }
                        }
                    }
                }
            });

            return validCount;
        },


        onComponentCreated() {
            if (this.hasShowWhen) {

                if (_.isObject(this.field.show_when) || _.isArray(this.field.show_when)) {

                    _.each(this.field.show_when, (when) => {
                        if (_.isString(when) && when.match(/([\|,]+?)/g)) {
                            let separator = '|';
                            if (!when.match(/\|/g)) {
                                separator = ',';
                            }
                            let conditions = when.split(separator);

                            if (this.hasFormModel) {
                                if (conditions[0] && !this.hasOwnPropertyPath(this.formModel, conditions[0])) {
                                    let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                    this.setPropertyPath(this.formModel, conditions[0], null, true);
                                    this.$route.meta.dirty = oldDirty;
                                }
                            }
                            else {
                                if (conditions[0] && !this.hasOwnPropertyPath(this.$root.model, conditions[0])) {
                                    let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                    this.setPropertyPath(this.$root.model, conditions[0], null, true);
                                    this.$route.meta.dirty = oldDirty;
                                }
                            }
                        }
                    });
                }
                else {
                    if (this.field.show_when.match(/([\|,]+?)/g)) {
                        let separator = '|';
                        if (!this.field.show_when.match(/\|/g)) {
                            separator = ',';
                        }

                        let conditions = this.field.show_when.split(separator);
                        if (this.hasFormModel) {
                            if (conditions[0]) {
                                if (!this.hasOwnPropertyPath(this.formModel, conditions[0])) {
                                    let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                    this.setPropertyPath(this.formModel, conditions[0], null, true);
                                    this.$route.meta.dirty = oldDirty;
                                }
                                else {

                                }
                            }

                        }
                        else {
                            if (conditions[0] && !this.hasOwnPropertyPath(this.$root.model, conditions[0])) {
                                let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                this.setPropertyPath(this.$root.model, conditions[0], null, true);
                                this.$route.meta.dirty = oldDirty;
                            }
                        }

                    }
                }
            }
        },


        onComponentMounted() {
            // //
            // this.onComponentCreated();
            //
            // this.$events.$on('form-model-changed', () => {
            //     this.$nextTick(() => {
            //         this.$root.$forceUpdate()
            //     })
            // })

            this.$nextTick(() => {
                if (this.hasShowWhen) {

                    if (_.isObject(this.field.show_when) || _.isArray(this.field.show_when)) {

                        _.each(this.field.show_when, (when) => {
                            if (_.isString(when) && when.match(/([\|,]+?)/g)) {
                                let separator = '|';
                                if (!when.match(/\|/g)) {
                                    separator = ',';
                                }
                                let conditions = when.split(separator);

                                if (this.hasFormModel) {
                                    if (conditions[0] && !this.hasOwnPropertyPath(this.formModel, conditions[0])) {
                                        let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                        this.setPropertyPath(this.formModel, conditions[0], null, true);
                                        this.$route.meta.dirty = oldDirty;

                                        this.$watch('formModel.' + conditions[0], (n, o) => {
                                            if (n !== o) {
                                                this.$forceUpdate();
                                            }
                                        }, {deep: true});
                                    } else if (conditions[0]) {
                                        this.$watch('formModel.' + conditions[0], (n, o) => {
                                            if (n !== o) {
                                                this.$forceUpdate();
                                            }
                                        }, {deep: true});
                                    }


                                } else {
                                    if (conditions[0] && !this.hasOwnPropertyPath(this.$root.model, conditions[0])) {
                                        let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                        this.setPropertyPath(this.$root.model, conditions[0], null, true);
                                        this.$route.meta.dirty = oldDirty;

                                        this.$watch('$root.model.' + conditions[0], (n, o) => {
                                            if (n !== o) {
                                                this.$forceUpdate();
                                            }
                                        }, {deep: true});
                                    } else if (conditions[0]) {
                                        this.$watch('$root.model.' + conditions[0], (n, o) => {
                                            if (n !== o) {
                                                this.$forceUpdate();
                                            }
                                        }, {deep: true});
                                    }
                                }

                            }
                        });
                    } else {
                        if (this.field.show_when.match(/([\|,]+?)/g)) {
                            let separator = '|';
                            if (!this.field.show_when.match(/\|/g)) {
                                separator = ',';
                            }


                            let conditions = this.field.show_when.split(separator);

                            if (this.hasFormModel) {
                                if (conditions[0] && !this.hasOwnPropertyPath(this.formModel, conditions[0])) {
                                    let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                    this.setPropertyPath(this.formModel, conditions[0], null, true);
                                    this.$route.meta.dirty = oldDirty;

                                    this.$watch('formModel.' + conditions[0], () => {
                                        this.$forceUpdate();
                                    }, {deep: true});
                                } else if (conditions[0]) {
                                    this.$watch('formModel.' + conditions[0], () => {
                                        this.$forceUpdate();
                                    }, {deep: true});
                                }
                            } else {
                                if (conditions[0] && !this.hasOwnPropertyPath(this.$root.model, conditions[0])) {
                                    let oldDirty = _.cloneDeep(this.$route.meta.dirty);
                                    this.setPropertyPath(this.$root.model, conditions[0], null, true);
                                    this.$route.meta.dirty = oldDirty;

                                    this.$watch('$root.model.' + conditions[0], () => {
                                        this.$forceUpdate();
                                    }, {deep: true});
                                } else if (conditions[0]) {
                                    this.$watch('$root.model.' + conditions[0], () => {
                                        this.$forceUpdate();
                                    }, {deep: true});
                                }
                            }


                        }
                    }
                }
            });
        },

        addModelConditionWatchers() {

        }

    }
}
