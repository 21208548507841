import LiteratureEdit from './components/LiteratureEdit.vue';
// import LiteraturePapersEdit from './components/LiteraturePapersEdit.vue';



export default [
    {
        path: '/literature',
        component: require('./components/LiteratureIndex.vue').default,
        name: 'admin.literature',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/literature/create',
        component: require('./components/LiteratureEdit.vue').default,
        name: 'admin.literature.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/literature/edit/:id',
        component: require('./components/LiteratureEdit.vue').default,
        name: 'admin.literature.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },






    {
        path: '/literature/papers',
        component: require('./components/LiteraturePapersIndex.vue').default,
        name: 'admin.literature.papers',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },
    {
        path: '/literature/papers/create',
        component: require('./components/LiteraturePapersEdit.vue').default,
        name: 'admin.literature.papers.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/literature/papers/edit/:id',
        component: require('./components/LiteraturePapersEdit.vue').default,
        name: 'admin.literature.papers.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },


    {
        path: '/literature/analyse',
        component: require('./components/LiteratureAnalyse.vue').default,
        name: 'admin.literature.analyse',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },

];
