<script>
import {defineComponent, ref} from 'vue'

export default defineComponent({
    name: "search-result-item",
    emits: ['scrollTop', 'details'],
    props: {
        item: {
            type: Object,
            required: true
        },
        searchModel: {
            type: Object,
            required: true
        }
    },
    setup(props, context) {
        const showDetails = ref(false);
        const detailsForRequestId = ref(null)

        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }

        function makeSourceLinks(value, entry) {

            if (typeof value === "string") {

                if (entry) {
                    if (entry.hasOwnProperty('customsources') && Array.isArray(entry.customsources) && entry.customsources.length) {
                        _.each(entry.customsources, (r) => {
                            if (r.placeholder) {
                                value = value.replace(new RegExp(escapeRegExp(r.placeholder), 'g'), '<a href="javascript: void(0)"' +
                                    ' data-customsources="customsource_'+ r.id +'">'+ r.placeholder +'</a>');
                            }
                        })
                    }

                    if ( Array.isArray(entry.links) && entry.links.length) {

                    }

                    if ( Array.isArray(entry.links) && entry.links.length) {

                    }
                }
            }

            return value;
        }

        function showRequestDetails(rowData, link) {
            context.emit('details', rowData);
        }

        function scrollTop(e) {
            context.emit('scrollTop', e);
        }

        function stripHtml(value) {
            let d = document.createElement('div');
            d.innerHTML = value;
            return (d.textContent || d.innerText);
        }

        function truncatedContent(value, limit = 300, dropHtml = false) {

            if (typeof value === "string") {
                if (dropHtml) {
                    value = stripHtml(value);
                }
                value = value.trim();
                value = value.length > limit ? value.substring(0, limit) +' ...' : value;
            }

            return value;
        }

        function entryHasSources(r) {
            if (r) {
                if ((r.hasOwnProperty('literatures') && Array.isArray(r.literatures) && r.literatures.length ) ||
                    (r.hasOwnProperty('links') && Array.isArray(r.links) && r.links.length) ||
                    (r.hasOwnProperty('customsources') && Array.isArray(r.customsources) && r.customsources.length) ) {
                    return true;
                }
            }

            return false
        }

        return {
            detailsForRequestId,
            showDetails,

            makeSourceLinks,
            showRequestDetails,
            scrollTop,
            truncatedContent,
            entryHasSources
        }
    }

})
</script>
<template>
    <div class="item">
        <div class="item-header">
            <div><b>Nr.:</b> {{ item.id }}</div>
            <div><a v-if="item.modul_key === 'requests:public' || item.modul_key === 'requests:local'" href="javascript: void(0)"
                    @click.prevent="showRequestDetails(item)">vollständiger Eintrag</a></div>
            <div><a href="javascript: void(0)" @click.prevent="scrollTop">Seitenanfang</a></div>
            <div class="percentage-score">Relevance: {{ item.percentage}} - {{ item.score }}</div>
        </div>

        <template v-if="item.modul_key === 'requests:public' || item.modul_key === 'requests:local'">
            <div class="item-row">
                <div class="item-location">
                    <div>Land:</div>
                    <div>
                        <template v-if="item.institution && item.institution.name">
                            <div>{{ item.institution.name }}</div>
                        </template>
                    </div>
                </div>

                <div class="item-dates">
                    <div>F-Datum/Stand:</div>
                    <div>
                        <span v-if="item.created_at">{{ formatDate(item.created_at) }}</span>
                        <span v-if="item.created_at && item.updated_at"> / </span>
                        <span v-if="item.updated_at">{{ formatDate(item.updated_at) }}</span>
                    </div>
                </div>
            </div>
            <div class="item-row">
                <div class="item-subjects">
                    <div>Thema:</div>
                    <div>
                        <template v-if="item.subjects">
                            <div v-for="t in item.subjects">
                                {{ t.label }}
                            </div>
                        </template>
                    </div>
                </div>

                <div class="item-subjects">
                    <div>Medium:</div>
                    <div>
                        <template v-if="Array.isArray(item.mediums) && item.mediums.length">
                            {{ item.mediums.join(', ') }}
                        </template>
                        <template v-else>-</template>
                    </div>
                </div>
            </div>
            <div class="item-row">
                <div class="item-keywords">
                    <div>Stichworte:</div>
                    <div>{{ item.keywords || '-' }}</div>
                </div>
            </div>
            <div class="item-row">
                <div class="item-question">
                    <div>Frage:</div>
                    <div>
                        <template v-if="searchModel.viewmode == 1">
                            <pre v-html="item.question_short ? item.question_short : item.question"></pre>
                        </template>
                        <template v-else-if="searchModel.viewmode == 2">
                            <pre v-html="truncatedContent(item.question_short ? item.question_short : item.question)"></pre>
                        </template>
                        <template v-else>
                            <pre v-html="item.question_short ? item.question_short : item.question"></pre>
                        </template>
                    </div>
                </div>
            </div>
            <div class="item-row" v-if="searchModel.viewmode < 3">
                <div class="item-answer">
                    <div>Antwort:</div>
                    <div>
                        <template v-if="!item.hasOwnProperty('answer')">
                            <em>Leider können wir Ihnen die Antwort nicht anzeigen, da Sie in den letzten 3 Jahren keinerlei Beteiligung hatten.</em>
                        </template>
                        <template v-else>
                            <template v-if="searchModel.viewmode == 1">
                                <pre v-html="makeSourceLinks(item.answer, item)"></pre>
                            </template>
                            <template v-else-if="searchModel.viewmode == 2">
                                <pre v-html="makeSourceLinks( truncatedContent(item.answer_short ? item.answer_short : item.answer), item )"></pre>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div class="item-row" v-if="entryHasSources(item) && searchModel.viewmode < 3">
                <div class="item-sources">
                    <div>genutzte Quellen:</div>
                    <div>

                        <template v-if="!item.hasOwnProperty('answer')">
                            <em>Leider können wir Ihnen die Quellen nicht anzeigen, da Sie in den letzten 3 Jahren keinerlei Beteiligung hatten.</em>
                        </template>
                        <template v-else>
                            <template v-if="Array.isArray(item.literatures) && item.literatures.length">
                                <div class="l">Literatur/-en</div>
                                <div v-for="(l, x) in item.literatures"
                                     v-if="l.sourceable" :data-source="'literatures_'+ l.sourceable.id">
                                    <span class="key">{{ l.sourceable.id }}</span>
                                    <span class="s">{{ l.sourceable.title }} {{ l.sourceable.vintage }}/{{ l.sourceable.issue_no }}
                                                <template v-if="l.sourceable.page > 0">
                                                    Seite {{ l.sourceable.page }}
                                                </template>
                                                <template v-if="l.sourceable.position">
                                                    {{ l.sourceable.position }}
                                                </template>
                                            </span>
                                </div>
                            </template>

                            <template v-if="Array.isArray(item.links) && item.links.length">
                                <div class="l">Link/-s</div>
                                <div v-for="(l, x) in item.links" v-if="l.sourceable" :data-source="'links_'+ l.sourceable.id">
                                    <span class="key">{{ l.sourceable.id }}</span>
                                    <a :href="l.sourceable.url" target="_blank">{{ l.sourceable.title || l.sourceable.url }}</a>
                                </div>
                            </template>

                            <template v-if="Array.isArray(item.customsources) && item.customsources.length">
                                <div class="l">Sonstige</div>
                                <div v-for="(s, x) in item.customsources" :data-source="'customsource_'+ s.id">
                                    <span class="key" v-if="s.placeholder">{{ s.placeholder }}</span>
                                    <template v-if="s.medium_id === 21">
                                        <a :href="s.content" target="_blank">{{ s.title || s.content }}</a>
                                    </template>
                                    <template v-else>
                                        <span class="s" v-if="s.content">{{ s.content }}</span>
                                        <span class="s" v-else-if="s.title">{{ s.title }}</span>
                                        <span class="s" v-else> --- </span>
                                    </template>
                                </div>
                            </template>

                        </template>



                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="item.modul_key === 'links'">
            <div class="item-row links">
                <div class="entry title">
                    <div>Titel:</div>
                    <div>
                        {{ item.title }}
                    </div>
                </div>
                <div class="entry url">
                    <div>Link:</div>
                    <div>
                        <a :href="item.url" target="_blank" v-if="item.url">{{ item.url }}</a>
                    </div>
                </div>
                <div class="entry provider">
                    <div>Anbieter:</div>
                    <div>
                        {{ item.provider }}
                    </div>
                </div>
                <div class="entry keywords">
                    <div>Stichworte:</div>
                    <div>
                        {{ item.keywords }}
                    </div>
                </div>
                <div class="entry description">
                    <div>Beschreibung:</div>
                    <div>
                        {{ item.description }}
                    </div>
                </div>

                <!--                                    <div class="item-dates">-->
                <!--                                        <div>F-Datum/Stand:</div>-->
                <!--                                        <div>-->
                <!--                                            <span v-if="item.created_at">{{ formatDate(item.created_at) }}</span>-->
                <!--                                            <span v-if="item.created_at && item.updated_at"> / </span>-->
                <!--                                            <span v-if="item.updated_at">{{ formatDate(item.updated_at) }}</span>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
            </div>
        </template>

        <template v-else-if="item.modul_key === 'literature'">
            <div class="item-row literature">
                <div class="entry title">
                    <div>Titel:</div>
                    <div>
                        {{ item.title }}
                    </div>
                </div>

                <div class="entry keywords">
                    <div>Autor:</div>
                    <div>
                        {{ item.author ? item.author : '-' }}
                    </div>
                </div>

                <div class="entry keywords">
                    <div>Stichworte:</div>
                    <div>
                        {{ item.keywords }}
                    </div>
                </div>

                <template v-if="item.newspaper">
                    <div class="entry newspaper">
                        <div>Quelle:</div>
                        <div>
                            {{ item.newspapeitem.title }} {{ item.vintage }} Heft-Nitem. {{ item.issue_no }}/{{ item.vintage }}
                        </div>
                    </div>
                </template>

                <div class="entry link" v-if="item.link">
                    <div>Volltext unter:</div>
                    <div>
                        <a :href="item.link" target="_blank">{{ item.link }}</a>
                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="item.modul_key === 'recipes'">
            <div class="item-row recipes">
                <div class="entry title">
                    <div>Titel:</div>
                    <div>
                        {{ item.name }}
                    </div>
                </div>
                <div class="entry url" v-if="item.url || item.vilfd_url">
                    <div>Link:</div>
                    <div>
                        <a v-if="item.url || item.vilfd_url" :href="item.url ? item.url : item.vilfd_url" target="_blank">{{ item.url ? item.url : item.vilfd_url }}</a>
                    </div>
                </div>
                <div class="entry dosage_form">
                    <div>Darreichungsform:</div>
                    <div>
                        {{ item.dosage_form }}
                    </div>
                </div>
                <div class="entry composition">
                    <div>Zusammensetzung:</div>
                    <div>
                        <pre>{{ item.composition }}</pre>
                    </div>
                </div>
                <div class="entry indication">
                    <div>Indikation:</div>
                    <div>
                        {{ item.indication || '-' }}
                    </div>
                </div>
                <div class="entry editorial_date">
                    <div>Stand:</div>
                    <div>
                        <span v-if="item.editorial_date">{{ formatDate(item.editorial_date) }}</span>
                    </div>
                </div>
                <div class="entry keywords">
                    <div>Stichworte:</div>
                    <div>
                        {{ item.keywords || '-' }}
                    </div>
                </div>
                <div class="entry risk">
                    <div>Bewertung:</div>
                    <div>
                        {{ item.risk || '-' }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>
