
export default [
    {
        path: '/users',
        component: require('./components/Index.vue').default,
        name: 'admin.users',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },

    {
        path: '/users/create',
        component: require('./components/UserEdit.vue').default,
        name: 'admin.users.create',
        meta: {
            title: '',
            nocache: false,
        }
    },

    {
        path: '/users/edit/:user',
        component: require('./components/UserEdit.vue').default,
        name: 'admin.users.edit',
        meta: {
            title: '',
            nocache: false,
        }
    },

    {
        path: '/account/settings',
        component: require('./components/AccountSettings.vue').default,
        name: 'admin.account.settings.edit',
        meta: {
            title: '',
            nocache: false,
        }
    },

    {
        path: '/account/password',
        component: require('./components/AccountPassword.vue').default,
        name: 'admin.account.password',
        meta: {
            title: '',
            nocache: false,
        }
    },

    {
        path: '/account/email',
        component: require('./components/AccountChangeEmail.vue').default,
        name: 'admin.account.email',
        meta: {
            title: '',
            nocache: false,
        }
    },

    {
        path: '/account/username',
        component: require('./components/AccountUsername.vue').default,
        name: 'admin.account.username',
        meta: {
            title: '',
            nocache: false,
        }
    },

    {
        path: '/account/personal-data',
        component: require('./components/AccountPersonalData.vue').default,
        name: 'admin.account.personal-data',
        meta: {
            title: '',
            nocache: false,
        }
    },





    {
        path: '/account/api-token',
        component: require('./components/AccountApiToken.vue').default,
        name: 'admin.account.api-token',
        meta: {
            title: '',
            nocache: false,
        }
    },
];
