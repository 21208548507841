const DataTableCache = {
    state: {
        cache: {},
        filters: {},
        loading: false
    },
    mutations: {
        SET_DATATABLE_CACHE(state, {path, data}) {
            path = path.replace(/\//g, '_');

            let d = _.cloneDeep(data);

            if (!state.cache.hasOwnProperty(path)) {
                state.cache[path] = d
            }
            else {
                state.cache[path] = Object.assign(state.cache[path], d);
            }
        },

        UPDATE_DATATABLE_CACHE(state, {path, data}) {
            path = path.replace(/\//g, '_');
            if (state.cache.hasOwnProperty(path)) {
                let d = _.cloneDeep(data);
                state.cache[path] = Object.assign(state.cache[path], d);
            }
        },


        UPDATE_DATATABLE_FILTER_CACHE(state, {path, data}) {
            path = path.replace(/\//g, '_');
            let d = _.cloneDeep(data);
            if (state.filters.hasOwnProperty(path)) {
                state.filters[path] = Object.assign(state.filters[path], d);
            }
            else {
                state.filters[path] = d;
            }
        },


        SET_TABLE_LOADING(state, data) {
            state.loading = data;
            
            let docContent = document.querySelector('.content-container > .content > .data-table');
            if (docContent) {
                docContent.scrollTo({top: 0, behavior: 'smooth'});
            }
        }
    },


    actions: {
        setDataTableLoading({ commit }, data) {
            commit('SET_TABLE_LOADING', data);
        },
        setDataTableCache({ commit }, {path, data}) {
            commit('SET_DATATABLE_CACHE', {path, data});
        },
        updateDataTableCache({ commit }, {path, data}) {
            commit('UPDATE_DATATABLE_CACHE', {path, data});
        },
        setFilterForDataTableCache({ commit }, {path, data}) {
            commit('UPDATE_DATATABLE_FILTER_CACHE', {path, data});
        }
    }
}
export default DataTableCache
