<template>
    <div class="vdp-datepicker form-control"
         :class="[wrapperClass, isRtl ? 'rtl' : '', disabled ? 'disabled' : '', {'has-date': value, 'focus':isOpen}]"
         ref="wrap">
        <date-input
            :selectedDate="selectedDate"
            :resetTypedDate="resetTypedDate"
            :format="format"
            :translation="translation"
            :inline="inline"
            :id="id"
            :name="name"
            :refName="refName"
            :openDate="openDate"
            :placeholder="placeholder"
            :inputClass="inputClass"
            :typeable="typeable"
            :clearButton="clearButton && !disabled"
            :clearButtonIcon="clearButtonIcon"
            :calendarButton="calendarButton"
            :calendarButtonIcon="calendarButtonIcon"
            :calendarButtonIconContent="calendarButtonIconContent"
            :disabled="disabled"
            :required="required"
            :bootstrapStyling="bootstrapStyling"
            :use-utc="useUtc"
            :monday-first="true"
            @showCalendar="showCalendar"
            @closeCalendar="close"
            @typedDate="setTypedDate"
            @clearDate="clearDate">
<!--            <slot name="afterDateInput">-->
<!--                <template v-slot:afterDateInput></template>-->
<!--            </slot>-->
        </date-input>

        <!--		<portal :key="name" to="app">-->
        <!--			<popper-->
        <!--				v-if="showDayView || showMonthView|| showYearView"-->
        <!--				:class="['picker', {open: showDayView || showMonthView|| showYearView }]"-->
        <!--				:referenceElm="$refs.wrap"-->
        <!--				:popperOptions="$popperProps.popperOptions"-->
        <!--				:arrowOffsetScaling="$popperProps.arrowOffsetScaling"-->
        <!--				:arrowPosition="$popperProps.arrowPosition"-->
        <!--			>-->

        <teleport to="#app">
            <div ref="picker" :class="['date-picker', 'picker', {open: showDayView || showMonthView|| showYearView }]">
                <div ref="pickerinner">
                    <div v-if="showDayView || showMonthView|| showYearView" class="arrow" data-popper-arrow></div>
                    <!-- Day View -->

                    <template v-if="allowedToShowView('day')">
                        <picker-day

                            :pageDate="pageDate"
                            :selectedDate="selectedDate"
                            :showDayView="showDayView"
                            :fullMonthName="fullMonthName"
                            :allowedToShowView="allowedToShowView"
                            :disabledDates="disabledDates"
                            :highlighted="highlighted"
                            :calendarClass="calendarClass"
                            :calendarStyle="calendarStyle"
                            :translation="translation"
                            :pageTimestamp="pageTimestamp"
                            :isRtl="isRtl"
                            :mondayFirst="mondayFirst"
                            :dayCellContent="dayCellContent"
                            :use-utc="useUtc"
                            :monday-first="true"
                            @changedMonth="handleChangedMonthFromDayPicker"
                            @selectDate="selectDate"
                            @showMonthCalendar="showMonthCalendar"
                            @selectedDisabled="selectDisabledDate">
                            <!--                        <template v-slot:beforeCalendarHeader>-->
                            <!--                            <slot name="beforeCalendarHeader"/>-->
                            <!--                        </template>-->
                        </picker-day>
                    </template>



                    <!-- Month View -->
                    <template v-if="allowedToShowView('month')">
                        <picker-month
                            :pageDate="pageDate"
                            :selectedDate="selectedDate"
                            :showMonthView="showMonthView"
                            :allowedToShowView="allowedToShowView"
                            :disabledDates="disabledDates"
                            :calendarClass="calendarClass"
                            :calendarStyle="calendarStyle"
                            :translation="translation"
                            :isRtl="isRtl"
                            :use-utc="useUtc"
                            :monday-first="true"
                            @selectMonth="selectMonth"
                            @showYearCalendar="showYearCalendar"
                            @changedYear="setPageDate">
                            <!--                        <template v-slot:beforeCalendarHeader>-->
                            <!--                            <slot name="beforeCalendarHeader"/>-->
                            <!--                        </template>-->
                        </picker-month>
                    </template>

                    <!-- Year View -->
                    <template v-if="allowedToShowView('year')">
                        <picker-year
                            :pageDate="pageDate"
                            :selectedDate="selectedDate"
                            :showYearView="showYearView"
                            :allowedToShowView="allowedToShowView"
                            :disabledDates="disabledDates"
                            :calendarClass="calendarClass"
                            :calendarStyle="calendarStyle"
                            :translation="translation"
                            :isRtl="isRtl"
                            :use-utc="useUtc"
                            :monday-first="true"
                            @selectYear="selectYear"
                            @changedDecade="setPageDate">
                            <!--                        <template v-slot:beforeCalendarHeader>-->
                            <!--                            <slot name="beforeCalendarHeader"/>-->
                            <!--                        </template>-->
                        </picker-year>
                    </template>


                </div>


            </div>
        </teleport>

        <!--			</popper>-->
        <!--		</portal>-->


    </div>
</template>

<script>
import en from './locale/translations/en'
import DateInput from './DateInput'
import PickerDay from './PickerDay'
import PickerMonth from './PickerMonth'
import PickerYear from './PickerYear'
import utils, {makeDateUtils} from './utils/DateUtils'
import {createPopper} from '~/@popperjs/core';
import {setStyle} from '@/utils/popper-utils'
import {ref, watch, computed, onBeforeUnmount, onMounted, nextTick} from "vue";
import {getCurrentInstance, inject} from "vue";
import preventOverflow from "~/@popperjs/core/lib/modifiers/preventOverflow.js";
import flip from "~/@popperjs/core/lib/modifiers/flip.js";
import offset from "~/@popperjs/core/lib/modifiers/offset";
const toInt = x => parseInt(x, 10);
const popperProps = {
    strategy: 'fixed',
    // flipVariations: true,
    // rootBoundary: document.querySelector('#app'),
    modifiers: [
        {
            name: 'preventOverflow',
            options: {
                altAxis: true,
                tether: true,
                boundary: document.querySelector('#app'),
            },
        },
        {
            name: 'offset',
            options: {
                offset: [0, 5],
            },
        },
        {
            name: 'flip',
            enabled: true,
            options: {
                fallbackPlacements: ['top', 'bottom', 'right', 'left'],
            },
        },
        // {
        //     name: 'computeStyle',
        //     enabled: true,
        //     phase: 'beforeWrite',
        //     fn: ({state}) => {
        //         let rect = state.rects.reference;
        //         let inner = state.elements.popper.querySelector('.vdp-datepicker__calendar')
        //         let left = (rect.x + (rect.width / 2)) - (inner.offsetWidth/2);
        //         let arrowLeft = (inner.offsetWidth / 2) - 5;
        //
        //
        //         let top = rect.y + rect.height + 5
        //         if (top + inner.offsetHeight > document.body.offsetHeight) {
        //             top = rect.y - (inner.offsetHeight + 5);
        //         }
        //
        //         state.styles.arrow = {
        //             ...state.styles.arrow,
        //             left: `${arrowLeft}px`,
        //         }
        //
        //         // state.styles.popper = {
        //         //     ...state.styles.popper,
        //         //     position: 'fixed',
        //         //     left: `${left}px`,
        //         //     top: `${top}px`,
        //         //     transform: 'translateY(-50%)',
        //         // }
        //
        //         return state
        //     },
        // },

    ]
}



export default {
    name: "laravision-datepicker",
    emits: ['selected', 'input', 'showCalendar', 'closeCalendar', 'selectDate', 'closed', 'clearDate', 'cleared', 'selectedDisabled', 'changedMonth', 'changedYear'],
    components: {
        DateInput,
        PickerDay,
        PickerMonth,
        PickerYear,
        // 'popper': VuePopper
    },
    props: {
        popperProps: {
            type: Object,
            default: {
                placement: 'bottom',
            }
        },
        value: {
            validator: val => utils.validateDateInput(val)
        },
        name: String,
        refName: String,
        id: String,
        format: {
            type: [String, Function],
            default: 'dd MMM yyyy'
        },
        language: {
            type: Object,
            default: () => en
        },
        openDate: {
            validator: val => utils.validateDateInput(val)
        },
        dayCellContent: Function,
        fullMonthName: Boolean,
        disabledDates: Object,
        highlighted: Object,
        placeholder: String,
        inline: Boolean,
        calendarClass: [String, Object, Array],
        inputClass: [String, Object, Array],
        wrapperClass: [String, Object, Array],
        mondayFirst: Boolean,
        clearButton: Boolean,
        clearButtonIcon: String,
        calendarButton: Boolean,
        calendarButtonIcon: String,
        calendarButtonIconContent: String,
        bootstrapStyling: Boolean,
        initialView: String,
        disabled: Boolean,
        required: Boolean,
        typeable: {
            type: Boolean,
            default: false
        },
        useUtc: Boolean,
        minimumView: {
            type: String,
            default: 'day'
        },
        maximumView: {
            type: String,
            default: 'year'
        }
    },
    setup(props, context) {

        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const $events = inject('$events');

        let popperJs = null;

        const startDate = props.openDate ? new Date(props.openDate) : new Date()
        const resetTypedDate = ref(new Date())
        const constructedDateUtils = makeDateUtils(props.useUtc)
        const pageTimestamp = ref(constructedDateUtils.setDate(startDate, 1))
        const utils = constructedDateUtils;

        /*
         * Selected Date
         * {Date}
         */
        const selectedDate = ref(null);

        /*
         * Flags to show calendar views
         * {Boolean}
         */
        const showDayView = ref(false);
        const showMonthView = ref(false);
        const showYearView = ref(false);

        /*
         * Positioning
         */
        const calendarHeight = ref(0);


        watch(() => props.value, (n, o) => {
            if (n !== o) {
                setValue(n)
            }
        });

        watch(() => props.openDate, (n, o) => {
            if (n !== o) {
                setPageDate()
            }
        });

        watch(() => props.initialView, (n, o) => {
            if (n !== o) {
                setInitialView()
            }
        });

        const computedInitialView = computed(() => {
            if (!props.initialView) {
                return props.minimumView
            }
            return props.initialView
        })

        const pageDate = computed(() => {
            return new Date(pageTimestamp.value)
        })

        const translation = computed(() => {
            return props.language
        })

        const calendarStyle = computed(() => {
            return {
                position: props.isInline ? 'static' : undefined
            }
        })
        const isOpen = computed(() => {
            return showDayView.value || showMonthView.value || showYearView.value
        })

        const isInline = computed(() => {
            return props.inline === true
        })

        const isRtl = computed(() => {
            return false
        })


        onBeforeUnmount(() => {
            destroyPopper()
            if (inst.refs.wrap && inst.refs.picker) {
                inst.refs.wrap.appendChild(inst.refs.picker);
            }
            $events.$off('close-datepickers', close);
        })

        onMounted(() => {
            setStyle();
            init();
        });


        function createPopperInstance() {

            destroyPopper()

            // const customBoundary = this.$root.$el;

            let appEl = document.querySelector('#app');
            let opts = Object.assign(popperProps, props.popperProps);

            if (appEl) {
                appEl.appendChild(inst.refs.picker);
            } else {
                $root.$el.appendChild(inst.refs.picker);
            }

            popperJs = createPopper(inst.vnode.el, inst.refs.picker, opts);

            nextTick(() => {
                updatePopper()
            })
        }

        function updatePopper() {
            if (popperJs) {
                popperJs.update()
            }
        }

        function destroyPopper() {
            if (popperJs) {
                popperJs.destroy()
                popperJs = null;
            }
        }


        /**
         * Called in the event that the user navigates to date pages and
         * closes the picker without selecting a date.
         */
        function resetDefaultPageDate() {
            if (selectedDate.value === null) {
                setPageDate()
                return
            }

            setPageDate(selectedDate.value)
        }

        /**
         * Effectively a toggle to show/hide the calendar
         * @return {mixed}
         */
        function showCalendar() {
            if (props.disabled || props.isInline) {
                return false
            }

            $events.$emit('close-datepickers');

            if (isOpen.value)
            {
                return close(true)
            }

            setInitialView();

            nextTick(() => {
                createPopperInstance();

                nextTick(() => {
                    updatePopper();
                });
            })
        }

        /**
         * Sets the initial picker page view: day, month or year
         */
        function setInitialView() {
            const _initialView = computedInitialView.value;

            if (!allowedToShowView(_initialView)) {
                throw new Error(`initialView '${props.initialView}' cannot be rendered based on minimum '${props.minimumView}' and maximum '${props.maximumView}'`)
            }

            switch (_initialView) {
                case 'year':
                    showYearCalendar()
                    break
                case 'month':
                    showMonthCalendar()
                    break
                default:
                    showDayCalendar()
                    break
            }
        }

        /**
         * Are we allowed to show a specific picker view?
         * @param {String} view
         * @return {Boolean}
         */
        function allowedToShowView(view) {
            const views = ['day', 'month', 'year']
            const minimumViewIndex = views.indexOf(props.minimumView)
            const maximumViewIndex = views.indexOf(props.maximumView)
            const viewIndex = views.indexOf(view)
            return viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex
        }

        /**
         * Show the day picker
         * @return {Boolean}
         */
        function showDayCalendar() {
            if (!allowedToShowView('day')) {
                return false
            }
            close()
            showDayView.value = true
            return true
        }

        /**
         * Show the month picker
         * @return {Boolean}
         */
        function showMonthCalendar() {
            if (!allowedToShowView('month')) {
                return false
            }
            close()
            showMonthView.value = true
            return true
        }

        /**
         * Show the year picker
         * @return {Boolean}
         */
        function showYearCalendar() {
            if (!allowedToShowView('year')) {
                return false
            }
            close()
            showYearView.value = true
            return true
        }

        /**
         * Set the selected date
         * @param {Number} timestamp
         */
        function setDate(timestamp) {
            const date = new Date(timestamp)
            selectedDate.value = date
            setPageDate(date)
            context.emit('selected', date)
            context.emit('input', date)
        }


        /**
         * Clear the selected date
         */
        function clearDate() {
            selectedDate.value = null
            setPageDate()
            context.emit('selected', null)
            context.emit('input', null)
            context.emit('cleared')
        }

        /**
         * @param {Object} date
         */
        function selectDate(date) {
            setDate(date.timestamp)
            if (!isInline.value) {
                close(true)
            }
            resetTypedDate.value = new Date()
        }

        /**
         * @param {Object} date
         */
        function selectDisabledDate(date) {
            context.emit('selectedDisabled', date)
        }

        /**
         * @param {Object} month
         */
        function selectMonth(month) {
            const date = new Date(month.timestamp)
            if (allowedToShowView('day')) {
                setPageDate(date)
                context.emit('changedMonth', month)
                showDayCalendar()
            } else {
                selectDate(month)
            }
        }

        /**
         * @param {Object} year
         */
        function selectYear(year) {
            const date = new Date(year.timestamp)
            if (allowedToShowView('month')) {
                setPageDate(date)
                context.emit('changedYear', year)
                showMonthCalendar()
            } else {
                selectDate(year)
            }
        }

        /**
         * Set the datepicker value
         * @param {Date|String|Number|null} date
         */
        function setValue(date) {
            if (typeof date === 'string' || typeof date === 'number') {
                let parsed = new Date(date)
                date = isNaN(parsed.valueOf()) ? null : parsed
            }
            if (!date) {
                setPageDate()
                selectedDate.value = null
                return
            }
            selectedDate.value = date
            setPageDate(date)
        }

        /**
         * Sets the date that the calendar should open on
         */
        function setPageDate(date) {
            if (!date) {
                if (props.openDate) {
                    date = new Date(props.openDate)
                } else {
                    date = new Date()
                }
            }
            pageTimestamp.value = utils.setDate(new Date(date), 1)
        }

        /**
         * Handles a month change from the day picker
         */
        function handleChangedMonthFromDayPicker(date) {
            setPageDate(date)
            context.emit('changedMonth', date)
        }

        /**
         * Set the date from a typedDate event
         */
        function setTypedDate(date) {
            setDate(date.getTime())
        }

        /**
         * Close all calendar layers
         * @param {Boolean} emitEvent - emit close event
         */
        function close(emitEvent) {
            showDayView.value = false;
                showMonthView.value = false;
                    showYearView.value = false;

            if (!isInline.value) {
                if (emitEvent) {
                    context.emit('closed')
                }

            }
        }

        function clickOutside(evt) {
            if (showDayView.value || showMonthView.value || showYearView.value) {
                if (!evt.target.closest('.vdp-datepicker') &&
                    !evt.target.closest('.vdp-datepicker__calendar')) {
                    close(true);
                }
            }
        }

        function bindClickOutside() {
            document.addEventListener('click', clickOutside, false);
            $events.$on('close-datepickers', close);
        }

        /**
         * Initiate the component
         */
        function init() {
            if (props.value) {
                setValue(props.value)
            }

            if (isInline.value) {
                setInitialView()
            }
            else {
                bindClickOutside()
            }
        }



        return {
            isRtl,
            selectedDate,
            resetTypedDate,
            translation,
            isOpen,
            showCalendar,
            setTypedDate,
            clearDate,

            showDayView,
            showMonthView,
            showYearView,

            allowedToShowView,
            pageDate,

            calendarStyle,
            pageTimestamp,

            close,
            handleChangedMonthFromDayPicker,
            selectDate,
            showMonthCalendar,
            selectDisabledDate,

            selectMonth,
            showYearCalendar,
            setPageDate,
            selectYear,

        }

    },


    //
    // data() {
    //     const startDate = this.openDate ? new Date(this.openDate) : new Date()
    //     const constructedDateUtils = makeDateUtils(this.useUtc)
    //     const pageTimestamp = constructedDateUtils.setDate(startDate, 1)
    //     return {
    //         popperJs: null,
    //         /*
    //          * Vue cannot observe changes to a Date Object so date must be stored as a timestamp
    //          * This represents the first day of the current viewing month
    //          * {Number}
    //          */
    //         pageTimestamp,
    //         /*
    //          * Selected Date
    //          * {Date}
    //          */
    //         selectedDate: null,
    //         /*
    //          * Flags to show calendar views
    //          * {Boolean}
    //          */
    //         showDayView: false,
    //         showMonthView: false,
    //         showYearView: false,
    //         /*
    //          * Positioning
    //          */
    //         calendarHeight: 0,
    //         resetTypedDate: new Date(),
    //         utils: constructedDateUtils,
    //         defaultPopperProps: {
    //             arrowPosition: 'middle',
    //             strategy: 'fixed',
    //             arrowOffsetScaling: 1,
    //             placement: 'bottom-start',
    //             modifiers: [
    //                 {
    //                     name: 'offset',
    //                     options: {
    //                         offset: [0, 0],
    //                     },
    //                 },
    //                 {
    //                     name: 'computeStyles',
    //                     options: {
    //                         gpuAcceleration: false, // true by default
    //                     },
    //                 },
    //                 {
    //                     name: 'flip',
    //                     options: {
    //                         rootBoundary: document.body,
    //                         padding: 10,
    //                         // rootBoundary: this.$root.$el,
    //                         boundary: document.body,
    //                         flipVariations: true,
    //                         fallbackPlacements: ['top', 'bottom', 'right'],
    //                         allowedAutoPlacements: ['top', 'bottom', 'right']
    //                     },
    //                 },
    //                 {
    //                     name: 'preventOverflow',
    //                     options: {
    //                         padding: 8,
    //                         altAxis: true,
    //                         rootBoundary: document.body,
    //                         boundary: document.body,
    //                     },
    //                 },
    //             ],
    //         },
    //
    //         defaultPopperProps0: Object.freeze({
    //             arrowPosition: 'middle',
    //             arrowOffsetScaling: 1,
    //             popperOptions: {
    //                 arrowPosition: 'middle',
    //                 arrowOffsetScaling: 1,
    //                 positionFixed: true,
    //             },
    //         }),
    //     }
    // },
    // watch: {
    //     value(value) {
    //         this.setValue(value)
    //     },
    //     openDate() {
    //         this.setPageDate()
    //     },
    //     initialView() {
    //         this.setInitialView()
    //     }
    // },
    // computed: {
    //     $popperProps() {
    //         return Object.assign({}, this.defaultPopperProps, this.popperProps)
    //     },
    //     computedInitialView() {
    //         if (!this.initialView) {
    //             return this.minimumView
    //         }
    //         return this.initialView
    //     },
    //     pageDate() {
    //         return new Date(this.pageTimestamp)
    //     },
    //     translation() {
    //         return this.language
    //     },
    //     calendarStyle() {
    //         return {
    //             position: this.isInline ? 'static' : undefined
    //         }
    //     },
    //     isOpen() {
    //         return this.showDayView || this.showMonthView || this.showYearView
    //     },
    //     isInline() {
    //         return !!this.inline
    //     },
    //     isRtl() {
    //         return this.translation.rtl === true
    //     }
    // },
    //
    // beforeUnmount() {
    //     this.destroyPopper()
    //
    //     if (this.$refs.wrap && this.$refs.picker) {
    //         this.$refs.wrap.appendChild(this.$refs.picker);
    //     }
    // },
    //
    // mounted() {
    //     setStyle();
    //     this.init();
    //
    // },
    // methods: {
    //
    //     createPopper() {
    //         this.destroyPopper()
    //
    //         // const customBoundary = this.$root.$el;
    //
    //         let appEl = document.querySelector('#app');
    //
    //         let opts = this.$popperProps;
    //         if (appEl) {
    //             appEl.appendChild(this.$refs.picker);
    //         } else this.$root.$el.appendChild(this.$refs.picker);
    //
    //         this.popperJs = createPopper(this.$el, this.$refs.picker, opts);
    //     },
    //     updatePopper() {
    //         if (this.popperJs) this.popperJs.scheduleUpdate()
    //     },
    //     destroyPopper() {
    //         if (this.popperJs) this.popperJs.destroy()
    //     },
    //
    //
    //     /**
    //      * Called in the event that the user navigates to date pages and
    //      * closes the picker without selecting a date.
    //      */
    //     resetDefaultPageDate() {
    //         if (this.selectedDate === null) {
    //             this.setPageDate()
    //             return
    //         }
    //         this.setPageDate(this.selectedDate)
    //     },
    //     /**
    //      * Effectively a toggle to show/hide the calendar
    //      * @return {mixed}
    //      */
    //     showCalendar() {
    //         if (this.disabled || this.isInline) {
    //             return false
    //         }
    //         if (this.isOpen) {
    //             return this.close(true)
    //         }
    //         this.setInitialView();
    //         this.$nextTick(() => {
    //             this.createPopper();
    //         })
    //     },
    //     /**
    //      * Sets the initial picker page view: day, month or year
    //      */
    //     setInitialView() {
    //         const initialView = this.computedInitialView
    //         if (!this.allowedToShowView(initialView)) {
    //             throw new Error(`initialView '${this.initialView}' cannot be rendered based on minimum '${this.minimumView}' and maximum '${this.maximumView}'`)
    //         }
    //         switch (initialView) {
    //             case 'year':
    //                 this.showYearCalendar()
    //                 break
    //             case 'month':
    //                 this.showMonthCalendar()
    //                 break
    //             default:
    //                 this.showDayCalendar()
    //                 break
    //         }
    //     },
    //     /**
    //      * Are we allowed to show a specific picker view?
    //      * @param {String} view
    //      * @return {Boolean}
    //      */
    //     allowedToShowView(view) {
    //         const views = ['day', 'month', 'year']
    //         const minimumViewIndex = views.indexOf(this.minimumView)
    //         const maximumViewIndex = views.indexOf(this.maximumView)
    //         const viewIndex = views.indexOf(view)
    //         return viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex
    //     },
    //     /**
    //      * Show the day picker
    //      * @return {Boolean}
    //      */
    //     showDayCalendar() {
    //         if (!this.allowedToShowView('day')) {
    //             return false
    //         }
    //         this.close()
    //         this.showDayView = true
    //         return true
    //     },
    //     /**
    //      * Show the month picker
    //      * @return {Boolean}
    //      */
    //     showMonthCalendar() {
    //         if (!this.allowedToShowView('month')) {
    //             return false
    //         }
    //         this.close()
    //         this.showMonthView = true
    //         return true
    //     },
    //     /**
    //      * Show the year picker
    //      * @return {Boolean}
    //      */
    //     showYearCalendar() {
    //         if (!this.allowedToShowView('year')) {
    //             return false
    //         }
    //         this.close()
    //         this.showYearView = true
    //         return true
    //     },
    //     /**
    //      * Set the selected date
    //      * @param {Number} timestamp
    //      */
    //     setDate(timestamp) {
    //         const date = new Date(timestamp)
    //         this.selectedDate = date
    //         this.setPageDate(date)
    //         this.$emit('selected', date)
    //         this.$emit('input', date)
    //     },
    //     /**
    //      * Clear the selected date
    //      */
    //     clearDate() {
    //         this.selectedDate = null
    //         this.setPageDate()
    //         this.$emit('selected', null)
    //         this.$emit('input', null)
    //         this.$emit('cleared')
    //     },
    //     /**
    //      * @param {Object} date
    //      */
    //     selectDate(date) {
    //         this.setDate(date.timestamp)
    //         if (!this.isInline) {
    //             this.close(true)
    //         }
    //         this.resetTypedDate = new Date()
    //     },
    //     /**
    //      * @param {Object} date
    //      */
    //     selectDisabledDate(date) {
    //         this.$emit('selectedDisabled', date)
    //     },
    //     /**
    //      * @param {Object} month
    //      */
    //     selectMonth(month) {
    //         const date = new Date(month.timestamp)
    //         if (this.allowedToShowView('day')) {
    //             this.setPageDate(date)
    //             this.$emit('changedMonth', month)
    //             this.showDayCalendar()
    //         } else {
    //             this.selectDate(month)
    //         }
    //     },
    //     /**
    //      * @param {Object} year
    //      */
    //     selectYear(year) {
    //         const date = new Date(year.timestamp)
    //         if (this.allowedToShowView('month')) {
    //             this.setPageDate(date)
    //             this.$emit('changedYear', year)
    //             this.showMonthCalendar()
    //         } else {
    //             this.selectDate(year)
    //         }
    //     },
    //     /**
    //      * Set the datepicker value
    //      * @param {Date|String|Number|null} date
    //      */
    //     setValue(date) {
    //         if (typeof date === 'string' || typeof date === 'number') {
    //             let parsed = new Date(date)
    //             date = isNaN(parsed.valueOf()) ? null : parsed
    //         }
    //         if (!date) {
    //             this.setPageDate()
    //             this.selectedDate = null
    //             return
    //         }
    //         this.selectedDate = date
    //         this.setPageDate(date)
    //     },
    //     /**
    //      * Sets the date that the calendar should open on
    //      */
    //     setPageDate(date) {
    //         if (!date) {
    //             if (this.openDate) {
    //                 date = new Date(this.openDate)
    //             } else {
    //                 date = new Date()
    //             }
    //         }
    //         this.pageTimestamp = this.utils.setDate(new Date(date), 1)
    //     },
    //     /**
    //      * Handles a month change from the day picker
    //      */
    //     handleChangedMonthFromDayPicker(date) {
    //         this.setPageDate(date)
    //         this.$emit('changedMonth', date)
    //     },
    //     /**
    //      * Set the date from a typedDate event
    //      */
    //     setTypedDate(date) {
    //         this.setDate(date.getTime())
    //     },
    //     /**
    //      * Close all calendar layers
    //      * @param {Boolean} emitEvent - emit close event
    //      */
    //     close(emitEvent) {
    //         this.showDayView = this.showMonthView = this.showYearView = false;
    //         if (!this.isInline) {
    //             if (emitEvent) {
    //                 this.$emit('closed')
    //             }
    //             document.removeEventListener('click', this.clickOutside, false)
    //         }
    //     },
    //     /**
    //      * Initiate the component
    //      */
    //     init() {
    //         if (this.value) {
    //             this.setValue(this.value)
    //         }
    //         if (this.isInline) {
    //             this.setInitialView()
    //         }
    //     }
    // },
}
</script>
<!--<style scoped>-->
<!--    @import '~@livelybone/vue-popper/lib/css/index.css';-->
<!--</style>-->
