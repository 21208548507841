<template>
    <div class="nestable-wrapper">
        <div class="nestable-header" :style="computedHeaderStyle" v-if="$slots.header">
            <slot name="header"></slot>
        </div>
        <div ref="nestable_tree" class="nestable-tree" :style="getHeight">
            <vue-nestable v-model:value="mutatedData"
                          :key-prop="keyName"
                          :parent-key-prop="parentKeyName"
                          :children-prop="childKeyName"
                          :hooks="{
                                'beforeMove': beforeMove
                              }"
                          :max-depth="maxDepth"
                          :group="groupname"
                          @input="updateFromInput"
                          @change="(item, options, oldItems) => { changeTreePosition(item, options, oldItems) }"
            >
                <template v-slot:placeholder>
                    <slot name="placeholder">
                        No content
                    </slot>
                </template>

                <template v-slot:default="{ item, index, isChild, deepCount }">
                    <vue-nestable-handle :item="item" v-bind:index="numericIndex(index)">
                        <svg-icon :name="isLoading(item) ? 'fa-refresh' : 'grip-vertical'"
                                  :class="{spin: isLoading(item)}"/>
                    </vue-nestable-handle>
                    <slot name="content"
                          v-bind:loading="isLoading(item)"
                          v-bind:row="item"
                          v-bind:index="numericIndex(index)"
                          v-bind:is-child="isChild"
                          v-bind:deep-count="deepCount"
                    ></slot>
                </template>
            </vue-nestable>
        </div>
    </div>
</template>

<script>
import draggable from '../draggable/vuedraggable'
import {
    computed,
    getCurrentInstance,
    inject,
    nextTick,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
} from 'vue'
import {useStore} from "~/vuex";


export default {
    name: "tree-view",
    emits: ['beforemove', 'input'],
    components: {
        draggable
    },
    props: {
        keyName: {
            type: String,
            default: 'id'
        },
        parentKeyName: {
            type: String,
            required: false,
            default: 'parent_id'
        },

        childKeyName: {
            type: String,
            default: 'children'
        },


        treeData: {
            type: [Array, Object],
            default: []
        },

        renderRowAttributes: {
            type: Function,
            default: () => {
            }
        },

        renderColumn: {
            type: Function,
            default: () => {
            }
        },

        isRoot: {
            required: false,
            type: Boolean,
            default: true
        },

        maxDepth: {
            type: Number,
            default: 10
        },

        deep: {
            type: Number,
            default: 0
        },

        loading: {
            required: false,
            type: Boolean,
            default: false
        },

        load: {},

        height: {
            default() {
                return {height: null}
            }
        },

        groupname: {
            type: String,
            default() {
                return Math.random().toString(36).slice(2)
            }
        }
    },


    setup(props, context) {
        const inst = getCurrentInstance();
	    const $root = inst.root.ctx;
        const store = useStore();
        const $events = inject('$events')
        const showChild = ref(true);
        const dragging = ref(false);
        const mutatedData = ref([]);
        const computedHeaderStyle = ref(null);

        watch(() => props.treeData, (n) => {
            mutatedData.value = Array.isArray(n) ? n : [];
            nextTick(() => {
                refreshZebraColors();
            })
        }, {deep: true, immediate: true});

        // mutatedData.value = _.cloneDeep(props.treeData);

        const scrollbarOptions = {};
        const sortOptions = {
            group: "name",
            animation: 0,
            handle: '.drag-handler',
            draggable: '.tree-item'
        };

        const getHeight = computed(() => {
            return {height: props.height}
        });

        const spacer = computed(() => {
            if (!props.deep) return null;
            return props.deep * 30 + 'px';
        });

        const caretPosition = computed(() => {
            if (showChild.value) {
                return 'fa-caret-down'
            } else {
                return 'fa-caret-right'
            }
        });

        const stickyTop = ref(null);

        const resizeObserver = new ResizeObserver(entries => {
            if (entries[0].target.offsetHeight > 0) {

                let r, h = entries[0].target.offsetHeight;
                if ((r = document.querySelector('.content-main > .route-tabs'))) {
                    h += r.offsetHeight;
                }
                stickyTop.value = h + 'px'
            } else {
                stickyTop.value = null;
            }
        })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResized);
	        $events.$off('visible-tab.tree', visibleTab);
        });

        onBeforeMount(() => {
            $events.$on('visible-tab.tree', visibleTab);
        });

        onMounted(() => {
            window.addEventListener('resize', onResized);

            onResized();

            nextTick(() => {
                refreshZebraColors();
            })
        })


	    function visibleTab(t) {
		    if (t.id === 'list-terms') {
			    onResized()
		    }
	    }

        function refresh() {
            mutatedData.value = Array.isArray(props.treeData) ? _.cloneDeep(props.treeData) : [];
            onResized();
        }


        function refreshZebraColors() {
            const nt = inst.refs.nestable_tree
            if (nt) {
                nt.querySelectorAll('.nestable-item').forEach((el, index) => {
                    let cls = (index % 2) ? '-even' : '-odd';

                    el.classList.remove(`nestable-item-even`);
                    el.classList.remove(`nestable-item-odd`);

                    el.classList.add(`nestable-item${cls}`);
                });
            }

        }


        function onResized() {

            computedHeaderStyle.value = {
                top: stickyTop.value
            };
            // return;
            //
            // const header = document.querySelector('.header-bottom > .header-nav')
            // const routetabsTop = document.querySelector('.content-main > .route-tabs')
            // const toolbarTop = document.querySelector('.content-main > .toolbar-container') ?? document.querySelector('.content-main > .content-container > .toolbar-container')
            // const contentMainTop = document.querySelector('.content-main > .content-container')
            //
            // if (routetabsTop && toolbarTop && header) {
            //     computedHeaderStyle.value = {
            //         top: (header.offsetHeight + toolbarTop.offsetHeight + routetabsTop.offsetHeight) + 'px'
            //     }
            // } else if (routetabsTop && toolbarTop) {
            //     computedHeaderStyle.value = {
            //         top: (toolbarTop.offsetHeight + routetabsTop.offsetHeight) + 'px'
            //     }
            // } else if (contentMainTop) {
            //     computedHeaderStyle.value = {
            //         top: contentMainTop.offsetTop + 'px'
            //     }
            // }
        }

        function isLoading(item) {
            if (props.load) {
                return props.load === item;
            }

            return item.hasOwnProperty('loading') && item.loading === true;
        }

        function numericIndex(value) {
            return parseInt(value);
        }

        function beforeMove({dragItem, pathFrom, pathTo}) {
            context.emit('beforemove', dragItem, pathFrom, pathTo);
            return true
        }

        function changeTreePosition(item, options, oldItems) {
            context.emit('input', item, mutatedData.value, options, oldItems);
        }

        function isFloder(item) {
            if (!item.children) return false;
            return item.children.length > 0;
        }

        function clickCollapse() {
            showChild.value = !showChild.value;
        }

        function getKey(item) {
            return item[props.keyName]
        }

        function updateFromInput(n) {
            mutatedData.value = Array.isArray(n) ? n : [];
        }

        return {
            computedHeaderStyle,
            sortOptions,
            showChild,
            caretPosition,
            spacer,
            getHeight,
            mutatedData,
            dragging,
            stickyTop,
            updateFromInput,
            refresh,
            isLoading,
            numericIndex,
            beforeMove,
            changeTreePosition,
            isFloder,
            clickCollapse,
            getKey
        }
    },


    //
    //
    // data() {
    //     return {
    //         showChild: true,
    //         dragging: false,
    //         mutatedData: [],
    //         scrollbarOptions: this.scrollbarConfig,
    //         sortRootOptions: {
    //             group: "name",
    //             animation: 0,
    //             handle: '.drag-handler',
    //             draggable: '.tree-item'
    //         },
    //         sortOptions: {
    //             group: "name",
    //             animation: 0,
    //             handle: '.drag-handler',
    //             draggable: '.tree-item'
    //         }
    //     }
    // },
    // computed: {
    //
    //     getHeight() {
    //         return {height: this.height}
    //     },
    //
    //     spacer() {
    //         if (!this.deep) return null;
    //         return this.deep * 30 + 'px';
    //     },
    //
    //     caretPosition: function () {
    //         if (this.showChild) {
    //             return 'fa-caret-down'
    //         } else {
    //             return 'fa-caret-right'
    //         }
    //     }
    // },
    //
    // watch: {
    //     treeData(newData) {
    //         if (this.mutatedData !== newData) {
    //             this.mutatedData = newData;
    //             this.$forceUpdate();
    //         }
    //     }
    // },
    //
    // emits: ['beforemove', 'input'],
    //
    // beforeMount() {
    //     this.mutatedData = this.treeData;
    // },
    //
    // methods: {
    //     isLoading(item) {
    //
    //         if (this.load) {
    //             return this.load === item;
    //         }
    //
    //         return item.hasOwnProperty('loading') && item.loading === true;
    //     },
    //     numericIndex(value) {
    //         return parseInt(value);
    //     },
    //     beforeMove({dragItem, pathFrom, pathTo}) {
    //         this.$emit('beforemove', dragItem, pathFrom, pathTo);
    //         return true
    //     },
    //     changeTreePosition(item, options) {
    //         this.$emit('input', item, this.mutatedData, options);
    //     },
    //     isFloder(item) {
    //         if (!item.children) return false;
    //         return item.children.length > 0
    //     },
    //     clickCollapse() {
    //         this.showChild = !this.showChild
    //     },
    //     getKey(item) {
    //         return item[this.keyName]
    //     }
    // }
}
</script>

<style scoped>

</style>
