<template>
    <template v-if="mode === 'section'">
        <form-section :name="name"
                      :validate="validate"
                      :field="fieldOptions"
                      :form-model="formModel"></form-section>
    </template>
    <template v-else>
        <form-textarea :name="name"
                       :field="field"
                       :form-model="formModel" v-autosize></form-textarea>
    </template>
</template>
<script>

import { ref, onBeforeMount} from "vue"
import { defaultFieldProps} from "@/mixins/use-form-field";

export default {
    name: "memofield-section",

    props: {
        ...defaultFieldProps,
        label: {
            required: false,
            type: String
        },
    },

    setup(props, context) {
        const mode = ref('section');
        const fieldOptions = ref({});

        onBeforeMount(() => {

            if (props.field) {

                if ( props.field.hasOwnProperty('mode') && props.field.mode && props.field.mode !== 'sidebar') {
                    mode.value = props.field.mode;
                }

                fieldOptions.value = {
                    memo: true,
                    class: 'memo-section',
                    label: props.label,
                    icon: 'fa-sticky-note',
                    fields: {}
                };

                if (props.field.class) {
                    fieldOptions.value.class += ' ' + props.field.class;
                }

                if (props.field.label) {
                    fieldOptions.value.label = props.field.label;
                }

                if (props.field.usemodel) {
                    fieldOptions.value.usemodel = props.field.usemodel;
                }

                if (props.field.usemodel) {
                    fieldOptions.value.fields[props.name] = Object.assign({}, {
                        usemodel: props.field.usemodel,
                        type: 'textarea',
                        autosize: true,
                        attributes: {
                            rows: 2,
                            class: 'memofield'
                        }
                    });
                } else {
                    fieldOptions.value.fields[props.name] = Object.assign({}, {
                        type: 'textarea',
                        autosize: true,
                        attributes: {
                            rows: 2,
                            class: 'memofield'
                        }
                    });
                }
            }
        })
        return {
            mode,
            fieldOptions
        }
    },

}
</script>

<style scoped>

</style>
