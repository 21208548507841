<script>
import {defineComponent, getCurrentInstance, inject, ref, computed, watch, nextTick} from 'vue'
import {defaultFieldProps, useFormField} from "@/mixins/use-form-field";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "ams-medications",
    props: {
        ...defaultFieldProps,
    },
    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;
        const store = useStore()

        const {
            allowMultiple,
            fieldIsDisabled,
            modelName,
            defaultAttributes,
            modelvalue,
            fieldAttributes
        } = useFormField(props, context);

        const route = useRoute();
        const $http = inject('$http');
        const $events = inject('$events');

        const notification = inject('notification');
        const enableEditingWatcher = ref(false);
        const editing = ref(false);
        const editingRow = ref(null);
        const editingDirty = ref(false);

        const loading = ref(false);
        const warning = ref(false);

        const expandedPlanes = ref([]);

        watch(() => editingRow.value, (n, o) => {
            if (enableEditingWatcher.value && n !== o) {
                editingDirty.value = true
            }
        }, {deep: true})

        const warning_label = computed(() => {
            if (warning.value && warning.value.relID > 0) {
                let plan = _.find(modelvalue.value, {id: warning.value.relID})
                if (plan && Array.isArray(plan.medications)) {
                    let r = _.find(plan.medications, {id: warning.value.id})
                    if (r) {
                        return {
                            med: r,
                            plan: plan
                        }
                    }
                }
            }
            return null;
        })

        const canEdit = computed(() => {
            return editing.value === false
        })

        let newID = 0,
            newID0 = 0;


        /**
         * Medikament einem Plan entfernen
         * @param relID
         * @param id
         */
        function remove(relID, id) {
            if (editingDirty.value) return;

            let plan = _.find(modelvalue.value, {id: relID});

            if (plan) {
                if (typeof id === "string" && id.match(/^n\d+?/g)) {
                    plan.medications = _.filter(plan.medications, (r) => {
                        return r.id !== id;
                    });

                } else {
                    warning.value = {id: id, relID: relID};
                }
            }
        }

        /**
         * Medikament einem Plan entfernen > Bestätigung
         */
        function confirmRemove() {
            if (warning.value && warning.value.relID > 0) {
                let item = _.find(modelvalue.value, {id: warning.value.relID});
                if (Array.isArray(item.medications) && _.find(item.medications, {id: warning.value.id})) {
                    loading.value = true;

                    $http.post(app.routes('admin.ams.medication.remove'), {
                        id: warning.value.id,
                        relID: warning.value.relID,
                        case_id: props.formModel.id
                    }).then(r => {

                        notification(r.data);

                        if (r.data.success)
                        {
                            item.medications = _.filter(item.medications, (r) => {
                                return warning.value.id !== r.id
                            })

                            loading.value = false;
                            warning.value = false;

                        }
                        else {
                            loading.value = false;
                        }

                    }).catch(e => {
                        loading.value = false;
                    });
                }
            }
        }


        /**
         * Neuen Medikationsplan hinzufügen
         */
        function addPlan() {
            if (editingDirty.value) return;


            if (!modelvalue.value || !Array.isArray(modelvalue.value)) {
                modelvalue.value = [];
            }

            newID0++;
            modelvalue.value.push({
                'id': 'n' + newID0,
                case_id: null,
                medications: []
            });
            expandedPlanes.value.push('n' + newID0)

        }


        function removePlan(relID) {
            if (editingDirty.value) return;
            modelvalue.value = _.filter(modelvalue.value, (r) => {
                return relID !== r.id
            })
        }


        /**
         * Medikament hinzufügen
         * @param addToPlan
         */
        function add(addToPlan) {
            if (editingDirty.value) return;


            if (!addToPlan.medications || !Array.isArray(addToPlan.medications)) {
                addToPlan.medications = [];
            }

            newID++;

            addToPlan.medications.push({
                'id': 'n' + newID,

                'days_from': null, //
                'substance': null, // Wirkstoff
                'trade_name': null, // Handelsname
                'strength': null, // Stärke
                'dosage_form': null, // Darreichungsform
                'pzn': null, //
                'should_morning': null, // SOLL morgens
                'should_noon': null, // SOLL mittags
                'should_evening': null, // SOLL abends
                'should_night': null, // SOLL nachts
                'is_morning': null, // IST morgens
                'is_noon': null, // IST mittags
                'is_evening': null, // IST abends
                'is_night': null, // IST nachts
                'unit': null, // einheit
                'requirement': null, // bedarf
                'reason': null, // Anwendungsgrund
                'atc': null, // ATC-Code
                'amstatus': null, // R-Rezeptpf.,A-Apothekenpf.,N-Nicht-Apo,M-Medizinprod.,L-Lebensmittel
                'memo': null,
            });
            editing.value = 'n' + newID;
        }

        /**
         * medikament bearbeiten
         * @param planID
         * @param id
         * @param row
         */
        function editEntry(planID, id, row) {
            if (editingDirty.value) return;

            enableEditingWatcher.value = false;
            editingDirty.value = false;
            editingRow.value = row;
            editing.value = id;

            nextTick(() => {
                enableEditingWatcher.value = true;
            })
        }

        /**
         * medikament bearbeiten > ABBRECHEN
         * @param planID
         * @param id
         */
        function cancelEditEntry(planID, id) {
            if (editing.value === id) {
                enableEditingWatcher.value = false;
                editingRow.value = null;
                editing.value = false;
                editingDirty.value = false
            }
        }


        /**
         * Plan einblenden/ausblenden
         * @param id
         */
        function expandPlan(id) {
            let index = expandedPlanes.value.indexOf(id);
            if (index < 0) {
                expandedPlanes.value.push(id)
            }
            else {
                expandedPlanes.value.splice(index, 1)
            }
        }


        return {
            loading,
            modelvalue,
            warning,
            warning_label,
            editing,
            canEdit,
            editingDirty,
            editingRow,
            expandedPlanes,
            add,
            remove,
            confirmRemove,

            editEntry,
            cancelEditEntry,

            addPlan,
            removePlan,
            expandPlan,
        }
    }
})
</script>

<template>


    <confirm
        :show="warning !== false"
        title="Medikation entfernen ..."
        ok-label="Ja diese Medikation entfernen"
        type="warning"
        @cancel="warning = false"
        @submit="confirmRemove">
        <template v-slot:message>

            <loading :active="loading === true"
                     color="blue"
                     :width="40"
                     :height="40"
                     :opacity="0.6"
                     :is-full-page="false">
            </loading>

            <p v-if="warning_label && warning_label.med">
                Möchten Sie die Medikation "{{ warning_label.med.substance || warning_label.med.trade_name }}" im Plan: "{{ warning_label.plan.id }}" wirklich entfernen?
            </p>

            <p><b>Diese wird dann direkt entfernt und kann nicht wiederhergestellt werden!</b></p>
        </template>
    </confirm>


    <template v-if="Array.isArray(modelvalue) && modelvalue.length">
        <template v-for="(plan, x) in modelvalue">
            <div class="ams-medications-arr" :class="{open: expandedPlanes.indexOf(plan.id) >= 0}">
                <div class="ams-medications-title" @click="expandPlan(plan.id)">
                    <div class="t">
                        <svg-icon name="chevron-down"/>
                        <span>Medikationsplan {{ x+1 }}</span>
                    </div>

                    <div v-if="typeof plan.id === 'string' && plan.id.match(/^n\d{1,}/g)"
                        @click="removePlan(plan.id)"
                         v-tooltip="'Medikationsplan entfernen'"
                         class="b">
                        <svg-icon name="trash"/>
                    </div>
                </div>
                <div class="grid-container-outer ams-medications-container" v-if="expandedPlanes.indexOf(plan.id) >= 0">
                    <table class="table fixed-layout vuetable table-striped table-hovered">
                        <thead class="grid-header">
                        <tr>
                            <th width="90">
                                <div class="actions">

                                </div>
                            </th>
                            <th width="200">
                                <div>Wirkstoff</div>
                            </th>
                            <th width="auto">
                                <div>Handelsname</div>
                            </th>
                            <th width="110">
                                <div>Stärke</div>
                            </th>
                            <th width="60">
                                <div>Form <sup>1</sup></div>
                            </th>
                            <th class="two-rows" width="90">
                                <div>Morgens</div>
                            </th>
                            <th class="two-rows" width="90">
                                <div>Mittags</div>
                            </th>
                            <th class="two-rows" width="90">
                                <div>Abends</div>
                            </th>
                            <th class="two-rows" width="90">
                                <div>sonstige</div>
                            </th>
                            <th width="60">
                                <div>Einheit</div>
                            </th>
                            <th width="auto">
                                <div>Hinweise</div>
                            </th>
                            <th width="200">
                                <div>Grund <sup>2</sup></div>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="vuetable-body grid-data">
                        <template v-if="Array.isArray(plan.medications) && plan.medications.length">
                            <template v-for="(r, i) in plan.medications">
                                <tr :class="i % 2 ? 'even' : 'odd'">
                                    <td data-label="Aktionen" width="90">
                                        <div class="actions">
                                            <template v-if="r.id === editing">
                                                <button type="button" class="btn btn-sm"
                                                        v-tooltip="'Wirkstoff speichern'">
                                                    <svg-icon name="device-floppy"/>
                                                </button>

                                                <button type="button" class="btn btn-sm"
                                                        v-tooltip="'Bearbeitung abbrechen'"
                                                        :disabled="editingDirty"
                                                        @click="cancelEditEntry(plan.id, r.id)">
                                                    <svg-icon name="x"/>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button type="button"
                                                        class="btn btn-sm"
                                                        v-tooltip="'Wirkstoff bearbeiten'"
                                                        @click="editEntry(plan.id, r.id)"
                                                        :disabled="editingDirty"
                                                >
                                                    <svg-icon name="pencil"/>
                                                </button>

                                                <button type="button" class="btn btn-sm"
                                                        v-tooltip="'Wirkstoff entfernen'"
                                                        @click.prevent="remove(plan.id, r.id)"
                                                        :disabled="editingDirty">
                                                    <svg-icon name="trash"/>
                                                </button>
                                            </template>


                                        </div>
                                    </td>
                                    <td data-label="Wirkstoff" width="200">
                                        <div>
                                            <input type="text" v-if="r.id === editing"
                                                   v-model="r.substance"
                                                   placeholder="Wirkstoff ..."
                                            />
                                            <span v-else>{{ r.substance }}</span>
                                        </div>
                                    </td>
                                    <td data-label="Handelsname" width="auto">
                                        <div>
                                            <input type="text" v-if="r.id === editing"
                                                   v-model="r.trade_name"
                                                   placeholder="Handelsname ..."
                                            />
                                            <span v-else>{{ r.trade_name }}</span>
                                        </div>
                                    </td>
                                    <td data-label="Stärke" width="110">
                                        <div>
                                            <input type="text" v-if="r.id === editing"
                                                   v-model="r.strength"
                                                   placeholder="Stärke ..."
                                            />
                                            <span v-else>{{ r.strength }}</span>
                                        </div>
                                    </td>
                                    <td data-label="Form" width="60">
                                        <div>
                                            <input type="text" v-if="r.id === editing"
                                                   v-model="r.dosage_form"
                                                   placeholder="Form ..."
                                            />
                                            <span v-else>{{ r.dosage_form }}</span>
                                        </div>
                                    </td>
                                    <td data-label="Morgens" class="two-rows" width="90">
                                        <div>
                                            <div>
                                                <div>
                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.should_morning"
                                                           placeholder="SOLL Morgens ..."
                                                    />
                                                    <span v-else>{{ r.should_morning }}</span>
                                                </div>
                                                <div>

                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.is_morning"
                                                           placeholder="IST Morgens ..."
                                                    />
                                                    <span v-else>{{ r.is_morning }}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Mittags" class="two-rows" width="90">
                                        <div>
                                            <div>
                                                <div>
                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.should_noon"
                                                           placeholder="SOLL Mittags ..."
                                                    />
                                                    <span v-else>{{ r.should_noon }}</span>
                                                </div>
                                                <div>
                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.is_noon"
                                                           placeholder="IST Mittags ..."
                                                    />
                                                    <span v-else>{{ r.is_noon }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Abends" class="two-rows" width="90">
                                        <div>
                                            <div>
                                                <div>
                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.should_evening"
                                                           placeholder="SOLL Abends ..."
                                                    />
                                                    <span v-else>{{ r.should_evening }}</span>
                                                </div>
                                                <div>
                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.is_evening"
                                                           placeholder="IST Abends ..."
                                                    />
                                                    <span v-else>{{ r.is_evening }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="sonstige" class="two-rows" width="90">
                                        <div>
                                            <div>
                                                <div>
                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.should_night"
                                                           placeholder="SOLL sonstige ..."
                                                    />
                                                    <span v-else>{{ r.should_night }}</span>
                                                </div>
                                                <div>

                                                    <input type="text" v-if="r.id === editing"
                                                           v-model="r.is_night"
                                                           placeholder="IST sonstige ..."
                                                    />
                                                    <span v-else>{{ r.is_night }}</span>


                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-label="Einheit" width="60" class="unit">
                                        <div>
                                            <input type="text" v-if="r.id === editing"
                                                   v-model="r.unit"
                                                   placeholder="Einheit ..."
                                            />
                                            <span v-else>{{ r.unit }}</span>
                                        </div>
                                    </td>
                                    <td data-label="Hinweise" width="auto" class="m">
                                        <div>
                                            <input type="text" v-if="r.id === editing"
                                                   v-model="r.memo"
                                                   placeholder="Hinweise ..."
                                            />
                                            <span v-else>{{ r.memo }}</span>
                                        </div>
                                    </td>
                                    <td data-label="Grund" width="200" class="reason">
                                        <div>
                                            <input type="text" v-if="r.id === editing"
                                                   v-model="r.reason"
                                                   placeholder="Grund ..."
                                            />
                                            <span v-else>{{ r.reason }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                            <template v-else>
                                <tr>
                                    <td colspan="12" class="empty"><em>es wurden noch keine Medikamente hinterlegt</em></td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="after-table-actions" v-if="expandedPlanes.indexOf(plan.id) >= 0">
                    <button type="button" class="btn btn-primary" @click="add(plan)">Medikament hinzufügen</button>
                </div>
            </div>
        </template>
    </template>





    <div class="after-table-actions">
        <button type="button" class="btn btn-primary" @click="addPlan">Medikationsplan hinzufügen</button>
    </div>

</template>

<style scoped>

</style>
