<template>
    <li :v-bind="rowAttributes(rowData)">
        <template>
            <div class="collapser">
                <span v-if="isFloder" class="fa" :class="[caretPosition]" @click="clickCollapse"></span>
            </div>
            <slot name="ItemComponent" v-bind:c="rowData"></slot>
        </template>

        <div v-show="showChild">

            <!--<tree-view :key-name="keyName"-->
            <!--:tree-data="rowData.children"-->
            <!--slot="ItemChildComponent">-->
            <!--<template slot="content">-->
            <!--<slot name="ItemComponent" v-bind:row="rowData"></slot>-->
            <!--</template>-->
            <!--</tree-view>-->

            <draggable tag="ul"
                       :list="rowData.children"
                       @start="dragging=true"
                       @end="dragging=true"
                       :group="{ name: 'g1' }">

                <tree-item v-for="child2 in rowData.children"
                           :key-name="keyName"
                           :key="getKey(child2)"
                           :row-data="child2">
                    <slot name="ItemChildComponent" v-bind:c="child2"></slot>

                </tree-item>

            </draggable>
        </div>
    </li>
</template>

<script>
    import draggable from '~/vuedraggable'
    export default {
        name: "TreeItem",
        components: {
            draggable
        },
        props: {
            keyName: {
                type: String,
                required: true
            },

            rowData: {
                type: [Array, Object],
                default: []
            },
            renderRowAttributes: {
                type: Function,
                default: () => {}
            },

            renderColumn: {
                type: Function,
                default: () => {}
            },
        },
        computed: {
            isFloder: function () {
                return this.rowData.children.length > 0 && this.rowData.children
            },
            caretPosition: function () {
                if (this.showChild) {
                    return 'fa-caret-down'
                } else {
                    return 'fa-caret-right'
                }
            }
        },
        data () {
            return {
                showChild: true
            }
        },
        methods: {
            prepareTreeData(data) {

                if (_.isObject(data)) {
                    return [data]
                }

                return data;
            },

            getKey(item) {
                return item[this.keyName]
            },
            clickCollapse: function () {
                this.showChild = !this.showChild
            },

            rowAttributes(row) {

                let attr = this.renderRowAttributes(row);

                if (attr) {
                    return attr;
                }

                if (row.hasOwnProperty('attributes') && row.attributes) {
                    return row.attributes;
                }

                return []
            },

            getAttributes(row) {
                if (row.hasOwnProperty('attributes') && row.attributes) {
                    return row.attributes;
                }

                return []
            }
        }
    }
</script>

<style scoped>
    li {
        display: flex;
        flex-direction: row;
    }

    li > .collapser {
        width: 30px;
        line-height: 42px;
        text-align: center;
    }
    li > .collapser > .fa {
        line-height: 42px;
    }

    li table {
        margin: 0;
    }
</style>
