<script>
import {defineComponent, computed, inject} from 'vue'
import {useRouter, useRoute} from "~/vue-router";

export default defineComponent({
    name: "proofreading-message-tree-item",
    props: {
        item: {},
        formModel: {}
    },
    setup(props, context) {
        const router = useRouter();
        const route = useRoute();
        const $moment = inject('$moment')

        const hasChildren = computed(() => {
            if (props.item.hasOwnProperty('children') && Array.isArray(props.item.children) && props.item.children.length > 0 ) {
                return true;
            }

            return false;
        });

        const currentWillEdit = computed(() => {
            if (props.formModel && props.formModel.hasOwnProperty('id') ) {
                if (props.formModel.id === props.item.id) {
                    return true;
                }
            }
            return false;
        });

        const canEditMyLastItem = computed(() => {
            if (props.formModel && props.formModel.hasOwnProperty('mylast') && props.formModel.mylast) {
                return props.formModel.mylast.id === props.item.id
            }
            return false;
        });

        const isEditMode = computed(() => {
            return route.name === 'admin.proofreading.message.edit'
        });

        function renderDate(date, format) {
            return date ? $moment(date).format(format ? format : 'DD.MM.YYYY') : '-'
        }

        function editMyEntry() {
            router.push({name: 'admin.proofreading.message.edit', params: {message: props.item.id}})
        }

        return {
            hasChildren,
            currentWillEdit,
            canEditMyLastItem,
            isEditMode,
            renderDate,
            editMyEntry
        }
    }
})
</script>

<template>
    <div class="answer-item">

        <div class="answer-item-entry" :class="{'will-edit': currentWillEdit}">
            <div class="header" v-if="item.title">
                <div class="title">{{ item.title }}</div>
                <div class="author">
                    <div class="createdat">{{ renderDate(item.created_at) }}</div>
                    <template v-if="item.createdby">
                        <div class="createdby">von {{ item.createdby.first_name }} {{ item.createdby.surname }}</div>
                    </template>
                </div>
            </div>
            <template v-if="canEditMyLastItem && !isEditMode">
                <div class="answer-item-entry-toolbar">
                    <button type="button" class="btn btn-primary" @click.prevent="editMyEntry">Bearbeiten</button>
                </div>
            </template>
            <div class="message">

                <pre>{{ item.content }}</pre>
            </div>
        </div>

        <template v-if="hasChildren">
            <div class="answer-children">
                <template v-for="r in item.children">
                    <proofreading-message-tree-item :item="r" :form-model="formModel"  />
                </template>
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>
