import EditRequest from './components/EditRequest.vue';

export default [
    {
        path: '/requests',
        component: require('./components/RequestsIndex.vue').default,
        name: 'admin.requests',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },

    {
        path: '/requests/edit/:id',
        component: EditRequest,
        name: 'admin.requests.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
    {
        path: '/requests/create',
        component: EditRequest,
        name: 'admin.requests.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },




    {
        path: '/proofreading',
        component: require('./components/proofreading/ProofreadingIndex.vue').default,
        name: 'admin.proofreading',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },

    {
        path: '/proofreading/edit/:id',
        component: require('./components/proofreading/ProofreadingEdit.vue').default,
        name: 'admin.proofreading.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },


    {
        path: '/proofreading/my-entry/edit/:message',
        component: require('./components/proofreading/ProofreadingEditMessage.vue').default,
        name: 'admin.proofreading.message.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
];
