import {getToken, removeToken} from '../../utils/auth';

const user = {
    namespaced: true,
	state: {
		session_id: null,
		token: getToken(),
		user: {
			id: 0
		},
		webuser: {},
		usergroups: [],
		registrationTypes: []
	},
    getters: {
        user: state => state.user,
        isLoggedIn: state => state.user.id > 0 && state.user.id !== null,
        isLocked: state => state.user.id === null,
        username: state => {
            if (state.user && state.user.username) {
                return state.user.username;
            }
            return null
        },
    },
	mutations: {
		SET_SESSION_ID: (state, session_id) => {
			state.session_id = session_id;
		},
		SET_TOKEN: (state, token) => {
			state.token = token;
		},
		set_user(state, payload) {
			state.user = Object.assign(state.user, payload);
		},
		set_webuser(state, payload) {
			state.webuser = payload;
		},

		set_usergroups(state, payload) {
			state.usergroups = payload;
		},

		set_webuser_registration_types(state, payload) {
			state.registrationTypes = payload;
		},

		update_user (state, payload) {
			if (_.isObject(payload)) {
				state.user = Object.assign({}, state.user, payload);
			}
			else {
				console.warn('Could not update user Store!');
			}
		}
	},
	actions: {
		setSessionID({ commit }, data) {
			commit('SET_SESSION_ID', data);
		},
		setUsergroups({ commit }, props) {
			commit('set_usergroups', props);
		},
		setUser({ commit }, props) {
			return new Promise(resolve => {
				commit('set_user', props);
				resolve();
			});
		},
		setWebUser({ commit }, props) {
			commit('set_webuser', props);
		},
		updateUser({ commit }, props) {
			commit('update_user', props);
		},

		setWebUserRegistrationTypes({ commit }, props) {
			commit('set_webuser_registration_types', props);
		},

		resetToken({ commit }) {
			return new Promise(resolve => {
				commit('SET_TOKEN', '');
				commit('set_user', null);
				removeToken();
				resolve();
			});
		},

        lock({ commit }, props) {
            return new Promise(resolve => {
                commit('set_user', {user_id: null});
                resolve();
            });
        }
	}
};

export default user
