<template>
    <div :class="['tree-table sortable-table', {'is-scrollable': scrollable}]">
        <table width="100%" class="header-table">
            <thead>
            <tr>
                <th v-if="sortable" width="40">
                    <div><span class="drag-handler"></span></div>
                </th>
                <th v-for="head in headers" v-bind="headAttributes(head)">
                    <div>{{ head.title }}</div>
                </th>
            </tr>
            </thead>
        </table>
        <div v-if="scrollable" :style="{height: calcFormContentHeight +'px'}">
            <v-scroll ref="scroller" :ops="scrollbarOptions">
                <tree-table-rows
                        ref="rows"
                        :headers="headers"
                        :rows="mutatedRows"
                        :is-sortable="sortable"
                        :render-column="renderColumn"
                        :render-row-attributes="renderRowAttributes">
                    <template slot="column" slot-scope="{ rowData }">
                        <slot name="column-content" v-bind:row="rowData"></slot>
                    </template>

                    <template slot="childtree" slot-scope="{ childs }">
                        <slot name="column-content" v-bind:row="childs"></slot>
                    </template>
                </tree-table-rows>
            </v-scroll>
        </div>
        <tree-table-rows
                v-else
                ref="rows"
                :headers="headers"
                :rows="mutatedRows"
                :is-sortable="sortable"
                :render-column="renderColumn"
                :render-row-attributes="renderRowAttributes">
            <template slot="column" slot-scope="{ rowData }">
                <slot name="column-content" v-bind:row="rowData"></slot>
            </template>

            <template slot="childtree" slot-scope="{ childs }">
                <slot name="column-content" v-bind:row="childs"></slot>
            </template>
        </tree-table-rows>

    </div>
</template>

<script>
    import VScroll from "vuescroll";
    import draggable from 'vuedraggable'
    export default {
        name: "TreeTable",

        components: {
            draggable,
            'v-scroll': VScroll
        },
        props: {
            scrollable: {
                required: false,
                default: true
            },
            headers: {
                required: true,
                type: [Array, Object],
                default() {
                    return {}
                }
            },
            dataRows: {
                type: [Array, Object],
                default() {
                    return {}
                }
            },
            sortable: {
                type: Boolean,
                default: true
            },
            renderRowAttributes: {
                type: Function,
                default: () => {
                }
            },

            renderColumn: {
                type: Function,
                default: () => {
                }
            },
        },
        watch: {
            dataRows(v) {
                this.mutatedRows = v;
            }
        },

        data() {
            return {
                scrollbarOptions: {
                    sizeStrategy: 'number',
                    speed: 100,
                    keepShow: true,
                    scrollButton: {
                        enable: false,
                    },
                    scrollPanel: {
                        easing: 'easeInQuad',
                        speed: 0
                    },
                    vuescroll: {
                        wheelScrollDuration: 0
                    },
                    rail: {
                        opacity: '1',
                        background: 'transparent',
                        border: '0 solid #cecece',
                        size: '6px'
                    },
                    bar: {
                        background: '#cecece',
                        keepShow: true,
                        size: '6px',
                        minSize: 0.2
                    },
                },
                tabledata: null,
                mutatedRows: [],
                sortOptions: {
                    group: "name",
                    animation: 0,
                    handle: '.drag-handler',
                    draggable: 'li'
                },


                height: null
            }
        },
        computed: {

            parent() {
                let p = this.$parent;
                while (p && p._isMounted) {
                    if (p.$el.classList && p.$el.classList.contains('form-content')) {
                        return p.$el;
                    }

                    p = p.$parent;
                }

                return 0;
            },


            calcFormContentHeight() {
                return this.$parent.height - 40;
               // return this.parent.calcFormContentHeight;
            },
        },

        mounted() {

            this.mutatedRows = this.dataRows;

            this.$events.$on('addSortable', () => {
                // this.addSortable();
            });

            this.$nextTick(() => {
                // this.addSortable();
                this.height = this.calcFormContentHeight;

            })
        },

        methods: {
            addSortable() {
                if (!this.sortable) {

                }
                return;
                let to, that = this, el = $('ul:first', this.$el);

                el.find('li').each(function () {
                    if (!$(this).find('> ul').length) {
                        $(this).append('<ul>');
                    }
                });

                el.nestedSortable({
                    forcePlaceholderSize: true,
                    forceHelperSize: true,
                    handle: '.drag-handler',
                    helper: 'clone',
                    // type: 'semi-dynamic',
                    items: 'li',
                    listType: 'ul',
                    opacity: .6,
                    placeholder: 'ui-sortable-placeholder',
                    revert: 250,
                    tabSize: 25,
                    tolerance: 'pointer',
                    toleranceElement: ' > div:first',
                    maxLevels: 15,
                    isTree: true,
                    expandOnHover: 500,
                    startCollapsed: true,
                    appendTo: 'body',
                    zIndex: 5000,
                    scroll: true,
                    start: function (event, ui) {
                        // $(ui.helper).css({height: $(ui.item).innerHeight()});

                    },
                    stop: function (event, ui) {
                        let serialized = el.nestedSortable('serialize', {startDepthCount: 0});
                        that.$emit('sorted', serialized);
                    }
                });
            },

            headAttributes(head) {
                if (head.hasOwnProperty('attributes') && head.attributes) {
                    return head.attributes;
                }

                return []
            }
        }

    }
</script>

<style scoped>

</style>