<template>
    <form-field :name="name" :form-model="formModel" :field="field" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:field>
            <div :class="{'with-prepend': field.prependlabel, 'with-append': field.appendlabel}">
                <span class="prepend-label" v-if="field.prependlabel" v-html="field.prependlabel"></span>
                <input
                    type="text"
                    :name="name"
                    ref="formfield"
                    class="form-control"
                    v-model="modelvalue"
                    v-bind="fieldAttributes"
                    @keyup.enter="(e) => $emit('enter', e)">
                <span class="append-label" v-if="field.appendlabel" v-html="field.appendlabel"></span>

            </div>
        </template>
    </form-field>
</template>
<script>
import {watch} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";


export default {
    name: "form-text",
    emits: ['input', 'meta', 'enter'],
    props: {
        ...defaultFieldProps,
        returnOnly: {
            type: Boolean,
            default: false
        }
    },

    setup(props, context) {
        const {modelvalue, fieldAttributes} = useFormField(props, context);

        watch(() => modelvalue.value, (n) => {
            context.emit('input', n);
        });

        return {
            modelvalue,
            fieldAttributes,
        }
    },

}
</script>

<style scoped>

</style>
