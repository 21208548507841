import FormFieldCondition from "./form-field-conditions";
export default {
    mixins: [ FormFieldCondition],
    props: {
        name: {
            required: false
        },
        field: {
            required: false,
            default() {
                return {}
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        },
        responseerrors: {},
        rendersections: {}
    },

    data: () => ({
        isMounted: false,
        dirty: false,
        modelName: null,
        metaModelName: null,
        useModelName: null,
        defaultModelValue: null,
        units: [],
        defaultAttributes: {

        },
        mutatedAttributes: {

        },
        mutatedFieldAttributes: {}
    }),
    watch: {
        '$route.meta.dirty'(n, o) {
            this.dirty = n;
        },

        // 'field.attributes'(n, o) {
        //     this.mutatedFieldAttributes = n;
        // }
    },
    computed: {

        hasFormModel() {
            return _.isObject(this.formModel);
        },

        formcontainer() {
            let container = null;

            if (this.$children[0] && (this.$children[0].name === 'form-container' || this.$children[0].name === 'Formrenderer') ) {
                return container;
            }


            container = this.$parent;
            if (container && (container.name === 'form-container' || container.name === 'Formrenderer') ) {
                return container;
            }

            while (true) {

                if (container) {
                    if (container._isVue && container.$el && container.$el.classList && container.$el.classList.contains('form-wrapper')) {
                        return container;
                    }
                }

                if (container && container.hasOwnProperty('_isVue') && container._isVue) {
                    container = container.$parent;
                }
                else {
                    break;
                }

            }

            return null;
        },

        getUnits() {
            return this.units;
        },


        fieldConfig: {
            get() {
                if (this.field.hasOwnProperty('config') && _.isObject(this.field.config)) {
                    return this.field.config;
                }

                return null;
            },
            set(v) {
                this.$set(this.field, 'config', v);
            }
        },

        modelvalue: {
            get() {
                if (this.field.hasOwnProperty('metadata')) {
                    return this.field.metadata;
                }
                else {
                    if (_.isString(this.modelName) && this.modelName.match(/([a-z0-9\.]+)/ig) ) {
                        // @todo remove old root model !!!
                        let v = this.getPropertyPath(this.formModel, this.modelName);

                        // console.log('get modelvalue', this.modelName)
                        if (typeof v === "undefined")
                        {
                            // console.warn('Undefined model name! FieldName: ', this.name, ' Field:', this.field);
                        }
                        return typeof v !== "undefined" ? v : this.defaultModelValue;
                    }

                    return this.defaultModelValue;
                }
            },

            set(value) {

                if (!this.formModel) return;


                if (this.field.hasOwnProperty('metadata')) {
                    this.$emit('meta', value);
                    return;
                }

                if ( !this.$route.meta.nodirty && !this.field.nodirty ) {
                    if (value !== this.modelvalue) {
                        this.dirty = true;
                        if (this.formcontainer) this.formcontainer.makeDirty();
                    }
                }

                if (_.isArray(value)) {
                    this.vueSet(this.formModel, this.modelName, value);
                }
                else {
                    this.vueSet(this.formModel, this.modelName, value);
                    this.setPropertyPath(this.formModel, this.modelName, value, true);
                }
            }
        },

        // value0: {
        //     get() {
        //
        //         if (!this.hasOwnPropertyPath(this.$root.model, this.modelName)) {
        //             this.setPropertyPath(this.$root.model, this.modelName, _.cloneDeep(this.defaultModelValue) );
        //         }
        //
        //         let v = this.getPropertyPath(this.$root.model, this.modelName);
        //         // console.log('get value', this.modelName)
        //
        //         if (typeof v === "undefined")
        //         {
        //             console.warn('Undefined model name! FieldName: ', this.name, ' Field:', this.field);
        //         }
        //
        //         return typeof v !== "undefined" ? v : null;
        //     },
        //     set(value) {
        //         this.$set(this.$root.model, this.modelName, value);
        //     }
        // },

        unitValue: {
            get() {

                let u = this.getUnits;
                if (!u || (_.isArray(u) && !u.length)) {
                    return;
                }


                let v;
                if (this.hasFormModel) {
                    v = this.getPropertyPath(this.formModel, this.modelName+'unit');
                }
                else {
                    v = this.getPropertyPath(this.$root.model, this.modelName+'unit');
                }



                // if (typeof v === "undefined")
                // {
                //     console.warn('Undefined model name! FieldName: ', this.name, ' Field:', this.field, ' ModelKey:', this.modelName);
                // }

                return typeof v !== "undefined" ? v : null;
            },
            set(value) {
                let u = this.getUnits;
                if (!u || (_.isArray(u) && !u.length)) {
                    return;
                }

                if (this.hasFormModel) {
                    this.vueSet(this.formModel, this.modelName+'unit', value);
                }
                else {
                    this.vueSet(this.$root.model, this.modelName+'unit', value);
                }
            }
        },
        fieldName(){
            return this.name; //this.$root.transformKey(this.name)
        },

        fieldTooltip() {
            if (_.isString(this.field.tooltip)) {
                return this.field.tooltip;
            }
            else if (this.field.description && _.isBoolean(this.field.tooltip)) {
                return this.field.description;
            }

            return '';
        },
        fieldHasTooltip() {
            return _.isString(this.field.tooltip) || this.field.description && _.isBoolean(this.field.tooltip)
        },

        fieldAutosaveAttribute() {

            if (this.field.autosave) {
                return this.$root.createVModel( this.name ) + '|' + this.field.autosave;
            }

            return null;
        },

        fieldAttributes() {
            let defaults = _.cloneDeep(this.defaultAttributes);
            let attrs = _.cloneDeep(this.mutatedAttributes);
            let isDisabled = null;

            if (this.hasDisableWhen) {
                isDisabled = this.getDisableWhen;
            }

            if (_.isObject(attrs) || _.isArray(attrs)) {

                if (attrs.hasOwnProperty('disabled') && !attrs.disabled) {
                    delete attrs.disabled;
                }

                if (this.field.hasOwnProperty('placeholder') && this.field.placeholder && !attrs.hasOwnProperty('placeholder')) {
                    attrs.placeholder = this.field.placeholder;
                }

                if (this.hasDisableWhen && isDisabled !== null) {
                    attrs.disabled = isDisabled;
                }

                return {
                    ...defaults,
                    ...attrs
                };
            }


            if (this.hasDisableWhen && isDisabled !== null) {
                defaults.disabled = isDisabled;
            }

            if (this.field.hasOwnProperty('placeholder') && this.field.placeholder) {
                defaults.placeholder = this.field.placeholder;
            }

            return defaults;
        },

        fieldIsDisabled() {
            let attr = this.fieldAttributes;
            return attr.hasOwnProperty('disabled') && attr.disabled === true || this.field.disabled === true;
        }
    },
    created() {

        if (this.field && this.field.hasOwnProperty('fieldname') && this.field.fieldname) {
            this.modelName = this.$root.createVModel(this.field.fieldname );
        }
        else {

            if (!_.isString(this.useModelName) || (_.isString(this.useModelName) && !this.useModelName) ) {
                this.modelName = this.$root.createVModel( this.name );
            }
            else {
                this.modelName = this.$root.createVModel( this.useModelName );
            }
        }

        // if (!_.isString(this.modelName)) {
        //     console.error('Invalid ModelName')
        // }



        this.mutatedAttributes = this.field.attributes;

        if (this.field.hasOwnProperty('metadata') && this.modelName && this.modelName.match(/^meta\./g)) {
            this.metaModelName = this.modelName.replace(/^meta\./g, '');
        }
    },
    mounted() {
        this.isMounted = true;

        this.$nextTick(() => {
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.bindMounted();
                    })
                })
            })
        })
    },

    methods: {
        vueSet: require('vue-deepset').vueSet,
        vueModel: require('vue-deepset').vueModel,

        getFieldValidationError(errors, modelName) {
            if (_.isObject(errors) && !_.isEmpty(errors) && modelName) {
                if (this.hasOwnPropertyPath(errors, modelName)) {
                    let e = this.getPropertyPath(errors, modelName);
                    if (_.isArray(e) && e[0]) {
                        return e[0];
                    }
                }
            }
            return false;
        },


        bindMounted() {
            if (!this.$el || !_.isFunction(this.$el.querySelector)) return;

            let field = this.$el.querySelector('select');
            if (!field) {
                field = this.$el.querySelector('textarea');
            }

            if (!field) {
                field = this.$el.querySelector('input');
            }

            if (field) {
                if (this.field.hasOwnProperty('enableif') && this.field.enableif) {
                    let str = this.field.enableif;
                    str = str.replace(/^\./g, '');



                    if (this.hasFormModel) {
                        this.$watch('formModel.' + str, (n, o) => {
                            if (n) {
                                field.removeAttribute('disabled');
                            } else {
                                this.mutatedAttributes.disabled = "disabled";
                            }
                        });


                        if (this.formModel[str]) {
                            field.removeAttribute('disabled');
                        }
                        else {
                            field.setAttribute('disabled', 'disabled');
                        }

                    }
                    else {
                        this.$watch('$root.model.' + str, (n, o) => {
                            if (n) {
                                field.removeAttribute('disabled');
                            } else {
                                this.mutatedAttributes.disabled = "disabled";
                            }
                        });

                        if (this.$root.model[str]) {
                            field.removeAttribute('disabled');
                        }
                        else {
                            field.setAttribute('disabled', 'disabled');
                        }

                    }

                }
                if (this.field.hasOwnProperty('enableifnot') && this.field.enableifnot) {
                    let str2 = this.field.enableifnot.replace(/^\./g, '');


                    if (this.hasFormModel) {
                        this.$watch('formModel.'+ str2, (n, o) => {
                            if (!n) {
                                field.removeAttribute('disabled');
                            }
                            else {
                                field.setAttribute('disabled', 'disabled');
                            }
                        });

                        if (_.isEmpty(this.formModel[str2])) {

                            field.removeAttribute('disabled');
                        }
                        else {
                            field.setAttribute('disabled', 'disabled');
                        }
                    }
                    else {
                        this.$watch('$root.model.'+ str2, (n, o) => {
                            if (!n) {
                                field.removeAttribute('disabled');
                            }
                            else {
                                field.setAttribute('disabled', 'disabled');
                            }
                        });
                        if (_.isEmpty(this.$root.model[str2])) {

                            field.removeAttribute('disabled');
                        }
                        else {
                            field.setAttribute('disabled', 'disabled');
                        }
                    }

                }
            }




        }
    }
}
