module.exports = {

    'source-details-btn': require('./components/btn-source-details.vue').default,

    'show-request-details': require('./components/ShowRequestDetails.vue').default,

    'proofreading-message-tree-item': require('./components/proofreading/proofreading-message-tree-item.vue').default,

    'form-source-options': require('./components/fields/source-options.vue').default,
    'form-customsources': require('./components/fields/customsources.vue').default,


    'form-medium-select': require('./components/fields/medium-select.vue').default,

    'form-proofreading-compare': require('./components/fields/proofreading-compare.vue').default,
    'form-proofreading-question': require('./components/proofreading/question-proofreading.vue').default,


    'form-proofreading-message-tree': require('./components/proofreading/proofreading-message-tree.vue').default,
    'form-proofreading-question-info': require('./components/proofreading/proofreading-question-info.vue').default,


    'form-question-opinions': require('./components/fields/question-opinions.vue').default,

}
