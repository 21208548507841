<template>
    <div class="nestable-expand-handler"
         @click="handleExpand"
         v-tooltip.top="tooltip">
        <slot/>
    </div>
</template>

<script>
import {useGroupObserver} from "./groups-observer.js";
import {getCurrentInstance} from "vue";

export default {
    name: "VueNestableExpandable",
    props: {
        item: {
            type: Object,
            required: false,
            default: () => ({})
        },
        expandable: {
            required: false,
            type: Boolean,
            default: false
        },

        defaultExpanded: {
            required: false,
            type: Boolean,
            default: false
        },
        tooltip: {
            required: false,
            type: String,
            default() {
                return 'einklappen / ausklappen'
            }
        }
    },

    emits: ['expand'],

    setup(props, context) {
        const inst = getCurrentInstance();
        const {
            unregisterNestable,
            registerNestable,
            notifyDragStart,
            notifyMouseEnter,
            notifyMouseMove
        } = useGroupObserver(props, context, inst);

        function handleExpand() {
            if (props.expandable) {
                if (props.item.hasOwnProperty('__expanded') ) {
                    context.emit('expand', props.item.__expanded !== true );
                }
                else {
                    context.emit('expand', props.defaultExpanded !== true );
                }
            }
        }

        return {
            handleExpand
        }
    },

}
</script>

<style scoped>

</style>
