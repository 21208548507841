<template>
	<div class="inner-wrapper data-table" :class="{loading: loading}">
		<form class="nodirty layout grid-form" :action="url">
			<!-- :field-slots="fieldSlots" -->
			<div class="grid-container-outer">
				<vuetable v-if="mounted"
                          ref="vuetable"
						  :api-mode="true"
						  :api-url="url"
						  http-method="post"
						  data-path="rows"
						  pagination-path="pagination"
						  :view-trash="viewTrash"
						  @vuetable:pagination-data="onPaginationData"
						  @vuetable:loading="onLoading"
						  @vuetable:loaded="onLoaded"
						  @vuetable:checked="checkSelection"
						  @vuetable:visible-columns-changed="saveColumns"
						  @vuetable:initialized="initializedColumns"
						  @vuetable:cell-clicked="columnClicked"
						  @vuetable:cell-dblclicked="columnDblClicked"
						  @vuetable:row-clicked="rowClicked"
						  @vuetable:row-dblclicked="rowDblClicked"
						  @vuetable:open-details="openDetailRow"
						  @vuetable:close-details="closeDetailRow"
						  @closepopup="$parent.$emit('close-popup')"
						  @delete="showDelete"
						  @restore="handleRestore"
                          @data-selection-action="(btn, a, instance) => $emit('data-selection-action', btn, a, instance )"
                          @change-selections=""
                          @vuetable:load-success="(response) => $emit('load-success', response)"

						  :field-components="fieldComponents"
						  :field-events="fieldEvents"
						  :field-transform-functions="fieldFunctions"
						  :detail-row-component="detailRowComponent"
						  :detail-row-only="detailRowOnly"
						  :per-page="perPage"
						  :css="css.table"
						  :data-total="total"
						  :append-params="appendParams"
						  :fields="header"
						  :sort-order="sortOrder"
						  :load-on-start="false"
						  :row-class="renderRowClass"
						  :ajax-action="ajaxAction"
						  :request-params="requestParams"
						  class="layout-row"
						  no-data-template="Keine Daten vorhanden"
						  :in-popup="inPopup"
						  :track-by="keyname"
                          :toolbar="toolbar"
                          :grid-actions="gridActions"
                >
					<template v-for="(s, columnname) in tableDataSlots"
                              v-slot:[columnname]="{item, index, field }">
						<slot :name="columnname"
							  v-bind:row="item"
							  v-bind:index="index"
							  v-bind:field="field" />
					</template>
				</vuetable>
			</div>


			<div ref="vuetable_footer" class="layout-row grid-footer-wrapper">
				<div class="grid-footer-infos">
					<div class="grid-options">

                        <template v-if="showPagination">
                            <div class="select-wrap">
                                <select class="nodirty form-control" v-model="perPage" id="vue-grid-perpage">
                                    <slot name="perpages">
                                        <option value="10">10 pro Seite</option>
                                        <option value="15">15 pro Seite</option>
                                        <option value="20">20 pro Seite</option>
                                        <option value="30">30 pro Seite</option>
                                        <option value="40">40 pro Seite</option>
                                        <option value="50">50 pro Seite</option>
                                        <option value="75">75 pro Seite</option>
                                        <option value="100">100 pro Seite</option>
                                    </slot>
                                </select>
                                <span></span>
                            </div>
                        </template>

						<button class="btn btn-default"
                                @click.prevent="refreshGrid">Tabelle aktualisieren</button>


					</div>

                    <template v-if="showPagination && mounted" >
                        <div class="grid-options grid-pagination" id="grid-pages">

                            <vuetable-pagination v-bind:css="css.pagination"
                                                 v-bind:on-each-side="5"
                                                 :current-page-number="page"
                                                 :pagination="paginationInfo"
                                                 @vuetable-pagination:change-page="onChangePage">
                                Seiten
                            </vuetable-pagination>
                        </div>

                        <div class="info-data-items">
                            <vuetable-pagination-info v-bind:css="css.pagination"
                                                      :pagination="paginationInfo"
                                                      no-data-template="Keine Datensätze vorhanden"
                                                      info-template="Datensatz {from} bis {to} von {total} Datensätzen"
                                                      info-class="pagination-info"></vuetable-pagination-info>
                        </div>
                    </template>
				</div>
			</div>
		</form>

		<confirm :show="deleteConfirmOpen"
				 type="warning"
				 title="Datensatz löschen?"
				 cancel-label="Abbrechen"
				 ok-label="Löschen"
				 @cancel="handleDeleteCancel"
				 @submit="handleDelete">
            <template v-slot:message>
                <div v-html="deleteConfirmMessage"></div>
            </template>
		</confirm>
	</div>

</template>

<script>
const css = {
    table: {
        tableClass: 'table table-striped table-hovered',
        loadingClass: 'loading',
        ascendingIcon: 'fa fa-caret-up',
        descendingIcon: 'fa fa-caret-down',
        handleIcon: 'glyphicon glyphicon-menu-hamburger',
    },

    pagination: {
        infoClass: 'pull-left',
        wrapperClass: 'vuetable-pagination',
        activeClass: 'btn-primary',
        disabledClass: 'disabled',
        pageClass: 'btn btn-default',
        linkClass: 'btn btn-default',
        icons: {
            first: '',
            prev: 'fa fa-caret-left',
            next: 'fa fa-caret-right',
            last: '',
        }
    }
};

const protectedSlotNames = [
    'prepend',
    'before',
    'header',
    'toolbar_prepend',
    'toolbar',
    'toolbar_append',
    'active_data_filter',
    'beforedata',
    'beforetable',
    'append',
];

import {computed, getCurrentInstance, inject, nextTick, onBeforeMount, onMounted, ref, toRaw, watch} from "vue";
import {useRoute} from "~/vue-router";
import {useStore} from "~/vuex";
import { getDataTableCache, setDataTableCache} from "./../../mixins/data-table-mixin";

export default {
	name: "GridTable",
    emits: [
        'mounted'
        , 'clear-filter'
        , 'done'
        , 'delete'
        , 'action'
        , 'publish'
        , 'column-action'
        , 'selected'
        , 'data-selection'
        , 'data-selection-action',
        'delete-selections'
        , 'column-click'
        , 'column-dbl-click'
        , 'checkbox-toggled'
        , 'checkbox-toggled-all'
        , 'show-detail-row'
        , 'hide-detail-row'
        , 'detail-row-clicked'
        , 'open-modal'
        , 'modal-loaded'
        , 'change-visible-fields'
        , 'loaded'
        , 'load-done'
        , 'load-success'
        , 'load-error'
        , 'details-open'
        , 'details-closed'
        ,"change-page"
        , "row-click"
        , "row-dblclick"
        , 'selected-count'
        , 'checkbox-toggled'
        , 'checkbox-toggled-all',
	    'open-detail-row',
	    'close-detail-row'
    ],
	props: {
		baseData: {
			type: Object,
			required: true,
			default() {
				return {
					headers: [],
					rows:  []
				}
			}
		},
		defaultPagination: {
			type: [Array, Object],
			default() {
				return []
			}
		},

		actionField: {
			type: String,
			default() {
				return ''
			}
		},

		fieldComponents: {
			type: Object,
			default() {
				return {}
			}
		},

		fieldEvents: {
			type: Object,
			default() {
				return {}
			}
		},

		fieldSlots: {
			type: [Object, Array],
			default() {
				return []
			}
		},

		fieldFunctions: {
			type: Object,
			default() {
				return {}
			}
		},

		ajaxAction: {
			type: String,
			default: ''
		},

		requestParams: {
			type: Object,
			default() {
				return {}
			}
		},

		inPopup: {
			type: Boolean,
			default() {
				return false
			}
		},

		idKey: {
			type: String,
			default() {
				return 'id'
			}
		},

		viewTrash: {
			type: Boolean,
			default() {
				return false
			}
		},
		detailRowComponent: {
			type: String,
			default: ''
		},
		detailRowOnly:  {
			type: Function,
			default() {
				return function (item) {
					return true
				}
			}
		},

		toolbar: {},

        showPagination: {
            type: Boolean,
            default() {
                return true
            }
        }
	},

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx;
        const route = useRoute();
        const store = useStore();

        const $http = inject('$http');
        const $events = inject('$events');

        const mounted = ref( false)
        const firstRun = ref( true)
        const loadViaApi = ref( false)
        const loadedFromCache = ref( false)
        const loading = ref( false)
        const actionToolbar = ref( null)

        const total = ref( 0)
        const keyname = ref( 'id')
        const useDetailRow = ref( false)
        const url = ref( '')
        const header = ref( [])
        const rows = ref( [])
        const gridActions = ref( [])

        // Grid params
        const page = ref( 1);
        const perPage = ref( 20);


        const paginationInfo = ref({
            last_page: 1
        });

        const appendParams = ref({
            filter: {},
            get_griddata: 1,
            isVue: 1
        });

        const sortOrder = ref([
            {
                field: props.baseData.orderby || '',
                direction: props.baseData.sort || '',
                sortField: props.baseData.orderby || '',
            }
        ]);

        const deleteConfirmOpen = ref( false);
        const deleteConfirmMessage = ref( null);
        const deleteConfirmItem = ref( null);
        const deleteConfirmLoading = ref( false);
        const hasShortcuts = ref( false);

        let cache = getDataTableCache(route.path);
        if (cache && cache.grid) {
            let c = cache.grid;

            if (c.perpage) {
                perPage.value = c.perpage;
            }

            if (c.tablePagination) {
                paginationInfo.value = _.cloneDeep(c.tablePagination);
                if (inst.refs.vuetable) inst.refs.vuetable.gridActions = c.gridActions;
            }

            if (c.tableData) {
                if (inst.refs.vuetable) inst.refs.vuetable.tableData = c.tableData;
            }

            if (c.orderby) {
                if (inst.refs.vuetable) inst.refs.vuetable.mutatedSortOrder[0].field = c.orderby;
                sortOrder.value[0].field = c.orderby;
            }

            if (c.sortField) {
                if (inst.refs.vuetable) inst.refs.vuetable.mutatedSortOrder[0].sortField = c.sortField;
                sortOrder.value[0].sortField = c.sortField;
            }

            if (c.sort) {
                if (inst.refs.vuetable) inst.refs.vuetable.mutatedSortOrder[0].direction = c.sort;
                sortOrder.value[0].direction = c.sort;
            }
        }



        const slots = computed(() => {
            return inst.slots;
        });



        const tableDataSlots = computed(() => {
            let valid = {};
            _.each(inst.slots, (o, k) => {
                if (protectedSlotNames.indexOf(k) === -1) {

                    valid[cleanSlotName(k)] = o;
                }
            });

            return valid;
        });

        const hasColumns = computed(() => {
            return !_.isEmpty(header.value)
        });

        const dataTableHeight = 0;

        const idkey = computed(() => {
            return props.baseData.pk || 'id'
        });






        onBeforeMount(() => {
            firstRun.value = true;

            if (props.toolbar) {
                props.toolbar.setGrid(inst);
            }

            header.value = props.baseData.headers || [];
            rows.value = props.baseData.rows || {};
            total.value = props.baseData.total;

            if (props.baseData.order) {
                sortOrder.value = [{field: props.baseData.order, sortField:  props.baseData.order, direction: props.baseData.sort}];
            }

            page.value = 1;

            if (props.baseData.pagination) {
                paginationInfo.value = _.cloneDeep(props.baseData.pagination);

                if (props.baseData.pagination.current_page > 0) {
                    page.value = props.baseData.pagination.current_page;
                }
            }

            sortOrder.value[0].field = props.baseData.order;

            perPage.value = props.baseData.perPage || parseInt(getUsersPerpage(20));

            if (props.baseData.hasOwnProperty('gridActions') && !_.isEmpty(props.baseData.gridActions))
            {
                if (Array.isArray(header.value) && header.value[0].name !== '__checkbox')
                {
                    header.value.unshift({
                        width: '40',
                        name: '__checkbox:id'
                    });
                }
            }

            if (_.isString(props.baseData.sort) && props.baseData.sort)
            {
                sortOrder.value[0].direction = _.toLower(props.baseData.sort);
            }

            let cache = getDataTableCache(route.path);
            if (cache && cache.grid) {
                let c = cache.grid;

                if (c.tablePagination) {
                    paginationInfo.value = _.cloneDeep(c.tablePagination);
                }

                if (c.perpage) {
                    perPage.value = c.perpage;
                }

                if (c.page) {
                    if (page.value !== c.page) {
                        page.value = c.page;
                    }
                }

                if (c.orderby) {
                    sortOrder.value[0].field = c.orderby;
                }

                if (c.sortField) {
                    sortOrder.value[0].sortField = c.sortField;
                }

                if (c.sort) {
                    sortOrder.value[0].direction = c.sort;
                }
            }

            url.value = props.baseData.url;

            if (cache && cache.grid) {
                page.value = cache.grid.page || page.value;
            }






            // this.loadFromCache();
            // loadViaApi.value = true;
        });


        onMounted(() => {
            let order = props.baseData.order;

            page.value = 1;
            if (props.baseData.pagination && props.baseData.pagination.current_page > 0) {
                page.value = props.baseData.pagination.current_page;
            }


            const _keyListener = (e) => {
                if (e.key === "r" && e.altKey) {
                    e.preventDefault(); // present "Save Page" from getting triggered.
                    refreshGrid();
                }
            };

            document.addEventListener('keydown', _keyListener);



            $events.$on('data-table:update-settings', updateDataTableSettings );

            $events.$on('refreshGrid', () => {
                refreshGrid();
            });


            nextTick(() => {

                if (inst.refs.vuetable_footer)
                {
                    let inTabPane = inst.refs.vuetable_footer.closest('.tab-pane');
                    if (inTabPane) {
                        inTabPane.classList.add('contains-data-table');
                    }
                }

                // this.tableHeight = this.getTableHeight();
                if (inst.refs.hasOwnProperty('vuetable'))
                {
                    if ( props.toolbar ) {
                        props.toolbar.setDataTable(inst.refs.vuetable);
                    }

                    if (!inst.refs.vuetable.loadOnStart)
                    {
                        if (rows.value && rows.value.length)
                        {
                            inst.refs.vuetable.tableData = rows.value;
                        }

                        if (props.baseData.hasOwnProperty('pagination'))
                        {
                            paginationInfo.value = _.cloneDeep(props.baseData.pagination);
                            inst.refs.vuetable.tablePagination = props.baseData.pagination;

                            if (props.baseData.pagination.current_page && page.value !== props.baseData.pagination.current_page) {
                                page.value = props.baseData.pagination.current_page
                            }
                        }
                        else
                        {
                            inst.refs.vuetable.tablePagination = props.defaultPagination;
                        }

                        inst.refs.vuetable.$emit('vuetable:pagination-data', inst.refs.vuetable.tablePagination);
                        inst.refs.vuetable.$emit('vuetable:loaded');
                    }

                    let perpage = inst.vnode.el.querySelector('#vue-grid-perpage');
                    if (perpage) {
                        let option = perpage.querySelector('[value="' + perPage.value + '"]');
                        if (option) {
                            option.setAttribute('selected', 'selected')
                        }
                    }

                    _bindToolbarEvents();

                    let cache = getDataTableCache(route.path);
                    if (cache && cache.grid) {
                        let c = cache.grid;

                        if (c.perpage) {
                            perPage.value = c.perpage;
                        }

                        if (c.page) {
                            inst.refs.vuetable.currentPage = c.page;

                            if (page.value !== c.page) {
                                page.value = c.page
                            }
                        }
                        else {
                            inst.refs.vuetable.currentPage = page.value;
                        }

                        if (props.toolbar && _.isObject(c.currentFilter)) {
                            props.toolbar.setDataTableCurrentFilter(c.currentFilter)
                        }

                        if (c.tablePagination) {
                            paginationInfo.value = _.cloneDeep(c.tablePagination);
                            inst.refs.vuetable.gridActions = c.gridActions;
                        }

                        if (c.tableData) {
                            inst.refs.vuetable.tableData = c.tableData;
                        }

                        if (c.gridActions) {
                            inst.refs.vuetable.gridActions = c.gridActions;
                        }

                        if (c.orderby) {
                            inst.refs.vuetable.mutatedSortOrder[0].field = c.orderby;
                            sortOrder.value[0].field = c.orderby;
                        }

                        if (c.sortField) {
                            inst.refs.vuetable.mutatedSortOrder[0].sortField = c.sortField;
                            sortOrder.value[0].sortField = c.sortField;
                        }

                        if (c.sort) {
                            inst.refs.vuetable.mutatedSortOrder[0].direction = c.sort;
                            sortOrder.value[0].direction = c.sort;
                        }
                    }
                    else {
                        inst.refs.vuetable.currentPage = page.value;
                    }
                }

                // resize the Table Body container
                $events.$emit('resize');

                $events.$on('dataTableDeleted', (response) =>{
                    refreshGrid();
                });

                if (inst.refs.hasOwnProperty('vuetable'))
                {
                    inst.refs.vuetable.updateTableActionsColumnWidth()
                }

            });
        });


        function updateDataTableSettings() {
            if (store.getters.user && store.getters.user.hasOwnProperty('settings')) {
                let userPerpage = _.find(store.getters.user.settings, {option: 'perpage'});
                if (userPerpage && userPerpage.option_value > 0) {
                    perPage.value = parseInt(userPerpage.option_value);
                }
            }
        }

        function getUsersPerpage(defaultPP = 20) {
            if (store.getters.user && store.getters.user.settings)
            {
                let userPerpage = _.find(store.getters.user.settings, {option: 'perpage'});
                if (userPerpage && userPerpage.option_value > 0) {
                    return parseInt(userPerpage.option_value);
                }
            }

            return defaultPP;
        }


        function changeTableFieldLabel(name, newlabel) {
            inst.refs.vuetable.changeTableFieldLabel(name, newlabel);
        }

        function cleanSlotName(name) {
            return name.replace(/^slot_(.*)/g, '$1');
        }

        function dataTableMargin() {
            let mt = 0, mb = 0;
            if (inst.refs.gridcontainerouter)
            {
                let computedStyle = window.getComputedStyle(inst.refs.gridcontainerouter);
                mt = parseInt(computedStyle.getPropertyValue('margin-top'));
                mb = parseInt(computedStyle.getPropertyValue('margin-bottom'));
            }
            return {
                top: mt,
                bottom: mb
            }
        }


        // ------------ GRID Methods

        function initializedColumns(tableFields) {
            if (!_.isEmpty(props.fieldComponents))
            {
                let o = props.fieldComponents;

                for (let f in tableFields)
                {
                    let name = tableFields[f].name;
                    if (props.fieldComponents.hasOwnProperty(name))
                    {
                        Neloh.component(props.fieldComponents[name][0], props.fieldComponents[name][1]);

                        tableFields[f].name = props.fieldComponents[name][0];
                    }
                }

                inst.refs.vuetable.tableFields = tableFields
            }
        }


        function transform(data) {
            return data;
        }



        function loadFromCache() {

        }

        function saveCache() {

        }

        function saveColumns() {

        }

        function refreshGrid(event) {
            if (event) event.preventDefault();

            if (inst.refs.vuetable) {
                inst.refs.vuetable.resetVisibleDetailRows();
                inst.refs.vuetable.reload();
            }
        }

        function renderRowClass(dataItem, index) {
            let cls = [];
            let selected = inst.refs.vuetable.selectedTo;

            if (_.indexOf(selected, dataItem[idkey.value]) !== -1)
            {
                cls.push('selected');
            }
            if (dataItem.hasOwnProperty('_rowclass') && dataItem._rowclass)
            {
                cls.push(dataItem._rowclass);
            }

            return cls.join(' ')
        }

        function getSortParam() {
            let fieldName = !sortOrder.value[0].hasOwnProperty('sortField')
                ? sortOrder.value[0].field
                : sortOrder.value[0].sortField;
            let sort = sortOrder.value[0].direction;

            return {
                orderby: fieldName,
                sort: _.toLower(sort)
            };
        }

        function allcap(value) {
            return value.toUpperCase()
        }

        function formatDate(value) {
            return (value ? value : '-');
        }

        function formatNumber(value) {
            return accounting.formatNumber(value, 2)
        }

        /**
         * @param columnData
         * @param field         Header column
         * @param item
         * @param index
         */
        function formatLabelColumn(columnData, field, item, index) {
            // console.log('formatLabelColumn', arguments)

            if (!field.hasOwnProperty('dataClass'))
            {
                field.dataClass = '';
            }

            if (field.dataClass.indexOf('label-column') === -1)
            {
                field.dataClass = ' label-column opt-column';
            }

            let valStr, key, i, x, link, note = formatNote(columnData), actions = [];

            if (columnData.hasOwnProperty('rawactions') && columnData.rawactions.length)
            {

                for (i in columnData.rawactions)
                {
                    link = columnData.rawactions[i];
                    // console.log('rawaction: ', link);

                    if (link.url)
                    {
                        var linkStr = '<a href="' + link.url + '" ';

                        if (link.label)
                        {
                            if (index > 0)
                            {
                                linkStr += ' data-toggle="tooltip" ' +
                                    ' title="' + link.label.replace(/"/g, '\'') + '"';
                            } else
                            {
                                linkStr += ' data-placement="left" ' +
                                    ' data-toggle="tooltip" ' +
                                    ' title="' + link.label.replace(/"/g, '\'') + '"';
                            }
                        }

                        if (link.hasOwnProperty('attributes'))
                        {
                            for (x in link.attributes)
                            {
                                let attribut = link.attributes[x];
                                // console.log('attribute: ', x, attribut);
                                if (x === 'id' && (attribut === false || attribut === 'false'))
                                {
                                    // console.log('skip attribute: ', x, attribut);
                                    continue;
                                }

                                if (typeof attribut === 'string')
                                {
                                    linkStr += ' ' + x + '="' + _.escape(attribut) + '"';
                                } else if (typeof attribut === 'boolean' && x !== 'id')
                                {
                                    linkStr += ' ' + x + '="' + _.escape(attribut) + '"';
                                } else if (x === 'data' && typeof attribut === 'object')
                                {
                                    for (key in attribut)
                                    {

                                        // skip all success attributes
                                        // this is only for blade templates
                                        if (key !== 'success' && key !== 'request-success')
                                        {
                                            let escaped = attribut[key] ? _.escape(attribut[key]) : attribut[key];
                                            linkStr += ' data-' + key + '="' + escaped + '"';
                                        }
                                    }
                                } else
                                {
                                    linkStr += ' ' + x + '="' + _.escape(attribut) + '"';
                                }
                            }
                        }


                        let icon = '';
                        if (link.hasOwnProperty('icon') && link.icon)
                        {
                            icon = link.icon;
                        }


                        let icon2 = '';
                        if (link.hasOwnProperty('type') && link.type)
                        {
                            if (link.hasOwnProperty('type') && link.type)
                            {
                                linkStr += ' data-type="' + link.type + '"';
                            }

                            switch (link.type)
                            {
                                case 'edit':
                                    icon2 = '<i class="fa fa-pencil"></i>';
                                    break;
                                case 'delete':
                                    icon2 = '<i class="fa fa-trash-o"></i>';
                                    break;
                                case 'publish':
                                    icon2 = '<i class="fa fa-eye"></i>';
                                    break;
                                case 'draft':
                                    icon2 = '<i class="fa fa-hourglass-half"></i>';
                                    break;
                                case 'download':
                                    icon2 = '<i class="fa fa-download"></i>';
                                    break;

                                case 'install':
                                    icon2 = '<i class="fa fa-plus"></i>';
                                    break;

                                case 'uninstall':
                                    icon2 = '<i class="fa fa-times"></i>';
                                    break;

                                case 'perms':
                                    icon2 = '<i class="fa fa-universal-access"></i>';
                                    break;

                                case 'backendperms':
                                    icon2 = '<i class="fa fa-user-secret"></i>';
                                    break;

                                case 'setdefault':
                                    icon2 = '<i class="fa fa-check-square"></i>';
                                    break;
                            }
                        }

                        if (icon2)
                        {
                            linkStr += '>' + icon2 + '</a>';
                        } else
                        {
                            linkStr += '>' + icon + link.label + '</a>';
                        }

                        actions.push(linkStr)
                    }
                }


                valStr = columnData.value;
                if (columnData.hasOwnProperty('props') && columnData.props.hasOwnProperty('avatar'))
                {
                    valStr = columnData.props.avatar + '<div class="v">' + columnData.value + '</div>';
                }

                if (actions.length)
                {

                    return '' +
                        '<div class="">' + valStr + '</div>' +
                        '<div class="opt">' + actions.join('') + '</div>' + note;
                }

                return valStr + note;
            }
            else
            {
                valStr = columnData.value;
                if (columnData.hasOwnProperty('props') && columnData.props.hasOwnProperty('avatar'))
                {
                    valStr = columnData.props.avatar + '<div class="v">' + columnData.value + '</div>';
                }
                return columnData.value + note;
            }
        }


        function formatNote(data) {
            if (!data.hasOwnProperty('note') || !data.note) return '';
            return '<div class="note">' + data.note + '</div>'
        }



        function onPaginationData(pData) {
            if (_.isObject(pData)) {
                if (pData.hasOwnProperty('current_page') && pData.current_page > 0) {
                    page.value = pData.current_page;
                }
                paginationInfo.value = _.cloneDeep(pData);
            }

        }


        function onChangePage(p, force) {

            if (p === 'next') {
                page.value = page.value + 1;
            }

            if (p === 'prev') {
                page.value = page.value > 1 ? page.value - 1 : 1;
            }

            if (p === page.value)
            {
                return;
            }

            page.value = p;
            inst.refs.vuetable.changePage(p);
            context.emit('change-page', p);
        }


        function getToolbar() {

            if (this.$parent.$parent.$children.length > 0)
            {
                for (let i = 0; i < this.$parent.$parent.$children.length; i++)
                {
                    let el = this.$parent.$parent.$children[i].$el;

                    if (el && el.classList.contains('form-header'))
                    {

                        if (this.$parent.$parent.$children[i].gridInstance &&
                            this.$parent.$parent.$children[i].gridInstance.key === this.$route.fullPath)
                        {
                            return this.$parent.$parent.$children[i];
                        }

                        return this.$parent.$parent.$children[i];
                    }
                }
            }


            if (this.$parent.$children[0].hasOwnProperty('gridInstance') &&
                this.$parent.$children[0].gridInstance.key === this.$route.fullPath)
            {
                return this.$parent.$children[0];
            }
        }


        function onLoading() {
			store.dispatch('setDataTableLoading', loading.value)
            loading.value = true;
            $events.$emit('loading', true);
        }

        function onLoaded(args) {
	        store.dispatch('setDataTableLoading', false)
            $events.$emit('loaded');

            let cache = getDataTableCache(route.path);
            if (cache && cache.grid) {

                cache.grid.perpage = perPage.value;
                cache.grid.page = inst.refs.vuetable.currentPage;
                cache.grid.orderby = inst.refs.vuetable.mutatedSortOrder[0].field;
                cache.grid.sort = inst.refs.vuetable.mutatedSortOrder[0].direction;


                //
                cache.grid.tablePagination = inst.refs.vuetable.tablePagination;
                cache.grid.tableData = inst.refs.vuetable.tableData;

                // paginationInfo.value = inst.refs.vuetable.tablePagination;

                setDataTableCache(route.path, cache);
            }
            else {
                // paginationInfo.value = inst.refs.vuetable.tablePagination;

                setDataTableCache(route.path, {
                    grid: {
                        sort: inst.refs.vuetable.mutatedSortOrder[0].direction,
                        sortField: inst.refs.vuetable.mutatedSortOrder[0].sortField,
                        orderby: inst.refs.vuetable.mutatedSortOrder[0].field,
                        page: inst.refs.vuetable.currentPage,
                        perpage: perPage.value,
                        tablePagination: inst.refs.vuetable.tablePagination,
                        tableData: inst.refs.vuetable.tableData,
                    }
                });
            }


            cache = getDataTableCache(route.path);
            if (props.toolbar && _.isObject(cache.currentFilter)) {
                props.toolbar.setDataTableCurrentFilter(cache.currentFilter)
            }

            firstRun.value = false;

            nextTick(() =>
            {
                $events.$emit('resize');
                context.emit('loaded', args);

                nextTick(() =>
                {
                    loading.value = false;
                })
            })
        }

        function checkSelection() {
            context.emit('selected-count', inst.refs.vuetable.countSelected());
        }

        function clearSelction() {
            inst.refs.vuetable.clearSelction();
            checkSelection();
        }

        function getSelectedItems() {
            return inst.refs.vuetable.selectedTo;
        }

        function getTableDataRows() {
            return inst.refs.vuetable.tableData;
        }

        function loadCachedOptions(cache) {
            if (cache && cache.grid) {
                if (props.toolbar) {
                    props.toolbar.setGridFilters(cache.grid.currentFilter);

                    if (cache.grid.gridActions) {
                        props.toolbar.setDataTableActions(cache.grid.gridActions);
                    }
                }

                if (inst.refs.vuetable) {
                    inst.refs.vuetable.filter = Object.assign(_.cloneDeep( inst.refs.vuetable.appendParams), cache.grid.currentFilter );
                }
            }
        }

        function setPage(p) {
            inst.refs.vuetable.currentPage = p;
            page.value = p;

            return this;
        }

        function setFilter(filterData, tb, p) {
            if (_.isObject(filterData) && !_.isEmpty(filterData)) {

				let currentActiveFilter = _.filter( Object.assign(_.cloneDeep(inst.refs.vuetable.filter)), (v, k) => {
					if (inst.refs.vuetable.appendParams.hasOwnProperty(k) || k === 'filter' || k === 'get_griddata' || k === 'isVue') {
						return false;
					}
					return true;
				});

				let useFilter = Object.assign(_.cloneDeep( inst.refs.vuetable.appendParams), filterData);

				// If filter is not changed the return
				if (currentActiveFilter === useFilter ) {
					return;
				}

                inst.refs.vuetable.filter = Object.assign(_.cloneDeep( inst.refs.vuetable.appendParams), filterData);

                if (_.isNumber(p)) {
                    inst.refs.vuetable.currentPage = p;
                    page.value = p;
                }
                else {
                    inst.refs.vuetable.currentPage = 1;
                    page.value = 1;
                }

                store.dispatch('setFilterForDataTableCache', {path: route.path, data: inst.refs.vuetable.filter}).then(() => {
                    inst.refs.vuetable.resetVisibleDetailRows();
                    inst.refs.vuetable.loadData();
                });
                return;
            }

            if (tb && tb.$el && tb.$el.tagName === 'FORM')
            {
                let all = {};
                let params = _.cloneDeep( inst.refs.vuetable.appendParams);

                // Fields
                let elements = tb.$el.elements;
                if (elements && !_.isEmpty(elements))
                {
                    _.each(elements, function(field, key) {
                        if (field && field.name) {

                            if (field.type === 'radio' || field.type === 'checkbox') {
                                if (field.checked) {
                                    //
                                    all[field.name] = field.value;
                                }
                            }
                            else if (field.tagName === 'SELECT') {
                                if (field.multiple) {
                                    if (field.selectedOptions) {

                                        let selectedOptions = [];
                                        _.each(field.selectedOptions, (el) => {
                                            selectedOptions.push(el.value);
                                        });
                                        all[field.name] = selectedOptions
                                    }
                                }
                                else {
                                    if (field.selectedOptions) {
                                        all[field.name] = field.selectedOptions[0] ? field.selectedOptions[0].value : null;
                                    }
                                }
                            }
                            else {
                                //
                                all[field.name] = field.value;
                            }
                        }
                    })
                }

                inst.refs.vuetable.filter = Object.assign(params, all);

            }


            store.dispatch('setFilterForDataTableCache', {path: route.path, data: inst.refs.vuetable.filter}).then(() => {
                inst.refs.vuetable.currentPage = 1;
                page.value = 1;
                inst.refs.vuetable.loadData();

            });


        }

        function resetFilter() {
            let defaultFilter = {};
            if (props.toolbar) {
                defaultFilter = props.toolbar.defaultFilterData;
            }

            $events.$emit('use-grid-filters', {});

            nextTick(() => {
                inst.refs.vuetable.filter = Object.assign(_.cloneDeep( inst.refs.vuetable.appendParams),defaultFilter);


                store.dispatch('setFilterForDataTableCache', {path: route.path, data: inst.refs.vuetable.filter}).then(() => {
                    inst.refs.vuetable.currentPage = 1;
                    page.value = 1;
                    inst.refs.vuetable.loadData();

                });

            });
        }


        function _bindToolbarEvents() {

        }


        function updateSize(tab, pane) {

        }

        function refresh() {
            inst.refs.vuetable.refresh();
            // this.saveCache();
        }

        function reload() {
            inst.refs.vuetable.reload();
        }

        function columnClicked(dataItem, field, event, index) {
            if ( props.fieldEvents.hasOwnProperty(field.name) && _.isFunction(props.fieldEvents[field.name])) {
                props.fieldEvents[field.name]( dataItem, index );
            }

            context.emit('column-click', dataItem, field, event, index);
        }

        function columnDblClicked(dataItem, field, event, index) {
            if ( props.fieldEvents.hasOwnProperty(field.name) && _.isFunction(props.fieldEvents[field.name])) {
                props.fieldEvents[field.name]( dataItem, index );
            }

            context.emit('column-dblclick', dataItem, field, event, index);
        }

        function rowDblClicked( dataItem, event) {
            context.emit('row-dblclick', dataItem, event);
        }

        function rowClicked( dataItem, event) {
            context.emit('row-click', dataItem, event);
        }

        function openDetailRow(dataItem, event) {
            context.emit('open-detail-row', dataItem, event);
        }

        function closeDetailRow(dataItem, event) {
            context.emit('close-detail-row', dataItem, event);
        }


        function handleDeleteCancel() {
            deleteConfirmOpen.value = false;

            nextTick(() => {
                deleteConfirmItem.value = null;
                deleteConfirmMessage.value = null;
            });
        }

        function showDelete(deleteData) {
            deleteConfirmItem.value = deleteData;
            deleteConfirmMessage.value = deleteData.message;
            deleteConfirmOpen.value = true;
        }

        /**
         * delete event
         * delete(row, button, callback);
         */
        function handleDelete() {
            context.emit('delete', deleteConfirmItem.value.row, deleteConfirmItem.value.button, () => {
                deleteConfirmOpen.value = false;
                nextTick(() => {
                    deleteConfirmItem.value = null;
                    deleteConfirmMessage.value = null;
                });
            });
        }

        function handleRestore(row) {
            context.emit('restore', row);
        }

        function setRowClass(index, classNames) {
            inst.refs.vuetable.setRowClass(index, classNames)
        }

        function removeRowClass(index, classNames) {
            inst.refs.vuetable.removeRowClass(index, classNames)
        }

        function getRows() {
            return inst.refs.vuetable && _.isArray(inst.refs.vuetable.tableData) ? inst.refs.vuetable.tableData : [];
        }


        watch(() => props.baseData, (n, o) => {
            if (n !== o) {
                keyname.value = n.pk;
                header.value = n.headers || [];
                rows.value = n.rows || {};
                total.value = n.total;
                paginationInfo.value = n.pagination;

                let cache = getDataTableCache(route.path);
                if (cache && cache.grid)
                {
                    let c = cache.grid;

                    if (c.orderby) {
                        sortOrder.value[0].field = c.orderby;
                    }
                    else {
                        if (n.hasOwnProperty('orderby')) {
                            sortOrder.value[0].field = n.orderby;
                        }
                        else if (n.hasOwnProperty('order')) {
                            sortOrder.value[0].field = n.order;
                        }
                    }

                    if (c.orderby) {
                        sortOrder.value[0].sortField = c.orderby;
                    }
                    else {
                        if (n.hasOwnProperty('orderby')) {
                            sortOrder.value[0].sortField = n.orderby;
                        }
                        else if (n.hasOwnProperty('order')) {
                            sortOrder.value[0].sortField = n.order;
                        }
                    }

                    if (c.sort) {
                        sortOrder.value[0].direction = c.sort;
                    }
                    else {
                        sortOrder.value[0].direction = n.sort || 'asc';
                    }
                }
                else {
                    if (n.hasOwnProperty('order')) sortOrder.value[0].field = n.order;
                    if (n.hasOwnProperty('sort')) sortOrder.value[0].direction = _.toLower(n.sort);
                    if (n.hasOwnProperty('order') && n.hasOwnProperty('sort')) {
                        sortOrder.value = [];
                        sortOrder.value.push({
                            field: n.order,
                            sortField: n.order,
                            direction: n.sort ? n.sort : 'asc'
                        });
                    }
                }

                if (n.hasOwnProperty('gridActions') && !_.isEmpty(n.gridActions))
                {
                    if (_.isObject(header.value)) {
                        header.value = Object.assign({'__checkbox:id': {
                                width: '40',
                                name: '__checkbox:id'
                            } }, header.value);
                    }
                    else if (_.isArray(header.value)) {
                        header.value.unshift({
                            width: '40',
                            name: '__checkbox:id'
                        })
                    }

                    gridActions.value = n.gridActions;
                }

                if (n.url) {
                    url.value = n.url;
                }
                else {
                    if (!url.value) {
                        url.value = inst.root.ctx.currentRouteUrl(route.path)
                    }
                }


                nextTick(() => {
                    mounted.value = true;

                    nextTick(() => {

                        if (props.toolbar) {
                            props.toolbar.setDataTable(inst.refs.vuetable);
                            props.toolbar.setDataTableActions(gridActions.value);

                            if (cache && cache.grid && _.isObject(cache.grid.currentFilter)) {
                                props.toolbar.setDataTableCurrentFilter(cache.grid.currentFilter);
                            }
                        }

                        inst.refs.vuetable.gridActions = gridActions.value;
                        inst.refs.vuetable.tablePagination = paginationInfo.value;
                        inst.refs.vuetable.setData(rows.value);
                        page.value = n.page || 1;

                        if (paginationInfo.value && paginationInfo.value.current_page > 0) {
                            page.value = paginationInfo.value.current_page;
                        }
                    });
                });
            }
        }, { deep: true, immediate: true});

        watch(() => perPage.value, (val, oldVal) => {
            if (perPage.value !== parseInt(val)) {
                perPage.value = parseInt(val) ? parseInt(val) : 20;
            }
            $events.$emit('changeperpage', [perPage.value])
        });


        return {
            mounted,
            css,
            firstRun,
            loadViaApi,
            loadedFromCache,
            loading,
            actionToolbar,
            total,
            keyname,
            useDetailRow,
            url,
            header,
            rows,
            gridActions,
            page,
            perPage,
            paginationInfo,
            appendParams,
            sortOrder,
            deleteConfirmOpen,
            deleteConfirmMessage,
            deleteConfirmItem,
            deleteConfirmLoading,
            hasShortcuts,
            slots,
            tableDataSlots,
            hasColumns,
            idkey,

            initializedColumns,
            changeTableFieldLabel,
            cleanSlotName,
            formatDate,
            formatNumber,
            refreshGrid,
            renderRowClass,
            getSortParam,
            loadCachedOptions,

            formatLabelColumn,
            formatNote,

            onPaginationData,
            onChangePage,

            getToolbar,

            checkSelection,
            clearSelction,
            getSelectedItems,
            getTableDataRows,
            setPage,
            setFilter,
            resetFilter,
            refresh,
            reload,
            columnClicked,
            columnDblClicked,
            rowDblClicked,
            rowClicked,
            openDetailRow,
            closeDetailRow,
            handleDeleteCancel,
            showDelete,
            handleDelete,
            handleRestore,
            setRowClass,
            removeRowClass,
            getRows,
            onLoading,
            onLoaded,
            saveColumns,
        }
    },
}
</script>
