
<script>
import {defineComponent, getCurrentInstance, ref, computed, watch, nextTick, onBeforeMount, onBeforeUnmount, onMounted, inject} from "~/vue";

export default defineComponent({
	name: "memo-button",
	props: {
		memo: {

		},
		requestEvent: {
			type: String,
			required: false,
			default() {
				return '';
			}
		},
		updateUrl: {
			type: String,
			required: true,
		},
		updateParams: {
			type: Object,
			default() {
				return {}
			}
		},
		position: {
			type: String,
			required: false,
			default() {
				return 'right';
			}
		},
        isDeleted: {
            default: null
        },
		label: {
			type: String,
			required: false,
			default() {
				return 'Notiz';
			}
		},
		classes: {
			type: String,
			required: false,
			default() {
				return '';
			}
		}
	},

    emits: ['input'],

    setup(props, context) {
        const inst = getCurrentInstance()
        const isOpen = ref(false)
        const mutatedMemo = ref('')
        const popper = ref(null);

        const $events = inject('$events');

        watch(() => props.memo, (n, o) => {
            if (n !== o && n !== mutatedMemo.value) {
                mutatedMemo.value = _.isString(n) ? _.cloneDeep(n) : '';
            }
        });

        watch(() => mutatedMemo.value, (n, o) => {
            if (n !== o) {
                context.emit('input', n)
            }
        });


        const hasMemo = computed(() => {
            return _.isString(props.memo) && !_.isEmpty(props.memo) && props.memo.trim() !== ''
        })


        onBeforeUnmount(() => {
            // if (popper.value) {
            //     popper.value.destroy();
            //     popper.value = null;
            // }
            $events.$off('open-memo', close);
        });


        onBeforeMount(() => {
            mutatedMemo.value = _.isString(props.memo) ? _.cloneDeep(props.memo) : '';
        });

        onMounted(() => {
            $events.$on('open-memo', close);
        });


        function createPopperInstance() {
            // if (inst.refs.memo) {
            //     popper.value = createPopper(inst.refs.memo_btn, inst.refs.memo.node, {
            //         onFirstUpdate: (state) => {
            //             console.log('Popper positioned on', state.placement)
            //         },
            //         strategy: 'fixed',
            //         placement: 'bottom-start',
            //         modifiers: [
            //             {
            //                 name: 'offset',
            //                 options: {
            //                     offset: [0, 8],
            //                 },
            //             },
            //         ],
            //     });
            // }
        }

        function onOpen() {

            if (isOpen.value) return;

            // Close all other opened Memos
            $events.$emit('open-memo');

            isOpen.value = true;

             // nextTick(() => {
             //     inst.refs.memo.node.setAttribute('data-show', '');
             //    createPopperInstance();
             //     popper.value.update();
			 //
			 //
             // });

        }

        function close() {
            if (!isOpen.value) return;

            if (inst.refs.memo) inst.refs.memo.node.removeAttribute('data-show');

            if (popper.value) popper.value.destroy();

            nextTick(() => {
                popper.value = null;
                isOpen.value = false;
            });
        }


        return {
            isOpen,
            hasMemo,
            mutatedMemo,
            onOpen,
            close
        }
    },
})
</script>
<template>

	<span class="position-relative">
			<button ref="memo_btn"
			        type="button"
			        @click="onOpen"
			        class="btn btn-sm btn-primary btn-note"
			        :class="[classes, {'has-note': mutatedMemo && mutatedMemo.trim() }]"
			        v-tooltip="label" aria-describedby="tooltip" style="position: relative">
		<svg-icon name="note"/>
	</button>

	<popover :show="isOpen" :reference="$refs.memo_btn">
        <memo-popup
	        ref="memo"
	        :memo="mutatedMemo"
	        :isopen="isOpen"
	        :is-deleted="isDeleted"
	        :request-event="requestEvent"
	        :update-url="updateUrl"
	        :update-params="updateParams"
	        :position="position"
	        @update="m => { mutatedMemo = m }"
	        @done="close"
        ></memo-popup>
	</popover>

	</span>

</template>
<style scoped>

</style>
