import {
    mixin, debounce, map, uniq, clone, cloneDeep, filter, find, findIndex, flatMap, add, remove, concat, flatten, values, each, forEach, isEmpty, isString, isBoolean, isNumber, isInteger, isFunction, isArray, isObject, isNull, isUndefined,
    orderBy, sortBy, toLower, toUpper, size, startsWith, trim, trimStart, trimEnd, pickBy, keys, keyBy, keysIn, some, range, random, indexOf, first, last, isEqual, clamp, union, reverse, sumBy, reject
} from '~/lodash-es';

const _ = {
    mixin, debounce, map, uniq, clone, cloneDeep, filter, find, findIndex, flatMap, add, remove, concat, flatten, values, each, forEach, isEmpty, isString, isBoolean, isNumber, isInteger, isFunction, isArray, isObject, isNull, isUndefined,
    orderBy, sortBy, toLower, toUpper, size, startsWith, trim, trimStart, trimEnd, pickBy, keys, keyBy, keysIn, some, range, random, indexOf, first, last, isEqual, clamp, union, reverse, sumBy, reject
};




const lodashMix = {
    'isDecimal': (value) => {
        let res;
        if (_.isString(value)){
            res = value.match(/^[\-\+]?([0-9]+)([\,\.][0-9]{2})?$/g);
        }
        return res ? true : false;
    }
}
_.each(lodashMix, (caller, name) => {
    _[name] = caller;
})
_.mixin(lodashMix);
global._ = _;
window._ = _;


