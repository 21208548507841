import EditInstitution from './components/EditInstitution.vue';

export default [
    {
        path: '/institutions',
        component: require('./components/Index.vue').default,
        name: 'admin.institutions',
        meta: {
            title: '',
            nocache: false,
            grid: true
        }
    },

    {
        path: '/institutions/edit/:id',
        component: EditInstitution,
        name: 'admin.institutions.edit',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },

    {
        path: '/institutions/create',
        component: EditInstitution,
        name: 'admin.institutions.create',
        meta: {
            title: '',
            nocache: false,
            grid: false
        }
    },
];
