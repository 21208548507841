<template>
    <form-field :name="name" :field="field" :key="name" :validate="validate" :form-model="formModel" :responseerrors="responseerrors">
        <template v-slot:field>
            <div :class="['select-autocomplete', {'multiple': allowMultiple}]" :data-name="name">
                <v-select ref="select"
                          :get-option-label="getOptionLabel"
                          :filterable="filterable"
                          :label="filterable ? filterBy : 'label'"
                          :options="selectOptions"
                          :model-value="selectedValue"
                          :disabled="isDisabled"
                          :clearable="isDisabled ? false : true"
                          :multiple="allowMultiple"
                          :placeholder="field.placeholder"
                          @search="onSearch"
                          @update:modelValue="updateModelValue"
                          append-to-body>


                    <template v-slot:no-options>
                        <slot name="no-options">
                            {{ nooptionsLabel}}
                        </slot>
                    </template>


                    <template v-slot:selected-option="option">
                        <slot name="selectedoption" v-bind:option="option">
                            <div class="selected-people"
                                 v-if="option && (option.id && option.name || option.display_name)">

                                <div class="prefix-spacer">
                                    <span class="selected-prefix">Fid: {{ option.id }}</span>
                                </div>

                                <div class="label-container">
                                    <span v-if="option.display_name" class="main-label">{{ option.display_name }}</span>
                                    <span class="main-label" v-else>{{ option.name }}</span>

                                    <div class="sub-label">
                                        <span v-if="option.street || option.street_number">{{ option.street }} {{ option.street_number }} </span>
                                        <span v-if="option.zip || option.city">, {{ option.zip }} {{ option.city }}</span>
                                    </div>

                                </div>

                            </div>
                        </slot>
                    </template>

                    <template v-slot:option="option">
                        <div class="selected-people" v-if="option && option.id && option.name">
                            <div class="prefix-spacer">
                                <span class="selected-prefix">Fid: {{ option.id }}</span>
                            </div>
                            <div class="label-container">
                                <div class="main-label">{{ option.name }}</div>
                                <div v-if="option.street || option.zip || option.city" class="sub-label">
                                    <div v-if="option.street">{{ option.street }} {{ option.street_number }}</div>
                                    <div v-if="option.zip || option.city">{{ option.zip }} {{ option.city }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </v-select>
            </div>
        </template>

    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, nextTick, onBeforeMount, inject} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";
import {useStore} from "~/vuex";

export default {
    name: "form-organisationselect",
    emits: ['input'],
    props: {
        ...defaultFieldProps,
        returnOnly: {
            type: Boolean,
            default: false
        }
    },


    setup(props, context) {
        const inst = getCurrentInstance()
        const app = inst.root.ctx;

        const hasOwnPropertyPath = inject('hasOwnPropertyPath')
        const setPropertyPath = inject('setPropertyPath')
        const $events = inject('$events');
        const $http = inject('$http')
        const levelSpacer = 10;

        const {
            hasDisableWhen,
            getDisableWhen,
           allowMultiple, modelName, modelvalue, fieldAttributes} = useFormField(props, context);

        const mutatedField = ref({});
        const fieldOptions = ref([]);
        const selectedValue = ref([]);
        const initValue = ref(null)
        const cancel = ref(null)

        watch(() => props.field, (n) => {
            mutatedField.value = n;
        }, {deep: true, immediate: true});

        watch(() => props.field.value, (n, o) => {
            if (n !== o) {
                if (_.isObject(n)) {
                    selectedValue.value = Object.assign({}, n);
                } else if (_.isArray(n)) {
                    selectedValue.value = Object.assign({}, n);
                } else {
                    selectedValue.value = n;
                }
            }
        });


        const nooptionsLabel = computed(() => {
            return props.field.nooptions_label ? props.field.nooptions_label : 'keine Organisation gefunden...'
        });

        const isDisabled = computed(() => {
            if (hasDisableWhen.value) {
                return getDisableWhen.value;
            }

            if (props.field.hasOwnProperty('disabled')) {
                return props.field.disabled;
            }

            if (props.field.attributes && props.field.attributes.hasOwnProperty('disabled')) {
                return props.field.attributes.disabled;
            }

            return false;
        });

        const selectOptions = computed(() => {
            return fieldOptions.value;
        });

        const autocompleteUrl = computed(() => {
            if (props.field.hasOwnProperty('route')) {
                return app.routes(props.field.route);
            } else if (props.field.hasOwnProperty('url')) {
                return props.field.url;
            }
        });

        const filterable = computed(() => {
            return props.field.filterable === true;
        });

        const filterBy = computed(() => {
            return filterable.value ? props.field.filterBy : 'name';
        });

        onBeforeMount(() => {
            if (allowMultiple.value) {
                if (!_.isArray(modelvalue.value)) {
                    modelvalue.value = [];
                    selectedValue.value = [];
                }
            } else {
                if (_.isArray(modelvalue.value) && !_.isObject(modelvalue.value)) {
                    modelvalue.value = null;
                    selectedValue.value = null;
                }
            }



            if (!props.field.hasOwnProperty('value')) {
                setPropertyPath(props.field, 'value', null);
            }

            if (props.field.hasOwnProperty('options') && !_.isEmpty(props.field.options)) {
                fieldOptions.value = _.isArray(props.field.options) ? props.field.options : [props.field.options];
            }

            if (props.field.hasOwnProperty('selected')) {
                selectedValue.value = props.field.selected
            } else {
                if (_.isObject(props.field.value)) {
                    selectedValue.value = Object.assign({}, props.field.value);
                } else if (_.isArray(props.field.value)) {
                    selectedValue.value = Object.assign({}, props.field.value);
                } else {
                    selectedValue.value = modelvalue.value;
                }
            }


            mutatedField.value = props.field;

            if (_.isObject(mutatedField.value.value)) {
                selectedValue.value = Object.assign({}, mutatedField.value.value);
            }


            if (hasDisableWhen) {
                watch(() => props.formModel[ props.field.disable_when], (n) => {
                    if (n) {
                        nextTick(() => {
                            setPropertyPath(fieldAttributes.value, 'disabled', true);
                        })
                    }
                })
            }

            if (props.field.hasOwnProperty('watchview') && props.field.watchview) {
                watch(() => props.formModel[ props.field.watchview ], (n) => {
                    if (_.isObject(n)) {
                        selectedValue.value = Object.assign({}, n);
                    }
                })
            }
        });


        onMounted(() => {
            initValue.value = {
                value: _.cloneDeep(selectedValue.value),
                fieldOptions: _.cloneDeep(fieldOptions.value)
            };

            $events.$on('form-reset', () => {
                selectedValue.value = _.cloneDeep(initValue.value.value);
                fieldOptions.value = _.cloneDeep(initValue.value.fieldOptions);
            });
        });





        function getOptionLabel(option) {
            let label = '';
            if (typeof option === 'object') {
                if (option.hasOwnProperty('name') ) {
                    label += option.name
                } else if (option.hasOwnProperty('display_name')) {
                    label += option.display_name
                }
            }
            return label;
        }

        function reset() {
            selectedValue.value = [];
        }

        function getLevelClass(option) {
            let levelStr = '';
            if (option.hasOwnProperty('level') && option.level > 0) {
                levelStr += 'level-' + option.level;
            } else {
                levelStr += 'level-0';
            }

            let index = _.find(selectOptions.value, {topid: option.id});

            if (index) {
                levelStr += ' has-childs';
            }
            return levelStr;
        }

        function getLevelStyle(option) {
            if (option.hasOwnProperty('level') && option.level > 0) {
                return 'padding-left:' + (option.level * levelSpacer) + 'px';
            }
            return '';
        }

        function transformFieldOptions() {

            let options = [];
            _.each(mutatedField.value.options, (label, value) => {

                if (label.hasOwnProperty('label') && label.hasOwnProperty('value')) {
                    options.push(label);
                } else {
                    if ((_.isString(value) && value.match(/^\d{1,}/g)) || _.isNumber(value)) {

                        options.push({label: label, value: parseInt(value)});
                    } else {
                        options.push({label: label, value: value});
                    }
                }
            });

            return options;
        }

        let t;
        function onSearch(search, loading) {
            if (filterable.value) {
                loading(false);
                return;
            }
            clearTimeout(t)

            if (cancel.value) {
                cancel.value.cancel();
                cancel.value = null;
                loading(false);
            }

            if (!search) {
                return;
            }

            t = setTimeout(() => {
                callSearch(loading, search)
            }, 300);
        }

        function callSearch(loading, search) {
            let params = {q: search};


            if (props.field.hasOwnProperty('paramsEvent') && props.field.paramsEvent ) {
                let p = null;
                let eventname = null;

                if (props.field.paramsEvent.match(/^\$events\.\$emit/g)) {
                    eventname = _.clone(props.field.paramsEvent).replace(/^\$events\.\$emit[\.@]/g, '');
                    eval('p = $events.$emit("' + eventname + '", selectedValue.value)');
                }
                else if (props.field.paramsEvent.match(/^\$emit\./g)) {
                    eventname = _.clone(props.field.paramsEvent).replace(/^\$emit[\.@]/g, '');
                    eval('p = $events.$emit("' + eventname + '", selectedValue.value)');
                }

                if (p !== null && typeof p === "object" && !Array.isArray(p)) {
                    params = Object.assign(params, p)
                }
            }

            if (props.field.hasOwnProperty('params') && (_.isArray(props.field.params) || _.isObject(props.field.params))) {
                params = Object.assign(params, props.field.params)
            }

            $http.post(autocompleteUrl.value, params).then(r => {
                if (r.data) {
                    fieldOptions.value = r.data;
                }
                loading(false);
            }).catch(e => {
                loading(false);
            });
        }





        function deselect(option) {
            console.log('deselect', option)
        }

        function updateModelValue(option) {

            if (_.isNull(option)) {
                selectedValue.value = option;
                modelvalue.value = option;
            }


            if (props.field.fill_on_select && (_.isArray(props.field.fill_on_select) || _.isObject(props.field.fill_on_select))) {
                _.each(props.field.fill_on_select, (fill, key) => {

                    if ((_.isObject(option) && option.hasOwnProperty('id'))) {
                        if (option.hasOwnProperty(key)) {
                            if (hasOwnPropertyPath(props.formModel, fill)) {
                                setPropertyPath(props.formModel, fill, option[key])
                            }
                        } else {
                            if (hasOwnPropertyPath(props.formModel, fill)) {
                                setPropertyPath(props.formModel, fill, null)
                            }
                        }
                    } else {
                        if (hasOwnPropertyPath(props.formModel, fill)) {
                            setPropertyPath(props.formModel, fill, null)
                        }
                    }

                })
            }


            if (!props.returnOnly) {

                if (allowMultiple.value) {

                    if (Array.isArray(option)) {
                        selectedValue.value = option;
                        modelvalue.value = option;
                    }

                }
                else {

                    selectedValue.value = option;
                    modelvalue.value = option;
                }

                if (props.field.hasOwnProperty('onSelect') &&
                    _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval(props.field.onSelect);
                    } else if (props.field.onSelect.match(/^\$events\.\$emit/g)) {
                        let eventname = _.clone(props.field.onSelect).replace(/^\$events\.\$emit[\.@]/g, '');
                        eval('$events.$emit("' + eventname + '", selectedValue.value)');
                    } else {
                        if (_.isFunction(props.field.onSelect)) {
                            props.field.onSelect.call();
                        } else {
                            eval(props.field.onSelect);
                        }
                    }
                }
            } else {

                if (allowMultiple.value) {
                    if (_.isArray(option)) {
                        selectedValue.value = option;
                    }
                } else {
                    if (_.isObject(option) && option.hasOwnProperty('id')) {
                        selectedValue.value = option;
                    }
                    else {
                        selectedValue.value = option;
                    }
                }

                if (props.field.hasOwnProperty('onSelect') && _.isString(props.field.onSelect)) {
                    if (props.field.onSelect.match(/^\$emit/g)) {
                        eval(props.field.onSelect);
                    } else if (props.field.onSelect.match(/^\$events\.\$emit/g)) {
                        let eventname = _.clone(props.field.onSelect).replace(/^\$events\.\$emit[\.@]/g, '');
                        eval('$events.$emit("' + eventname + '", selectedValue.value)');
                    } else {
                        if (_.isFunction(props.field.onSelect)) {
                            props.field.onSelect.call();
                        } else {
                            eval(props.field.onSelect);
                        }
                    }
                }
            }

            // setPropertyPath(props.field, 'value', selectedValue.value);
            context.emit('input', selectedValue.value);
        }


        function changeOrganisation() {
            $events.$emit('selectOrganisation', selectedValue.value);
        }









        return {
            nooptionsLabel,
            allowMultiple,
            selectedValue,
            selectOptions,
            filterable,
            filterBy,
            isDisabled: isDisabled,
            getLevelStyle,
            getOptionLabel,
            modelvalue,
            updateModelValue,
            deselect,
            onSearch,
        }




    }
}
</script>

<style scoped>

</style>
