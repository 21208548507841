<template>
    <form-field :name="name" :form-model="formModel"
                :field="mutatedField" :key="name" :validate="validate" :responseerrors="responseerrors">
        <template v-slot:append>
            <slot name="append"/>
        </template>

        <template v-slot:field>
            <div class="select-autocomplete user-select" :data-name="name">
                <v-select ref="select"
                          :get-option-label="getOptionLabel"
                          :filterable="false"
                          :options="selectOptions"
                          :model-value="modelvalue"
                          :disabled="isDisabled"
                          :multiple="isMultiple"
                          @input="updateModelValue"
                          @search="onSearch">

                    <template v-slot:no-options>
                        kein Benutzer gefunden...
                    </template>

                    <template v-slot:selected-option="option">
                        <slot name="selectedoption" v-bind:option="option">
                            <template v-if="option && option.id">
                                <div class="selected-people">
                                    <div class="prefix-spacer">
                                        <span class="selected-prefix">Uid: {{ option.id }}</span>
                                    </div>
                                    <div class="label-container">
                                        <div class="main-label">{{ option.first_name }} {{ option.surname }}</div>
                                        <div class="sub-label" v-if="option.institution">
                                            <span>{{ option.institution.name }}</span>
                                            <span v-if="option.institution.street || option.institution.street_nr"> | {{ option.institution.street }} {{ option.institution.street_nr }}</span>
                                            <span v-if="option.institution.zip || option.institution.city"> | {{ option.institution.zip }} {{ option.institution.city }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </slot>
                    </template>

                    <template v-slot:option="option">
                        <div class="selected-people" v-if="option && option.id">
                            <div class="prefix-spacer">
                                <span class="selected-prefix" v-if="option.id">Uid: {{ option.id }}</span>
                            </div>
                            <div class="label-container">
                                <div class="main-label">{{ option.first_name }} {{ option.surname }}</div>
                                <div class="sub-label" v-if="option.institution">
                                    <span>{{ option.institution.name }}</span>
                                    <span v-if="option.institution.street || option.institution.street_nr"> | {{ option.institution.street }} {{ option.institution.street_nr }}</span>
                                    <span v-if="option.institution.zip || option.institution.city"> | {{ option.institution.zip }} {{ option.institution.city }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </v-select>
            </div>
        </template>

    </form-field>
</template>

<script>
import {computed, getCurrentInstance, onMounted, ref, watch, nextTick, inject, onBeforeMount} from "vue"
import {useFormField, defaultFieldProps} from "@/mixins/use-form-field";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";


export default {
    name: "UserSelect",
    props: {
        ...defaultFieldProps,
        returnOnly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    setup(props, context) {
        const inst = getCurrentInstance()
        const $root = inst.root.ctx;
        const $events = inject('$events')
        const $http = inject('$http')
        const getPropertyPath = inject('getPropertyPath')

        const {allowMultiple, fieldIsDisabled,  modelvalue} = useFormField(props, context);


        const isMounted = ref(false);
        const cancel = ref(null)
        const mutatedField = ref({})
        const searchInput = ref('');
        const selectedValue = ref([]);
        const initValue = ref(null);
        const fieldOptions = ref([])

        watch(() => props.field, (n) => {
            mutatedField.value = n
        }, {deep: true, immediate: true});


        const autocompleteUrl = computed(() => {
            if (mutatedField.value.hasOwnProperty('route')) {
                return $root.routes(mutatedField.value.route);
            } else if (mutatedField.value.hasOwnProperty('url')) {
                return mutatedField.value.url;
            }
        });

        const selectOptions = computed(() => {
            if (!isMounted.value) return [];
            return fieldOptions.value
        });


        onBeforeMount(() => {

            initDefaults();


            $events.$on('form-reset', () => {
                nextTick(() => {
                    selectedValue.value = _.cloneDeep(initValue.value.value);
                    fieldOptions.value = _.cloneDeep(initValue.value.fieldOptions);
                })
            });

            if (props.field.hasOwnProperty('watchview') && props.field.watchview) {
                const watchview = getPropertyPath(props.formModel, props.field.watchview);
                watch(() => watchview, (n) => {
                    if (_.isObject(n)) {
                        selectedValue.value = Object.assign({}, n);
                    }
                })
            }
        });

        onMounted(() => {
            isMounted.value = true;
        })


        function getOptionLabel(option) {
            if (typeof option === 'object') {
                if (option.hasOwnProperty('username')) {
                    return option.username
                }
            }
            return '';
        }

        function initDefaults() {
            mutatedField.value = props.field;

            if (props.field.hasOwnProperty('options') && !_.isEmpty(props.field.options)) {
                fieldOptions.value = _.isArray(props.field.options) ? props.field.options : [props.field.options];
            }

            if (props.field.hasOwnProperty('selected')) {
                selectedValue.value = props.field.selected
            } else {
                if (_.isObject(props.field.value)) {
                    selectedValue.value = Object.assign({}, props.field.value);
                } else if (_.isArray(props.field.value)) {
                    selectedValue.value = Object.assign({}, props.field.value);
                } else {
                    selectedValue.value = modelvalue.value;
                }
            }


            if (!props.returnOnly) {
                if (allowMultiple.value) {
                    if (!_.isArray(modelvalue.value)) {
                        modelvalue.value = [];
                    }
                } else {
                    if (_.isArray(modelvalue.value)) {
                        modelvalue.value = null;
                    }
                }
                if (_.isObject(mutatedField.value.value)) {
                    selectedValue.value = Object.assign({}, mutatedField.value.value);
                }
                $events.$on('clear-form', () => {
                    updateModelValue(null);
                });
            }

            initValue.value = {
                value: _.cloneDeep(selectedValue.value),
                fieldOptions: _.cloneDeep(fieldOptions.value)
            };

        }

        function reset() {
            selectedValue.value = [];
        }


        function transformFieldOptions() {

            let options = [];
            _.each(mutatedField.value.options, (label, value) => {

                if (label.hasOwnProperty('label') && label.hasOwnProperty('value')) {
                    options.push(label);
                } else {
                    if ((_.isString(value) && value.match(/^\d{1,}/g)) || _.isNumber(value)) {

                        options.push({label: label, value: parseInt(value)});
                    } else {
                        options.push({label: label, value: value});
                    }
                }
            });

            return options;
        }

        function onSearch(search, loading) {


            if (!search) {
                return;
            }

            loading(true);
            callSearch(loading, search, this);
        }


        function callSearch(loading, search) {

            const axiosSource = axios.CancelToken.source();
            cancel.value = {cancel: axiosSource.cancel, msg: "Loading..."};

            let postData;
            if (mutatedField.value.hasOwnProperty('postparams') && _.isObject(mutatedField.value.postparams)) {
                postData = Object.assign({}, mutatedField.value.postparams, {q: search})
            } else {
                postData = Object.assign({}, {q: search});
            }

            loading(true);

            $http.post(autocompleteUrl.value, postData, {cancelToken: axiosSource.token}).then(r => {
                if (r.data) {
                    fieldOptions.value = r.data;
                }

                loading(false);
            }).catch(e => {
                console.warn('Error:', e.statusText);
                loading(false);
            });
        }


        function updateModelValue(option) {
            if (_.isObject(option) && option.type === 'input') {
                return;
            }


            if (allowMultiple.value) {
                if (!Array.isArray(modelvalue.value)) {
                    modelvalue.value = [];
                }

                modelvalue.value = option;
            }
            else {
                modelvalue.value = option;
            }

            context.emit('input', option);


        }

        function changePeople() {
            if (selectedValue.value) {
                if (selectedValue.value.hasOwnProperty('people')) {
                    $events.$emit('selectUser', selectedValue.value);
                }
            }
        }

        return {
            isMounted,
            modelvalue,
            mutatedField,
            isMultiple: allowMultiple,
            isDisabled: fieldIsDisabled,
            selectedValue,
            fieldOptions,
            selectOptions,

            getOptionLabel,
            reset,
            onSearch,
            updateModelValue,
        }


    }
}
</script>

<style scoped>

</style>
