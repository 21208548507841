<script>
import {defineComponent, getCurrentInstance, onMounted, computed, inject, ref, onBeforeMount} from 'vue'
import {useStore} from "~/vuex"
import {useRoute, useRouter} from "~/vue-router";
import {getHtmlCsrfToken} from "@/utils/auth";

export default defineComponent({
    name: "PasswordReset",
    setup() {
        const inst = getCurrentInstance();
        const store = useStore();
        const router = useRouter();
        const route = useRoute();


        const $http = inject('$http');
        const appname = inject('appname');
        const version = inject('version');
        const notification = inject('notification')

        const loading = ref(false)


        const model = ref({
            email: null,
            username: null
        });

        const successSend = ref(null)

        const canSubmit = computed(() => {
            return model.value.username || model.value.email
        })

        const errors = computed(() => {
            if (!_.isArray(store.getters.formErrors) && _.isObject(store.getters.formErrors) && Object.keys(store.getters.formErrors).length) {
                return store.getters.formErrors;
            }
            else if (_.isArray(store.getters.formErrors) && store.getters.formErrors.length) {
                return store.getters.formErrors;
            }
            return null;
        })



        onBeforeMount(() => {
            store.dispatch('form/clearFormErrors');
        });


        onMounted(() => {
            if (inst.refs.token_field) {
                inst.refs.token_field.value = getHtmlCsrfToken();
            }
        })

        function sendResetPassword(evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();

            loading.value = true;


            const form = new FormData();
            form.append('username', model.value.username);
            form.append('email', model.value.email);
            form.append('errorbackto', route.path );
            form.append('_token', getHtmlCsrfToken());


            store.dispatch('form/clearFormErrors');

            $http.post('backend/reset-password', {
                username: model.value.username,
                email: model.value.email,
                errorbackto: route.path,
            }).then((r) => {
                if (r.data.success) {
                    if (r.data.password_sending) {
                        successSend.value = r.data.password_sending;
                    }
                }
                else {
                    notification(r.data);
                }

                loading.value = false;
            }).catch(e => {
                loading.value = false;
            });

            // inst.refs.form.submit();
        }

        function toLogin() {
            router.push({name: 'auth'})
        }

        return {
            errors,
            model,
            successSend,
            canSubmit,
            loading,
            sendResetPassword,
            toLogin,
            appname,
            version
        }
    }
})
</script>

<template>
    <div class="auth-container">
        <div class="auth-form-container">
            <div class="auth-form reset-form">

                <form ref="form" action="backend/reset-password" method="post" autocomplete="off">
                    <input type="hidden" name="_token" value="" ref="token_field">

	                <div class="login-container">
		                <div class="login-header">Amino Passwort reset</div>
		                <div class="login-content">

                            <loading :active="loading === true"
                                     color="blue"
                                     :width="40"
                                     :height="40"
                                     :opacity="0.6"
                                     :is-full-page="false">
                            </loading>

			                <template v-if="errors">
				                <form-errors :errors="errors"/>
			                </template>

			                <template v-if="!successSend">
				                <form-text name="username" :form-model="model" :field="{label: 'Benutzername'}"/>
				                <div class="or"><div><span>oder</span></div></div>
				                <form-text name="email" :form-model="model" :field="{label: 'E-Mail'}"/>


				                <!--                        <div>-->
				                <!--                            <input type="text" name="username"-->
				                <!--                                   v-model="model.username"-->
				                <!--                                   @keyup.enter="sendResetPassword"-->
				                <!--                                   autocomplete="off"-->
				                <!--                                   placeholder="Benutzername">-->
				                <!--                        </div>-->
				                <!--                        <div>oder</div>-->
				                <!--                        <div>-->
				                <!--                            <input type="password" name="email"-->
				                <!--                                   v-model="model.email"-->
				                <!--                                   @keyup.enter="sendResetPassword"-->
				                <!--                                   autocomplete="off"-->
				                <!--                                   placeholder="E-Mail">-->
				                <!--                        </div>-->
			                </template>
			                <template v-else>
				                <div class="alert alert-info mt-4">
					                Wir haben Ihnen soeben eine E-Mail an {{ successSend.email }} gesendet.
				                </div>
			                </template>

			                <div class="btns mt-8">
				                <button type="button"
				                        class="btn btn-primary"
				                        v-if="!successSend"
				                        :disabled="!canSubmit"
				                        @click.prevent="sendResetPassword">
					                Passwort zurücksetzen
				                </button>

				                <button type="button"
				                        class="btn btn-primary"
				                        @click.prevent="toLogin">Zum Login</button>
			                </div>
		                </div>

		                <div class="copyright">
			                <small>&copy; 2024 by Neloh&trade; Software - Version {{ version }}
<!--				                <br/>written by Marcel Domke, <a href="https://lara.vision" target="_blank">Lara.Vision</a>-->
			                </small>
		                </div>

	                </div>



                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
