<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {defineComponent, getCurrentInstance, inject, nextTick} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";
import {defaultFieldProps, useFormField} from "neloh-ui/mixins/use-form-field";

export default defineComponent({
    name: "institution-users-table",
    props: {
        ...defaultFieldProps
    },
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx
        const $http = inject('$http');
        const $events = inject('$events');
        const route = useRoute();
        const router = useRouter()
        const store = useStore();
	    
	    const requestAction = inject('requestAction')
	    const setPropertyPath = inject('setPropertyPath');
		
        const {allowMultiple, fieldIsDisabled, modelName, modelvalue, fieldAttributes} = useFormField(props, context);

        const {
            hasTrashed,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);




        loadData();


        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            let url = $root.routes('admin.institutions.users', {institut: props.formModel.id});
            $http.post(url, postData).then((r) => {

                r.data.grid.url = url;

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {

        }

        function handleCreateUser() {
            router.push({name: 'admin.users.create'})
        }

        function handleDelete() {

        }
	    
	    
	    function openDetails(row, event) {
		    setPropertyPath(row, 'loadingdetails', true);
		    
		    if (row.hasOwnProperty('rowdetails')) {
			    setPropertyPath(row, 'loadingdetails', false);
			    return;
		    }
		    
		    requestAction('onLoadUserDetails').post($root.currentRouteUrl(route.path), {user_id: row.id, institut: row.institution_id }).then((r) => {
			    setPropertyPath(row, 'rowdetails', r.data);
			    setPropertyPath(row, 'loadingdetails', false);
		    });
	    }
	    
	    function closeDetails(row, event) {
		   
	    }

		
	    return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            selectedTrashCount,
            back,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreateUser,
            handleDelete,
	        
	        openDetails,
	        closeDetails,
        }

    }
})
</script>

<template>
    <div class="institution-users-list">
        <toolbar ref="toolbar"
                 :filters="toolbarData"
                 @clearfilter="handleClearGridFilter"
                 @executefilter="handleGridFilter">
        </toolbar>

        <data-table v-if="grid" ref="gridata"
                    detail-row-component="UserDetailRow"
                    @open-detail-row="openDetails"
                    @close-detail-row="closeDetails"
                    :field-components="fieldComponents"
                    :base-data="grid"
                    :toolbar="$refs.toolbar"
                    @selected-count="(v) => { selectedTrashCount = v }"
                    @delete="handleDelete"
                    @loaded="onLoaded">

            <template v-slot:custom_actions="{ row, index, field }">
                <template v-if="row.hasOwnProperty('_rowmemo')">
                    <memo-button :ref="'memo-'+ row.id"
                                 :memo="row._rowmemo.value ?? ''"
                                 :update-url="row._rowmemo.url"
                                 :is-deleted="row.deleted_at"
                                 :key="'memo-'+ row.id"
                                 position="left"
                                 classes="ml-5"
                                 @input="m => { row._rowmemo.value = m }"></memo-button>
                </template>
            </template>

            <template v-slot:username="{row}">
                <div>{{ row.username }}</div>
                <div class="text-sm">{{ row.first_name }} {{ row.surname }}</div>
            </template>
        </data-table>
    </div>
</template>

<style scoped>

</style>
