<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {computed, defineComponent, getCurrentInstance, inject, nextTick, ref} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";

export default defineComponent({
    name: "RequestsIndex",

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx

        const route = useRoute();
        const router = useRouter()
        const store = useStore();

        const requestAction = inject('requestAction')
        const setPropertyPath = inject('setPropertyPath');
        const changeBreadcrumb = inject('changeBreadcrumb')
        const $http = inject('$http');
        const $events = inject('$events');
		const showDetails = ref(false);
		const detailsForRequestId = ref(null);
        const holder = ref(null);

        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
            return true
            // return hasPermission('admins.system.users.create') || hasPermission('gf.system.users.create') || hasPermission('rechnungswesen.system.users.create') || hasPermission('mitarbeiter.system.users.create')
        })


	    $events.$on('request:show-details', showRequestDetails);

        loadData();


        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            if (route.query.newregistrations) {
                postData.newregistrations = true;
            }

            $http.post($root.currentRouteUrl(route.path), postData).then((r) => {
                if (r.data.holder) {
                    holder.value = r.data.holder
                }

                if (!r.data.grid.url) {
                    r.data.grid.url = $root.currentRouteUrl(route.path);
                }

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                changeBreadcrumb(r.data)

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {
            if (data.holder) {
                holder.value = data.holder
            }
            else {
                holder.value = null
            }

            changeBreadcrumb(data);

        }

        function handleCreate() {
            router.push({name: 'admin.requests.create'})
        }

        function handleDelete() {

        }

		function showRequestDetails(rowData, link) {
			detailsForRequestId.value = rowData.id;
			showDetails.value = true;
		}

        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,
	        showDetails,
	        detailsForRequestId,
            holder,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreate,
            handleDelete,
        }
    }
})
</script>

<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">

        <button class="btn btn-primary"
                @click="handleCreate"
                type="button"
                v-if="canCreateUser">
            <svg-icon name="plus"/>
            Anfrage anlegen
        </button>

        <template v-slot:description>
            <div class="description" v-if="holder && holder.name">Kammerbereich <b>{{ holder.name }}</b></div>
        </template>

    </toolbar>


    <data-table v-if="grid" ref="gridata"
                :field-components="fieldComponents"
                :base-data="grid"
                :toolbar="$refs.toolbar"
                @selected-count="(v) => { selectedTrashCount = v }"
                @delete="handleDelete"
                @loaded="onLoaded">



        <template v-slot:question="{row}">
            <div class="question-col">
                <div class="question-label">
                    {{ row.question }}
                </div>

                <div class="question-infos" v-if="row.hasFiles || row.hasLinks || row.hasLiteratures || row.hasNewspapers || row.hasCustomsources">
                    <svg-icon v-if="row.hasFiles" name="files" v-tooltip="'enthält :count Dateien als Quellen'.replace(':count', row.hasFiles)"/>
                    <svg-icon v-if="row.hasLinks" name="link" v-tooltip="'enthält :count Links als Quellen'.replace(':count', row.hasLinks)"/>
                    <svg-icon v-if="row.hasLiteratures" name="book" v-tooltip="'enthält :count Literaturen als Quellen'.replace(':count', row.hasLiteratures)"/>
                    <svg-icon v-if="row.hasNewspapers" name="news" v-tooltip="'enthält :count News als Quellen'.replace(':count', row.hasNewspapers)"/>
                    <svg-icon v-if="row.hasCustomsources" name="folders" v-tooltip="'enthält :count sonstige Quellen'.replace(':count', row.hasCustomsources)"/>
                </div>



            </div>
            <div v-if="row.answer" class="answer-label">
                {{ row.answer }}
            </div>

        </template>

        <template v-slot:subjects="{row}">
            <template v-if="row.subjects">
                <div class="text-small subjects">
                    <template v-for="title in row.subjects">
                        <span>{{ title }}</span>
                    </template>
                </div>
            </template>
        </template>


	    <template v-slot:institution_id="{row}">
		    <template v-if="row.institution">
			    <div class="institution">
                    {{ row.institution.name }} <span class="badge-dark badge">{{ row.institution.is_holder ? 'Kammerbereich' : 'Infostelle' }}</span>
                </div>
		    </template>
		    <template v-if="row.holder">
			    <div class="holder">Kammerbereich: {{ row.holder.name }}</div>
		    </template>
	    </template>





        <template v-slot:priority="{row}">
            <template v-if="row.priority_label">
                <div class="text-small">{{ row.priority_label }}</div>
            </template>
        </template>



        <template v-slot:created_at="{row}">
            <created-updated-by-at :date="row.created_at" :user="row.createdby" type="created"/>
        </template>

        <template v-slot:updated_at="{row}">
            <created-updated-by-at :date="row.updated_at" :user="row.updatedby" type="updated"/>
        </template>

<!--        <template v-slot:created_by="{row}">-->
<!--            <template v-if="row.createdby">-->
<!--                <div>{{ row.createdby.username }}</div>-->
<!--                <div class="text-small">-->
<!--                    {{ row.createdby.first_name }} {{ row.createdby.surname }}-->
<!--                </div>-->
<!--            </template>-->
<!--        </template>-->

<!--        <template v-slot:holder="{row}">-->
<!--            <template v-if="row.holder">-->
<!--                <div>{{ row.holder.name }}</div>-->
<!--                <div class="text-small">{{ row.holder.street }} {{ row.holder.street_number }} <br>{{ row.holder.zip }} {{ row.holder.city }}</div>-->
<!--            </template>-->
<!--        </template>-->


    </data-table>


	<show-request-details :show="showDetails"
	                      :request-id="detailsForRequestId"
	                      @close="showDetails = false; detailsForRequestId = null" />
</template>

<style scoped>

</style>
