<template>

    <validation-errors v-if="mode !== 'sidebar'" :errors="responseerrors"></validation-errors>
    <template v-for="(f, n) in formfields">
        <component v-if="fieldComponentName(f) && hasMode(f)"
                   :is="fieldComponentName(f)"
                   :name="n"
                   :field="f"
                   :key="n"
                   :mode="mode"
                   :validate.sync="validate"
                   :rendersections="rendersections"
                   :responseerrors.sync="responseerrors"
                   :form-model.sync="formModel"
        ></component>
    </template>
</template>

<script>
import { ref, watch} from "vue"

export default {
    name: "form-fields",
    props: {
        fields: {
            type: [Object, Array],
            required: true
        },
        rendersections: {
            required: false,
            default() {
                return {}
            }
        },
        validate: {
            type: Boolean,
            default: false
        },
        responseerrors: {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        mode: {
            type: String,
            default() {
                return 'main'
            }
        },
        formModel: {
            required: false,
            default() {
                return null
            }
        }
    },


    setup(props, context) {
        const formfields = ref({});

        watch(() => props.fields, (n, o) => {
            if (n !== o && n !== formfields.value && n) {
                if (!n.hasOwnProperty('fields')) {
                    formfields.value = n;
                } else if (n.hasOwnProperty('fields') && n.fields) {
                    formfields.value= n.fields;
                }
            }
        }, {deep: true, immediate: true});


        function fieldComponentName(field) {
            if (field.type === 'tab') {
                return null;
            }
            return 'form-' + field.type;
        }

        function hasMode(field) {
            if (props.mode === 'main') {
                return field.mode === 'main' || _.isUndefined(field.mode)
            } else {
                return field.mode === props.mode
            }
        }

        return {
            formfields,
            fieldComponentName,
            hasMode,
        }
    },


}
</script>
<style scoped>

</style>
