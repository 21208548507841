<template>
    <toolbar ref="toolbar"
             :filters="toolbarData"
             @clearfilter="handleClearGridFilter"
             @executefilter="handleGridFilter">
<!--        <button class="btn btn-primary"-->
<!--                @click="handleCreate"-->
<!--                type="button"-->
<!--                v-if="canCreateUser">-->
<!--            <svg-icon name="plus"/>-->
<!--            Literatur anlegen-->
<!--        </button>-->
    </toolbar>


    <div :class="['grid-pane-container', 'messager-index', {preview: isPreviewMode}]">
        <div class="data-container">



            <data-table v-if="grid" ref="gridata"
                        :field-components="fieldComponents"
                        :base-data="grid"
                        :toolbar="$refs.toolbar"
                        @column-click="cellClicked"
                        @selected-count="(v) => { selectedTrashCount = v }"
                        @delete="handleDelete"
                        @loaded="onLoaded">

                <template v-slot:subject="{row}">
                    <div>
                        <div>{{ row.subject }}</div>
                        <template v-if="row.messageable">
                            <div class="messageable">
                                {{ formatMessageableLabel(row.messageable) }}

<!--                                <template v-if="row.messageable.link">-->
<!--                                    <router-link :to="row.messageable.link">{{ formatMessageableLabel(row.messageable) }}</router-link>-->
<!--                                </template>-->
<!--                                <template v-else>{{ formatMessageableLabel(row.messageable) }}</template>-->
                            </div>
                        </template>
                    </div>
                </template>

                <template v-slot:sender="{row}">
                    <div>
                        <template v-if="row.fromuser">
                            <div class="fromuser">
                                <div v-if="!row.fromuser.surname">{{ row.fromuser.username }}</div>
                                <div v-if="row.fromuser.surname">{{ row.fromuser.first_name }} {{ row.fromuser.surname }}</div>
                            </div>
                        </template>
                        <template v-if="row.frominstitution && row.frominstitution.name">
                            <div class="frominstitution">
                                {{ row.frominstitution.name }}
                                <br>{{ row.frominstitution.street }} {{ row.frominstitution.street_number }}
                                <br>{{ row.frominstitution.zip }} {{ row.frominstitution.city }}
                            </div>
                        </template>
                    </div>
                </template>


                <template v-slot:to="{row}">
                    <div v-if="row.to">
                        <template v-if="row.to.touser">
                            <div class="fromuser">
                                <div v-if="!row.to.touser.surname">{{ row.to.touser.username }}</div>
                                <div v-if="row.to.touser.surname">{{ row.to.touser.first_name }} {{ row.to.touser.surname }}</div>
                            </div>
                        </template>
                        <template v-if="row.to.toinstitution && row.to.toinstitution.name">
                            <div class="frominstitution">
                                {{ row.to.toinstitution.name }}
                                <br>{{ row.to.toinstitution.street }} {{ row.to.toinstitution.street_number }}
                                <br>{{ row.to.toinstitution.zip }} {{ row.to.toinstitution.city }}
                            </div>
                        </template>
                    </div>
                </template>


                <template v-slot:date="{row}">
                    <template v-if="row.date">{{ formatDate(row.date) }}</template>
                </template>

                <template v-slot:created_at="{row}">
                    <created-updated-by-at v-if="row.created_at" :date="row.created_at" :user="row.createdby" type="created"/>
                </template>

                <template v-slot:updated_at="{row}">
                    <created-updated-by-at v-if="row.updated_at" :date="row.updated_at" :user="row.updatedby" type="updated"/>
                </template>
            </data-table>

        </div>
        <div class="preview">

            <loading :active.sync="loadingPreview"
                     color="blue"
                     :width="60"
                     :height="60"
                     :opacity="0.6"
                     :is-full-page="false">
                <template v-slot:after>Hole Nachricht ...</template>
            </loading>

            <template v-if="isPreviewMode && !loadingPreview">
                <message-preview
                    :preview="preview"
                    @submitted="reloadAfterMessageSubmit"
                    @reload="reload"
                    v-if="preview !== null"></message-preview>
            </template>
        </div>
    </div>

    <view-message
        :show="preview > 0"
                  :id="preview"
                  @success-send="onSuccessSendReply"
                  @close="preview = null"
                  @cancel="preview = null" />

</template>

<script>
import {computed, getCurrentInstance, inject, nextTick, ref, onMounted, onBeforeUnmount} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {getDataTableCacheForFilter, useDatatable} from "@/mixins/data-table-mixin";
import {truncate} from "@/utils/truncate"
export default {
    name: "messagerIndex",
    components: {
        'message-preview': require('./messenger-sidebar').default,
    },

    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx
        const $http = inject('$http');
        const $events = inject('$events');
        const route = useRoute();
        const router = useRouter()
        const store = useStore();
        const requestAction = inject('requestAction')
        const setPropertyPath = inject('setPropertyPath');
        const changeBreadcrumb = inject('changeBreadcrumb')
        const $moment = inject('$moment')


        const info = ref(false);
        const preview = ref(null);
        const loadingPreview = ref(false);

        const {
            hasTrashed,
            viewTrash,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            tempRoute,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            refreshGrid,
            back
        } = useDatatable(props, context, inst);

        const canCreateUser = computed(() => {
            return true
        })

        const isPreviewMode = computed(() => {
            return _.isObject(preview.value) || loadingPreview.value;
        });

        loadData();

        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }


            $http.post($root.currentRouteUrl(route.path), postData).then((r) => {

                if (!r.data.grid.url) {
                    r.data.grid.url = $root.currentRouteUrl(route.path);
                }

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                changeBreadcrumb(r.data)

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {
            changeBreadcrumb(data)
        }

        function handleCreate() {
            // router.push({name: 'admin.literature.create'})
        }

        function handleDelete(row, btn, callback) {

            if (btn.url) {
                callback();

                $events.$emit('pageloading', true);
                $http.post(btn.url).then((r) => {
                    $events.$emit('pageloading', false);

                    $root.notification(r.data);

                    if (r.data.success) {
                        refreshGrid();
                    }
                }).catch((e) => {
                    callback();

                    $events.$emit('pageloading', false);
                });
            }
        }

        function formatDate(v, format = 'DD.MM.YYYY') {
            return v ? $moment(v).format(format) : null;
        }

        function formatMessageableLabel(messageable) {
            if (messageable.hasOwnProperty('question_short')) {
                if (typeof messageable.question_short === "string" && messageable.question_short.trim()) {
                    return truncate( messageable.question_short.trim(), 100 );
                }

                if (typeof messageable.question === "string" && messageable.question.trim()) {
                    return truncate( messageable.question.trim(), 100 );
                }
            }

            return null;
        }

        function closeMessageView() {
            preview.value = null;
        }

        function cellClicked(dataItem, field, event, index) {
            if (field.name === 'subject' || field.name === '__slot:subject') {

                // if (preview.value == dataItem.id) {
                //     preview.value = null;
                //     inst.refs.gridata.removeRowClass(index, 'selected');
                //     return;
                // }


                // preview.value = dataItem.id;
                //
                //
                // if (loadingPreview.value) return;
                //
                // loadingPreview.value = true;
                //
                // let sel;
                //
                // if (inst.vnode.el.nodeType == 3) {
                //     sel = inst.vnode.el.parentNode.querySelector('tr.selected');
                // }
                // else {
                //     sel = inst.vnode.el.querySelector('tr.selected');
                // }
                //
                // if (sel) {
                //     sel.classList.remove('selected')
                // }
                //
                // inst.refs.gridata.setRowClass(index, 'selected');
                //
                // $http.post($root.routes('admin.messager.preview'), {
                //     id: dataItem.id
                // }).then((r) => {
                //     let date = r.data.date;
                //
                //     if (date && date.match(/^\d{4}-\d{2}-\d{2}/g)) {
                //         date = $moment(date).format('DD.MM.YYYY, HH:mm');
                //     }
                //
                //     preview.value = {
                //         index: index,
                //         id: dataItem.id,
                //         editable: r.data.editable,
                //         messager_message_id: dataItem.messager_message_id,
                //         content: r.data.content,
                //         subject: r.data.subject,
                //         status: r.data.status,
                //         date: date ? date : '',
                //         read_date: r.data.read_date,
                //         fromuser: r.data.fromuser,
                //         attachments: r.data.attachments
                //     };
                //
                //     loadingPreview.value = false;
                // });
            }
        }

        function download(attachment) {
            if (attachment.download_url) window.open(attachment.download_url);
        }

        function getFormatedText(value) {
            value = value.split('---');
            return value[0];
        }
        function reload() {
            inst.refs.gridata.refresh();
        }

        function markState(newstate) {
            if (preview.value) {
                $http.post($root.routes('admin.messager.change_state'), {id: preview.value.id, state: newstate}).then((r) => {
                    preview.value.status = newstate;
                    inst.refs.gridata.refresh();
                });
            }
        }

        function reloadAfterMessageSubmit() {
            preview.value = null;
            showWriteNewMessage.value = false;
            inst.refs.gridata.refresh();
        }


        function openMessage(rowData, link) {
            preview.value = rowData.id;
        }

        function onSuccessSendReply() {
            if (inst.refs.gridata) {
                inst.refs.gridata.refresh();
            }
        }


        function myHandleFilter(filterData) {
            handleGridFilter(filterData);

            if (inst.refs.toolbar) {
                if (filterData.mode == 2) {

                }
                else {

                }
            }
        }


        function myClearGridFilter() {
            handleClearGridFilter();
        }


        onBeforeUnmount(() => {
            $events.$off('messanger:show-message', openMessage);
        })

        onMounted(()=> {
            $events.$on('messanger:show-message', openMessage);
        });



        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            canCreateUser,
            selectedTrashCount,
            back,


            loadingPreview,
            isPreviewMode,
            preview,

            onLoaded,
            handleGridFilter: myHandleFilter,
            handleClearGridFilter: myClearGridFilter,
            handleCreate,
            handleDelete,
            formatDate,
            formatMessageableLabel,
            getFormatedText,
            cellClicked,
            download,
            reload,
            markState,
            formatFilesize: inject('formatFilesize'),
            reloadAfterMessageSubmit,
            onSuccessSendReply
        }
    }
}
</script>

<style scoped>

</style>
