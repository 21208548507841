const Sortable = require('~/sortablejs');
Neloh.directive('sortable', {
    mounted(el, binding, vnode) {
        if (!el.__sortableDone) {
            let o = binding.value;
            if (typeof o === "object" && o) {
                o = Object.assign(o, {forceFallback: true, fallbackOnBody: false, animation: 250})
            }
            else {
                o = {
                    ghostClass: 'ghost',
                    handle: '.drag-handler',
                    forceFallback: true,
                    fallbackOnBody: false,
                    animation: 250
                }
            }


            let sortable = new Sortable.Sortable(el, o);
            el.__sortableDone = true;
        }
    },


    updated(el, binding, vnode, prevVnode) {
        if (!el.__sortableDone) {
            let o = binding.value;
            if (typeof o === "object" && o) {
                o = Object.assign(o, {forceFallback: true, fallbackOnBody: false, animation: 250})
            }
            else {
                o = {
                    ghostClass: 'ghost',
                    handle: '.drag-handler',
                    forceFallback: true,
                    fallbackOnBody: false,
                    animation: 250
                }
            }
            let sortable = new Sortable.Sortable(el, o);
            el.__sortableDone = true;
        }
    }
});
