export function useNestableHelpers(props, context, inst, isCollapsed) {
    function getPathById(id, items = props.value) {
        let path = []

        items.every((item, i) => {
            if (item[props.keyProp] === id) {
                path.push(i)
            } else if (item[props.childrenProp]) {
                const childrenPath = getPathById(id, item[props.childrenProp])

                if (childrenPath.length) {
                    path = path.concat(i).concat(childrenPath)
                }
            }

            return path.length === 0
        })

        return path
    }


    function getItemByPath(path, items = props.value) {
        let item = null

        path.forEach(index => {
            const list = item && item[props.childrenProp] ? item[props.childrenProp] : items
            item = list[index]
        })

        return item
    }

    function getItemDepth(item) {
        let level = 1

        if (item[props.childrenProp] && item[props.childrenProp].length > 0) {
            const childrenDepths = item[props.childrenProp].map(getItemDepth)
            level += Math.max(...childrenDepths)
        }

        return level
    }

    function getSplicePath(path, options = {}) {
        const splicePath = {}
        const numToRemove = options.numToRemove || 0
        const itemsToInsert = options.itemsToInsert || []
        const lastIndex = path.length - 1
        let currentPath = splicePath

        path.forEach((index, i) => {
            if (i === lastIndex) {
                currentPath.$splice = [[index, numToRemove, ...itemsToInsert]]
            } else {
                const nextPath = {}
                currentPath[index] = {[options.childrenProp]: nextPath}
                currentPath = nextPath
            }
        })

        return splicePath
    }


    function getRealNextPath(prevPath, nextPath) {
        const ppLastIndex = prevPath.length - 1
        const npLastIndex = nextPath.length - 1

        if (prevPath.length < nextPath.length) {
            // move into deep
            let wasShifted = false

            return nextPath.map((nextIndex, i) => {
                if (wasShifted) {
                    return i === npLastIndex
                        ? nextIndex + 1
                        : nextIndex
                }

                if (typeof prevPath[i] !== 'number') {
                    return nextIndex
                }

                if (nextPath[i] > prevPath[i] && i === ppLastIndex) {
                    wasShifted = true
                    return nextIndex - 1
                }

                return nextIndex
            })
        } else if (prevPath.length === nextPath.length) {
            // if move bottom + move to item with children => make it a first child instead of swap
            if (nextPath[npLastIndex] > prevPath[npLastIndex]) {
                const target = getItemByPath(nextPath)

                if (target[props.childrenProp] && target[props.childrenProp].length && !isCollapsed(target)) {
                    return nextPath
                        .slice(0, -1)
                        .concat(nextPath[npLastIndex] - 1)
                        .concat(0)
                }
            }
        }

        return nextPath
    }


    return {
        getPathById,
        getItemByPath,
        getItemDepth,
        getSplicePath,
        getRealNextPath
    };


}
