<template>
    <div v-if="isMounted" class="vuetable-pagination-info" v-html="paginationInfo"></div>
</template>

<script>
    export default {
        props: {
            css: {
                type: Object,
                default() {
                    return {
                        infoClass: 'left floated left aligned six wide column'
                    }
                }
            },
            infoTemplate: {
                type: String,
                default() {
                    return "Displaying {from} to {to} of {total} items"
                }
            },
            noDataTemplate: {
                type: String,
                default() {
                    return 'No relevant data'
                }
            },
            pagination: {
                type: [Object, Array],
                default() {
                    return {
                        total: 0
                    }
                }
            }
        },
        data: function() {
            return {
                tablePagination: null,
                isMounted: false
            }
        },
        watch: {
            pagination(n) {
                this.tablePagination = n;
                this.$forceUpdate()
            }
        },
        computed: {
            paginationInfo () {
                if (this.tablePagination === null || this.tablePagination.total === 0) {
                    return this.noDataTemplate;
                }

                return this.infoTemplate
                    .replace('{from}', this.tablePagination.from || 0)
                    .replace('{to}', this.tablePagination.to || 0)
                    .replace('{total}', this.tablePagination.total || 0);
            },
        },
        mounted() {
            this.tablePagination = this.pagination
            this.isMounted = true;
        },
        methods: {
            setPaginationData (tablePagination) {
                this.tablePagination = tablePagination ;
                this.$forceUpdate()
            },

            resetData () {
                this.tablePagination = null;
                this.$forceUpdate()
            }
        },
    }
</script>
