<script>
import {getDataTableCacheForFilter, useDatatable} from "neloh-ui/mixins/data-table-mixin";
import {defineComponent, getCurrentInstance, inject, nextTick} from "vue";
import {useRoute, useRouter} from "~/vue-router";
import {useStore} from "~/vuex";
import {defaultFieldProps, useFormField} from "neloh-ui/mixins/use-form-field";

export default defineComponent({
    name: "list-institutions",
    props: {
        ...defaultFieldProps
    },
    setup(props, context) {
        const inst = getCurrentInstance();
        const $root = inst.root.ctx
        const $http = inject('$http');
        const $events = inject('$events');
        const route = useRoute();
        const router = useRouter()
        const store = useStore();


        const {allowMultiple, fieldIsDisabled, modelName, modelvalue, fieldAttributes} = useFormField(props, context);

        const {
            hasTrashed,
            selectedTrashCount,
            cleaning,
            grid,
            toolbarData,
            fieldComponents,
            handleClearGridFilter,
            handleGridFilter,
            back
        } = useDatatable(props, context, inst);




        loadData();


        function loadData() {
            let cache = getDataTableCacheForFilter(route.path);

            let postData = {};
            if (cache && cache.grid) {
                postData = cache.grid;
                if (cache.grid.currentFilter) {
                    postData = Object.assign(postData, cache.grid.currentFilter);
                }
            }

            let url = $root.routes('admin.holders.list-institutions', {item: props.formModel.id});
            $http.post(url, postData).then((r) => {

                r.data.grid.url = url;

                hasTrashed.value = r.data.hastrashed;
                toolbarData.value = r.data.grid.filter;
                grid.value = r.data.grid;

                nextTick(() => {
                    $events.$emit('pageloading', false);
                })

            }).catch((e) => {
                console.warn(e);
                nextTick(() => {
                    $events.$emit('pageloading', false);
                })
            });
        }


        function onLoaded(data) {

        }

        function handleCreateUser() {
            router.push({name: 'admin.users.create'})
        }

        function handleDelete() {

        }

        return {
            grid,
            cleaning,
            toolbarData,
            fieldComponents,
            selectedTrashCount,
            back,
            onLoaded,
            handleGridFilter,
            handleClearGridFilter,
            handleCreateUser,
            handleDelete,
        }

    }
})
</script>

<template>
    <div class="institution-list">
        <toolbar ref="toolbar"
                 :filters="toolbarData"
                 @clearfilter="handleClearGridFilter"
                 @executefilter="handleGridFilter">
        </toolbar>

        <data-table v-if="grid" ref="gridata"
                    :field-components="fieldComponents"
                    :base-data="grid"
                    :toolbar="$refs.toolbar"
                    @selected-count="(v) => { selectedTrashCount = v }"
                    @delete="handleDelete"
                    @loaded="onLoaded">

            <template v-slot:name="{row}">
                <div>
                    {{ row.name }}
                    <span class="badge-dark badge">{{ row.institution_type }}</span>
                </div>
                <div class="text-sm">
                    <div>{{ row.street }} {{ row.street_number }}</div>
                    <div>{{ row.zip }} {{ row.city }}</div>
                </div>
            </template>
        </data-table>
    </div>
</template>

<style scoped>

</style>
