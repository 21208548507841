<template>
    <neloh-datepicker
        @selected="handleDateSelect"
        :clear-button="clearButton"
        :format="format"
        :name="name"
        :value="currentDate"
        :language="de"
        :disabled="disabled"
        :placeholder="placeholder"
        :monday-first="true"
        :popper-props="popperOptions"
        :disabled-dates="state.disabledDates"></neloh-datepicker>
</template>

<script>
import {en, de} from 'vuejs-datepicker/dist/locale'
import Datepicker from '@/components/datepicker/datepicker';
import { inject, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";

export default {
    name: "date-picker",
    components: {
        'neloh-datepicker': Datepicker
    },
    props: {
        popperProps: {
            type: Object,
            default() {
                return {}
            }
        },
        validate: {
            type: Boolean,
            default: false
        },
        value: {},
        v: {},
        name: {
            type: String
        },
        format: {
            type: String,
            default() {
                return 'dd.MM.yyyy'
            }
        },
        clearButton: {
            type: Boolean,
            default() {
                return true;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        placeholder: {
            type: String,
            default() {
                return '';
            }
        },
        minDate: {},
        maxDate: {}
    },


    emits: ['input'],

    setup(props, context) {

        const $moment = inject('$moment');

        const defaultPopperOptions = {
            arrowPosition: 'middle',
            arrowOffsetScaling: 1,
            positionFixed: true,
            popperOptions: {
                placement: 'bottom-start',
                positionFixed: true,
                modifiers: {
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        // padding: 20,
                        enabled: true,
                        boundariesElement: document.querySelector('#app'),
                    },
                },
            },
        };

        const popperSettings = ref({})

        const currentDate = ref(null);
        const state = ref({
            disabledDates: {}
        });

        watch(() => props.value, (n, o) => {
            if (n !== o && n !== currentDate.value) currentDate.value = n ? n : null;
        });

        watch(() => props.minDate, (n, o) => {
            if (_.isString(n) || _.isObject(n) && n && n !== o) {
                state.value.disabledDates.to = n;
            }
        });

        watch(() => props.maxDate, (n, o) => {
            if (_.isString(n) || _.isObject(n) && n && n !== o) {
                state.value.disabledDates.from = n;
            }
        });

        onBeforeUnmount(() => {

        });

        onBeforeMount(() => {
            popperSettings.value = Object.assign(defaultPopperOptions, props.popperProps || {});
            prepareCurrentValue();
        });

        function prepareCurrentValue() {
            currentDate.value = props.value ? $moment(props.value).format('YYYY-MM-DD') : null;
        }


        function customFormatter(date) {
            return $moment(date).format('dd MM yyyy, h:mm');
        }

        function handleDateSelect(date) {
            if (date) {
                currentDate.value = $moment(date).format('YYYY-MM-DD');
            } else {
                currentDate.value = null;
            }

            context.emit('input', currentDate.value)
        }

        return {
            en: en,
            de: de,
            popperOptions: popperSettings,
            state,
            currentDate,

            customFormatter,
            handleDateSelect,
        };


    }
}
</script>

<style scoped>

</style>
